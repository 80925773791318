import * as React from "react"
import {Row, Col, Card} from "reactstrap"

import formatDate from "YConnect/Utils/FormatDate.util"
import OpenQuestionReviewType from "YConnect/Enums/OpenQuestionReviewType"

import * as greenCheck from "YConnect/Assets/svg/checkedGreen.svg"
import * as closeRed from "YConnect/Assets/svg/closeRed.svg"

const OpenQuestionModalCards = ({reviews}:any) => {
  const getReviewIcon = (reviewType:string) => {
    if (reviewType === OpenQuestionReviewType.APPROVAL) {
      return greenCheck
    } else if (reviewType === OpenQuestionReviewType.REJECTION) {
      return closeRed
    }

    return ""
  }

  if (reviews.length === 0) {
    return (
    <Row className="mb-0 mr-0 justify-content-center">
      <Col md={12}>
        <Card>
          <strong>No reviews...yet!</strong>
        </Card>
      </Col>
    </Row>
    )
  }

  return reviews.map((review:any, key:number) => 
    <Row key={key} className="mb-0 mr-0 justify-content-center open-question__modal__reviews">
      <Col md={12}>
        <Card outline={true}>
          <div className="open-question__modal__reviews__title">
            <strong>Review by <i>{review.teacherName}</i> on <i>{formatDate(review.createdDate, false)}</i></strong>
            <img src={getReviewIcon(review.type)} />
          </div>
          <span>
            {review.review}
          </span>
        </Card>
      </Col>
    </Row>
  )
}

export default OpenQuestionModalCards
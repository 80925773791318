import styled       from "styled-components"
import { Dropdown } from "react-bootstrap"

const ToggleUserProfileStyle = styled(Dropdown.Toggle)`
    align-items: center;
    background: transparent;
    border: transparent;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    border-bottom: solid 4px transparent;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0);
    border-radius: 0;
    color: ${props => props.theme.main.textWhite};
    height: 100%;
    padding: 0;

    & > img {
        padding-left: 0;
    }

    &::after {
        display: none;
    }

    .menu-profile--firstname {
        max-width: 86px;
        padding: 0 10px 0 0;
        overflow: hidden !important;
        text-decoration: none;
        text-overflow: ellipsis;
    }

    &:hover {
        color: ${props => props.theme.main.textWhite};
        background: transparent;
        border-bottom: solid 4px transparent;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        background-color: ${props => props.theme.main.lightPurple};
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0);
        border-bottom: solid 4px ${props => props.theme.main.lightGreen} !important;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
        box-shadow: unset;
    }
`

export default ToggleUserProfileStyle
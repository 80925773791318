import * as React   from "react"
import {
    Col, Modal, ModalHeader, ModalBody, Tooltip, Row
}                   from "reactstrap"

import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style"

import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"
import API          from "YConnect/API"
import Loader       from "YConnect/Components/Loader"
import getConfig    from "YConnect/Utils/GetRequestConfig.util"

import find      from "lodash/find"
import indexOf   from "lodash/indexOf"
import clone     from "lodash/clone"
import findIndex from "lodash/findIndex"
import isBoolean from "lodash/isBoolean"

import ExerciseRowStyle from "YConnect/Fields/Exercises/DragDropTxtImg.field/ExerciseRow.style"
import ImgStyle         from "YConnect/Fields/Exercises/DragDropTxtImg.field/Img.style"
import MediaPlayer  from "YConnect/Components/MediaPlayer"

class DragDropTxtImgField extends React.Component<ExerciseFieldPropsType, {
    loadingAnwers    : boolean,
    modal            : boolean,
    img              : string,
    title            : string,
    YConnectAnswers  : any,
    respostasUsuario : any,
    rightAnswers     : any,
    width            : number,
    tooltipOpen      : boolean
    isDisabledViewAnswers : boolean
}> {
    constructor(props: any) {
        super(props)

        if (this.props.innerRef) {
            this.props.innerRef.current.selectLanguage = this.selectLanguage
        }

        this.state = {
            loadingAnwers: false,
            modal: false,
            tooltipOpen: false,
            img: '',
            title: '',
            YConnectAnswers: this.embaralhaOpRespostas(props.exercise.respostas_Exercicio_Yconnect),
            rightAnswers: [],
            respostasUsuario: [],
            width: window.innerWidth,
            language: this.props.translated,
        }

        this.updateDimensions = this.updateDimensions.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this)
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);

        if (this.props.attempts > 0)
            this.fetchUserAnswer()
    }

    selectLanguage = (language: string) => {
        this.setState({...this.state, language})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    async fetchUserAnswer() {
        this.setState({ loadingAnwers: true })
        try {
            const response = await API.Exercicio.getRespostaUsuario({
                userId: this.props.studentId,
                exer: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem,
                tentativa: 'U',
                idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
            }, getConfig())

            this.findAnswers(response.data.resposta)

        } catch (error) {}
    }

    updateDimensions() {
        this.setState({
            width: window.innerWidth
        })
    }

	handleChange(event: any) {
        /**
         * value is dialogue Order
         */
        const { exercise, id, onChange } = this.props
        const { target: { value, attributes } } = event
        const { respostasUsuario, rightAnswers } = this.state
        const answerId = attributes['data-answerId'].value;

        const answer = find(
            exercise.respostas_Exercicio_Yconnect,
            {
                id_resposta: Number(answerId)
            }
        )

        /** clean last answer of sabe value before set current user answer */
        if (respostasUsuario.includes(answer.ordem)) {
            const index = indexOf(respostasUsuario, answer.ordem)

            respostasUsuario[index] = null
            rightAnswers[index] = null
        }

        respostasUsuario[value - 1] = answer.ordem
        /** clean initial answer value */
        rightAnswers[value - 1] = null

        const score = this.calculaScore(respostasUsuario)
        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })

        this.setState({ rightAnswers, respostasUsuario })
    }

    toggle(url: string, title: string) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            img: url ? getStaticUrl(url): prevState.img,
            title
        }))
    }

    embaralhaOpRespostas (respostas: any) {
        let retorno = clone(respostas)

        if (respostas) {
            // Embaralha as respostas, e armazena nas opções de respostas
            for (var i = 0; i < respostas.length; i++) { // gera números de 0 ao tamanho do vetor de respostas
                var r1 = Math.floor(Math.random() * respostas.length)
                var r2 = Math.floor(Math.random() * respostas.length)

                var aux = retorno[r2]
                retorno[r2] = retorno[r1]
                retorno[r1] = aux
            }

        }

        return retorno
    }

    findAnswers (respostasUsuario: string) {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const { rightAnswers } = this.state

        //Checa se é um string, caso seja, verifica se começa com [ e termina com ], e nesse caso, remove ambos os caractéres da string.
        if(typeof respostasUsuario === 'string' && respostasUsuario.charAt(0) === '[' && respostasUsuario.charAt(respostasUsuario.length - 1) === "]"){
            respostasUsuario  = respostasUsuario.slice(1,-1);
        }

        const result  = respostasUsuario.split(',').map((answer, index) => {
            const resp = respostas_Exercicio_Yconnect[answer - 1] || {}
            rightAnswers.push(resp.ordem === index + 1)

            return Number(answer)
        })

        this.setState({
            loadingAnwers: false,
            rightAnswers,
            respostasUsuario: result
        })
    }

    calculaScore(respostasUsuario: any) {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        let qntdAcertos = 0;

        respostas_Exercicio_Yconnect.map((resposta: any, index: number) => {
            if (resposta && resposta.ordem === respostasUsuario[index]) {
                qntdAcertos++;
            }
        })

        return { qntd_acertos: qntdAcertos, qntd_respostas: respostas_Exercicio_Yconnect.length };
    }

    showCorrectAnswers() {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const { respostasUsuario, rightAnswers } = this.state

        respostas_Exercicio_Yconnect.map((item, index) => {
            respostasUsuario[index] = item.ordem
            rightAnswers[index] = true
        })

        /** calculate score */
        const score = this.calculaScore(respostasUsuario)

        /** call parent function and send the values */
        this.props.onChange(this.props.id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })

        /** update component parameters */
        this.setState({ rightAnswers, respostasUsuario })
    }

    render() {
        let {
			props: {
                id,
                exercise,
                attempts,
                isStudent,
                isDisabledViewAnswers
            },
            state: {
                loadingAnwers,
                respostasUsuario,
                rightAnswers,
                YConnectAnswers,
                width
            },
            handleChange
        } = this

        if (loadingAnwers) {
            return <Loader />
        }
        return (
            <>
               {
                    !isDisabledViewAnswers
                     &&  <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                   {(attempts >= 2 || !isStudent) ?
                        <HeaderButtonStyle
                            className="float-right"
                            label="View Answers"
                            onClick={this.showCorrectAnswers}
                            icon="checkedGreen2"
                        />
                        :
                        <>
                            <HeaderButtonStyle
                                id="completeResultToolTip"
                                className="float-right disabled view-answers"
                                label="View Answers"
                                icon="checkedGreen2"
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="completeResultToolTip" toggle={() => this.setState(state => ({ tooltipOpen: !state.tooltipOpen }))}>
                                    {
                                        this.state.language == "br" &&
                                        <span>Oops, você só poderá saber a resposta correta depois de verificar suas próprias respostas na primeira e segunda tentativa.</span>
                                    }

                                    {
                                        this.state.language == "en" &&
                                        <span>Oops, you'll only be able to know the
                                    correct answers once you've checked your own
                                    answers on the 1st and 2nd attempts.</span>
                                    }
                            </Tooltip>
                        </>
                    }
                </div>}
                <div className="pr-3 pl-3 pr-md-5 pl-md-5 pt-4 pb-4">
                    {
                        exercise.midia_Yconnect.map((img: any, key: number) => {
                            const answerIndex = findIndex(YConnectAnswers, { ordem: Number(respostasUsuario[img.ordem - 1]) })
                            const position = indexOf(respostasUsuario, YConnectAnswers[key].ordem)

                            return (
                                <ExerciseRowStyle className="exercise-dialogue mb-1" key={`exercise-${key}`}>
                                    {/** exercise questions options */}
                                    <Col className="exercise-dialogue-options mt-3" xs="4" sm="4" md="2" lg="2">
                                        <div className="form-group" onClick={() => {}}>
                                            <label className="form-label label-select">Match with</label>
                                            <select
                                                className = "form-control text-uppercase"
                                                id        = {id}
                                                onChange  = {handleChange}
                                                value     = {position + 1}
                                                data-answerId={YConnectAnswers[key].id_resposta}
                                            >
                                                <option />
                                                {exercise.midia_Yconnect.map(({ ordem }, index: number) => (
                                                    <option
                                                        key={`option-${index}`}
                                                        className="text-uppercase"
                                                        value={ordem}>
                                                        {(ordem + 9).toString(36)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>

                                    {/** exercise answer text */}
                                    <Col className="exercise-dialogue-question" xs="4" sm="4" md="5" lg="5">
                                        <Row className="align-items-center flex-nowrap">
                                        <span className="option">
                                            <span>{ key + 1 }</span>
                                        </span>
                                         <MediaPlayer media={YConnectAnswers[key].resposta} className="w-90"/>
                                         </Row>
                                    </Col>

                                    {/** exercise question text */}
                                    <Col
                                        className={`exercise-dialogue-question d-none d-md-flex flex-collunm ${isBoolean(rightAnswers[img.ordem - 1]) ? (rightAnswers[img.ordem - 1] ? 'right' : 'wrong') : ''}`}
                                        xs="4" sm="4" md="5" lg="5"
                                    >
                                        {/** Answer option selected */}
                                        {
                                            answerIndex >= 0 ?
                                            <span className="selected-option">
                                                <span>{answerIndex + 1}</span>
                                            </span>
                                            :
                                            ''
                                        }

                                        <span className="option text-uppercase"
                                            style={{marginLeft: answerIndex >= 0 ? 'unset' : '24px'}}
                                        >
                                            <span>
                                                { (img.ordem + 9).toString(36) }
                                            </span>
                                        </span>
                                        <MediaPlayer media={img.url} toggleImage={() => this.toggle(img.url, (img.ordem + 9).toString(36))}/>
                                    </Col>
                                </ExerciseRowStyle>
                            )
                        })
                    }

                    {
                        width <= 576 ?
                            exercise.midia_Yconnect.map((img: any, key: number) => {
                                const answerIndex = findIndex(YConnectAnswers, { ordem: Number(respostasUsuario[img.ordem - 1]) })

                                return (
                                    <ExerciseRowStyle className="exercise-dialogue mb-1 pl-3 pr-3 pt-4" key={`exercise-${key}`}>
                                        {/** exercise question text */}
                                        <Col
                                            className={`exercise-dialogue-question d-flex flex-collunm ${isBoolean(rightAnswers[img.ordem - 1]) ? (rightAnswers[img.ordem - 1] ? 'right' : 'wrong') : ''}`}
                                            md="12" lg="12"
                                        >
                                            {/** Answer option selected */}
                                            {
                                                answerIndex >= 0 ?
                                                <span className="selected-option">{answerIndex + 1}</span>
                                                :
                                                ''
                                            }

                                            <span className="option text-uppercase"
                                                style={{marginLeft: answerIndex >= 0 ? 'unset' : '24px'}}
                                            >
                                                <span>{ (img.ordem + 9).toString(36) }</span>
                                            </span>
                                            <ImgStyle
                                                onClick={() => this.toggle(img.url, (img.ordem + 9).toString(36)) }
                                                src={getStaticUrl(img.url)} alt="" />
                                        </Col>
                                    </ExerciseRowStyle>
                                )
                            })
                        :
                        ''
                    }
                </div>
                <Modal id="myModal" isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={() => this.toggle(this.state.img,"")}>
                        Image <span className="text-uppercase font-weight-bolder">{this.state.title}</span>
                        </ModalHeader>
                    <ModalBody>
                        <img style={{ width: '100%', height: 'auto' }} alt="" src={this.state.img} />
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default DragDropTxtImgField

import * as React           from "react"
import styled from "styled-components";

import { useState, useContext, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { ButtonStyle }      from "YConnect/Components/Button"
import API         from "YConnect/API"
import getConfig   from "YConnect/Utils/GetRequestConfig.util"
import UserContext from "YConnect/User.context"

const ArchiveCourseModal = ({
    hasShow,
    onClose,
    dataCourse,
}:any) => {

    const textAreaCustom = {
        height: '379px',
        borderRadius: '10px',
        backgroundColor: '#EEE'
    }

    const [motive, setMotive] = useState()
    const [isArchiveButtonEnabled, setIsArchiveButtonEnabled] = useState(false);
    const {idUsuario, nome}:any = useContext(UserContext)

    const [isTextareaFocused, setIsTextareaFocused] = useState(false);
    const [showError, setShowError] = useState(false);
    const [textareaBorder, setTextareaBorder] = useState('');

    const setMotiveHandler = (value:any) =>{
        setMotive(value);
        setIsArchiveButtonEnabled(value !== '');
    }

    const handleBlur = (e: any) => {
        if(!e.target.value){
            setIsTextareaFocused(e.target.value)
        }
    };

    useEffect(() => {
        if(hasShow){
          validTextArea(1, motive);
        }
    }, [hasShow]);

    const validTextArea = (state: number, motive: any) => {

        setTextareaBorder('1px solid #EEE');
        setShowError(false);
        setIsArchiveButtonEnabled(true);

        if(state == 2 && ((!motive) || (!/[a-zA-ZÀ-ÿ]/.test(motive)))){
            setShowError(true);
            setTextareaBorder('1px solid red');
            setIsArchiveButtonEnabled(false);
            return false;
        }
        return true;
    }

    const sendArchiveRequest = (isArchive: boolean) => {

        if(isArchive && !validTextArea(2, motive)){
            return;
        }

        const requestData = {
          idCursoMudanca: dataCourse.id,
          motivo: motive,
          accountable: nome,
          accountableId: idUsuario,
          idALuno: dataCourse.id_aluno,
        };
        API.Usuario.archiveCourse(requestData, getConfig())
          .then(({ data }: any) => window.location.reload())
          .catch((error: any) => {
        });
    }

    const TextAlert = styled.span`
        text-align: center;
        font-size: 20px;
    `

    const ButtonsOptions = styled.div`
        margin-top: 20px;

        button {
            justify-content: center;
        }
    `
    const YesIWantButton = styled(ButtonStyle)`{
        background: ${isArchiveButtonEnabled ? '#A9CE71' : '#eee'};
        border: 2px solid ${isArchiveButtonEnabled ? '#A9CE71' : '#eee'};
        color: ${isArchiveButtonEnabled ? '#FFFFFF' : '#ADADAD'};
        border-color: ${showError ? 'red' : (isArchiveButtonEnabled ? '#A9CE71' : '#eee')};
        &:hover {
            background: ${isArchiveButtonEnabled ? '#A9CE71' : '#eee'};
            border: 2px solid ${isArchiveButtonEnabled ? '#A9CE71' : '#eee'};
            color: ${isArchiveButtonEnabled ? '#FFFFFF' : '#ADADAD'};
        }
    }`

    return <Modal show={hasShow} onHide={onClose} size="md" centered>
        {
            dataCourse.curso_corrente == 1 ? <> <Modal.Header closeLabel="Close" closeButton>
                <h2>Archive Course</h2>
            </Modal.Header>
                <TextAlert>
                    Justify the reason for archiving the course below:
                </TextAlert>
                <textarea
                    onFocus={() => {
                        setIsTextareaFocused(true);
                        setShowError(false); // Hide the error message when focused
                    }}
                    onBlur={handleBlur}
                    className="form-control mt-3"
                    onChange={(e: any) => {
                        setMotiveHandler(e.target.value);
                        setShowError(false); // Hide the error message when typing
                        setTextareaBorder('');
                    }}
                    placeholder={isTextareaFocused ? "" : "describe here"}
                    style={{ ...textAreaCustom, border: textareaBorder }}
                ></textarea>
                {showError && (
                    <TextAlert className="mt-1" style={{ color: 'red', textAlign: 'left', fontSize: '16px' }}>
                        Provide a justification
                    </TextAlert>
                )}
                <TextAlert className = "mt-3">
                    Are you want to archive the course?
                </TextAlert>
                <ButtonsOptions>
                    <div className="d-flex flex-row justify-content-center">
                        <ButtonStyle
                            type="button"
                            label="Cancel"
                            size="medium"
                            className="mr-2"
                            onClick={onClose} />
                        <YesIWantButton
                            label="Yes, I want to archive"
                            size="medium"
                            onClick={() => sendArchiveRequest(true)} />
                    </div>
                </ButtonsOptions> </>
                : <> <Modal.Header closeLabel="Close" closeButton>
                    <h2>Unarchive Course</h2>
                </Modal.Header>
                    <TextAlert>
                        Are you sure you want to unarchive the course?
                    </TextAlert>
                    <ButtonsOptions>
                        <div className="d-flex flex-row justify-content-center">
                            <ButtonStyle
                                type="button"
                                label="Cancel"
                                size="medium"
                                className="mr-2"
                                onClick={onClose} />
                            <ButtonStyle
                                green
                                label="Yes, I want to unarchive"
                                size="medium"
                                onClick={() => sendArchiveRequest(false)} />
                        </div>
                    </ButtonsOptions> </>
        }
                </Modal>}

export default ArchiveCourseModal

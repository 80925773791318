import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Col, Card } from "reactstrap"

import styled from "styled-components"

import AnswerEditableTable from "YConnect/PagesComponents/CMS/Editors/Dialogue.editor/Answer.editableTable"

type AnswersEditorProps = {
    question: QuestionType
    onChange:any
}

const AnswersEditor = ({
    question,
    onChange
}:AnswersEditorProps) => {

    const [values, setValues] = useState<Array<AnswerType>>([])


    useEffect(() => {
        if(question) {
            setValues(question.Answers)
        }
    }, [question])

    const handleChangeAnswer = (newAnswers:Array<AnswerType>) => {
        const newValues = {
            ...question,
            Answers:newAnswers.map((answer, i) => ({...answer, IsCorret:true, Order:i+1}))
        }

        setValues(newAnswers)
        onChange(newValues)
    }

    return <Col md={12} className="mt-3">
                <h3 className="font-weight-bold">
                    Answers {values.length > 0 && ` (${values.length})`}
                </h3>
                <AnswerEditableTable
                        values={values}
                        onChange={(newAnswers:Array<AnswerType>) => handleChangeAnswer(newAnswers)}/>
            </Col>
}


export default AnswersEditor

import * as React   from "react"
import { Col, Tooltip, Row, Container } from "reactstrap"
import styled from "styled-components"

import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style"

import API       from "YConnect/API"
import Loader    from "YConnect/Components/Loader"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"

import findIndex from "lodash/findIndex"
import last      from "lodash/last"
import clone     from "lodash/clone"
import unionBy   from "lodash/unionBy"
import find      from "lodash/find"
import isBoolean from "lodash/isBoolean"

import ButtonCollapseStyle       from "YConnect/Fields/Exercises/DialogueLong.field/ButtonCollapse.style"
import DivCollapseStyle          from "YConnect/Fields/Exercises/DialogueLong.field/DivCollapse.style"
import ExerciseDialogueStyle     from "YConnect/Fields/Exercises/DialogueLong.field/ExerciseDialogue.style"
import ExerciseRowStyle          from "YConnect/Fields/Exercises/DialogueLong.field/ExerciseRow.style"
import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/DialogueLong.field/ExerciseSubStatement.style"
import HrCollapseStyle           from "YConnect/Fields/Exercises/DialogueLong.field/HrCollapse.style"

import ReactPlayer from "react-player"
import formatText from "YConnect/Utils/FormatText.util";

const ReactPlayerStyled = styled(ReactPlayer)`
        @media (max-width: 768px) {
            width: auto !important;
            height: auto !important;
        }
`

class DialogueLongField extends React.Component<ExerciseFieldPropsType, {
    loadingExercise  : boolean,
    loadingAnwers    : boolean,
    answers          : any,
    contentDialogue  : any,
    respostasUsuario : any,
    collapse         : boolean,
    rightAnswers     : any,
    formatedExercise : any,
    tooltipOpen      : boolean
    isDisabledViewAnswers : boolean
}> {
    constructor(props: any) {
        super(props)

        this.props.innerRef.current.selectLanguage = this.selectLanguage

        this.state = {
            collapse: false,
            loadingExercise: true,
            loadingAnwers: false,
            tooltipOpen: false,
            answers: [],
            respostasUsuario: [],
            contentDialogue: {},
            rightAnswers: [],
            formatedExercise: [],
            language: this.props.translated,
        }

        this.handleChange = this.handleChange.bind(this)
        this.formatData = this.formatData.bind(this)
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this)

    }

    componentDidMount() {
        this.fetchDialogueExercise()

        if (this.props.attempts > 0)
            this.fetchUserAnswer()
    }

    selectLanguage = (language: string) => {
        this.setState({...this.state, language})
    }

    async fetchDialogueExercise () {
        try {
            const contentDialogue = await API.Exercicio.getDialogo({
                idExercicio: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem
            }, getConfig())

            this.setState({
                contentDialogue: contentDialogue.data,
                formatedExercise: this.formatData(contentDialogue.data.dialogo_Mensagem_Yconnect),
                loadingExercise: false
            })

        } catch (error) {}
    }

    async fetchUserAnswer() {
        this.setState({ loadingAnwers: true })
        try {
            const response = await API.Exercicio.getRespostaUsuario({
                userId: this.props.studentId,
                exer: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem,
                tentativa: 'U',
                idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
            }, getConfig())

            this.verifyRespostasDDDialogoLongo(response.data.resposta)

        } catch (error) {}
    }

	handleChange(event: any) {
        const { exercise, id, onChange } = this.props
        const { target: { value, attributes } } = event
        const { respostasUsuario, answers, rightAnswers } = this.state
        const answerOrder = attributes['data-answerOrder'].value;

        /** filter options related to answer Order */
        const options = this.filterAnswers(answerOrder, exercise.respostas_Exercicio_Yconnect)

        /** get answer object by id_resposta */
        const answerIndex = findIndex(
            options,
            {
                id_resposta: Number(value)
            }
        )

        answers[answerOrder - 1] = options[answerIndex]
        respostasUsuario[answerOrder - 1] = answerIndex + 1
        /** clean initial answer value */
        rightAnswers[answerOrder - 1] = null

        const score = this.calculaScore(answers)
        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })

        this.setState({ answers, respostasUsuario, rightAnswers })
    }

    formatData(dialogues: any) {
        const { exercise } = this.props

        let lastOrder = 0
        let result = []
        for (let index = 0; index < dialogues.length; index++) {
            const item = dialogues[index]
            let node = {}

            if (item.ordem_dialogo !== lastOrder) {
                lastOrder = item.ordem_dialogo
                node = {
                    ordem_mensagem: item.ordem_mensagem,
                    ordem_dialogo: item.ordem_dialogo,
                    texto: [ item.texto ],
                    options: this.embaralhaOpRespostas(
                        this.filterAnswers(item.ordem_dialogo, exercise.respostas_Exercicio_Yconnect)
                    )
                }
            } else {
                node = last(result)
                result.pop()

                node.options = this.embaralhaOpRespostas(
                    this.filterAnswers(item.ordem_dialogo, exercise.respostas_Exercicio_Yconnect)
                )
                node.ordem_mensagem = item.texto ? item.ordem_mensagem : node.ordem_mensagem
                node.texto.push(item.texto)
            }
            result.push(node)
        }

        return result;
    }

    filterAnswers (questionOrdem: number, answers: any) {
        return answers.reduce((result, item) => {
            if (item.ordem == questionOrdem) {
                result.push(item);
            }

            return result
        }, [])
    }

    embaralhaOpRespostas (respostas: any) {
        let retorno = clone(respostas)

        // Embaralha as respostas, e armazena nas opções de respostas
        respostas.map(() => {
            const r1 = Math.floor(Math.random() * respostas.length)
            const r2 = Math.floor(Math.random() * respostas.length)

            const aux = retorno[r2]
            retorno[r2] = retorno[r1]
            retorno[r1] = aux
        })

        return retorno
    }

    verifyRespostasDDDialogoLongo (respostasUsuario: string) {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const { rightAnswers } = this.state

        //Checa se é um string, caso seja, verifica se começa com [ e termina com ], e nesse caso, remove ambos os caractéres da string.
        if(typeof respostasUsuario === 'string' && respostasUsuario.charAt(0) === '[' && respostasUsuario.charAt(respostasUsuario.length - 1) === "]"){
            respostasUsuario  = respostasUsuario.slice(1,-1);
        }

        const result = respostasUsuario.split(',').map((answer, index) => {
            const answers = this.filterAnswers(index + 1, respostas_Exercicio_Yconnect)
            const resp = answers[answer - 1] || {}

            rightAnswers.push(resp.correta)
            return resp
        }, [])

        this.setState({
            loadingAnwers: false,
            answers: result,
            rightAnswers,
            respostasUsuario: respostasUsuario.split(',')
        })
    }

    calculaScore(answers: any) {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        var total = unionBy(respostas_Exercicio_Yconnect, 'ordem').length;
        let qntdAcertos = 0;

        answers.map((resposta: any) => {
            if (resposta && resposta.correta) {
                qntdAcertos++;
            }
        })

        return { qntd_acertos: qntdAcertos, qntd_respostas: total };
    }

    showCorrectAnswers() {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const { respostasUsuario, answers, rightAnswers } = this.state

        respostas_Exercicio_Yconnect.map((item, index) => {
            /** set answers object */
            if (item.correta) {
                answers[item.ordem - 1] = item

                /** add answer to an array sent to backend
                 *  the backend expect the index of the answer in the original object `respostas_Exercicio_Yconnect` 🙄
                 */
                respostasUsuario[item.ordem -1] = index + 1

                /** clear last answer result */
                rightAnswers[item.ordem - 1] = true
            }
        })

        /** calculate score */
        const score = this.calculaScore(answers)

        /** call parent function and send the values */
        this.props.onChange(this.props.id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })

        /** update component parameters */
        this.setState({ answers, respostasUsuario, rightAnswers })
    }

    render() {
        let {
			props: {
                id,
                exercise,
                attempts,
                isStudent,
                isDisabledViewAnswers
            },
            state: {
                loadingExercise,
                loadingAnwers,
                formatedExercise,
                rightAnswers,
                answers,
                collapse
            },
            handleChange
        } = this

        if (loadingExercise || loadingAnwers) {
            return <Loader />
        }

        const midiaYConnect = exercise.midia_Yconnect.length > 0 ? exercise.midia_Yconnect[0]
            : (exercise.exercicio_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect.length > 0) ? exercise.exercicio_Yconnect.midia_Yconnect[0]
                : {}

        const hasVideo = (midiaYConnect || {}).tipo_midia.toLowerCase() === 'video';
        const urlVideo = (midiaYConnect || {}).url;

        return (
            <Container>
                <Row>
                {
                    !isDisabledViewAnswers
                     && <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                   {(attempts >= 2 || !isStudent) ?
                        <HeaderButtonStyle
                            className="float-right"
                            label="View Answers"
                            onClick={this.showCorrectAnswers}
                            icon="checkedGreen2"
                        />
                        :
                        <>
                            <HeaderButtonStyle
                                id="completeResultToolTip"
                                className="float-right disabled view-answers"
                                label="View Answers"
                                icon="checkedGreen2"
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="completeResultToolTip" toggle={() => this.setState(state => ({ tooltipOpen: !state.tooltipOpen }))}>
                                    {
                                        this.state.language == "br" &&
                                        <span>Oops, você só poderá saber a resposta correta depois de verificar suas próprias respostas na primeira e segunda tentativa.</span>
                                    }

                                    {
                                        this.state.language == "en" &&
                                        <span>Oops, you'll only be able to know the
                                    correct answers once you've checked your own
                                    answers on the 1st and 2nd attempts.</span>
                                    }
                            </Tooltip>
                        </>
                    }
                </div>}
                {exercise.ajuda || exercise.texto || hasVideo ?
                    <ExerciseSubStatementStyle className="w-100">
                        {/** Ajuda */}
                        <div className="w-100 text-center">
                            <h2 dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.ajuda))}}></h2>
                        </div>

                        {/** texto */}
                        {exercise.texto ?
                            <ExerciseRowStyle className="exercise-dialogue pr-5 pl-5">
                                <Col className="exercise-dialogue-content w-100" md="12" lg="12">
                                    <DivCollapseStyle toggler="#toggler" dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.texto))}} />
                                    <HrCollapseStyle />
                                    <ButtonCollapseStyle color="link" id="toggler" size="md" onClick={() => this.setState(state => ({ collapse: !state.collapse }))}>
                                        {collapse ? '- Collapse text' : '+ Expand text' }
                                    </ButtonCollapseStyle>
                                </Col>
                            </ExerciseRowStyle>
                            :
                            <Col className="exercise-dialogue-content text-center" md="12" lg="12">
                                <div className="exercise-dialogue-text">
                                    {/** video */}
                        {hasVideo && !urlVideo.includes("facebook") && <div style={{display: "flex", justifyContent: "center"}}><ReactPlayerStyled url={`${getStaticUrl(urlVideo)}?autopause=0`} controls={true} /></div>}
                        {hasVideo && urlVideo.includes("facebook") && <div style={{width: "100%", display: "flex", justifyContent: "center"}}><iframe src={`https://www.facebook.com/plugins/post.php?href=${urlVideo}`} width="552" height="661" style={{border:"none",overflow:"hidden"}} scrolling="no"  ></iframe></div>}
                                </div>
                            </Col>
                        }
                    </ExerciseSubStatementStyle>
                    :
                    ''
                }

                {/** exercicios */}
                {
                    formatedExercise.map((item: Array<any>, key: number) => {
                        const answer = find(answers, { ordem: item.ordem_dialogo }) || {}

                        return (
                            <ExerciseDialogueStyle className={`exercise-dialogue-content borderBottom pl-5 pr-5 pt-5 ${isBoolean(rightAnswers[item.ordem_dialogo - 1]) ? (rightAnswers[item.ordem_dialogo - 1] ? 'right' : 'wrong') : ''}`} md="12" lg="12" key={key}>
                                <div className="exercise-dialogue-text">
                                    {item.texto.map((texto) => (
                                        texto ?
                                            <span className="mr-1" dangerouslySetInnerHTML={{ __html: texto }} />
                                            : (answer.resposta ? <span className="resposta mr-1" dangerouslySetInnerHTML={{ __html: answer.resposta }} /> : ' ____ ')
                                        ))
                                    }
                                </div>

                                {item.options ?
                                    <div className="exercise-dialogue-options mt-3">
                                        <div className="form-group">
                                            <label className="form-label label-select">Select to complete</label>
                                            <select
                                                className   = "form-control pr-4"
                                                id          = {id}
                                                value       = {answer.id_resposta}
                                                onChange    = {handleChange}
                                                data-answerOrder = {item.ordem_dialogo}
                                            >
                                                { answer.resposta ? '' : <option /> }
                                                {item.options.map(({id_resposta, resposta}, index) => (
                                                    <option key={`option-${index}`} value={id_resposta}>{resposta}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div> : ''
                                }
                            </ExerciseDialogueStyle>
                        )
                    })
                }
                </Row>
            </Container>
        )
    }
}

export default DialogueLongField

import * as React from "react"
import {Fragment} from "react"

import {
    Card,
    Row,
    Col
} from "reactstrap"


import ButtonGreenStyled from "YConnect/PagesComponents/CMS/Styleds/ButtonGreen.styled"
import ButtonWhiteStyled from "YConnect/PagesComponents/CMS/Styleds/ButtonWhite.styled"

import ExerciseTemplate from "YConnect/Enums/ExerciseTemplate"
import ExerciseDescription from "YConnect/Enums/ExerciseDescription"

type ListButtonTypeQuestionProps = {
    listTemplates:Array<TemplateType>
    onSelectTemplate:any
    templateIdSelected:number
}

const ListButtonTypeQuestion = ({
    listTemplates,
    onSelectTemplate,
    templateIdSelected
}:ListButtonTypeQuestionProps) =>
<Fragment>
    {
        listTemplates
        && listTemplates
        .filter(({Name, Description}:any)=>
            Description === ExerciseDescription.CHOOSE_MULTIPLE
            || Description === ExerciseDescription.CHOOSE_MULTIPLE_AUDIO
            || Description === ExerciseDescription.CHOOSE_MULTIPLE_LONG_TEXT
            || Description === ExerciseDescription.TYPE_IN
            || Description === ExerciseDescription.TYPE_IN_IMG
            || Description === ExerciseDescription.TYPE_IN_LONG_TEXT
            || Description === ExerciseDescription.TYPE_IN_AUDIO
            || Description === ExerciseDescription.CHOOSE_MULTIPLE_IMG
            || Description === ExerciseDescription.DIALOGO
            || Description === ExerciseDescription.DRAG_DROP
            || Description === ExerciseDescription.DRAG_DROP_VIDEO
            || Description === ExerciseDescription.DRAG_DROP_LONG_TEXT
            || Description === ExerciseDescription.DRAG_DROP_AUDIO
            || Description === ExerciseDescription.DRAG_DROP_IMG
            || Description === ExerciseDescription.DRAG_DROP_TEXT_IMG
            || Name === ExerciseTemplate.OPEN_QUESTION
            || Description === ExerciseDescription.UNSCRAMBLE
            || Description === ExerciseDescription.UNSCRAMBLE_IMG
            || Description === ExerciseDescription.UNSCRAMBLE_AUDIO
            || Description === ExerciseDescription.CHOOSE_MULTIPLE_VIDEO
            || Description === ExerciseDescription.TYPE_IN_ORDENADO
            || Description === ExerciseDescription.MATCH_TEXT
            || Description === ExerciseDescription.CLICK_LISTEN_IMG)
        .sort((a, b) => {
            if (a.Description > b.Description) {
                return 1
            }
            if (a.Description < b.Description) {
                return -1
            }
            return 0
        })
        .map(({Id, Description}, key) => {
            const props = {
                key,
                className:"mx-1 mb-2",
                onClick:() => {
                    onSelectTemplate(Id)
                },
                children:Description
            }
            return Id == templateIdSelected
                        ? <ButtonGreenStyled {...props}/>
                        : <ButtonWhiteStyled {...props}/>
        })
    }
</Fragment>


type QuestionChooseTypeCardProps = {
    listTemplates:Array<TemplateType>
    templateIdSelected:number
    onSelectTemplate:any
}

const TitleNewQuestion = () =>
    <Col md={12}>
        <h3 className="font-weight-bold">New Question</h3>
        <h4 className="font-weight-bold">Choose template for following question</h4>
    </Col>



const QuestionChooseTypeCard = ({
    listTemplates,
    templateIdSelected,
    onSelectTemplate
}:QuestionChooseTypeCardProps)=>{

    return <Card className="p-3 mb-3">
                <Row>
                    <TitleNewQuestion/>
                </Row>
                <Row>
                    <Col md={12}>
                        <ListButtonTypeQuestion
                            templateIdSelected = {templateIdSelected}
                            listTemplates      = {listTemplates}
                            onSelectTemplate   = {onSelectTemplate}/>
                    </Col>
                </Row>
            </Card>
}

export default QuestionChooseTypeCard

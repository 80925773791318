import styled from "styled-components"

const ImgStyle = styled.img`
    width: 100%;
    height: auto;

    @media screen and (max-width: 576px) {
        margin-top: 20px;
    }
`

export default ImgStyle

const ConvertLegacyExerciseInQuestion = (legacyExercise:any, listTemplates:Array<TemplateType>, dialogos:any) => {
    const {
        exercicio:{
            enunciado,
            conteudo_exercicio,
            respostas_Exercicio_Yconnect,
            midia_Yconnect,
            template_Yconnect:{nome}
        }
    } = legacyExercise

    const template = listTemplates.find(({Name}:TemplateType)=> nome == Name)

    const newDialogueMessages = 
    dialogos && dialogos.dialogo_Mensagem_Yconnect
    ? dialogos.dialogo_Mensagem_Yconnect
        .map(({ordem_mensagem, ordem_dialogo, texto}:any) => ({
            OrderMessage:ordem_dialogo,
            OrderSubMessage:ordem_mensagem,
            Text:texto
        }))
    : []

    const newDialogueAvatars = dialogos && dialogos.dialogo_Avatar_Yconnect
    ? dialogos.dialogo_Avatar_Yconnect.map((dialogoAvatar:any) => ({
        Order:dialogoAvatar.ordem,
        Avatar:{
            Source:dialogoAvatar.avatar_Yconnect.url_foto,
            Name:dialogoAvatar.avatar_Yconnect.nome
        }
    }))
    :[]
    
    return {
        TemplateId:template.Id || "",
        Content:conteudo_exercicio,
        Enunciated:enunciado,
        Answers:respostas_Exercicio_Yconnect.map(({correta, resposta, ordem}:any) => ({Order:ordem, Awnser:resposta, IsCorret:correta})),
        Dialogues:dialogos ? [{
            DialogueMessages:newDialogueMessages,
            DialogueAvatars:newDialogueAvatars
        }] : [],
        ExerciseMedias:midia_Yconnect.map(({ordem,tipo_midia, url}:any) => ({
            Source:url,
            MediaType:tipo_midia,
            Order:ordem
        }))
    }
}

export default ConvertLegacyExerciseInQuestion
import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"

import * as clock from "YConnect/Assets/svg/clock.svg"
import * as calendar from "YConnect/Assets/svg/calendar-report.svg"
import * as question from "YConnect/Assets/svg/question.svg"

import API from "YConnect/API"
import getRequestConfig from "YConnect/Utils/GetRequestConfig.util"

import ProfilePicture from "YConnect/Components/ProfilePicture"
import formatDate from "YConnect/Utils/FormatDate.util"
import convertPhoto from "YConnect/Utils/ConvertPhoto.util"
import LineProgressStyled from "YConnect/Styles/LineProgress.style"

import ResultStyle from "YConnect/PagesComponents/PrePostReport/Components/StudentVision/Result.style"
import ResultCardContentStyle from "YConnect/PagesComponents/PrePostReport/Components/StudentVision/ResultCardContent.style"
import ProgressPercentStyle from "YConnect/PagesComponents/PrePostReport/Components/StudentVision/ProgressPercent.style"
import ReportSummaryStyle from "YConnect/PagesComponents/PrePostReport/Components/StudentVision/ReportSummary.style"
import StudentInfoStyle from "YConnect/PagesComponents/PrePostReport/Components/StudentVision/StudentInfo.style"
import FinalScoreStyle from "YConnect/PagesComponents/PrePostReport/Components/StudentVision/FinalScore.style"

type StudentVisionProps = {
    studentIdSelected: any
    establishmentIdSelected: any
    courseIdSelected: any
    classIdSelected: any
    semesterIdSelected: any
    periodIdSelected: any
}

const StudentVision = ({
    studentIdSelected,
    establishmentIdSelected,
    courseIdSelected,
    classIdSelected,
    semesterIdSelected,
    periodIdSelected = 0
}: StudentVisionProps) => {

    const [preTest, setPreTest] = useState<any>()
    const [postTest, setPostTest] = useState<any>()

    const [studentScore, setStudentScore] = useState<any>()
    const [studentInfo, setStudentInfo] = useState<any>()
    const [lastUnits, setLastUnits] = useState<any>()

    useEffect(() => {        
        if (studentScore && studentScore.medias) {
            setPreTest(studentScore.medias.filter((media: any) => media.fl_is_pre === true)[0])
            setPostTest(studentScore.medias.filter((media: any) => media.fl_is_pre === false)[0])
        }
    }, [])

    useEffect(() => {
        if (studentScore && studentScore.medias) {
            setPreTest(studentScore.medias.filter((media: any) => media.fl_is_pre === true)[0])
            setPostTest(studentScore.medias.filter((media: any) => media.fl_is_pre === false)[0])
        }
    }, [studentScore])

    useEffect(() => {
        if (studentIdSelected && studentIdSelected !== "") {
            fetchData();
        }
    }, [studentIdSelected])

    useEffect(() => {
        if(periodIdSelected){
            fetchData();
        }
    }, [periodIdSelected]);

    const fetchData = async () => {
        try {
            const responseInfoStudent = await API.PrePostTest.GetInformacoesAluno({
                idAluno: studentIdSelected,
                idEstabelecimento: establishmentIdSelected,
                idCurso: courseIdSelected,
                idGrupo: classIdSelected,
                idAnoSemestre: semesterIdSelected,
                idmatricula: periodIdSelected
            }, getRequestConfig());
    
            const responseGetStudent = await API.PrePostTest.GetEstudante({
                idAluno: studentIdSelected,
                idEstabelecimento: establishmentIdSelected,
                idCurso: courseIdSelected,
                idGrupo: classIdSelected,
                idMatricula: periodIdSelected
            }, getRequestConfig());
    
            const response = await API.Report.Get({
                id_aluno: studentIdSelected,
                id_curso: courseIdSelected,
                tipo_unidade: "BOOK_TASK",
                id_matricula: periodIdSelected
            }, getRequestConfig());
    
            setStudentScore(responseInfoStudent.data.score);
            setStudentInfo(responseGetStudent.data.aluno);
            setLastUnits(response.data.reportEtapa);
        } catch (error) {
            console.error(error);
        }
    }

    return <>
        <Row>
            <Col xs={12}>
                <div className="card shadow d-flex flex-row flex-wrap justify-content-between">
                    {
                        studentInfo
                            ? <StudentInfoStyle >
                                <div className="student-photo">
                                    <ProfilePicture
                                        border="8px solid #bdbdbd"
                                        height={70}
                                        picture={convertPhoto(studentInfo.foto)}
                                        width={70}
                                    />
                                </div>
                                <div className="student-info">
                                    <h3 className="mb-2">{studentInfo.nomeCompleto}</h3>
                                    <p className="text-clickable mb-2">{studentInfo.email_aluno}</p>
                                    <p className="mb-2"><b>Course</b>: {studentInfo.course}</p>
                                    <p className="mb-2"><b>Class</b>: {studentInfo.classe}</p>
                                    <p className="mb-2"><b>Teacher</b>: {studentInfo.teacher}</p>
                                </div>
                            </StudentInfoStyle>
                            : null
                    }
                    <FinalScoreStyle>
                        {
                            lastUnits
                                ? <div className="card shadow">
                                    <h5 className="font-weight-bold mb-4">Report's final score</h5>
                                    <div className="d-flex justify-content-between">
                                        {
                                            lastUnits.map(({ descricao }: any, key: any) => <span key={key} className="title">{descricao}</span>)
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        {
                                            lastUnits.map(({ nota }: any, key: any) => <span key={key} className="subtitle small">Grade: {nota}</span>)
                                        }
                                    </div>
                                </div>
                                : null
                        }
                    </FinalScoreStyle>
                    <ResultStyle>
                        <h2>Pre test score</h2>
                        <ResultCardContentStyle>
                            {preTest
                                ? <>
                                    <ReportSummaryStyle>
                                        <div className="report">
                                            <img src={calendar} alt="" />
                                            <div className="report-info">
                                                <span className="title">Date:</span>
                                                <span className="value">{formatDate(preTest.dataTest)}</span>
                                            </div>
                                        </div>
                                        <div className="report">
                                            <img src={clock} alt="" />
                                            <div className="report-info">
                                                <span className="title">Duration:</span>
                                                <span className="value">{Math.floor(preTest.tempo / 60)} minutes</span>
                                            </div>
                                        </div>
                                        <div className="report">
                                            <img src={question} alt="" />
                                            <div className="report-info">
                                                <span className="title">"I don't know" answers:</span>
                                                <span className="value">{preTest.idk}%</span>
                                            </div>
                                        </div>
                                    </ReportSummaryStyle>

                                    <ProgressPercentStyle>
                                        <div className="percent">
                                            <LineProgressStyled>
                                                <span
                                                    className="progress-percentage"
                                                    style={{ marginLeft: `calc(${preTest.media}* 80%)` }}>
                                                    {(preTest.media * 100).toFixed(1)}%
                                            </span>
                                                <div className="line" />
                                                <div
                                                    className="line-progress"
                                                    style={{ width: `calc(${preTest.media}* 100%)` }} />
                                                <div
                                                    className="pointer-progress"
                                                    style={{ marginLeft: `calc(${preTest.media}* 100%)` }} />
                                                <div className="d-flex justify-content-between">
                                                    <span className="line-start">0</span>
                                                    <span className="line-start" style={{ marginLeft: `calc(0.70 * 100%)` }}>70%</span>
                                                    <span className="line-start">100%</span>
                                                </div>
                                            </LineProgressStyled>
                                        </div>
                                        <div className="overall">
                                            <small>Overall percentage:</small>
                                            <h3 className="percent-title" style={{ color: preTest.media > 0.7 ? "#00A281" : "#FF1A4A" }}>
                                                {(preTest.media * 100).toFixed(1)}%
                                        </h3>
                                        </div>
                                    </ProgressPercentStyle>
                                </>
                                : <p className="text-center w-100">There is not a pre-test done</p>
                            }
                        </ResultCardContentStyle>
                    </ResultStyle>

                    <ResultStyle>
                        <h2>Post test score</h2>
                        <ResultCardContentStyle>
                            {
                                postTest
                                    ? <>
                                        <ReportSummaryStyle>
                                            <div className="report">
                                                <img src={calendar} alt="" />
                                                <div className="report-info">
                                                    <span className="title">Date:</span>
                                                    <span className="value">{formatDate(postTest.dataTest)}</span>
                                                </div>
                                            </div>
                                            <div className="report">
                                                <img src={clock} alt="" />
                                                <div className="report-info">
                                                    <span className="title">Duration:</span>
                                                    <span className="value">{Math.floor(postTest.tempo / 60)} minutes</span>
                                                </div>
                                            </div>
                                            <div className="report">
                                                <img src={question} alt="" />
                                                <div className="report-info">
                                                    <span className="title">"I don't know" answers:</span>
                                                    <span className="value">{postTest.idk}%</span>
                                                </div>
                                            </div>
                                        </ReportSummaryStyle>

                                        <ProgressPercentStyle>
                                            <div className="percent">
                                                <LineProgressStyled>
                                                    <span
                                                        className="progress-percentage"
                                                        style={{ marginLeft: `calc(${postTest.media}* 100%)` }}
                                                    >
                                                        {(postTest.media * 100).toFixed(1)}%
                                            </span>
                                                    <div className="line" />
                                                    <div
                                                        className="line-progress"
                                                        style={{ width: `calc(${postTest.media}* 100%)` }}
                                                    />
                                                    <div
                                                        className="pointer-progress"
                                                        style={{ marginLeft: `calc(${postTest.media}* 100%)` }}
                                                    />
                                                    <div className="d-flex justify-content-between">
                                                        <span className="line-start">0</span>
                                                        <span className="line-start" style={{ marginLeft: `calc(0.70 * 100%)` }}>70%</span>
                                                        <span className="line-start">100%</span>
                                                    </div>
                                                </LineProgressStyled>
                                            </div>
                                            <div className="overall">
                                                <small>Overall percentage:</small>
                                                <h3 className="percent-title" style={{ color: postTest.media > 0.7 ? "#00A281" : "#FF1A4A" }}>
                                                    {(postTest.media * 100).toFixed(1)}%
                                        </h3>
                                            </div>
                                        </ProgressPercentStyle>
                                    </>
                                    : <p className="text-center w-100">There is not a post-test done</p>
                            }
                        </ResultCardContentStyle>
                    </ResultStyle>
                </div>
            </Col>
        </Row>
    </>
}

export default StudentVision
import * as React   from "react"
import { Fragment, useState, useContext, useEffect }   from "react"
import { Link, withRouter }     from "react-router-dom"


import * as logoYazigi    from "YConnect/Assets/png/logo-yazigi-white.png"
import * as logoPearson   from "YConnect/Assets/png/logoPearson.png"
import * as always        from "YConnect/Assets/png/always-learning.png"
import * as GSE           from "YConnect/Assets/png/logo-GSE.png"

import * as logoFacebook    from "YConnect/Assets/svg/logos/facebook.svg"
import * as logoTwitter     from "YConnect/Assets/svg/logos/twitter.svg"
import * as logoYoutube     from "YConnect/Assets/svg/logos/youtube.svg"
import * as logoSpotify     from "YConnect/Assets/svg/logos/spotify.svg"

import AccessibilityOptionsModal from "YConnect/Modals/AccessibilityOptions.modal"
import { ButtonStyle }           from "YConnect/Components/Button"
import FooterMessageStyle        from "YConnect/Styles/FooterMessage.style"
import FooterTopStyle            from "YConnect/Styles/FooterTop.style"
import styled                    from "styled-components"


import AuthPlacement from  "YConnect/Utils/AuthPlacement"
import UserContext from "YConnect/User.context"
import Toastify from "toastify-js"

type FooterProps = {
    contrast         : string
    reading          : string
    onChangeContrast : Function
    onChangeReading  : Function
}

const {buildDate} = build.info

const renderMessage = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = window.lpTag=window.lpTag||{},'undefined'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:'18943792'||'',section:lpTag.section||'',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:'1.10.0',_tagCount:1,protocol:'https:',events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0===e?(this._defB=this._defB||[],this._defB.push(t)):1===e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+'//'+(this.ovr&&this.ovr.domain?this.ovr.domain:'lptag.liveperson.net')+'/tag/tag.js?site='+this.site);var o=document.createElement('script');o.setAttribute('charset',e?e:'UTF-8'),i&&o.setAttribute('id',i),o.setAttribute('src',n),document.getElementsByTagName('head').item(0).appendChild(o)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent('onload',function(){t._domReady('domReady')}):(window.addEventListener('DOMContentLoaded',function(){t._domReady('contReady')},!1),window.addEventListener('load',function(){t._domReady('domReady')},!1)),'undefined'===typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger('LPT','DOM_READY',{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;

        document.body.appendChild(script);
      }, []);

    useEffect(() => {
        const chat = document.querySelector('.LPMcontainer') as any;
        if (chat)
           chat.style.inset = "auto 0px 60px auto";
      }, []);

    return <div className="col-12">
        <FooterMessageStyle className="card shadow get-better d-flex">
            <div className="get-better-content d-flex flex-column justify-content-start">
                <h2 className="mb-3 font-weight-bold">Get help, get better.</h2>
                <p>Our online teachers are here to guide you. Simply ask them your questions and enjoy the opportunity of having a one-to-one class.</p>
            </div>

            <div className="get-better-cta d-flex flex-row justify-content-end" id="YConnect_Chat_Button">
                <ButtonStyled
                    id = "buttonFlag"
                    size={'large'}
                    label="Talk to an Online Teacher"
                    icon="messageGreen"
                    onClick={() => openChat()} />
            </div>
        </FooterMessageStyle>
    </div>

}

const openChat = () => {
    const chat = document.querySelector('.LPMcontainer') as any
    const button = document.querySelector('#buttonFlag') as HTMLButtonElement

        if (chat) {
            chat.click()
            button.disabled = false
        }

        else {
            Toastify({
                text: "Please, wait a few seconds and try again.",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
}

const _popupCenter = (url: string, title: string, w: number, h: number) => {
    // Fixes dual-screen position                         Most browsers      Firefox
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title, 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) newWindow.focus();
}

const ButtonStyled = styled(ButtonStyle)`
    width: fit-content !important;
`

const Footer = ({ contrast, reading, onChangeContrast, onChangeReading }: FooterProps) => {

    const [menu, setMenu] = useState(false)
    const location = window.location

    const { apelido, email }: any = useContext(UserContext)

    return (
        <Fragment>
            <footer className="YC-Footer">
                <FooterTopStyle className={`YC-Footer-top top ${location.href.includes('pre_post/test') ? 'withoutOnlineTeacher' : ''}`}>
                    <div className="footer-top container">
                        <div className="footer-top-message row">
                            {location.href.includes('pre_post/test') ?  '' : renderMessage()}

                            <div className="col-12">
                                <div className="footer-top-about d-flex">
                                    <div className="about-logos row m-0">
                                        <div className="col-6">
                                        <img src={logoYazigi} alt="" className="footer-logo-img img-fluid" />
                                        </div>
                                        <div className="col-6">
                                        <img src={GSE} alt="" className="footer-logo-img img-fluid" />
                                        </div>
                                    </div>
                                    <div className="about-links">
                                        <a
                                            onClick={() => setMenu(!menu)}
                                            className="pointer d-none d-lg-inline p-3">
                                            Accessibility
                                        </a>
                                        <Link to="/about" className="d-none d-lg-inline p-3">About YConnect</Link>
                                        {location.href.includes('pre_post/test') ?
                                            ''
                                            :
                                            <Link to="/online-teacher" className="d-none d-lg-inline p-3">Online Teacher</Link>
                                        }
                                        <Link to="/support" className="d-none d-lg-inline p-3">Support</Link>
                                        <a  href="/placement/#/waiting"
                                            target="_blank"
                                            className="d-none d-lg-inline p-3"
                                            onClick={()=>{AuthPlacement(apelido, email)}}>
                                            {`Test your English`}
                                        </a>
                                    </div>
                                    <div className="social-media">
                                        <a href="https://www.facebook.com/yazigi.oficial?fref=ts" target="_blank"><img src={logoFacebook} className="social-media-img" /></a>
                                        <a href="https://twitter.com/yazigioficial" target="_blank"><img src={logoTwitter} className="social-media-img" /></a>
                                        <a href="https://www.youtube.com/user/yazigioficial" target="_blank"><img src={logoYoutube} className="social-media-img" /></a>
                                        <a href="https://open.spotify.com/user/yazigi.oficial/playlist/4jH6HlSiFQWq1neTmiyJe6" target="_blank"><img src={logoSpotify} className="social-media-img" /></a>
                                    </div>
                                </div>
                                <p className="allrights">YAZIGI / YConnect © 2019. All rights reserved.</p>
                                <p className="allrights">{`Build Info | date -> ${buildDate}`}</p>
                            </div>
                        </div>

                        <AccessibilityOptionsModal
                            isShow           = {menu}
                            contrast         = {contrast}
                            reading          = {reading}
                            onClose          = {() => setMenu(!menu)}
                            onChangeContrast = {(contrast: string) => onChangeContrast(contrast)}
                            onChangeReading  = {(reading: string) => onChangeReading(reading)} />

                    </div>
                </FooterTopStyle>
            </footer>
            <div className="YC-Footer-bottom">
                <div className="container">
                    <img src={logoPearson} alt="" />
                    <img src={always} alt="" />
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(Footer)

import * as React        from "react"
import {Container, Card, Button, Col} from "reactstrap"
import styled            from "styled-components"

import * as warning from "YConnect/Assets/svg/warning.svg"
import * as home    from "YConnect/Assets/svg/homeWhite.svg"

const getColor = () => {
	const theme = localStorage.getItem("contrast")
	return theme === "high" ? "#000" : "#fff"
}

const CardStyled = styled(Card) `
	background-color: ${getColor()};
	textarea {
		background-color: ${getColor()};
		color: red;
		height: 300px;
		padding: 25px;
	}
`

const ButtonStyle = styled(Button)`
	margin-bottom: 10px;
	background: #79b100;
    border: 2px solid #79b100;
    border-radius: 50px;
`
const ImageStyled = styled.img`
    width: 27px;
	margin-left: 10px;
`

const FrontErrorPage = ({stack}:any) => {

	const handleGoHome = () => {
		window.location.href = "/"
	}

	return <Container className="py-4">
				<CardStyled className="text-center p-3">
					<Col><img src={warning} className="mb-3"/></Col>
					<h1>Error</h1>
					<p>Sorry, something went wrong. Please go back, or contact support if needed.</p>
					<div>
						<ButtonStyle onClick={handleGoHome}>
							<strong>Go Home</strong>
							<ImageStyled src={home}/>
						</ButtonStyle>
					</div>
					<textarea>{stack}</textarea>
				</CardStyled>
			</Container>
}


class ErrorBoundary extends React.Component {

	constructor(props: any) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error: any) {
		return { hasError: true, stack: error.stack}
	}

	render() {
		const {state:{hasError, stack}} = this
		if (hasError) {
			return <FrontErrorPage stack={stack}/>
		}

		return this.props.children
	}
}

export default ErrorBoundary

import styled from "styled-components"

const FigureStyle = styled.figure`
    margin: 0;
    z-index: 9;

    @media screen and (min-width: 768px) {
        margin: 0 32px 0 0;
    }
`

export default FigureStyle
import * as React     from "react"
import { useContext } from "react"
import { Container }  from "reactstrap"
import qs             from "querystring"

import { BreadcrumbPrePostReport } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle   from "YConnect/Components/PageTitle"
import UserContext from "YConnect/User.context"

import TeacherReportContainer from "YConnect/PagesComponents/PrePostReport/Teacher.report/Report.container"
import PearsonReportContainer from "YConnect/PagesComponents/PrePostReport/Pearson.report/Report.container"
import SchoolReportContainer  from "YConnect/PagesComponents/PrePostReport/School.report/Report.container"

const PrePostReportPage = ({location, history}:any) => {
    
    const queryParams = qs.parse(location.search.substr(1))
	
	const handleChangeQueryParams = (newQueryParams:any) => {
        const search = qs.stringify(newQueryParams)
		history.push({search: `?${search}`})
    }
    

    const { profiles, estabsSupervisor }: any = useContext(UserContext)

    return <Container>
                <BreadcrumbPrePostReport/>
                <PageTitle label="Pre and post test" />
                {
                    profiles.indexOf(12) > -1 && estabsSupervisor.length 
                    && <h4>Select parameters above to visualize reports - <small>(*) Required fields</small></h4> 
                }
                {
                    profiles.indexOf(6) > -1
                        ? <PearsonReportContainer 
                            queryParams         = {queryParams}
                            onChangeQueryParams = {handleChangeQueryParams}/>
                        : profiles.indexOf(12) > -1
                            ? <SchoolReportContainer
                                queryParams         = {queryParams}
                                onChangeQueryParams = {handleChangeQueryParams}/>
                            : <TeacherReportContainer
                                queryParams         = {queryParams}
                                onChangeQueryParams = {handleChangeQueryParams} />
                             
                }
            </Container>
}

export default PrePostReportPage
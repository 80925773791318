import * as React from "react"

import { ButtonStyle } from "YConnect/Components/Button"
import Wrapper from "YConnect/Modals/OpenQuestion.modal/OpenQuestionWrapper.style"
import OpenQuestionTextArea from "YConnect/Fields/Exercises/OpenQuestion.field/OpenQuestionTextArea.field"

const OpenQuestionModalActions = ({visible, value, onChange, onCancel, onSubmit}:any) => {
  if (!visible) return null

    return (
      <div className="open-question__new-review">
        <h3>Teacher, add your review below:</h3>
        <OpenQuestionTextArea
          maxLength={1000} 
          onChange={({target}:any) => onChange(target.value)}
          rows={10}
          value={value}
        />
        <Wrapper className="open-question__new-review-actions">
          <ButtonStyle
            size="small"
            label="Cancel"
            onClick={onCancel}
          />
          <ButtonStyle
            green
            size="small"
            label="Submit"
            icon="save"
            disabled={value.length < 1}
            onClick={onSubmit}
          />
        </Wrapper>
      </div>
    )
}

export default OpenQuestionModalActions
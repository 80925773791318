import * as React   from "react"
import convertPhoto from "YConnect/Utils/ConvertPhoto.util"
import Tag          from "YConnect/Components/Tag"

const TripProgress = ({stamps, tags}: any) => <div className="trip-progress w-100">
    <h5 className="text-center mb-4 mt-3 font-weight-bold">Your trip progress</h5>
    <div className="home-tags d-flex flex-row justify-content-around mb-4">
        {
            stamps && stamps.map((stamp: any, key:any) => 
             <a key={key} href={`#/countries/${stamp.id_stamp}`}>
                <img key={stamp.id_stamp} src={convertPhoto(stamp.img_stamp)} alt={`Stamp from ${stamp.nome_pais}`} width="70" height="auto" />
            </a>)
        }
    </div>
    <hr />
    <div className="home-tags d-flex flex-row justify-content-around mt-5 mb-0">
        {
            tags && tags.map((tag: any, key: any) => <Tag key={key} tags={tag} />)
        }
    </div>
</div>

export default TripProgress


import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Col, Card, FormGroup} from "reactstrap"

import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"

import getStaticUrlAssets from "YConnect/Utils/GetStaticUrlAssets.util"
import ImageFilesModal from "YConnect/PagesComponents/CMS/Modals/ImageFiles.modal"

const ImageStyled = styled.img`
    width: 100%;
    height: auto;
`

type AvatarsEditorProps = {
    Order:number
    question: QuestionType
    onChange:any
}

const AvatarsEditor = ({
    Order,
    question,
    onChange
}:AvatarsEditorProps) => {


    const [isShowImageFilesModal, setShowImageFilesModal] = useState(false)

    const [newAvatar, setNewAvatar] = useState<AvatarType>()

    const [dialogueAvatar, setDialogueAvatar] = useState<DialogueAvatarType>()

    useEffect(() => {
        if(question && question.Dialogues[0]){
            const {DialogueAvatars} = question.Dialogues[0]
            if(DialogueAvatars){
                const currentAvatar = DialogueAvatars.find((dialogueAvatar) => dialogueAvatar.Order == Order)
                setDialogueAvatar(currentAvatar)
            }
        }

    }, [question])


    const handlChangeImageNewAvatar = ({Source, MediaType}:any) => {
        setNewAvatar({
            ...(newAvatar || (dialogueAvatar && dialogueAvatar.Avatar) || {}),
            Source
        })
        setShowImageFilesModal(false)
    }

    const handleOpenModalImageFiles = () => setShowImageFilesModal(true)
    const handleCloseModalImageFiles = () => setShowImageFilesModal(false)

    const handleChangeNameNewAvatar = (name:string) => {

        setNewAvatar({
            ...(newAvatar || (dialogueAvatar && dialogueAvatar.Avatar) || {}),
            Name:name
        })
    }

    const handleConfirmAvatar = () => {

        const dialogue0 = question.Dialogues[0] || {DialogueMessages:[], DialogueAvatars:[]}
        const {DialogueAvatars} = dialogue0

        const dialogueAvatarCurrent = DialogueAvatars.find((dialogueAvatar) => dialogueAvatar.Order == Order) || {Order}

        const newDialogue0 = {
            ...dialogue0,
            DialogueAvatars:[
                ...DialogueAvatars.filter((dialogueAvatar) => dialogueAvatar.Order != Order),
                {
                    ...dialogueAvatarCurrent,
                    Avatar:{
                        ...dialogueAvatarCurrent.Avatar,
                        Source:newAvatar.Source,
                        Name:newAvatar.Name
                    }}
            ]
        }

        const newValues= {
            ...question,
            Dialogues:[newDialogue0]
        }

        onChange(newValues)
        setNewAvatar(undefined)
    }


    const getAvatarName = () => {
        if(newAvatar && newAvatar.Name !== undefined){
            return newAvatar.Name
        }else if(dialogueAvatar && dialogueAvatar.Avatar && dialogueAvatar.Avatar.Name){
            return  dialogueAvatar.Avatar.Name
        }else{
            return ""
        }
    }

    return <>
            <Col md={12} className="mt-3">
                <h3 className="font-weight-bold">
                    Avatar {Order}
                </h3>
                <Row className="p-3" style={newAvatar || !dialogueAvatar ? {backgroundColor:"aliceblue"}:{}}>
                        {
                            (
                                !(newAvatar&& newAvatar.Source)
                                || !newAvatar
                            )
                            && !(dialogueAvatar && dialogueAvatar.Avatar && dialogueAvatar.Avatar.Source)
                            &&
                            <Col md="auto align-self-center">
                                <ButtonStyle
                                    size      = "small"
                                    onClick   = {handleOpenModalImageFiles}
                                    label     = "Choose Image"/>
                            </Col>
                        }
                        {
                            (
                                (newAvatar&& newAvatar.Source)
                                || (dialogueAvatar && dialogueAvatar.Avatar && dialogueAvatar.Avatar.Source)
                            )
                            && <Col md={2} className="align-self-center">
                                    <ImageStyled src={getStaticUrlAssets(newAvatar ? newAvatar.Source : dialogueAvatar.Avatar.Source)}/>
                                    <ButtonStyle
                                    className = "mt-3"
                                    size      = "small"
                                    onClick   = {handleOpenModalImageFiles}
                                    label     = "Choose Image"/>
                                </Col>
                        }
                        <Col className="align-self-center">
                            <FormGroup className="mb-0">
                                <label className="form-label">Name</label>
                                <input
                                    type="text"
                                    className={`form-control`}
                                    value={getAvatarName()}
                                    onChange={({target:{value}})=>handleChangeNameNewAvatar(value)}/>
                            </FormGroup>
                        </Col>
                        <Col md="auto align-self-center ">
                            <ButtonStyle
                                green
                                disabled  = {!(newAvatar && newAvatar.Name && newAvatar.Source) }
                                size      = "130"
                                onClick   = {handleConfirmAvatar}
                                label     = "Confirm"/>
                        </Col>
                </Row>

            </Col>
                {
                    isShowImageFilesModal
                    && <ImageFilesModal onSelect={handlChangeImageNewAvatar}
                    onClose={handleCloseModalImageFiles}/>
                }
            </>
}


export default AvatarsEditor

import axios       from "axios"
import * as qs     from "querystring"
import Toastify from "toastify-js"

import ENDPOINT_CONFIG from "YConnect/Configs/Endpoints2.config"
import getBaseURL      from "YConnect/Utils/GetBaseURL.util"

const _ENDPOINT:any = ENDPOINT_CONFIG
const Request:any = axios.create({baseURL:getBaseURL()+"/YConnectServer"})

//TODO: PARA TESTE
/*
const Request:any = axios.create({
    baseURL:"http://web01homolog3.pearsontc.com/YConnectServer"
})*/



const getURLPath = (url:string, parameters:Array<object>) =>
parameters && parameters.length > 0
? parameters
    .filter((parameter:any) => (parameter.in == "path"))
    .reduce((url:string, parameter:any) => url.replace(`:${parameter.name}`, parameter.value), url)
: url

const getURLQuery = (url:string, parameters:Array<object>) => {

    const newParameters = parameters && parameters
    .filter((parameter:any) => (parameter.in == "query" && parameter.value !== undefined))

    if(newParameters && newParameters.length > 0){
        const values = newParameters.reduce((values:any, {name, value, type}:any)=>{
            if(name){
                values[name] = value
                values[name] =  values[name] !== "{}"?values[name]:""
            }else if(type === "json")
                        return value
            return values
        }, {})
        return `${url}?${qs.stringify(values, null, null, null)}`
    }else
        return url
}

const getURL = (url:string, parameters:Array<object>) => {
    return getURLQuery(getURLPath(url, parameters), parameters)
}

const getParametersWithData = (parameters:Array<any>, data:any) => {
    return parameters && parameters.reduce((acc, parameter:any)=>{
        let newParamenter = {...parameter}
        if(data[parameter.name] !== undefined)
          newParamenter = {...newParamenter, value:data[parameter.name]}

        if(parameter.type === "json")
            newParamenter = {...newParamenter, value:data}

        return [...acc, newParamenter]
    }, [])
}

const processError = (requestInfo:any) => (error:any) => {

    const {service, summary, method, url, parameters} = requestInfo
    const {response} = error

    if(response && response.status == 401){
        if(
            service!=="Auth"
            && summary!=="CheckTokenPassword" 
            && summary!=="ResetPassword"
            ){
            
            Toastify({
                text: "Error! <br> Session expired",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();

            localStorage.removeItem("Authorization")
            localStorage.removeItem("data")
            localStorage.removeItem("contrast")
            localStorage.removeItem("reading")
            localStorage.removeItem("prepost")
            window.location.href = "#/login"
        }else throw error

    } else if(response && response.status === 500 && service!=="Note" && summary!=="Get2"){
        window.location.href = "#/server-error"

        //JSON.parse(localStorage.getItem("data"))
/*

        console.log(response)
        console.log(error)
        console.log(requestInfo)

        const eventLog = {
            timestamp: (new Date()).getTime(),
            type:"SERVER_ERROR",
            payload:{
                statusCode:response.status,
                url:response.config.url,
                requestInfo,
                data:response.data
            }

        }
*/
        console.log(eventLog)
        throw error
    }else throw error
}

const getRequest = (
    {
        service, summary, method, url, parameters
    }:{
        service:string, summary:string, method:string, url:string, parameters:Array<object>
    }) => {
    return (data:object, config?:object) => {
        const parametersWithData = getParametersWithData(parameters, data)
        const bodyValues = parametersWithData && parametersWithData
        .filter((parameter:any) => (parameter.in == "body"))
        .reduce((bodyValues, {name, value, type}:any)=>{
            if(name)
                bodyValues[name] = value
            else if(type === "json") return value
            return bodyValues
        }, {})

        if(method.toLowerCase() === "delete")
            return Request[method.toLowerCase()](getURL(url, parametersWithData), {...config, data:bodyValues}).catch(processError({service, summary, method, url, parameters}))
        else if(method.toLowerCase() === "get")
        return Request[method.toLowerCase()](getURL(url, parametersWithData), config).catch(processError({service, summary, method, url, parameters}))
        else
            return Request[method.toLowerCase()](getURL(url, parametersWithData), bodyValues, config).catch(processError({service, summary, method, url, parameters}))
    }
}

const LIST_ENDPOINT:Array<any> = Object.keys(_ENDPOINT)
.reduce(
    (acc, serviceName:string)=>([...acc, ..._ENDPOINT[serviceName].map((endpoint:any) => ({service:serviceName, ...endpoint}))]),
[])

const reduceService = (
    services:any,
    {url, method, service, summary, parameters}
    :{
        url:string,
        method:string,
        service:string,
        summary:string,
        parameters:Array<object>
    }) => {
        if(!services[service])
            services[service] = {}

        services[service][summary] = getRequest({service, summary, method, url, parameters})
        return services
}

export default LIST_ENDPOINT.reduce(reduceService, {})

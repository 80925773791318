import * as React from "react"
import { useState, useEffect, useContext } from "react"

import Table from "YConnect/Components/Table"
import API from "YConnect/API"
import SelectFieldStyle from "YConnect/Styles/SelectField.style"
import Loader from "YConnect/Components/Loader"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

import CONFIG_TABLE_REPORT_FINAL_SCORE, { CONFIG_TABLE_FINAL_SCORE } from "YConnect/Configs/Tables/FinalScore.table.config"
import CONFIG_TABLE_REPORT_FINAL_SCORE_STUDENT from "YConnect/Configs/Tables/FinalScoreStudent.table.config"
import CONFIG_TABLE_REPORT_RESOURCE_BOOK from "YConnect/Configs/Tables/ReportUnitStudentResourceBook.table.config"
import UnitType from "YConnect/Enums/UnitType"

import UserContext from "YConnect/User.context";

import ModalScoreFinal from "YConnect/Modals/CalculateFinalScore.modal"
import Toastify from "toastify-js"

const idCursoStarter = 67

const getUnitSelectData = (idCourse: string) => {
    if (parseInt(idCourse, 10) === 64) {
        return Array.from(Array(6).keys()).map(i => ({ id: i + 1, value: `Unit ${i + 1}` }))
    }
    return Array.from(Array(8).keys()).map(i => ({ id: i + 1, value: `Unit ${i + 1}` }))
}


const FinalScore = ({ idGrupo, idCurso, resourceBook, isMultinivel, studentsSeriados, idAluno = false, idMatricula }: any) => {
    const [hasLoading, setHasLoading] = useState(true)
    const [report, setReport] = useState<any>()
    const [tableData, setTableData] = useState([])
    const [tableDataFinal, setTableDataFinal] = useState([])
    const [unitParam, setUnitParam] = useState<any>(1)

    const [students, setStudents] = useState()
    const [studentParam, setStudentParam] = useState()
    const [originalReport, setOriginalReport] = useState<any>()
    const [resourceBookUnits, setResourceBookUnits] = useState();
    const [dataFinalTest, setDataFinalTest] = useState<any>();
    const [tableDataFinalFiltered, setTableDataFinalFitered] = useState<any>();
    const [dataFinalTestList, setDataFinalTestList] = useState<any>();

    const [modalFinalTestOpen, setModalFinalTestOpen] = useState(false);

    const [dataStudentModal, setDataStudentModal] = useState('')

    const [flagStudent, setFlagStudent] = useState(false)

    const cursoStarter: boolean = parseInt(idCurso, 10) === idCursoStarter
    const { idUsuario }: any = useContext(UserContext);

    useEffect(() => {
        if(idAluno) {
            getPontuacaoFinal(idCurso, idAluno)
            setHasLoading(false)
        }
        else {
            getPontuacaoFinalTurma(idGrupo, idCurso)
            fetch()
        }
    }, [unitParam, flagStudent])

    useEffect(() => {
        if (report) {
            let newDataTable = report.map(({ id_usuario, nome_aluno, id_curso, nome_curso, reportUnidade }: any) => ({
                id_aluno: id_usuario,
                idCurso,
                id_curso,
                nome_curso,
                nome_aluno,
                ...reportUnidade[0]
            }))
            API.Aluno.GetAlunoCurso({idTurma: idGrupo},getConfig())
            .then((res:any)=>{
              let clearList:any = [];
              newDataTable.forEach((elem:any)=>{
                let aluno = res.data.filter((x: any) => x.id_aluno == elem.id_aluno);
                elem.idCurso = aluno[0].id_curso;
                elem.nome_curso = aluno[0].nome_curso;
                clearList.push(elem);
                setTableData(clearList)
                setOriginalReport(clearList)
                findUnits()
              })
            })
        }
    }, [report])

    const findUnits = async () => {
        const units = await API.Course.getById(
            {
                id_curso: idCurso,
                id_usuario: idUsuario,
                isDegustacao: false,
            },
            getConfig()
        );
        setUnitsData(units.data, UnitType.RESOURCE_BOOK, setResourceBookUnits)
    }

    const setUnitsData = (units: Array<any>, unitType: string, setUnitFn: Function) => {
        const filteredUnits = units.filter((u: any) => u.tipo_unidade === unitType)
        const convertedList: any = [];
        filteredUnits.map((element, key) => {
            convertedList.push({ id: (key+1), value: `Unit ${element.ordem}` })
        })
        setUnitFn(convertedList)
    }

    useEffect(() => {
        if (dataFinalTest) {

            loadStudents()
            // setModalFinalTestOpen(true)
        }
    }, [dataFinalTest, flagStudent]);

    const loadStudents = async () => {
        let value = dataFinalTest.map((key: any) => {
            return {
                ...key,
                editFunction: loadInfo,
                updateOT: onUpdate
            }
        })
        setTableDataFinalFitered(value)
        setTableDataFinal(value)
    }

    const getPontuacaoFinal = async (id_curso: any, id_aluno_final: any) => {
        const finalTestScore = await API.AssessmentFinalTest.GetNotasFinal(
            {
                id_curso: id_curso,
                id_aluno_nota_final: id_aluno_final,
                id_matricula: idMatricula
            },
            getConfig()
        )
        setDataFinalTestList(finalTestScore.data)
        setHasLoading(false)
    }

    const getPontuacaoFinalTurma = async (id_turma_final: any, id_curso: number) => {
        const finalTestScore = await API.AssessmentFinalTest.GetNotasFinalTurma(
            {
                id_curso: id_curso,
                id_turma: id_turma_final
            },
            getConfig()
        );
        setDataFinalTest(finalTestScore.data)
    }

    const loadInfo = (dataModal: any) => {
        setDataStudentModal(dataModal)
        setModalFinalTestOpen(true)
    }


    const onUpdate = () => {
        setModalFinalTestOpen(true)
        setFlagStudent(false)
    }

    const openModal = () => {
        setModalFinalTestOpen(true)
    }

    const handleUpdateOTGrade = async (score: any, idProva: any) => {
        await API.AssessmentFinalTest.PostAtualizaPontuacao(
            {
                idProvaFinal: idProva,
                pontuacao: score,
                tipo: 'OT'
            }, getConfig())
        .then(() => {
            if(idAluno) {
                getPontuacaoFinal(idCurso, idAluno)
            }
            else {
                getPontuacaoFinalTurma(idGrupo,idCurso)
            }
        })
    }

    const fetch = async () => {
        if(isMultinivel) {
            const paramForFetch = {
                id_turma: idGrupo,
                id_unidade: unitParam,
                tipo_unidade: resourceBook ? "RESOURCE_BOOK" : "BOOK_TASK",
                id_curso: idCurso
            }
            try {
                setHasLoading(true)
                const { data } = await API.Report.GetByUnitMultiseriado(paramForFetch, getConfig())

                const newAlunos = studentsSeriados.map((aluno: any, i: number) => ({ ...aluno, ...data[i] }))
                setReport(newAlunos)
                const uniqueStudents = data.reduce((unique: any, o: any) => {
                    if (!unique.some((obj: any) => obj.nome_aluno === o.nome_aluno)) {
                        unique.push({ id: o.id_usuario, value: o.nome_aluno });
                    }
                    return unique;
                }, []);

                setStudents(uniqueStudents)
            } catch (e) {
                const { response } = e
                if (response && response.data) {
                    Toastify({
                        text: response.data.message,
                        duration: 3000,
                        className: "toastify-error",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }
            } finally {
                setHasLoading(false)
            }
        }

        else {
            const paramForFetch = {
                idCurso,
                id_turma: idGrupo,
                id_unidade: unitParam,
                tipo_unidade: resourceBook ? "RESOURCE_BOOK" : "BOOK_TASK"
            }
            try {
                setHasLoading(true)
                const { data } = await API.Report.GetByUnit(paramForFetch, getConfig())
                setReport(data)
                const uniqueStudents = data.reduce((unique: any, o: any) => {
                    if (!unique.some((obj: any) => obj.nome_aluno === o.nome_aluno)) {
                        unique.push({ id: o.id_usuario, value: o.nome_aluno });
                    }
                    return unique;
                }, []);
                setStudents(uniqueStudents)
            } catch (e) {
                const { response } = e
                if (response && response.data) {
                    Toastify({
                        text: response.data.message,
                        duration: 3000,
                        className: "toastify-error",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }
            } finally {
                setHasLoading(false)
            }
        }
    }

    const handleChangeFilter = (keystone: string, value: any) => {
        const sets: any = {
            unit: setUnitParam
        }
        if (sets[keystone]) sets[keystone](value)
    }

    const handleFilterStudent = (value: any, keystone: any) => {
        const filterById = (student: any) => student.id_aluno === parseInt(keystone, 10);
        if (keystone === "") {
            setTableData(originalReport)
            setTableDataFinalFitered(dataFinalTest)
        } else {
            setTableData(originalReport.filter(filterById))
            setTableDataFinalFitered(dataFinalTest.filter(filterById))
        }
    }

    return <div className="score">
        {
            report && report.length > 0 && <header className="score-header ">
                <div className="w-100 d-flex justify-content-end">
                    <div className="score-filter">
                        <span className="filter-label  pr-5">Filter by</span>
                        {/* {resourceBook ?
                            <SelectFieldStyle
                                keystone="unit"
                                label="Unit"
                                value={unitParam}
                                onChange={handleChangeFilter}
                                values={resourceBookUnits}
                                hasOptionEmpty={true} />
                            : !cursoStarter &&
                            <SelectFieldStyle
                                keystone="unit"
                                label="Unit"
                                value={unitParam}
                                onChange={handleChangeFilter}
                                values={getUnitSelectData(idCurso)}
                                hasOptionEmpty={true} />
                        } */}
                        <SelectFieldStyle
                            keystone="student"
                            label="Student"
                            value={studentParam}
                            onChange={handleFilterStudent}
                            values={students}
                        />
                        <ModalScoreFinal
                            hasShow={modalFinalTestOpen}
                            onClose={() => setModalFinalTestOpen(!modalFinalTestOpen)}
                            data={tableDataFinal}
                            onUpdate={handleUpdateOTGrade}
                            dataModal={dataStudentModal}
                            teste={onUpdate}
                            flag={setFlagStudent}
                        />
                    </div>
                </div>
            </header>
        }
        {
            !idAluno && report && report.length > 0
                ? <Table
                    data={tableDataFinalFiltered}
                    config={cursoStarter ? (CONFIG_TABLE_FINAL_SCORE) : resourceBook ? CONFIG_TABLE_REPORT_RESOURCE_BOOK : CONFIG_TABLE_REPORT_FINAL_SCORE}
                />
                : <p className="text-center m-3"></p>
        }
        {
            dataFinalTestList && dataFinalTestList.length > 0 &&
                <Table
                    data={dataFinalTestList}
                    config={cursoStarter ? (CONFIG_TABLE_REPORT_FINAL_SCORE_STUDENT) : resourceBook ? CONFIG_TABLE_REPORT_RESOURCE_BOOK : CONFIG_TABLE_REPORT_FINAL_SCORE_STUDENT}
                />
        }
        {hasLoading && <Loader />}
    </div>
}

export default FinalScore

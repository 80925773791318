import * as React from "react"
import styled from "styled-components"

import SelectFieldNewStyle from "YConnect/Components/SelectFieldNew.style"

const Filters = ({
    isLoading,
    filterProps
}:any) => {

    const HeaderFilterStyle = styled.header`
        display: flex;
        flex-flow: row wrap;
    `

    return <HeaderFilterStyle>
                {isLoading && <div className="loader"></div>}
                {
                    filterProps
                    .map((props:any, key:number) => 
                        React.createElement(SelectFieldNewStyle, {
                            key, 
                            ...props, 
                            disabled: isLoading || props.disabled
                        }, null))
                }
            </HeaderFilterStyle>
}

export default Filters

import * as React from "react"

import { ButtonStyle, YLinkButtonStyle } from "YConnect/Components/Button"
import ImagesBook from "YConnect/Utils/ImagesBook.util"
import { useState, useEffect } from "react"

import * as CourseResource  from "YConnect/ResourcesBackEnd/Course.resources"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"

//done TODO EDUARDO trocar ImagesBook para API.Book.GetCover({idCurso})


const RecommendedCard = ({ user, history, exercise, isLoadingExercise }: any) => {

    const [bookCoverUrl, setBookCoverUrl] = useState("")

    useEffect(() => {
        console.log("user.cursoAtual");

        fetchCapaDeLivro()

    }, [])

    const responseHandler = (response: any) => {
        const hasBookCover = response.data.capas[0] ? true : false
        if (hasBookCover) return response.data.capas[0].url_capa
        return ""
    }

    const fetchCapaDeLivro = () : void => {
        const idCourseExists = user.cursoAtual ? true : false
        if(!idCourseExists) return null
        CourseResource
        .GetCover(user.cursoAtual)
        .then((response:any) => {

            const capa_do_livro = responseHandler(response)
            const urlCoverBookCMS = getStaticUrl(capa_do_livro)
            setBookCoverUrl(urlCoverBookCMS)
        })
    }

    const redirectPage = (course: any) => {
        history.push({
            pathname: `/courses/${course.id_curso}/view-book`,
            state: {
                course
            }
        });
    }

    const getBookInfo = () => {
        if (user.cursoAtual && user.cursoAtual[0].length < 1 && user.profiles.indexOf(11) > -1) {
            return <div className="courses card shadow mb-4 recommended-card">
                <header className="courses-header d-flex flex-row justify-content-start">
                    <div className="courses-header-title">
                        <span className="text-muted">Recommended for you:</span>
                        <span className="h5 font-weight-bold">
                            You are not in any course yet!
                        </span>
                    </div>
                </header>
            </div>
        }
        return window.innerWidth < 1024 ?
        <div className="courses card shadow mb-4 recommended-card">
            <header className="courses-header d-flex flex-row justify-content-start">
                <figure className="m-0">
                    <img src={`${ bookCoverUrl /* ImagesBook[user.cursoAtual && user.cursoAtual[0] && user.cursoAtual[0].id_curso] */}`} />
                </figure>
                <div className="courses-header-title pl-3">
                    <span className="text-muted">Recommended for you:</span>
                    <span className="h5 font-weight-bold">
                        {user.cursoAtual && user.cursoAtual.length > 0 && user.cursoAtual[0].titulo_curso}
                    </span>
                </div>
            </header>
            <div className="course-info mb-3 mt-3">
                <p className="course-description mt-4 font-weight-bold">
                {user.cursoAtual && user.cursoAtual.length > 0 && user.cursoAtual[0].descr_curso}
                </p>
                <YLinkButtonStyle
                    size={'large'}
                    link={`/courses/${user.cursoAtual && user.cursoAtual[0].id_curso}/view-book`}
                    label="Go to course"
                    icon="arrowRightGreen"
                />
                {isLoadingExercise ?
                    <span className="mt-3">Loading...</span>
                    :
                    exercise ?
                        <YLinkButtonStyle
                            green
                            className="mt-2"
                            label={`Keep Practicing (Unit ${exercise.ordem_unidade})`}
                            icon="arrowRight"
                            link={`/courses/${user.idCursoAtual}/unit/${exercise.id_unidade}/view-book/${exercise.secao}/${exercise.ordem_task}/exercise/${exercise.parte_atual}`}
                            size={'large'}
                        />
                        :
                        ''
                }
            </div>
        </div>
        :
        <div className="courses courses-desktop card shadow mb-4 recommended-card">
            <figure className="m-0">
                <img src={`${ bookCoverUrl /* ImagesBook[user.cursoAtual && user.cursoAtual[0] && user.cursoAtual[0].id_curso] */}`} />
            </figure>
            <div className="courses-info">
                <span className="text-muted">Recommended for you:</span>
                <span className="h5 font-weight-bold">
                    {user.cursoAtual && user.cursoAtual.length > 0 && user.cursoAtual[0].titulo_curso}
                </span>
                <p className="course-description mt-4 font-weight-bold">
                    {
                        user
                        && user.cursoAtual && user.cursoAtual[0]
                        && (
                            user.cursoAtual[0].descr_curso > 100
                            ? `${user.cursoAtual[0].descr_curso.slice(0,100)}...`
                            : user.cursoAtual[0].descr_curso
                        )
                    }
                </p>
                <div className="d-xl-flex flex-xl-row">
                    <ButtonStyle
                        label="Go to course"
                        icon="arrowRightGreen"
                        onClick={() => redirectPage(user.cursoAtual && user.cursoAtual[0])}
                        size={'small'}
                    />
                    {isLoadingExercise ?
                        <span className="mt-3 mt-xl-2 ml-xl-5">Loading...</span>
                        :
                        exercise ?
                            <ButtonStyle
                                green
                                className="ml-xl-1 mt-2 mt-xl-0"
                                label={`Keep Practicing (Unit ${exercise.ordem_unidade})`}
                                icon="arrowRight"
                                onClick={() => history.push({
                                    pathname: `/courses/${user.idCursoAtual}/unit/${exercise.id_unidade}/view-book/${exercise.secao}/${exercise.ordem_task}/exercise/${exercise.parte_atual}`
                                })}
                                size={'medium'}
                            />
                        :
                        ''
                    }
                </div>
            </div>

        </div>
    }

    return getBookInfo()
}

export default RecommendedCard

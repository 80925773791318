import * as React from "react"


const SelectField = ({
	keystone,
	required,
	label,
	value,
	className,
	disabled,
	values,
	messageBlocker,
	id,
	onChange,
	hasOptionEmpty,
	isInvalid
}:SelectFieldPropsType) => {

	const handleChange = (event: any) => {
		const { target: { value } } = event
		onChange(keystone, value)
	}

	return <div className={`${className || ""}`}>
				<div className={`form-group ${messageBlocker ? '' : 'mb-4'} ${disabled ? 'input-disabled' : ''}`}>
					{(label) ? <label className="form-label label-select">{`${label}${required ? "*" : ""}`}</label> : ""}
					<select
						className = {`form-control ${isInvalid === true && "isInvalid"}`}
						style     = {{alignItems: "flex-end", minWidth:"100px"}}
						id        = {`${id ? id: keystone}`}
						disabled  = {disabled}
						value     = {value && value.id || value}
						onChange  = {handleChange.bind(this)}>
						{!hasOptionEmpty && <option/>}
						{values && values.map(({id, value}, key) => <option key={key} value={id}>{value}</option>)}
					</select>
				</div>
				{(messageBlocker) ? <small className="form-text text-muted mb-3 text-clickable">{messageBlocker}</small> : ''}
			</div>
}

export default SelectField

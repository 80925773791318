import * as React            from "react"
import {useState, useEffect} from "react"
import {Card, Row, Col}      from "reactstrap"

import styled from "styled-components"

import {ButtonStyle}      from "YConnect/Components/Button"
import Table              from "YConnect/Components/Table"

import FormModal   from "YConnect/PagesComponents/ControlPanel/Components/Form.modal"
import RemoveModal from "YConnect/PagesComponents/ControlPanel/Components/Remove.modal"
import ReorderModal from "YConnect/PagesComponents/ControlPanel/Components/Reorder.modal"

import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

type PanelCrudProps = {
    idName            : string
    labelButtonCreate : string
    labelForm         : string
    template          : any
    configTable       : any
    selectData?       : any
    GetAll            : any
    paramGetAll      ?: any
    paramType        ?: any
    GetById           : any
    Create            : any
    paramCreate      ?: any
    UpdateById        : any
    DeleteById        : any
}

const DragableDiv = styled.table`
    .enable-link {
        pointer-events: visible;
        cursor: pointer;

        span {
            position: absolute;
            margin-left: -13px;
            margin-top: 13px;
            padding: 10px 0;
            border-radius: 3px;

            &::before {
                position: relative;
                top: 1px;
                left: 10px;
                display: block;
                width: 20px;
                height: 15px;
                margin-right: 20px;
                cursor: row-resize;
                background: -webkit-linear-gradient(top, ${props => props.theme.main.textColor}, ${props => props.theme.main.textColor} 20%, ${props => props.theme.main.backgroundPage} 0, ${props => props.theme.main.backgroundPage} 40%, ${props => props.theme.main.textColor} 0, ${props => props.theme.main.textColor} 60%, ${props => props.theme.main.backgroundPage} 0, ${props => props.theme.main.backgroundPage} 80%, ${props => props.theme.main.textColor} 0, ${props => props.theme.main.textColor});
                background: linear-gradient(180deg,${props => props.theme.main.textColor},${props => props.theme.main.textColor} 20%,${props => props.theme.main.backgroundPage} 0,${props => props.theme.main.backgroundPage} 40%,${props => props.theme.main.textColor} 0,${props => props.theme.main.textColor} 60%,${props => props.theme.main.backgroundPage} 0,${props => props.theme.main.backgroundPage} 80%,${props => props.theme.main.textColor} 0,${props => props.theme.main.textColor});
                content: "";
            }
        }
    }
`;

const PanelCrud = ({
    idName,
    labelButtonCreate,
    labelForm,
    template,
    configTable,
    selectData,
    GetAll,
    paramGetAll,
    paramType,
    GetById,
    Create,
    paramCreate,
    UpdateById,
    DeleteById,
}:PanelCrudProps) =>{
    const CREATE = "CREATE";
    const EDIT = "EDIT";
    const EXTRA_SUPPORT = "EXTRA_SUPPORT";
    const FINAL_TEST = "FINAL_TEST";
    const UNIT = "Unit";
    const SECTION = "Section";
    const [num, setNum]                           = useState<any>();
    const [mode, setMode]                         = useState<any>();
    const [listRecord, setListRecord]             = useState<Array<any>>()
    const [isShowModalForm, setShowModalForm]     = useState<boolean>(false)
    const [isShowModalRemove, setShowModalRemove] = useState<boolean>(false)
    const [idSelected, setIdSelected]             = useState()
    const [nameSelected, setNameSelected]         = useState()
    const [isLoading, setLoading]                 = useState<boolean>(false)

    const [state, setState] = useState([])
    const [modalDrag, setModalDrag] = useState(false)

    useEffect(()=>{
        if(!listRecord && (paramType != undefined || paramType != "")){
            setLoading(true)
            GetAll(paramGetAll, paramType)
            .then((response:any) => {
                const {data} = response
                setListRecord(data)
                setState(data)
            })
            .finally(() => setLoading(false))
        } else {
            setNum(listRecord.length)
        }
    }, [listRecord])



    useEffect(()=>{
        reload()
    }, [paramType, paramGetAll])

    useEffect(()=>{
        if(isShowModalForm === false && idSelected) setIdSelected(undefined)
    }, [isShowModalForm])

    useEffect(()=>{
        if(isShowModalRemove === false && idSelected) setIdSelected(undefined)
    }, [isShowModalRemove])

    const defineMode = (bool:boolean, modeOp:string) => {
      setShowModalForm(bool);
      setMode(modeOp);
    }

    const handleEdit = (Id:any, modeOp:string) => {
        setIdSelected(Id)
        setMode(modeOp);
        setShowModalForm(true)
    }

    const handleRemove = (Id:any, Name:any) => {
        setIdSelected(Id)
        setNameSelected(Name)
        setShowModalRemove(true)
    }

    const reload = () => {
        setListRecord(undefined)
        setState(undefined)
        setIdSelected(undefined)
        setShowModalForm(false)
        setShowModalRemove(false)
        setModalDrag(false)
    }

    const handleCreated = () => reload()
    const handleUpdated = () => reload()
    const handleRemoved = (Id:any) => {
      reload()
      var index = 1;
      var clearList:any = [];
      listRecord.forEach(element => {
        if(element.Id != idSelected){
          element.Order = index;
          clearList.push(element);
          index += 1;
        }
      });
      if(labelForm == UNIT){
        let paramUpdate = {"listUnit": clearList, "unitId": paramGetAll};
        UpdateById({...paramUpdate?paramUpdate:{}})
        .then((response:any) => {
          reload();
        })
      } else if (labelForm == SECTION) {
        // let paramUpdate = {"listSection": clearList, "sectionId": paramGetAll};
        // UpdateById({...paramUpdate?paramUpdate:{}})
        // .then((response:any) => {
        //   reload();
        // })
      }
    }

    var index = 1;
    var clearList:any = [];

    const SortableItem = SortableElement(({ value, sortIndex }) => {
        if (sortIndex + 1 !== value.Order && paramType !== "FINAL_TEST" && paramType !== "EXTRA_SUPPORT") {
            value.Order = sortIndex + 1;
            clearList.push(value);
            setModalDrag(true)
        }

        return (
                <tr className="table-line" style={{height: '67px'}}>
                    <div className="enable-link">
                        <span />
                    </div>
                    <td>{value.Name}</td>
                    <td>{value.Order}</td>
                    {
                        configTable.map((key) => {
                            if(key.label == 'UnitType') {
                                return ( <td scope="col">{value.UnitType}</td> )
                            }
                        })
                    }
                    <td>{value.Id}</td>
                    <Row className="px-0" style={{marginTop: '11px'}}>
                        <ButtonStyle
                            title     = {`Edit record ${value.Id}`}
                            size      = "forTable"
                            className = "mr-2"
                            onClick   = {() => handleEdit(value.Id, EDIT)}
                            icon      = "pencilGreen"/>
                        <ButtonStyle
                            title     = {`Remove record ${value.Id}`}
                            size    = "forTable"
                            onClick = {() => handleRemove(value.Id, value.Name)}
                            icon    = "remove" />
                    </Row>
                </tr>
        )
    })

    const SortableList = SortableContainer(({ children }) => {

        return (
                <DragableDiv className="table">
                    <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Order</th>
                                {
                                    configTable.map((key) => {
                                        if(key.label == 'UnitType') {
                                            return ( <th scope="col">UnitType</th> )
                                        }
                                    })
                                }
                                <th scope="col">Id</th>
                                <th></th>
                            </tr>
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </DragableDiv>
        )
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setState(arrayMove(state, oldIndex, newIndex));
    };

    return <div>
            {((paramType == FINAL_TEST || paramType == EXTRA_SUPPORT) && (listRecord && listRecord.length == 0)) &&
              <ButtonStyle
              onClick={() => defineMode(true, CREATE)}
              label={labelButtonCreate}
              icon="plusGreen"
              className="mb-3"
              size="xlarge"/>
            }
            {(paramType != FINAL_TEST && paramType != EXTRA_SUPPORT) &&
              <ButtonStyle
              onClick={() => defineMode(true, CREATE)}
              label={labelButtonCreate}
              icon="plusGreen"
              className="mb-3"
              size="xlarge"/>
            }

            {
                listRecord && state &&
                    <Card className="px-4 pt-4 pb-0 mb-3">
                            <SortableList onSortEnd={onSortEnd} distance={2}>
                                {state.map((data: Array<any>, key: number) => {
                                        return (
                                            <SortableItem key={`item-${key}`} index={key} sortIndex={key} value={data} />
                                        )
                                })}
                            </SortableList>
                    </Card>
            }

            <FormModal
                idName      = {idName}
                labelForm   = {labelForm}
                template    = {template}
                selectData  = {selectData}
                Id          = {idSelected}
                show        = {isShowModalForm}
                onUpdated   = {handleUpdated}
                onCreated   = {handleCreated}
                onHide      = {() => setShowModalForm(false)}
                GetById     = {GetById}
                Create      = {Create}
                paramCreate = {paramCreate}
                UpdateById  = {UpdateById}
                mode        = {mode}
                num         = {num}
                type        = {paramType}/>

            <RemoveModal
                Id           = {idSelected}
                Name         = {nameSelected}
                show         = {isShowModalRemove}
                onRemoved    = {handleRemoved}
                onHide       = {() => setShowModalRemove(false)}
                DeleteById   = {DeleteById}
            />

            <ReorderModal
                Id           = {idSelected}
                Name         = {nameSelected}
                List         = {state}
                params       = {paramGetAll}
                label        = {labelForm}
                show         = {modalDrag}
                onUpdated   = {handleUpdated}
                UpdateById   = {UpdateById}
                onHide       = {() => setModalDrag(false)}
                reload       = {reload}
            />
            </div>
}

export default PanelCrud

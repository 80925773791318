import * as React from "react"
import { useState, useEffect } from "react"
import { Container } from "reactstrap"

import { BreadcrumbViewFullReportResourceBook, addBreadcrumbData } from "YConnect/Components/BreadCrumbList.tsx"
import ViewReportResourceBookContainer from "YConnect/Containers/ViewReportResourceBook.container"

const ViewFullReportResourceBookPage = ({ match }: any) => {
  console.log(match);
    const [reportAluno, setReportAluno] = useState()
    const { idCurso, idAluno } = match.params

    const handleChangeReportAluno = (reportAluno: any) => setReportAluno(reportAluno)

    addBreadcrumbData([{ page: 'ViewFullReportResourceBookPage', data: { idAluno, idCurso, reportAluno } }])

    return <Container>
        {reportAluno && <BreadcrumbViewFullReportResourceBook reportAluno={reportAluno} />}

        <ViewReportResourceBookContainer
            idCurso={idCurso}
            idAluno={idAluno}
            onChangeReportAluno={handleChangeReportAluno} />
    </Container>

}


export default ViewFullReportResourceBookPage

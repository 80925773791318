import * as React from "react"
import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg"

import { DropdownButton, Dropdown, Button } from "react-bootstrap"

import * as edit       from "YConnect/Assets/svg/edit.svg"
import * as eyes       from "YConnect/Assets/svg/eyes.svg"
import * as reloadGrey from "YConnect/Assets/svg/reloadGrey.svg"


const CONFIG_TABLE_LAST_SEARCHES:Array<ColumnConfigType> = [
    {
        formatter : ({nome, sobrenome}: any) =>`${nome} ${sobrenome}`,
        label    : "Name"
    },
    {
        keystone : "usuario",
        label    : "Username",
    },
    {
        keystone : "email",
        label    : "E-mail",
    },
    // {
    //     keystone : "profile",
    //     label    : "Profile",
    // },
    {
        keystone : "titulo_curso",
        label    : "Course",
    },
    {
        keystone : "nome_grupo",
        label    : "Class"
    },
    {
        label     : "Details",
        formatter : (user:any) => {
        return <a href={`#/reports/view-full-report/${user.cursos}/${user.id}`}>View Report</a>}
    }

]

export default CONFIG_TABLE_LAST_SEARCHES

import * as React from "react"
import { useState, useEffect, useContext } from "react"

import ProgressBar from "YConnect/Components/ProgressBar"
import API         from "YConnect/API"
import UserContext from "YConnect/User.context"
import getConfig   from "YConnect/Utils/GetRequestConfig.util"
import getUnitName from "YConnect/Utils/CourseUnitName.util"

const Item = ({report}: any) => <div className="col-xs-12 col-md-4">
        <p className="font-weight-bold m-0 mt-3">
            {report.curso}
            <br/>
            {getUnitName(report.idcurso, report.unidade, report.nomeUnidade)}
        </p>
        <span className="h1">
            {report.concludedFirstTry > report.concludedLastTry
            ? report.concludedFirstTry
            : report.concludedLastTry}%
        </span>
        <small className="d-block">sections concluded</small>
            <ProgressBar 
                percent={report.concludedFirstTry > report.concludedLastTry ? report.concludedFirstTry : report.concludedLastTry} 
                className="w-100" />
        <p className="font-weight-bold">
            Grade:
            {report.finalScore ? report.finalScore : report.scoreFirstAttempt}
        </p>
    </div>

interface ReportCardProps { className?: string}

const ReportCard = ({className}:ReportCardProps) => {

    const {idUsuario}:any = useContext(UserContext)

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState<any>({});

    useEffect(() => {
        
        fetchData();

    }, []);

    const fetchData = async () => {

        setLoading(true);

        try {
            const result = await API.Report.GetByStudent({id_aluno : idUsuario, resource_book: false }, getConfig());
            setReports(result.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return <div className={`card shadow p-4`}>
        {   
            loading
            ? <p>Loading...</p>
            : <>
                <h5 className="font-weight-bold">
                    Report card: Your recent grades
                </h5>
                <div className="row">
                    {
                        reports && reports.lastUnits && reports.lastUnits.length > 0
                        ? reports.lastUnits.map((report: any, key: any) => <Item report={report} key={key}/> )
                        : <p className="pl-3 pr-3"> No scores saved yet!</p>
                    }
                </div>
            </>
        }
    </div>
}

export default ReportCard
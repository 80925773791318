import * as React from "react"

import {Row, Col} from "reactstrap"
import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"

import { connect }            from "react-redux"
import { bindActionCreators } from "redux"

const ButtonAdvancedStyled = styled.button`
    font-size:medium;
    border: 2px solid #79b100;
    background: ${props => props.actived ? "#79b100":"#fff"};
    color: ${props => !props.actived ? "#79b100":"#fff"};
    padding: 5px 5px 5px 5px;
    font-weight:bold;

    &:hover {
        background: #f0f5f6;
        color: #79b100!important;
    }
`


const ButtonRotatedIcon = styled.div`
    transform: rotate(180deg);
`

type QuestionCardHeaderProps = {
    questionOrder           : number
    listTemplates           : Array<TemplateType>
    templateIdSelected      : number
    onCancel               ?: any
    onToggleAdvantedEditor ?: any
    QueryParams             : any
    onColapse               : any
    colapsed                : boolean
}

const QuestionCardHeader = ({
    questionOrder,
    listTemplates,
    templateIdSelected,
    onToggleAdvantedEditor,
    QueryParams,
    onCancel,
    onColapse,
    colapsed,
}:QuestionCardHeaderProps) => {

    const template = (listTemplates && listTemplates.find(({Id})=> templateIdSelected == Id))||{}

    const colapseButton = <ButtonStyle
                            size      = "forTable"
                            onClick   = {onColapse}
                            icon      = "arrowDownIcon"/>

    return <Row className="justify-content-between ml-4">
                <Col md="auto row">
                    <Col md="auto">
                        <h2 className="font-weight-bold">
                            Question {questionOrder} / {template && <span className="font-weight-normal">{template.Description}</span>}

                        </h2>
                    </Col>
                    {
                        onToggleAdvantedEditor
                        && <Col md="auto">

                                    {/*<ButtonAdvancedStyled
                                        actived={!!QueryParams.advancedEditor}
                                        className="ml-2"
                                        onClick={onToggleAdvantedEditor}>
                                        <span>advanced editor</span>
                                    </ButtonAdvancedStyled>*/}
                        </Col>
                    }
                </Col>
                {
                    onCancel
                    && <Col md="auto">
                            <ButtonStyle
                                size      = "forTable"
                                onClick   = {onCancel}
                                icon      = "closeGreen"/>
                        </Col>
                }

                {
                    onColapse &&
                    <Col md="auto">
                        {colapsed?
                            colapseButton
                        :
                            <ButtonRotatedIcon>
                                {colapseButton}
                            </ButtonRotatedIcon>
                        }
                    </Col>
                }
            </Row>

}


const mapDispatchToProps = (dispatch:any) => bindActionCreators({

}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCardHeader)

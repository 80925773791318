import * as React from "react"
import { Col, Tooltip } from "reactstrap"

import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style"

import API       from "YConnect/API"
import Loader    from "YConnect/Components/Loader"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import formatText   from "YConnect/Utils/FormatText.util";
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

import find      from "lodash/find"
import indexOf   from "lodash/indexOf"
import clone     from "lodash/clone"
import findIndex from "lodash/findIndex"
import isBoolean from "lodash/isBoolean"

import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/MatchText.field/ExerciseSubStatement.style"
import ExerciseRowStyle from "YConnect/Fields/Exercises/MatchText.field/ExerciseRow.style"

class MatchTextField extends React.Component<ExerciseFieldPropsType, {
    loadingExercise  : boolean,
    loadingAnwers    : boolean,
    contentDialogue  : any,
    YConnectAnswers  : any,
    respostasUsuario : any,
    answers          : any,
    rightAnswers     : any,
    width            : number,
    tooltipOpen      : boolean
    isDisabledViewAnswers : boolean
}> {
    constructor(props: any) {
        super(props)

        if (this.props.innerRef) {
            this.props.innerRef.current.selectLanguage = this.selectLanguage
        }

        this.state = {
            loadingExercise: true,
            loadingAnwers: false,
            tooltipOpen: false,
            contentDialogue: {},
            YConnectAnswers: this.embaralhaOpRespostas(props.exercise.respostas_Exercicio_Yconnect),
            respostasUsuario: [],
            answers: [],
            rightAnswers: [],
            width: window.innerWidth,
            language: this.props.translated,
        }

        this.handleChange = this.handleChange.bind(this)
        this.findAnswers = this.findAnswers.bind(this)
        this.updateDimensions = this.updateDimensions.bind(this)
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this)
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);

        this.fetchDialogueExercise()

        if (this.props.attempts > 0)
            this.fetchUserAnswer()
    }

    selectLanguage = (language: string) => {
        this.setState({...this.state, language})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    async fetchDialogueExercise () {
        try {
            const contentDialogue = await API.Exercicio.getDialogo({
                idExercicio: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem
            }, getConfig())

            this.setState({
                contentDialogue: contentDialogue.data,
                loadingExercise: false
            })
        } catch (error) {}
    }

    async fetchUserAnswer() {
        this.setState({ loadingAnwers: true })
        try {
            const response = await API.Exercicio.getRespostaUsuario({
                userId: this.props.studentId,
                exer: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem,
                tentativa: 'U',
                idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
            }, getConfig())

            this.findAnswers(response.data.resposta)

        } catch (error) {}
    }

    updateDimensions() {
        this.setState({
            width: window.innerWidth
        })
    }

	handleChange(event: any) {
        /**
         * value is dialogue Order
         */
        const { exercise, id, onChange } = this.props
        const { target: { value, attributes } } = event
        const { respostasUsuario, answers, rightAnswers } = this.state
        const answerId = attributes['data-answerId'].value;

        const answer = find(
            exercise.respostas_Exercicio_Yconnect,
            {
                id_resposta: Number(answerId)
            }
        )

        /** clean last answer of sabe value before set current user answer */
        if (respostasUsuario.includes(answer.ordem)) {
            const index = indexOf(respostasUsuario, answer.ordem)

            respostasUsuario[index] = null
            answers[index] = null
            rightAnswers[index] = null
        }

        answers[value - 1] = answer
        respostasUsuario[value - 1] = answer.ordem
        /** clean initial answer value */
        rightAnswers[value - 1] = null

        const score = this.calculaScore(answers)
        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })

        this.setState({ answers, rightAnswers, respostasUsuario })
    }

    embaralhaOpRespostas (respostas: any) {
        let retorno = clone(respostas)

        if (respostas) {
            // Embaralha as respostas, e armazena nas opções de respostas
            for (var i = 0; i < respostas.length; i++) { // gera números de 0 ao tamanho do vetor de respostas
                var r1 = Math.floor(Math.random() * respostas.length)
                var r2 = Math.floor(Math.random() * respostas.length)

                var aux = retorno[r2]
                retorno[r2] = retorno[r1]
                retorno[r1] = aux
            }

        }

        return retorno
    }

    findAnswers (respostasUsuario: string) {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const { rightAnswers } = this.state
        const respFinal = []

        //Checa se é um string, caso seja, verifica se começa com [ e termina com ], e nesse caso, remove ambos os caractéres da string.
        if(typeof respostasUsuario === 'string' && respostasUsuario.charAt(0) === '[' && respostasUsuario.charAt(respostasUsuario.length - 1) === "]"){
            respostasUsuario  = respostasUsuario.slice(1,-1);
        }   

        const result = respostasUsuario.split(',').map((answer, index) => {
            const resp = respostas_Exercicio_Yconnect[answer - 1] || {}

            rightAnswers.push(resp.ordem === index + 1)
            respFinal.push(Number(answer))
            return resp
        }, [])

        this.setState({
            loadingAnwers: false,
            answers: result,
            rightAnswers,
            respostasUsuario: respFinal
        })
    }

    calculaScore(answers: any) {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        let qntdAcertos = 0;

        answers.map((resposta: any, index: number) => {
            if (resposta && resposta.ordem === index + 1) {
                qntdAcertos++;
            }
        })

        return { qntd_acertos: qntdAcertos, qntd_respostas: respostas_Exercicio_Yconnect.length };
    }

    showCorrectAnswers() {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const { respostasUsuario, answers, rightAnswers } = this.state

        respostas_Exercicio_Yconnect.map((item, index) => {
            respostasUsuario[index] = item.ordem
            answers[index] = item
            rightAnswers[index] = true
        })

        /** calculate score */
        const score = this.calculaScore(answers)

        /** call parent function and send the values */
        this.props.onChange(this.props.id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })

        /** update component parameters */
        this.setState({ rightAnswers, respostasUsuario, answers })
    }

    render() {
        let {
			props: {
                id,
                attempts,
                exercise,
                isStudent,
                isDisabledViewAnswers
            },
            state: {
                loadingExercise,
                loadingAnwers,
                contentDialogue,
                respostasUsuario,
                rightAnswers,
                YConnectAnswers,
                width
            },
            handleChange
        } = this

        if (loadingAnwers || loadingExercise) {
            return <Loader />
        }

        return (
            <>
                {
                    !isDisabledViewAnswers
                     && <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                   {(attempts >= 2 || !isStudent) ?
                        <HeaderButtonStyle
                            className="float-right"
                            label="View Answers"
                            onClick={this.showCorrectAnswers}
                            icon="checkedGreen2"
                        />
                        :
                        <>
                            <HeaderButtonStyle
                                id="completeResultToolTip"
                                className="float-right disabled view-answers"
                                label="View Answers"
                                icon="checkedGreen2"
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="completeResultToolTip" toggle={() => this.setState(state => ({ tooltipOpen: !state.tooltipOpen }))}>
                                    {
                                        this.state.language == "br" &&
                                        <span>Oops, você só poderá saber a resposta correta depois de verificar suas próprias respostas na primeira e segunda tentativa.</span>
                                    }

                                    {
                                        this.state.language == "en" &&
                                        <span>Oops, you'll only be able to know the
                                    correct answers once you've checked your own
                                    answers on the 1st and 2nd attempts.</span>
                                    }
                            </Tooltip>
                        </>
                    }
                </div>}
                   {/** texto */}
                   {exercise.conteudo_exercicio ?
                        <ExerciseSubStatementStyle className="w-100">
                            <Col className="exercise-dialogue-content text-center w-100 pb-2" md="12" lg="12">
                                <div className="exercise-dialogue-enunciado">
                                    <span dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.conteudo_exercicio))}} />
                                </div>
                            </Col>
                        </ExerciseSubStatementStyle>
                            :
                            ''
                        }
                <div className="pr-3 pl-3 pr-md-5 pl-md-5 pt-4 pb-4">
                    {
                        contentDialogue.dialogo_Mensagem_Yconnect.map((item, key: number) => {
                            const answerIndex = findIndex(YConnectAnswers, { ordem: Number(respostasUsuario[item.ordem_dialogo - 1]) })
                            const answer = YConnectAnswers[key]
                            const position = indexOf(respostasUsuario, answer.ordem)

                            return (
                                <ExerciseRowStyle className="pt-3" key={`exercise-${key}`}>
                                    {/** exercise questions options */}
                                    <Col className="exercise-dialogue-options mt-3" xs="4" sm="4" md="2" lg="2">
                                        <div className="form-group" onClick={() => {}}>
                                            <label className="form-label label-select">Match with</label>
                                            <select
                                                className = "form-control text-uppercase"
                                                id        = {id}
                                                onChange  = {handleChange.bind(this)}
                                                value     = {position + 1}
                                                data-answerId={answer.id_resposta}
                                            >
                                                <option />
                                                {contentDialogue.dialogo_Mensagem_Yconnect.map(({ ordem_dialogo }, index: number) => (
                                                    <option
                                                        key={`option-${index}`}
                                                        className="text-uppercase"
                                                        value={ordem_dialogo}>
                                                        {(ordem_dialogo + 9).toString(36)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>

                                    {/** exercise answer text */}
                                    <Col className="exercise-dialogue-question" xs="8" sm="8" md="5" lg="5">
                                        <span className="option">
                                            <span>{ key + 1 }</span>
                                        </span>
                                        <span dangerouslySetInnerHTML={{ __html: answer.resposta }} />
                                    </Col>

                                    {/** exercise question text */}
                                    <Col
                                        className={`exercise-dialogue-question d-none d-md-block ${isBoolean(rightAnswers[item.ordem_dialogo - 1]) ? (rightAnswers[item.ordem_dialogo - 1] ? 'right' : 'wrong') : ''}`}
                                        md="5" lg="5">
                                        {/** Answer option selected */}
                                        {
                                            answerIndex >= 0 ?
                                                <span className="selected-option">
                                                    <span>{answerIndex + 1}</span>
                                                </span>
                                            :
                                            ''
                                        }

                                        <span className="option text-uppercase"
                                            style={{marginLeft: answerIndex >= 0 ? 'unset' : '24px'}}
                                        >
                                            <span>
                                                { (item.ordem_dialogo + 9).toString(36) }
                                            </span>
                                        </span>
                                        <span dangerouslySetInnerHTML={{ __html: item.texto }} />
                                    </Col>
                                </ExerciseRowStyle>
                            )
                        })
                    }

                    {
                        width <= 576 ?
                            contentDialogue.dialogo_Mensagem_Yconnect.map((item, key: number) => {
                                const answerIndex = findIndex(YConnectAnswers, { ordem: Number(respostasUsuario[item.ordem_dialogo - 1]) })

                                return (
                                    <ExerciseRowStyle className="exercise-dialogue pr-3 pl-3 pr-md-5 pl-md-5 pt-3" key={`exercise-${key}`}>
                                        {/** exercise question text */}
                                        <Col
                                            className={`exercise-dialogue-question ${isBoolean(rightAnswers[item.ordem_dialogo - 1]) ? (rightAnswers[item.ordem_dialogo - 1] ? 'right' : 'wrong') : ''}`}
                                            md="5" lg="5">
                                            {/** Answer option selected */}
                                            {
                                                answerIndex >= 0 ?
                                                    <span className="selected-option">
                                                        <span>{answerIndex + 1}</span>
                                                    </span>
                                                :
                                                ''
                                            }

                                            <span className="option text-uppercase"
                                                style={{marginLeft: answerIndex >= 0 ? 'unset' : '24px'}}
                                            >
                                                <span>{ (item.ordem_dialogo + 9).toString(36) }</span>
                                            </span>
                                            <span dangerouslySetInnerHTML={{ __html: item.texto }} />
                                        </Col>
                                    </ExerciseRowStyle>
                                )
                            })
                        :
                        ''
                    }
                </div>
            </>
        )
    }
}

export default MatchTextField

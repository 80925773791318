import * as React from "react"
import { withRouter } from "react-router";

const ScrollToTop = ({ children, location: { pathname } }: any) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return children || null;
};

export default withRouter(ScrollToTop);
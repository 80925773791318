import * as React from "react"
import { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"

import SelectField from "YConnect/Fields/Forms/Select.field"

import LearningCard from 'YConnect/PagesComponents/CMS/Cards/Learning.card'
import styled from "styled-components"

const StyledCol = styled(Col)`
    ::before{
        content: '';
        position: absolute;
        height: 90%;
        width: calc(100% - 16px);
        border-radius: 10px;
        border-bottom: 5px solid #643E94;
        bottom: 20px;
    }
`
const BoldDiv = styled.div`
        font-weight:bold;
`
const RelativeDiv = styled.div`
        position:relative;
`

const SpanNoStudent = styled.span`
    font-style: italic;
`

const SpanNoInfo = styled.span`
    font-weight:bold;
    color: #FF1A4A;
`

type course = {
    id_curso: number,
    titulo_curso: string,
}

type student = {
    id_aluno: number,
    id_curso: number,
    nome_aluno: string,
    sobrenome_aluno: string,
    porcentagem_feita: number,
    qtd_classes_left: number,
    qtd_unidades_left: number,
    initialLesson: string,
    currentLesson: string
}

type LearningTrackProps = {
    situacaoTurma: Array<student>,
    courses: Array<course>,
}

const LearningTrack = ({
    situacaoTurma,
    courses,
    hasStudents
}: LearningTrackProps) => {

    const [students, setStudents] = useState([])
    const [hasLoading, setHasLoading] = useState(true)
    const [filterParam, setFilterParam] = useState("Graduating students")

    const MAXNEXTREENROLL = 3

    const filters = [
        { value: 'Graduating students', propertie: 'porcentagem_feita', func: compareGeneric, asc: false },
        { value: 'Starting students', propertie: 'porcentagem_feita', func: compareGeneric, asc: true },
        { value: 'Course ascending', propertie: 'id_curso', func: compareGeneric, asc: true },
        { value: 'Course descending', propertie: 'id_curso', func: compareGeneric, asc: false },
        { value: 'A - Z', propertie: 'nome_aluno', func: compareGenericAlphab, asc: true },
        { value: 'Z - A', propertie: 'nome_aluno', func: compareGenericAlphab, asc: false }
    ]

    useEffect(() => {
        if(Object.keys(situacaoTurma).length > 0){
            try {
                const std = situacaoTurma ? JSON.parse(JSON.stringify(situacaoTurma)) : []
                const sort = filters.find(each => each.value == filterParam)
                setStudents(std.sort((a: any, b: any) => sort.func(a, b, sort.propertie, sort.asc)));
                // if (!!situacaoTurma) {
                //     setHasLoading(false)
                // }
            } catch (error) {
                console.error(error);
            } finally {
                setHasLoading(false)
            }
        }
    }, [filterParam, situacaoTurma])

    function compareGeneric(a: any, b: any, propertie: string, asc: boolean = true) {
        if ((asc && a[propertie] < b[propertie]) || (!asc && a[propertie] > b[propertie])) {
            return -1
        }
        if ((asc && a[propertie] > b[propertie]) || (!asc && a[propertie] < b[propertie])) {
            return 1
        }
        return 0
    }

    function compareGenericAlphab(a: any, b: any, propertie: string, asc: boolean = true) {

        const strA = a[propertie].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        const strB = b[propertie].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()

        if ((asc && strA < strB) || (!asc && strA > strB)) {
            return -1
        }
        if ((asc && strA > strB) || (!asc && strA < strB)) {
            return 1
        }
        return 0
    }

    const handleChangeFilter = (keystone: string, value: any) => {
        const sets: any = { order: setFilterParam }
        if (sets[keystone]) sets[keystone](value)
    }

    const getNextReenrollArray = () => {
        const std = situacaoTurma ? JSON.parse(JSON.stringify(situacaoTurma)) : []
        const sort = std
            .filter((each: student) =>
                each.porcentagem_feita > 80
                && (each.qtd_classes_left > 0 ||
                    each.qtd_unidades_left > 0)
            )
            .sort((a: any, b: any) => CompareNextReenroll(a, b));

        return sort.slice(0, MAXNEXTREENROLL)
    }

    function CompareNextReenroll(a: any, b: any) {
        if (a["qtd_unidades_left"] < b["qtd_unidades_left"]) {
            return -10000
        }
        if (a["qtd_unidades_left"] > b["qtd_unidades_left"]) {
            return 10000
        }

        if (a["qtd_classes_left"] < b["qtd_classes_left"]) {
            return -100
        }

        if (a["qtd_classes_left"] > b["qtd_classes_left"]) {
            return 100
        }

        if (a["porcentagem_feita"] > b["porcentagem_feita"]) {
            return -1
        }

        if (a["porcentagem_feita"] < b["porcentagem_feita"]) {
            return 1
        }

        return 0
    }


    const nextReenroll = (student: student, idx: number) => {

        return <span className="next-reenroll" key={idx}>
            <span><b>{student.nome_aluno} {student.sobrenome_aluno}</b></span>
            {' - '}
            <span>{student.qtd_unidades_left > 0 ? student.qtd_unidades_left + ' Unit' : ''}</span>
            <span>{student.qtd_unidades_left > 1 ? 's' : ''}</span>
            {student.qtd_unidades_left > 0 && student.qtd_classes_left > 0 ? '/' : ''}
            <span>{student.qtd_classes_left > 0 ? student.qtd_classes_left + ' Class' : ''}</span>
            <span>{student.qtd_classes_left > 1 ? 'es' : ''}</span>
            {' left'}
            {idx < MAXNEXTREENROLL - 1 ? <>&emsp;|&emsp;</> : ''}
        </span>
    }

    const renderCards = () => {

        const cards: any = []
        let row: any = []

        students.map((student: student, idx: number) => {
            if (idx % 4 == 0 && idx > 0) {
                cards.push(<Row className="learning-row my-2" key={student.id_aluno}>{row}</Row>)
                row = []
            }
            const course = courses.find((each: course) => each.id_curso == student.id_curso);

            row.push(
                <StyledCol md={3} key={idx} className="px-2 pb-4 col-md-3 learning-card">
                    <LearningCard
                        name={`${student.nome_aluno} ${student.sobrenome_aluno}`}
                        value={student.porcentagem_feita}
                        started={student.initialLesson ? student.initialLesson : "-"}
                        current={!!course ? course.titulo_curso : '-'}
                    />
                </StyledCol>
            )

            if (idx === students.length - 1) {
                cards.push(<Row className="learning-row my-2" key={student.id_aluno}>{row}</Row>)
            }

        })

        return <>{cards}</>
    }

    useEffect(()=>{
        if(Object.keys(situacaoTurma).length > 0 && students) {
            renderLearningTrack();
        }
    }, [hasLoading, situacaoTurma])


    const renderLearningTrack = () => {
        if(students.length > 0){
            return <>
                <header className="flex-row mt-3">
                    <Row className="font-20">
                        <Col md={6}>
                            <div >Currently:</div>
                            {
                                students[0].currentLesson ?
                                 <BoldDiv>{students[0].currentLesson}</BoldDiv> : <SpanNoInfo>
                                 No information was found on Sponte regarding these students.</SpanNoInfo>
                            }
                        </Col>
                        <Col md={6}>
                            <div >Next students to reenroll:</div>
                            {/* <SpanNoStudent className="info-no-students">No students to reenroll yet...</SpanNoStudent> */}
                            { situacaoTurma.length > 0 ? getNextReenrollArray().map((each: student, idx: number) => nextReenroll(each, idx)) : "-"}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9} className="learning-class">
                            <div>Class Students:</div>
                        </Col>
                        <Col md={3}>
                            <div className="score-filter">
                                <SelectField
                                    keystone="order"
                                    label="Order by"
                                    value={filterParam}
                                    onChange={handleChangeFilter}
                                    values={filters}
                                    hasOptionEmpty={true} />
                            </div>
                        </Col>
                    </Row>
                </header>
                {students && renderCards()}
            </>
        }

        else if(hasLoading && !hasStudents) {
            return <RelativeDiv className="loader" />
        }

        else if(hasStudents) {
            if(Object.keys(situacaoTurma).length === 0){
                return <p className="text-center m-3">No data found</p>
            }
        }
    }

    return <div className="learning-track">
        {renderLearningTrack()}
    </div>
}

export default LearningTrack

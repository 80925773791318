import Select from 'react-select';
import styled      from "styled-components"

const SelectStyleNew = styled(Select)`
    .css-13cymwt-control {
        height: 65PX;
        bottom: 27px;
        border-bottom: 1px solid #79b100;
    }

    .css-1fdsijx-ValueContainer {
        height: 63px;
    }

    .css-1uccc91-singleValue {
        margin-top: 20px;
    }

    .css-qbdosj-Input {
        margin-top: 20px;
    }

    .css-1pahdxg-control {
        height: 65PX;
        bottom: 27px;
        border-bottom: 1px solid #79b100;
    }


    @media (max-width: 767px) {
        .css-1uccc91-singleValue {
            margin-top: 17px;
        }

        .css-b8ldur-Input {
            margin-top: 17px;
        }
    }

    #react-select-2-input {
        margin-top: 17px;
    }


`

export default SelectStyleNew;

import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAll = () => 
    API2
    .Level
    .GetAll({}, getConfig())

export const Create = (level:any) => 
    API2
    .Level
    .Create(level, getConfig())
    
export const UpdateById = (level:any) => 
    API2
    .Level
    .UpdateById(level, getConfig())

export const GetById = (levelId:any) => 
    API2
    .Level
    .GetById({levelId}, getConfig())

export const DeleteById = (levelId:any) => 
    API2
    .Level
    .DeleteById({levelId}, getConfig())
import * as React from "react"

import OpenQuestionTextArea from "YConnect/Fields/Exercises/OpenQuestion.field/OpenQuestionTextArea.field"
import OpenQuestionReviewType from "YConnect/Enums/OpenQuestionReviewType"

class OpenQuestionStudentAnswer extends React.Component<{
  attempts: number,
  latestReviewTypeAndAttempt: any,
  value: string,
  onChange: any
}, {}> {
  render() {
    const {attempts, latestReviewTypeAndAttempt, value, onChange} = this.props
    const notReviewedYet = attempts === 1 && !latestReviewTypeAndAttempt 
    const exceededAttempts = attempts === 2
    const isApproved = latestReviewTypeAndAttempt && latestReviewTypeAndAttempt.type === OpenQuestionReviewType.APPROVAL

    return (
      <OpenQuestionTextArea 
        disabled={notReviewedYet || exceededAttempts || isApproved}
        maxLength={4000}
        onChange={onChange}
        rows={10}
        value={value}
      />
    )
  }
}

export default OpenQuestionStudentAnswer
import styled from "styled-components"
import { UncontrolledCollapse } from "reactstrap"

const DivCollapseStyle = styled(UncontrolledCollapse)`
    &:not(.show) {
        display: block;
        height: 5.5em;
        overflow: hidden;
    }
`
export default DivCollapseStyle
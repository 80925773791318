import * as React from "react"


class Textarea extends React.Component<TextareaFieldPropsType, {}>{

  handleChange(event: any) {
    const { props: { keystone } } = this
    const { target: { value } } = event
    this.props.onChange(keystone, value)
  }

  render() {
    let {
      props: {
        keystone,
        required,
        label,
        value,
        placeholder,
        className,
        disabled,
        messageBlocker,
        rows,
        id,
        isInvalid
      },
      handleChange
    } = this

    return <div className={`${className || ""}`}>
      <div className={`form-group ${messageBlocker ? 'mb-0' : 'mb-4'} ${disabled ? 'input-disabled' : ''}`}>
        {(label) ? <label className="form-label">{`${label}${required ? "*" : ""}`}</label> : ""}
        <textarea
            className={`form-control ${isInvalid === true && "isInvalid"}`}
            defaultValue={value ? value : ''}
            onChange={handleChange.bind(this)}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows}
            id={`${id ? id: keystone}`}
        ></textarea>
      </div>
      {(messageBlocker) ? <small className="form-text text-muted mb-3 text-clickable">{messageBlocker}</small> : ''}
    </div>
  }
}

export default Textarea
import { Row } from "reactstrap"
import styled from "styled-components"

const ExerciseRowStyle = styled(Row)`
    padding: 20px 0;

    .exercise-dialogue-photo {
        text-align: center;
        font-weight: bold;
        color: ${props => props.theme.main.textColor};

        img {
            border-radius: 100%;
            width: 100px;
            @media (max-width: 768px) {
               width: 50px;
            }
        }
    }

    &.reverse {
        flex-direction: row-reverse;

        .exercise-dialogue-username {
            text-align: right;
        }

        .exercise-dialogue-photo {
            filter: ${props => props.theme.main.grayScale};

            img {
                @media screen and (max-width: 576px) {
                    float: right;
                }
            }
        }
    }

    &.borderBottom:not(:last-child) {
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
    }
`

export default ExerciseRowStyle
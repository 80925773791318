import styled  from "styled-components"
import { Col } from "reactstrap"

const DivStudentModeStripStyle = styled(Col)`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.main.textWhite};
    height: 55px;
    background:  rgba(0,0,0,0.7); 
    position: sticky !important;   
    top: 55px;
    @media screen and (min-width: 768px) {
        height: 80px;
        position: initial !important;
    }
`

export default DivStudentModeStripStyle
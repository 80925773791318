import styled from "styled-components"

const MapLineStyle = styled.div`

    .map-line {
        border-bottom: 0.3px solid #C5CBCC;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;

        .map-line-city {
            display: flex;
            flex-flow: row wrap;
            width: 75%;
            .counter {
                color: #565656;
                font-size: 20px;
                font-weight: 600;
                margin-right: 10px;
            }

            .rank {
                span {
                    color: #00A281;
                }
            }
            .city {
                align-items: center;
                color: #565656;
                display: flex;
                margin-right: 8px;
                max-width: 150px;
                overflow: hidden !important;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
            }
        }

        .map-line-percent {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            margin: 0;
            .total-percent {
                font-size: 17px;
                font-weight: 600;
            }
            .difference-percent {
                color: #00A281;
            }
        }
    }
`

export default MapLineStyle
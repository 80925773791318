import styled from "styled-components"

const InputEditableStyle = styled.textarea`
    min-width: 120px;
    height: fit-content;
    border: 0;
    border-bottom: 2px solid;
    background-color: ${props => props.theme.main.backgroundWhite};
    font-weight: bold;
    color: #8fa7ac;
    cursor: text;
    max-width: 76vw;
    text-align: center;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 2em;

    border-image: linear-gradient(to bottom, white 90%, ${props => props.theme.main.lightBlue});
    border-image-slice: 1;
    border-image-width: 0px 0px 3px 0px;
`

export default InputEditableStyle
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Container, Row } from "reactstrap";

import CourseInfo from "YConnect/PagesComponents/ViewBook/CourseInfo";
import BookTasks from "YConnect/PagesComponents/ViewBook/BookTasks";
import ResourceBooks from "YConnect/PagesComponents/ViewBook/ResourceBooks";
import BookTasksStarter from "YConnect/PagesComponents/ViewBook/BookTasksStarter";
import PageTitle from "YConnect/Components/PageTitle";
import { BreadcrumbViewBook, removeBreadcrumbData } from "YConnect/Components/BreadCrumbList.tsx"
import Loader from "YConnect/Components/Loader";
import getConfig from "YConnect/Utils/GetRequestConfig.util";
import UserContext from "YConnect/User.context";
import API from "YConnect/API";
import PrePostContext from "YConnect/PrePost.context";

import UnitType from "YConnect/Enums/UnitType";
import FinalTest from "YConnect/PagesComponents/ViewBook/FinalTest";
import ExtraSupport from "YConnect/PagesComponents/ViewBook/ExtraSupport";
import WrittenTest from "YConnect/PagesComponents/ViewBook/WrittenTest";

const ViewBookPage = ({
    onOpenAudio,
    setAudioFiles,
    setRetractPlayer,
    setAudioTitle,
    history,
    match,
}: any) => {
    const {
        idProfessor,
        observador,
        colaborador,
        usuarioTrial,
        usuarioDegustacao,
        cursosVigentes,
    }: any = useContext(UserContext);

    const prePostUserContext: any = useContext(PrePostContext);

    const [currentCourse, setCurrentCourse] = useState();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingPre, setLoadingPre] = useState(true);

    const [bookTaskUnits, setBookTaskUnits] = useState();
    const [bookTaskSections, setBookTaskSections] = useState([]);
    const [bookTaskExercises, setBookTaskExercises] = useState([]);
    const [bookTaskUnitOrder, setBookTaskUnitOrder] = useState();
    const [allUnits, setAllUnits] = useState();

    const [resourceBookUnits, setResourceBookUnits] = useState();
    const [resourceBookExercises, setResourceBookExercises] = useState([]);
    const [resourceBookUnitOrder, setResourceBookUnitOrder] = useState();

    const [hasPrePost, setHasPrePost] = useState();
    const [courseHasPre, setCoursePre] = useState(false);
    const [testDone, setTestDone] = useState();

    const [courseTitle, setCourseTitle] = useState();

    const [isResourcesBookBlocked,setIsResourcesBookBlocked] = useState(false);

    const [finalTestUnits, setFinalTestUnits] = useState();
    const [finalTestExercises, setFinalTestExercises] = useState([]);
    const [finalTestUnitOrder, setFinalTestOrder] = useState();

    const [extraSupportUnits, setExtraSupportUnits] = useState();
    const [extraSupportExercises, setExtraSupportExercises] = useState([]);
    const [extraSupportUnitOrder, setExtraSupportOrder] = useState();

    const [writtenTestUnits, setWrittenTestUnits] = useState();
    const [writtenTestExercises, setWrittenTestExercises] = useState([]);
    const [writtenTestUnitOrder, setWrittenTestOrder] = useState();

    removeBreadcrumbData([{page:'UnlockScorePage'}])

    let id_curso: string | number;

    const {
        params: { id }
    } = match
    const { idUsuario, profiles, idCursoAtual }: any = useContext(UserContext);

    useEffect(() => {
        setLoading(true);
        if (
            history.location.state !== undefined &&
            history.location.state.course
        ) {
            setCurrentCourse(history.location.state.course);

            id_curso = history.location.state.course.id_curso;

            setCourseTitle(history.location.state.course.titulo_curso)
            fetchPrePostTest()
            fetchUnitsData()
        } else {
            const fetchData = async () => {
                try {
                    const result = await API.Course.GetAll(
                        { isDegustacao: false },
                        getConfig()
                    );
                    const searchedCourse = result.data.filter(
                        (course: any) =>
                            course.id_curso === parseInt(match.params.id, 10)
                    )[0];
                    setCurrentCourse(searchedCourse)
                    setCourseTitle(searchedCourse.titulo_curso)
                    fetchUnitsData()
                    fetchPrePostTest()
                } catch (error) { }
            };
            const statusCourse = cursosVigentes.filter(
                ( course : any )=> course.id_curso === parseInt(match.params.id, 10)).map((course:any) => course.ordem_curso);
            if(statusCourse && statusCourse.length > 0){
                setIsResourcesBookBlocked(statusCourse[0][0] === "20")
                // setIsResourcesBookBlocked(statusCourse[0][0] === "3")
            };
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (isStudent()) {
            const listIdVigente = cursosVigentes.map(
                ({ id_curso }: any) => id_curso
            );
            if (listIdVigente.indexOf(match.params.id * 1) === -1) {
                prePostUserContext &&
                    prePostUserContext.listPptTestAlunoYConnect &&
                    prePostUserContext.listPptTestAlunoYConnect[0] &&
                    prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso &&
                    prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso
                        .fl_feito === false
                    ? (window.location.href = "#/")
                    : (window.location.href = "#/courses");
            }
        }
    }, []);

    const fetchPrePostTest = async () => {
        try {
            const response = await API.PrePostTest.Get(
                {
                    id_aluno: idUsuario,
                    id_curso: id,
                },
                getConfig()
            );

            if (
                response.data.listPptTestAlunoYConnect &&
                response.data.listPptTestAlunoYConnect[0] &&
                response.data.listPptTestAlunoYConnect[0].aluno_curso
            ) {
                setHasPrePost(
                    response.data.listPptTestAlunoYConnect[0].aluno_curso
                        .fl_is_pre
                        ? "pre"
                        : "post"
                );
                setTestDone(
                    response.data.listPptTestAlunoYConnect[0].aluno_curso
                        .fl_feito
                );
            }
            setCoursePre(
                response.data.listPptTestAlunoYConnect[0].cursoTemPrePostTest
            );
            setLoadingPre(false);
        } catch (error) {
            setLoadingPre(false);
        }
    };

    const isStudent = () => {
        return (
            idProfessor == 0 &&
            !observador &&
            !colaborador &&
            !usuarioTrial &&
            !usuarioDegustacao
        );
    };

    const fetchUnitsData = async () => {
        try {
            const units = await API.Course.getById(
                {
                    id_curso: id,
                    id_usuario: idUsuario,
                    isDegustacao: false,
                },
                getConfig()
            );

            setAllUnits(units.data)

            setUnitsData(units.data, UnitType.BOOK_TASK, setBookTaskUnits, setBookTaskUnitOrder, fetchBookTaskUnitContent)
            setUnitsData(units.data, UnitType.RESOURCE_BOOK, setResourceBookUnits, setResourceBookUnitOrder, fetchResourceBookUnitContent)
            setUnitsData(units.data, UnitType.FINAL_TEST, setFinalTestUnits, setFinalTestOrder, fetchFinalTestContent)
            setUnitsData(units.data, UnitType.EXTRA_SUPPORT, setExtraSupportUnits, setExtraSupportOrder, fetchExtraSupportContent)
            setUnitsData(units.data, UnitType.WRITTEN_TEST, setWrittenTestUnits, setWrittenTestOrder, fetchWrittenTestContent)

        } finally {
            setLoading(false)
        }
    };

    const setUnitsData = (units: Array<any>, unitType: string, setUnitFn: Function, setUnitOrderFn: Function, fetchUnitContentFn: Function) => {
        const filteredUnits = units.filter((u: any) => u.tipo_unidade === unitType)
        setUnitFn(filteredUnits)

        if (filteredUnits.length > 0) {
            setUnitOrderFn(filteredUnits[0].ordem)
            fetchUnitContentFn(filteredUnits[0].id_unidade)
        }
    }

    const fetchBookTaskUnitContent = async (unitId: number) => {
        fetchUnitContent(unitId, setBookTaskUnitOrder, setBookTaskExercises, setBookTaskSections)
    }

    const fetchResourceBookUnitContent = async (unitId: number) => {
        fetchUnitContent(unitId, setResourceBookUnitOrder, setResourceBookExercises)
    }

    const fetchFinalTestContent = async (unitId: number) => {
        fetchUnitContent(unitId, setFinalTestOrder, setFinalTestExercises)
    }

    const fetchExtraSupportContent = async (unitId: number) => {
        fetchUnitContent(unitId, setExtraSupportOrder, setExtraSupportExercises)
    }

    const fetchWrittenTestContent = async (unitId: number) => {
        fetchUnitContent(unitId, setWrittenTestOrder, setWrittenTestExercises)
    }

    const fetchUnitContent = async (unitId: number, setUnitOrderFn: Function, setExercisesFn: Function, setUnitSectionsFn: Function = () => { }) => {
        const courseInfo = JSON.parse(localStorage.getItem("courseInfo"));
        const titulo_curso = courseInfo ? courseInfo.titulo_curso : '';

        try {
            const contentBook = await API.Course.getUnitByCourse(
                {
                    id_unit: unitId,
                    curso: titulo_curso,
                    id_usuario: idUsuario,
                    isDegustacao: false,
                },
                getConfig()
            );
            setUnitOrderFn(
                contentBook.data.exercicios !== null
                    ? contentBook.data.exercicios[0].ordem_unidade
                    : null
            );

            let formatted: any[] = [];
            if (contentBook.data.exercicios !== null) {
                contentBook.data.exercicios.map((exercise: any) => {
                    contentBook.data.telas.forEach((screen: any) => {
                        if (exercise.id_exercicio === screen.id_exercicio) {
                            formatted.push({ ...screen, ...exercise });
                        }
                    });
                });
            }

            setUnitSectionsFn(contentBook.data.secoes);
            setExercisesFn(formatted);
        } finally {
            setLoading(false);
        }
    }

    if (isLoading || isLoadingPre) return <Loader />;

    return (
        <Container>
            <BreadcrumbViewBook currentCourse={courseTitle}/>
            <PageTitle label="Courses" className="courses col-12" />
            <Row>
                <CourseInfo
                    courseInfo={currentCourse}
                    profiles={profiles}
                    courseHasPre={courseHasPre}
                    testDone={testDone}
                    hasPrePost={hasPrePost}
                    onOpenAudio={onOpenAudio}
                    onChangeAudioFiles={setAudioFiles}
                    onChangeAudioTitle={setAudioTitle}
                    isBlocked={isResourcesBookBlocked}
                    availableUnits={allUnits}
                />
            </Row>
            {!(
                hasPrePost === "pre" &&
                isStudent() &&
                currentCourse.id_curso === idCursoAtual
            ) && currentCourse.id_curso === 67 ? (
                    <Row>
                        <BookTasksStarter
                            courseId={match.params.id}
                            bookTasks={bookTaskExercises}
                            sections={bookTaskSections}
                            unitsFilter={bookTaskUnits}
                            fetchUnitContent={fetchBookTaskUnitContent}
                            unitId={bookTaskUnitOrder}
                            isBlocked = {isResourcesBookBlocked}
                        />
                    </Row>
                ) : (
                    <>
                        <Row>
                            <BookTasks
                                courseId={match.params.id}
                                tasks={bookTaskExercises}
                                availableUnits={bookTaskUnits}
                                fetchUnitContent={fetchBookTaskUnitContent}
                                unitOrder={bookTaskUnitOrder}
                                unitType="Book Tasks"
                                isBlocked = {isResourcesBookBlocked}
                            />
                        </Row>
                        <Row>
                            <ResourceBooks
                                courseId={match.params.id}
                                tasks={resourceBookExercises}
                                availableUnits={resourceBookUnits}
                                fetchUnitContent={fetchResourceBookUnitContent}
                                unitOrder={resourceBookUnitOrder}
                                unitType="Resource Book"
                                isBlocked = {isResourcesBookBlocked}
                            />
                        </Row>

                        {
                            profiles.indexOf(37) > -1 &&
                            <>
                                <Row>
                                    <FinalTest
                                        courseId={match.params.id}
                                        tasks={finalTestExercises}
                                        availableUnits={finalTestUnits}
                                        fetchUnitContent={fetchFinalTestContent}
                                        unitOrder={finalTestUnitOrder}
                                        unitType="Final Test"
                                        isBlocked = {isResourcesBookBlocked}
                                    />
                                </Row>
                                <Row>
                                    <ExtraSupport
                                        courseId={match.params.id}
                                        tasks={extraSupportExercises}
                                        availableUnits={extraSupportUnits}
                                        fetchUnitContent={fetchExtraSupportContent}
                                        unitOrder={extraSupportUnitOrder}
                                        unitType="Extra Support"
                                        isBlocked = {isResourcesBookBlocked}
                                    />
                                </Row>
                                <Row>
                                    <WrittenTest
                                        courseId={match.params.id}
                                        tasks={writtenTestExercises}
                                        availableUnits={writtenTestUnits}
                                        fetchUnitContent={fetchWrittenTestContent}
                                        unitOrder={writtenTestUnitOrder}
                                        unitType="Written Test"
                                        isBlocked = {isResourcesBookBlocked}
                                    />
                                </Row>

                            </>
                        }
                        
                    </>
                )}
        </Container>
    );
};

export default ViewBookPage;

import styled from "styled-components"

import * as checked  from "YConnect/Assets/svg/checkedGreen.svg"
import * as remove   from "YConnect/Assets/svg/closeRed.svg"

const ExerciseRowStyle = styled.div`
    position: relative;
    padding: 20px 0;
    margin-bottom: 10px;
    text-align: justify;

    .breakLine {
        flex-basis: 100%;
        height: 0;
    }

    &.borderBottom:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.main.lightGray};
    }

    &.right {
        border: 1px solid ${props => props.theme.main.successColor} !important;

        &::after {
            content: url(${checked});
            margin-left: 20px;
            position: absolute;
            filter: ${props => props.theme.main.filterSvg};
            right: 15px;
            top: 10px;

            @media screen and (max-width: 768px) {
                transform: scale(0.7)
            }
        }
    }

    &.wrong {
        border: 1px solid ${props => props.theme.main.errorColor} !important;

        &::after {
            content: url(${remove});
            margin-left: 20px;
            position: absolute;
            filter: ${props => props.theme.main.filterSvg};
            right: 20px;
            top: 10px;

            @media screen and (max-width: 768px) {
                transform: scale(0.8)
            }
        }
    }
`

export default ExerciseRowStyle
import * as React from "react"

import styled from "styled-components"

import StudentContentCard from "YConnect/PagesComponents/PrePostReport/Teacher.components/BestStudentScore/StudentContentCard"
import ProfilePictureById from "YConnect/PagesComponents/PrePostReport/Components/ProfilePictureById"


const CardStyle = styled.div`
    align-items: center;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 223px;
`


const CardLoadingStyle = styled(CardStyle)`
.loading {
    left: calc(50% - 20px);
    position: relative;
    width: 40px;
    height: 40px;
    border: 4px transparent solid;
    border-top: 4px ${props => props.theme.main.lightPurple} solid;
    border-radius: 30px;
    animation: preload .7s infinite;
    animation-timing-function: linear;
}

    
@-moz-keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
`
const BestStudentScoreStyle = styled.div`
    align-items: center;
    border: 1px solid #E1E6E7;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 250px;
    width: 100%;

    @media screen and (min-width: 1024px) {
        width: 70%;
    }

`

const BestStudentStyle = styled.div`
    align-items: center;
    border-right: 1px solid #E1E6E7;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    height: 100%;
    padding: 32px 0;
    text-align:center;
    width: 100%;
    
    @media screen and (min-width: 1024px) {
        text-align: left;
        width: 30%;
    }

    h5 {
        color: #565656;
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 35px;
    }

    span {
        color: #565656;
        font-size: 20px;
        font-weight: 600;
        margin-top: 15px;
        @media screen and (min-width: 1024px) {
            margin: 0 16px;
        }
    }
`

const BestStudentScore = ({
    bestStudent
}:any) => 
    <BestStudentScoreStyle>
        {
            bestStudent
            ? Object.keys(bestStudent).length > 0
                ? <>
                    <BestStudentStyle>
                        <h5>Best student score</h5>
                        <ProfilePictureById
                            border    = "8px solid rgba(255, 255, 255, #bdbdbd)"
                            height    = {70}
                            idUsuario = {bestStudent.idaluno}
                            width     = {70}/>
                        <span>{ bestStudent && bestStudent.nome }</span>
                    </BestStudentStyle>
                    <StudentContentCard student={bestStudent}/>
                </> 
                : <div className="w-100 align-items-center d-flex flex-row justify-content-center">
                        <p>No data recorded!</p>
                    </div>
           
            : <CardLoadingStyle>
                    <div className="loading"></div>
                </CardLoadingStyle>
        }
    </BestStudentScoreStyle>
    

/*
<p className="text-center w-100">No one student have the post-test done</p>
*/
export default BestStudentScore
import * as React   from "react"
import PanelCrud from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"

import {GetAll, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/LearningObjective.resources"

import CONFIG_TABLE_LEARNING_OBJECTIVE from "YConnect/PagesComponents/ControlPanel/Tables/LearningObjective.table.config"
import LEARNING_OBJECTIVE_TYPE_TEMPLATE from "YConnect/PagesComponents/ControlPanel/Templates/LearningObjective.template"

const LearningObjectivesPanel = () =>{

    return <PanelCrud
                idName            = "learningObjectiveId"
                labelButtonCreate = "Create Learning Objective"
                labelForm         = "Learning Objective"
                template          = {LEARNING_OBJECTIVE_TYPE_TEMPLATE}
                configTable       = {CONFIG_TABLE_LEARNING_OBJECTIVE}
                GetAll            = {GetAll}
                GetById           = {GetById}
                Create            = {Create}
                UpdateById        = {UpdateById}
                DeleteById      = {DeleteById} />
}

export default LearningObjectivesPanel
import AUTH_API from "./Endpoints/placement/Auth.api.json";
import EXERCICIO_API from "./Endpoints/placement/Exercicio.api.json";
import FILE_API from "./Endpoints/placement/File.api.json";
import PARTE_API from "./Endpoints/placement/Parte.api.json";
import PLACEMENT_API from "./Endpoints/placement/Placement.api.json";
import TELA_API from "./Endpoints/placement/Tela.api.json";




export default [
    AUTH_API,
    EXERCICIO_API,
    FILE_API,
    PARTE_API,
    PLACEMENT_API,
    TELA_API,


].reduce((acc:any, {name, endpoints}:any)=>{
    return {...acc, [name]:endpoints}
}, {})
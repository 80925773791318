import {useEffect} from "react"
import usePrevious from "YConnect/Hooks/usePrevious"

const useUpdateFilterHierarchy = (
  selectedValue:any, 
  queryParamsToRemove:Array<String> = [], RemoveQueryParamFn:Function,
  statesToRemove:Array<String> =[], setStateFn:Function
  ) => {
  const previousValue = usePrevious(selectedValue)

  useEffect(() => {
      if (checkIfValueHasChangedAndBothExist(selectedValue, previousValue)
      || checkIfValueHasChangedToEmpty(selectedValue, previousValue)) {
        queryParamsToRemove.forEach(param => RemoveQueryParamFn(param))
        const statesAsObject = statesToRemove.reduce((acc:any, item:any) => ({...acc, [item]: ''}), {});
        setStateFn(statesAsObject)
      }
  }, [selectedValue])
}

const checkIfValueHasChangedAndBothExist = (selectedValue:any, previousValue:any) => 
  (selectedValue && previousValue && previousValue !== selectedValue)

const checkIfValueHasChangedToEmpty = (selectedValue:any, previousValue: any) => (previousValue && !selectedValue)

export default useUpdateFilterHierarchy
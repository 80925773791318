import styled from "styled-components"
import { Tabs } from "react-bootstrap"

const TabStyle = styled(Tabs)`
    background-color: ${(props) => props.theme.main.backgroundWhite} !important;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.11);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    overflow-x: scroll;
    position: relative;
    z-index: 9;
    width: 100%;

    &.auto {
        .nav-item,
        a {
            flex: 1 auto;
        }
    }
/*
    &.grade-student {
        + 
    }*/

    &.explore-tab {
        position: fixed;
        width: 90%;
        overflow-x: scroll;
        @media screen and (min-width: 1024px) {
            overflow-x: hidden;
        }

        a {
            white-space: pre-wrap;
            height: 80px;
        }

        .header-filter {
            margin: 100px 0;
        }
    }

    @media screen and (min-width: 768px) {
        flex-flow: row wrap;
        justify-content: flex-start;
        overflow: hidden;
    }

  

    .nav-item,
    a {
        align-items: center;
        border: none;
        color: ${(props) => props.theme.main.clickable};
        display: flex;
        flex: 1 auto;
        flex-flow: row wrap;
        justify-content: center;
        font-weight: bold;
        padding: 40px 36px 19px !important;
        text-align: center;
        white-space: nowrap;
        @media screen and (min-width: 768px) {
            width: auto;
            padding: 5px;
        }
        @media screen and (max-width: 458px) {
            padding: 20px 10px 17px !important;
        }
        &:hover {
            border-color: transparent;
        }
    }
    .nav-link.active {
        border: none;
        background-color: ${(props) =>
        props.theme.main.backgroundWhite} !important;
        border-bottom: ${(props) => props.theme.main.tabTextActive} solid 5px;
        color: ${(props) => props.theme.main.tabTextActive};
    }

    
`;

export default TabStyle;
import * as React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

const RowGreyStyled = styled(Row)`
    background-color: #f0f5f6;
`

type HelpViewProps = {
    Help:string
}

const HelpView = ({Help}:HelpViewProps) =>
<>
    {
        Help
        && Help !== ""
        && <RowGreyStyled className="justify-content-center pt-5">
                <div className="text-center">
                    <h2 dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(Help)}}></h2>
                </div>
            </RowGreyStyled>
    }
</>

export default HelpView
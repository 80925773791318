import styled from "styled-components"
import { Col } from "reactstrap"

const HeaderScoreStyle = styled(Col)`
    span.right {
        color: ${props => props.theme.main.successColor};
        font-weight: 600;
    }

    span.wrong {
        font-weight: 600;
        color: ${props => props.theme.main.errorColor};
    }

    span.half {
        font-weight: 600;
        color: ${props => props.theme.main.lightGreen};
    }

    span.icon-x {
        background-color: ${props => props.theme.main.lineTable};
        border-radius: 100%;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        padding: 5px 10px;
        margin: 0 15px;

        img {
            width: 15px;
            height: 15px;
            filter: ${props => props.theme.main.brightness};
        }
    }

`

export default HeaderScoreStyle
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

import isEmpty from "lodash/isEmpty";

import { YLinkButtonStyle, LinkStyle } from "YConnect/Components/Button";

import * as audioPlay from "YConnect/Assets/svg/audio-play.svg";

import MOCK from "YConnect/Mocks/mock.file";
import API from "YConnect/API";
import getConfig from "YConnect/Utils/GetRequestConfig.util";
import CourseInfoStyle from "YConnect/Styles/CourseInfo.style";
import ImagesBook from "YConnect/Utils/ImagesBook.util";
import * as CourseResource  from "YConnect/ResourcesBackEnd/Course.resources"

// DONE TODO EDUARDO trocar ImagesBook para API.Book.GetCover({idCurso})

import ActiveTeachModal from "YConnect/Modals/ActiveTeach.modal";
import EpubsModal from "YConnect/Modals/Epubs.modal"
import VideoFileModal from "YConnect/Modals/VideoFile.modal";

import UserContext from "YConnect/User.context";

import getStaticUrl       from "YConnect/Utils/GetStaticUrl.util"

import * as resourceAPI from "YConnect/ResourcesBackEnd/Resource.resources"

const PreTestButtonStyle = styled(YLinkButtonStyle)`
    flex-flow: row nowrap;
    width: fit-content !important;
`;
const FinalTestButtonStyle = styled(YLinkButtonStyle)`
    flex-flow: row nowrap;
    margin: 1rem 0;
    width: fit-content !important;
    justify-content: center;
`;

const ClickableStyled = styled.span`
    color: ${(props) => props.theme.main.clickable} !important;
    font-weight: bold;
`;

const idCursoStarter = 67

const idsMultinivel = [69, 70, 82, 83, 85, 86, 87];
const idsBooksBlink = [64, 65, 66, 68, 69, 70, 81, 82, 83, 84, 85, 86 , 87, 92, 93, 104, 105, 106, 107];
const idsBooksBlinkRB = [64, 65, 66, 68];
const idsGameDownloadLabel = [81, 84, 92, 93];

interface ResourceValues {
    id_Resource: number,
    id_Course: number,
    name_File: string,
    title: string,
    type: string
  }

  const resourceTypes = {
    AUDIO: 4,
    VIDEO: 3,
    EPUB: 2,
    ACTIVE_TEACH: 1
}

const CourseInfo = ({
    courseInfo,
    onOpenAudio,
    onChangeAudioFiles,
    onChangeAudioTitle,
    hasPrePost,
    courseHasPre,
    testDone,
    setAudioFiles,
    setAudioTitle,
    isBlocked,
    availableUnits
}: any) => {
    const [activeTeach, setActiveTeach] = useState([]);
    const [epubs, setEpubs] = useState([]);
    const [listVideo, setListVideo] = useState([])
    const [notesTest, setNotesTest] = useState([]);
    const [whichStage, setWhichStage] = useState(0);
    const [secondTest, setSecondTest] = useState(0);
    const [amountAudio, setAmountAudio] = useState(0);
    const [downloads, showDownloads] = useState(false);
    const [activeTeachModal, showactiveTeachModal] = useState(false);
    const [isAbleExam, setIsAbleExam] = useState(false);
    const [batteryStatus, setBatteryStatus] = useState(false);
    const [isEpubModalVisible, setEpubModalVisible] = useState(false);
    const [fileVideoModal, showfileVideoModal] = useState(false);
    const [testButton, showTestButton] = useState(false);
    const [dataFinalTest, setDataFinalTest] = useState();
    const [bookCoverUrl, setBookCoverUrl] = useState("")
    const [hasFinalTest, setHasFinalTest]= useState(false);

    const {
        idProfessor,
        observador,
        colaborador,
        usuarioTrial,
        usuarioDegustacao,
        idCursoAtual,
        idUsuario,
        cursoAtual
    }: any = useContext(UserContext);

    const isCursoStarter: boolean = parseInt(courseInfo.id_curso, 10) === idCursoStarter

    useEffect(() => {
        localStorage.setItem("courseInfo", JSON.stringify(courseInfo));
        fetchActiveTeach()
        fetchVideoFile()
        fetchEpubs()
        getPontuacaoFinal()
        fetchCapaDeLivro()
        fetchCourseAbleFinalExam()
        fetchCourseWhichStage()
        fetchCMSAudios()
        if (availableUnits) {
            courseHasFinalTest()
        }
    }, []);

    const isStudent = () =>
        idProfessor == 0 &&
        !observador &&
        !colaborador &&
        !usuarioTrial &&
        !usuarioDegustacao;

    if (!courseInfo) {
        courseInfo = JSON.parse(localStorage.getItem("courseInfo"));
    }
    const responseHandler = (response: any) => {
        const hasBookCover = response.data.capas[0] ? true : false
        if (hasBookCover) return response.data.capas[0].url_capa
        return ""
    }

    const fetchCapaDeLivro = () : void => {
        const idCourseExists = courseInfo.id_curso ? true : false
        if(!idCourseExists) return null
        CourseResource
        .GetCover(courseInfo.id_curso)
        .then((response:any) => {
            const capa_do_livro = responseHandler(response)
            const urlCoverBookCMS = getStaticUrl(capa_do_livro)
            setBookCoverUrl(urlCoverBookCMS)
        })
    }
    const courseHasFinalTest = () => {
        availableUnits.map((units) => {
            if(units.tipo_unidade == 'FINAL_TEST') {
                setHasFinalTest(true)
            }
        })
    }

    const fetchCourseDownload = async () => {
        try {
            API.Downloads
            .GetAudiosCMS({
                courseId: id_curso,
            }, getConfig()).then((res) => {
                console.log(res.data)
            })

            API.Audio.Get(
                {
                    id_curso: courseInfo.id_curso,
                },
                getConfig()
            ).then((res: any) => {
                setAmountAudio(
                    res.data.faixas !== null ? res.data.faixas.length : 0
                );
                setAudioTitle(courseInfo.titulo_curso);
                setAudioFiles(res.data);
            });
        } catch (error) { }
    };

    const fetchCMSAudios = async () => {
        const { id_curso, titulo_curso } = courseInfo

        // Get the data from table YCO_Resource
        API.Downloads
        .GetAudiosCMS({
            courseId: id_curso,
        }, getConfig()).then((res) => {
            const { data } = res
            setAmountAudio( data !== null ? data.length : 0)
            onChangeAudioTitle(titulo_curso)
            onChangeAudioFiles(data)
        })
    }

    const fetchActiveTeach = () => {
        try {
            const { id_curso } = courseInfo
            API.Downloads
            .GetActiveTeach({
                idCursoActiveTeach: id_curso
             },getConfig())
            .then((res: any) => {
                setActiveTeach(res.data);
            });


        } catch (error) {
            setActiveTeach([]);
        }
    };

    const fetchCourseAbleFinalExam = () => {
        try {
            API.AssessmentFinalTest.GetPorcentagemConclusao(
                {
                    id_aluno: idUsuario,
                    id_curso: courseInfo.id_curso,
                },
                getConfig()
            ).then((res: any) => {
                setIsAbleExam(res.data.final_test_avaible);
            })
        } catch (error) {
            console.error('error')
            setIsAbleExam(false)
        }
    };

    const fetchCourseWhichStage = () => {
        try {
            API.AssessmentFinalTest.GetCondicaoPrimeiraProva(
                {
                    id_aluno_pri: idUsuario,
                    id_curso: courseInfo.id_curso,
                },
                getConfig()
            ).then((res: any) => {
                setWhichStage(res.data);
                if (res.data == 2) {
                    fetchCourseTestBattery();
                }else{
                    showTestButton(true)
                }

            });
        } catch (error) {
            setWhichStage(4);
        }
    };

    const fetchCourseSecondStatus = () => {
        try {
            API.AssessmentFinalTest.GetNotaSegundaProva(
                {
                    id_aluno_seg: idUsuario,
                    id_curso: courseInfo.id_curso,
                },
                getConfig()
            ).then((res: any) => {
                setSecondTest(res.data);
                showTestButton(true)
            });
        } catch (error) {
            setSecondTest(4);
        }
    };

    const fetchCourseTestBattery = () => {
        try {
            API.AssessmentFinalTest.GetCondicaoBateriaTeste(
                {
                    id_aluno_bat: idUsuario,
                    id_curso: courseInfo.id_curso,
                },
                getConfig()
            ).then((res: any) => {
                setBatteryStatus(res.data);
                if (res.data == true) {
                    fetchCourseSecondStatus();
                }else{
                    showTestButton(true)
                }
            });
        } catch (error) {
            setBatteryStatus(false);
        }
    };

    const fetchEpubs = async () => {

        const resEpubs = await API.Downloads.GetEpubs({
            courseId: courseInfo.id_curso,
            isStudent: isStudent()
        },getConfig());

        if (isStudent() == true) {
            if(idsBooksBlink.includes(courseInfo.id_curso)) {
                resEpubs.data[0].name_File = `/#/blink/${courseInfo.id_curso}`;

                if (idsBooksBlinkRB.includes(courseInfo.id_curso)) {
                    resEpubs.data[0].title = `Student's Book - Resource Book`;
                }
                else {
                    resEpubs.data[0].title = `Student’s Book`;
                }
                if (courseInfo.id_curso == 81) {
                    resEpubs.data[1].name_File = `/#/blink/${courseInfo.id_curso}`;
                }
            }
        }
        else {
            if(idsBooksBlink.includes(courseInfo.id_curso)) {
                resEpubs.data[0].name_File = `/#/blink/${courseInfo.id_curso}`;
                resEpubs.data[0].title = `Teacher’s Book`;
                resEpubs.data[1].name_File = `/#/blink/${courseInfo.id_curso}`;

                if (courseInfo.id_curso == 81) {
                    resEpubs.data[2].name_File = `/#/blink/${courseInfo.id_curso}`;
                }
            }
        }
        setEpubs(resEpubs.data);
    };

    const fetchVideoFile = () => {
        try {
            const {id_curso} = courseInfo
            API.Downloads
            .GetVideoFile({
                idCurso: id_curso
            },getConfig())
            .then((res: any) => {
                setListVideo(res.data);
            })
        } catch (error) {
            setListVideo([])
        }
    }



    const getNote = () => {
        if (notesTest.length > 0) {
            return "Result: " + notesTest[0];
        } else if (notesTest.length > 1) {
            return "Result: " + notesTest[1];
        }
    }

    const renderAudioFilesLink = () => {
        return (
            <ClickableStyled className="audio" onClick={onOpenAudio}>
                {amountAudio} audio files
                <img src={audioPlay} className="ml-2" />
            </ClickableStyled>
        )
    };

    const renderActiveTeachLink = (items: Array<any>) => {
        if (isStudent()) return null;
        if (isEmpty(items)) return null;

        return (
            <ClickableStyled
                className="mt-1 active-teach pointer"
                onClick={() => showactiveTeachModal(true)}
            >
                {
                    idsGameDownloadLabel.includes(courseInfo.id_curso) ?
                    <span>Game Download</span>
                    : <span>Active Teach Download</span>
                }
            </ClickableStyled>
        );
    };

    const renderEpubsModalLink = () => {
        if (epubs.length < 1) {
            return null;
        }

        return (
            <ClickableStyled
                className="mt-1 epub pointer"
                onClick={() => setEpubModalVisible(true)}
            >
                E-pub
            </ClickableStyled>
        );
    }

    const renderVideoFileLink = (items: Array<any>) => {
        if (isEmpty(items)) return null;
        if (isStudent() && !items.find(x => x.isForStudent === true)) {
            return null;
        }
        return (
            <ClickableStyled
                className="mt-1 active-teach pointer"
                onClick={() => showfileVideoModal(true)}
            >
                Video Files Download
            </ClickableStyled>
        );
    };

    const getPontuacaoFinal = async () => {
            const finalTestScore = await API.AssessmentFinalTest.GetNotasFinal(
                {
                    id_curso: courseInfo.id_curso,
                    id_aluno_nota_final: idUsuario
                },
                getConfig()
            );
            setDataFinalTest(finalTestScore.data)
    }


    const redirectPage = () => {
        window.location.href=`/#/courses/${cursoAtual[0].id_curso}/view-book`;
        window.location.reload();
    }

    const finalTest = () => {
        if (!testButton) {
            return <> </>
        }

        return <>

            {isAbleExam && whichStage == 0 && hasFinalTest &&
                <FinalTestButtonStyle
                    green
                    size="medium"
                    label={`Do Final Test`}
                    link={`/final_test/${courseInfo.id_curso}`}
                />
            }

            {isAbleExam && batteryStatus && secondTest == 0 && hasFinalTest &&
                <FinalTestButtonStyle
                    green
                    size="medium"
                    label={`Do Final Test`}
                    link={`/final_test/${courseInfo.id_curso}`}
                />
            }

            {
                isAbleExam && whichStage == 1 && !idsMultinivel.includes(courseInfo.id_curso) && dataFinalTest && dataFinalTest.length == 0 &&
                <FinalTestButtonStyle
                    green
                    size="medium"
                    label={`Do Final Test`}
                    link={`/final_test/${courseInfo.id_curso}`}/>
            }

            {//Written Test para cursos não Evolution (Multinivel)
                dataFinalTest && dataFinalTest.length > 0 && dataFinalTest[0].pontuacao >= 0 && dataFinalTest[0].pontuacaoOpenQuestion === null && dataFinalTest[0].data_finalizacao_written_test === null && !idsMultinivel.includes(courseInfo.id_curso)
                ?
                    <FinalTestButtonStyle
                        green
                        size="medium"
                        label={`Do Written Test`}
                        link={`/written_test/${courseInfo.id_curso}`}
                    />
                : <> </>
            }


            {//Aparecer botão do Written Test caso o aluno tenha tirado uma nota maior ou igual a 70 mas não tenha clicado no modal do written test
                dataFinalTest && dataFinalTest.length > 0 && dataFinalTest[0].pontuacao >= 70 && dataFinalTest[0].pontuacaoOpenQuestion === null && dataFinalTest[0].data_finalizacao_written_test === null
                ?
                    <FinalTestButtonStyle
                        green
                        size="medium"
                        label={`Do Written Test`}
                        link={`/written_test/${courseInfo.id_curso}`}/>
                : <> </>
            }

            {/* {
                dataFinalTest && dataFinalTest.length > 0 && dataFinalTest[0].pontuacao >= 0 && dataFinalTest[0].pontuacaoOpenQuestion === null && dataFinalTest[0].data_finalizacao_written_test === null && secondTest != 0
                ?
                    <FinalTestButtonStyle
                        green
                        size="medium"
                        label={`Do Written Test`}
                        link={`/written_test/${courseInfo.id_curso}`}
                    />
                : <> </>
            } */}

            {
                //Aparecer botão do Written Test caso o aluno tenha tirado uma nota menor que 70 e tenha feito o Extra Support
                dataFinalTest
                && dataFinalTest.length > 0
                && dataFinalTest[0].pontuacao >= 0
                && dataFinalTest[0].pontuacao < 70
                && dataFinalTest[0].pontuacaoOpenQuestion === null
                && dataFinalTest[0].data_finalizacao_written_test === null
                && batteryStatus
                && <FinalTestButtonStyle
                        green
                        size="medium"
                        label={`Do Written Test`}
                        link={`/written_test/${courseInfo.id_curso}`}
                    />
            }

            {
                whichStage == 2
                && batteryStatus == false
                && secondTest == 0
                && <FinalTestButtonStyle
                    green
                    size="medium"
                    label={`Do Extra support`}
                    link={`/extra_support/${courseInfo.id_curso}`}/>
            }
            {secondTest == 1 && dataFinalTest && dataFinalTest.length > 0 && dataFinalTest[0].pontuacaoOpenQuestion !== null &&
                <span className="d-block font-weight-bold">
                    {/* AQUI ELE PASSOU NA SEGUNDA PROVA */}
                    Congratulations, you have completed this level!
                    {getNote()}
                </span>
            }
            {whichStage == 1 && dataFinalTest && dataFinalTest.length > 0 && dataFinalTest[0].pontuacaoOpenQuestion !== null &&
                <span className="d-block font-weight-bold">
                    {/* AQUI ELE PASSOU NA PRIMEIRA PROVA */}
                    Congratulations, you have completed this level!
                    {getNote()}
                </span>
            }
        </>
    }

    return (
        <CourseInfoStyle className="col-12 course">
            <div className="card shadow">
                <div className="course-info">
                    {courseInfo && courseInfo.id_curso && (
                        <div
                            className="course-picture"
                            style={{
                                backgroundImage: `url(${
                                    bookCoverUrl
                                    }`,
                            }}
                        ></div>
                    )}
                    {window.innerWidth > 1024 ? (
                        <div className="course-info-content">
                            <h2>{courseInfo && courseInfo.titulo_curso}</h2>
                            <p>{courseInfo && courseInfo.descr_curso}</p>
                            { isBlocked ? (
                                <span className="d-block font-weight-bold">
                                {" "}To unlock this course, you will need to complete the{" "}
                                <ClickableStyled
                                    className="mt-1 active-teach pointer"
                                    onClick={() =>
                                       redirectPage()
                                    }
                                >
                                   {cursoAtual[0].titulo_curso}
                                </ClickableStyled>

                                </span>
                            ) : courseInfo.id_curso !== 67 ? (
                                <div className="course-info-resources">
                                    {hasPrePost &&
                                        !testDone &&
                                        isStudent() ? (
                                            <>
                                                <span className="d-block font-weight-bold">
                                                    Resources
                                                </span>
                                                <div className="resources-options mb-2">
                                                    {renderAudioFilesLink()}
                                                    {renderVideoFileLink(
                                                        listVideo
                                                    )}
                                                    {renderEpubsModalLink()}
                                                </div>
                                                <PreTestButtonStyle
                                                    green
                                                    size="medium"
                                                    label={`Do ${
                                                        hasPrePost === "pre"
                                                            ? "Pre"
                                                            : "Post"
                                                        }-test`}
                                                    icon="arrowRight"
                                                    link={`/pre_post/test/${courseInfo.id_curso}/-1`}
                                                />
                                                {finalTest()}
                                            </>
                                        ) : (
                                            <>
                                                <span className="d-block font-weight-bold">
                                                    Resources
                                            </span>
                                                <div className="resources-options">
                                                    {renderAudioFilesLink()}
                                                    {renderActiveTeachLink(
                                                        activeTeach
                                                    )}
                                                    {renderVideoFileLink(
                                                        listVideo
                                                    )}
                                                    {renderEpubsModalLink()}
                                                </div>
                                                {!isStudent() && courseHasPre && (
                                                    <PreTestButtonStyle
                                                        green
                                                        size="medium"
                                                        maxWidth={true}
                                                        className="mt-2"
                                                        label="Pre/Post-test"
                                                        icon="arrowRight"
                                                        link={`/pre_post/test/${courseInfo.id_curso}/-1`}
                                                    />
                                                )}
                                                {finalTest()}
                                            </>
                                        )}
                                </div>
                            ) : (
                                    <div className="course-info-resources">
                                        <span className="d-block font-weight-bold">
                                            Resources
                                    </span>
                                        <div className="resources-options">
                                            {renderVideoFileLink(
                                                listVideo
                                            )}
                                            {renderEpubsModalLink()}
                                        </div>
                                    </div>
                                )}
                        </div>
                    ) : (
                            <>
                                <div className="course-info-content d-flex flex-column flex-wrap">
                                    <h2>{courseInfo.titulo_curso}</h2>
                                </div>

                                <div className="w-100">
                                    <p style={{ margin: "16px 0" }}>
                                        {courseInfo && courseInfo.descr_curso}
                                    </p>

                                    <p style={{ margin: "16px 0" }}>
                                        {courseInfo && courseInfo.descr_traduzida}
                                    </p>

                                    {hasPrePost &&
                                        !testDone &&
                                        isStudent() ? (
                                            <div className="course-info-resources mt-4">
                                                <span className="d-block font-weight-bold">
                                                    Resources
                                                </span>
                                                <div className="resources-options mb-2">
                                                    {renderAudioFilesLink()}
                                                    {renderVideoFileLink(
                                                        listVideo
                                                    )}
                                                    {renderEpubsModalLink()}
                                                </div>
                                                <PreTestButtonStyle
                                                    green
                                                    size="small"
                                                    className="mt-2 align-self-end"
                                                    label={`Do ${
                                                        hasPrePost === "pre"
                                                            ? "Pre"
                                                            : "Post"
                                                        }-test`}
                                                    icon="arrowRight"
                                                    link={`/pre_post/test/${courseInfo.id_curso}/-1`}
                                                />
                                                {finalTest()}
                                            </div>
                                        ) : (
                                            <div className="course-info-resources mt-4">
                                                <span className="d-block font-weight-bold">
                                                    Resources
                                                </span>
                                                {!isCursoStarter && renderAudioFilesLink()}
                                                {renderActiveTeachLink(activeTeach)}
                                                {renderVideoFileLink(listVideo)}
                                                {renderEpubsModalLink()}
                                                {courseHasPre && !isStudent() && (
                                                    <PreTestButtonStyle
                                                        green
                                                        size={"medium"}
                                                        maxWidth={true}
                                                        className="mt-2"
                                                        label="Pre/Post-test"
                                                        icon="arrowRight"
                                                        link={`/pre_post/test/${courseInfo.id_curso}/-1`}
                                                    />
                                                )}
                                                {finalTest()}
                                            </div>
                                        )}
                                </div>
                            </>
                        )}
                </div>
            </div>

            {/* Download files */}
            <CSSTransition
                classNames=""
                timeout={300}
                in={downloads}
                unmountOnExit
            >
                <div
                    className={`YC-lateral lateral-audio ${
                        downloads ? "active" : ""
                        } shadow d-flex flex-column justify-content-start`}
                >
                    <h4 className="align-items-center d-flex flex-row justify-content-between mb-4">
                        YEP 2: download files
                        <LinkStyle
                            type="submit"
                            label="Close x"
                            className="close-modal"
                            onClick={() => showDownloads(!downloads)}
                        />
                    </h4>

                    <div className="audios">
                        {/*TODO ainda é usado isso? */}
                        {MOCK.stamps_locked.map((_, key) => {
                            return (
                                <div
                                    key={key}
                                    className="audio-task pb-2 align-items-center d-flex flex-row justify-content-between mb-2"
                                >
                                    <img src={audioPlay} />
                                    <div className="w-90 audio-task-title d-flex flex-column justify-content-start">
                                        <span className="p-0 font-weight-bold">
                                            Diving in 1: Task 1
                                        </span>
                                        <div className="audio-info d-flex flex-row justify-content-between">
                                            <span className="p-0 m-0 form-text text-muted">
                                                Unit 1
                                            </span>
                                            <span className="p-0 m-0 form-text text-muted">
                                                3:23
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {/*TODO ainda é usado isso? */}
                        {MOCK.stamps_locked.map((_, key) => {
                            return (
                                <div
                                    key={key}
                                    className="audio-task pb-2 align-items-center d-flex flex-row justify-content-between mb-2"
                                >
                                    <img src={audioPlay} />
                                    <div className="w-90 audio-task-title d-flex flex-column justify-content-start">
                                        <span className="p-0 font-weight-bold">
                                            Diving in 1: Task 1
                                        </span>
                                        <div className="audio-info d-flex flex-row justify-content-between">
                                            <span className="p-0 m-0 form-text text-muted">
                                                Unit 1
                                            </span>
                                            <span className="p-0 m-0 form-text text-muted">
                                                3:23
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </CSSTransition>

            {activeTeachModal && (
                <ActiveTeachModal
                    onDownloadMenu={showactiveTeachModal}
                    hasDownloadMenu={activeTeachModal}
                    downloadItems={activeTeach} />
            )}
            {isEpubModalVisible && (
                <EpubsModal
                    onClose={() => setEpubModalVisible(false)}
                    visible={isEpubModalVisible}
                    epubs={epubs} />
            )}
            {fileVideoModal && (
                <VideoFileModal
                    onDownloadMenu={showfileVideoModal}
                    hasDownloadMenu={fileVideoModal}
                    downloadItems={listVideo} />
            )}
        </CourseInfoStyle>
    );
};

export default CourseInfo;

import styled  from "styled-components"
import { Col } from "reactstrap"

const ResultBoxStyle = styled(Col)`
    border: 1px solid ${props => props.theme.main.borderLightGray};
    border-radius: 8px;
    min-height: 303px;
    margin: auto;

    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    div.result {
        border-bottom: 1px solid ${props => props.theme.main.borderLightGray};
        min-height: 90px;
        align-self: center;

        .score {
            align-self: center;
            justify-content: center;

            .img {
                align-self: center;
                filter: ${props => props.theme.main.filterSvg};
            }

            .text {
                margin-left: 10px;
                font-weight: 400;
                line-height: 24px;

                p {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    width: 95px;
                    text-align: left;
                    margin-bottom: 0;
                }
            }
        }

        .overall {
            align-self: center;

            span {
                font-size: 42px;
                font-weight: 300;
                line-height: 50px;
                color: ${props => props.theme.main.successColor};
                font-size: inherit;
            }
        }
    }

    div.graphic {
        min-height: 213px;

        .texto {
            line-height: 28px;
            align-self: center;
        }

        .line-graphic {
            margin: auto;
            width: 90%;

            .progress-percentage {
                color: ${props => props.theme.main.successColor};
                font-weight: 600;
            }

            .line {
                background-color: ${props => props.theme.main.backgroundBtDisabled};
                border-radius: 2px;
                width: 100%;
                height: 3px;
                margin-top: 10px;
            }

            .line-progress {
                border-radius: 4.5px 0 0  4.5px;
                background-color: ${props => props.theme.main.successColor};
                height: 3px;
                margin-top: -4px;
                box-shadow: 1px 0px 3px 2px ${props => props.theme.main.successShadowColor};
            }

            .pointer-progress {
                margin-top: -7px;
                background-color: ${props => props.theme.main.backgroundWhite};
                border: 2px solid ${props => props.theme.main.successColor};
                border-radius: 100%;
                width: 12px;
                height: 12px;
            }

            .line-start {
                color: ${props => props.theme.main.title};
                font-weight: 400;
                line-height: 24px;
                text-align: left;
            }

            &.fail {
                .progress-percentage {
                    color: ${props => props.theme.main.errorColor};
                }

                .line-progress {
                    background-color: ${props => props.theme.main.errorColor};
                    box-shadow: 1px 0px 3px 2px ${props => props.theme.main.errorShadowColor};
                }

                .pointer-progress {
                    border-color: ${props => props.theme.main.errorShadowColor};
                }
            }
        }
    }
`

export default ResultBoxStyle
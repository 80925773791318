import * as React from "react"
import {useEffect} from "react"
import {
    Row,
    Col
} from "reactstrap"
import styled from "styled-components"
import Table from "YConnect/Components/Table"

import { ButtonStyle } from "YConnect/Components/Button"
import useEditableTableState from "YConnect/PagesComponents/CMS/Hooks/useEditableTableState"

const TableStyled = styled(Table)`
    .table{
        min-height: auto;
    }
    td, th {
        padding:10px;
    }
`

const INITIAL_DATA_STATE = [
    {
        type:"NEW",
        row:{}
    }
]

const GetStateByValues = (values:Array<AnswerType>) => 
    values && values.length > 0
    ? [...values.map((answer) => ({type:"ADDED", row:answer})), {type:"NEW",row:{}}]
    : INITIAL_DATA_STATE


const AnswersChooseMultipleTable = ({
    values,
    onChange,
    nextQuestionOrder,
}:any) => {

    const {
        dataState,
        getValues,
        updateLine,
        addDataNewLine,
        removeLine
    } = useEditableTableState(GetStateByValues(values))

    
    const handleUpdateRow = (key:number, property:string, value:any) => {
        if(property === "IsCorret"){
            const nIsCorrect = dataState.filter(({row:{IsCorret}}) => IsCorret).length
            if(nIsCorrect < 1 || !value){
                updateLine(key, property, value)
            }
        }else{
            updateLine(key, property, value)  
        }
    }

    const handleAddNewRow = () => addDataNewLine()
    const handleRemoveRow = (key:number) => removeLine(key)

    useEffect(()=>{
        if(dataState){
            const valuess = getValues(null)
            .filter(({Awnser}:any) => Awnser && Awnser !== "")
            .map((row:any, idx) => ({...row,
                                IsCorret:!(!row.IsCorret),
                                Order:idx+1 })) //row.QuestionOrder ? row.QuestionOrder : nextQuestionOrder}))
            onChange(valuess)
        }
    }, [dataState])

    const config : Array<ColumnConfigType> = [
        {
            label     : "Answer",
            formatter : (state:any, key:number) => 
                <input 
                    type="text" 
                    className="form-control" 
                    onChange={({ target: { value } }:any) => handleUpdateRow(key, "Awnser", value)}
                    value={state.row["Awnser"] || ""}/>
        },
        {
            label     : "Is Correct",
            formatter : (state:any, key:number) => 
                <input 
                    type="checkbox" 
                    onChange={({ target: { checked } }) => handleUpdateRow(key, "IsCorret", checked)} 
                    checked={!!state.row["IsCorret"]}/>
        },
        {
            formatter : (state:any, key:any) => 
            <Col>
                {
                    state.type === "ADDED"
                    && <Row className="px-0">
                        <ButtonStyle 
                            size      = "forTable"
                            className = "mr-2"
                            icon      = "remove"
                            onClick   = {() => handleRemoveRow(key)}/>
                    </Row>
                }
            </Col>
        }
    ]

    return <TableStyled 
                data={dataState}
                config={config} />
}

export default AnswersChooseMultipleTable

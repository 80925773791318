import * as React              from "react"
import { useState, useEffect } from "react"
import Template                from "YConnect/Components/Template.component"

interface FormPropsType {
    label?       : string
    style?       : object
    className?   : string
    values?      : any
    template     : Array<FieldType>
    onChange?    : Function
    onMapState?  : Function
    onValidated? : Function
    selectData?  : object
    row?         : boolean
    isValidate?  : boolean
}

const addValueByKeystone = (keystone: string, value: any) =>
                                (field: FieldType) =>
                                    field.keystone === keystone ? ({ ...field, ...{ value } }) : field

const addProperty        = (property: string, value: any) =>
                                (field: FieldType) =>
                                    ({ ...field, ...{ [property]: value } })

const addValues          = (values: any) =>
                                (field: FieldType) => {
                                    if(values && values[field.keystone]) field.value = values[field.keystone]
                                    return field
                                }

const addSelectData      = (selectData:any) =>
                                (field: any) => {
                                    if(selectData[field.keystone]) field.values = selectData[field.keystone]
                                    return field
                                }

const addValidate = (isValidate:boolean) => (field: FieldType) => {
    field.isInvalid =  isValidate && field.required && (!field.value || field.value === "")
    return field
}

const FormContainer = ({
    label, 
    style, 
    className, 
    values, 
    template, 
    onChange, 
    onMapState, 
    selectData, 
    row, 
    isValidate, 
    onValidated
}:FormPropsType) => {

    const [formState, setFormState]         = useState()
    const [currentValue, setCurrentValue]   = useState()
    const [valuesUpdated, setValuesUpdated] = useState({})

    const handleChangeField = (keystone:string, value:any) => setCurrentValue({keystone, value})

    useEffect(() => initFormState(), [])

    useEffect(() => {
        if(formState){
            setFormState(formState.map(addValidate(isValidate)))
            if(isValidate){
                setTimeout(()=>{
                    onValidated()
                }, 500)
            }
        }
         
    }, [isValidate])

    useEffect(() => selectData && formState && setFormState(formState.map(addSelectData(selectData))), [selectData])

    const initFormState = () => {
        const initialFormState = template
        .map(addValues(values))
        .map(addProperty("onChange", handleChangeField))
        
        if(selectData) setFormState(initialFormState.map(addSelectData(selectData)))
        else setFormState(initialFormState)

        const initialValuesUpdated = initialFormState
        .filter(({type}) => type && type.toUpperCase() === "HIDDEN")
        .filter(({value}) => value)
        .reduce((acc:any, {keystone, value}) => ({...acc, [keystone]:value}), {})
    
        //setValuesUpdated(initialValuesUpdated)

    }

    useEffect(() => {
        if(currentValue){
            const {keystone, value} = currentValue
            setValuesUpdated({...valuesUpdated, ...{[keystone]:value}})
            const newFormState = formState.map(addValueByKeystone(keystone, value))
            .map(addValidate(isValidate))
            if(onMapState){
                setFormState(onMapState(keystone, value, newFormState))
            }else
                setFormState(newFormState)
        }
    }, [currentValue])

    useEffect(() =>
        formState
        && onChange
        && onChange({
            valuesUpdated:valuesUpdated,
            invalidFields:formState.filter(({isInvalid}:FieldType) => isInvalid).map(({keystone, label}:FieldType) => ({keystone, label}))
        }), [formState])
    return formState
    ? <Template label={label} style={style} className={className} template={formState} row={row}/>
    : <div/>
}


export default FormContainer


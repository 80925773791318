const DyslexiaTheme = {
    /** Global style */
    fontFamily   : "OpenDyslexic",
    bodyFontSize : "13px",

    /** Nanbar desktop */
    paddingLink   : "0 9px",
    paddingLinkLx : "0 15px",
}

export default DyslexiaTheme
import * as React from "react"
import {useState} from "react"
import {
    Row,
    Col
} from "reactstrap"

import { ButtonStyle } from "YConnect/Components/Button"
import CardEditStyled from "YConnect/PagesComponents/CMS/Styleds/CardEdit.styled"

import QuestionCardHeader from "YConnect/PagesComponents/CMS/CardHeaders/Question.cardHeader"
import QuestionView from "YConnect/PagesComponents/CMS/Components/QuestionView"
import QuestionEditor from "YConnect/PagesComponents/CMS/Components/QuestionEditor"

import ExerciseTemplate from "YConnect/Enums/ExerciseTemplate"

type QuestionEditCardProps = {
    listTemplates          : Array<TemplateType>
    question               : QuestionType
    onUpdate               : any
    onCancel               : any
    onToggleAdvantedEditor : any
}

const QuestionEditCard = ({
    listTemplates,
    question,
    onUpdate,
    onCancel,
    onToggleAdvantedEditor
}:QuestionEditCardProps)=>{

    const [values, setValues] = useState<QuestionType>()
    const [showPreview, setShowPreview] = useState<Boolean>(false)

    const isValidValues = () => {
        if (!values || !values.TemplateId || !listTemplates) {
            return false
        }

        const selectedTemplate = listTemplates.find((t) => t.Id === parseInt(values.TemplateId))

        return ((
                values.TemplateId == 8
                || values.TemplateId == 31
                || values.TemplateId == 32
                || selectedTemplate.Name === ExerciseTemplate.OPEN_QUESTION
                || values.Answers.length > 0
        )
        && values.Enunciated != null
        && values.Enunciated !== ""
        && values.Answers
        .reduce((acc:boolean, {Awnser}:AnswerType) => {
                 if(acc && Awnser && Awnser !== ""){
                     return true
                 }else{
                     return false
                 }
         }, true)
        )
        && ((  values.TemplateId == 4
            || values.TemplateId == 19
            || values.TemplateId == 22
            || values.TemplateId == 24
            || values.TemplateId == 25
            || values.TemplateId == 29
        ) ? values.Answers.filter(({IsCorret}) => IsCorret).length === 1
            && values.Answers.length > 0
        : true)
        &&(
            values.TemplateId == 6 ?
            values.Answers.length == values.Dialogues[0].DialogueMessages.length
            : true
        )
    }
        
    const handleSave = () =>{
        onUpdate(values)
    }

    const handleShowPreview = () => setShowPreview(true)
    const handleShowEditor  = () => setShowPreview(false)

    return <CardEditStyled className="p-3">
                <QuestionCardHeader
                    questionOrder          = {question.QuestionOrder}
                    listTemplates          = {listTemplates}
                    templateIdSelected     = {question.TemplateId}
                    onCancel               = {onCancel}
                    onToggleAdvantedEditor = {onToggleAdvantedEditor}/>

                {
                    showPreview
                    && <QuestionView
                        listTemplates      = {listTemplates}
                        question           = {values || question}/>
                }
                {
                    !showPreview
                    && <QuestionEditor
                        question           = {values|| question}
                        listTemplates      = {listTemplates}
                        onChange           = {(values:any) => setValues(values)}/>
                }

                <Row className="pt-3 justify-content-end">
                    <Col md="auto">
                        {
                            !showPreview
                            && <ButtonStyle
                            size      = "130"
                            onClick   = {handleShowPreview}
                            icon      = "eyesGreen"
                            label     = "Preview"/>
                        }
                         {
                            showPreview
                            && <ButtonStyle
                            size      = "130"
                            onClick   = {handleShowEditor}
                            icon      = "pencilGreen"
                            label     = "Editor"/>
                        }
                    </Col>
                    <Col md="auto">
                        <ButtonStyle
                            green
                            disabled = {!isValidValues()}
                            size     = "130"
                            onClick  = {handleSave}
                            icon     = "save"
                            label    = "Save"/>
                    </Col>
                </Row>
        </CardEditStyled>
}

export default QuestionEditCard

import styled from "styled-components"

const BestRankedStudentsStyle = styled.div`

   .order-header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .tabscore-title {
        color: #565656;
        font-size: 20px;
        font-weight: 600;
    }

    .table-border {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 8px;
        min-height: 85%;
    }

    tr:first-child {
        td:last-child {
            color: ${props => props.theme.main.greentextColor};
        }
    }

    tbody > tr {
        border-bottom: 1px solid ${props => props.theme.main.lightGray};
    }

    tbody > tr:last-child {
        border-bottom: 0;
    }

    .best-crown {
        background-color: #4e2d78;
        border-radius: 50%;
        height: 25px;
        margin-left: 25px;
        margin-top: -65px;
        position: relative;
        width: 25px;
        @media screen and (min-width: 1024px) {
            margin-left: 45px;
            margin-top: 10px;
            position: absolute;
        }
        .white-icon {
            height: 25px;
            padding: 3px;
            width: 25px;
        }
    }

    .best-teacher {
        display: block;
        max-width: 100px;
        overflow: hidden !important;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .user-avatar {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;

        h4 {
            margin: 0;
            margin-right: 16px;
            margin-top: 8px;
        }

        figure {
            margin-right: 8px;
        }

        .student-name {
            display: block;
            max-width: 80px;
            overflow: hidden !important;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
`

export default BestRankedStudentsStyle
import {useEffect}  from "react"

const useQueryParamLoader = (
  selectedValue:any, selectedValueFieldName:string, queryParamName:string, 
  requiredSiblingsQueryParams:Array<string>, QueryParams:any, setStateFn:Function
  ) => {
    useEffect(() => {
      if (!selectedValue && checkIfFieldsHaveValues(QueryParams, [...requiredSiblingsQueryParams, queryParamName])) {
        setStateFn({[selectedValueFieldName]: QueryParams[queryParamName]})
      }
    }, [QueryParams[queryParamName]])
}

const checkIfFieldsHaveValues = (QueryParams:any, fields:Array<string>) => fields.every(field => QueryParams[field])

export default useQueryParamLoader
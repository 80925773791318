import styled  from "styled-components"
import { Col } from "reactstrap"

const ColHelpInfoStyle = styled(Col)`
    @media screen and (max-width: 767px) {
        border-left: none;
        margin-top: 0px !important;
        
    }
    @media screen and (max-width: 1015px) {

             
    }
    @media screen and (max-width: 1024px) {
  
             
    }

    a {
        text-decoration: none;
    }
    span {
        margin: 0 auto;
        font-size: 15px;
        text-align: center;
        &:hover {
            color: ${(props) => props.theme.main.greentextColor} !important;
            border: 2px solid ${(props) => props.theme.main.greentextColor} !important;
        }
        
        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }
`

export default ColHelpInfoStyle
import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"

const HeaderButtonStyle = styled(ButtonStyle)`
    position: absolute;
    width: initial !important;
    top: -50px;
    right: 0px;

    &.disabled {
        color: ${props => props.theme.main.colorBtDisabled};
        border-color: ${props => props.theme.main.colorBtDisabled};
        background: ${props => props.theme.main.backgroundBtDisabled};

        img {
            filter: ${props => props.theme.main.grayScale} !important;
        }
    }


    @media screen and (max-width: 768px) and (min-width:320px) {
        top: 20px;
        right: 20px;
        width: 155px!important;
        img {
            padding-left:0px!important;
            width: 15px!important;
        }
    }

    @media screen and (max-width: 360px) {
        top: 5px;
        right: 5px;
        img {
            padding-left:0px!important;
            width: 15px!important;
        }
    }

    @media not all and (min-resolution:.001dpcm)
        { @supports (-webkit-appearance:none) {

            /*Safari browser*/
                //font-size: 0.9rem;
    }}
`

export default HeaderButtonStyle

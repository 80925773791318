
import * as React            from "react"
import {useEffect, 
        useState,
        useRef, Fragment}    from "react"
import { CSSTransition }     from "react-transition-group"
import { Row, Col }          from "reactstrap"

import * as nightModeIcon         from "YConnect/Assets/svg/night-mode.svg"
import * as highContrastIcon      from "YConnect/Assets/svg/high-contrast.svg"
import * as settingsIcon          from "YConnect/Assets/svg/settings.svg"
import * as regularReadIcon       from "YConnect/Assets/svg/regular-read.svg"
import * as dyslexiaReadIcon      from "YConnect/Assets/svg/dyslexia-read.svg"

import { LinkStyle, ButtonStyle } from "YConnect/Components/Button"
import getConfig                  from "YConnect/Utils/GetRequestConfig.util"
import API                        from "YConnect/API"

import { BackButtonStyle } from "YConnect/Styles/BackLink.style"
import AccessibilityStyle  from "YConnect/Styles/Accessibility.style"
import AlphaBlackStyle     from "YConnect/Styles/AlphaBlack.style"


type AccessibilityOptionsModalProps = {
    isShow           : boolean
    contrast         : string
    reading          : string
    userId           : number
    onClose          : Function
    onChangeContrast : Function
    onChangeReading  : Function
}

const AccessibilityOptionsModal = ({isShow, contrast, reading, userId, onClose, onChangeContrast, onChangeReading}:AccessibilityOptionsModalProps) => {

    const [contrastSaved, setContrast] = useState()
    const [readingSaved, setReading]   = useState()

    const willClose = () => {
        onChangeContrast(contrastSaved)
        onChangeReading(readingSaved)
        onClose()
    }

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose()
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        })
    }

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef)

    useEffect(()=>{
        if(isShow){
            setContrast(localStorage.getItem("contrast"))
            setReading(localStorage.getItem("reading"))
        }
    }, [isShow])

    const changeAccessibility = async () => {
        try {
            await API.Acessibilidade.Post({
                id_aluno    : userId,
                contraste   : contrast,
                leitura     : reading
            }, getConfig())
        } catch (error) {}
        onClose()
    }

    return <Fragment>
                 {isShow && <AlphaBlackStyle/>}
                <CSSTransition classNames="dialog accessibility-menu" timeout={300} in={isShow} unmountOnExit>
                    <AccessibilityStyle style={{zIndex:10000}} className="YC-lateral lateral-header d-flex d-lg-flex flex-column justify-content-between" ref={wrapperRef}>
                        <h4 className="align-items-center d-flex flex-row justify-content-between mb-4">
                            Accessibility options
                            <LinkStyle label="Close x" onClick={willClose} className="close-modal text-right header-btn"/>
                        </h4>
                        <p>
                            YConnect was developed in compliance with international standards on web accessibility. Using the properties below,
                            you can control some of the features:
                        </p>

                        <Row className="mb-3">
                            <Col xs="12" sm="7" md="12">
                                <label className="form-label">Contrast</label>
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label className={`btn btn-radio ${contrast === "regular" ? "active" : ""}`}>
                                        <input type="radio" name="contrast" value="regular" checked={contrast === "regular"} onChange={(e) => onChangeContrast(e.currentTarget.value)} />
                                        Regular
                                        <img className="YC-icon-svg" src={settingsIcon} alt="" />
                                    </label>
                                    <label className={`btn btn-radio ${contrast === "night" ? "active" : ""}`}>
                                        <input type="radio" name="contrast" value="night" checked={contrast === "night"} onChange={(e) => onChangeContrast(e.currentTarget.value)} />
                                        Night
                                        <img className="YC-icon-svg" src={nightModeIcon} alt="" />
                                    </label>
                                    <label className={`btn btn-radio ${contrast === "high" ? "active" : ""}`}>
                                        <input type="radio" name="contrast" value="high" checked={contrast === "high"} onChange={(e) => onChangeContrast(e.currentTarget.value)} />
                                        High contrast
                                        <img className="YC-icon-svg" src={highContrastIcon} alt="" />
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs="12" sm="7" md="8">
                                {<label className="form-label">Reading</label>}
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label className={`btn btn-radio ${reading === "regular" ? "active" : ""}`}>
                                        <input type="radio" name="reading" value="regular" onChange={(e) => onChangeReading(e.currentTarget.value)} />
                                        Regular
                                        <img className="YC-icon-svg" src={regularReadIcon} alt="" />
                                    </label>
                                    <label className={`btn btn-radio ${reading === "dyslexia" ? "active" : ""}`}>
                                        <input type="radio" name="reading" value="dyslexia" onChange={(e) => onChangeReading(e.currentTarget.value)} />
                                        Dyslexia
                                        <img className="YC-icon-svg" src={dyslexiaReadIcon} alt="" />
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        <footer className="d-flex flex-row justify-content-lg-between justify-content-end pt-4">
                            <BackButtonStyle icon="arrowPrevious" label="Back" onClick={willClose} />
                            <ButtonStyle
                                green
                                size="medium"
                                label="Apply preferences"
                                icon="arrowRight"
                                onClick={changeAccessibility}
                                maxWidth = {true}
                            />
                        </footer>

                    </AccessibilityStyle>
                </CSSTransition>
            </Fragment>
}

export default AccessibilityOptionsModal

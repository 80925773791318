import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"

import FormContainer from "YConnect/Containers/Form.container"
import ItemsEditableTable from "YConnect/PagesComponents/CMS/Editors/Unscramble.editor/Items.editableTable"
import DEFAULT_TEMPLATE   from "YConnect/PagesComponents/CMS/Templates/Default.template.json"

type UnscrambleTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const UnscrambleTemplateEditor = ({
    question,
    onChangeValues 
}: UnscrambleTemplateEditorProps
)=> {
    
    const [values, setValues] = useState<QuestionType>(question)

    const addValues = (newValues:QuestionType) => {
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }

    const handleChangeValues   = (values:QuestionType) => addValues(values)

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])

    return  <>
                <Row>
                    <Col md={12}>
                        <FormContainer
                            values   = {values}
                            onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                            template = {[...DEFAULT_TEMPLATE.map((field) => ({...field}))]}/>
                    </Col>
                    <Col md={12} className="mt-3">
                        <h3 className="font-weight-bold">
                            Item {values && values.Dialogues[0] && values.Dialogues[0].DialogueMessages.length > 0 && `(${values.Dialogues[0].DialogueMessages.length})`}
                        </h3>
                        <ItemsEditableTable values={values} onChange={(values:QuestionType) => handleChangeValues(values)}/>
                    </Col>
                </Row>
            </>
}

export default UnscrambleTemplateEditor

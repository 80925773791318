import * as React from "react"
import {useEffect, useState, useRef} from "react"
import styled from "styled-components"

import AudioDivStyle           from "YConnect/Styles/AudioPlayer/AudioDiv.style"
import SoundBarStyle           from "YConnect/Styles/AudioPlayer/SoundBar.style"
import SoundDragStyle          from "YConnect/Styles/AudioPlayer/SoundDrag.style"
import ProgressBarWrapperStyle from "YConnect/Styles/AudioPlayer/ProgressBarWrapper.style"

import PlayIcon  from "YConnect/Assets/svg/play.svg"
import PauseIcon from "YConnect/Assets/svg/pause.svg"
import NextIcon  from "YConnect/Assets/svg/next.svg"

const IconClick = styled.img`
    cursor: pointer;
`


type AudioPlayerProps = {
    className               ?:string
    isPlayingCurrent        ?:boolean
    hasNextButton           ?:boolean
    audioPlaying            ?: any
    src                      : string
    autoPlay                ?: boolean
    totalTime               ?: string
    onClose                 ?: Function,
    onPlay                  ?: Function,
    onPause                 ?: Function,
    onAbort                 ?: Function,
    onEnded                 ?: Function,
    onChangeTime            ?: Function,
    onListen                ?: Function,
    onDuration              ?: Function,
    onNext                  ?: Function,
    children                ?: any
}

const addHeadingZero = (num: any) => (num > 9 ? num.toString() : `0${num}`)

const PROGRESS_UPDATE_INTERVAL = 50

const AudioPlayer = ({ 
    className,
    hasNextButton,
    isPlayingCurrent,
    audioPlaying,
    children,
    src           = "",
    autoPlay     = false,
    onClose      = ()=>{},
    onPlay       = ()=>{},
    onPause      = ()=>{},
    onEnded      = ()=>{},
    onChangeTime = ()=>{},
    onListen     = ()=>{},
    onDuration   = ()=>{},
    onNext       = ()=>{}
}:AudioPlayerProps) => {

    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration]       = useState(0)
    const [isPlaying, setIsPlaying]     = useState(false)
    const [dragLeft, setDragLeft]       = useState(0)

    const audioRef = useRef<HTMLAudioElement>()
    const barRef   = useRef<HTMLDivElement>()

    useEffect(()=>{

        if(isPlayingCurrent && audioPlaying && audioPlaying.currentTime){
            updateCurrentTime(audioPlaying.currentTime)
            updateDisplayTime(audioPlaying.currentTime)
        }

        const intervalId = setInterval(() => {
            const {current:{paused, duration}} = audioRef
            if (!paused && !!duration) {
                updateDisplayTime()
            }
        }, PROGRESS_UPDATE_INTERVAL)

        return () => {
            isPlaying && onClose(currentTime)
            clearInterval(intervalId)
        }
    }, [])

    useEffect(()=>{
        const {current:{paused}} = audioRef
        if(!paused && audioPlaying.src !== src){
            audioRef.current.pause()
        }

        if(isPlayingCurrent && audioPlaying.status === "PLAYING" && audioPlaying.src === src){
            if (audioRef.current.paused && audioRef.current.src) audioRef.current.play()
        }
        
    }, [audioPlaying && audioPlaying.src, audioPlaying && audioPlaying.status])

    const handlePlay = () => {
        setIsPlaying(true)
        onPlay()
    }
    
    const handleTimeUpdate = ({target:{currentTime}}: Event) => {
        setCurrentTime(currentTime)
        onChangeTime(currentTime)
    }
    
    const handlePause = (e: Event) => {
        if (!audioRef) return
        setIsPlaying(false)
        const {current:{currentTime}} = audioRef
        onPause(currentTime)
    }

    const updateDisplayTime = (dragLeft?: number) => {
        const {current:{duration, currentTime}} = audioRef
        const {current:{offsetWidth}}           = barRef
        const barWidth = offsetWidth - 8
        onListen(currentTime)
        setCurrentTime(currentTime)
        setDuration(duration)
        setDragLeft(dragLeft || (barWidth * currentTime) / duration || 0)
    }

    const handleTogglePlay = () => {
        if (audioRef.current.paused && audioRef.current.src) audioRef.current.play()
        else if (!audioRef.current.paused) audioRef.current.pause()
    }

    const updateCurrentTime = (currentTime:number)=>{
        audioRef.current.currentTime = currentTime
    }

    const changeTime = (x:number) => {
        let dragLeft = x - barRef.current.getBoundingClientRect().left
        if (dragLeft < 0) {
            dragLeft = 0
        } else if (dragLeft > barRef.current.offsetWidth - 20) {
            dragLeft = barRef.current.offsetWidth - 21
        }
        updateCurrentTime((duration * dragLeft) / (barRef.current.offsetWidth - 20) || 0)
        updateDisplayTime(dragLeft)
    }

    const handleChangeTime     = ({pageX}:Event)             => changeTime(pageX)
    const handleLoadedMetadata = ({target:{duration}}:Event) => {
        onDuration(duration)
        setDuration(duration)
    }

        return <AudioDivStyle className={`d-flex ${className || ""}`}>
                    <audio
                        src              = {src}
                        controls         = {false}
                        autoPlay         = {autoPlay}
                        preload          = "metadata"
                        ref              = {audioRef}
                        onPlay           = {handlePlay}
                        onTimeUpdate     = {handleTimeUpdate}
                        onEnded          = {onEnded}
                        onPause          = {handlePause}
                        onLoadedMetadata = {handleLoadedMetadata}>
                        {
                            children || <p>Your browser does not support the <code>audio</code> element.</p>
                        }
                    </audio>

                    <ProgressBarWrapperStyle className="progress-bar-wrapper">
                        <div className="d-flex flex-row justify-content-between w-100 pb-2">
                            <div>
                                <a className="toggle-play-button" onClick={handleTogglePlay}>
                                    {
                                        isPlaying 
                                        ? <IconClick src={PauseIcon}/>
                                        : <IconClick src={PlayIcon}/>
                                    }
                                    {hasNextButton && <IconClick className="ml-2" src={NextIcon} onClick={onNext}/>}
                                </a>
                            </div>

                            <div className="time">
                                {addHeadingZero(Math.floor(currentTime / 60))}:{addHeadingZero(Math.floor(currentTime % 60))}
                                /{`${addHeadingZero(Math.floor(duration / 60))}:${addHeadingZero(Math.floor(duration % 60))}`}
                            </div>
                        </div>

                        <SoundBarStyle
                            ref         = {barRef}
                            onMouseDown = {handleChangeTime}/>

                        <div 
                            className    = "sound-progress" 
                            style        = {{"width": dragLeft}}
                            onMouseDown  = {handleChangeTime} />

                        <SoundDragStyle
                            contentEditable
                            draggable
                            className   = "indicator"
                            onTouchMove = {(e:Event) => changeTime(e.touches[0].clientX)}
                            onDrag      = {(e:Event) => e.pageX > 0 && handleChangeTime(e)}
                            style       = {{ "left": dragLeft }} />
                    </ProgressBarWrapperStyle>
                </AudioDivStyle>
}
export default AudioPlayer
import * as React from "react"
import {Card} from "reactstrap"

import OpenQuestionReviewButton from "YConnect/Fields/Exercises/OpenQuestion.field/OpenQuestionReviewButton.field"
import OpenQuestionStatus from "YConnect/Fields/Exercises/OpenQuestion.field/OpenQuestionStatus.field"

class OpenQuestionStatusCard extends React.Component<{
  attempts: number,
  latestReviewTypeAndAttempt: any,
  onReviewClick: any
}, {}> {
  render() {
    const {attempts, latestReviewTypeAndAttempt, hasReviews, isStudent, onReviewClick} = this.props

    if (attempts < 1) return null

    return (
      <Card className="open-question__status-card">
        <OpenQuestionStatus
          attempts={attempts}
          latestReviewTypeAndAttempt={latestReviewTypeAndAttempt}
          isStudent={isStudent}
        />
        <OpenQuestionReviewButton
          attempts={attempts}
          hasReviews={hasReviews}
          isStudent={isStudent}
          onClick={onReviewClick}
        />
      </Card>
    )
  }
}

export default OpenQuestionStatusCard
import * as React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"

const RowGreyStyled = styled(Row)`
    background-color: #f0f5f6;
`

type ImageMainViewProps = {
    medias:Array<ExerciseMediaType>
}

const ImageMainView = ({
    medias
}:ImageMainViewProps) => {

    const getMainExerciseMedia = () =>
        medias.find(({Order}) => Order === 0)

    return <>
        {
            getMainExerciseMedia()
            && getMainExerciseMedia().MediaType.toLowerCase() === "image"
            && <RowGreyStyled className="justify-content-center">
                    <Col md="auto" className="p-5">
                        <img className="img-fluid" src={getStaticUrlAssests(getMainExerciseMedia().Source)}/>
                    </Col>
                </RowGreyStyled>
        }
    </>
}

export default ImageMainView
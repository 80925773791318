import * as React from "react"
import {
    useState,
    useContext,
    useEffect
} from "react"
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker,
} from "react-simple-maps"
import {
    Container, Row,
    Col
} from "reactstrap"

import UserContext from "YConnect/User.context"
import { BreadcrumbTravelWorld } from "YConnect/Components/BreadCrumbList"
import YCard from "YConnect/Components/YCard"
import PageTitle from "YConnect/Components/PageTitle"

import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import Loader from "YConnect/Components/Loader"
import styled from "styled-components"

import orderBy from "lodash/orderBy"

const TravelTitle = styled.h2 `
    font-weight: 400;
`

const TravelWorldPage = ({ history }: any) => {
    const user: any = useContext(UserContext)

    const [showDropdown, setShowDropdown] = useState(false)
    const [selectedContinent, setSelectedContinent] = useState({ markerOffset: 35, name: "América do Sul", coordinates: [-45.9825, -9.7942], classCSS: 'america-dropdown' })
    const [stamps, setStamps] = useState()
    const [loading, setLoading] = useState(true)
    const [map, setMap] = useState()

    const markers = [
        { markerOffset: 35, name: "South America", coordinates: [-45.9825, -9.7942], classCSS: 'southAmerica-dropdown' },
        { markerOffset: 35, name: "North America", coordinates: [-120.311983, 57.020699], classCSS: 'northAmerica-dropdown' }, ,
        { markerOffset: 35, name: "Central America", coordinates: [-72.718235, 24.277864], classCSS: 'centralAmerica-dropdown' },
        { markerOffset: 35, name: "Africa", coordinates: [26.949732, 15.453410], classCSS: 'africa-dropdown' },
        { markerOffset: 35, name: "Europe", coordinates: [17.281764, 52.353123], classCSS: 'europe-dropdown' },
        { markerOffset: 35, name: "Asia", coordinates: [107.633326, 30.870637], classCSS: 'asia-dropdown' },
        { markerOffset: 35, name: "Oceania", coordinates: [136.285670, -20.833025], classCSS: 'oceania-dropdown' },
    ]

    useEffect(() => {
        getStamps();

        import(/* webpackChunkName: "map" */ "YConnect/Configs/Sources/world-110m")
        .then(map => {
            setMap(map.default)
        })
    }, [])

    const getStamps = async () => {
        if (user.profiles.indexOf(15) > -1 || user.profiles.indexOf(12) > -1 ) {
            const stamps = await API.Stamp.getAll({
            }, getConfig())
            setStamps(stamps.data)
            setLoading(false);
        } else {
            const stamps = await API.Stamp.getStampByStudent({
                idAluno: user.idUsuario
            }, getConfig())
            setStamps(stamps.data)
            setLoading(false);
        }
    }

    const redirectCountry = (countryId: string) => {
        const selectedCountry = stamps.find((country: any) => country.id_stamp === parseInt(countryId, 10));
        history.push({
            pathname: `/countries/${countryId}`,
            state: {
                selectedCountry
            }
        });
    }

    return <Container>
        <BreadcrumbTravelWorld/>
        <PageTitle label="Travel the world" />
        <Row className="mb-5">

            {
                loading
                    ? <Loader />
                    : <>
                        <Col md={8} xs={12} className="travel-world">
                            <div className="card shadow p-4 h-100">
                                <TravelTitle>Where do you wanna go?</TravelTitle>
                                <h6 className="travel-subtitle">Join your friends, collect stamps and discover the world with YConnect.</h6>

                                <p className="mt-2">Select a country above or in the map, to learn more about it</p>

                                <div className="form-group countries-list">
                                    <label className="form-label label-select">Country</label>
                                    <select className="form-control" onChange={(e: any) => redirectCountry(e.target.value)}>
                                        <option value="select">Select a country</option>
                                        {stamps && orderBy(stamps, "nome_pais").map((stamp: any, key: any) =>
                                            <option key={key} value={stamp.id_stamp}>
                                                {stamp.nome_pais}
                                            </option>)}
                                    </select>
                                </div>

                                {window.innerWidth > 1024
                                    ? <div className="world-map">
                                        <ComposableMap
                                            projectionConfig={{ scale: 180 }}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                minHeight: "520px"

                                            }}>
                                            <ZoomableGroup center={[0, 0]} disablePanning>
                                                {map && <Geographies geography={map}>
                                                    {(geographies, projection) => geographies.map((geography: any, key: any) => (
                                                        <Geography
                                                            key={key}
                                                            geography={geography}
                                                            projection={projection}
                                                            style={{
                                                                default: {
                                                                    fill: "#CFD8DC",
                                                                    stroke: "#607D8B",
                                                                    strokeWidth: 0,
                                                                    outline: "none",
                                                                },
                                                                hover: {
                                                                    fill: "#CFD8DC",
                                                                    stroke: "#CFD8DC",
                                                                    strokeWidth: 0.75,
                                                                    outline: "none",
                                                                },
                                                                pressed: {
                                                                    fill: "#FF5722",
                                                                    stroke: "#607D8B",
                                                                    strokeWidth: 0.75,
                                                                    outline: "none",
                                                                },
                                                            }}
                                                        />
                                                    ))}
                                                </Geographies>}
                                                <Markers>
                                                    {markers.map((marker, i) => (
                                                        <Marker
                                                            key={i}
                                                            marker={marker}
                                                            style={{
                                                                default: { fill: "#4e2d78" },
                                                                hover: { fill: "#FFFFFF" },
                                                                pressed: { fill: "#4e2d78" },
                                                            }}
                                                        >
                                                            <g transform="translate(-12, -5)">
                                                                <path
                                                                    fill="#000"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="square"
                                                                    strokeMiterlimit="10"
                                                                    strokeLinejoin="miter"
                                                                    d="M20,9c0,4.9-8,13-8,13S4,13.9,4,9c0-5.1,4.1-8,8-8S20,3.9,20,9z"
                                                                />
                                                                <circle
                                                                    fill="#fff"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="square"
                                                                    strokeMiterlimit="10"
                                                                    strokeLinejoin="miter"
                                                                    cx="12"
                                                                    cy="9"
                                                                    r="3"
                                                                />
                                                            </g>
                                                            <text
                                                                textAnchor="middle"
                                                                y={marker.markerOffset}
                                                                style={{
                                                                    fontFamily: "Roboto, sans-serif",
                                                                    fill: "#000",
                                                                    fontSize: "20px",
                                                                    textShadow: "2px 1px 5px #fff"
                                                                }}
                                                                onClick={() => {
                                                                    // setSeeCountry(marker.name)
                                                                    // setShowDropdown(true)
                                                                    setSelectedContinent(marker)
                                                                }}
                                                            >
                                                                {marker.name}
                                                            </text>
                                                        </Marker>
                                                    ))}
                                                </Markers>
                                            </ZoomableGroup>
                                        </ComposableMap>
                                    </div>
                                    : null
                                }
                            </div>
                        </Col>

                        {
                            showDropdown &&
                            <div className={`dropdown-continent ${selectedContinent.classCSS}`}>
                                <header className="continent-header d-flex flex-row justify-content-between">
                                    <span className="continent-title">Countries of {selectedContinent.name}</span>
                                    <span className="pointer continent-close" onClick={() => setShowDropdown(false)}>x</span>
                                </header>
                                <div className="continent-countries">
                                    <span>Netherlands</span>
                                    <span>Netherlands</span>
                                    <span>Netherlands</span>
                                </div>
                            </div>
                        }

                        <Col md={4} xs={12} className="">
                            <div className="card shadow travel-profile p-0">
                                <YCard user={user} />
                            </div>
                        </Col>
                    </>
            }

        </Row>

    </Container>
}

export default TravelWorldPage

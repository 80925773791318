
export enum ExerciseDescription {
    CHOOSE_MULTIPLE = "Choose Multiple",
    CHOOSE_MULTIPLE_AUDIO = "Choose Multiple - Audio Control",
    CHOOSE_MULTIPLE_LONG_TEXT = "Choose Multiple - Long Text",
    CHOOSE_MULTIPLE_IMG = "Choose Multiple with Image",
    CHOOSE_MULTIPLE_VIDEO = "Choose Multiple - Video Control",
    CLICK_LISTEN_IMG = "Click and Audio / Listening with images",
    MATCH_TEXT = "Match Text",
    DIALOGO = "Dialogue",
    DRAG_DROP = "Drag and Drop",
    DRAG_DROP_VIDEO = "Drag and Drop Video",
    DRAG_DROP_LONG_TEXT = "Drag and Drop - Long Text",
    DRAG_DROP_AUDIO = "Drag and Drop - Audio Control",
    DRAG_DROP_IMG = "Drag and Drop with Image",
    DRAG_DROP_TEXT_IMG = "Drag and Drop - Text and Image",
    UNSCRAMBLE = "Unscramble - List",
    UNSCRAMBLE_IMG = "Unscramble with Image",
    UNSCRAMBLE_AUDIO = "Unscramble - Audio Control",
    TYPE_IN = "Type In",
    TYPE_IN_IMG = "Type In with Image",
    TYPE_IN_LONG_TEXT = "Type In - Long Text",
    TYPE_IN_AUDIO = "Type In - Audio Control",
    TYPE_IN_ORDENADO = "Type In Ordered",
}

export default ExerciseDescription;
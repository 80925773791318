import React from "react"

const CONFIG_TABLE_COURSE:Array<ColumnConfigType> = [
    {
        keystone : "Image",
        label    : "Image"
    },
    {
        keystone : "Duration",
        label    : "Duration"
    },
    {
        keystone : "Value",
        label    : "Value"
    },
    {
        keystone : "Description",
        label    : "Description",
        formatter:(data:any) => <div className="d-flex"><span dangerouslySetInnerHTML={{__html:data}}></span></div>
    },
    {
        keystone : "Title",
        label    : "Title"
    },
    {
        keystone       : "LevelId",
        label          : "Level",
        selectDataName : "LevelId"
    },
    {
        keystone       : "BookId",
        label          : "Book",
        selectDataName : "BookId"
    }
]

export default CONFIG_TABLE_COURSE
import * as React          from "react"
import {useEffect, useRef} from "react"
import {Fragment}          from "react"
import { CSSTransition }   from "react-transition-group"
import { Row, Col }        from "reactstrap"

import AlphaBlackStyle    from "YConnect/Styles/AlphaBlack.style"
import AccessibilityStyle from "YConnect/Styles/Accessibility.style"
import { LinkStyle }      from "YConnect/Components/Button"
import Testimonials       from "YConnect/PagesComponents/Country/Testimonials"

type AllTestimonialsModalProps = {
    isShow              : boolean
    testimonials        : any
    onClose             : Function
    updateTestimonials  : Function
    loadMore?           : Function
    loadingTestimonials : boolean
    loadingMore         : boolean
    currentCountry      : any
}

const AllTestimonials = ({
    isShow, 
    onClose, 
    testimonials, 
    updateTestimonials, 
    loadMore, 
    loadingTestimonials, 
    loadingMore, 
    currentCountry
}:AllTestimonialsModalProps) => {

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose()
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside)
            return () => document.removeEventListener("mousedown", handleClickOutside)
        })
    }

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef)

    return <Fragment>
                {isShow && <AlphaBlackStyle/>}
                <CSSTransition classNames="dialog accessibility-menu" timeout={300} in={isShow} unmountOnExit >
                    <AccessibilityStyle className="YC-lateral lateral-header testimonial-modal d-flex d-lg-flex flex-column justify-content-start" ref={wrapperRef}
                        style={{zIndex: 9999, padding: "36px"}}>
                        <h4 className="align-items-center d-flex flex-row justify-content-between mb-4">
                            Testimonials
                            <LinkStyle label="Close x" onClick={onClose} className="close-modal text-right header-btn" />
                        </h4>

                        <Row className="mb-3">
                            <Col xs="12">
                                {!loadingTestimonials && testimonials.map((testimonial: any, key: any) =>
                                    <Testimonials
                                        key                = {key}
                                        testimonial        = {testimonial}
                                        updateTestimonials = {updateTestimonials}
                                        classNameCard      = "borderLine"
                                        currentCountry     = {currentCountry}
                                    />)
                                }
                                <p className="text-center pt-2 pb-2 pointer text-clickable"
                                    onClick={() => loadMore()}>
                                    {loadingMore ? "Loading..." : "Load more"}
                                </p>
                            </Col>
                        </Row>
                    </AccessibilityStyle>
                </CSSTransition>
            </Fragment>
}

export default AllTestimonials

import * as React from "react"
import {useState, useEffect} from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import ExerciseRowStyle from "YConnect/Fields/Exercises/DragDropTxtImg.field/ExerciseRow.style"
import ImgStyle from "YConnect/Fields/Exercises/DragDropTxtImg.field/Img.style"

import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"


const  ColStyled= styled(Col)`

display: flex;
padding: 0 !important;

span.option{

    align-self: center;

    @media (min-width: 768px) {
        margin-left: 24px;
    }
}
`

type DragAndDropTextAndImageViewProps = {
    question:QuestionType
}

const DragAndDropTextAndImageView = ({ question }: DragAndDropTextAndImageViewProps) => {

    const [values, setValues] = useState<QuestionType>()
    const [textAndImagesByOrder, setTextAndImagesByOrder] = useState()

    useEffect(() => {
        if(question) {
            setValues(question)
            const {Answers, ExerciseMedias} = question
            if(Answers && ExerciseMedias){
                const newTextAndImagesByOrder = Answers
                .reduce((acc:any, answer)=>{
                    acc[answer.Order] = {
                        answer,
                        exerciseMedia:ExerciseMedias.find(({Order}) => answer.Order == Order)
                    }
                    return acc
                },{})

                setTextAndImagesByOrder(newTextAndImagesByOrder)
            }
        }
    }, [question])


    return <Col md={12} className="p-md-5">
            
            {
                textAndImagesByOrder
                && Object
                .keys(textAndImagesByOrder)
                .map((order:any, index) =>
                <ExerciseRowStyle className="pt-3" key={index}>
                    <Col className="exercise-dialogue-question" xs="6" sm="6" md="6" lg="6">
                        <span className="option">
                            <span>{ index + 1 }</span>
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: textAndImagesByOrder[order].answer.Awnser }} />
                    </Col>
                    <ColStyled className={"exercise-dialogue-question d-md-block"} xs="6" sm="6" md="6" lg="6">
                        <span className="option text-uppercase">
                            <span>
                                { (order*1 + 9).toString(36) }
                            </span>
                        </span>
                        <ImgStyle src={getStaticUrlAssests(textAndImagesByOrder[order].exerciseMedia.Source)}/>
                    </ColStyled>
                </ExerciseRowStyle>)
            }
    </Col>


}

export default DragAndDropTextAndImageView
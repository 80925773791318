import * as React from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"

import * as edit from "YConnect/Assets/svg/edit.svg"
import * as eyes from "YConnect/Assets/svg/eyes.svg"
import * as reloadGrey from "YConnect/Assets/svg/reloadGrey.svg"
import * as book from "YConnect/Assets/svg/book.svg"
import * as usergray from "YConnect/Assets/svg/user-2.svg"
import { useContext } from "react"
import UserContext from "YConnect/User.context"
import { maskForEmail } from "YConnect/Utils/MaskFormat.util"

const CONFIG_TABLE_USER: Array<ColumnConfigType> = [
    {
        label: "Name",
        formatter: ({ nome, sobrenome }: any) => `${nome} ${sobrenome}`
    },
    {
        keystone: "email",
        label: "E-mail",
        formatter: (email: string) => email ? maskForEmail(email) : 'Not registered'
    },
    {
        keystone: "usuario",
        label: "Username",
    },
    {
        keystone: "perfil",
        label: "Profile",
        className: "text-capitalize",
        formatter: (perfil: any) => perfil === "1" ? "Student"
            : perfil === "2" ? "Collaborator"
                : "Unspecified Profile"
    },
    {
        label: "Manage",
        formatter: (user: any) => {
            const { profiles }: any = useContext(UserContext)

            return <DropdownButton
                alignRight
                drop="down"
                key="up"
                title="Actions"
                className="users-dropdown"
                id={`${user.id}`}>
                <Dropdown.Item eventKey="4" href={`#/manage-users/${user.id}`}>
                    <img src={edit} alt="" className="pr-3" />
                    Edit User
                </Dropdown.Item>
                <Dropdown.Item eventKey="4" href={`#/unlock-score/${user.id}`}>
                    <img src={eyes} alt="" className="pr-3" />
                    Unlock Score
                </Dropdown.Item>
                <Dropdown.Item eventKey="4" href={`#/reset-student/${user.id}`}>
                    <img src={reloadGrey} alt="" className="pr-3" />
                    Reset Activities
                </Dropdown.Item>

                {profiles.indexOf(6) > -1
                    &&
                    <>
                    <Dropdown.Item eventKey="4" href={`#/courses-control/${user.id}`}>
                        <img src={book} alt="" className="pr-3" />
                        Courses Control
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4"  onClick={()=> user.setStudentMode() }>
                        <img src={usergray} alt="" className="pr-3" />
                        Student Mode
                    </Dropdown.Item>
                    </>}
            </DropdownButton>
        }
    }
]


export default CONFIG_TABLE_USER

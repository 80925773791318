import * as React from "react"
import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Tooltip } from "reactstrap"

import * as pencil from "YConnect/Assets/svg/pencil.svg"
import * as remove from "YConnect/Assets/svg/close-btn.svg"

import { BreadcrumbExerciseReport, addBreadcrumbData, BreadcrumbExerciseReportStudent } from "YConnect/Components/BreadCrumbList.tsx"

import PageTitle from "YConnect/Components/PageTitle"
import Loader from "YConnect/Components/Loader"
import ServerErrorPage from "YConnect/Pages/ServerError.page"
import { BackLinkStyle } from "YConnect/Styles/BackLink.style"
import { ButtonStyle, YLinkButtonStyle } from "YConnect/Components/Button"
import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"
import DescryptoValue from "YConnect/Utils/DescryptoValue";
import ExerciseTemplate, { getTemplateType } from "YConnect/Enums/ExerciseTemplate"

import AttemptsIconStyle from "YConnect/Styles/AttemptsIcon.style"
import HeaderExerciseStyle from "YConnect/Styles/HeaderExercise.style"
import ExerciseTimelineStyle from "YConnect/Styles/ExerciseTimeline.style"
import HeaderScoreStyle from "YConnect/Styles/HeaderScore.style"
import FormStyle from "YConnect/Styles/Form.style"
import FooterStyle from "YConnect/Styles/Footer.style"
import HomeworkLabelStyle from "YConnect/Styles/HomeworkLabel.style"

import NextUnitReport from "YConnect/Modals/NextUnitReport.modal"

import UserContext from "YConnect/User.context"
import useIsStudent from "YConnect/Hooks/useIsStudent"

import UnitType from "YConnect/Enums/UnitType"

import capitalize from "lodash/capitalize"

import styled from "styled-components"

const ButtonNextUnit = styled(ButtonStyle)`
    border: none;
`

const ExerciseReportPage = ({
    match,
    location,
    onCheckTag,
}: any) => {
    const userContext: any = useContext(UserContext)
    const [tooltipOpen, setTooltip] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [contentExercise, setContentExercise] = useState<any>({})
    const [formValues, setFormValues] = useState()
    const [canSubmit, setSubmit] = useState(true)
    const [showResult, setShowResult] = useState(false)
    const [courseInfo, setCourseInfo] = useState()
    const [bookInfo, setBookInfo] = useState<any>()
    const [student, setStudent] = useState()
    const [prevExercises, setPrevExercises] = useState([])
    const [width, setWidth] = useState(window.innerWidth)
    const [modalNextUnit, setModalNextUnit] = useState(false)
    const [tries, setTries] = useState(0)
    const [flagTry, setFlagTry] = useState(false)

    const reportAluno = JSON.parse(localStorage.getItem("reportAluno"))

    const {
        params: { idCourse, idUnidade, sectionName, orderId, idExercise, idStudent }
    } = match

    useEffect(() => {
        if (courseInfo && !bookInfo) {
            fetchBookContent()
        } else if (bookInfo && !contentExercise) {
            fetchExerciseContent()
        }

        const handleResize = () => {
            setTooltip(false)
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })

    useEffect(() => {
        setLoading(true)
        fetchCourseContent()
        setBookInfo(null)
        setContentExercise(null)
    }, [idCourse])

    useEffect(() => {
        setLoading(true)
        setBookInfo(null)
        setContentExercise(null)
    }, [idUnidade, sectionName, orderId, idStudent])

    useEffect(() => {
        setLoading(true)
        setContentExercise(null)
        setShowResult(false)
    }, [idExercise])

    useEffect(() => {
        setSubmit(true)
        if (formValues && formValues.valuesUpdated.exercise) {
            const answers = formValues.valuesUpdated.exercise

            if (contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.TYPE_IN
                || contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.CATYPE_IN
            ) {
                let quant = 0
                answers.resposta.map(item => {
                    if (item) {
                        quant += Object.keys(item).length
                    }
                })

                if (quant === answers.qntd_respostas) {
                    setSubmit(false)
                }
            } else if (contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.CHOOSE_MULTIPLE
                || contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.CACHOOSE_MULTIPLE
                || contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.VIDEO_CHOOSE_MULTIPLE
            ) {
                if (answers.resposta) {
                    setSubmit(false)
                }
            } else if (contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.OPEN_QUESTION) {
                if (answers.resposta) {
                    setSubmit(false)
                }
            } else if (answers.qntd_respostas === (answers.resposta.filter(Boolean)).length) {
                setSubmit(false)
            } else {
                setSubmit(true)
            }
        } else {
            setSubmit(true)
        }
    }, [formValues])

    useEffect(() => {
        const { telas_total } = contentExercise || {}
        if (telas_total > 1) {
            Array.from(Array(telas_total).keys()).reduce(async (acc: any, index: any) => {
                const accumulator = await acc
                const { data } = await fetchExerciseHistoryContent(index + 1)
                return Promise.resolve([...accumulator, data.tentativas > 0])
            }, Promise.resolve([]))
                .then(setPrevExercises)
        }
    }, [contentExercise])

    const fetchCourseContent = async () => {
        try {
            const { data } = await API.Course.GetAll({
                id: idCourse,
                isDegustacao: false
            }, getConfig())

            const course = data.find(({ id_curso }: any) => id_curso == idCourse)

            localStorage.setItem("courseInfo", JSON.stringify(course))
            setCourseInfo(course)
        } catch (e) {
            setError(true)
        }
    }

    const fetchBookContent = async () => {
        try {
            const { titulo_curso } = courseInfo
            const { data: { exercicios } } = await API.Exercicio.GetCourseUnits({
                idUnit: idUnidade,
                curso: titulo_curso,
                id_usuario: idStudent,
                isDegustacao: false
            }, getConfig())

            const book = exercicios.find(({ nome_secao, ordem_exercicio }: any) =>
                nome_secao === sectionName && ordem_exercicio == orderId)

            localStorage.setItem("bookInfo", JSON.stringify(book))
            setBookInfo(book)
        } catch (e) {
            setError(true)
        }
    }

    const DecryptExercise = (content:any) => {
         content.exercicio.enunciado = DescryptoValue.descryptoString( content.exercicio.enunciado );
         content.exercicio.conteudo_exercicio = DescryptoValue.descryptoString( content.exercicio.conteudo_exercicio );
         content.exercicio.texto = content.exercicio.texto ? DescryptoValue.descryptoString( content.exercicio.texto ) :  content.exercicio.texto ;
         content.exercicio.respostas_Exercicio_Yconnect = content.exercicio.respostas_Exercicio_Yconnect &&  content.exercicio.respostas_Exercicio_Yconnect.map((elem : object) =>
            DescryptoValue.descryptoObject(elem)
         );

         if ( content.anterior ) {
             content.anterior.curso = content.anterior.curso ? DescryptoValue.descryptoString( content.anterior.curso ) : content.anterior.curso;
             content.anterior.secao = content.anterior.secao ? DescryptoValue.descryptoString( content.anterior.secao ) : content.anterior.secao;
             content.anterior.unidade = content.anterior.unidade ? DescryptoValue.descryptoString( content.anterior.unidade ) : content.anterior.unidade;
         }

         if ( content.proximo ) {
             content.proximo.curso = content.proximo.curso ? DescryptoValue.descryptoString( content.proximo.curso ) : content.proximo.curso;
             content.proximo.unidade = content.proximo.unidade ? DescryptoValue.descryptoString( content.proximo.unidade ) : content.proximo.unidade;
         }

        return content;
    }

    const fetchExerciseContent = async () => {
        try {
            const { titulo_curso } = courseInfo
            const {
                nome_secao, ordem_exercicio, nome_unidade
            } = bookInfo

            const response = await API.Exercicio.getExercicio({
                userId: idStudent,
                idCurso: idCourse,
                curso: titulo_curso,
                unidade: nome_unidade,
                idUnidade,
                secao: nome_secao,
                ordem: ordem_exercicio,
                tela: idExercise,
                isDegustacao: false
            }, getConfig())

            if (response && response.data) {
                const { exercicio: { ordem } } = response.data
                setPrevExercises(Array.from(Array(ordem - 1).fill()))
                response.data = DecryptExercise(response.data);
                setContentExercise(response.data)
                fetchUserReport()
                setLoading(false)
            } else {
                window.location.href = "#/login"
            }

        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }

    const fetchSaveExercise = async (
        idUsuario: number,
        id_exercicio: any,
        ordem: any,
        qntd_acertos: any,
        qntd_respostas: any,
        respostas: any,
        onFetch: Function) => {

        try {
            await API.Exercicio.saveExercicio({
                id_usuario: idUsuario,
                id_exercicio: id_exercicio,
                ordem_tela: ordem,
                qntd_acertos: qntd_acertos,
                qntd_respostas: qntd_respostas,
                resposta: respostas
            }, getConfig())


            onFetch && onFetch()

            //fetchUnlockPostExercise(idUsuario, id_exercicio)
        } catch (e) { }
    }

    const fetchUserReport = async () => {
        try {
            const { data } = await API.ReportIndividual.Get({
                id_aluno: idStudent,
                id_curso: idCourse,
            }, getConfig())

            setStudent(data)
            setLoading(false)
        } catch (e) { }
    }

    const fetchExerciseHistoryContent = (tela: number) => {
        const { titulo_curso } = courseInfo
        const {
            nome_unidade, nome_secao, ordem_exercicio
        } = bookInfo

        return API.Exercicio.getExercicio({
            userId: idStudent,
            idCurso: idCourse,
            curso: titulo_curso,
            unidade: nome_unidade,
            idUnidade: idUnidade,
            secao: nome_secao,
            ordem: ordem_exercicio,
            tela: tela,
            isDegustacao: false
        }, getConfig())
    }

    const renderBookType = () => {
        if(contentExercise.tipo_exercicio){
            const { tipo_exercicio } = contentExercise
            return <HomeworkLabelStyle className="task-card-homework">{tipo_exercicio}</HomeworkLabelStyle>
        } else {
            return <></>
        }
    }

    const renderBookLifeTime = () => (
        <div className="w-100 p-5 mt-3 d-none d-lg-block">
            <ExerciseTimelineStyle className="exercise-line d-flex flex-row justify-content-around mb-4">
                {[...Array(contentExercise.telas_total)].map((x, i) => {
                    const tela = Number(idExercise)

                    if (tela === i + 1) {
                        return (
                            <a key={i} className="exercise-task active">
                                <img src={pencil} alt="" />
                            </a>
                        )
                    }

                    return (
                        <Link
                            key={i}
                            className={`exercise-task ${prevExercises[i] ? 'checked-item' : ''}`}
                            to={`/courses/${courseInfo.id_curso}/unit/${idUnidade}/view-book/${bookInfo.nome_secao}/${bookInfo.ordem_exercicio}/exercise/${i + 1}/student/${idStudent}`}>

                            {prevExercises[i] ?
                                <span className="circle"></span>
                                :
                                <img src={pencil} alt="" />
                            }
                        </Link>
                    )
                })}

            </ExerciseTimelineStyle>
        </div>
    )

    const getTemplate = () => {
        const { tentativas, exercicio } = contentExercise || {}
        const { template_Yconnect } = exercicio || {}
        const type = getTemplateType(template_Yconnect)

        return [
            {
                "id": "exercise",
                "studentId": idStudent,
                "type": type,
                "attempts": tentativas,
                "isStudent": useIsStudent(userContext),
                "exercise": exercicio,
                "userId": userContext.idUsuario,
                "bookInfo": bookInfo,
                "courseInfo": courseInfo
            }
        ]
    }

    const getAttempts = () => {
        const { tentativas } = contentExercise || {}

        //this rule only applys to open question
        if (tentativas === 1 && ExerciseTemplate.OPEN_QUESTION === getTemplateType(contentExercise.exercicio.template_Yconnect) || tentativas >= 2 && ExerciseTemplate.OPEN_QUESTION === getTemplateType(contentExercise.exercicio.template_Yconnect)) {
            return tentativas
        }

        if (tentativas < 2) {
            return tentativas + 1
        }

        return 3
    }

    const getPageTitle = () => {
        const { nome_secao, ordem_exercicio } = bookInfo
        return `${capitalize(nome_secao)} : Task ${ordem_exercicio}`
    }

    const sendMessageToTeacher = (exerciseData: any, template: string) => {
        if (template === ExerciseTemplate.OPEN_QUESTION) {
            API.OpenQuestion.sendMessageToTeacher(exerciseData, getConfig())
        }
    }

    const saveExercise = () => {
        const {
            valuesUpdated: {
                exercise: {
                    resposta,
                    qntd_acertos,
                    qntd_respostas
                }
            }
        } = formValues

        const { id_curso } = courseInfo
        const { idUsuario } = userContext
        const { nome_secao } = bookInfo

        const {
            exercicio: {
                id_exercicio,
                ordem,
                template_Yconnect: { nome }
            },
            tentativas
        } = contentExercise

        if (useIsStudent(userContext) && tentativas < 2) {
            let respostas = resposta.toString()
            if (nome === ExerciseTemplate.UNSCRAMBLE || nome === ExerciseTemplate.TYPE_IN) {
                respostas = JSON.stringify(resposta)
            }

            fetchSaveExercise(
                idUsuario,
                id_exercicio,
                ordem,
                qntd_acertos,
                qntd_respostas,
                respostas,
                () => {
                    onCheckTag({
                        idAluno: idUsuario,
                        id: id_exercicio,
                        idSituacao: 1,
                        idCurso: id_curso
                    })
                    sendMessageToTeacher({
                        studentId: idUsuario,
                        exerciseId: id_exercicio,
                        questionOrder: ordem,
                        courseId: id_curso,
                        unitId: idUnidade,
                        sectionName: nome_secao
                    }, nome)
                }
            )
        }

        if (contentExercise.proximo != null && contentExercise.proximo.id_unidade != idUnidade) {
            handleModalNextUnit()
        }

        setShowResult(true)
        window.innerWidth > 1024 ? window.scrollTo(0, 0) : window.scrollTo(0, 170);
    }

    addBreadcrumbData([{ page: 'ViewReportStudentPage', data: { idCourse, idStudent, reportAluno } }])

    const reloadExercise = () => {
        setLoading(true)
        setContentExercise(null)
        setShowResult(false)
    }

    const handleModalNextUnit = () => {
        setModalNextUnit(!modalNextUnit)
    }

    // TODO: Break this into a separate component
    const renderResult = () => {
        const answers = formValues.valuesUpdated.exercise
        const qntd_erros = answers.qntd_respostas - answers.qntd_acertos;

        return <div className="w-100 pt-5 pt-lg-0">
            {
                contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.OPEN_QUESTION ?
                    <>
                        <Col md="12" className="text-center">
                            <h1>
                                Now, wait for your teacher's feedback.
                            </h1>
                        </Col>
                    </>
                    :
                    <>
                        <Col md="12" className="text-center">
                            <h1>
                                {qntd_erros > 0 ? 'Keep Trying!' : 'Nice work!'} Your score is:
                            </h1>
                        </Col>
                        <HeaderScoreStyle md="12" className="text-center mt-2">
                            <h2>
                                <span className="right">{answers.qntd_acertos} right</span>
                                <span className="icon-x">
                                    <img src={remove} alt="" />
                                </span>
                                <span className="wrong">{qntd_erros} wrong</span>
                            </h2>
                        </HeaderScoreStyle>
                        <Col md="12" className="text-center mt-2">
                            <h3>Keep studying and getting better</h3>
                        </Col>
                    </>
            }
            <Col md="12" className="d-none d-md-flex flex-row justify-content-center mt-5 mb-5">
                <ButtonStyle
                    size="medium"
                    label="Repeat this question"
                    icon="reset"
                    onClick={() => { reloadExercise() }}
                />
                <span className="p-2">Or</span>
                {
                    contentExercise.proximo ?
                        <>
                        {contentExercise.proximo.id_unidade == idUnidade
                        ?
                        <YLinkButtonStyle
                            green
                            size="medium"
                            label="Go to the next question"
                            icon="arrowRight"
                            onClick={() => { setShowResult(false) }}
                            link={`/courses/${courseInfo.id_curso}/unit/${contentExercise.proximo.id_unidade}/view-book/${contentExercise.proximo.secao}/${contentExercise.proximo.ordem}/exercise/${contentExercise.proximo.tela}/student/${idStudent}`}
                        />
                        :
                        <ButtonNextUnit
                            green
                            size="medium"
                            label="Go to the next question"
                            icon="arrowRight"
                            onClick={() => {
                                handleModalNextUnit()
                                setShowResult(false)
                            }}
                        />
                        }
                        </>
                        :
                        <YLinkButtonStyle
                            green
                            size="medium"
                            label="Return to courses"
                            icon="arrowRight"
                            onClick={() => { setShowResult(false) }}
                            link="/courses"
                        />
                }
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none mt-5">
                <ButtonStyle
                    size="medium"
                    label="Repeat this question"
                    icon="reset"
                    onClick={() => { reloadExercise() }}
                />
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none text-center mt-2">
                <span className="p-2">Or</span>
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none mt-2 mb-5">
                {
                    contentExercise.proximo ?
                        <YLinkButtonStyle
                            green
                            size="medium"
                            label="Go to the next question"
                            icon="arrowRight"
                            onClick={() => { setShowResult(false) }}
                            link={`/courses/${courseInfo.id_curso}/unit/${contentExercise.proximo.id_unidade}/view-book/${contentExercise.proximo.secao}/${contentExercise.proximo.ordem}/exercise/${contentExercise.proximo.tela}/student/${idStudent}`}
                        />
                        :
                        <YLinkButtonStyle
                            green
                            size="medium"
                            label="Return to courses"
                            icon="arrowRight"
                            onClick={() => { setShowResult(false) }}
                            link="/courses"
                        />
                }
            </Col>
        </div>

    }

    if (error) return <ServerErrorPage location={location} />

    return (
        <Container>
            {
                isLoading
                    ? <Loader />
                    : <>

                        {userContext.idProfessor != 0 && contentExercise
                            ? <BreadcrumbExerciseReport
                                contentExercise={contentExercise}
                                ordem_exercicio={bookInfo.ordem_exercicio}
                            />
                            :
                            <>
                                {
                                    bookInfo && contentExercise
                                    && <BreadcrumbExerciseReportStudent idCurso={idCourse} contentExercise={contentExercise} ordem_exercicio={bookInfo.ordem_exercicio} reportAluno={reportAluno} />
                                }
                            </>
                        }

                        {contentExercise && <>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                    <PageTitle label={getPageTitle()}>
                                        {width > 768 ?
                                            <span className="col-12 mb-4">
                                                Attempts: {getAttempts()} / 2
                                            <AttemptsIconStyle id="TooltipAttempt">
                                                    ?
                                            </AttemptsIconStyle>
                                                <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipAttempt" toggle={() => setTooltip(!tooltipOpen)}>
                                                    You have two attempts on a task. After that, you can try again, but it won’t change your grades. If you need help, ask an Online Teacher!
                                            </Tooltip>
                                            </span>
                                            :
                                            ''
                                        }
                                    </PageTitle>
                                </Col>
                            </Row>

                            <Row className="m-0 mb-5">
                                <div className="exercise card shadow d-flex flex-row flex-wrap justify-content-between col-12 p-0">
                                    {renderBookType()}
                                    {renderBookLifeTime()}
                                    {showResult
                                        ? renderResult()
                                        : <>
                                            {
                                                width <= 768 &&
                                                <span className="col-12 mb-5 mt-5">
                                                    Attempts: {getAttempts()} / 2
                                        <AttemptsIconStyle id="TooltipAttempt2">
                                                        ?
                                        </AttemptsIconStyle>
                                                    <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipAttempt2" toggle={() => setTooltip(!tooltipOpen)}>
                                                        You have two attempts on a task. After that, you can try again, but it won’t change your grades. If you need help, ask an Online Teacher!
                                        </Tooltip>
                                                </span>
                                            }

                                            <HeaderExerciseStyle className="exercise-header d-flex flex-column justify-content-center w-100 pb-3 pl-3 pr-3">
                                                <h1 className="text-center h3 mb-3 font-weight-bolder">
                                                    Question {contentExercise.exercicio.ordem} of {contentExercise.telas_total}
                                                </h1>
                                                <h3
                                                    className="text-center mt-2"
                                                    dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(contentExercise.exercicio.enunciado) }}
                                                ></h3>
                                            </HeaderExerciseStyle>

                                            <FormStyle
                                                className="exercise-body w-100"
                                                onChange={setFormValues}
                                                row={false}
                                                template={getTemplate()} />
                                        </>
                                    }
                                    {showResult
                                        ? <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                            <BackLinkStyle
                                                size="medium"
                                                link={`/courses/${courseInfo.id_curso}/view-book`}
                                                icon="arrowPrevious"
                                                label={bookInfo.tipo_unidade === UnitType.BOOK_TASK ? "Back (Book Tasks)" : "Back (Resource Book)"} />
                                        </FooterStyle>
                                        : <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                            {contentExercise.anterior
                                                && contentExercise.telas_total > 1
                                                && contentExercise.exercicio.ordem > 1
                                                ? <BackLinkStyle
                                                    icon="arrowPrevious"
                                                    size="medium"
                                                    label={`Back (Question ${contentExercise.anterior.tela} / ${contentExercise.telas_total})`}
                                                    link={`/courses/${courseInfo.id_curso}/unit/${idUnidade}/view-book/${bookInfo.nome_secao}/${bookInfo.ordem_exercicio}/exercise/${contentExercise.anterior.tela}/student/${idStudent}`}
                                                />
                                                : bookInfo.tipo_unidade == "RESOURCE_BOOK" ?
                                                <BackLinkStyle
                                                    link={`/reports/view-full-report-resource-book/${ (reportAluno && reportAluno.courseParam) || (courseInfo && courseInfo.id_curso)}/${idStudent}`}
                                                    icon="arrowPrevious"
                                                    label="Back to Report" />
                                                    : [(useIsStudent(userContext)
                                                        ? <BackLinkStyle
                                                            link={`/reports/view-report-student/${courseInfo.id_curso}`}
                                                            icon="arrowPrevious"
                                                            label="Back to Report" />
                                                        : <BackLinkStyle
                                                            link={`/reports/view-full-report/${reportAluno && reportAluno.courseParam}/${idStudent}`}
                                                            icon="arrowPrevious"
                                                            label="Back to Report" />
                                                    ),]
                                            }
                                            <span className="d-flex flex-row">
                                                {!canSubmit && <ButtonStyle
                                                    green
                                                    size="medium"
                                                    label="Answer Question"
                                                    onClick={saveExercise}
                                                    icon="checked"
                                                />}
                                            <NextUnitReport isShow={modalNextUnit} onClose={handleModalNextUnit} idCourse={courseInfo.id_curso} nameUnit={bookInfo.nome_unidade} content={contentExercise} username={userContext.nome} idUser={idStudent}/>
                                                {
                                                    !!canSubmit
                                                    && contentExercise.proximo
                                                    &&
                                                    <>
                                                        { contentExercise.proximo.id_unidade == idUnidade
                                                            ?
                                                            <BackLinkStyle
                                                            size="small"
                                                            className="font-weight-bolder border-0"
                                                            label={`Next task (${contentExercise.proximo.tela} / ${contentExercise.telas_total})`}
                                                            link={`/courses/${courseInfo.id_curso}/unit/${contentExercise.proximo.id_unidade}/view-book/${contentExercise.proximo.secao}/${contentExercise.proximo.ordem}/exercise/${contentExercise.proximo.tela}/student/${idStudent}`}
                                                            iconLeft="arrowRightGreen" />
                                                            :
                                                            <ButtonNextUnit
                                                            size="small"
                                                            className="font-weight-bolder border-0"
                                                            label={`Next task (${contentExercise.proximo.tela} / ${contentExercise.telas_total})`}
                                                            onClick={handleModalNextUnit}
                                                            iconLeft="arrowRightGreen" />
                                                        }
                                                    </>
                                                }
                                            </span>
                                        </FooterStyle>
                                    }
                                </div>
                            </Row>
                        </>
                        }
                    </>
            }

        </Container>
    )
}

export default ExerciseReportPage

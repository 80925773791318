import styled from "styled-components"

const ReportSummaryStyle = styled.div`
    align-items: flex-start;
    border-bottom: 1px solid #E1E6E7;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 25px 20px;
    width: 100%;

    .report {
        height: 40px;
        display: flex; 
        flex-flow: row wrap;
    }
    img {
        margin-right: 15px;
        width: 30px;
    }

    .report-info {
        display: flex;
        flex-flow: column wrap;
        .title {
            color: #989898;
            font-size: 12px;
            font-weight: 400;
        }

        .value {
            color: #565656;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }

`

export default ReportSummaryStyle
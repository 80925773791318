import * as React  from "react"
import {Fragment}  from "react"
import {Row}       from "reactstrap"
import styled      from "styled-components"

import FormWrapper   from "YConnect/Containers/Profile.container/Form.wrapper"
import {ButtonStyle} from "YConnect/Components/Button"

const ParagraphStyle = styled.p`
    color: ${props => props.theme.main.title};
    margin-top: 9px;
`

const GetForms = ({
    isMyProfile,
    formAbout,
    formContact,
    formAccount
}:any) =>
({
    isValidate,
    messageErrorPassword,
    selectData,
    response,
    onChangeAbout,
    onChangeContact,
    onChangeAccount,
    onChangePassword,
    onValidated,
    onResetPassword,
    maskForEmail,
    maskForFields,
}:any) => {
    const values = {
        ...response,
        email_aluno: maskForEmail(response.email_aluno),
        endereco_aluno: maskForFields(response.endereco_aluno),
        cep_aluno: maskForFields(response.cep_aluno),
        telefone_aluno: maskForFields(response.telefone_aluno),
        data_nasc_aluno: "**/**/****",
    };
 
return [{
        keystone : "about-you",
        title    : "About you",
        children : <FormWrapper
                        form        = {formAbout}
                        selectData  = {selectData}
                        values      = {values}
                        isValidate  = {isValidate}
                        onValidated = {onValidated}
                        onChange    = {onChangeAbout}/>
    },
    {
        keystone : "contact-information",
        title    : "Contact Information",
        children : <FormWrapper
                        form        = {formContact}
                        selectData  = {selectData}
                        values      = {values}
                        isValidate  = {isValidate}
                        onValidated = {onValidated}
                        onChange    = {onChangeContact}/>
    },
    {
        keystone : "account-details",
        title    : "Account Details",
        children : <Fragment>
                        <FormWrapper
                            form        = {formAccount}
                            values      = {values}
                            isValidate  = {isValidate}
                            onValidated = {onValidated}
                            onChange    = {onChangeAccount}/>
                    {
                        messageErrorPassword &&
                        <small className="form-text text-danger mb-3">{messageErrorPassword}</small>
                    }
                    {
                        isMyProfile &&
                        <div className="text-right">
                            <ButtonStyle label="Change password" onClick={() => onChangePassword('changePassword')} icon="reset" size="medium" />
                        </div>
                    }

                    {
                        !isMyProfile &&
                        <div>
                            <p>Do you need to change your password?</p>
                            <Row className="">
                                <ButtonStyle className="ml-2 mr-3" label="Reset password" onClick={onResetPassword} icon="reset" size="medium" />
                                <ParagraphStyle className="d-block pl-3 pr-3 mb-3">
                                    By clicking the button, a password reset email will be sent to the user.
                                </ParagraphStyle>
                            </Row>
                        </div>
                    }
                </Fragment>
    }
]}


export default GetForms

import React, { useContext, useEffect, useState } from "react";
import {
    Container, Card,
    Row, Col, Badge
} from "reactstrap";
import { Tab } from "react-bootstrap";
import moment from "moment";

import Loader from "YConnect/Components/Loader";
import { 
        BreadcrumbViewReportFinalTestStudent, 
        BreadcrumbViewReportFinalTestTeacher 
} from "YConnect/Components/BreadCrumbList";

import TabStyle from "YConnect/Styles/Tab.style";

import API from "YConnect/API";
import getConfig from "YConnect/Utils/GetRequestConfig.util";
import DescryptoValue       from "YConnect/Utils/DescryptoValue";

import ScoreFinalTest from "YConnect/PagesComponents/ViewReportFinalTest/ScoreFinalTest";
import ScoreWrittenTest from "YConnect/PagesComponents/ViewReportFinalTest/ScoreWrittenTest";

import UserContext from "YConnect/User.context";
import Toastify from "toastify-js";

type ViewReportFinalTest = {
    match: any,
    location: any
}

const ViewReportFinalTestPage = ({match, location}: ViewReportFinalTest) => {

    const { idCurso, idAluno, idNotaFinal } = match.params;

    const [hasLoading, setHasLoading] = useState<boolean>(false);
    const [reportFinal, setReportFinal] = useState<any>([]);
    const [reportWrittenTest, setReportWrittenTest] = useState<any>([]);

    const userContext: any = useContext(UserContext);

    useEffect(() => {

        if(Object.keys(userContext).length > 0){
            if(reportViewAuth()){
                fetchFinalTestAnswer();
                fetchWrittenTestAnswer();
            } else {
                Toastify({
                    text: "Permission denied to view final test report. Check your user permission or if your user already did the Final Test.",
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
                window.location.href = "#/";
            }
        } else {
            Toastify({
                text: "No User Was Found",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            window.location.href = "#/";
        }

    }, [userContext]);

    const fetchFinalTestAnswer = async () => {
        setHasLoading(true);
        try {
            const response = await API.AssessmentFinalTest.GetFinalTestAnswer({id_curso: idCurso, id_prova_final: idNotaFinal}, getConfig());
            setReportFinal(response.data);
            localStorage.setItem("reportFinalTest", JSON.stringify(response.data));
        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching Final Test Answer. Please check with support.",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        } finally {
            setHasLoading(false);
        }
    }

    const descryptWrittenQuestion = (content:any) => {

        const dataDescrypt = content &&  content.map((elem : object) => {
         elem.enunciado = DescryptoValue.descryptoString( elem.enunciado);        
         elem.conteudo_exercicio = DescryptoValue.descryptoString( elem.conteudo_exercicio);         
         elem.texto = DescryptoValue.descryptoString( elem.texto);         
         return elem;      
        }
       );
      return dataDescrypt;
  }

    const fetchWrittenTestAnswer = async () => {
        setHasLoading(true);
        try {
            const responseQuestion = await API.WrittenTest.GetPerguntaWrittenTestProva({
                id_curso: idCurso,
                id_aluno_prova_writterTest: idAluno
            }, getConfig());
    
            const responseAnswer = await API.WrittenTest.GetRespostaWrittenTest({
                id_curso: idCurso,
                id_aluno: idAluno
            }, getConfig());

            const dataDescrypt =  descryptWrittenQuestion(responseQuestion.data);

            if(responseQuestion.data && responseAnswer.data.obj){
                setReportWrittenTest({
                    question: dataDescrypt[0],
                    answer: responseAnswer.data.obj[0]
                });
            } else {
                Toastify({
                    text: "Could not fetch your written test. Please check with support.",
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            }

        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching written test. Please check with support.",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        } finally {
            setHasLoading(false);
        }
    }

    const reportViewAuth = () => {
        let auth = false;

        //Verificando se quem está tentando acessar o relatório é professor ou orientador
        if(userContext.profiles.indexOf(15) > -1 || userContext.profiles.indexOf(12) > -1){
            auth = true;
        //Verificando se o aluno que está tentando acessar é o mesmo da url
        } else if(userContext.idUsuario == idAluno){
            auth = true;
        }

        //Se nenhuma das opções acima forem verdadeiras o aluno não acessará o relatório
        return auth;
    }

    return (
        !hasLoading ? 
        <Container>
            {reportFinal && reportFinal.length > 0 
            ? userContext.profiles.indexOf(15) > -1 || userContext.profiles.indexOf(12) > -1 
                ? <BreadcrumbViewReportFinalTestTeacher reportFinal={reportFinal[0].provaDTO}/>
                : <BreadcrumbViewReportFinalTestStudent reportFinal={reportFinal[0].provaDTO}/> 
            : <></> }
            { 
                reportFinal && reportFinal.length > 0 && <>
                    <Row className="mb-0 mr-0">
                        <Col md={6} xs={12}>
                            <h2>Report Final Test</h2>
                            <div className="mb-1">
                                <strong>Course: </strong>
                                {reportFinal[0].provaDTO.curso}
                            </div>
                            <div className="mb-1">
                                <strong>Final Test's Date: </strong>
                                {
                                    reportFinal[0].provaDTO.provaFinal.data_finalizacao 
                                    ? moment(reportFinal[0].provaDTO.provaFinal.data_finalizacao).format("DD/MM/YYYY HH:mm:ss")
                                    : "-"
                                }
                            </div>
                            <div className="mb-1">
                                <strong>Written Test's Date: </strong>
                                {
                                    reportFinal[0].provaDTO.provaFinal.data_finalizacao_written_test 
                                    ? moment(reportFinal[0].provaDTO.provaFinal.data_finalizacao_written_test).format("DD/MM/YYYY HH:mm:ss")
                                    : "-"
                                }
                            </div>
                            <div className="mb-1">
                                <strong>Final Score: </strong>
                                {
                                    reportFinal[0].provaDTO.provaFinal.pontuacaoFinal 
                                    ? reportFinal[0].provaDTO.provaFinal.pontuacaoFinal
                                    : "-"
                                }
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="final-score">
                            <div className="card shadow">
                                <h5 className="font-weight-bold mb-4">Report's final score</h5>
                                <div className="d-flex justify-content-between">
                                    <span className="title">
                                        Final Test
                                    </span>
                                    <span className="title">
                                        Written Test
                                    </span>
                                    <span className="title">
                                        Oral Test
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="subtitle small">
                                        Points:&nbsp;
                                        {
                                            reportFinal[0].provaDTO.provaFinal.pontuacao 
                                            ? reportFinal[0].provaDTO.provaFinal.pontuacao
                                            : "-"
                                        } 
                                    </span>
                                    <span className="subtitle small">
                                        Points:&nbsp;
                                        {
                                            reportFinal[0].provaDTO.provaFinal.pontuacaoOpenQuestion 
                                            ? reportFinal[0].provaDTO.provaFinal.pontuacaoOpenQuestion
                                            : "-"
                                        } 
                                    </span>
                                    <span className="subtitle small">
                                        Points:&nbsp;
                                        {
                                            reportFinal[0].provaDTO.provaFinal.pontuacaoOralTest 
                                            ? reportFinal[0].provaDTO.provaFinal.pontuacaoOralTest
                                            : "-"
                                        } 
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            }
            <Row className="mb-5">
                <Col md={12}>
                    <Card className="shadow p-0">
                        <TabStyle defaultActiveKey={'final-test'} onSelect={(eventKey: string) => { }}>
                            <Tab eventKey="final-test" title="Final Test" onEntered={() => {}} className="px-4">
                                {reportFinal && <ScoreFinalTest reportsFinal={reportFinal} idCurso={idCurso} idNotaFinal={idNotaFinal}/>}
                            </Tab>
                            <Tab eventKey="written-test" title="Written Test" onEntered={() => {}} className="px-4">
                                {reportWrittenTest && <ScoreWrittenTest report={reportWrittenTest}/>}
                            </Tab>
                        </TabStyle>
                    </Card>
                </Col>
            </Row>
        </Container>
        : 
        <section className="row">
            <div className="col-12 ">
                <div className="card p-0 shadow d-flex flex-row flex-wrap justify-content-around profile">
                    <Loader />
                </div>
            </div>
        </section>
        )

}

export default ViewReportFinalTestPage;
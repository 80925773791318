import * as React from "react";
import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import styled from "styled-components";

import Loader from "YConnect/Components/Loader";

import PDFViewer from "YConnect/Components/PDFViewer";

import API from "YConnect/API";

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util";

import getBaseURLWebAPI from "YConnect/Utils/GetBaseURLWebAPI.util";

import { Card } from "reactstrap";
import MainTheme from "YConnect/Themes/Main.theme.ts";

function replaceAll(str: string, needle: string, replacement: string) {
    return str.split(needle).join(replacement);
}

const ContainerStyle = styled(Container)`
    margin-top: 15px;

    @media (max-width: 768px) {
        margin-top: 80px !important;
        margin-bottom: 78px !important;
        p {
            font-size: 1.1rem;
        }
    }

    .card {
        width: 100%;

        .title {
            color: ${MainTheme.mainPurple};
            margin-bottom: 2rem;

            h1 {
                font-weight: bold;
            }
        }
    }
`;

const ContentStyle = styled(Container)`
    /* quadro left */
    div {
        display: inline-block !important;
    }

    p,
    h2 {
        text-align: center;
    }

    img {
        min-width: auto !important;
        width: 100%;
    }

    .pressImage {
        object-fit: contain;
        max-height: 27rem;
        height: fit-content;
        margin-bottom: 3rem;
    }
`

const PressPage = ({ match: { params } }: any) => {
    const [data, setData] = useState<any>()
    const [date, setDate] = useState<string>()
    const [response, setResponse] = useState<boolean>()

    const fetch = () => API.Chamada.Get(params)
        .then(({ data }: any) => {
            setData(data)
            setResponse(true)
        })

    useEffect(() => {
        fetch();
    }, [params.id]);

    useEffect(() => {
        if (data) {
            const date = new Date(data.dt_exibicao);
            const day =
                date.getDate() < 9 ? `0${date.getDate()}` : date.getDate();
            const month =
                date.getMonth() + 1 < 9
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1;
            setDate(`${day}/${month}/${date.getFullYear()}`);
        }
    }, [data]);

    if (response) {
        if (data) {
            return <ContainerStyle>
                {/*<div style={{"background":`url('${replaceAll(data.img, "img/", `${getBaseURL()}/img/`)}') center center no-repeat`}}></div>*/}
                <div className="mb-4 mt-4 col-12">
                    {data.unit != null && data.unit.length > 0
                        ? data.unit
                        : null}
                </div>
                <Card className="shadow">
                    <div className="title">
                        <h1>{data.titulo}</h1>
                        {/* <time>{date}</time> */}
                    </div>
                    {data.img != null && data.img.length > 0 && data.img.endsWith('.pdf')
                        ? <PDFViewer file={replaceAll(data.img, "img/", `${getBaseURLWebAPI()}/img/`)} /> /* <iframe className="pressPDF" src={data.pdf} /> */
                        : null}
                    <ContentStyle>
                        {data.img != null && data.img.length > 0 && !data.img.endsWith('.pdf')
                            ? < img className="pressImage" src={replaceAll(data.img, "img/", `${getBaseURLWebAPI()}/img/`)} />
                            : null
                        }
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(data.conteudo) }}></p>
                        </div>
                    </ContentStyle>
                </Card>
            </ContainerStyle>
        }
        return <ContainerStyle>
            <Card className="shadow">
                <div className="title">
                    <h1 style={{fontSize:'2rem'}}>No content</h1>
                       </div>
            </Card>
        </ContainerStyle>
    }
    return <Loader />;
}

export default PressPage;

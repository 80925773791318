import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAll = () => 
    API2
    .Template
    .GetAll({}, getConfig())

export const Create = (template:any) => 
    API2
    .Template
    .Create(template, getConfig())
    
export const UpdateById = (template:any) => 
    API2
    .Template
    .UpdateById(template, getConfig())

export const GetById = (templateId:any) => 
    API2
    .Template
    .GetById({templateId}, getConfig())

export const DeleteById = (templateId:any) => 
    API2
    .Template
    .DeleteById({templateId}, getConfig())
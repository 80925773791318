import * as React from "react"
import {useState, useEffect} from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import ExerciseRowStyle from "YConnect/Fields/Exercises/MatchText.field/ExerciseRow.style"


const  ColStyled= styled(Col)`

display: flex;
padding: 0 !important;

span.option{

    align-self: center;

    @media (min-width: 768px) {
        margin-left: 24px;
    }
}
`

type MatchTextViewProps = {
    question:QuestionType
}

const MatchTextView = ({ question }: MatchTextViewProps) => {

    const [values, setValues] = useState<QuestionType>()
    const [matchTextsByOrder, setMatchTextsByOrder] = useState<any>()

    useEffect(() => {
        if(question) {
            setValues(question)
            const dialogue0 = question.Dialogues[0]
            const {Answers} = question
            if(dialogue0 && dialogue0.DialogueMessages){
                const {DialogueMessages} = dialogue0
                const newMatchTextsByOrder = DialogueMessages
                .reduce((acc:any, dialogueMessage)=>{
                    acc[dialogueMessage.OrderMessage] = {
                        dialogueMessage,
                        answer:Answers.find(({Order}) => dialogueMessage.OrderMessage == Order)
                    }
                    return acc
                },{})

                setMatchTextsByOrder(newMatchTextsByOrder)
            }
        }
    }, [question])

    return <Col md={12} className="p-md-5">
            
            {
                matchTextsByOrder
                && Object
                .keys(matchTextsByOrder)
                .map((order:any, index) =>
                <ExerciseRowStyle className="pt-3" key={index}>
                    <Col className="exercise-dialogue-question" xs="6" sm="6" md="6" lg="6">
                        <span className="option">
                            <span>{ index + 1 }</span>
                        </span>
                        {
                        matchTextsByOrder[order].answer &&
                            <span dangerouslySetInnerHTML={{ __html: matchTextsByOrder[order].answer.Awnser }} />
                        }
                    </Col>
                    <ColStyled className={"exercise-dialogue-question d-md-block"} xs="6" sm="6" md="6" lg="6">
                        <span className="option text-uppercase">
                            <span>
                                { (order*1 + 9).toString(36) }
                            </span>
                        </span>
                        {
                            matchTextsByOrder[order].dialogueMessage &&
                            <span dangerouslySetInnerHTML={{ __html: matchTextsByOrder[order].dialogueMessage.Text }} />
                        }
                    </ColStyled>
                </ExerciseRowStyle>)
            }
    </Col>


}

export default MatchTextView
import * as React from "react"
import { useEffect, useState } from "react"
import { Col } from "reactstrap"

import MatchTextTable from "YConnect/PagesComponents/CMS/Editors/MatchText.editor/MatchText.editableTable"


const changeHtml =(str:string)=>{
    return str.replace(/<p>/g,'').replace(/<\/p>/g,'').replace(/\n/ig,'<br>')
}


type MatchTextEditorProps = {
    question: QuestionType
    onChange:any
}

const MatchTextEditor = ({
    question,
    onChange
}:MatchTextEditorProps) => {

    const [values, setValues] = useState<QuestionType>()
    const [matchTextsByOrder, setMatchTextsByOrder] = useState()

    useEffect(() => {
        if(question) {
            setValues(question)
            const dialogue0 = question.Dialogues[0]
            const {Answers} = question
            if(dialogue0 && dialogue0.DialogueMessages){
                const {DialogueMessages} = dialogue0
                const newMatchTextsByOrder = DialogueMessages
                .reduce((acc:any, dialogueMessage)=>{
                    acc[dialogueMessage.OrderMessage] = {
                        dialogueMessage,
                        answer:Answers.find(({Order}) => dialogueMessage.OrderMessage == Order)
                    }
                    return acc
                },{})

                setMatchTextsByOrder(newMatchTextsByOrder)
            }
        }
    }, [question])

    const handleChangeMatchTexts = (newMatchTextsByOrder:any) => {
        const {
            newDialogueMessages,
            newAnswers
        } = Object.keys(newMatchTextsByOrder)
        .reduce((acc, i) => {

            const newOrder = i*1+1

            const {dialogueMessage, answer}:any = newMatchTextsByOrder[i]
            answer && acc.newAnswers.push({...answer, Order:newOrder, IsCorret:true, Awnser: answer.Awnser? changeHtml(answer.Awnser) : null})
            dialogueMessage ? acc.newDialogueMessages.push({...dialogueMessage, OrderMessage:newOrder, OrderSubMessage:1, Text: dialogueMessage.Text? changeHtml(dialogueMessage.Text) : null})
                : acc.newDialogueMessages.push({OrderMessage:newOrder, OrderSubMessage:1, Text: null})
            return acc
        }, {newDialogueMessages:[], newAnswers:[]})
        
        const newValues = {
            ...question,
            Answers:newAnswers,
            Dialogues:[
                {
                    ...(question.Dialogues[0] || {DialogueAvatars:[], DialogueMessages:[]}),
                    DialogueMessages:newDialogueMessages
                }
            ]
        }

        onChange(newValues)
    }

    return <Col md={12} className="mt-3">
                <h3 className="font-weight-bold">
                    Match Text Editor {matchTextsByOrder && Object.keys(matchTextsByOrder).length > 0 && ` (${Object.keys(matchTextsByOrder).length})`}
                </h3>
                <MatchTextTable
                    matchTextsByOrder={matchTextsByOrder}
                    onChange={(newMatchTextsByOrder:any) => handleChangeMatchTexts(newMatchTextsByOrder)}/>
            </Col>
}


export default MatchTextEditor

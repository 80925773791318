import * as React from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import * as calendar from "YConnect/Assets/svg/calendar.svg"

const DatePickerField = ({
    keystone,
    onChange,
    required,
    label,
    value,
    placeholder,
    className,
    disabled,
    messageBlocker,
    isInvalid,
    id}:any) =>{


    return <div className={`${className || ""}`}>
            <div className={`form-group ${messageBlocker ? 'mb-0' : 'mb-4'} ${disabled ? 'input-disabled' : ''}`}>
                {(label) ? <label className="form-label">{`${label}${required ? "*" : ""}`}</label> : ""}
                <DatePicker
                    onChange={(event:any) => onChange(keystone, event)}
                    placeholderText={placeholder}
                    selected={new Date(value)}
                    dateFormat="dd/MM/yyyy"
                    className={`form-control ${isInvalid === true && "isInvalid"}`}
                    disabled={disabled}
                    id={`${id ? id : keystone}`}
                />
                <img src={calendar} alt="" style={{ position: "absolute", bottom: "35px", right: "25px" }} />
            </div>
            {(messageBlocker) ? <small className="form-text text-muted mb-3 text-clickable">{messageBlocker}</small> : ''}
        </div>
}

export default DatePickerField
import * as React from "react"
import { useState, useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Tooltip } from "reactstrap"

import styled from "styled-components"

import * as pencil from "YConnect/Assets/svg/pencil.svg"

import { BreadcrumbExerciseReport, addBreadcrumbData, BreadcrumbExerciseReportStudent } from "YConnect/Components/BreadCrumbList.tsx"

import PageTitle from "YConnect/Components/PageTitle"
import Loader from "YConnect/Components/Loader"
import ServerErrorPage from "YConnect/Pages/ServerError.page"
import { BackLinkStyle } from "YConnect/Styles/BackLink.style"
import { ButtonStyle, YLinkButtonStyle } from "YConnect/Components/Button"
import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"
import ExerciseTemplate, { getTemplateType } from "YConnect/Enums/ExerciseTemplate"
import DescryptoValue       from "YConnect/Utils/DescryptoValue";

import AttemptsIconStyle from "YConnect/Styles/AttemptsIcon.style"
import HeaderExerciseStyle from "YConnect/Styles/HeaderExercise.style"
import ExerciseTimelineStyle from "YConnect/Styles/ExerciseTimeline.style"
import FormStyle from "YConnect/Styles/Form.style"
import FooterStyle from "YConnect/Styles/Footer.style"
import HomeworkLabelStyle from "YConnect/Styles/HomeworkLabel.style"

import UserContext from "YConnect/User.context"
import useIsStudent from "YConnect/Hooks/useIsStudent"

import capitalize from "lodash/capitalize"
import Breadcrumb from "YConnect/Components/Breadcrumb"
import Toastify from "toastify-js"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 60%;

  textarea {
    width: 100%;
    padding: 1em;
  }

  .open-question__char-counter {
    width: 100%;
    text-align: right;
  }
`

const WrittenTestReviewPage = ({
    match,
    location,
}: any) => {
    const userContext: any = useContext(UserContext)
    const [tooltipOpen, setTooltip] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [contentExercise, setContentExercise] = useState({})
    //TODO Não usado
    const [formValues, setFormValues] = useState()
    const [courseInfo, setCourseInfo] = useState()
    const [bookInfo, setBookInfo] = useState()
    //TODO Student
    const [student, setStudent] = useState()
    const [prevExercises, setPrevExercises] = useState([])
    const [width, setWidth] = useState(window.innerWidth)
    const [dataFinalTest, setDataFinalTest] = useState()
    const [writtenTestScore, setWrittenTestScore] = useState()
    const [answersWT, setAnswersWT] = useState()
    const [disableText, setDisableText] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [notAnswered, setNotAnswered] = useState(false)
    const [lastUpdateGrade, setLastUpdateGrade] = useState(false)
    const [initialValueWT, setInitialValueWT] = useState()
    const [isStudent, setIsStudent] = useState()

    const reportAluno = JSON.parse(localStorage.getItem("reportAluno"))

    const {
        params: { idCurso, idCourse, idUnidade, sectionName, orderId, idExercise, idStudent }
    } = match

    useEffect(() => {
        if (courseInfo && !bookInfo) {
            // fetchBookContent()
        } else if (bookInfo && !contentExercise) {
            // fetchExerciseContent()
        }

        const handleResize = () => {
            setTooltip(false)
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })

    useEffect(() => {
        setLoading(true)
        fetchCourseContent()
        fetchExerciseContentBattery()
        setBookInfo(null)
        setContentExercise(null)
        getPontuacaoFinal()
        fetchUserAnswerFinalTest()
    }, [idCourse])

    useEffect(() => {
        setLoading(true)
        setBookInfo(null)
        setContentExercise(null)
    }, [idUnidade, sectionName, orderId, idStudent])

    useEffect(() => {
        setLoading(true)
        setContentExercise(null)
    }, [idExercise])

    useEffect(() => {
        const { telas_total } = contentExercise || {}
        if (telas_total > 1) {
            Array.from(Array(telas_total).keys()).reduce(async (acc: any, index: any) => {
                const accumulator = await acc
                const { data } = await fetchExerciseHistoryContent(index + 1)
                return Promise.resolve([...accumulator, data.tentativas > 0])
            }, Promise.resolve([]))
                .then(setPrevExercises)
        }
    }, [contentExercise])

    useEffect(() => {
        if (answersWT !== undefined) {
            if (answersWT && answersWT.obj.length > 0 && answersWT.obj[0].resposta.length > 0) {
                setDisableText(true)
            }

            if (answersWT.obj.length == 0) {
                setNotAnswered(true)
            }
        }
    }, [answersWT])

    useEffect(() => {
        if (writtenTestScore > 100) {
            setWrittenTestScore(100)
        }
        if (writtenTestScore < 0) {
            setWrittenTestScore(0)
        }
    }, [writtenTestScore])

    useEffect(() => {
        if (dataFinalTest && dataFinalTest.length > 0) {
            setWrittenTestScore(dataFinalTest[0].pontuacaoOpenQuestion)
            setInitialValueWT(dataFinalTest[0].pontuacaoOpenQuestion)
        }
    }, [dataFinalTest])

    useEffect(() => {
        if(writtenTestScore == initialValueWT) {
            setLastUpdateGrade(true)
        }

        else if (writtenTestScore !== initialValueWT) {
            setLastUpdateGrade(false)
        }
    }, [writtenTestScore, initialValueWT])

    useEffect(() => {
        setIsStudent(useIsStudent(userContext))
    }, [])

    const getBreadcrumbLinks = () => {
        const curso = userContext.cursosVigentes.find(({ id_curso }: any) => id_curso == match.params.idCurso) || {titulo_curso:'Current course'}
        return [
            { path: '/', label: 'Homepage' },
            { path: '/courses', label: 'Courses' },
            { path: `/courses/${match.params.idCurso}/view-book`, label: curso.titulo_curso },
            { path: '', label: 'Written Test Review' }
        ]
    }

    const fetchCourseContent = async () => {
        try {
            const { data } = await API.Course.GetAll({
                id: idCurso,
                isDegustacao: false
            }, getConfig())

            const course = data.find(({ id_curso }: any) => id_curso == idCurso)

            localStorage.setItem("courseInfo", JSON.stringify(course))
            setCourseInfo(course)
        } catch (error) {
            setError(true)
        }
    }

    const getPontuacaoFinal = async () => {
        const { idUsuario } = userContext

        const finalTestScore = await API.AssessmentFinalTest.GetNotasFinal(
            {
                id_curso: idCurso,
                id_aluno_nota_final: idStudent
            },
            getConfig()
        );
        setDataFinalTest(finalTestScore.data)
    }

    const fetchUserAnswerFinalTest = async () => {
        const answers = await API.WrittenTest.GetRespostaWrittenTest({
                            id_aluno: idStudent,
                            id_curso: idCurso,
        }, getConfig())

        setAnswersWT(answers.data)
    }
    const DecryptWrittenTest = (content:any) => {
        const dataDescrypt = content &&  content.map((elem : object) => {
         elem.enunciado = DescryptoValue.descryptoString( elem.enunciado);
         return elem;      
        }
       );
      return dataDescrypt;
  }

    const fetchExerciseContentBattery = async () => {
        try {
            const { idUsuario } = userContext

            const { data } = await API.WrittenTest.GetPerguntaWrittenTestProva({
                id_curso: idCurso,
                id_aluno_prova_writterTest: idStudent
            }, getConfig())
            const dataDecrypted =  DecryptWrittenTest(data);
            setContentExercise(dataDecrypted)
            // setBatteryExercise(data)
            // setTotalQuestions(data.length)
            setLoading(false)
        } catch (error) {
            setError(true)
            setLoading(false)
        }
    }

    const fetchUserReport = async () => {
        try {
            const { data } = await API.ReportIndividual.Get({
                id_aluno: idStudent,
                id_curso: idCourse,
            }, getConfig())

            setStudent(data)
            setLoading(false)
        } catch (e) { }
    }

    const fetchExerciseHistoryContent = (tela: number) => {
        const { titulo_curso } = courseInfo
        const {
            nome_unidade, nome_secao, ordem_exercicio
        } = bookInfo

        return API.Exercicio.getExercicio({
            userId: idStudent,
            idCurso: idCourse,
            curso: titulo_curso,
            unidade: nome_unidade,
            idUnidade: idUnidade,
            secao: nome_secao,
            ordem: ordem_exercicio,
            tela: tela,
            isDegustacao: false
        }, getConfig())
    }

    const renderBookType = () => {
        // const { tipo_exercicio } = contentExercise
        return <HomeworkLabelStyle className="task-card-homework">Review</HomeworkLabelStyle>
    }

    const getTemplate = () => {
        const { tentativas, exercicio_Yconnect } = contentExercise[0]
        const { template_Yconnect } = contentExercise[0]
        const type = getTemplateType(template_Yconnect)

        return [
            {
                "id": "exercise",
                "studentId": idStudent,
                "type": type,
                "attempts": tentativas,
                "isStudent": useIsStudent(userContext),
                "exercise": exercicio_Yconnect,
                "userId": userContext.idUsuario,
                "bookInfo": bookInfo,
                "courseInfo": courseInfo
            }
        ]
    }

    const renderResult = () => {
        const { params } = match

        return <div className="exercise card shadow d-flex flex-row flex-wrap justify-content-between col-12 p-0">
            <div className="w-100 pt-5 pt-lg-0">
                <Col md="12" className="text-center mt-5">
                    <h1>
                        The grade was sent to the student.
                    </h1>
                </Col>
                <Col md="12" className="text-center mt-2">
                You can acess the pontuation on the report score.
                </Col>
                <Col md="12" className="d-none d-md-flex flex-row justify-content-center mt-5 mb-5">
                    <YLinkButtonStyle
                        green
                        size="medium"
                        label="Return to courses"
                        icon="arrowRight"
                        onClick={() => { setShowResult(false) }}
                        link="/courses"
                    />
                </Col>
                <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none mt-5">
                </Col>
                <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none text-center mt-2">
                    <span className="p-2">Or</span>
                </Col>
                <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none mt-2 mb-5">
                    <YLinkButtonStyle
                        green
                        size="medium"
                        label="Return to course"
                        icon="arrowRight"
                        onClick={() => { setShowResult(false) }}
                        link={`/courses/${params.idCurso}/`}
                    />
                </Col>
            </div>
        </div>
    }

    const handleWTGrade = async () => {
        try {
            await API.AssessmentFinalTest.PostAtualizaPontuacao(
                {
                    idProvaFinal: answersWT.obj[0].id_prova_final,
                    pontuacao: writtenTestScore,
                    tipo: 'OQ'
                }, getConfig()).then(() => {
                    Toastify({
                        text: "Success <br> The grade was sent to the student.",
                        duration: 3000,
                        className: "toastify-success",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                    setShowResult(true)
                })
        } catch ({response}) {
            Toastify({
                text: "Please, wait a few seconds and try again.",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }

    const getPageTitle = () => {
        return `Written Test Student Review`
    }

    addBreadcrumbData([{ page: 'ViewReportStudentPage', data: {idCourse, idStudent, reportAluno} }])

    if (error) return <ServerErrorPage location={location} />

    return (
        <Container>
            {
                isLoading
                    ? <Loader />
                    : <>
                        <Breadcrumb links={getBreadcrumbLinks()} />
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                <PageTitle label={getPageTitle()}>
                                    {width > 768 ?
                                        <span className="col-12 mb-4">
                                            {/* Attempts: {getAttempts()} / 2 */}
                                        <AttemptsIconStyle id="TooltipAttempt">
                                                ?
                                        </AttemptsIconStyle>
                                            <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipAttempt" toggle={() => setTooltip(!tooltipOpen)}>
                                                Give a grade for the Written Test of the student, score between 1 and 100.
                                        </Tooltip>
                                        </span>
                                        :
                                        ''
                                    }
                                </PageTitle>
                            </Col>
                        </Row>
                        {
                            showResult
                            ? renderResult()
                            :  <Row className="m-0 mb-5">
                            <div className="exercise card shadow d-flex flex-row flex-wrap justify-content-between col-12 p-0">
                                {renderBookType()}
                                {/* {renderBookLifeTime()} */}

                                {
                                    width <= 768 &&
                                    <span className="col-12 mb-5 mt-5">
                                        {/* Attempts: {getAttempts()} / 2 */}
                                    <AttemptsIconStyle id="TooltipAttempt2">
                                            ?
                                    </AttemptsIconStyle>
                                        <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipAttempt2" toggle={() => setTooltip(!tooltipOpen)}>
                                            You have two attempts on a task. After that, you can try again, but it won’t change your grades. If you need help, ask an Online Teacher!
                                    </Tooltip>
                                    </span>
                                }

                                <HeaderExerciseStyle className="exercise-header d-flex flex-column justify-content-center w-100 pb-3 pl-3 pr-3">
                                    <h1 className="text-center h3 mt-5 mb-3 font-weight-bolder">
                                        Open Question
                                    </h1>
                                    <h3
                                        className="text-center mt-2"
                                        dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(contentExercise[0].enunciado) }}
                                    ></h3>
                                </HeaderExerciseStyle>
                                {
                                    answersWT && answersWT.obj.length > 0 ?
                                    <Wrapper className="open-question__answer">
                                        <textarea
                                            maxLength={4000}
                                            rows={10}
                                            disabled={disableText}
                                            // onChange={onChange}
                                            value={answersWT.obj[0].resposta}>
                                        </textarea>
                                        <div className="open-question__char-counter">
                                            {answersWT.obj[0].resposta.length}
                                        </div>
                                    </Wrapper>

                                    : <Wrapper className="open-question__answer">
                                        <div>
                                            The student doesn't answered the Written Test yet! Please check again later.
                                        </div>
                                    </Wrapper>
                                }
                                {
                                    isStudent
                                    ? 
                                    <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                        <Col sm={{size: 5}}>
                                            <input
                                                className="w-100"
                                                placeholder="Waiting for your teacher corretion"
                                                value={writtenTestScore}
                                                onChange={(e) => setWrittenTestScore(e.target.value)}
                                                type="number"
                                                size={3}
                                                required
                                                disabled={true}
                                            />
                                        </Col>
                                      </FooterStyle>
                                    : <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                        <Col xs={6} sm={{size: 5}} md={{size: 4}} className="d-flex">
                                            <input
                                                placeholder="Type the student score"
                                                value={writtenTestScore}
                                                onChange={(e) => setWrittenTestScore(e.target.value)}
                                                type="number"
                                                size={3}
                                                style={{fontSize: '0.9rem'}}
                                                required
                                                disabled={notAnswered}
                                                className="w-100"
                                            />
                                        </Col>

                                        <Col xs={6} sm={{size: 5, offset: 2}} md={{size: 5, offset: 3}}>
                                            <ButtonStyle
                                                className="float-right"
                                                green
                                                size="large"
                                                disabled={notAnswered || lastUpdateGrade}
                                                label={`Calculate student’s score`}
                                                onClick={handleWTGrade}
                                                icon="checked"
                                            />
                                        </Col>

                                    </FooterStyle>
                                }
                            </div>
                        </Row>
                        }
                    </>
            }

        </Container>
    )
}

export default WrittenTestReviewPage

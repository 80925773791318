import * as React        from "react"
import { CSSTransition } from "react-transition-group"
import { Link }          from "react-router-dom"
import { NavLink }       from "react-router-dom"

import * as arrowRightGreenIcon   from "YConnect/Assets/svg/arrow-right-green.svg"
import * as logoFacebook          from "YConnect/Assets/svg/logos/facebook.svg"
import * as logoTwitter           from "YConnect/Assets/svg/logos/twitter.svg"
import * as logoYoutube           from "YConnect/Assets/svg/logos/youtube.svg"
import * as logoSpotify           from "YConnect/Assets/svg/logos/spotify.svg"

import convertPhoto from "YConnect/Utils/ConvertPhoto.util"

import MobileMenuStyle      from "YConnect/Styles/MobileMenu.style"
import ProfilePicture       from "YConnect/Components/ProfilePicture"
import { ButtonStyle } from "YConnect/Components/Button"

type UserInfoMobileComponentProps = {
    isShow                      : boolean
    user                        : any
    cursoAtualBloqueadoPreTest  : boolean
    configs                     : Array<PageConfig>
    onOpenAccessibility         : Function
    onLogout                    : Function
}

const UserInfoMobileComponent = ({isShow, user, cursoAtualBloqueadoPreTest, configs, onOpenAccessibility, onLogout}:UserInfoMobileComponentProps) => {

    const location = window.location

    return (
        <CSSTransition classNames="dialog lateral-menu-mobile" timeout={300} in={isShow} unmountOnExit>
            <MobileMenuStyle className="YC-lateral lateral-menu-mobile shadow d-flex flex-column" >
                <header className="lateral-header-menu">
                    <div className="d-flex align-items-center flex-row flex-wrap justify-content-start mb-2">
                        <ProfilePicture
                            picture = {convertPhoto(user.foto)}
                            user    = {user.nome}
                            border  = "5px solid #c9c9c9"
                            height  = {50}
                            width   = {50} />

                        <div className="d-flex flex-column flex-wrap pl-2 mt-2">
                            <span className="font-weight-bold">{user.nome}</span>
                            <span className="font-weight-bold text-clickable small">{user.email}</span>
                        </div>
                    </div>
                    <Link to="/my-profile" className="mt-4 profile-link text-clickable font-weight-bold">
                        Consult or edit your profile
                        <img src={arrowRightGreenIcon} className="img-fluid pl-3" />
                    </Link>
                </header>

                <span
                    className="profile-link menu-separator"
                    onClick={() => onOpenAccessibility()}>
                    Accessibility
                    <img src={arrowRightGreenIcon} className="img-fluid pl-3" />
                </span>

                <div className="lateral-body-menu">
                    {!cursoAtualBloqueadoPreTest ?
                        <div className="body-link">

                            <NavLink to="/">
                                <span className="body-link--span">
                                    Homepage
                                </span>
                            </NavLink>
                            {
                                configs
                                    .map(
                                        ({ label, path }: any, key) => {
                                            if (path === '/online-teacher') {
                                                if (location.href.includes('pre_post/test')) {
                                                    return true
                                                }
                                            }

                                            return (
                                                <NavLink key={key} to={`${path}`}>
                                                    <span className="body-link--span">
                                                        {label}
                                                    </span>
                                                </NavLink>
                                            )
                                        }
                                    )
                            }

                        </div>
                        :
                        ''
                    }
                    <div className="body-link">
                        <NavLink to="/support">
                            <span className="body-link--span">
                                Support
                            </span>
                        </NavLink>
                        <NavLink to="/about">
                            <span className="body-link--span">
                                About YConnect
                            </span>
                        </NavLink>
                        <a href="http://www.placement.yazigi.com.br" target="_blank">
                            <span className="body-link--span">
                                Test your English
                            </span>
                        </a>
                    </div>
                    <div className="lateral-social-media">
                        <a href="https://www.facebook.com/yazigi.oficial?fref=ts" target="_blank"><img src={logoFacebook} className="social-media-img" /></a>
                        <a href="https://twitter.com/yazigioficial" target="_blank"><img src={logoTwitter} className="social-media-img" /></a>
                        <a href="https://www.youtube.com/user/yazigioficial" target="_blank"><img src={logoYoutube} className="social-media-img" /></a>
                        <a href="https://open.spotify.com/user/yazigi.oficial/playlist/4jH6HlSiFQWq1neTmiyJe6" target="_blank"><img src={logoSpotify} className="social-media-img" /></a>
                    </div>
                    <div className="lateral-footer-menu">
                        <ButtonStyle green
                            
                            label    = "Logout"
                            icon     = "logout"
                            onClick  = {onLogout}
                            
                            maxWidth = {true}/>
                    </div>
                    
                </div>
            </MobileMenuStyle>
        </CSSTransition>
    )
}

export default UserInfoMobileComponent

import * as React from "react"
import {
    useEffect,
    useRef,
    useContext,
    useState
} from "react"
import { CSSTransition } from "react-transition-group"
import ReactPaginate from "react-paginate";

import { saveAs } from "file-saver"

import API from "YConnect/API"
import getBaseURLWebApi from "YConnect/Utils/GetBaseURLWebAPI.util"

import { LinkStyle } from "YConnect/Components/Button"
import Table from "YConnect/Components/Table"

import AlphaBlackStyle from "YConnect/Styles/AlphaBlack.style"

import * as downloadGreen from "YConnect/Assets/svg/download.svg"

import UserContext from "YConnect/User.context";

import styled from "styled-components";
import parseFileName from "YConnect/Utils/ParseFileName.util";
import getStaticUrl    from "YConnect/Utils/GetStaticUrl.util"
import Toastify from "toastify-js";


const StyledDiv = styled.div`

    .page{
        padding: 5px 8px;

        a:hover,:hover{
            text-decoration: none;
        }
    }

    .previous, .next, .page{
        a{
            padding: 5px 8px;
        }
        a:hover,:hover{
            text-decoration: none;
        }
    }
`


const CONFIG_TABLE_BOOK: Array<ColumnConfigType> = [
    {
        label: "Title & video",
        formatter: (data: any) => <div className="download-title" title={data.title}>{data.title}<br />
            <small title={parseFileName(data.name_File)} className="text-muted">{parseFileName(data.name_File)}</small><br />
            <small title={parseFileName(data.name_File)} className="text-muted">Extension: {data.name_File.split('.').pop()}</small></div>
    },
    {
        label: "Video",
        formatter: (data: any) => {
            return <div
                className="pointer text-clickable"
                onClick={() => downloadItem(data.id_Resource, data.name_File)}
            >
                Download <img src={downloadGreen} />
            </div>
        }
    }
]

const downloadItem = async (idResource: number, fileName: string) => {

    const idUsuario = JSON.parse(localStorage.getItem('data')).idUsuario;

    try {
        if (fileName.includes("midia:")) {
            saveAs(getStaticUrl(fileName), parseFileName(fileName));
        } else {
            API.Downloads.ActiveTeachDownload({
                idUsuario: idUsuario,
                idResource: idResource
            }, {
                headers: {
                     "Authorization": localStorage.getItem("Authorization")
                }
            })
            .then((res: any) => {
                saveAs(`${getBaseURLWebApi()}${res.data}`);
            })
            .catch((err: any) => {
                Toastify({
                    text: "Error! <br>" + err.response.data.message,
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            });
        }
      } catch (err) {
        Toastify({
            text: "Error! <br>" + err.response.data.message,
            duration: 3000,
            className: "toastify-error",
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
            escapeMarkup: false,
            onClick: function(){} 
          }).showToast();
      }
}

const VideoFileModal = ({ onDownloadMenu, hasDownloadMenu, downloadItems }: any) => {

    const [filteredItems, setFilteredItems] = useState();
    const [paginatedItems, setPaginatedItems] = useState();
    const [isFiltering, setIsFiltering] = useState(false);

    const {
        idProfessor,
        observador,
        colaborador,
        usuarioTrial,
        usuarioDegustacao,
        idCursoAtual,
        idUsuario
    }: any = useContext(UserContext);

    const paginateItems = (data: any, array?: any) => {
        const items = array ? array : filteredItems;
        const paginated = items.slice(
            data.selected * 10,
            (data.selected + 1) * 10
        );
        setPaginatedItems(paginated);
    };

    useEffect(() => {
        if (!isFiltering) {
            setPaginatedItems(downloadItems);
            setIsFiltering(true)
        }
        setFilteredItems(downloadItems);
    })

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onDownloadMenu(false)
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const isStudent = () =>
        idProfessor == 0 &&
        !observador &&
        !colaborador &&
        !usuarioTrial &&
        !usuarioDegustacao;

    const CourseDownloadContent = (helperClass?: string) => <>
        {
            window.innerWidth > 768 ? (
                <StyledDiv className={`table-responsive download-menu ${helperClass}`}>
                    {paginatedItems &&
                    <>
                        <Table data={!isStudent() ? paginatedItems : paginatedItems.filter(x => x.isForStudent === true)} config={CONFIG_TABLE_BOOK} />
                        <ReactPaginate
                            previousClassName={"previous"}
                            nextClassName={"next"}
                            pageLinkClassName={"page"}
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(
                                filteredItems && filteredItems.length / 10
                            )}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            containerClassName={"pagination YC-Pagination"}
                            activeClassName={"active"}
                            onPageChange={paginateItems}
                        />
                    </>
                    }
                </StyledDiv>
            )
                :
                downloadItems.map((item: any, key: any) => {
                    return (
                        <div className="download-mobile" key={key}>
                            <div className="book" style={{
                                borderBottom: '1px solid #f4f4f4',
                                marginBottom: 30,
                                paddingBottom: 30
                            }}>
                                <div className="book-row d-flex flex-row" style={{
                                    marginBottom: 20
                                }}>
                                    <span className="w-40">Title & file</span>
                                    <span className="w-40">
                                        {item.title} <br />
                                        <small>{parseFileName(item.name_File)}</small>
                                    </span>
                                </div>
                                <div className="book-row d-flex flex-row">
                                    <span className="w-40">File</span>
                                    <div
                                        className="text-clickable pointer w-40 d-flex flex-row align-items-center"
                                        onClick={() => downloadItem(item.id_Resource, item.name_File)}
                                    >
                                        Download <img src={downloadGreen} className="pl-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
        }
    </>

    const isMobile = window.innerWidth < 768;
    return (
        <>
            {hasDownloadMenu && <AlphaBlackStyle />}
            <CSSTransition classNames="dialog YC-lateral-menu" timeout={300} in={hasDownloadMenu} unmountOnExit>
                <div
                    style={{
                        zIndex: 10000,
                        overflowY: 'scroll',
                        width: isMobile ? '100vw' : '650px'
                    }}
                    className={`YC-lateral lateral-header lateral-header-course d-flex flex-column`}
                    ref={wrapperRef}
                >
                    <h4 className="lateral-header--title">
                        Downloads
                    <LinkStyle
                            label="Close x"
                            className={'close-modal text-right text-right header-btn'}
                            onClick={() => onDownloadMenu(!hasDownloadMenu)} />
                    </h4>
                    {CourseDownloadContent()}
                </div>
            </CSSTransition>
        </>
    )
}

export default VideoFileModal

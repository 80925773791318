import styled from "styled-components"

import * as checked  from "YConnect/Assets/svg/checkedGreen.svg"
import * as remove   from "YConnect/Assets/svg/closeRed.svg"

const ExerciseOptionStyle = styled.div `
    margin: 0 0px 6px 0px;
    padding: 7px;
    padding-left: 20px;
    height: 100%;
    background-color: ${props => props.theme.main.backgroundPage};
    border: 1px solid ${props => props.theme.main.lightGray};
    border-radius: 5px;
    cursor: pointer;

    .enable-link {
        pointer-events: visible;
        cursor: pointer;

        span {
            position: absolute;
            margin-left: -13px;
            padding: 10px 0;
            border-radius: 3px;

            &::before {
                position: relative;
                top: 1px;
                left: 10px;
                display: block;
                width: 20px;
                height: 15px;
                margin-right: 20px;
                cursor: row-resize;
                background: -webkit-linear-gradient(top, ${props => props.theme.main.textColor}, ${props => props.theme.main.textColor} 20%, ${props => props.theme.main.backgroundPage} 0, ${props => props.theme.main.backgroundPage} 40%, ${props => props.theme.main.textColor} 0, ${props => props.theme.main.textColor} 60%, ${props => props.theme.main.backgroundPage} 0, ${props => props.theme.main.backgroundPage} 80%, ${props => props.theme.main.textColor} 0, ${props => props.theme.main.textColor});
                background: linear-gradient(180deg,${props => props.theme.main.textColor},${props => props.theme.main.textColor} 20%,${props => props.theme.main.backgroundPage} 0,${props => props.theme.main.backgroundPage} 40%,${props => props.theme.main.textColor} 0,${props => props.theme.main.textColor} 60%,${props => props.theme.main.backgroundPage} 0,${props => props.theme.main.backgroundPage} 80%,${props => props.theme.main.textColor} 0,${props => props.theme.main.textColor});
                content: "";
            }
        }
    }

    p {
        margin: 0;
        color: ${props => props.theme.main.textColor};
        font-size: 14px;
        text-align: left;
        padding: 6px 0 0 40px;
        width: 100% !important;
        height: 100%;
        padding-right: 30px;
        text-align: justify;
    }

    &.right {
        border-color: ${props => props.theme.main.successColor};

        &::before {
            content: url(${checked});
            position: absolute;
            filter: ${props => props.theme.main.filterSvg};
            top: 10px;
            right: 0;
            margin-top: 0px;
            transform: scale(0.7);
            margin-right: 25px;
        }
    }

    &.wrong {
        border-color: ${props => props.theme.main.errorColor};

        &::before {
            content: url(${remove});
            position: absolute;
            filter: ${props => props.theme.main.filterSvg};
            top: 18px;
            right: 0;
            margin-top: 0px;
            transform: scale(0.8);
            margin-right: 30px;
        }
    }
`

export default ExerciseOptionStyle
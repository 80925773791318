import * as React   from "react"
import { Modal }    from "react-bootstrap"

import { YLinkButtonStyle }  from "YConnect/Components/Button"
import { BackLinkStyle }     from "YConnect/Styles/BackLink.style"

type PostTestModalProps = {
    isShow: boolean
    onClose: () => void
}

const PrePostTestExit = ({ isShow, onClose }: PostTestModalProps) =>
    <Modal show={isShow} onHide={onClose} size="lg" centered>
        <Modal.Header closeLabel="Close" closeButton>
            Are you sure you want to leave?
        </Modal.Header>
        <Modal.Body className="pb-4 pt-3">
            If you leave the test before submitting, you will have to star over from the beginning. :(
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between pt-4">
            <BackLinkStyle
                link  = "/"
                icon  = "arrowPrevious"
                label = "Leave" />
            <YLinkButtonStyle
                green
                size    = "medium"
                link    = "#"
                icon    = "arrowRight"
                onClick = {onClose}
                label   = "Continue the test" />
        </Modal.Footer>
    </Modal>

export default PrePostTestExit

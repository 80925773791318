import * as React from "react"
import {useState, useEffect} from "react"
import { Col, Tooltip } from "reactstrap"

import ExerciseDialogueStyle     from "YConnect/Fields/Exercises/DialoguePhrase.field/ExerciseDialogue.style"
import ExerciseRowStyle          from "YConnect/Fields/Exercises/DialoguePhrase.field/ExerciseRow.style"
import getStaticUrlAssets from "YConnect/Utils/GetStaticUrlAssets.util"

const setClass = (index: number) => {
    return index % 2 === 0 ? "" : "reverse"
}

import * as userBlue from "YConnect/Assets/svg/user-blue.svg"

type DialogueViewProps = {
    question:QuestionType
}

const DialogueView = ({ question }: DialogueViewProps) => {

    const [values, setValues] = useState<QuestionType>()
    const [dialogueMessageByOrder, setDialogueMessageByOrder] = useState<any>()

    useEffect(()=>{
        if(question){
            setValues(question)
        }
    }, [question])

    
    useEffect(()=>{
        if(values && values.Dialogues[0] && values.Dialogues[0].DialogueMessages){

            const {DialogueMessages} = values.Dialogues[0]
            const {newDialogueMessageByOrder} = DialogueMessages
            .reduce((acc:any, value) => {
                if(!acc.newDialogueMessageByOrder[value.OrderMessage]){
                    acc.newDialogueMessageByOrder[value.OrderMessage] = []
                }
                if(value.Text === null){
                    acc.countAnswerOrder++;
                    acc.newDialogueMessageByOrder[value.OrderMessage].push({...value,  AnswerOrder:acc.countAnswerOrder})
                }else{
                    acc.newDialogueMessageByOrder[value.OrderMessage].push(value)
                }
                return acc
            }, {newDialogueMessageByOrder:{}, countAnswerOrder:0})
            setDialogueMessageByOrder(newDialogueMessageByOrder)
            
        }
    }, [values])

    const getAvatar = (Order:number) => {
        const {DialogueAvatars} = values.Dialogues[0]

        if(DialogueAvatars){
            return !(Order % 2 === 0 )
            ? (DialogueAvatars.find(({Order}) => Order === 1)||{}).Avatar
            : (DialogueAvatars.find(({Order}) => Order === 2)||{}).Avatar
        }


    }
    
    return <div className="pl-3 pr-3 pl-md-5 pr-md-5 pt-5">
            
            {
                dialogueMessageByOrder
                && Object
                .keys(dialogueMessageByOrder)
                .map((OrderMessage:any, index) =>
                    <ExerciseRowStyle key={index} className={`${setClass(index)}`}>
                        <Col
                            className = "exercise-dialogue-username font-weight-bolder d-md-none"
                            xs        = "12">
                            { getAvatar(OrderMessage) && <span>{getAvatar(OrderMessage).Name}</span>}
                        </Col>
                        <Col
                            className = "exercise-dialogue-photo mb-2 mb-md-1 text-left text-md-center"
                            xs        = "1"
                            sm        = "1" 
                            md        = "2" 
                            lg        = "2" 
                            xl        = "2">
                            {
                                getAvatar(OrderMessage)
                                    ? <>
                                            <img  src={getStaticUrlAssets(getAvatar(OrderMessage).Source)} alt=""/>
                                            <span className="ml-1 ml-md-0 d-none d-md-block">{getAvatar(OrderMessage).Name}</span>
                                        </>
                                    : <img src={userBlue} alt=""/>
                            }
                        </Col>
                        <ExerciseDialogueStyle
                            className = {`${setClass(index)}`}
                            xs        = "10" 
                            sm        = "10" 
                            md        = "10" 
                            lg        = "10" 
                            xl        = "10">
                            <div className={`exercise-dialogue-text ${setClass(index)}`}>
                                <div className="text">
                                    {
                                        dialogueMessageByOrder[OrderMessage]
                                        .map(({Text, AnswerOrder}:any) => {
                                            return Text 
                                            ?<p className="mb-0">{Text}</p>
                                            : <span className="resposta">
                                                {(values.Answers.find(({Order}) => Order === AnswerOrder)||{}).Awnser}
                                            </span>
                                        })
                                    }
                                </div>
                            </div>
                        </ExerciseDialogueStyle>
                    </ExerciseRowStyle> )
            }
    </div>


}

export default DialogueView
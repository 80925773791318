
import styled from "styled-components"

const FooterMessageStyle = styled.footer`
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    @media screen and (min-width: 1024px) {
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between
    }

    .get-better-content {
        color: ${props => props.theme.main.textColor};
        text-align: center;

        @media screen and (min-width: 1024px) {
            text-align: left;
            width: 60%;
        }
    }

    .get-better-cta {
        @media screen and (min-width: 1024px) {
            width: 40%;
        }
    }
`


export default FooterMessageStyle

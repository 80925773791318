
export default [
    {
        keystone: "Title",
        className: "col-md-12",
        type: "input",
        label: "Title"
    },
    {
        keystone: "Author",
        className: "col-md-6",
        type: "input",
        label: "Author"
    },
    {
        keystone: "Publishing",
        className: "col-md-6",
        type: "input",
        label: "Publishing"
    },
    {
        keystone: "Description",
        className: "col-md-12",
        type: "textarea",
        label: "Description"
    }
]
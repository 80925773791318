import * as React from "react"
import {
    Row,
    Col
} from "reactstrap"
import styled from "styled-components"
import Table from "YConnect/Components/Table"

import { ButtonStyle } from "YConnect/Components/Button"
import useEditableTableState from "YConnect/PagesComponents/CMS/Hooks/useEditableTableState"

const TableStyled = styled(Table)`
    .table{
        min-height: auto;
    }
    td, th {
        padding:10px;
    }
`

const INITIAL_DATA_STATE = [
    {
        type:"NEW",
        row:{}
    }
]

const GetStateByValues = (values:Array<DialogueMessageType>) => 
    values && values.length > 0
    ? [...values.map((value) => ({type:"ADDED", row: {...value, ToComplete: value.Text == null }  })), {type:"NEW",row:{}}]
    : INITIAL_DATA_STATE


type MessageEditableTableProps = {
    values?:Array<DialogueMessageType>
    onChange:any
}
    
const MessageEditableTable = ({
    values,
    onChange
}:MessageEditableTableProps) => {

    const {
        dataState,
        getValues,
        updateLine,
        addDataNewLine,
        removeLine,
        setDataState
    } = useEditableTableState(GetStateByValues(values))

    const handleUpdateRow = (key:number, property:string, value:any) => {
        if(property === "ToComplete"){
            const nToComplete = dataState.filter(({row:{ToComplete}}) => ToComplete).length
            if(nToComplete < 1 || !value){
                const values  = updateLine(key, property, value)
                onChange(values)
            }
        }else{
            const values  = updateLine(key, property, value)  
            onChange(values)
        }
    }
    const handleRemoveRow = (key:number) => {
        const values  = removeLine(key)
        onChange(values)
    }

    const config : Array<ColumnConfigType> = [
        {
            label     : "To complete",
            formatter : (state:any, key:number) => 
                <input 
                    type="checkbox" 
                    onChange={({ target: { checked } }) => handleUpdateRow(key, "ToComplete", checked)} 
                    checked={!!state.row["ToComplete"]}/>
        },
        {
            label     : "Message",
            formatter : (state:any, key:number) => 
            !!state.row["ToComplete"]
            ? <input 
                    disabled
                    type="text" 
                    className="form-control" 
                    value=""/>
            :     <input 
                    type="text" 
                    className="form-control" 
                    onChange={({ target: { value } }:any) => handleUpdateRow(key, "Text", value)}
                    value={state.row["Text"] || ""}/>
        },
        {
            formatter : (state:any, key:any) => 
            <Col>
                {
                    state.type === "ADDED"
                    && <Row className="px-0">
                        <ButtonStyle 
                            size      = "forTable"
                            className = "mr-2"
                            icon      = "remove"
                            onClick   = {() => handleRemoveRow(key)}/>
                    </Row>
                }
            </Col>
        }
    ]

    return <TableStyled 
                data={dataState}
                config={config} />
}

export default MessageEditableTable
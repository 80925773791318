import * as React from "react"
import { useEffect, useState }  from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"


import * as clock    from "YConnect/Assets/svg/clock.svg"
import * as test from "YConnect/Assets/svg/test.svg"
import * as calendar from "YConnect/Assets/svg/calendar-report.svg"

import API from "YConnect/API"

import getConfig  from "YConnect/Utils/GetRequestConfig.util"
import LineProgressStyled from "YConnect/Styles/LineProgress.style"

const fixDate = (date: string) => {
    const receivedDate = new Date(date);
    return ('0' + receivedDate.getDate()).slice(-2) + '/' + ('0' + (receivedDate.getMonth() + 1)).slice(-2) + '/' + receivedDate.getFullYear();
}

const ResultStyle = styled.div`
    color: ${props => props.theme.main.textColor};
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    margin: 20px 0;
    text-decoration: none;
    width: 100%;
    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }
    @media screen and (min-width: 1024px) {
        margin: 20px 15px;
        width: calc(50% - 30px);
    }
`

const ResultCardContent = styled.div`
    align-items: center;
    border: 1px solid #E1E6E7;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 260px;
`

const ProgressPercent = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 30px 18px;
    width: 100%;

    .percent {
        width: 100%;
        @media screen and (min-width: 1024px) {
            width: 60%;
        }
    }

    .percent-title {
        font-size: 42px;
        margin-top: 10px;
    }


    .percent-progress {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 14px;
        position: relative;
        width: 100%;
        color: #565656;
    }
    .percent-counter {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .overall {
        align-items: flex-end;
        margin-left: 24px;
        text-align: center;
        width: 100%;
        @media screen and (min-width: 1024px) {
            width: calc(40% - 24px);
        }

        small {
            color: #565656;
            font-size: 17px;
            font-weight: 600;
        }
    }
`

const ReportSummary = styled.div`
    align-items: flex-start;
    border-bottom: 1px solid #E1E6E7;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 25px 20px;
    width: 100%;

    .report {
        height: 40px;
        display: flex; 
        flex-flow: row wrap;
        width: 100%;
        @media screen and (min-width: 768px) {
            width: 45%;
        }
        @media screen and (min-width: 1024px) {
            height: auto;
            margin-left: 0;
            width: 33%;
        }
    }
    img {
        margin-right: 15px;
        width: 30px;
    }

    .report-info {
        display: flex;
        flex-flow: column wrap;
        .title {
            color: #989898;
            font-size: 12px;
            font-weight: 400;
        }
    
        .value {
            color: #565656;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }

`

type AverageResultProps = {
    idCourse?: string | number;
    idAluno? : string | number;
    idMatricula? : string | number;
    className?: string;
}

const AverageResultStudent = ({className, idCourse, idAluno, idMatricula}: AverageResultProps) => {
    
    const [preTest, setPreTest] = useState();
    const [postTest, setPostTest] = useState();
    
    useEffect(() => {
        API.PrePostReport.GetStudentReport({
            idCurso      : idCourse,
            idAluno      : idAluno,
            isAluno      : true,
            id_matricula : idMatricula
        }, getConfig())
        .then((res: any) => {
            setPreTest(res.data.relatorio.filter((el: any) => el.fl_is_pre)[0]);
            setPostTest(res.data.relatorio.filter((el: any) => !el.fl_is_pre)[0])
        })
    }, [])

    return <Row>
        <Col xs={12}>
            <div className={`${className ? className : 'card shadow'} d-flex flex-row flex-wrap justify-content-between`}>

                <ResultStyle>
                    <h2>Pre test score</h2>
                    <ResultCardContent>
                        {preTest 
                        ? <>
                                <ReportSummary>
                                    <div className="report">
                                        <img src={calendar} alt="" />
                                        <div className="report-info">
                                            <span className="title">Date:</span>
                                            <span className="value">{fixDate(preTest.data_inicio)}</span>
                                        </div>
                                    </div>
                                    <div className="report">
                                        <img src={clock} alt="" />
                                        <div className="report-info">
                                            <span className="title">Duration:</span>
                                            <span className="value">{Math.ceil(preTest.tempo_aluno / 60)} minutes</span>
                                        </div>
                                    </div>
                                    <div className="report">
                                        <img src={test} alt="" />
                                        <div className="report-info">
                                            <span className="title">Correct answers:</span>
                                            <span className="value">{preTest.qts_acertos} of {preTest.qtde_total_questoes}</span>
                                        </div>
                                    </div>
                                </ReportSummary>

                                <ProgressPercent>
                                    <div className="percent">
                                        <LineProgressStyled>
                                            <span
                                                className="progress-percentage"
                                                style={{ marginLeft: `calc(${preTest.media_aluno}* 85%)` }}
                                            >
                                                {(preTest.media_aluno * 100).toFixed(1)}%
                                            </span>
                                            <div className="line" />
                                            <div
                                                className="line-progress"
                                                style={{ width: `calc(${preTest.media_aluno}* 100%)` }}
                                            />
                                            <div
                                                className="pointer-progress"
                                                style={{ marginLeft: `calc(${preTest.media_aluno}* 100%)` }}
                                            />
                                            <div className="d-flex justify-content-between">
                                                <span className="line-start">0</span>
                                                <span className="line-start" style={{ marginLeft: `calc(0.70 * 100%)` }}>70%</span>
                                                <span className="line-start">100%</span>
                                            </div>
                                        </LineProgressStyled>
                                    </div>
                                    <div className="overall">
                                        <small>Overall percentage:</small>
                                        <h3 className="percent-title" style={{color: preTest.media_aluno > 0.7 ? '#00A281' : '#FF1A4A'}}>{(preTest.media_aluno * 100).toFixed(1)}%</h3>
                                    </div>
                                </ProgressPercent>
                            </> 
                        : <p className="text-center w-100">There are not a pre-test done!</p>
                        }
                    </ResultCardContent>
                </ResultStyle>

                <ResultStyle>
                    <h2>Post test score</h2>
                    <ResultCardContent>
                        {postTest 
                        ? <>
                                <ReportSummary>
                                    <div className="report">
                                        <img src={calendar} alt="" />
                                        <div className="report-info">
                                            <span className="title">Date:</span>
                                            <span className="value">{fixDate(postTest.data_inicio)}</span>
                                        </div>
                                    </div>
                                    <div className="report">
                                        <img src={clock} alt="" />
                                        <div className="report-info">
                                            <span className="title">Duration:</span>
                                            <span className="value">{Math.ceil(postTest.tempo_aluno / 60)} minutes</span>
                                        </div>
                                    </div>
                                    <div className="report">
                                        <img src={test} alt="" />
                                        <div className="report-info">
                                            <span className="title">Correct answers:</span>
                                            <span className="value">{postTest.qts_acertos} of {postTest.qtde_total_questoes}</span>
                                        </div>
                                    </div>
                                </ReportSummary>

                                <ProgressPercent>
                                    <div className="percent">
                                        <LineProgressStyled>
                                            <span
                                                className="progress-percentage"
                                                style={{ marginLeft: `calc(${postTest.media_aluno}* 85%)` }}
                                            >
                                                {(postTest.media_aluno * 100).toFixed(1)}%
                                            </span>
                                            <div className="line" />
                                            <div
                                                className="line-progress"
                                                style={{ width: `calc(${postTest.media_aluno}* 100%)` }}
                                            />
                                            <div
                                                className="pointer-progress"
                                                style={{ marginLeft: `calc(${postTest.media_aluno}* 100%)` }}
                                            />
                                            <div className="d-flex justify-content-between">
                                                <span className="line-start">0</span>
                                                <span className="line-start" style={{ marginLeft: `calc(0.70 * 100%)` }}>70%</span>
                                                <span className="line-start">100%</span>
                                            </div>
                                        </LineProgressStyled>
                                    </div>
                                    <div className="overall">
                                        <small>Overall percentage:</small>
                                        <h3 className="percent-title" style={{color: postTest.media_aluno > 0.7 ? '#00A281' : '#FF1A4A'}}>{(postTest.media_aluno * 100).toFixed(1)}%</h3>
                                    </div>
                                </ProgressPercent>
                            </> 
                        : <p className="text-center w-100">There are not a post-test done</p>
                        }
                    </ResultCardContent>
                </ResultStyle>



            </div>
        </Col>
    </Row>
}

export default AverageResultStudent
import styled from "styled-components"

const TeacherInfoStyle = styled.div`
    display: flex;
    flex-flow: row wrap;

    figure {
        display: block;
        margin: auto;
        @media screen and (min-width: 1024px) {
            margin: 0;
            width: 50%;
        }
    }

    .teacher-data {
        
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        @media screen and (min-width: 1024px) {
            margin: 16px 0;
            width: 50%;
        }

        span{
            color: #989898;
            font-size: 12px;
            font-weight: 400;
        }

        h4 {
            color: #565656;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.4;
            text-align: center;
            @media screen and (min-width: 1024px) {
                text-align: left;
            }
        }

        small {
            color: #989898;
            font-size: 14px;
        }
    }
`
export default TeacherInfoStyle
import * as React from "react"


class InputField extends React.Component<InputFieldPropsType, {}>{

  handleChange(event: any) {
    const { props: { keystone } } = this
    const { target: { value } } = event
    this.props.onChange(keystone, value)
  }

  render() {
    let {
      props: {
        keystone,
        required,
        label,
        value,
        placeholder,
        className,
        disabled,
        messageBlocker,
        isInvalid,
        id
      },
      handleChange
    } = this
    
    return <div className={`${className || ""}`}>
      <div className={`form-group is-invalid ${messageBlocker ? 'mb-0' : 'mb-4'} ${disabled ? 'input-disabled' : ''}`}>
        {(label) ? <label className="form-label">{`${label}${required ? "*" : ""}`}</label> : ""}
        <input
          type="text"
          className={`form-control ${isInvalid === true && "isInvalid"}`}
          defaultValue={value ? value : ''}
          onChange={handleChange.bind(this)}
          placeholder={placeholder}
          disabled={disabled}
          id={`${id ? id: keystone}`}
        />
      </div>
      {(messageBlocker) ? <small className="form-text text-muted text-right mb-3 text-clickable">{messageBlocker}</small> : ''}
    </div>
  }
}

export default InputField
import * as React from "react"
import { useState, useEffect } from "react"

import { ButtonStyle } from "YConnect/Components/Button"
import Table from "YConnect/Components/Table"
import API from "YConnect/API"
import SelectFieldStyle from "YConnect/Styles/SelectField.style"
import Loader from "YConnect/Components/Loader"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

import CONFIG_TABLE_GRADE_SENDING from "YConnect/Configs/Tables/GradeSending.table.config"
import Toastify from "toastify-js"

const getOrdemEtapa = (unitsParam: string) => {
    switch (unitsParam) {
        case "Units 1 - 3":
            return 1
        case "BLUE - PURPLE":
            return 1
        case "Units 4 - 8":
        case "Units 4 - 6":
            return 2
        case "ORANGE - PINK":
            return 2
        case "Units 1 - 8 (2nd Chance)":
        case "Recuperação":
            return 3
        default:
            return -1
    }
}

const preparaForTable = ({ unitsParam, setCurrentCheckInfo, idCurso, handleTryAgain }: any) =>
    ({ id_aluno, nome_aluno, reportEtapa, reportUnidade, id_curso, nome_curso }: any) => {

        const {
            error,
            data_envio,
            etapa_enviada,
            nota,
            unidade_inicial,
            unidade_final
        } = (reportEtapa
            .find(
                ({ descricao }: any) =>
                    descricao.split(" ").join("").toUpperCase() === unitsParam.split(" ").join("").toUpperCase())
            || {})

        const unitsScore = reportUnidade.filter(({ unidade }: any) => unidade >= unidade_inicial && unidade <= unidade_final)
            .map(({ unidade, finalScore }: any) => ({ unidade, finalScore }))
        return {
            onChangeCheck: setCurrentCheckInfo,
            checked: false,
            id_aluno,
            idCurso,
            id_curso,
            nome_curso,
            units: unitsParam,
            etapa_enviada,
            nome_aluno,
            unitsScore,
            error,
            data_envio,
            finalScore: nota,
            onTryAgain: handleTryAgain(id_aluno, id_curso)
        }
    }

const GradeSending = ({
    turma: { alunos },
    idEstab,
    idCurso,
    idGrupo,
    onChangeSendErrors,
    studentsSeriados,
    isMultinivel
}: any) => {

    const [hasLoading, setHasLoading] = useState(true)
    const [reportAlunos, setReportAlunos] = useState<any>()
    const [tableData, setTableData] = useState<any>()
    const [unitsParam, setUnitsParam] = useState(idCurso == 69 || idCurso == 70 || idCurso == 82 || idCurso == 83 || idCurso == 85 || idCurso == 86 || idCurso == 87 ? "BLUE - PURPLE" : "Units 1 - 3")
    const [currentCheckInfo, setCurrentCheckInfo] = useState<any>()
    const [checkAll, setCheckAll] = useState<any>()
    const [selectData, setSelectData] = useState([])

    const [students, setStudents] = useState()
    const [studentParam, setStudentParam] = useState()

    useEffect(() => {
        fetchAllReportsByAluno()
    }, [studentsSeriados, idCurso])

    useEffect(() => {
        if (checkAll === true || checkAll === false)
            setTableData(tableData.map((row: any) => ({ ...row, checked: checkAll })))

    }, [checkAll])

    useEffect(() => {
        if (unitsParam && reportAlunos) {
            refreshSelectData()
            refreshTableData()
        }
    }, [unitsParam, reportAlunos, idCurso])

    useEffect(() => {
        if (tableData && onChangeSendErrors)
            onChangeSendErrors(tableData.filter(({ error }: any) => error).length)

    }, [tableData])

    useEffect(() => {
        if (currentCheckInfo) {
            const { id_aluno, checked } = currentCheckInfo
            setTableData(tableData.map((row: any) => (row.id_aluno === id_aluno) ? ({ ...row, checked }) : row))
            setCurrentCheckInfo(undefined)
            setCheckAll(undefined)
        }
    }, [currentCheckInfo])

    const refreshTableData = () =>
        setTableData(reportAlunos.map(preparaForTable({ unitsParam, setCurrentCheckInfo, idCurso: idCurso, handleTryAgain })))

    const sendReport = async (idsAluno: Array<number>, cursoId?: any) => {
        setHasLoading(true)

        try {
            await API.EnvioNotas.SendScore({
                alunos_envio: idsAluno,
                id_curso: cursoId,
                id_grupo: idGrupo,
                nsu_estab: idEstab,
                ordem_etapa: getOrdemEtapa(unitsParam)
            }, getConfig())

            fetchAllReportsByAluno()
            Toastify({
                text: "Report sent successfully!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        } catch{
            Toastify({
                text: "Error sending report!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }

    const refreshSelectData = () => {
        if (reportAlunos.length > 0) setSelectData(reportAlunos[0].reportEtapa.map(({ descricao }: any) => ({ id: descricao, value: descricao })))
    }

    const handleSendReport = () => {
        const idsAluno = tableData.filter(({ checked }: { checked: boolean }) => checked).map(({ id_aluno }: any) => id_aluno)
        if (idsAluno.length) {
            const returnCursos = studentsSeriados.map((key: any) => key.id_curso)

            if (returnCursos.length > 1) {
                studentsSeriados.map((key: any) => {
                    idsAluno.map((idAluno: any) => {
                        if (key.id_curso == idCurso && key.id_aluno == idAluno) {
                            sendReport([key.id_aluno], key.id_curso)
                        }

                        else if (key.id_curso != idCurso && key.id_aluno == idAluno) {
                            sendReport([key.id_aluno], key.id_curso)
                        }
                    })
                })
            }

            else {
                sendReport(idsAluno, idCurso)
            }
        } else {
            Toastify({
                text: "No students were selected! <br> select at least one student to send report",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }
    const handleTryAgain = (idAluno: number, idCurso: number) => () => sendReport([idAluno], idCurso)


    const fetchAllReportsByAluno = async () => {
        if (isMultinivel && studentsSeriados) {
            const getRequest = ({ id_aluno, id_curso }: any) => API.Report.Get({ id_aluno, id_curso, tipo_unidade: "BOOK_TASK" }, getConfig())

            const responses = (await Promise.all(studentsSeriados.map((aluno: any) => getRequest({ id_aluno: aluno.id_aluno, id_curso: aluno.id_curso }))))
                .map(({ data }: any) => data)

            const newAlunos = studentsSeriados.map((aluno: any, i: number) => ({ ...aluno, ...responses[i], id_curso: idCurso }))

            const uniqueStudents = studentsSeriados.reduce((unique: any, o: any) => {
                if (!unique.some((obj: any) => obj.nome_aluno === o.nome_aluno)) {
                    unique.push({ id: o.id_usuario, value: o.nome_aluno });
                }
                return unique;
            }, []);
            setStudents(uniqueStudents)

            setReportAlunos(newAlunos)
            setHasLoading(false)
            setCheckAll(undefined)
        }

        else if (idCurso) {
            const getRequest = ({ id_aluno, id_curso }: any) => API.Report.Get({ id_aluno, id_curso, tipo_unidade: "BOOK_TASK" }, getConfig())

            const responses = (await Promise.all(alunos.map((aluno: any) => getRequest({ id_aluno: aluno.id_aluno, id_curso: idCurso }))))
                .map(({ data }: any) => data)

            const newAlunos = alunos.map((aluno: any, i: number) => ({ ...aluno, ...responses[i], idCurso }))

            const uniqueStudents = alunos.reduce((unique: any, o: any) => {
                if (!unique.some((obj: any) => obj.nome_aluno === o.nome_aluno)) {
                    unique.push({ id: o.id_usuario, value: o.nome_aluno });
                }
                return unique;
            }, []);
            setStudents(uniqueStudents)

            setReportAlunos(newAlunos)
            setHasLoading(false)
            setCheckAll(undefined)
        }
    }

    const handleChangeFilter = (keystone: string, value: any) => {
        const sets: any = {
            units: setUnitsParam
        }
        if (sets[keystone]) sets[keystone](value)
    }

    const handleFilterStudent = (value: any, keystone: any) => {
        if (keystone === "") {
            setTableData(reportAlunos.map(preparaForTable({ unitsParam, setCurrentCheckInfo, idCurso: studentsSeriados.map((key: any) => key.id_curso) || idCurso, handleTryAgain })))
        } else {
            const filter = reportAlunos.map(preparaForTable({ unitsParam, setCurrentCheckInfo, idCurso: studentsSeriados.map((key: any) => key.id_curso || idCurso), handleTryAgain }))
            setTableData(filter.filter((student: any) => student.nome_aluno.replace(/\s+/g, '') === keystone.replace(/\s+/g, '')))
        }
    }

    return <div className="score">
        {reportAlunos && reportAlunos.length > 0 && <header className="score-header flex-row">
            <div className="header-cta header-student grade-sending mb-4 d-flex flex-row">
                <ButtonStyle green
                    size="xlarge"
                    label={`Send report (${unitsParam})`}
                    icon="messageWhite"
                    maxWidth={true}
                    onClick={handleSendReport} />
            </div>
            <div className="score-filter">
                <span className="filter-label  pr-5">Filter by</span>
                <SelectFieldStyle
                    keystone="units"
                    label="Units"
                    value={unitsParam}
                    onChange={handleChangeFilter}
                    values={selectData}
                    hasOptionEmpty={true} />
                <SelectFieldStyle
                    keystone="student"
                    label="Student"
                    value={studentParam}
                    onChange={handleFilterStudent}
                    values={students} />
            </div>
        </header>}
        {tableData && reportAlunos && reportAlunos.length > 0
            ? <Table data={tableData} config={
                [
                    {
                        label: <input type="checkbox" onChange={({ target: { checked } }) => setCheckAll(checked)} checked={checkAll} />,
                        formatter: ({ id_aluno, checked, onChangeCheck }: any) => {
                            return <input type="checkbox" onChange={({ target: { checked } }) => onChangeCheck({ id_aluno, checked })} checked={checked} />
                        }
                    },
                    ...CONFIG_TABLE_GRADE_SENDING
                ]} />
            : <p className="text-center m-3">No data found</p>
        }
        {hasLoading && <Loader />}
    </div>
}

export default GradeSending

import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"

import { ButtonStyle } from "YConnect/Components/Button"
import AudioMainView from "YConnect/PagesComponents/CMS/Views/AudioMain.view"
import AudioFilesModal from "YConnect/PagesComponents/CMS/Modals/AudioFiles.modal"

import FormContainer from "YConnect/Containers/Form.container"

import ItemsEditableTable from "YConnect/PagesComponents/CMS/Editors/Unscramble.editor/Items.editableTable"
import AUDIO_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Audio.template.json"

type UnscrambleAudioControlTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const UnscrambleAudioControlTemplateEditor = ({
    question,
    onChangeValues 
}: UnscrambleAudioControlTemplateEditorProps
)=> {
    
    const [isShowAudioFilesModal, setShowAudioFilesModal] = useState(false)
    const [values, setValues] = useState<QuestionType>(question)


    const addValues = (newValues:QuestionType) => {
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }

    const handleChangeValues   = (values:QuestionType) => addValues(values)

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])

    const addMainExerciseMedia = ({Source, MediaType}:any) => {
        const newValues = {
            ...values,
            ExerciseMedias: [{Order:0, Source, MediaType}, ...values.ExerciseMedias.filter(({Order}) => Order !== 0)]
        }
        setValues(newValues)
        onChangeValues(newValues)
    }

    const getMainExerciseMedia = () =>
        values && values.ExerciseMedias.find(({Order}) => Order === 0)

    const handleOpenModalAudioFiles = () => setShowAudioFilesModal(true)
    const handleCloseModalAudioFiles = () => setShowAudioFilesModal(false)
    const handleAddAudio = ({Source, MediaType}:any) => {
        addMainExerciseMedia({Source, MediaType})
        setShowAudioFilesModal(false)
    }
    const handleRemoveExerciseMedia = () => {
        const newValues = {
            ...values,
            ExerciseMedias: values.ExerciseMedias
            .filter(({Order}) => Order !== 0 )
        }

        setValues(newValues)
        onChangeValues(newValues)
    }

    return  <>
            <AudioMainView medias={values && values.ExerciseMedias}/>
                    {
                        !(getMainExerciseMedia()
                        && getMainExerciseMedia().MediaType.toLowerCase() === "audio")
                        && <Row className="py-3">
                                <Col md="auto">
                                    <ButtonStyle
                                        green
                                        disabled  = {getMainExerciseMedia() && getMainExerciseMedia().MediaType === "image"}
                                        size      = "190"
                                        onClick   = {handleOpenModalAudioFiles}
                                        icon      = "audioWhite"
                                        label     = "Add Audio"/>
                                </Col>
                            </Row>
                    }
                    {
                        getMainExerciseMedia()
                        && <Row className="py-3 justify-content-center">
                                <Col md="auto">
                                    <ButtonStyle
                                        className = "mb-2"
                                        size      = "130"
                                        onClick   = {handleOpenModalAudioFiles}
                                        icon      = "reset"
                                        label     = "Change"/>
                                </Col>
                                <Col md="auto">
                                    <ButtonStyle
                                        size      = "130"
                                        onClick   = {handleRemoveExerciseMedia}
                                        icon      = "remove"
                                        label     = "Remove"/>
                                </Col>
                            </Row>
                    }
                <Row>
                    <Col md={12}>
                        <FormContainer
                            values   = {values}
                            onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                            template = {[...AUDIO_TEMPLATE.map((field) => ({...field}))]}/>
                    </Col>
                    <Col md={12} className="mt-3">
                        <h3 className="font-weight-bold">
                            Item {values && values.Dialogues[0] && values.Dialogues[0].DialogueMessages.length > 0 && `(${values.Dialogues[0].DialogueMessages.length})`}
                        </h3>
                        <ItemsEditableTable values={values} onChange={(values:QuestionType) => handleChangeValues(values)}/>
                    </Col>
                </Row>
                {
                    isShowAudioFilesModal
                    && <AudioFilesModal 
                        onSelect={handleAddAudio}
                        onClose={handleCloseModalAudioFiles}/>
                }
            </>
}

export default UnscrambleAudioControlTemplateEditor
import * as React from "react"
import orderBy    from "lodash/orderBy"
import styled from "styled-components"

import LineProgressStyle from "YConnect/Styles/LineProgress.style"
import RankedClassStyle  from "YConnect/PagesComponents/PrePostReport/Components/RankedProgressBar/RankedClass.style"


const CardLoadingStyle = styled.div`
    .loading {
        left: calc(50% - 20px);
        position: relative;
        width: 40px;
        height: 40px;
        border: 4px transparent solid;
        border-top: 4px ${props => props.theme.main.lightPurple} solid;
        border-radius: 30px;
        animation: preload .7s infinite;
        animation-timing-function: linear;
    }

        
    @-moz-keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`

const CardLoading = () => 
<CardLoadingStyle>
    <div className="loading"></div>
</CardLoadingStyle>

const RankedProgressBar = ({
    title, 
    isOrderable, 
    prePost,
    className
}:any) => {
    const [rankedStudentEvo, setRankedStudentEvo] = React.useState("score")

    const setOrder = (e: any) => {
        setRankedStudentEvo(e.target.value)
    }

    return <RankedClassStyle className={className}>
        <header className="order-header">
            <h2 className="tabscore-title">{title}</h2>
            {
                isOrderable
                && <div className="order mb-3">
                    <label className="form-label mr-2">Order by higher</label>
                    <div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(e) => setOrder(e)}>
                        <label className={`btn btn-radio ${rankedStudentEvo === "score" ? "active": ""}`}>
                            <input type="radio" name="order" value="score"/>
                            Score
                        </label>
                        <label className={`btn btn-radio ${rankedStudentEvo === "evolution" ? "active": ""}`}>
                            <input type="radio" name="order" value="evolution"/>
                            Evolution
                        </label>
                    </div>
                </div>
            }
        </header>
        <div className="rankedCard" >
            {!prePost && <CardLoading/>}

            {
                prePost && 
                   (prePost.length > 0
                    ? (rankedStudentEvo === "evolution" 
                        ? orderBy(prePost, "evolucao", "desc")
                        : prePost
                    ).map(({ escola, nome, mediaTurma, uf, evolucao }: any, key: any) => {
                        return <div className="ranked-item" key={key}>
                                <span className="item-counter">{key + 1}</span>
                                <div className="item-about">
                                    <span>{escola || nome} {uf ? `(${uf})` : null}</span>
                                </div>
                                <LineProgressStyle>
                                    <div className="line" />
                                    <div
                                        className="line-progress"
                                        style={{ width: `calc(${mediaTurma}* 90%)` }}
                                    />
                                    <div
                                        className="pointer-progress"
                                        style={{ marginLeft: `calc(${mediaTurma}* 90%)` }}
                                    />
                                </LineProgressStyle>
                                <span className="item-percent">
                                    {
                                        rankedStudentEvo === "evolution"
                                        ? `${evolucao}%`
                                        : `${(mediaTurma * 100).toFixed(1)}%`
                                    }
                                </span>
                            </div>    
                    })
                    : <div className="align-items-center d-flex flex-row justify-content-center">
                        <p>No data recorded!</p>
                    </div>
                   )
            }
        </div>
    </RankedClassStyle>
}

export default RankedProgressBar
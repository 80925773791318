import * as React from "react"

import * as pencil from "YConnect/Assets/svg/pencil.svg"
import * as checked from "YConnect/Assets/svg/checked.svg"

const Task = ({task, unit, courseId}: any) => {
    return (
        <div className="task-card card shadow">
            <header className="task-card-header d-flex justify-content-center">
                 <span className={`header-icon ${task.total_done === task.total_telas ? 'done' : 'to-do'}`}>
                    <img src={task.total_done === task.total_telas ? checked : pencil} alt="" className="task-card-img" />
                </span>
            </header>
            <div className="task-card-body">
                {
                    courseId == 69 || courseId == 70 || courseId == 82 || courseId == 83 || courseId == 85 || courseId == 86 || courseId == 87
                    ? <span className="subtitle d-block text-center mx-2">{task.nome_unidade}</span>
                    : <span className="subtitle d-block text-center mx-2">{`Unit ${unit}: `}{task.nome_unidade}</span>
                }

                <span className="title d-block text-center font-weight-bold h5">{task.nome_secao}</span>
            </div>
            <hr />
            <footer className="task-card-footer">
                <span className="d-block lead text-center font-weight-bold">Task {task.ordem_exercicio}</span>
            </footer>
        </div>
    )
}

export default Task

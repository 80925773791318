import styled from "styled-components"

import SelectFieldStyle from "YConnect/Styles/SelectField.style"

const SelectFieldNewStyle = styled(SelectFieldStyle)`
    @media (min-width: 768px) {
        width: 33%;
    }
`

export default SelectFieldNewStyle
import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAll = () => 
    API2
    .File
    .GetAll({}, getConfig())

export const Upload = (file:any) => 
    API2
    .File
    .Upload(file, {
        headers: { 
            "Authorization": localStorage.getItem("Authorization"),
            "content-type": "multipart/form-data"
        }
    })
   
import * as React from "react"
import {
    useEffect, useState, useContext
} from "react"
import { Container, Row, Col, Card } from "reactstrap"
import styled from "styled-components"

import { BreadcrumbCMS } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle from "YConnect/Components/PageTitle"
import UserContext from "YConnect/User.context"

import * as classGradeIcon from "YConnect/Assets/svg/class-grades.svg"
import * as preAndPostTest from "YConnect/Assets/svg/pre-and-post-test.svg"

const ReportCardStyle = styled.div`
    align-items: center;
    border-bottom: 2px solid ${props => props.theme.main.clickable};
    color: ${props => props.theme.main.textColor};
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }

    .report-type {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin-left: 20px;

        h4, p {
            margin: 0;
        }
    }

`

const OpenningCMS = () => {

    const [lastSearches, setLastSearches] = useState(JSON.parse(localStorage.getItem("lastSearches")))
    const [lastSearchesRB, setLastSearchesRB] = useState(JSON.parse(localStorage.getItem("lastSearchesRB")))

    const { idUsuario }: any = useContext(UserContext)

    useEffect(() => {
        if (!lastSearches) {
            const newLastSearches: any = {
                [idUsuario]: []
            }
            setLastSearches(newLastSearches)
            localStorage.setItem("lastSearches", JSON.stringify(newLastSearches))
        }
        if (!lastSearchesRB) {
            const newLastSearches: any = {
                [idUsuario]: []
            }
            setLastSearchesRB(newLastSearches)
            localStorage.setItem("lastSearchesRB", JSON.stringify(newLastSearches))
        }
    }, [])

    return <Container>
        <BreadcrumbCMS/>
        <PageTitle label="CMS" />
        <Row>
            <Col xs={12} md={6}>
                <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/cms/exercises"}>
                    <figure>
                        <img src={preAndPostTest} alt="" />
                    </figure>
                    <div className="report-type">
                        <h4>Exercises</h4>
                        <p>Go to edit Exercises.</p>
                    </div>
                </ReportCardStyle>
            </Col>

            <Col xs={12} md={6}>
                <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/cms/units"}>
                    <figure>
                        <img src={classGradeIcon} alt="" />
                    </figure>
                    <div className="report-type">
                        <h4>Units</h4>
                        <p>Go to edit Units</p>
                    </div>
                </ReportCardStyle>
            </Col>

            <Col xs={12} md={6}>
                <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/cms/sections"}>
                    <figure>
                        <img src={classGradeIcon} alt="" />
                    </figure>
                    <div className="report-type">
                        <h4>Sections</h4>
                        <p>Go to edit Sections</p>
                    </div>
                </ReportCardStyle>
            </Col>
        </Row>

    </Container>

}


export default OpenningCMS

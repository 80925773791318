import * as React from "react"
import { useState, useEffect, useContext } from "react"

import Table from "YConnect/Components/Table"
import API from "YConnect/API"
import SelectFieldStyle from "YConnect/Styles/SelectField.style"
import Loader from "YConnect/Components/Loader"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

import CONFIG_TABLE_REPORT_UNIT_STUDENT, { CONFIG_TABLE_REPORT_STUDENT } from "YConnect/Configs/Tables/ReportUnitStudent.table.config"
import CONFIG_TABLE_REPORT_RESOURCE_BOOK from "YConnect/Configs/Tables/ReportUnitStudentResourceBook.table.config"
import UnitType from "YConnect/Enums/UnitType"

import UserContext from "YConnect/User.context";
import Toastify from "toastify-js"

const idCursoStarter = 67

const ScoreUnitStudent = ({ idGrupo, idCurso, resourceBook, isMultinivel, studentsSeriados = false }: any) => {
    const [hasLoading, setHasLoading] = useState(true);
    const [report, setReport] = useState<any>();
    const [tableData, setTableData] = useState([]);
    const [unitParam, setUnitParam] = useState<any>(1);

    const [students, setStudents] = useState();
    const [studentParam, setStudentParam] = useState();
    const [originalReport, setOriginalReport] = useState<any>();
    const [resourceBookUnits, setResourceBookUnits] = useState<any>([]);
    const [bookTaskUnits, setBookTaskUnits] = useState<any>([]);

    const cursoStarter: boolean = parseInt(idCurso, 10) === idCursoStarter
    const { idUsuario }: any = useContext(UserContext);

    useEffect(() => {
        fetch();
    }, [unitParam, isMultinivel]);

    useEffect(() => {
        if(resourceBook && resourceBookUnits.length != 0) {
            insertUnitOnReport(tableData);
        } else if(bookTaskUnits.length != 0){
            insertUnitOnReport(tableData);
        }
    }, [resourceBookUnits, bookTaskUnits]);

    useEffect(() => {
        if (report) {
            let newDataTable = report.map(({ id_usuario, nome_aluno, id_curso, nome_curso, reportUnidade }: any) => ({
                id_aluno: id_usuario,
                idCurso,
                id_curso,
                nome_curso,
                nome_aluno,
                ...reportUnidade[0]
            }))
            API.Aluno.GetAlunoCurso({idTurma: idGrupo, idCurso:idCurso},getConfig())
            .then((res:any)=>{
              let clearList:any = [];
              let alunoAux:any = null;
              let key = 0;
              newDataTable.forEach((elem:any, index: any)=>{
                let aluno = res.data.filter((x: any) => x.id_aluno == elem.id_aluno);
                elem.idCurso = aluno[0].id_curso;
                elem.nome_curso = aluno[0].nome_curso;
                clearList.push(elem);
                setTableData(clearList);
                setOriginalReport(clearList);
                findUnits();
              })

              const uniqueValuesSet = new Set();
              const filteredList = clearList.filter((obj: any) => {
                const isPresentInSet = uniqueValuesSet.has(obj.id_aluno);
                uniqueValuesSet.add(obj.id_aluno);
                return !isPresentInSet;
              });

              setTableData(filteredList);
              setOriginalReport(filteredList);
              findUnits();
            })


        }
    }, [report])

    const findUnits = async () => {
        const units = await API.Course.getById(
            {
                id_curso: idCurso,
                id_usuario: idUsuario,
                isDegustacao: false,
            },
            getConfig()
        );
        setUnitsData(units.data, UnitType.RESOURCE_BOOK, setResourceBookUnits)
        setUnitsData(units.data, UnitType.BOOK_TASK, setBookTaskUnits)
    }

    const setUnitsData = (units: Array<any>, unitType: string, setUnitFn: Function) => {
        const filteredUnits = units.filter((u: any) => u.tipo_unidade === unitType)
        const convertedList: any = [];
        filteredUnits.map((element, key) => {
            convertedList.push({ id: (key+1), value: idCurso == 69 || idCurso == 70 || idCurso == 82 || idCurso == 83 || idCurso == 85 || idCurso == 86 || idCurso == 87 ? element.nome : `Unit ${element.ordem}` })
        })
        setUnitFn(convertedList)
    }


    const fetch = async () => {
        if(isMultinivel) {
            const paramForFetch = {
                id_turma: idGrupo,
                id_unidade: unitParam,
                tipo_unidade: resourceBook ? "RESOURCE_BOOK" : "BOOK_TASK",
                id_curso: idCurso
            }
            try {
                setHasLoading(true)
                const { data } = await API.Report.GetByUnitMultiseriado(paramForFetch, getConfig())

                // const newAlunos = studentsSeriados.map((aluno: any, i: number) => ({ ...aluno, ...data[i] }))
                const newAlunos = studentsSeriados.map(
                    (aluno: any, i: number) => ({
                        ...aluno,
                        ...data.find(
                            ({ id_usuario }) => id_usuario === aluno.id_aluno
                        ),
                    })
                );
                setReport(newAlunos)
                const uniqueStudents = data.reduce((unique: any, o: any) => {
                    if (!unique.some((obj: any) => obj.value === o.nome_aluno)) {
                        unique.push({ id: o.id_usuario, value: o.nome_aluno });
                    }
                    return unique;
                }, []);

                setStudents(uniqueStudents)
                setHasLoading(false)
            } catch (e) {
                const { response } = e
                if (response && response.data) {
                    Toastify({
                        text: response.data.message,
                        duration: 3000,
                        className: "toastify-error",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }
            } finally {
                setHasLoading(false)
            }
        }

        else {
            const paramForFetch = {
                idCurso,
                id_turma: idGrupo,
                id_unidade: unitParam,
                tipo_unidade: resourceBook ? "RESOURCE_BOOK" : "BOOK_TASK"
            }
            try {
                setHasLoading(true)
                const { data } = await API.Report.GetByUnit(paramForFetch, getConfig()) //exemplo Eduardo
                setReport(data)
                const uniqueStudents = data.reduce((unique: any, o: any) => {
                    if (!unique.some((obj: any) => obj.value === o.nome_aluno)) {
                        unique.push({ id: o.id_usuario, value: o.nome_aluno });
                    }
                    return unique;
                }, []);
                setStudents(uniqueStudents)
            } catch (e) {
                const { response } = e
                if (response && response.data) {
                    Toastify({
                        text: response.data.message,
                        duration: 3000,
                        className: "toastify-error",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }
            } finally {
                setHasLoading(false)
            }
        }
    }

    const insertUnitOnReport = (report: any) =>{

        tableData.forEach((itemReport: any, index:number) => {

            let unidadeObj: any = {};

            if(resourceBook){
                unidadeObj = resourceBookUnits.filter((item: any) => item.id === itemReport.unidade)[0];
            } else {
                unidadeObj = bookTaskUnits.filter((item: any) => item.id === itemReport.unidade)[0];
            }

            tableData[index].nomeUnidade = unidadeObj.value;

        });
    }

    const handleChangeFilter = (keystone: string, value: any) => {
        const sets: any = {
            unit: setUnitParam
        }
        if (sets[keystone]) sets[keystone](value)
    }

    const handleFilterStudent = (value: any, keystone: any) => {

        if (keystone === "") {
            setTableData(originalReport)
        } else {
            setTableData(originalReport.filter((student: any) => student.id_aluno === parseInt(keystone, 10)))
        }
    }

    return <div className="score">
        {
            report && report.length > 0 && <header className="score-header ">
                <div className="w-100 d-flex justify-content-end">
                    <div className="score-filter">
                        <span className="filter-label  pr-5">Filter by</span>
                        {resourceBook ?
                            <SelectFieldStyle
                                keystone="unit"
                                label="Unit"
                                value={unitParam}
                                onChange={handleChangeFilter}
                                values={resourceBookUnits}
                                hasOptionEmpty={true} />
                            : !cursoStarter &&
                            <SelectFieldStyle
                                keystone="unit"
                                label="Unit"
                                value={unitParam}
                                onChange={handleChangeFilter}
                                values={bookTaskUnits}
                                hasOptionEmpty={true} />
                        }
                        <SelectFieldStyle
                            keystone="student"
                            label="Student"
                            value={studentParam}
                            onChange={handleFilterStudent}
                            values={students}
                        />
                    </div>
                </div>
            </header>
        }
        {
            report && report.length > 0
                ? <Table
                    data={tableData}
                    config={cursoStarter ? (CONFIG_TABLE_REPORT_STUDENT) : resourceBook ? CONFIG_TABLE_REPORT_RESOURCE_BOOK : CONFIG_TABLE_REPORT_UNIT_STUDENT} />
                : <p className="text-center m-3">No data found</p>
        }
        {hasLoading && <Loader />}
    </div>
}

export default ScoreUnitStudent

import {
    useReducer
}                  from "react"

const useReport = () => {

    const fiterState = useReducer((state:any, idSelected:any) => {
        return  {...state, ...idSelected}
    }, {})

    const comboState = useReducer((state:any, combo:any) => {
        return  {...state, ...combo}
    }, {})

    return {fiterState, comboState}
}

export default useReport
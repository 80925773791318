import {useEffect}  from "react"

const useChangeQueryParamByFilter = (AddQueryParam:any, RemoveQueryParam:any, queryParamName:string, filterValue:string) => {
    useEffect(()=>{
        if(filterValue && filterValue !== ""){
            AddQueryParam(queryParamName, filterValue)
        } else{
            RemoveQueryParam(queryParamName)
        }
    }, [filterValue])
}

export default useChangeQueryParamByFilter
import styled  from "styled-components"
import { Col } from "reactstrap"

const ColMessageStyle = styled(Col)`
    padding: 0;
    height: 100%;

    img {
        width: 20px;
        @media screen and (min-width: 768px) {
            width: 25px;
        }
    }

    a:hover {
        text-decoration: none;
    }

    span {
        color: ${props => props.theme.main.mainPurple};
        left: 9px;
        top: -10px;
        position: relative;
        font-weight: bold;
        z-index: 5;

        @media screen and (min-width: 1024px) {
            left: -10px;
            top: 15px;
        }

        @media screen and (max-width: 375px) {
            left: -10px;
            top: 10px;
        }
    }
`

export default ColMessageStyle
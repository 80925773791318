import * as React from "react"
import {useState} from "react"
import { Modal, Row, Col }  from "react-bootstrap"
import styled from "styled-components"

import * as AddHomeStep1 from "YConnect/Assets/jpeg/add-home-step-1.jpeg"
import * as AddHomeStep2 from "YConnect/Assets/jpeg/add-home-step-2.jpeg"
import * as AddHomeStep3 from "YConnect/Assets/jpeg/add-home-step-3.jpeg"

import { ButtonStyle } from "YConnect/Components/Button"


type IOSAddScreenModalProps = {
    isShow: boolean
    onClose: (checked:Boolean) => void
}

const RowStyle = styled(Row)`
    margin-top: -50px;
`

const H2Style = styled.h2`
    color:#fff;
`

const H3Style = styled.h3`
    color:#fff;
`

const SpanStyle = styled.span`
    color: #fff;
`

const InputStyle = styled.input`
    -webkit-appearance: button!important;
`

const IOSAddScreenModal = ({ isShow, onClose }: IOSAddScreenModalProps) => {

    const [checked, setChecked] = useState()

    return <Modal keyboard={false} show={isShow} onHide={() => onClose(checked)} size="lg" className="modal-background first-access">
                <Modal.Header closeLabel="Close" closeButton className="first-access">
                    
                </Modal.Header>
                <Modal.Body>
                    <RowStyle>
                        <Col md={12}>
                            <H2Style>Install this webapp on your iPhone</H2Style>
                        </Col>
                        <Col md={12}>
                            <H3Style>1. Press the share button</H3Style>
                            <img className="img-fluid" src={AddHomeStep1}/>
                        </Col>
                        <Col className="mt-2" md={12}>
                            <H3Style>2. Select "Add to Home Screen"</H3Style>
                            <img className="img-fluid" src={AddHomeStep2}/>
                        </Col>
                        <Col className="mt-2" md={12}>
                            <H3Style>3. Press the "Add" button</H3Style>
                            <img className="img-fluid" src={AddHomeStep3}/>
                        </Col>
                        <Col className="mt-2" md={12}>
                            <label className="container-input">
                                <InputStyle
                                    name="selectedToDel"
                                    type="checkbox"
                                    onChange={(e) => setChecked(e.target.checked)}/>
                                <span className="checkmark"/>
                                <SpanStyle>Don't show this message again</SpanStyle>
                            </label>
                            <ButtonStyle green label="Close x" onClick={() => onClose(checked)} />
                        </Col>
                    </RowStyle>
                </Modal.Body>
            </Modal>
}
    

//#481983

export default IOSAddScreenModal
import * as React          from "react"
import { useEffect,
        useRef }           from "react"
import { CSSTransition }   from "react-transition-group"

import { saveAs }          from "file-saver"

import API                 from "YConnect/API"
import getBaseURLWebApi    from "YConnect/Utils/GetBaseURLWebAPI.util"

import { LinkStyle }       from "YConnect/Components/Button"
import Table               from "YConnect/Components/Table"

import AlphaBlackStyle     from "YConnect/Styles/AlphaBlack.style"

import * as downloadGreen  from "YConnect/Assets/svg/download.svg"

import getStaticUrl    from "YConnect/Utils/GetStaticUrl.util"
import parseFileName from "YConnect/Utils/ParseFileName.util";
import Toastify from "toastify-js"

const CONFIG_TABLE_BOOK: Array<ColumnConfigType> = [
    {
        label: "Title & file",
        formatter: (data: any) => <div className="download-title" title={data.title}>{data.title}<br /><small title={parseFileName(data.name_File)} className="text-muted">{parseFileName(data.name_File)}</small></div>
    },
    {
        label: "File",
        formatter: (data: any) => {
            return <div
                className="pointer text-clickable"
                onClick={() => data.name_File.includes('midia:') ? downloadItemS3(data.name_File) : downloadItem(data.id_Resource, data.name_File)}
                >
                Download <img src={downloadGreen} />
            </div>
        }
    }
]

const downloadItem = async (idResource: number, fileName: string) => {

    const idUsuario = JSON.parse(localStorage.getItem('data')).idUsuario;

    API.Downloads.ActiveTeachDownload({
        idUsuario: idUsuario,
        idResource: idResource
    }, { headers: {
            "Authorization": localStorage.getItem("Authorization")
        }
    })
    .then((res: any) => {
        saveAs(`${getBaseURLWebApi()}${res.data}`);
    })
    .catch((err: any) => {
        Toastify({
            text: "Error! <br>" + err.response.data.message,
            duration: 3000,
            className: "toastify-error",
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
            escapeMarkup: false,
            onClick: function(){} 
          }).showToast();
    });
}

const downloadURL = (url:string, filename:string) => {
    fetch(url).then(function(t) {
        return t.blob().then((b)=>{
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        })
    }
)}

const downloadItemS3 = async (fileName: string) => {
    try {
        downloadURL(getStaticUrl(fileName), fileName.substring(fileName.lastIndexOf('\\')+1, fileName.length))
    } catch (error) {
        Toastify({
            text: "Error! <br>" + error.message,
            duration: 3000,
            className: "toastify-error",
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
            escapeMarkup: false,
            onClick: function(){} 
          }).showToast();
    }
}

const ActiveTeachModal = ({ onDownloadMenu, hasDownloadMenu, downloadItems }: any) => {


    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onDownloadMenu(false)
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    const CourseDownloadContent = (helperClass?: string) => <>
        {
            window.innerWidth > 768 ?
                <div className={`table-responsive download-menu ${helperClass}`} style={{ maxHeight: '70%' }}>
                    <Table data={downloadItems} config={CONFIG_TABLE_BOOK} />
                </div>
                :
                downloadItems.map((item: any, key: any) => {
                    return (
                        <div className="download-mobile" key={key}>
                            <div className="book">
                                <div className="book-row d-flex flex-row">
                                    <span className="w-40">Title & file</span>
                                    <span className="w-40">
                                        {item.title} <br />
                                        <small>{parseFileName(item.name_File)}</small>
                                    </span>
                                </div>
                                <div className="book-row d-flex flex-row">
                                    <span className="w-40">File</span>
                                    <div
                                        className="text-clickable pointer w-40 d-flex flex-row align-items-center"
                                        onClick={() => downloadItem(item.id_Resource, item.name_File)}
                                    >
                                        Download <img src={downloadGreen} className="pl-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
        }
    </>

    const isMobile = window.innerWidth < 768;
    return (
        <>
        {hasDownloadMenu && <AlphaBlackStyle/>}
        <CSSTransition classNames="dialog YC-lateral-menu" timeout={300} in={hasDownloadMenu} unmountOnExit>
            <div
                style={{
                    zIndex:10000,
                    width: isMobile ? '100vw' : '650px'
                }}
                className={`YC-lateral lateral-header lateral-header-course d-flex flex-column`}
                ref={wrapperRef}
            >
                <h4 className="lateral-header--title">
                    Downloads
                    <LinkStyle
                        label="Close x"
                        className={'close-modal text-right text-right header-btn'}
                        onClick={() => onDownloadMenu(!hasDownloadMenu)} />
                </h4>
                {CourseDownloadContent()}
            </div>
        </CSSTransition>
        </>
    )
}

export default ActiveTeachModal

import * as React                  from "react"
import {
    useEffect, useState, useContext, useRef
}                                  from "react"
import {Container, Row, Col, Card} from "reactstrap"
import styled                      from "styled-components"

import { BreadcrumbExplore } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle        from "YConnect/Components/PageTitle"
import API              from "YConnect/API"
import UserContext      from "YConnect/User.context"
import { ButtonStyle, LinkStyle }  from "YConnect/Components/Button"
import Table            from "YConnect/Components/Table"
import getConfig        from "YConnect/Utils/GetRequestConfig.util"

import AlphaBlackStyle from "YConnect/Styles/AlphaBlack.style"
import { CSSTransition } from "react-transition-group"

import * as remove          from "YConnect/Assets/svg/remove.svg"
import * as arrowNext       from "YConnect/Assets/svg/arrow-next.svg"
import * as arrowPrevious   from "YConnect/Assets/svg/arrow-previous.svg"
import { FormGroup } from "react-bootstrap"
import Select                  from "react-select"
import Toastify from "toastify-js"

const FormGroupStyled = styled.div`
    @media (min-width: 768px) {
        width: 50%;
    }
`

const ButtonStyleNew = styled(ButtonStyle)`
    width: 100%;
    @media (min-width: 768px) {
        width: 25%;
    }

    img {
        filter: grayscale(100%) brightness(0) invert(1);
    }
`

const ButtonStyleWhiteIcon = styled(ButtonStyle)`
    img {
        filter: invert(1);
        width: 33px;
    }
`

const ExplorePage = () => {
    
    const CONFIG_TABLE_EXPLORE: Array<ColumnConfigType> = [
        {
            keystone: "codigo_multi",
            label: "Code"
        },
        {
            keystone: "nm_estab",
            label: "Name"
        },
        {
            label: "Action",
            formatter: (data: any) => {
                return <span className="download-link pointer text-clickable text-right" onClick={() => removeExploreSchool(data.nsu_estab)}><img src={remove} /> Remove</span>
            }
        }
    ];

    const [establishments, setEstablishments] = useState();

    const [exploreEstabs, setExploreEstabs]           = useState([]);
    const [hasNextExplore, setNextExplore]            = useState(false);
    const [hasPreviousExplore, setPreviousExplore]    = useState(false);
    const [totalExplore, setTotalExplore]             = useState(0);
    const [currentPage, setCurrentPage]               = useState(1)
    
    const [newEstabToAdd, setNewEstabToAdd]           = useState()
    
    
    const [exploreSearch, setExploreSearch] = useState('');
    const [composeMenu, setComposeMenu]     = useState(false)
    const [isSaving, setSaving]             = useState(false);

    const { idUsuario }:any = useContext(UserContext);

    const startSchools = currentPage === 1 ? 1 : 10 * (currentPage - 1) + 1;
    const endSchools   = currentPage === 1 ? currentPage + 9 : 10 * (currentPage - 1) + 10;

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setComposeMenu(false)
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    useEffect(() => {
        getExploreEstabs();
        getEstabsNotExplore();
    }, []);

    const getExploreEstabs = (schoolName = '', page = 1) => {
        API.ExploreReport.GetExplore({ nomeEscola: schoolName, pagina: page }, getConfig())
            .then((response: any) => {
                setPreviousExplore(response.data.estabelecimentoPaginado.hasPrev);
                setNextExplore(response.data.estabelecimentoPaginado.hasNext);
                setTotalExplore(response.data.estabelecimentoPaginado.totalEstabelecimentos)
                setExploreEstabs(response.data.estabelecimentoPaginado.estabelecimentos);
            })
    }

    const getEstabsNotExplore = () => {
        API.ExploreReport.GetAll({ nomeEscola: ''}, getConfig()).then((res: any) => setEstablishments(res.data.listaEscolasIsNotExplore))
    }

    const handleSearch = () => {
        setCurrentPage(1);
        getExploreEstabs(exploreSearch);
    }

    const handleNextPage = () => {
        if (hasNextExplore) {
            getExploreEstabs(exploreSearch, currentPage + 1)
            setCurrentPage(currentPage + 1)
        }
    };

    const handlePreviousPage = () => {
        if (hasPreviousExplore) {
            getExploreEstabs(exploreSearch, currentPage - 1)
            setCurrentPage(currentPage - 1)
        }
    };

    const addNewEstab = async () => {
        setSaving(true)
        API.ExploreReport.UpdateExplore({ idEscola: newEstabToAdd.value, isExplore: true, idUsuario: idUsuario}, getConfig())
        .then(() => {
            setComposeMenu(false);

            Toastify({
                text: "Success! <br> School added with success!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            getExploreEstabs();
            getEstabsNotExplore();
        }).catch((e: any) => {
            Toastify({
                text: "Error! <br> An error has occurred!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
        .finally(() => setSaving(false))

    }

    const removeExploreSchool = (id: number) => {
        setCurrentPage(1);
        API.ExploreReport.UpdateExplore({ idEscola: id, isExplore: false, idUsuario: idUsuario}, getConfig())
        .then(() => {
            setComposeMenu(false);
            Toastify({
                text: "Success! <br> School has been removed with success!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            getExploreEstabs();
        }).catch((e: any) => {
            Toastify({
                text: "Error! <br> An error has occurred!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
    }

    return <>
        <Container>
            <BreadcrumbExplore/>
            <PageTitle label="Explore Yázigi" className={"col-xs-12 col-md-6 d-flex flex-row flex-wrap justify-content-between"}>
                <div className="btn-fixed col-md-6 d-flex justify-content-end">
                    <ButtonStyleWhiteIcon green
                        type    = "submit"
                        label   = "Add School"
                        onClick = {() => setComposeMenu(true)}
                        size    = {'small'} 
                        icon    = {'plus'}
                    />
                </div>
            </PageTitle>

            <Row className="mb-5">
                <Col md={12}>
                    <Card className="users-table shadow">
                        <header className="users-table-header mb-2">

                            <FormGroupStyled className={`form-group`}>
                                <label className="form-label">Establishment</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="password"
                                    placeholder="Search for an establishment"
                                    value={exploreSearch}
                                    onChange={(e) => setExploreSearch(e.target.value)}
                                    onKeyPress={(e) => e.key === 'Enter' ? handleSearch() : null}
                                />
                            </FormGroupStyled>

                            <ButtonStyleNew
                                green
                                label="Search School"
                                icon="search"
                                className="mb-3"
                                onClick={handleSearch} />
                        </header>

                        <header className="inbox-header">
                            <div className="email-input">
                            </div>
                            <div className="email-filter">
                                {<div className="pagination align-items-center d-none d-lg-flex flex-row flex-wrap justify-content-end">
                                    <img
                                        src={arrowPrevious}
                                        className="pr-4"
                                        style={{
                                            cursor: hasPreviousExplore ? 'pointer' : 'not-allowed',
                                            filter: hasPreviousExplore ? 'grayscale(0)' : 'grayscale(100%)'
                                        }}
                                        onClick={() => handlePreviousPage()}
                                    />
                                    <span>
                                        Showing <b>{startSchools} - {hasNextExplore ? endSchools : totalExplore} </b> of {totalExplore}
                                    </span>
                                    <img
                                        src={arrowNext}
                                        className="pl-4"
                                        style={{
                                            cursor: hasNextExplore ? 'pointer' : 'not-allowed',
                                            filter: hasNextExplore ? 'grayscale(0)' : 'grayscale(100%)'
                                        }}
                                        onClick={() => handleNextPage()}
                                    />
                                </div>}
                            </div>
                        </header>

                        {
                            window.innerWidth > 768 
                            ? <Table data={exploreEstabs} config={CONFIG_TABLE_EXPLORE} className={'explore-table'}/>
                            : exploreEstabs.map((item: any, key: any) => {
                                return (
                                    <div className="download-mobile" key={key}>
                                        <div className="book">
                                            <div className="book-row d-flex flex-row">
                                                <span className="w-40">Code</span>
                                                <span className="w-40">{item.codigo_multi}</span>
                                            </div>
                                            <div className="book-row d-flex flex-row">
                                                <span className="w-40">Name</span>
                                                <span className="w-40">{item.nm_estab}</span>
                                            </div>
                                            <div className="book-row d-flex flex-row">
                                                <span className="w-40">Action</span>
                                                <span className="download-link pointer text-clickable" onClick={() => removeExploreSchool(item.nsu_estab)}>
                                                    Remove <img src={remove} />
                                                </span>
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            })
                        }
                    </Card>
                </Col>
            </Row>
        </Container>


        {composeMenu && <AlphaBlackStyle />}
        <CSSTransition classNames="dialog YC-lateral-menu" timeout={300} in={composeMenu} unmountOnExit>
            <div style={{ zIndex: 10000 }} className="YC-lateral lateral-header lateral-header-messages shadow d-flex flex-column" ref={wrapperRef}>
                <h4 className="align-items-center d-flex flex-row justify-content-between mb-3">
                    Add new school
                    <LinkStyle
                        label="Close x"
                        className="close-modal text-right header-btn"
                        onClick={() => setComposeMenu(false)} 
                    />
                </h4>
                <FormGroup className="d-flex flex-row flex-nowrap" style={{ minHeight: '60px' }}>
                    <label className="form-label label-select" htmlFor="nm_estab">Available Schools</label>
                    {establishments 
                    && <Select
                            id="nm_estab"
                            name="nm_estab"
                            options={establishments}
                            onChange={(e: any) => setNewEstabToAdd(e)}
                            isMulti={false}
                            isSearchable={true}
                            placeholder="Select an option"
                            classNamePrefix="message"
                            className="option-message"
                        />
                    }
                </FormGroup>
                <div className="w-100 mt-3 d-flex justify-content-end">
                    <ButtonStyleNew
                        green
                        label={`${isSaving ? 'Saving...' : 'Save'}`}
                        icon="save"
                        className="mb-3"
                        onClick={addNewEstab} 
                        disabled={!newEstabToAdd || isSaving}
                    />
                </div>
            </div>
        </CSSTransition>
    </>
}

export default ExplorePage
import * as React   from "react"
import {useContext} from "react"
import {
    Container, Row, Col
} from "reactstrap"

import UserContext    from "YConnect/User.context"
import { YLinkStlye } from "YConnect/Components/YLink"
import { BreadCrumbAbout } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle      from "YConnect/Components/PageTitle"

import * as responsive     from "YConnect/Assets/svg/responsive.svg"
import * as instructor     from "YConnect/Assets/svg/instructor.svg"
import * as world          from "YConnect/Assets/svg/world.svg"
import * as imgFirstAccess from "YConnect/Assets/png/bg-video-first-login.png"

const cardContent = [
    {
        img: responsive,
        title: 'Courses and Reports',
        text: 'Take our online courses to improve your English, gain knowledge on a variety of different subjects and track your development.',
        label: 'Check your courses',
        link: '/courses'
    },
    {
        img: world,
        title: 'Travel the World',
        text: 'With our passport, you can explore the world, discover countries, learn new cultures, collect stamps, labels and much more!',
        label: 'Explore the world',
        link: '/travel-the-world'
    },
    {
        img: instructor,
        title: 'Online Teacher',
        text: 'Consult an expert teacher to help you with your assignments, or to ask questions about English, through chat or direct message.',
        label: 'Consult a teacher',
        link: '/online-teacher'
    }
]

const AboutPage = () => {

    const {primeiroAcessoGameficacao}:any = useContext(UserContext)

    const isFirstLogin = !(primeiroAcessoGameficacao && primeiroAcessoGameficacao.dt_primeiro_acesso)

    return <Container>
        <BreadCrumbAbout isFirstLogin={isFirstLogin} />
        <PageTitle label="About YConnect" className="col-12 courses" />

        <Row className="">
            <Col md={12} className={'d-flex justify-content-center'}>
                <div className="about-card card shadow">
                    <img src={imgFirstAccess} alt="" className="about-card-video"/>
                    <div className="about-card-info">
                        <h2 className="card-info--title">YConnect is the fastest path to better learning</h2>
                        <p>
                            We make studying more dynamic, interactive and fun!
                            From the basics of speaking and listening to advanced reading and writing skills.
                            Organize and take your courses, check your reports, or ask a teacher for help.
                                    </p>
                    </div>
                </div>
            </Col>
        </Row>

        <Row className="learning">
            <h2 className="col-12 learning-title">Learning English will never be the same</h2>
            <Col md={12} className="learning-cards">
                {
                    cardContent.map((card: { img: any, title: string, text: string, label: string, link: string }, key: any) => {
                        return <div className="learning-card shadow card" key={key}>
                            <figure className="">
                                <img src={card.img} alt="" />
                            </figure>
                            <h5 className="learning-card--title">{card.title}</h5>
                            <p className="learning-card--description">{card.text}</p>
                            {!isFirstLogin && <YLinkStlye link={card.link} label={card.label} icon="arrowRightGreen" />}
                        </div>
                    })
                }

            </Col>
        </Row>
    </Container>

}

export default AboutPage



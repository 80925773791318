import * as React from "react"

import UnitTasks from "YConnect/PagesComponents/ViewBook/UnitTasks"

const ResourceBooks = ({ courseId, tasks, availableUnits, fetchUnitContent, unitOrder, unitType, isBlocked }: any) => (
    <UnitTasks
        courseId={courseId}
        tasks={tasks}
        availableUnits={availableUnits}
        fetchUnitContent={fetchUnitContent}
        unitOrder={unitOrder}
        unitType={unitType}
        isBlocked = {isBlocked}
    />
)

export default ResourceBooks

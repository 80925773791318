export default [
    {
        keystone: "Title",
        className: "col-md-12",
        type: "input",
        label: "Title"
    },
    {
        keystone: "Image",
        className: "col-md-12",
        type: "input",
        label: "Image"
    },
    {
        keystone: "Duration",
        className: "col-md-3",
        type: "input",
        label: "Duration"
    },
    {
        keystone: "Value",
        className: "col-md-3",
        type: "input",
        label: "Value"
    },
    {
        keystone: "LevelId",
        className: "col-md-3",
        type: "select",
        label: "Level"
    },
    {
        keystone: "BookId",
        className: "col-md-3",
        type: "select",
        label: "Book"
    },
    {
        keystone: "Description",
        className: "col-md-12 editor-template",
        type: "TextArea",
        label: "Description"
    }
]
import styled from "styled-components"

const CardBestRankedStudentsStyle = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
    margin-bottom: 24px;

    .content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .ranked-card {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        padding: 16px;
    }

    .title {
        font-weight: bold;
        margin-right: 16px;
    }

    .information {
        text-align: end;
    }
`

export default CardBestRankedStudentsStyle
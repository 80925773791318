
import styled from "styled-components"

const RankedClassStyle = styled.div`

    .order-header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

    }

    .tabscore-title {
        color: #565656;
        font-weight: 600;
        font-size: 20px;
    }

    .rankedCard {
        border: 1px solid #E1E6E7;
        border-radius: 8px;
        padding: 40px 10px;
        min-height: 296px;
        @media screen and (min-width: 1024px) {
            padding: 40px 30px;
        }
    }
    
    .ranked-item{
        align-items: center;
        display: flex; 
        flex-flow: row wrap; 
        justify-content: space-between; 
        width: 100%;
        @media screen and (min-width: 1024px) {
            flex-flow: row nowrap; 
        }

        .item-counter {
            color: #565656;
            font-size: 20px;
            font-weight: 600;
            width: 10%;
        }

        .item-about {
            width: 80%;
        }

        .item-progress {
            width: 40%;
            margin: 0 16px;
        }
        .item-percent {
            margin-left: 8px;
            width: 30%;
            @media screen and (min-width: 1024px) {
                width: 10%;
            }
        }
    }
`

export default RankedClassStyle
import * as React from "react"
import { useState, useContext } from "react"
import { Container } from "reactstrap"

import {
    BreadcrumbViewFullReport,
    addBreadcrumbData,
    removeBreadcrumbData,
 } from "YConnect/Components/BreadCrumbList.tsx"
 import UserContext         from "YConnect/User.context"
import ViewReportContainer from "YConnect/Containers/ViewReport.container"

const ViewFullReportPage = ({ match }: any) => {

    const [reportAluno, setReportAluno] = useState()
    const { idCurso, idAluno } = match.params

    const {idProfessor}:any               = useContext(UserContext)

    addBreadcrumbData([{ page:'ViewFullReportPage', data:{ idCurso, idAluno }}])
    removeBreadcrumbData([{ page: 'UnlockScorePage' }, { page: 'ViewReportStudentPage' }])

    const handleChangeReportAluno = (reportAluno: any) => setReportAluno(reportAluno)

    return <Container>
        {reportAluno && <BreadcrumbViewFullReport />}

        <ViewReportContainer
            idCurso={idCurso}
            idAluno={idAluno}
            onChangeReportAluno={handleChangeReportAluno}
            idProfessor={idProfessor}/>
    </Container>

}


export default ViewFullReportPage

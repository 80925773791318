import styled  from "styled-components"
import { Col } from "reactstrap"

const ColUserInfoStyle = styled(Col)`
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    height: 100%;

    figure {
        margin: 0 10px 0 0;
    }
`

export default ColUserInfoStyle
import * as React from "react"
import {useState} from "react"
import { Modal }  from "react-bootstrap"

import {ButtonStyle} from "YConnect/Components/Button"

const ReorderModal = ({
    show,
    Id,
    onHide,
    onUpdated,
    UpdateById,
    Name,
    List,
    params,
    label,
    reload
}:any) =>{

    const [hasShowLoader, setShowLoader] = useState<boolean>(false)
    const UNIT = "Unit";
    const SECTION = "Section";

    const updateDrag = () => {
        setShowLoader(true)

        if(label == UNIT){
            let paramUpdate = {"listUnit": List, "unitId": params};
            UpdateById({...paramUpdate?paramUpdate:{}})
            .then((response:any) => {
            }).finally(() => {
                setShowLoader(false)
                onHide(false);
            })
          } else if (label == SECTION) {
            let paramUpdate = {"listSection": List, "sectionId": params};
            UpdateById({...paramUpdate?paramUpdate:{}})
            .then((response:any) => {
                onUpdated()
            }).finally(() => {
                setShowLoader(false)
                onHide(false);
            })
          }
    }

    const handleDrag = () => updateDrag()

    return <Modal show={show} onHide={onHide} size="lg">
                {
                    !hasShowLoader
                    && <>
                        <Modal.Header
                            closeButton
                            closeLabel={"Close"}
                            className="first-access"
                            style={{zIndex: 10}}>
                                <Modal.Title>Are you sure you want to reorder these items?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <ButtonStyle
                                    onClick={handleDrag}
                                    label="Yes"
                                    icon="checkedGreen2" />
                            <ButtonStyle
                                    onClick={() => {
                                        onHide()
                                        reload()
                                    }}
                                    label="No"
                                    icon="close" />
                        </Modal.Footer>
                    </>
                }
                {
                    hasShowLoader && <div className="loader"></div>
                }
            </Modal>
}


export default ReorderModal

import * as React    from "react"
import { Modal }     from "react-bootstrap"
import styled        from "styled-components"

import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"
import ModalBodyUnlockStyle from "YConnect/Styles/ModalBodyUnlock.style"
import { YLinkButtonStyle, LinkStyle, ButtonStyle } from "YConnect/Components/Button"


type UnlockTagModalProps = {
    isShow:boolean
    backpack: any
    onClose:() => void
}

const ImgStyle = styled.img`
    max-width: 92px;
`

const ContentText = styled.div`
    color: #565656;
`

const UnlockTagModal = ({isShow, onClose, backpack}:UnlockTagModalProps) =>{

    const {nome_stamp, imagem, nome_tag, nome, id_stamp} = backpack || {}

    return <Modal keyboard={false} show={isShow} onHide={onClose} size="lg" className="modal-background first-access">
                <Modal.Header closeLabel="Close" closeButton className="first-access"></Modal.Header>
                <ModalBodyUnlockStyle>
                    <div className="tag-img">
                        <ImgStyle className="img-fluid" src={getStaticUrl(`assets/${imagem}`)} alt={`${nome} from ${nome_stamp}`}/>
                    </div>
                    <ContentText className="modal-content">
                        <h2>Congratulations!</h2>
                        <p>You have unlocked a new {nome}: {nome && nome.toUpperCase() === "TAG"?<strong>{nome_tag}/{nome_stamp}</strong>:<strong>{nome_stamp}</strong>}</p>
                        {
                            nome && nome.toUpperCase() === "TAG"
                            ?<small>You can already see the contents of the new tag.</small>
                            :<small>You can now start the studies of the new unit.</small>
                        }
                    </ContentText>
                    <div className="footer-modal-tag">
                        <ButtonStyle green
                            size={'large'}
                            label="Go to country"
                            icon="arrowRight"
                            onClick={() => { window.location.href = `#/countries/${id_stamp}`; onClose() }} />
                    </div>
                </ModalBodyUnlockStyle>
            </Modal>
    }

export default UnlockTagModal

import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"

import { ButtonStyle } from "YConnect/Components/Button"
import AudioMainView from "YConnect/PagesComponents/CMS/Views/AudioMain.view"
import FormContainer from "YConnect/Containers/Form.container"
import AudioFilesModal from "YConnect/PagesComponents/CMS/Modals/AudioFiles.modal"

import DEFAULT_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Default.template.json"
import ClickAudioWithImageTable from "YConnect/PagesComponents/CMS/Editors/ClickAudioWithImage.editor/ClickAudioWithImage.editableTable"


const GetImagesByOrderByQuestion = (question: QuestionType) => {
    if (question) {
        const { Answers, ExerciseMedias } = question
        if (Answers && ExerciseMedias) {
            const newImagesByOrder = Answers
                .reduce((acc: any, answer) => {
                    acc[answer.Order] = {
                        answer,
                        exerciseMedia: ExerciseMedias.find(({ Order }) => answer.Order == Order)
                    }
                    return acc
                }, {})

            return newImagesByOrder
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}


type ClickAudioWithImageTemplateEditorProps = {
    question: QuestionType
    onChangeValues: any
}

const ClickAudioWithImageTemplateEditor = ({
    question,
    onChangeValues
}: ClickAudioWithImageTemplateEditorProps) => {

    const [isShowAudioFilesModal, setShowAudioFilesModal] = useState(false)

    const [values, setValues] = useState(question)
    const [Answers, setAnswers] = useState<Array<AnswerType>>(question.Answers)

    const [imagesByOrder, setImagesByOrder] = useState(GetImagesByOrderByQuestion(question))


    const handleChangeValues = (values: any) => addValues(values)
    const handleChangeAnswer = (values: Array<AnswerType>) => setAnswers(values)

    const addValues = (newValues: any) => {

        setValues({ ...values, ...newValues })
        onChangeValues({ ...values, ...newValues })
    }

    useEffect(() => {
        setValues({ ...values, Answers })
        onChangeValues({ ...values, Answers })
    }, [Answers])

    useEffect(() => {
        if (question) {
            setValues(question)
        }
    }, [question])

    const addMainExerciseMedia = ({ Source, MediaType }: any) => {
        const newValues = {
            ...values,
            ExerciseMedias: [{ Order: 0, Source, MediaType }, ...values.ExerciseMedias.filter(({ Order }) => Order !== 0)]
        }
        setValues(newValues)
        onChangeValues(newValues)
    }

    const getMainExerciseMedia = () =>
        values && values.ExerciseMedias.find(({ Order }) => Order === 0)

    const handleOpenModalAudioFiles = () => setShowAudioFilesModal(true)
    const handleCloseModalAudioFiles = () => setShowAudioFilesModal(false)
    const handleAddAudio = ({ Source, MediaType }: any) => {
        addMainExerciseMedia({ Source, MediaType })
        setShowAudioFilesModal(false)
    }
    const handleRemoveExerciseMedia = () => {
        const newValues = {
            ...values,
            ExerciseMedias: values.ExerciseMedias
                .filter(({ Order }) => Order !== 0)
        }

        setValues(newValues)
        onChangeValues(newValues)
    }

    const handleChangeImages = (newImagesByOrder: any) => {
        const { newAnswers } = Object.keys(newImagesByOrder)
            .reduce((acc, i) => {

                const newOrder = i * 1 + 1

                const { answer, IsCorrect }: any = newImagesByOrder[i]
                answer && acc.newAnswers.push({ Awnser: answer.Awnser, Order: newOrder, IsCorret: !!IsCorrect })

                return acc
            }, { newExerciseMedias: [], newAnswers: [] })

        const newValues = {
            ...question,
            Answers: newAnswers,
        }

        setAnswers(newAnswers)
        onChangeValues(newValues)
    }

    return <>
        <AudioMainView medias={values && values.ExerciseMedias} />
        {
            !(getMainExerciseMedia()
                && getMainExerciseMedia().MediaType.toLowerCase() === "audio")
            && <Row className="py-3">
                <Col md="auto">
                    <ButtonStyle
                        green
                        disabled={getMainExerciseMedia() && getMainExerciseMedia().MediaType === "image"}
                        size="190"
                        onClick={handleOpenModalAudioFiles}
                        icon="audioWhite"
                        label="Add Audio" />
                </Col>
            </Row>
        }
        {
            getMainExerciseMedia()
            && <Row className="py-3 justify-content-center">
                <Col md="auto">
                    <ButtonStyle
                        className="mb-2"
                        size="130"
                        onClick={handleOpenModalAudioFiles}
                        icon="reset"
                        label="Change" />
                </Col>
                <Col md="auto">
                    <ButtonStyle
                        size="130"
                        onClick={handleRemoveExerciseMedia}
                        icon="remove"
                        label="Remove" />
                </Col>
            </Row>
        }
        <Row>
            <Col md={12}>
                <FormContainer
                    values={values}
                    onChange={({ valuesUpdated }: any) => handleChangeValues(valuesUpdated)}
                    template={[...DEFAULT_TEMPLATE.map((field) => ({ ...field }))]} />
            </Col>
            <Col md={12} className="mt-3">
                <h3 className="font-weight-bold">Images{Answers.length > 0 && ` (${Answers.length})`}</h3>
                <ClickAudioWithImageTable
                    imagesByOrder={imagesByOrder}
                    onChange={(newImagesByOrder: any) => handleChangeImages(newImagesByOrder)} />
            </Col>
        </Row>
        {
            isShowAudioFilesModal
            && <AudioFilesModal
                onSelect={handleAddAudio}
                onClose={handleCloseModalAudioFiles} />
        }
    </>
}

export default ClickAudioWithImageTemplateEditor
import * as React        from "react"
import {  
    useEffect, 
    useRef,
    Fragment }           from "react"
import { CSSTransition } from "react-transition-group"
import { Tab }           from "react-bootstrap"
import styled            from "styled-components"

import Tag             from "YConnect/Components/Tag"
import convertPhoto    from "YConnect/Utils/ConvertPhoto.util"
import { LinkStyle }   from "YConnect/Components/Button"
import TabStyle        from "YConnect/Styles/Tab.style"
import AlphaBlackStyle from "YConnect/Styles/AlphaBlack.style"


const TabContentStyle = styled.div `
    padding: 16px;
    max-height: 70vh;
    overflow-y: scroll;
    @media screen and (min-width: 1024px) {
        padding: 30px;
    }
`

const TagStyled = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 30%;
    @media screen and (min-width: 1024px) {
        width: 25%;
    }
`

const TripProgressModal = ({ stamps, tags, showTripModal, setShowTrip }: any) => {

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowTrip(false)
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return <Fragment>
                {showTripModal && <AlphaBlackStyle/>}
                <CSSTransition classNames="dialog lateral-progress" timeout={300} in={showTripModal} unmountOnExit>
                    <div className={`YC-lateral d-flex flex-column p-0`} ref={wrapperRef} style={{zIndex: 9999}}>
                        <h4 className="align-items-center d-flex flex-row justify-content-between pl-5 pr-5 pt-5">
                            My progress
                    <LinkStyle label="Close x" className="close-modal" onClick={() => setShowTrip(!showTripModal)} />
                        </h4>

                        <TabStyle defaultActiveKey="tags" style={{ boxShadow: '2px 4px 5px 0 rgba(0,0,0,0.11)', minHeight: '90px' }}>
                            <Tab eventKey="tags" title={'Your backpack tags'} >
                                <TabContentStyle>
                                    <h2>Your backpack tags</h2>
                                    <div className="progress-filters">

                                    </div>
                                    <div className="home-tags d-flex mt-4 mb-0">
                                        {
                                            tags && tags.map((tag: any, key: any) => 
                                                <TagStyled key={key}>
                                                    <Tag tags={tag} />
                                                </TagStyled>)
                                        }
                                    </div>
                                </TabContentStyle>
                            </Tab>
                            <Tab eventKey="stamps" title={'Your country stamps'}>
                                <TabContentStyle>
                                    <h2>Your country stamps</h2>
                                    <div className="progress-filters">

                                    </div>
                                    <div className="home-tags d-flex mt-4 mb-0">
                                        {
                                            stamps && stamps.map((stamp: any, key:any) => 
                                            stamp.img_stamp
                                            && <TagStyled key={key}>
                                                    {
                                                        stamp.id_stamp !== undefined
                                                        ? <a href={`#/countries/${stamp.id_stamp}`}>
                                                            <img key={stamp.id_stamp} src={convertPhoto(stamp.img_stamp)} alt={`Stamp from ${stamp.nome_pais}`} width="70" />
                                                        </a>
                                                        : <img key={stamp.id_stamp} src={convertPhoto(stamp.img_stamp)} alt={`Stamp from ${stamp.nome_pais}`} width="70" />
                                                    }
                                                </TagStyled>)
                                        }
                                    </div>
                                </TabContentStyle>
                            </Tab>
                        </TabStyle>
                    </div>
                </CSSTransition>
            </Fragment>
}

export default TripProgressModal
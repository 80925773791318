import * as React from "react"

import PanelCrud from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"

import {
    GetAll, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/Book.resources"

import CONFIG_TABLE_BOOK from "YConnect/PagesComponents/ControlPanel/Tables/Book.table.config"
import BOOK_TEMPLATE     from "YConnect/PagesComponents/ControlPanel/Templates/Book.template"

const BooksPage = () =>{

    return <PanelCrud
            idName            = "bookId"
            labelButtonCreate = "Create Book"
            labelForm         = "Book"
            template          = {BOOK_TEMPLATE}
            configTable       = {CONFIG_TABLE_BOOK}
            GetAll            = {GetAll}
            GetById           = {GetById}
            Create            = {Create}
            UpdateById        = {UpdateById}
            DeleteById        = {DeleteById} />
}

export default BooksPage
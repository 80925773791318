import * as React from "react"
import { Link }   from "react-router-dom"
import styled     from "styled-components"

import * as save            from "YConnect/Assets/svg/save.svg"
import * as remove          from "YConnect/Assets/svg/remove.svg"
import * as print           from "YConnect/Assets/svg/print.svg"
import * as download        from "YConnect/Assets/svg/download.svg"
import * as arrowRight      from "YConnect/Assets/svg/arrow-right.svg"
import * as arrowRightGreen from "YConnect/Assets/svg/arrow-right-green.svg"
import * as message         from "YConnect/Assets/svg/pencil.svg"
import * as messageWhite    from "YConnect/Assets/svg/message-white.svg"
import * as reply           from "YConnect/Assets/svg/reply.svg"
import * as logout          from "YConnect/Assets/svg/logout.svg"

const ICONS = {
    save,
    remove,
    print,
    download,
    arrowRight,
    message,
    messageWhite,
    reply,
    logout,
    arrowRightGreen
}

interface LinkProps {
    children?   : any,
    label?      : string,
    link?       : any,
    className?  : string,
    icon?       : 
    "save"|"remove"|"print"|"download"
    |"arrowRight"|"message"|"messageWhite"
    |"reply"|"logout"|"arrowRightGreen",
    disabled?   : boolean,
}

//TODO renomear
const YLink = ({children, label, link, icon, disabled, className }:LinkProps) => 
    <Link 
        to={link} 
        className={`${className} ${disabled ? "disabled" : "" }`}>
        {label}
        {children}
        {icon && <img src={ICONS[icon]} className="pl-3" />}
    </Link>

const YLinkStlye = styled(YLink)`
    align-items: center;
    color: #79b100;
    display: flex;
    flex-flow: row wrap;
    height: 42px;
    margin-right: 10px
    :hover {
        color: #79b100;
        text-decoration: underline;
    }
`


export {YLinkStlye}
const bc = {
    About: { path: "/about", label: "About" },
    Card: { path: "/upload-card", label: "Upload Cards" },
    Courses: { path: "/courses", label: "Courses" },
    EditUser: { path: '', label: "Edit user" },
    Explore: { path: "/explore", label: "Explore Yázigi" },
    Homepage: { path: "/", label: "Homepage" },
    ManagerUser: { path: "/manage-users", label: "Manage users" },
    Message: { path: "/my-message", label: "My messages" },
    PrePost: { path: '', label: "Pre and post test" },
    Profile: { path: "/my-profile", label: "Profile" },
    Reintegrate: { path: '', label: "Reset Activities" },
    Reports: { path: "/reports", label: "Reports" },
    ReportsTeacher: { path: "/reports-teacher", label: "Reports" },
    ReportResourceBook: { path: "/report-resource-book", label: "Report Resource Book" },
    Settings: { path: "/settings", label: "Settings" },
    Support: { path: "/support", label: "Support" },
    StudentReport: { path: '', label: "Student Report" },
    TravelWorld: { path: '/travel-the-world', label: 'Travel the world' },
    Unlock: { label: "Unlock Score" },
    CoursesControl: { label: "Courses Control" },
    ReportClassGrades: { path: "/reports-class-grades", label: "Class Grade Report" },
    ReportByStudent: { path: "/reports-by-student", label: "Report By Student" },
    CMS: { path: "/cms", label: "CMS"},
    CmsExercise: { path: "/cms/exercise", label: "Exercise Edit"},
    CmsUnits: { path: "cms/units", label: "Units Edit"},
    CmsSections: { path: "cms/sections" , label: "Sections Edit"}
}

export default bc;

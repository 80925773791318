
import * as React from "react"

import { ButtonStyle } from "YConnect/Components/Button"

import * as questionnaire   from "YConnect/Assets/svg/questionnaire.svg"
import * as travelling      from "YConnect/Assets/svg/travelling.svg"

const PrePostCard = ({ user, history, prePost }: any) => {
    const redirectPage = (id: any) => {
        history.push({
            pathname: `/pre_post/test/${prePost.listPptTestAlunoYConnect[0].aluno_curso.ppT_Teste_Yconnect.id_curso}/-1`
        });
    }

    return (
        window.innerWidth < 1024 ?
            <div className="courses card shadow mb-4 recommended-card">
                <header className="courses-header d-flex flex-row justify-content-start">
                    <figure className="m-0">
                        <img src={prePost
                            && prePost.listPptTestAlunoYConnect
                            && prePost.listPptTestAlunoYConnect[0] 
                            && prePost.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre ? travelling : questionnaire} />
                    </figure>
                    <div className="courses-header-title pl-3">
                        <span className="text-muted">
                            Your current course: {user.cursoAtual.length > 0 && user.cursoAtual[0].titulo_curso}
                        </span>
                        <h3 className="font-weight-bold">
                        {
                            prePost
                            && prePost.listPptTestAlunoYConnect
                            && prePost.listPptTestAlunoYConnect[0] 
                            && prePost.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre
                            ? 'Are you ready to start you journey?'
                            : "Let's review everything you learned this semester?"
                            }
                        </h3>
                        {
                            prePost
                            && prePost.listPptTestAlunoYConnect
                            && prePost.listPptTestAlunoYConnect[0] 
                            && prePost.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre
                            && <p className="course-description mt-4 font-weight-bold">
                                Before you start, complete a quick test to measure your current English level.
                            </p>
                        }
                    </div>
                </header>
                <div className="course-info mb-3">
                    <ButtonStyle
                        green
                        label     = "Letʼs get it started"
                        icon      = "arrowRight"
                        className = "align-self-end"
                        onClick   = {() => redirectPage(prePost
                            && prePost.listPptTestAlunoYConnect
                            && prePost.listPptTestAlunoYConnect[0] 
                            && prePost.listPptTestAlunoYConnect[0].aluno_curso.ppT_Teste_Yconnect.id_curso)}
                        size      = "medium"
                    />
                </div>
            </div>
            :
            <div className="courses courses-desktop card shadow mb-4 recommended-card">
                <figure className="m-0">
                    <img src={prePost
                            && prePost.listPptTestAlunoYConnect
                            && prePost.listPptTestAlunoYConnect[0] 
                            && prePost.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre ? travelling : questionnaire} />
                </figure>
                <div className="courses-info">
                    <span className="text-muted">
                    Your current course: {user.cursoAtual.length > 0 && user.cursoAtual[0].titulo_curso}
                    </span>
                    <h3 className="font-weight-bold">
                        {
                            prePost
                            && prePost.listPptTestAlunoYConnect
                            && prePost.listPptTestAlunoYConnect[0] 
                            && prePost.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre
                            ? "Are you ready to start you journey?"
                            : "Let's review everything you learned this semester?"
                        }
                    </h3>
                    {
                        prePost
                        && prePost.listPptTestAlunoYConnect
                        && prePost.listPptTestAlunoYConnect[0] 
                        && prePost.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre
                        && <p className="course-description mt-4 font-weight-bold">
                            Before you start, complete a quick test to measure your current English level.
                        </p>
                    }
                    <ButtonStyle
                        green
                        label     = "Letʼs get it started"
                        icon      = "arrowRight"
                        className = "align-self-end"
                        onClick   = {() => redirectPage(prePost
                            && prePost.listPptTestAlunoYConnect
                            && prePost.listPptTestAlunoYConnect[0] 
                            && prePost.listPptTestAlunoYConnect[0].aluno_curso.ppT_Teste_Yconnect)}
                        size      = "medium"/>
                </div>

            </div>
    )
}

export default PrePostCard

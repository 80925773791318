import styled from "styled-components"


const CourseInfoStyle  = styled.div`
display: flex;
justify-content: space-between;
flex-flow: row wrap;

.card {
    @media screen and (min-width: 1024px) {
        display: flex;
        flex-flow: row wrap;
        width: 100% !important;
    }
}

.course-info {
    display: flex;
    flex-flow: row wrap;
    @media screen and (min-width: 1024px) {
        width: 100%;
    }

    .course-picture {
        background-size: cover;
        height: 91px;
        width: 81px;
        @media screen and (min-width: 1024px) {
            height: 250px;
            width: 250px;
        }
    }

    .course-info-content {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        padding: 0 0 0 16px;
        width: 60%;
        @media screen and (min-width: 1024px) {
            padding: 0 0 0 43px;
            width: 75%;
        }
    }

    .course-info-subtitle {
        display: block;
        font-size: 0.75rem;
        line-height: 14px;
    }

    .course-info-percent {
        color: ${props => props.theme.main.mainPurple};
        display: block;
        font-size: 36px;
        margin: 15px 0 0;
    }

    .course-info-completed {
        margin: 0 0 8px;
    }

    .course-info-resources {
        margin: 24px 0 8px;
        display: flex;
        flex-direction: column;

        .audio, .active-teach, .epub {
            color: ${props => props.theme.main.clickable};
        }

        .resources-options {
            color: ${props => props.theme.main.clickable};
            display: flex;
            flex-flow: column wrap;

            span {
                color: ${props => props.theme.main.clickable};
                cursor: pointer;
                display: flex;
                font-weight: bold;

                img {
                    margin: 0 16px;
                    width: 15px;
                    filter: ${props => props.theme.main.filterSvg};
                }
            }
        }
    }
}

.course-tags {
    border-left: 1px solid #f0f2f2;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    @media screen and (min-width: 1024px) {
        width: 40%;
    }

    .course-tag {
        img {
            height: 65px;
            width: 65px;
        }
    }

    .tag-title {
        align-items: flex-end;
        display: flex;
        flex-flow: row nowrap;
        font-weight: bold;
        justify-content: space-between;
        font-size: 1rem;
        @media screen and (min-width: 1024px) {
            flex-wrap: wrap;
            font-size: 1.5rem;
        }

        .tag-title-expand {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            font-weight: lighter;
            justify-content: center;
            margin-left: 47px;
            @media screen and (min-width: 1024px) {
                display: none;
            }

            > img {
                margin-left: 8px;
            }
        }
    }

    .tag {
        display: flex !important;
        flex: initial;
        height: 30%;
        margin: 0 0 0 8px;
        width: 30%;
        @media screen and (min-width: 1024px) {
            flex: 1 1 auto;
            height: 60px;
            margin: 16px;
            width: 60px;
        }

        &.locked {
            border: 3px solid ${props => props.theme.main.backgroundBtDisabled};
            border-radius: 100%;
            figure {
                height: 100%;
                padding: 16px;
                width: 100%;
            }
            img {
                height: 30px;
                width: 30px;
            }

        }
    }

    .course-tags-footer {
        border-top: 1px solid ${props => props.theme.main.backgroundBtDisabled};
        color: ${props => props.theme.main.clickable};
    }
}
`

export default CourseInfoStyle

import * as React from "react"

import Wrapper from "YConnect/Fields/Exercises/OpenQuestion.field/Wrapper.style"
import OpenQuestionStudentAnswer from "YConnect/Fields/Exercises/OpenQuestion.field/OpenQuestionStudentAnswer.field"
import OpenQuestionStatusCard from "YConnect/Fields/Exercises/OpenQuestion.field/OpenQuestionStatusCard.field"
import ExerciseText from "YConnect/Fields/Exercises/OpenQuestion.field/ExerciseText.field"
import OpenQuestionContainer from "YConnect/Fields/Exercises/OpenQuestion.field/OpenQuestionContainer.field"
import OpenQuestionModal from "YConnect/Modals/OpenQuestion.modal"
import Loader from "YConnect/Components/Loader"

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import OpenQuestionReviewType from "YConnect/Enums/OpenQuestionReviewType"
import formatText from "YConnect/Utils/FormatText.util";

class OpenQuestion extends React.Component<ExerciseFieldPropsType, {
  studentAnswer: string,
  isLoading: boolean,
  reviews: Array<any>,
  showReviewHistory: boolean,
  latestReviewTypeAndAttempt: any
}> {
  constructor(props:ExerciseFieldPropsType) {
    super(props)
    this.state = {
      studentAnswer: "",
      isLoading: false,
      reviews: [],
      showReviewHistory: false,
      latestReviewTypeAndAttempt: null
    }

    this.updateStudentAnswer = this.updateStudentAnswer.bind(this)
    this.toggleReviewHistoryDisplay = this.toggleReviewHistoryDisplay.bind(this)
    this.saveReview = this.saveReview.bind(this)
    this.saveApprovalReview = this.saveApprovalReview.bind(this)
    this.saveRejectionReview = this.saveRejectionReview.bind(this)
    this.listReviews = this.listReviews.bind(this)
  }

  componentDidMount() {
    const {attempts} = this.props

    if (attempts > 0) {
      this.fetchUserAnswer().then((response:any) => {
        this.setState({
          studentAnswer: response.data.resposta,
        })

        return this.getLatestReviewTypeAndAttempt()
      }).then((response:any) => {
        this.setState({latestReviewTypeAndAttempt: response.data})
        return this.listReviews()
      }).then((response:any) => {
        this.setState({reviews: response.data})
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
    }

    const result = this.checkNumberCharacters();

    if (result > 200) {
        document.getElementById('container-text').classList.add("textLarge");
    }
  }

  fetchUserAnswer() {
    return API.Exercicio.getRespostaUsuario({
        userId: this.props.studentId,
        exer: this.props.exercise.id_exercicio,
        tela: this.props.exercise.ordem,
        tentativa: 'U',
        idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
    }, getConfig())
  }

  getLatestReviewTypeAndAttempt() {
    return API.OpenQuestionReview.getLatestReviewTypeAndAttempt({
        idStudent: this.props.studentId,
        idExercise: this.props.exercise.id_exercicio,
        questionOrder: this.props.exercise.ordem
    }, getConfig())
  }

  updateStudentAnswer(event:any) {
    const {id, onChange} = this.props
    const {target: {value}} = event

    this.setState({studentAnswer: value})

    onChange(id, {
      qntd_acertos: 0,
      qntd_respostas: 1,
      resposta: value
    })
  }

  toggleReviewHistoryDisplay() {
    this.setState((prevState:any) => ({showReviewHistory: !prevState.showReviewHistory}),
      () => this.state.showReviewHistory ? document.body.style.overflow = "hidden" : document.body.style.overflow = "unset");
  }

  saveApprovalReview(review:string) {
    return this.saveReview(review, OpenQuestionReviewType.APPROVAL);
  }

  saveRejectionReview(review:string) {
    return this.saveReview(review, OpenQuestionReviewType.REJECTION);
  }

  saveReview(review:string, type:OpenQuestionReviewType) {
    // IMPORTANT: Some fields like userId, bookInfo and courseInfo are only available in the ExerciseReport page!
    // This function won't work in a regular exercise page unless these props are added there too.
    const {attempts, bookInfo, courseInfo, exercise, studentId, userId} = this.props

    return API.OpenQuestionReview.saveReview({
      userId,
      review,
      type,
      attempt: attempts,
      exerciseId: exercise.id_exercicio,
      questionOrder: exercise.ordem,
      studentId,
      courseId: courseInfo.id_curso,
      unitId: bookInfo.id_unidade,
      sectionName: bookInfo.nome_secao
    }, getConfig())
    .then(() => this.getLatestReviewTypeAndAttempt())
    .then((response:any) => {
      this.setState({latestReviewTypeAndAttempt: response.data})
      return this.listReviews()
    }).then((response:any) => {
      this.setState({reviews: response.data})
    })
  }

  listReviews() {
    return API.OpenQuestionReview.findAllByExerciseAndQuestionOrder({
      exerciseId: this.props.exercise.id_exercicio,
      questionOrder: this.props.exercise.ordem,
      studentId: this.props.studentId
    }, getConfig())
  }

  checkNumberCharacters() {
    const quantity = document.getElementById('text-quantity').innerHTML.length;
    return quantity;
  }

	render() {
    const {attempts, exercise, isStudent} = this.props
    const {isLoading, latestReviewTypeAndAttempt, reviews, showReviewHistory, studentAnswer} = this.state

    if (isLoading) return <Loader />

    return (
        <OpenQuestionContainer>
            <ExerciseText id="container-text" className="pl-2 pr-2 pl-lg-4 pr-lg-4 pb-2 pt-2">
                <p
                    id="text-quantity"
                    className="open-question__title"
                    dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.conteudo_exercicio)) }}
                >
                </p>
            </ExerciseText>
            <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                <Wrapper className="open-question">
                    <OpenQuestionStudentAnswer
                    attempts={attempts}
                    latestReviewTypeAndAttempt={latestReviewTypeAndAttempt}
                    value={studentAnswer}
                    onChange={this.updateStudentAnswer}
                    />
                    <OpenQuestionStatusCard
                    attempts={attempts}
                    hasReviews={reviews.length > 0}
                    isStudent={isStudent}
                    onReviewClick={this.toggleReviewHistoryDisplay}
                    latestReviewTypeAndAttempt={latestReviewTypeAndAttempt}
                    />
                    <OpenQuestionModal
                    attempts={attempts}
                    visible={showReviewHistory}
                    isStudent={isStudent}
                    latestReviewTypeAndAttempt={latestReviewTypeAndAttempt}
                    reviews={reviews}
                    onApprovalSubmit={this.saveApprovalReview}
                    onRejectionSubmit={this.saveRejectionReview}
                    onClose={this.toggleReviewHistoryDisplay}/>
                </Wrapper>
            </div>
        </OpenQuestionContainer>
    )
	}
}

export default OpenQuestion

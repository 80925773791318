import * as React from "react"
import { useEffect, useState, useContext, useRef} from "react"
import { Card } from "reactstrap"

import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import { ButtonStyle } from "YConnect/Components/Button"
import UserContext from "YConnect/User.context"
import ButtonsBookStyle from "YConnect/PagesComponents/BooksBlink/ButtonsBook.style"
import ContainerStyle from "YConnect/PagesComponents/BooksBlink/Container.styled"

const MY_STYLE_1 = {
    overflow: "hidden",
    height:"1000px"
}

const ListBooksByCourse = (idcurso:any) =>
    API.BlinkIntegration.GetListBooks({idcurso}, getConfig())

const GetBooks = ({idcurso, IsProfessor, idaluno, estab, name_book}:any) =>
    API.BlinkIntegration.GetBooks({idcurso, IsProfessor, idaluno, estab, name_book }, getConfig())


const ButtonsBook = ({list, onSelect, isTeacher}:{list:Array<any>, onSelect:any, isTeacher:boolean}) =>
    <>
        {
            list
            && list.map(({name_book}, key) =>
                <ButtonStyle
                    key={key}
                    onClick={() => onSelect({isTeacher, name_book})}>{name_book}</ButtonStyle>
            )
        }
    </>

const BooksBlinkPage = ({match}: any) => {

    const {  params: { id }} = match

    const {
        idUsuario,
        estabelecimentos,
        idProfessor,
        observador,
        colaborador,
        usuarioTrial,
        usuarioDegustacao
    } :any = useContext(UserContext)

    const [bookSelected, setBookSelected] = useState<any>()
    const [listBooks, setListBooks] = useState<Array<any>>()
    const [listBodyParam, setListBodyParam] = useState<any>()
    const formRef = useRef<any>()

    const listStudentsBooks = listBooks && listBooks.filter(({is_teacher_book}) =>  is_teacher_book === false)

    const [URLTest, setURLTest] = useState<string>()

    const isStudent = () =>
        idProfessor == 0
        && !observador
        && !colaborador
        && !usuarioTrial
        && !usuarioDegustacao

    useEffect(() => {
        updateListBooks()
    }, [])

    useEffect(() => {
        if(bookSelected)
            formUpdate()
    }, [bookSelected])

    useEffect(() => {
        if(listBodyParam)
            submit()
    }, [listBodyParam])

    const updateListBooks = async () =>
        setListBooks((await ListBooksByCourse(id)).data)

    const submit = () => {
        formRef.current.submit()
    }

    const formUpdate = async () => {
        const {parametersBody, url, urlParamters} = bookSelected
        setListBodyParam(parametersBody)
        setURLTest(url + urlParamters)
    }

    const selectBook = async ({isTeacher, name_book}:{isTeacher:boolean, name_book:string}) => {
        const {data:{response}} = await GetBooks({
            idcurso:id,
            IsProfessor:isTeacher,
            idaluno:idUsuario,
            estab:estabelecimentos[0].nm_estab,
            name_book
        })
        setBookSelected(response)
    }

    return <ContainerStyle>
                <Card className="shadow">
                    <ButtonsBookStyle>
                        <ButtonsBook
                            list={isStudent() ? listStudentsBooks : listBooks}
                            isTeacher={!isStudent()}
                            onSelect={(values:any)=> selectBook(values)}/>

                        </ButtonsBookStyle>

                        {
                            URLTest
                            && <>
                                    <form
                                        ref={formRef}
                                        className="form"
                                        action={URLTest}
                                        target="blinkFrame"
                                        onSubmit={e => {
                                            e.preventDefault()
                                        }}
                                        method="post">
                                            {
                                                listBodyParam
                                                .map(({key, value}:any, i:number) => <input key={i} type="hidden" name={key} defaultValue={value}/>)
                                            }
                                        </form>
                                    <iframe
                                        name="blinkFrame"
                                        id="blinkFrame"
                                        style={MY_STYLE_1}/>
                                </>
                        }
                    </Card>
            </ContainerStyle>
}

export default BooksBlinkPage

const NightTheme = {
/** background color */
backgroundPage:"#ccc",
backgroundWhite: "#c6c6c8",

/** main colors */
mainPurple:"#4e2d78",
errorShadowColor:"#ff1a4a59",
errorColor:"#FF1A4A",
successShadowColor: "#30dab7b0",
successColor:"#00A281",
errorColor2:"#FF1A4A",
successColor2:"#00A281",
lightPurple: "#55357d",
lightPurpleInvert  : "#55357d",
lightIvory:"#e1dcdc",
lightGray: "#f5f5f5",
lightGreen:"#bad04d",
lightBlue: '#0297af',
darkPurple:"#d7d5d5",
borderLightGray: "#cecece",
filterSvgGreen:"unset",
brightness:'brightness(100)',
grayScale: 'grayscale(1)',

/** text color */
textColor: "#424242",
textColor2:"#424242",
mutedTextColor:"#646464",
greentextColor:"#658b13",
title: "#565656",
textWhite: "#fff",
textWhite2: "#fff",
textInvert: "#fff",


/** buttons */
clickable:"#658b13",
backgroundBtDisabled: "#c6c6c8",
colorBtDisabled:"#515151",
backgroundRadioActive:"#d7d5d5",

/** Messages */
hoverMessage:"#f5f5f5",
selectText:"#fff",

}

export default NightTheme

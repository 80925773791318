import * as React           from "react"
import { Modal, FormGroup } from "react-bootstrap"
import { ButtonStyle }      from "YConnect/Components/Button"
import { BackLinkStyle } from "YConnect/Styles/BackLink.style"

import styled from "styled-components";

import { YLinkButtonStyle }  from "YConnect/Components/Button"

import * as world from "YConnect/Assets/svg/world.svg"

/** Style */
const GeneralStyle = styled(Modal)`
    .modal-content {
        width: 120% !important;
    }
`

const BodyStyle = styled(Modal.Body)`
    background: ${props => props.theme.main.backgroundWhite};
    padding: 20px !important;
    border-radius: 10px 10px 0 0 !important;

    h1 {
        text-align: center;
        margin-top: 30px;
    }

    .body-message {
        text-align: center;
        margin-top: 25px;
    }
`

const DivOvalStyle = styled.div`
    border-radius: 100%;
    box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.11);
    width: 204px;
    height: 204px;
    background: #643e94;
    padding: 19px;
    margin: auto;
    margin-top: -115px;

    div {
        background: white;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.11);
        text-align: center;
        padding: 30px;

        img {
            margin: auto;
            width: 100%;
            height: auto;
        }
    }
`

const FooterStyle = styled(Modal.Footer)`
    background: ${props => props.theme.main.backgroundWhite};
    justify-content: space-between;
`

const ButtonCloseStyle = styled(YLinkButtonStyle)`
    border: none;
    padding: 0;

    img {
        padding-left: 10px !important;
        width: 25px;
    }
`


type OrientationFinalTestToBatteryProps = {
    isShow: boolean
    onClose: () => void
    username: string
    prePost: any
}

const OrientationFinalTestToBattery = ({ isShow, onClose, username, idCourse, segundaProva, WTFinished }: OrientationFinalTestToBatteryProps) => {

    return <>
    {
        segundaProva
        ?
        <GeneralStyle show={isShow} onHide={onClose} className="modal-background first-access">
            <Modal.Header closeLabel={'Close'} closeButton className="first-access" style={{zIndex: 10}}></Modal.Header>
            <BodyStyle>
                <div className="w-100">
                    <DivOvalStyle>
                        <div>
                            <img src={world} />
                        </div>
                    </DivOvalStyle>
                </div>

                <h1>Hello, {username}! :)</h1>

                {
                    WTFinished == null ?
                    <div className="body-message">
                        Let's put your writing skills into practice. You still need some help with some topics studied in this program, talk to your teacher about the difficulties you had.
                    </div>
                    : <div>You concluded all of your tests, congratulations!</div>
                }

            </BodyStyle>
            <FooterStyle className="d-flex ">
                <ButtonCloseStyle
                    link    = {`/courses/${idCourse}/view-book`}
                    icon    = "reset"
                    onClick = {onClose}
                    label   = "Go Back to Book tasks" />
                {
                    WTFinished == null &&
                    <YLinkButtonStyle
                        green
                        size  = "medium"
                        label = "Go to the Written Test"
                        icon  = "arrowRight"
                        link  = {`/written_test/${idCourse}`}
                    />
                }

            </FooterStyle>
        </GeneralStyle>
        : <GeneralStyle show={isShow} onHide={onClose} className="modal-background first-access">
            <Modal.Header closeLabel={'Close'} closeButton className="first-access" style={{zIndex: 10}}></Modal.Header>
            <BodyStyle>
                <div className="w-100">
                    <DivOvalStyle>
                        <div>
                            <img src={world} />
                        </div>
                    </DivOvalStyle>
                </div>

                <h1>Hello, {username}! :)</h1>

                <div className="body-message">
                    You still need help with some topics studied in this program. The following activities are to practice these topics before you take the test again.
                </div>
            </BodyStyle>
            <FooterStyle className="d-flex ">
                <ButtonCloseStyle
                    link    = {`/courses/${idCourse}/view-book`}
                    icon    = "reset"
                    onClick = {onClose}
                    label   = "Go Back to Book tasks" />
                <YLinkButtonStyle
                    green
                    size  = "medium"
                    label = "Go to Extra support"
                    icon  = "arrowRight"
                    link  = {`/extra_support/${idCourse}`}
                />
            </FooterStyle>
        </GeneralStyle>
    }
    </>
    }

export default OrientationFinalTestToBattery

import * as React from "react"
import {useState, useEffect} from "react"



import ExerciseDialogueStyle     from "YConnect/Fields/Exercises/DialogueLong.field/ExerciseDialogue.style"



type DragAndDropViewProps = {
    question:QuestionType
}

const DragAndDropView = ({ question }: DragAndDropViewProps) => {

    const [values, setValues] = useState<QuestionType>()
    const [dialogueMessageByOrder, setDialogueMessageByOrder] = useState<any>()

    useEffect(()=>{
        if(question){
            setValues(question)
        }
    }, [question])

    useEffect(()=>{
        if(values && values.Dialogues[0] && values.Dialogues[0].DialogueMessages){

            const {DialogueMessages} = values.Dialogues[0]
            const {newDialogueMessageByOrder} = DialogueMessages
            .reduce((acc:any, value) => {
                if(!acc.newDialogueMessageByOrder[value.OrderMessage]){
                    acc.newDialogueMessageByOrder[value.OrderMessage] = []
                }
                if(value.Text === null){
                    acc.countAnswerOrder++;
                    acc.newDialogueMessageByOrder[value.OrderMessage].push({...value,  AnswerOrder:acc.countAnswerOrder})
                }else{
                    acc.newDialogueMessageByOrder[value.OrderMessage].push(value)
                }
                return acc
            }, {newDialogueMessageByOrder:{}, countAnswerOrder:0})
            setDialogueMessageByOrder(newDialogueMessageByOrder)
            
        }
    }, [values])


    return <>
            
            {
                dialogueMessageByOrder
                && Object
                .keys(dialogueMessageByOrder)
                .map((OrderMessage:any, index) =>
                        <ExerciseDialogueStyle
                            key={index}
                            className={`borderBottom pl-5 pr-5 pt-5`}
                            md="12"
                            lg="12">
                                <div className="exercise-dialogue-text">
                                     {
                                            dialogueMessageByOrder[OrderMessage]
                                            .map(({Text, AnswerOrder}:any, key:number) => {
                                                return Text 
                                                ?<span key={key} className="mr-1">{Text}</span>
                                                : <span key={key} className="resposta mr-1">
                                                    {(values.Answers.find(({Order, IsCorret}) => {
                                                        return Order == AnswerOrder && IsCorret
                                                    })||{}).Awnser || "___"}
                                                </span>
                                            })
                                        }
                                </div>
                        </ExerciseDialogueStyle>)
            }
    </>


}

export default DragAndDropView
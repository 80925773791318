import styled from "styled-components"

const ExerciseSubStatementStyle = styled.div`
    color: ${props => props.theme.main.textColor};
    background: ${props => props.theme.main.backgroundPage};
    border: ${props => props.theme.main.borderLightGray};
    border-style: solid none;
        padding: 50px 16px;
    @media screen and (min-width: 1024px) {
        padding: 50px 0;
    }
`
export default ExerciseSubStatementStyle
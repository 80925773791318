import * as React from "react"
import { useEffect, useState } from "react"
import { Col, Row, Card } from "reactstrap"
import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"

import AnswerEditor from "YConnect/PagesComponents/CMS/Editors/DragAndDrop.editor/Answer.editor"

const RowStyled = styled(Row)`
    max-height: 600px;
    border-top: 1px solid rgb(0 0 0 / 21%);
    border-right: 1px solid rgb(0 0 0 / 21%);
    border-left: 1px solid rgb(0 0 0 / 21%);
    background-color: #f0f5f6;
    overflow: auto;
`

const ColStyled = styled(Col)`
    border-bottom: 1px solid rgb(0 0 0 / 21%);
    border-right: 1px solid rgb(0 0 0 / 21%);
    border-left: 1px solid rgb(0 0 0 / 21%);
    background-color: #f0f5f6;
`


const GetAnswersByOrderByQuestion = (question:QuestionType) => {
    if(question && question.Answers.length > 0){
        const newAnswersByOrder = question.Answers
        .reduce((acc:any, answer)=>{
            if(!acc[answer.Order]){
                acc[answer.Order] = []
            }

            acc[answer.Order].push(answer)
            return acc
        },{})
        return newAnswersByOrder
    }else{
        return {1:[]}
    }
}

type AnswersEditorProps = {
    question: QuestionType
    onChange:any
}

const AnswersEditor = ({
    question,
    onChange
}:AnswersEditorProps) => {

    const [answersByOrder, setAnswersByOrder] = useState<any>(GetAnswersByOrderByQuestion(question))

    const handleNewAnswers = () => {
        setAnswersByOrder({
            ...answersByOrder,
            [Object.keys(answersByOrder).length+1]:[]
        })
    }

    const handleChangeAnswers = (order:number, newAnswers:Array<AnswerType>) => {

        const newQuestion = {
            ...question,
            Answers: [
                ...newAnswers.map((answer) => ({...answer, Order:order, IsCorret:!!answer.IsCorret})),
                ...question.Answers.filter(({Order}) => Order != order)
            ]
        }
        onChange(newQuestion)
        
    }

    return <Col md={12} className="mt-3">
                <h3 className="font-weight-bold">
                    Answer Group {answersByOrder && Object.keys(answersByOrder).length > 0 && ` (${ Object.keys(answersByOrder).length})`}
                </h3>
                <RowStyled className="m-0 pt-4 px-4">
                    {
                        answersByOrder
                        && Object.keys(answersByOrder)
                        .map((order, index) =>
                        <Col md={12} className="mb-4" key={index}>
                            <Card className="p-3 shadow mb-0">
                                <AnswerEditor 
                                    key={index}
                                    order={order}
                                    answers={answersByOrder[order]}
                                    onChange={(newAnswers:any) => handleChangeAnswers(order, newAnswers)}/>
                            </Card>
                        </Col>)
                    }
                </RowStyled>
                <ColStyled md={12} className="p-3">
                    <Row className="justify-content-end">
                        <Col md="auto">
                            <ButtonStyle
                                green
                                label     = "New Answers"
                                size      = "190"
                                className = "mr-2"
                                onClick   = {handleNewAnswers}
                                icon      = "plusWhite"/>
                        </Col>
                    </Row>
                </ColStyled>
            </Col>
}


export default AnswersEditor

import * as React              from "react"
import { useEffect, useState } from "react"
import { Row, Col }            from "reactstrap"

import RankedProgressBar   from "YConnect/PagesComponents/PrePostReport/Components/RankedProgressBar/index"
import TabReportStyle      from "YConnect/PagesComponents/PrePostReport/School.report/TabReport.style"

import BestRankedStudents  from "YConnect/PagesComponents/PrePostReport/School.components/BestRankedStudents"
import SchoolAverageScore  from "YConnect/PagesComponents/PrePostReport/School.components/SchoolAverageScore"
import BestClassScore      from "YConnect/PagesComponents/PrePostReport/School.components/BestClassScore"


const TabReport = ({
    isPost,
    bestClassScore, 
    rankedClasses, 
    bestRankedStudents, 
    averageScore
}: any) => 
    <>
        <Row>
            <Col md={4}>
                <SchoolAverageScore 
                    isPost       = {isPost}
                     averageScore = {averageScore}/>
                <BestClassScore 
                    className      = "mt-3 mb-3"
                    isPost         = {isPost}
                    bestClassScore = {bestClassScore}/>
            </Col>
            <Col md={8}>
                <BestRankedStudents 
                    isPost             = {isPost}
                    isOrderable        = {(isPost?true:false)} 
                    bestRankedStudents = {bestRankedStudents}/>
            </Col>
        </Row>

        <Row>
            <Col xs={12} lg={6} className="mb-3">
                <RankedProgressBar 
                    title       = "Best ranked classes" 
                    isOrderable = {(isPost?true:false)}
                    prePost     = {rankedClasses && rankedClasses.highest.filter((high: any) => high.fl_is_pre === (isPost?false:true))} />
            </Col>
            <Col xs={12} lg={6}>
                <RankedProgressBar 
                    title       = "Lowest ranked classes" 
                    isOrderable = {(isPost?true:false)}
                    prePost     = {rankedClasses && rankedClasses.lowest.filter((high: any) => high.fl_is_pre === (isPost?false:true))} />
            </Col>
        </Row>
    </>

export default TabReport
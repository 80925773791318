
import * as React           from "react"
import {
    Fragment,
    useEffect,
    useState
}                           from "react"

import Form from "YConnect/Containers/Form.container"

const FormWrapper = ({selectData, form, onChange, values, isValidate, onValidated}:any) => {
    const [formattedValues, setFormattedValues] = useState(undefined)
    
    useEffect(() => {
        setFormattedValues(values)
    }, [values])
    
    return formattedValues
    ? <Form
            className   = "mb-4"
            selectData  = {selectData}
            template    = {form.template}
            values      = {formattedValues}
            onChange    = {onChange}
            onValidated = {onValidated}
            isValidate  = {isValidate}
            onMapState  = {form.onMapState}/>
    : <Fragment/>
    
}

export default FormWrapper
import BOOK_API from "./Endpoints/cms-mvc/Book.api.json";
import ACESSIBILIDADE_API from "./Endpoints/legacy/Acessibilidade.api.json";
import ACESSO_API from "./Endpoints/legacy/Acessibilidade.api.json";
import ALUNO_API from "./Endpoints/legacy/Aluno.api.json"
import ASSESSMENT_FINAL_TEST_API from "./Endpoints/legacy/AssessmentFinalTest.api.json"
import AUDIO_API from "./Endpoints/legacy/Audio.api.json"
import AUTH_API from "./Endpoints/legacy/Auth.api.json"
import BATTERY_TEST_API from "./Endpoints/legacy/BatteryTest.api.json"
import BBB_INTEGRATION_API from "./Endpoints/legacy/BBBIntegration.api.json"
import BLINK_INTEGRATION_API from "./Endpoints/legacy/BlinkIntegration.api.json"
import CHAMADA_API from "./Endpoints/legacy/Chamada.api.json"
import COMENTARIO_API from "./Endpoints/legacy/Comentario.api.json"
import CONFIRMAR_EMAIL_API from "./Endpoints/legacy/ConfirmarEmail.api.json"
import CONSULADO_API from "./Endpoints/legacy/Consulado.api.json"
import COURSE_API from "./Endpoints/legacy/Course.api.json"
import CURTIDA_API from "./Endpoints/legacy/Curtida.api.json"
import DASHBOARD_API from "./Endpoints/legacy/Dashboard.api.json"
import DECONGELAR_NOTAS_API from "./Endpoints/legacy/DecongelarNotas.api.json"
import DOWNLOADS_API from "./Endpoints/legacy/Downloads.api.json"
import ENVIO_NOTAS_API from "./Endpoints/legacy/EnvioNotas.api.json"
import ESTADO_API from "./Endpoints/legacy/Estado.api.json"
import EXERCICIO_API from "./Endpoints/legacy/Exercicio.api.json"
import EXPLORE_REPORT_API from "./Endpoints/legacy/ExploreReport.api.json"
import GRUPO_API from "./Endpoints/legacy/Grupo.api.json"
import HISTORICO_REINTEGRACAO_API from "./Endpoints/legacy/HistoricoReintegracao.api.json"
import IDIOMA_API from "./Endpoints/legacy/Idioma.api.json"
import NOTE_API from "./Endpoints/legacy/Note.api.json"
import OPEN_QUESTION_API from "./Endpoints/legacy/OpenQuestion.api.json"
import OPEN_QUESTION_REVIEW_API from "./Endpoints/legacy/OpenQuestionReview.api.json"
import PAIS_YCONNECT_API from "./Endpoints/legacy/PaisYconnect.api.json"
import PRE_POST_REPORT_API from "./Endpoints/legacy/PrePostReport.api.json"
import PRE_POST_TEST_API from "./Endpoints/legacy/PrePostTest.api.json"
import PROFILE_API from "./Endpoints/legacy/Profile.api.json"
import REPORT_API from "./Endpoints/legacy/Report.api.json"
import REPORT_INDIVIDUAL from "./Endpoints/legacy/ReportIndividual.api.json"
import STAMP_API from "./Endpoints/legacy/Stamp.api.json"
import STARTER_API from "./Endpoints/legacy/Starter.api.json"
import SUPORTE_API from "./Endpoints/legacy/Suporte.api.json"
import TAG_API from "./Endpoints/legacy/Tag.api.json"
import UNIDADE_API from "./Endpoints/legacy/Unidade.api.json"
import URL_EXTERNA_API from "./Endpoints/legacy/UrlExterna.api.json"
import USUARIO_API from "./Endpoints/legacy/Usuario.api.json"
import WRITTEN_TEST_API from "./Endpoints/legacy/WrittenTest.api.json"
import ZERAR_NOTA_API from "./Endpoints/legacy/ZerarNota.api.json"



export default [
    BOOK_API,
    ACESSIBILIDADE_API,
    ACESSO_API,
    ALUNO_API,
    ASSESSMENT_FINAL_TEST_API,
    AUDIO_API,
    AUTH_API,
    BATTERY_TEST_API,
    BBB_INTEGRATION_API,
    BLINK_INTEGRATION_API,
    CHAMADA_API,
    COMENTARIO_API,
    CONFIRMAR_EMAIL_API,
    CONSULADO_API,
    COURSE_API,
    CURTIDA_API,
    DASHBOARD_API,
    DECONGELAR_NOTAS_API,
    DOWNLOADS_API,
    ENVIO_NOTAS_API,
    ESTADO_API,
    EXERCICIO_API,
    EXPLORE_REPORT_API,
    GRUPO_API,
    HISTORICO_REINTEGRACAO_API,
    IDIOMA_API,
    NOTE_API,
    OPEN_QUESTION_API,
    OPEN_QUESTION_REVIEW_API,
    PAIS_YCONNECT_API,
    PRE_POST_REPORT_API,
    PRE_POST_TEST_API,
    PROFILE_API,
    REPORT_API,
    REPORT_INDIVIDUAL,
    STAMP_API,
    STARTER_API,
    SUPORTE_API,
    TAG_API,
    UNIDADE_API,
    URL_EXTERNA_API,
    USUARIO_API,
    WRITTEN_TEST_API,
    ZERAR_NOTA_API
].reduce((acc:any, {name, endpoints}:any)=>{
    return {...acc, [name]:endpoints}
}, {})

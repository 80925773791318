import * as React       from "react"

interface YPageTitleProps {
    children?   : any,
    label       : string,
    className?  : string,
}

const PageTitle = ({children, label, className}:YPageTitleProps) => (
    <section className="row">
        <div className={`mb-4 ${className ? className: "col-12"}`}>
            <h1>{label}</h1>
        </div>
        {children}
    </section>
)


export default PageTitle

export default [
    {
        keystone: "Objective",
        className: "col-md-12",
        type: "input",
        label: "Objective"
    },
    {
        keystone: "Description",
        className: "col-md-12",
        type: "input",
        label: "Description"
    }
    
]
import styled from "styled-components"

const ResultCardContentStyle = styled.div`
    align-items: center;
    border: 1px solid #E1E6E7;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 223px;
`

export default ResultCardContentStyle
import BOOK_API from "./Endpoints/cms-mvc/Book.api.json";
import COURSE_API from "./Endpoints/cms-mvc/Course.api.json";
import EXERCISE_API from "./Endpoints/cms-mvc/Exercise.api.json";
import EXERCISE_TYPE_API from "./Endpoints/cms-mvc/ExerciseType.api.json";
import FILE_API from "./Endpoints/cms-mvc/File.api.json";
import LEARNING_OBJECTIVE_API from "./Endpoints/cms-mvc/LearningObjective.api.json";
import LEVEL_API from "./Endpoints/cms-mvc/Level.api.json";
import QUESTION_API from "./Endpoints/cms-mvc/Question.api.json";
import SECTION_API from "./Endpoints/cms-mvc/Section.api.json";
import TEMPLATE_API from "./Endpoints/cms-mvc/Template.api.json";
import UNIT_API from "./Endpoints/cms-mvc/Unit.api.json";




export default [
    BOOK_API,
    COURSE_API,
    EXERCISE_API,
    EXERCISE_TYPE_API,
    FILE_API,
    LEARNING_OBJECTIVE_API,
    LEVEL_API,
    QUESTION_API,
    SECTION_API,
    TEMPLATE_API,
    UNIT_API,


].reduce((acc:any, {name, endpoints}:any)=>{
    return {...acc, [name]:endpoints}
}, {})
import * as React from "react"
import {Card}     from "reactstrap"

import SelectFieldNewStyle from "YConnect/Components/SelectFieldNew.style"

const Filters = ({
    isLoading,
    filterProps
}:any) => 
<Card className="users-table shadow">
    <header className="users-table-header justify-content-start mb-2">
        {isLoading && <div className="loader"></div>}
        {
            filterProps
            .map((props:any, key:number) => 
                React.createElement(SelectFieldNewStyle, {
                    key, 
                    ...props, 
                    disabled: isLoading || props.disabled
                }, null))
        }
    </header>
</Card>

export default Filters
import * as React from "react"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"

const Tag = ({tags}: any) =>
    <a href={`#/countries/${tags.id_stamp}`} className="decoration-none" style={{minHeight: '150px'}}>
        <div className="tag mb-3 align-items-center d-flex flex-column flex-wrap">
            <figure className="mb-0">
                <img 
                    src={getStaticUrl(`assets/${tags.img_tag}`)} 
                    alt={`Tag of the country ${tags.stamp.nome_pais}`} 
                    title={`Tag of the country ${tags.stamp.nome_pais}`}
                />
            </figure>
        </div>
    </a>


export default Tag
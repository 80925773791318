import * as React from "react"
import {Tooltip, Col} from "reactstrap"
import find       from "lodash/find"

import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style"

import AudioPlayer  from "YConnect/Components/AudioPlayer"
import Loader       from "YConnect/Components/Loader"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"
import API          from "YConnect/API"
import getConfig    from "YConnect/Utils/GetRequestConfig.util"
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

import AudioPlayerColStyle       from "YConnect/Fields/Exercises/ClickAudio.field/AudioPlayerCol.style"
import Div2Style                 from "YConnect/Fields/Exercises/ClickAudio.field/Div2.style"
import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/ClickAudio.field/ExerciseSubStatement.style"
import OptionsRowStyle           from "YConnect/Fields/Exercises/ClickAudio.field/OptionsRow.style"
import formatText from "YConnect/Utils/FormatText.util";

class ClickAudioField extends React.Component<ExerciseFieldPropsType, {
    respostasUsuario : string
    imageCorrect     : boolean
    loading          : boolean
    resposta         : any
    tooltipOpen      : boolean
    isDisabledViewAnswers : boolean
}> {
    constructor(props: any) {
        super(props)

        if (this.props.innerRef) {
            this.props.innerRef.current.selectLanguage = this.selectLanguage
        }

		this.state = {
            respostasUsuario: null,
            imageCorrect: null,
            loading: false,
            resposta: null,
            tooltipOpen: false,
            language: this.props.translated,
        }

        this.handleChange = this.handleChange.bind(this)
        this.getClass = this.getClass.bind(this)
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this)
    }

    componentDidMount() {
        if (this.props.attempts > 0)
            this.fetchUserAnswer()
    }

    selectLanguage = (language: string) => {
        this.setState({...this.state, language})
    }

    async fetchUserAnswer() {
        this.setState({ loading: true })
        try {
            const response = await API.Exercicio.getRespostaUsuario({
                userId: this.props.studentId,
                exer: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem,
                tentativa: 'U',
                idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
            }, getConfig())

            this.setState({
                respostasUsuario: response.data.resposta,
                imageCorrect: response.data.qntd_acertos > 0,
                loading: false
            })

        } catch (error) {}
    }

    handleChange(ordem: any) {
        const { id, exercise, onChange } = this.props
        const score = this.calculaScore(ordem, exercise.respostas_Exercicio_Yconnect)
        this.setState({
            resposta: ordem
        })

        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: [ordem]
        })
    }

    calculaScore(respostaUsuario: any, alternativas: any) {
        let ordemCorreta = null;
        let qntdAcertos = 0;

        alternativas.map((alt: any) => {
            if (alt.correta) ordemCorreta = alt.ordem
        })

        if (respostaUsuario == ordemCorreta) {
            qntdAcertos++
        }

        return { qntd_acertos: qntdAcertos, qntd_respostas: 1 }
    }

    showCorrectAnswers() {
        const { id, exercise, onChange } = this.props

        const answer = find(exercise.respostas_Exercicio_Yconnect, { correta: true})

        const score = this.calculaScore(answer.ordem, exercise.respostas_Exercicio_Yconnect)
        this.setState({
            resposta: null,
            respostasUsuario: `${answer.ordem}`,
            imageCorrect: true
        })

        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: [answer.ordem]
        })
    }

    getClass(ordem: number) {
        const { resposta, imageCorrect, respostasUsuario } = this.state


        if (resposta) {
            return  resposta === ordem ? 'item-active': ''
        }

        if (respostasUsuario === `${ordem}`) {
            return imageCorrect ? 'item-active correct' : 'item-active wrong'
        }

        return ''
    }

	render() {
		let {
			props: {
                exercise,
                attempts,
                isStudent,
                isDisabledViewAnswers
			},
            handleChange,
            getClass
        } = this

        if (this.state.loading) return <Loader />

        const midiaYConnect = exercise.midia_Yconnect.length > 0 ? exercise.midia_Yconnect[0]
            : (exercise.exercicio_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect.length > 0) ? exercise.exercicio_Yconnect.midia_Yconnect[0]
                : {}

        return (
            <>
                {
                    !isDisabledViewAnswers
                     && <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                   {(attempts >= 2 || !isStudent) ?
                        <HeaderButtonStyle
                            className="float-right"
                            label="View Answers"
                            onClick={this.showCorrectAnswers}
                            icon="checkedGreen2"
                        />
                        :
                        <>
                            <HeaderButtonStyle
                                id="completeResultToolTip"
                                className="float-right disabled view-answers"
                                label="View Answers"
                                icon="checkedGreen2"
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="completeResultToolTip" toggle={() => this.setState(state => ({ tooltipOpen: !state.tooltipOpen }))}>
                                    {
                                        this.state.language == "br" &&
                                        <span>Oops, você só poderá saber a resposta correta depois de verificar suas próprias respostas na primeira e segunda tentativa.</span>
                                    }

                                    {
                                        this.state.language == "en" &&
                                        <span>Oops, you'll only be able to know the
                                    correct answers once you've checked your own
                                    answers on the 1st and 2nd attempts.</span>
                                    }
                            </Tooltip>
                        </>
                    }
                </div>}
                <ExerciseSubStatementStyle className="w-100 pl-5 pr-5">
                    {
                        exercise.conteudo_exercicio
                        && exercise.conteudo_exercicio !== ""
                        &&  <Col className="exercise-dialogue-content text-center w-100 pb-5" md="12" lg="12">
                                <div className="exercise-dialogue-enunciado">
                                    <span dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.conteudo_exercicio))}} />
                                </div>
                            </Col>
                    }
                    <AudioPlayerColStyle className="" xs="12" md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }}>
                        <AudioPlayer
                            src={getStaticUrl(midiaYConnect.url)}
                        />
                    </AudioPlayerColStyle>
                </ExerciseSubStatementStyle>


                <OptionsRowStyle className="d-flex flex-row flex-wrap text-center justify-content-center mt-5">
                    {exercise.respostas_Exercicio_Yconnect.map((item:any, key:any) => (
                        <Div2Style
                            key={key}
                            id={item.id_resposta}
                            className={getClass(item.ordem)}
                            onClick={() => {handleChange(item.ordem)}}>
                            <img src={getStaticUrl(item.resposta)} />
                        </Div2Style>
                    ))}
                </OptionsRowStyle>
            </>
        )
	}
}

export default ClickAudioField

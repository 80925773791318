import * as React from "react"

import { ButtonStyle } from "YConnect/Components/Button"
import Wrapper from "YConnect/Modals/OpenQuestion.modal/OpenQuestionWrapper.style"

import OpenQuestionReviewType from "YConnect/Enums/OpenQuestionReviewType"

const OpenQuestionModalActions = ({attempts, isStudent, newReviewDisplay, latestReviewTypeAndAttempt, onApprove, onReject}:any) => {
  const isApproved = () => latestReviewTypeAndAttempt.type === OpenQuestionReviewType.APPROVAL
  const isAwaitingForSecondAnswer = () => attempts === 1 && latestReviewTypeAndAttempt.type === OpenQuestionReviewType.REJECTION && latestReviewTypeAndAttempt.attempt === 1
  const exceededAttempts = () => latestReviewTypeAndAttempt.attempt === 2

  if (isStudent || newReviewDisplay) {
    return null
  }

  if (latestReviewTypeAndAttempt) {
    let endMessage;

    if (isApproved()) {
      endMessage = 'Answer has been approved'
    } else if (isAwaitingForSecondAnswer()) {
      endMessage = "Awaiting for the student's second chance"
    } else if (exceededAttempts()) {
      endMessage = 'The student has exceeded the maximum numbers of attempts'
    }
    
    if (endMessage) {
      return (<>{endMessage}. No more actions are required for a while.</>)
    }
  }

  return (
    <Wrapper className="open-question__review-actions">
      <ButtonStyle
        size="small"
        label="Disapprove"
        onClick={onReject}
        icon="cancelGreen"
      />
      <ButtonStyle
        green
        size="small"
        label="Approve"
        onClick={onApprove}
        icon="checked"
      />
    </Wrapper>
  )
}

export default OpenQuestionModalActions
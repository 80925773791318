import * as React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

type ChooseMultipleViewProps = {
    Answers:Array<AnswerType>
}

const SpanStyled = styled.span`
    
    b, strong {
        font-weight:normal!important;
        font-family:Panton-Bold;
    }
`

const ButtonStyled = styled.button`
    border: 1px solid #79b100;
    border-radius: 50px;
    background: #fff;
    padding: 5px 15px 5px 15px;

    &:hover {
        background: aliceblue;
        color: #79b100;
        font-weight: bold;
    }
`

const ButtonActiveStyled = styled.button`
    border: 1px solid #79b100;
    border-radius: 50px;
    background: aliceblue;
    padding: 5px 15px 5px 15px;
    color: #79b100;
    font-weight: bold;
`

const ChooseMultipleView = ({Answers}:ChooseMultipleViewProps) => 
<Col className="p-3">
    {
        Answers
        .map(({Awnser, IsCorret}, key)=>
        <Row className="justify-content-center mb-2" key={key}>
            <Col md="auto">
                
                {
                    IsCorret
                    ? <ButtonActiveStyled><SpanStyled dangerouslySetInnerHTML={{ __html: Awnser}} /></ButtonActiveStyled>
                    : <ButtonStyled><SpanStyled dangerouslySetInnerHTML={{ __html: Awnser}} /></ButtonStyled>
                }
            </Col>
        </Row>)
    }
</Col>
    

export default ChooseMultipleView
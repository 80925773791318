import styled       from "styled-components"
import { Dropdown } from "react-bootstrap"

const DropdownUserProfileStyle = styled(Dropdown)`
    align-items: center;
    display: flex;
    height: 100%;

    &.show > .btn-primary.dropdown-toggle {
        background-color: ${props => props.theme.main.lightPurple};
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0);
        border-bottom: solid 4px ${props => props.theme.main.lightGreen} !important;
    }

    &.show > .btn-primary.dropdown-toggle:active {
        border-bottom: solid 4px transparent;
        background-color: ${props => props.theme.main.lightPurple};
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0);
    }
`

export default DropdownUserProfileStyle
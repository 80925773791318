import * as React from "react"
import {
    useState,
    useEffect,
    useContext,
    Fragment
} from "react"
import { Tab } from "react-bootstrap"
import {
    Container, Card,
    Row, Col, Badge
} from "reactstrap"
import styled from "styled-components"
import qs from "querystring"

import TabStyle from "YConnect/Styles/Tab.style"
import Loader from "YConnect/Components/Loader"
import {
    BreadcrumbViewReportResourceBook,
    removeBreadcrumbData,
    addBreadcrumbData
} from "YConnect/Components/BreadCrumbList.tsx"
import API from "YConnect/API"
import UserContext from "YConnect/User.context"

import ScoreUnitStudent from "YConnect/PagesComponents/ViewReportTeacher/ScoreUnitStudent"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

const BadgeStyle = styled(Badge)`
    background-color: #FF1A4A;
`

const idCursoStarter = 67

const ViewReportResourceBookPage = ({ match, location }: any) => {

    const [messagePageTitle, setMessagePageTitle] = useState('')
    const [hasLoading, setHasLoading] = useState(true)
    const [grupos, setGrupos] = useState()
    const [turma, setTurma] = useState()
    const [currentSearch, setCurrentSearch] = useState()
    const [totalSendingErrors, setTotalSendingErrors] = useState()

    const { idUsuario }: any = useContext(UserContext)
    const { idEstab, idCurso, idGrupo } = match.params
    const { current } = qs.parse(location.search.substr(1))


    const cursoStarter: boolean = parseInt(idCurso, 10) === idCursoStarter
    const fl_vigentes = current;

    addBreadcrumbData([{ page: 'ViewReportResourceBookPage', data: { idUsuario, idEstab, idCurso, idGrupo, currentSearch } }])
    removeBreadcrumbData([{ page: 'ViewReportTeacherPage' }])

    useEffect(() => {
        API.Grupo.Get({ idEstab, idCurso, fl_vigentes }, getConfig())
            .then((response: any) => setGrupos(response.data))
    }, [])

    useEffect(() => {
        if (grupos && grupos.turmas.length > 0) {
            setHasLoading(false)
            //setTurma(grupos.turmas.find(({id_grupo}:any)=> id_grupo == idGrupo))

            const turma = grupos.turmas.find(({ id_grupo }: any) => id_grupo == idGrupo)
            turma.alunos = turma.alunos.map((aluno: any) => ({ ...aluno, porcent: parseInt(1 + (100 - 1) * Math.random(), 10) }))

            setTurma(turma)

            refreshCurrentSearch()
        }
    }, [grupos])

    useEffect(() => {
        setMessagePageTitle(`${current === "true" && !cursoStarter ? "Students score per unit" : "Class Score per Unit"}`);
    }, [current])

    const refreshCurrentSearch = () => {
        const lastSearches = JSON.parse(localStorage.getItem("lastSearchesRB"))
        if (lastSearches && lastSearches[idUsuario]) {
            const currentSearch = lastSearches[idUsuario].find(({ classParam }: any) => classParam == idGrupo)
            localStorage.setItem("reportAluno", JSON.stringify(currentSearch))
            setCurrentSearch(currentSearch)
            //setMessagePageTitle(currentSearch.class)
        }
    }
    const defaultTab = () => {

        return 'class-score-unit'
    }

    const renderGradeSendingTabName = () =>
        <Fragment>
            Grade Sending
        {
                (totalSendingErrors && totalSendingErrors > 0)
                    ? <BadgeStyle className="ml-2" pill>{
                        totalSendingErrors === 1
                            ? `${totalSendingErrors} error`
                            : `${totalSendingErrors} errors`
                    }</BadgeStyle>
                    : ""
            }
        </Fragment>

    return !hasLoading
        ? <Container>
            {currentSearch &&
                <BreadcrumbViewReportResourceBook
                    idEstab={idEstab}
                    idCurso={idCurso}
                    idGrupo={idGrupo}
                    currentSearch={currentSearch}
                />}
            <Row className="mb-5 mr-0">
                <Col md={6} xs={12}>
                    <h2>Reports: {messagePageTitle}</h2>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col md={12}>
                    <Card className="shadow p-0">
                        <TabStyle defaultActiveKey={defaultTab()}
                            onSelect={(eventKey: string) => { }}>
                            {
                                /*somente cursos mutinivel
                                //multinivel &&
                                 <Tab eventKey="learning-track" title="Learning Track" className="px-4" onEntered={() => setMessagePageTitle(currentSearch.class)}>
                                        {
                                            turma
                                            && <LearningTrack
                                                    onChangeSendErrors = {setTotalSendingErrors}
                                                    idEstab            = {idEstab}
                                                    idCurso            = {idCurso}
                                                    idGrupo            = {idGrupo}
                                                    turma              = {turma}/>
                                        }
                                    </Tab>
                                    */
                            }

                            <Tab eventKey="class-score-unit" title={cursoStarter ? "Students Score" : "Students score per unit"} onEntered={() => setMessagePageTitle('Students score per unit')} className="px-4">
                                <ScoreUnitStudent idCurso={idCurso} idGrupo={idGrupo} resourceBook />
                            </Tab>
                        </TabStyle>
                    </Card>
                </Col>
            </Row>
        </Container>
        : <section className="row">
            <div className="col-12 ">
                <div className="card p-0 shadow d-flex flex-row flex-wrap justify-content-around profile">
                    <Loader />
                </div>
            </div>
        </section>
}

export default ViewReportResourceBookPage;

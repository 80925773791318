import * as React                  from "react"
import {
    useEffect, useState, useContext
}                                  from "react"
import {Container, Row, Col, Card} from "reactstrap"
import styled                      from "styled-components"

import { BreadcrumbSettings } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle        from "YConnect/Components/PageTitle"
import UserContext      from "YConnect/User.context"
import VirtualClassesCard    from "YConnect/Components/VirtualClassesCard"

import * as manageUser      from "YConnect/Assets/svg/manage-user-icon.svg"
import * as explore         from "YConnect/Assets/svg/yazigi-explore-icon.svg"
import * as upload          from "YConnect/Assets/svg/upload-file-icon.svg"

import * as tasksIcon       from  "YConnect/Assets/png/tasks-256x256.png"

const ReportCardStyle = styled.div`
    align-items: center;
    border-bottom: 2px solid ${props => props.theme.main.clickable};
    color: ${props => props.theme.main.textColor};
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }

    .report-type {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin-left: 20px;

        h4, p {
            margin: 0;
        }
    }

`

const SettingsPage = () => {

    const { profiles }:any = useContext(UserContext)

    return <Container>
        <BreadcrumbSettings/>
        <PageTitle label="Settings" />
        <Row>
            {
                !(profiles.indexOf(11) > -1) &&
            <Col xs={12} md={6}>
                <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/manage-users"}>
                    <figure>
                        <img src={manageUser} alt="" />
                    </figure>
                    <div className="report-type">
                        <h4>Manage Users</h4>
                        <p>Manage users from YConnect.</p>
                    </div>
                </ReportCardStyle>
            </Col>
            }

            <Col xs={12} md={6}>
                <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/virtual-classes"}>
                    <figure>
                        <img src={tasksIcon} alt="" />
                    </figure>
                    <div className="report-type">
                        <h4>Virtual Classroom</h4>
                        <p>Manage Virtual Classrooms.</p>
                    </div>
                </ReportCardStyle>
            </Col>
            {
                profiles.indexOf(6) > -1
                || profiles.indexOf(36) > -1
                ? <>
                    <Col xs={12} md={6}>
                        <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/explore"}>
                            <figure>
                                <img src={explore} alt="" />
                            </figure>
                            <div className="report-type">
                                <h4>Yázigi Explore</h4>
                                <p>Manage schools from Yázigi Explore</p>
                            </div>
                        </ReportCardStyle>
                    </Col>

                    <Col xs={12} md={6}>
                        <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/upload-card"}>
                            <figure>
                                <img src={upload} alt="" />
                            </figure>
                            <div className="report-type">
                                <h4>Yázigi Card Upload</h4>
                                <p>Manage cards from Yázigi Explore.</p>
                            </div>
                        </ReportCardStyle>
                    </Col>
                </>
                : null
            }
        </Row>

    </Container>
}


export default SettingsPage

import * as React from "react";
import AudioPlayer from "YConnect/Components/AudioPlayer";
import ImgStyle from "YConnect/Fields/Exercises/DragDropTxtImg.field/Img.style";
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util";

export interface MediaPreviewProps {
    media: string;
    className?: string;
    imgStyle?: any;
    toggleImage?: any;
}

const MediaPlayer: React.FunctionComponent<MediaPreviewProps> = ({
    media,
    className,
    imgStyle,
    toggleImage,
}) => {

    const resultRegex = media.match(/image|avatar|audio|video|document|img\/atividades/);

    const type = resultRegex ? resultRegex[0] : "text";

    return (
        <>
            {(type === "image" || type === "avatar" || type === "cover" || type ==="img/atividades") && (
                <ImgStyle
                    onClick={toggleImage}
                    src={getStaticUrl(media)}
                    alt=""
                />
            )}
            {type === "audio" && <AudioPlayer src={getStaticUrl(media)} className={className} />}
            {type === "video" && (
                <video  width="90%"className={className} controls>
                    <source src={getStaticUrl(media)} />
                </video>
            )}
            {type === "text" && (
                <span dangerouslySetInnerHTML={{ __html: media }} />
            )}
        </>
    );
};

export default MediaPlayer;

import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

import ImageMainView from "YConnect/PagesComponents/CMS/Views/ImageMain.view"
import { ButtonStyle } from "YConnect/Components/Button"
import ImageFilesModal from "YConnect/PagesComponents/CMS/Modals/ImageFiles.modal"

import FormContainer from "YConnect/Containers/Form.container"
import AnswersChooseMultipleTable from "YConnect/PagesComponents/CMS/Editors/ChooseMultiple.editor/AnswersChooseMultipleTable"
import IMAGE_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Image.template.json"

type ChooseMultipleWithImageTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const ChooseMultipleWithImageTemplateEditor = ({
    question,
    onChangeValues
}:ChooseMultipleWithImageTemplateEditorProps)=>{

    const [values, setValues]   = useState(question)    
    const [Answers, setAnswers] = useState<Array<AnswerType>>(question.Answers)
    const [isShowImageFilesModal, setShowImageFilesModal] = useState(false)

    const handleChangeValues = (values:any) => addValues(values)
    const handleChangeAnswer = (values:Array<AnswerType>) => setAnswers(values)

    const addValues = (newValues:any) => {
        
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }

    useEffect(() => {
        setValues({...values, Answers})
        onChangeValues({...values, Answers})
    }, [Answers])

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])

    const getMainExerciseMedia = () =>
        values && values.ExerciseMedias.find(({Order}) => Order === 0)

    const addMainExerciseMedia = ({Source, MediaType}:any) => {
        const newValues = {
            ...values,
            ExerciseMedias: [{Order:0, Source, MediaType}, ...values.ExerciseMedias.filter(({Order}) => Order !== 0)]
        }
        setValues(newValues)
        onChangeValues(newValues)
    }

    const handleOpenModalImageFiles = () => setShowImageFilesModal(true)
    const handlAddImage = ({Source, MediaType}:any) => {
        addMainExerciseMedia({Source, MediaType})
        setShowImageFilesModal(false)
    }
    const handleCloseModalImageFiles = () => setShowImageFilesModal(false)

    const handleRemoveExerciseMedia = () => {
        const newValues = {
            ...values,
            ExerciseMedias: values.ExerciseMedias
            .filter(({Order}) => Order !== 0 )
        }
        setValues(newValues)
    }

    return  <>
                <ImageMainView medias={values && values.ExerciseMedias}/>
                {
                    !(getMainExerciseMedia()
                    && (getMainExerciseMedia().MediaType.toLowerCase() === "audio" || getMainExerciseMedia().MediaType.toLowerCase() === "image"))
                    && <Row className="py-3">
                            <Col md="auto">
                                <ButtonStyle
                                    green
                                    disabled  = {values && values.ExerciseMedias && values.ExerciseMedias[0] && values.ExerciseMedias[0].MediaType === "audio"}
                                    size      = "190"
                                    onClick   = {handleOpenModalImageFiles}
                                    icon      = "imageWhite"
                                    label     = "Add Image"/>
                            </Col>
                        </Row>
                }
                {
                    getMainExerciseMedia()
                    && <Row className="py-3 justify-content-center">
                            <Col md="auto">
                                <ButtonStyle
                                    className = "mb-2"
                                    size      = "130"
                                    onClick   = {handleOpenModalImageFiles}
                                    icon      = "reset"
                                    label     = "Change"/>
                            </Col>
                            <Col md="auto">
                                <ButtonStyle
                                    size      = "130"
                                    onClick   = {handleRemoveExerciseMedia}
                                    icon      = "remove"
                                    label     = "Remove"/>
                            </Col>
                        </Row>
                }
            <Row>
                <Col md={12}>
                    <FormContainer 
                        values   = {values}
                        onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                        template = {[...IMAGE_TEMPLATE.map((field) => ({...field}))]}/>
                </Col>
                <Col md={12} className="mt-3">
                    <h3 className="font-weight-bold">Answers{Answers.length > 0 && ` (${Answers.length})`}</h3>
                    <AnswersChooseMultipleTable
                        nextQuestionOrder = {question.nextQuestionOrder}
                        values            = {Answers}
                        onChange          = {(values:Array<AnswerType>) => handleChangeAnswer(values)}/>
                </Col>
            </Row>
                {
                    isShowImageFilesModal
                    && <ImageFilesModal onSelect={handlAddImage} 
                    onClose={handleCloseModalImageFiles}/>
                }
            </>
}
          
export default ChooseMultipleWithImageTemplateEditor
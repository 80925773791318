import { useEffect, useReducer } from "react"

import API              from "YConnect/API"
import getRequestConfig from "YConnect/Utils/GetRequestConfig.util"

type ReportAction = {
    name     : string
    report  ?: any 
}


const useReport = ({
    semesterIdSelected,
    courseIdSelected,
    establishmentIdSelected,
    teacherIdSelected,
    classIdSelected,
    idUsuario
}: any) => {
    
    const [{
        averageScore,
        bestClassScore,
        rankedClasses,
        bestRankedStudents
    }, setReport] = useReducer((state:any, {name, report}:ReportAction) => {
        return  {...state, [name]:report}
    }, {})

    useEffect(()=>{

        if(semesterIdSelected && semesterIdSelected !== ""){

            setReport({name:"bestRankedStudents", report: undefined})
            setReport({name:"bestClassScore", report: undefined})
            setReport({name:"rankedClasses", report: undefined})
            setReport({name:"averageScore", report: undefined})

            API
            .PrePostTest
            .GetBestRankedStudents({
                idAnoSemestrebrs     : semesterIdSelected,
                idEstabelecimentobrs : establishmentIdSelected,
                idCursobrs           : courseIdSelected,
                idClassebrs          : classIdSelected,
                regionalbrs          : "",
                idProfessor          : teacherIdSelected
            }, getRequestConfig())
            .then((response: any) => 
                setReport({name:"bestRankedStudents", report: response.data.bestRankedStudents}))

            API
            .PrePostTest
            .GetBestClassProfessor({
                idAnoSemestrebcp     : semesterIdSelected,
                idEstabelecimentobcp : establishmentIdSelected,
                idCursobcp           : courseIdSelected,
                idClassebcp          : classIdSelected,
                regionalbcp          : ""
            }, getRequestConfig())
            .then((response: any) => setReport({name:"bestClassScore", report: response.data.bestClassProfessorPrePost.filter((item:any) => item)}))
                

            API
            .PrePostTest
            .GetBestRankedClass({
                idAnoSemestrerc     : semesterIdSelected,
                idEstabelecimentorc : establishmentIdSelected,
                idCursorc           : courseIdSelected,
                idClasserc          : classIdSelected,
                regionalrc          : "",
                idProfessorrc       : teacherIdSelected,
                }, getRequestConfig())
                .then((response: any) => 
                    setReport({name:"rankedClasses", report: response.data.classes}))


                API
                .PrePostTest
                .GetSchoolAverageScore({
                        idAnoSemestreSas     : semesterIdSelected,
                        idOrientadorSas      : idUsuario,
                        regionalSas          : "",
                        idEstabelecimentoSas : establishmentIdSelected
                }, getRequestConfig())
                    .then((response: any) => 
                    setReport({name:"averageScore", report: response.data.averageScores.filter((item:any) => item)}))
        }
        
    }, [ 
        semesterIdSelected, 
        courseIdSelected, 
        teacherIdSelected, 
        classIdSelected
    ])

    return {
        averageScore,
        bestClassScore,
        rankedClasses,
        bestRankedStudents
    }
}

export default useReport
export default [
    {
        keystone: "QuestionOrder",
        className: "col-md-3",
        type: "input",
        label: "Order"
    },
    {
        keystone: "TemplateId",
        className: "col-md-5",
        type: "Select",
        label: "Template"
    },
    {
        keystone: "ExerciseId",
        className: "col-md-4",
        type: "Select",
        label: "Exercise"
    },
    {
        keystone: "Enunciated",
        className: "col-md-12",
        type: "TextArea",
        label: "Title"
    },
    {
        keystone: "Content",
        className: "col-md-12",
        type: "TextArea",
        label: "Content"
    },
    {
        keystone: "Answers",
        type: "hidden",
        value:[

           /* {
                Awnser:"Resposta 1",
                IsCorret:true
            },
            {
                Awnser:"Resposta 2",
                IsCorret:true
            },
            {
                Awnser:"Resposta 3",
                IsCorret:true
            }*/
        ]
    },
    {
        keystone: "Dialogues",
        type: "hidden",
        value:[
           /* {
                DialogueMessages: [
                    {
                        OrderMessage: 1,
                        Text: "Texto 1 ___ "
                    },
                    {
                        OrderMessage: 2,
                        Text: "Texto ___ 2 ___ "
                    }
                ],
                DialogueAvatars:[]
            }*/
        ]
    },
    {
        keystone: "ExerciseMedias",
        type: "hidden",
        value:[]
    }

]

import * as React from "react"
import { useEffect, useState } from "react"
import {
    Row,
    Col
} from "reactstrap"
import styled from "styled-components"
import Table from "YConnect/Components/Table"

import { ButtonStyle } from "YConnect/Components/Button"
import useEditableTableState from "YConnect/PagesComponents/CMS/Hooks/useEditableTableState"
import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"
import ImageFilesModal from "YConnect/PagesComponents/CMS/Modals/ImageFiles.modal"

const ImageStyled = styled.img`
    width: 100%;
    height: auto;
`

const TableStyled = styled(Table)`
    .table{
        min-height: auto;
    }
    td, th {
        padding:10px;
    }
`

const INITIAL_DATA_STATE = [
    {
        type: "NEW",
        row: {}
    }
]

const GetStateimagesByOrder = (imagesByOrder: any) =>
    imagesByOrder
        && Object.keys(imagesByOrder).length > 0
        ? [
            ...Object.keys(imagesByOrder).map(order => (
                {
                    type: "ADDED",
                    row: {
                        ...imagesByOrder[order],
                        IsCorrect: imagesByOrder[order].answer.IsCorret
                    }
                })),
            INITIAL_DATA_STATE[0]
        ]
        : INITIAL_DATA_STATE

type ClickAudioWithImageEditableTableProps = {
    imagesByOrder: any
    onChange: any
}

const ClickAudioWithImageEditableTable = ({
    imagesByOrder,
    onChange
}: ClickAudioWithImageEditableTableProps) => {

    const [isShowImageFilesModal, setShowImageFilesModal] = useState(false)
    const [imageIndexForUpdate, setImageIndexForUpdate] = useState<number>()

    const {
        dataState,
        updateLine,
        removeLine,
    } = useEditableTableState(GetStateimagesByOrder(imagesByOrder))

    const handleUpdateRow = (key: number, property: string, value: any) => {
        if (property === "IsCorrect") {
            const nIsCorrect = dataState.filter(({ row: { IsCorrect } }) => IsCorrect).length
            if (nIsCorrect < 1 || !value) {
                const values = updateLine(key, property, value)
                onChange(values)
            }
        } else {
            const values = updateLine(key, property, value)
            onChange(values)
        }
    }

    const handleRemoveRow = (key: number) => {
        const values = removeLine(key)
        onChange(values)
    }

    const handleShowModalImage = (key: any) => {
        setShowImageFilesModal(true)
        setImageIndexForUpdate(key)
    }

    const handleCloseModalImage = (key: any) => {
        setShowImageFilesModal(false)
        setImageIndexForUpdate(undefined)
    }

    const handlSelectImage = ({ Source, MediaType }: { Source: string, MediaType: string }) => {

        handleUpdateRow(imageIndexForUpdate, "answer", {
            ...(
                imagesByOrder[imageIndexForUpdate]
                    && imagesByOrder[imageIndexForUpdate].answer
                    ? imagesByOrder[imageIndexForUpdate].answer
                    : {}
            ),
            Awnser: Source,
            IsCorrect: false,
        })

        setImageIndexForUpdate(undefined)
        setShowImageFilesModal(false)
    }

    const config: Array<ColumnConfigType> = [
        {
            label: "Image",
            formatter: (state: any, key: number) =>
                state.row["answer"]
                    ? <Row>
                        <Col md={4}>
                            <ImageStyled
                                className="img-fluid"
                                style={{ width: "220px" }}
                                src={getStaticUrlAssests(state.row["answer"]["Awnser"])} />
                        </Col>
                        <Col md="auto" className="align-self-center">
                            <ButtonStyle
                                className="mt-3"
                                size="small"
                                onClick={() => handleShowModalImage(key)}
                                icon="reset"
                                label="Change" />
                        </Col>
                    </Row>
                    : <ButtonStyle
                        green
                        size="190"
                        onClick={() => handleShowModalImage(key)}
                        icon="imageWhite"
                        label="Add Image" />
        },
        {
            label: "Is Correct",
            formatter: (state: any, key: number) =>
                <input
                    type="checkbox"
                    onChange={({ target: { checked } }) => handleUpdateRow(key, "IsCorrect", checked)}
                    checked={!!state.row["IsCorrect"]} />
        },
        {
            formatter: (state: any, key: any) =>
                <>
                    {
                        state.type === "ADDED"
                        && <Col> <Row className="px-0">
                            <ButtonStyle
                                size="forTable"
                                className="mr-2"
                                icon="remove"
                                onClick={() => handleRemoveRow(key)} />
                        </Row></Col>
                    }
                </>
        }
    ]

    return <>
        <TableStyled
            data={dataState}
            config={config} />
        {
            isShowImageFilesModal
            && <ImageFilesModal onSelect={handlSelectImage}
                onClose={handleCloseModalImage} />
        }
    </>
}

export default ClickAudioWithImageEditableTable
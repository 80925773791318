import * as React           from "react"
import { useEffect, useRef, useContext, useState } from "react";
import { Modal, FormGroup } from "react-bootstrap"
import { ButtonStyle }      from "YConnect/Components/Button"

const CalculateFinalScoreModal = ({
    hasShow,
    onConfirm,
    onClose,
    data,
    onUpdate,
    dataModal,
    flag
}:any) => {
    const [oralTestScore, setOralTestScore] = useState()
    const [writtenTestScore, setWrittenTestScore] = useState(0)

    useEffect(() => {
        if (oralTestScore > 100) {
            setOralTestScore(100)
        }
        if (oralTestScore < 0) {
            setOralTestScore(0)
        }
    }, [oralTestScore])

        const updateScore = (() => {
            onUpdate(oralTestScore, dataModal.id_prova_final)
            onClose()
            flag(true)
        })

        return (
        <Modal show={hasShow} onHide={onClose} size="lg" centered>
            <Modal.Header closeLabel="Close" closeButton>
                <h2>Calculate Score</h2>
            </Modal.Header>
                <Modal.Body>
                    <div style={{display: 'flex'}}>
                        <span className="w-20">Oral Test Pontuation:</span>
                    </div>
                    <input
                        className="w-20"
                        value={oralTestScore}
                        onChange={(e) => setOralTestScore(e.target.value)}
                        type="number"
                        min="0"
                        max="100"
                        required
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex flex-row justify-content-end">
                        <ButtonStyle
                            type      = "button"
                            label     = "Cancelar"
                            size      = "small"
                            className = "mr-2"
                            onClick   = {onClose} />
                        <ButtonStyle
                            green
                            label    = "Update"
                            size     = "small"
                            onClick  = {updateScore}
                        />
                    </div>
                </Modal.Footer>
        </Modal>
        )
        }

export default CalculateFinalScoreModal

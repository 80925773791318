import * as React from "react";
import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import UserContext from "YConnect/User.context";
import AttemptsIconStyle from "YConnect/Styles/AttemptsIcon.style";
import { Tooltip } from "reactstrap";

import * as pencil from "YConnect/Assets/svg/pencil.svg";
import * as checked from "YConnect/Assets/png/Icon awesome-check.png";
import * as bubble from "YConnect/Assets/png/Icon metro-bubble-1.png";
import * as blocked from "YConnect/Assets/png/Group 105@2x.png";

const StyledCard = styled.div`
    &.card-blocked {
        background-color:  ${(props) => props.theme.main.blocked}; !important;
        cursor: not-allowed h3, span {
            color: ${(props) => props.theme.main.textBlocked};
        }
        span {
            font-size: 14px;
        }
    }

    header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 8px 24px;

        h3 {
            &.to-do {
                color: ${(props) => props.theme.main.lightPurpleInvert};
            }

            &.done {
                color: ${(props) => props.theme.main.greentextColor};
            }
        }
        span.header-icon {
            position: relative;
            right: -24px;

            &.done {
                background-color: ${(props) =>
        props.theme.main.greentextColor} !important;
            }
            &.presential {
                background-color: ${(props) =>
        props.theme.main.textColor} !important;
            }

            &.blocked {
                background-color: ${(props) =>
        props.theme.main.textWhite} !important;
            }

            img {
                &.blocked {
                    //filter: invert(1) !important;

                    filter: ${props => props.theme.main.blockedSvg} !important;;
                }
            }
            @media screen and (max-width: 768px) {
                position: absolute;
                right: -15px;
            }
        }
    }

    .task-card-header{
        @media screen and (max-width: 768px) {
            padding: 0;
            h3{
                font-size: 1.5rem;
                 padding: 10px
            }
        }
    }

    .card-body {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-top: 0;
        align-items: flex-end;
        div {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            span {
                margin: 4px 0;
            }
        }
        button {
            align-self: flex-end;
            height: 30px;
            width: 85px !important;
            padding: unset;
            border: none;
            &.to-do, 
            &.done {
                background-color: ${(props) => props.theme.main.lightPurpleInvert};
                color: ${(props) => props.theme.main.textInvert};
            }
            &.presential {
                background-color: ${(props) => props.theme.main.textColor};
                width: 145px !important;
                color: ${(props) => props.theme.main.textInvert};
            }
            &.high{
                &:active {
                    background-color: white !important;
                    color: black !important;
                    border: none !important;
                }

                &:focus {
                    box-shadow: none !important;
                }
            }

        }

        @media screen and (max-width: 768px) {
            padding: 0.5rem !important;
            padding-top: 0 !important;
        }
    }
    .title-task {
        font-size: 25px;
        padding-top: 5px;
    }
`;

const Task = ({ task, unit }: any) => {

    const [contrast, setContrast] = useState("");
    const {
        idProfessor,
        observador,
        colaborador,
        usuarioTrial,
        usuarioDegustacao,
        idCursoAtual,
    } = useContext(UserContext);

    const [tooltipOpen, setTooltip] = useState(false);

    const isStudent = () => {
        return (
            idProfessor == 0 &&
            !observador &&
            !colaborador &&
            !usuarioTrial &&
            !usuarioDegustacao
        );
    };

    const taskStatus = (task: any) => {
        if (task.objetivo_linguistico.toUpperCase() === "INTERACTION_CLASS") {
            return "presential";
        }
        return !isStudent()
            ? "to-do"
            : task.bloqueado
                ? "blocked"
                : task.porcentagem_secao == 100
                    ? "done"
                    : "to-do";
    };

    const textoBotao = (task: any) => {
        if (task.objetivo_linguistico.toUpperCase() === "INTERACTION_CLASS") {
            return "What to do?";
        } else if (!isStudent()) {
            return "Enter";
        } else if (task.porcentagem_secao == 0) {
            return "Start";
        } else if (task.porcentagem_secao > 0 && task.porcentagem_secao < 100) {
            return "Continue";
        } else if (task.porcentagem_secao == 100) {
            return "Review";
        }
    };

    useEffect(() => {
        setContrast(localStorage.getItem("contrast"))
    }, []);

    const StudentCard = () => {

        return (
            <>
                <div>
                    <span>{task.objetivo_secao}</span>
                    <span className="font-weight-bold mr-2">
                        {task.objetivo_linguistico.toUpperCase() ===
                            "INTERACTION_CLASS"
                            ? ""
                            : `${task.porcentagem_secao}% `}
                    </span>
                </div>
                <button
                    className={`btn btn-success ${contrast} font-weight-bold ${taskStatus(
                        task
                    )}`}
                >
                    {textoBotao(task)}
                </button>
            </>
        );
    };

    const TeacherCard = () => {
        return (
            <>
                {task.objetivo_linguistico.toUpperCase() !==
                    "INTERACTION_CLASS" ? (
                        <>
                            <button
                                className={`btn btn-success ${contrast} font-weight-bold ${taskStatus(
                                    task
                                )}`}
                            >
                                {textoBotao(task)}
                            </button>
                            <>
                                {/* <AttemptsIconStyle
                                className="info"
                                id={`tooltip-${task.id_secao}`}
                            >
                                ?
                            </AttemptsIconStyle>
                            <Tooltip
                                placement="right"
                                isOpen={tooltipOpen}
                                target={`tooltip-${task.id_secao}`}
                                toggle={() => setTooltip(!tooltipOpen)}
                            >
                                Lorem ipsum is placeholder text commonly used in
                                the graphic, print, and publishing industries
                                for previewing layouts and visual mockups.
                            </Tooltip> */}
                            </>
                        </>
                    ) : (
                        <StudentCard />
                    )}
            </>
        );
    };

    return (
        <StyledCard
            className={`task-card card shadow ${
                isStudent() && task.bloqueado ? "card-blocked" : ""
                }`}
        >
            <header className="task-card-header">
                <h3
                    className={`font-weight-bold title-task ${taskStatus(
                        task
                    )}`}
                >
                    {task.nome}
                </h3>
                <span className={`header-icon ${taskStatus(task)}`}>
                    <img
                        src={
                            isStudent()
                                ? task.bloqueado
                                    ? blocked
                                    : task.objetivo_linguistico.toUpperCase() ===
                                        "INTERACTION_CLASS"
                                        ? bubble
                                        : task.porcentagem_secao == 100
                                            ? checked
                                            : pencil
                                : task.objetivo_linguistico.toUpperCase() ===
                                    "INTERACTION_CLASS"
                                    ? bubble
                                    : pencil
                        }
                        alt=""
                        className={`task-card-img ${taskStatus(task)}`}
                    />
                </span>
            </header>
            <div className="card-body">
                {isStudent() ? (
                    !task.bloqueado ? (
                        <StudentCard />
                    ) : (
                            <span className="font-weight-bold text-blocked">
                                {" "}
                            To unlock this class, you will need to complete the
                            first 5 classes.{" "}
                            </span>
                        )
                ) : !isStudent() ? (
                    <TeacherCard />
                ) : (
                            ""
                        )}
            </div>
        </StyledCard>
    );
};

export default Task;
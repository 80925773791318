import styled from "styled-components"

const Wrapper = styled.div`
  margin: 1em auto;
  width: 85%;

  .open-question__title {
    text-align: center;
  }

  textarea {
    width: 100%;
    padding: 1em;
  }
  
  .open-question__status-card {
    text-align: center;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    img {
      vertical-align: top;
      height: 1.2em;
      width: 1.2em;
    }

    .open-question__status-card__review-button {
      margin-top: 1em;
    }
  }

  .open-question__modal {
    .open-question__modal__reviews {
      .open-question__modal__reviews__title {
        img {
          margin-left: 1em;
          vertical-align: middle;
        }
        
        strong {
          vertical-align: middle;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    width: 80%;
  }
`

export default Wrapper;
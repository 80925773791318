export default [
    {
        keystone: "Name",
        className: "col-md-12",
        type: "input",
        label: "Name"
    }
    // {
    //     keystone: "Order",
    //     className: "col-md-3",
    //     type: "input",
    //     label: "Order"
    // },
    // {
    //     keystone: "UnitType",
    //     className: "col-md-4",
    //     type: "Select",
    //     label: "Type"
    // },
    // {
    //     keystone: "CourseId",
    //     className: "col-md-4",
    //     type: "Select",
    //     label: "Course"
    // },
    
]
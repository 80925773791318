import * as React from "react"
import { useEffect, useState } from "react"
import { Col } from "reactstrap"

import AnswerEditableTable from "YConnect/PagesComponents/CMS/Editors/DragAndDrop.editor/Answer.editableTable"

type AnswerEditorProps = {
    order:number
    answers:Array<AnswerType>
    onChange:any
}

const AnswerEditor = ({
    order,
    answers,
    onChange
}:AnswerEditorProps) => {

    const [values, setValues] = useState<Array<AnswerType>>([])

    useEffect(() => {
        if(answers) {
            setValues(answers)
        }
    }, [answers])

    const handleChangeAnswer = (newAnswers:Array<AnswerType>) => {
        setValues(newAnswers)
        onChange(newAnswers)
    }

    return  <>
                <Col md={12} className="mt-3">
                    <h3 className="font-weight-bold">
                        Answers {values.length > 0 && ` (${values.length})`}
                    </h3>
                    <AnswerEditableTable
                            values={values}
                            onChange={(newAnswers:Array<AnswerType>) => handleChangeAnswer(newAnswers)}/>
                </Col>
            </>
}

export default AnswerEditor
import * as React from "react"
import {
    useState,
    useEffect,
    useContext
} from "react"
import { Link, withRouter } from "react-router-dom"

import * as plusIcon  from "YConnect/Assets/svg/plus.svg"
import * as user      from "YConnect/Assets/svg/user.svg"

import UserContext     from "YConnect/User.context"
import API             from "YConnect/API"
import { MessageLine } from "YConnect/Styles/MessageLine.style"
import formatDate      from "YConnect/Utils/FormatDate.util"
import convertPhoto    from "YConnect/Utils/ConvertPhoto.util"
import getConfig       from "YConnect/Utils/GetRequestConfig.util"

const renderMessageCounter = (isRead: string) => {
    return isRead !== 'S' ? <span className="message-notification align-items-end badge badge-pill badge-warning"> </span> : '';
}

const MessagesCard = (props: any) => {

    const { idUsuario }: any = useContext(UserContext)

    const [messages, setMessages] = useState([{}])
    const [isComponentLoading, setIsComponentLoading] = useState(false)

    useEffect(() => {
        setIsComponentLoading(true)
        API.Note.Get2({
            id: idUsuario,
            tipo: 'R',
            pageNumber: 1,
            qntdItens: 4,
            dashboard: false
        }, getConfig())
        .then((res: any) => {
                setMessages(res.data.listaMensagens.slice(0, 4))
            })
            .catch((err: any) => {})
            .finally(() => {
                setIsComponentLoading(false)
            })

    }, []);

    const redirectMessagePage = (message: any) => {
        props.history.push({
            pathname: `/my-message/${message.id_notes}`,
            state: {
                message
            }
        });
    }

    const renderByViewport = () => {
        if (!messages || !messages.length) {
            return <p>No messages</p>
        }
        return (
            window.innerWidth > 1024 ?
                messages.map(
                    (message: any, key) => {
                        return (
                            <MessageLine
                                key={key}
                                className={`message pointer align-items-center d-flex flex-row justify-content-around ${message.status_lido !== 'S' ? "newMessage" : ""}`}
                                onClick={() => redirectMessagePage(message)}
                            >
                                <header className="message-header align-items-center d-flex flex-row justify-content-start">
                                    <figure className="m-0">
                                        <img src={convertPhoto(message.foto ? atob(message.foto) : user)} className="message-photo" />
                                        {renderMessageCounter(message.status_lido)}
                                    </figure>
                                    <span className="message-author" style={{ marginLeft: message.isRead !== 'S' ? '20px' : '0' }}>
                                        <b>
                                            {message.nome_remetente}
                                        </b>
                                    </span>
                                </header>
                                <div className="message-body" dangerouslySetInnerHTML={{__html: message.anotacao}}>
                                </div>
                                <span className="message-time">
                                    {formatDate(message.data)}
                                </span>
                            </MessageLine>
                        )
                    }
                )
                :
                messages.map(
                    (message: any, key: any) => {
                        return (
                            <div key={key} onClick={() => redirectMessagePage(message)}>
                                <MessageLine className={`message ${message.status_lido !== 'S' ? "newMessage" : ""}`}>
                                    <header className="message-header">
                                        <figure className="m-0">
                                            <img src={convertPhoto(message.foto ? atob(message.foto) : user)} className="message-photo" />
                                            {renderMessageCounter(message.status_lido)}
                                        </figure>
                                    </header>
                                    <div className="message-mobile-body">
                                        <div className="mobile-body-header">
                                            <span className="message-author">
                                                {message.nome_remetente}
                                            </span>
                                            <span className="message-time">
                                                {formatDate(message.data)}
                                            </span>
                                        </div>
                                        <div className="mobile-body-message">
                                            {message.contentWithoutHtml && message.contentWithoutHtml.length > 100
                                                ? `${message.contentWithoutHtml.slice(0, 100)}...`
                                                : message.contentWithoutHtml
                                            }
                                        </div>

                                    </div>
                                </MessageLine>
                            </div>
                        )
                    }
                )

        )
    }
    return (
        <div className={`card shadow p-4 mr-3`}>
            <h5 className="font-weight-bold">
                Messages: Inbox
        </h5>
            {
                isComponentLoading ?
                    <p>Loading...</p>
                    :
                    <>
                        <div className="messages">
                            {renderByViewport()}
                        </div>
                        <Link to="/my-message" className="text-right text-clickable font-weight-bold mt-3 mb-0">
                            See all messages
                        <img src={plusIcon} className="ml-2" />
                        </Link>
                    </>
            }

        </div>
    )
}

export default withRouter(MessagesCard)

import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import Loader from "YConnect/Components/Loader";
import {
    BreadcrumbExerciseAnswerFinalTestStudent,
    BreadcrumbExerciseAnswerFinalTestTeacher
} from "YConnect/Components/BreadCrumbList";

import { BackLinkStyle } from "YConnect/Styles/BackLink.style";
import HeaderExerciseStyle from "YConnect/Styles/HeaderExercise.style";
import ExerciseTimelineStyle from "YConnect/Styles/ExerciseTimeline.style";
import FormStyle from "YConnect/Styles/Form.style";
import FooterStyle from "YConnect/Styles/Footer.style";
import HomeworkLabelStyle from "YConnect/Styles/HomeworkLabel.style";

import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util";
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util";

import { getTemplateType } from "YConnect/Enums/ExerciseTemplate";

import * as pencil from "YConnect/Assets/svg/pencil.svg";

import UserContext from "YConnect/User.context";
import Toastify from "toastify-js";

const FinalTestExerciseReportPage = ({
    match,
    location,
    onCheckTag,
}: any) => {

    const {
        params: { idCurso, idAluno, idNotaFinal, idExercicio }
    } = match;

    const [isLoading, setLoading] = useState<boolean>(true);
    const [contentExercise, setContentExercise] = useState<any>();
    const [reportFinal, setReportFinal] = useState<any>([]);
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [nextQuestionId, setNextQuestionId] = useState<number>(-1);
    const [formValues, setFormValues] = useState<any>();

    const userContext: any = useContext(UserContext);

    useEffect(() => {

        if(Object.keys(userContext).length > 0){
            if(reportViewAuth()){
                fetchContent();
                setReportFinal(JSON.parse(localStorage.getItem("reportFinalTest")));
            } else {
                Toastify({
                    text: "Permission denied to view final test report. Check your user permission or if your user already did the Final Test.",
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
                
                window.location.href = "#/";
            }
        } else {
            Toastify({
                text: "No User Was Found",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            
            window.location.href = "#/";
        }

    }, [userContext]);

    useEffect(() => {
        setLoading(true);
        setContentExercise(null);
        fetchContent();
        setupTestParams();
    }, [idExercicio])

    useEffect(() => {

        if(reportFinal && reportFinal.length > 0){
            setupTestParams();
        }

    }, [reportFinal]);

    const reportViewAuth = () => {
        let auth = false;

        //Verificando se quem está tentando acessar o relatório é professor ou orientador
        if(userContext.profiles.indexOf(15) > -1 || userContext.profiles.indexOf(12) > -1){
            auth = true;
        //Verificando se o aluno que está tentando acessar é o mesmo da url
        } else if(userContext.idUsuario == idAluno){
            auth = true;
        }

        //Se nenhuma das opções acima forem verdadeiras o aluno não acessará o relatório
        return auth;
    }

    const fetchContent = async () => {
        try {
            const response = await API.AssessmentFinalTest.GetRespostaExercicioFinalTest({id_curso:idCurso, id_prova_final:idNotaFinal, id_exercicio:idExercicio}, getConfig());
            response.data.tela_exercicio.idTesteFinal = idNotaFinal;
            setContentExercise(response.data.tela_exercicio);
        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching the exercise. Please contact the support.",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const setupTestParams = async () => {
        try {
            reportFinal.map((report:any, i: number) => {
                if(report.provaDTO.prova_Final_Resposta.id_exercicio == idExercicio){
                    setCurrentQuestion(i+1);
                    setNextQuestionId(i < (reportFinal.length - 1) ? reportFinal[i+1].provaDTO.prova_Final_Resposta.id_exercicio : -1);
                }
            });
            setTotalQuestions(reportFinal.length);
        } catch (error) {
            console.error(error);
        }
    }

    const getTemplate = () => {

        const type = getTemplateType(contentExercise.template_Yconnect)

        return [
            {
                "id": "exercise",
                "type": type,
                "exercise": contentExercise,
                "studentId": 818373,
                "attempts": 1,
                "isStudent": false,
                "idFinalTest": idNotaFinal
            }
        ]
    }

    const renderBookLifeTime = () => (
        <div className="w-100 p-5 mt-3 d-none d-lg-block">
            <ExerciseTimelineStyle className="exercise-line d-flex flex-row justify-content-around mb-4">
                {reportFinal.map((report: any, i: number) => {
                    if ((i+1) === currentQuestion) {
                        return (
                            <a key={i} className="exercise-task active">
                                <img src={pencil} alt="" />
                            </a>
                        )
                    }

                    return (
                        <Link
                            key={i}
                            className={`exercise-task`}
                            to={`/reports/view-report-final-test/${idCurso}/student/${idAluno}/score/${idNotaFinal}/exercise/${report.provaDTO.prova_Final_Resposta.id_exercicio}/`}>
                                <img src={pencil} alt="" />
                        </Link>
                    )
                })}

            </ExerciseTimelineStyle>
        </div>
    )

    return (
        <Container>
            {
                isLoading ? <Loader /> :
                <>
                    {
                        reportFinal && reportFinal.length > 0
                        ? userContext.profiles.indexOf(15) > -1 || userContext.profiles.indexOf(12) > -1
                            ? <BreadcrumbExerciseAnswerFinalTestTeacher reportFinal={reportFinal[0].provaDTO}/>
                            : <BreadcrumbExerciseAnswerFinalTestStudent reportFinal={reportFinal[0].provaDTO}/>
                        : <></>
                    }
                    {contentExercise &&
                        <Row className="m-0 mb-5">
                            <div className="exercise card shadow d-flex flex-row flex-wrap justify-content-between col-12 p-0">
                                <HomeworkLabelStyle className="task-card-homework">Final Test</HomeworkLabelStyle>
                                {renderBookLifeTime()}

                                <HeaderExerciseStyle className="exercise-header d-flex flex-column justify-content-center w-100 pb-3 pl-3 pr-3 mt-5">
                                    <h1 className="text-center h3 mb-3 font-weight-bolder mt-3">
                                        Question {currentQuestion} of {totalQuestions}
                                    </h1>
                                    <h3
                                        className="text-center mt-2"
                                        dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(contentExercise.enunciado) }}
                                    ></h3>
                                </HeaderExerciseStyle>


                                <FormStyle
                                    className="exercise-body w-100"
                                    onChange={setFormValues}
                                    row={false}
                                    template={getTemplate()} />
                                <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                    <BackLinkStyle
                                        link={`/reports/view-report-final-test/${idCurso}/student/${idAluno}/score/${idNotaFinal}`}
                                        icon="arrowPrevious"
                                        label="Back to Report" />
                                    {
                                        nextQuestionId === -1 ? <></> :
                                        <BackLinkStyle
                                        size="small"
                                        className="font-weight-bolder border-0"
                                        label={`Next task (${currentQuestion + 1} / ${totalQuestions})`}
                                        link={`/reports/view-report-final-test/${idCurso}/student/${idAluno}/score/${idNotaFinal}/exercise/${nextQuestionId}/`}
                                        iconLeft="arrowRightGreen" />
                                    }
                                </FooterStyle>
                            </div>
                        </Row>
                    }
                </>
            }
        </Container>
    )
}

export default FinalTestExerciseReportPage

export default {
    message: [
        { group: false, avatar: 'https://randomuser.me/api/portraits/men/52.jpg', label: "Lucas Shimizu", message: "Hey guys! Who wants to get together on Wednesday to study the next lesson?", time: "12:00 PM", isNew: true },
        { group: true, avatar: '', label: "Lucas, Bruno, Carla...+3", message: "Hey guys! Who wants to get together on Wednesday to study the next lesson?", time: "12:00 PM", isNew: false },
        { group: false, avatar: '', label: "Eduardo Lima", message: "Hey guys! Who wants to get together on Wednesday to study the next lesson?", time: "12:00 PM", isNew: false }
    ],
    books_download: [
        { book: "Yep 2", title: "Yep 2 Unit 3 FC", file: "03_YEP2_FC_UNIT3_OK.pdf", type: "FlashCard", weight: "16mb", date: "17/11/2016" },
        { book: "Yep 2", title: "Yep 2 Unit 3 FC", file: "04_YEP2_FC_UNIT3_OK.pdf", type: "FlashCard", weight: "10mb", date: "19/11/2016" },
        { book: "Yep 2", title: "Yep 2 Unit 3 FC", file: "05_YEP2_FC_UNIT3_OK.pdf", type: "FlashCard", weight: "15mb", date: "18/11/2016" }
    ],
    books_details: [
        { id: 1, title: 'MYP 2', subtitle: '2nd edition', completedTasks: 4, totalTasks: 10, img: 'https://picsum.photos/150/250?image=9', recommended: false },
        { id: 3, title: 'YEP 3', subtitle: '2nd edition (Revised)', completedTasks: 0, totalTasks: 10, img: 'https://picsum.photos/150/250?image=1', recommended: false },
        { id: 4, title: 'YEP 2', subtitle: '2nd edition', completedTasks: 8, totalTasks: 10, img: 'https://picsum.photos/150/250?image=4', recommended: false },
    ],
    countries_list: [
        { id: 1, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false },
        { id: 2, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false },
        { id: 3, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false }
    ],
    stamps_list: [
        { id: 4, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false },
        { id: 5, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false },
        { id: 6, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false },
        { id: 7, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false },
        { id: 8, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false },
        { id: 9, photo: '', title: 'Gastronomy', subtitle: 'Israel', locked: false }
    ],
    stamps_locked: [
        {id: 1, photo: '', locked: false },
        {id: 2, photo: '', locked: true },
        {id: 3, photo: '', locked: true },
        {id: 4, photo: '', locked: true },
        {id: 5, photo: '', locked: true },
        {id: 6, photo: '', locked: true },
        {id: 7, photo: '', locked: true },
        {id: 8, photo: '', locked: true },
    ],
    users: [
        {id: 1, firstName: 'David 32', username: 'yconnectStudent12', email: 'victorjordan95@gmail.com', role: ['student']},
        {id: 2, firstName: 'Victor 2', username: 'yconnectteacher1', email: 'test@test.cz', role: ['teacher']}
    ],
    user_info: {
        "avatar": "https://randomuser.me/api/portraits/men/1.jpg",
        "countriesList": [
            "5"
        ],
        "email": "teset@teste.com",
        "firstName": "Victor",
        "fullName": "Victor Jordan",
        "levelId": 15,
        "userName": "vjordan",
        "tagsCount": 10,
        "role": "student",
        "firstAccess": false,
        "__typename": "User"
    }
}

import * as React from "react";
import { Col, Tooltip } from "reactstrap";

import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style";

import getStaticUrl from "YConnect/Utils/GetStaticUrl.util";
import API from "YConnect/API";
import Loader from "YConnect/Components/Loader";
import AudioPlayer from "YConnect/Components/AudioPlayer";
import getConfig from "YConnect/Utils/GetRequestConfig.util";

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util";

import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";

import AudioPlayerColStyle from "YConnect/Fields/Exercises/TypeIn.field/AudioPlayerCol.style";
import ButtonCollapseStyle from "YConnect/Fields/Exercises/TypeIn.field/ButtonCollapse.style";
import DivCollapseStyle from "YConnect/Fields/Exercises/TypeIn.field/DivCollapse.style";
import InputEditableStyle from "YConnect/Fields/Exercises/TypeIn.field/InputEditable.style";
import ExerciseRowStyle from "YConnect/Fields/Exercises/TypeIn.field/ExerciseRow.style";
import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/TypeIn.field/ExerciseSubStatement.style";
import HrCollapseStyle from "YConnect/Fields/Exercises/TypeIn.field/HrCollapse.style";
import ImgStyle from "YConnect/Fields/Exercises/TypeIn.field/Img.style";
import ExerciseDescription from "YConnect/Enums/ExerciseDescription";
import formatText from "YConnect/Utils/FormatText.util";

function shuffle(array: Array<any>) {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

type DialogueMessageType = {
    id_dialogo: Number;
    id_mensagem: Number;
    ordem_dialogo: Number;
    ordem_mensagem: Number;
    texto: String;
};

type TypeInFieldStateType = {
    loading: boolean;
    dialogueMessageShuffed: Array<DialogueMessageType>;
    respostasUsuario: any;
    rightAnswers: Array<Boolean>;
    answerMaxSize: number;
    collapse: boolean;
    gabarito: any;
    answersByOrder: any;
    tooltipOpen: boolean;
    isDisabledViewAnswers: boolean;
    language: string;
};

class TypeInField extends React.Component<
    ExerciseFieldPropsType,
    TypeInFieldStateType
> {
    constructor(props: any) {
        super(props);

        if (this.props.innerRef) {
            this.props.innerRef.current.selectLanguage = this.selectLanguage;
        }

        this.state = {
            loading: true,
            tooltipOpen: false,
            dialogueMessageShuffed: [],
            respostasUsuario: [],
            rightAnswers: [],
            gabarito: this.gabarito(),
            answersByOrder: this.getAnswersByOrder(),
            answerMaxSize: this.answerMaxSize(),
            collapse: false,
            language: this.props.translated,
        };

        this.handleChange2 = this.handleChange2.bind(this);
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this);
    }

    componentDidMount() {
        this.fetchDialogueExercise();

        if (this.props.attempts > 0) this.fetchUserAnswer();
    }

    selectLanguage = (language: string) => {
        this.setState({ ...this.state, language });
    };

    async fetchDialogueExercise() {
        try {
            const {
                props: {
                    exercise: { id_exercicio, ordem },
                },
            } = this;

            const {
                data: { dialogo_Mensagem_Yconnect },
            } = await API.Exercicio.getDialogo(
                {
                    idExercicio: id_exercicio,
                    tela: ordem,
                },
                getConfig()
            );

            this.setState({
                dialogueMessageShuffed: shuffle(dialogo_Mensagem_Yconnect),
                //dialogueMessageShuffed: [...dialogo_Mensagem_Yconnect],
                loading: false,
            });
        } catch (error) {}
    }

    async fetchUserAnswer() {
        this.setState({ loading: true });

        try {
            const response = await API.Exercicio.getRespostaUsuario(
                {
                    userId: this.props.studentId,
                    exer: this.props.exercise.id_exercicio,
                    tela: this.props.exercise.ordem,
                    tentativa: "U",
                    idProvaFinal: this.props.exercise.idTesteFinal
                        ? this.props.exercise.idTesteFinal
                        : 0,
                },
                getConfig()
            );

            let respostasUsuario = [];
            try {
                respostasUsuario = JSON.parse(response.data.resposta) || [];

                const normalize = (arr: Array<any>) =>
                    arr.reduce((acc, obj) => {
                        Object.keys(obj).forEach((property) => {
                            acc = [...acc, { 0: obj[property] }];
                        });
                        return acc;
                    }, []);

                respostasUsuario = normalize(respostasUsuario);
            } catch {}

            this.setState(
                {
                    loading: false,
                    respostasUsuario,
                },
                () => this.bringRightAnswers2()
            );
        } catch (error) {}
    }

    handleChange2(event: any) {
        const {
            props: { id, onChange },
            state: { respostasUsuario, rightAnswers },
        } = this;

        const {
            target: { value, attributes },
        } = event;

        const indexAnswer = parseInt(attributes["data-index-answer"].value);

        const { respostas_Exercicio_Yconnect } = this.props.exercise;

        const nAnswer = Object.keys(
            respostas_Exercicio_Yconnect.reduce((acc: any, value: any) => {
                acc[value.ordem] = undefined;
                return acc;
            }, {})
        ).length;

        const newRightAnswers = (
            rightAnswers.length === nAnswer
                ? rightAnswers
                : Array.from(Array(nAnswer).keys()).map(() => null)
        ).reduce((acc, value, index) => {
            if (indexAnswer === index) {
                return [...acc, null];
            }

            return [...acc, value];
        }, []);

        const newUserAnswers = (
            respostasUsuario.length === nAnswer
                ? respostasUsuario
                : Array.from(Array(nAnswer).keys()).map(() => ({}))
        ).reduce((acc: Array<any>, answer: string, index: number) => {
            if (index === indexAnswer) {
                return [...acc, { 0: value }];
            }
            return [...acc, answer];
        }, []);

        this.setState({
            respostasUsuario: newUserAnswers,
            rightAnswers: newRightAnswers,
        });

        const score = this.calculaScore(newUserAnswers);
        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: newUserAnswers,
        });
    }

    gabarito() {
        const { respostas_Exercicio_Yconnect } = this.props.exercise;
        const gabarito: any = [];
        respostas_Exercicio_Yconnect.map((resposta: any) => {
            const ordem = resposta.ordem - 1;
            if (!gabarito[ordem]) gabarito[ordem] = [];

            gabarito[ordem].push(resposta);
        });
        return gabarito;
    }

    getAnswersByOrder = () => {
        const {
            props: {
                exercise: { respostas_Exercicio_Yconnect },
            },
        } = this;

        const answersByOrder = respostas_Exercicio_Yconnect.reduce(
            (acc: any, value: any) => {
                if (!acc[value.ordem]) {
                    acc[value.ordem] = [];
                }
                acc[value.ordem].push(value);
                return acc;
            },
            {}
        );
        return answersByOrder;
    };

    embaralhaOpRespostas(respostas: any) {
        let retorno = respostas;
        if (respostas) {
            // Embaralha as respostas, e armazena nas opções de respostas
            for (var i = 0; i < respostas.length; i++) {
                // gera números de 0 ao tamanho do vetor de respostas
                var r1 = Math.floor(Math.random() * respostas.length);
                var r2 = Math.floor(Math.random() * respostas.length);
                var aux = retorno[r2];
                retorno[r2] = retorno[r1];
                retorno[r1] = aux;
            }
        }
        return retorno;
    }

    segment(pergunta: any) {
        let htmlSegmentado =
            pergunta.match(/<(\S*?[^>]*)>.*?<\/\1>|<.*?\/>/g) || [];
        let perguntaSegmentada = [];
        let list = [];
        let lastIndex = 0;

        if (htmlSegmentado.length > 0) {
            for (let i = 0; i < htmlSegmentado.length; i++) {
                let index = pergunta.indexOf(htmlSegmentado[i], lastIndex);
                if (index >= 0) {
                    list.push({ index, texto: htmlSegmentado[i] });
                    lastIndex = index;
                }
            }

            const textos = pergunta.substring(0, list[0].index).split(" ");
            perguntaSegmentada.push(...textos);

            for (let i = 0; i < list.length; i++) {
                perguntaSegmentada.push(list[i].texto);

                let lastIndexLocal = list[i].index + list[i].texto.length;
                if (lastIndexLocal < pergunta.length) {
                    let textos;
                    if (list.length > i + 1) {
                        textos = pergunta
                            .substring(lastIndexLocal, list[i + 1].index)
                            .split(" ");
                    } else {
                        textos = pergunta
                            .substring(lastIndexLocal, pergunta.length)
                            .split(" ");
                    }
                    perguntaSegmentada.push(...textos);
                }
            }
        } else {
            perguntaSegmentada = pergunta.split(" ");
        }

        let id = 0;
        let segs = [];
        let find;

        for (let i = 0; i < perguntaSegmentada.length; i++) {
            const item = perguntaSegmentada[i];

            if (item === "___") {
                const obj = { isInput: true, identificador: id };
                id++;
                segs.push(obj);
            } else if ( item.match(/\{{.*?\}}\s?/g) ) {
                const obj = { isInput: true, identificador: id };
                id++;
                segs.push(obj);
            } else if (item == "<br>") {
                segs.push({ isInput: false, quebraLinha: true });
            } else if (item.indexOf("<") > -1) {
                //caso o input estiver dentro de alguma tag
                let localItem = item;
                do {
                    find = false;

                    const inputIndex = localItem.indexOf("___");

                    if (inputIndex < 0) {
                        break;
                    }
                    find = true;

                    const tags = localItem
                        .substring(0, inputIndex)
                        .match(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g);
                    const alreadyClosedTags =
                        localItem
                            .substring(0, inputIndex)
                            .match(
                                /<\/(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g
                            ) || [];

                    //remover tags ja fechadas, caso existam
                    if (tags) {
                        for (let k = 0; k < tags.length; k++) {
                            if (tags[k].includes("</")) {
                                tags.splice(k, 1);
                                k--;
                            }
                        }
                    }

                    for (let j = 0; j < alreadyClosedTags.length; j++) {
                        for (let k = 0; k < tags.length; k++) {
                            if (
                                tags[k] == alreadyClosedTags[j].replace("/", "")
                            ) {
                                tags.splice(k, 1);
                                break;
                            }
                        }
                    }

                    const closedTags = [];
                    if (tags) {
                        for (let k = 0; k < tags.length; k++) {
                            if (tags[k] != "<br>") {
                                closedTags.push(
                                    "</" + tags[k].slice(1, tags[k].length)
                                );
                            }
                        }
                    }

                    //fechar essas tags
                    segs.push({
                        isInput: false,
                        texto: localItem
                            .substring(0, inputIndex)
                            .concat(closedTags.reverse().join("")),
                    });

                    segs.push({ isInput: true, identificador: id });
                    id++;
                    //abrir novamente essas tags
                    localItem = closedTags
                        .reverse()
                        .join("")
                        .replaceAll("/", "")
                        .concat(
                            localItem.substring(
                                inputIndex + 3,
                                localItem.length
                            )
                        ); //segs[segs.length-1].texto
                } while (find);
                segs.push({ isInput: false, texto: localItem });
            } else if (
                item.indexOf("___") > -1 &&
                item.indexOf("[") === -1 &&
                item.indexOf("]") === -1
            ) {
                //Caso o input não esteja dentro de uma tag e não esteja dentro de cochetes.

                const indexUnderline = item.indexOf("___");

                //Tratativa para caso o input esteja concatenado com um ou mais caracteres.
                segs.push({
                    isInput: false,
                    texto: item.substring(0, indexUnderline),
                });
                segs.push({ isInput: true, identificador: id });
                segs.push({
                    isInput: false,
                    texto: item.substring(indexUnderline + 3, item.length),
                });

                id++;
            } else {
                segs.push({ isInput: false, texto: item });
            }
        }
        return segs;
    }

    getOrderFirstAnswerByOrderDialogue = (order: number) => {
        const { dialogueMessageShuffed } = this.state;
        const currentOrder = Array.from(Array(order - 1).keys()).reduce(
            (currentOrder, index) => {
                const element = dialogueMessageShuffed.find(
                    ({ ordem_dialogo }) => ordem_dialogo === index + 1
                );
                const qtsInputs = element.texto
                    .split(" ")
                    .filter(
                        (text: string) =>
                            (text.indexOf("___") > -1 &&
                            text.indexOf("[") === -1 &&
                            text.indexOf("]") === -1) ||
                            text.match(/\{{.*?\}}\s?/g)
                    ).length;
                currentOrder += qtsInputs;
                return currentOrder;
            },
            1
        );
        return currentOrder;
    };

    answerMaxSize() {
        const { respostas_Exercicio_Yconnect } = this.props.exercise;
        let maxAnswerSize = 0;
        respostas_Exercicio_Yconnect.map((item: any, i: number) => {
            if (item.resposta.length > maxAnswerSize) {
                maxAnswerSize = item.resposta.length;
            }
        });
        return (maxAnswerSize += 10);
    }

    isAnswerCorrect2(order: number, answer: string): Boolean {
        const {
            state: { answersByOrder },
        } = this;

        const formatter = (str: string) =>
            str.toLowerCase().trim().replace(/\s+/g, " ");
        const correct = !!(
            answer &&
            answersByOrder[order].find(
                ({ resposta }: any) =>
                    formatter(resposta) ===
                    formatter(answer.replace(/([\u2018\u2019])/g, "'"))
            )
        );
        return correct;
    }

    //TODO Legado
    isAnswerCorrect(gabarito: any, resposta: any): Boolean {
        const formataLacuna = (lacuna: string) => {
            return lacuna.toLowerCase().trim().replace(/\s+/g, " ");
        };
        let correct = false;
        if (resposta) {
            var respostaUsuario = formataLacuna(
                resposta.replace(/([\u2018\u2019])/g, "'")
            );

            forEach(gabarito, (item) => {
                if (respostaUsuario === formataLacuna(item.resposta))
                    correct = true;
            });
        }
        return correct;
    }

    bringRightAnswers2() {
        const {
            state: { respostasUsuario },
        } = this;

        const getArrayUserAnswers = (userAnswers: any) => {
            const nUserAnswers = Object.keys(userAnswers).length;
            return Array.from(Array(nUserAnswers).keys()).map(
                (index2: number) => userAnswers[index2]
            );
        };

        if (respostasUsuario.length !== 0) {
            const newRightAnswers = respostasUsuario.reduce(
                (
                    newRightAnswers: Array<any>,
                    userAnswers: any,
                    index: number
                ) => {
                    getArrayUserAnswers(userAnswers).forEach(
                        (userAnswer: any) => {
                            newRightAnswers[index] = this.isAnswerCorrect2(
                                index + 1,
                                userAnswer
                            );
                        }
                    );
                    return newRightAnswers;
                },
                []
            );

            const newUserAnswers = respostasUsuario.map((userAnswers: any) => {
                return getArrayUserAnswers(userAnswers).reduce(
                    (acc: any, userAnswer: any, index: number) => {
                        return {
                            ...acc,
                            [index]: userAnswer.trim().replace(/\s+/g, " "),
                        };
                    },
                    {}
                );
            });
            this.setState({
                rightAnswers: newRightAnswers,
                respostasUsuario: newUserAnswers,
            });
        }
    }

    calculaScore(respostasUsuario: any) {
        const { gabarito } = this.state;
        let score = 0;
        let ordem = 0;

        respostasUsuario.map((item: any) => {
            const qntdLacunas = Object.keys(item).length;

            for (var lacuna = 0; lacuna < qntdLacunas; lacuna++) {
                if (this.isAnswerCorrect(gabarito[ordem], item[lacuna])) {
                    score++;
                }
                ordem++;
            }
        });

        return { qntd_acertos: score, qntd_respostas: gabarito.length };
    }

    showCorrectAnswers() {
        const {
            props: {
                exercise: { respostas_Exercicio_Yconnect },
            },
            state: { dialogueMessageShuffed },
        } = this;

        const getDialogueByOrder = (order: number) =>
            dialogueMessageShuffed.find(
                ({ ordem_dialogo }) => ordem_dialogo === order
            );

        const getNInputByDialogueMessage = (
            dialogueMessage: DialogueMessageType
        ) =>
            dialogueMessage.texto
                .split(" ")
                .filter(
                    (text: string) =>
                        (text.indexOf("___") > -1 &&
                        text.indexOf("[") === -1  &&
                        text.indexOf("]") === -1 )||
                        text.match(/\{{.*?\}}\s?/g)
                ).length;

        const getAllAnswersByOrder = (order: number) =>
            respostas_Exercicio_Yconnect.filter(
                (answer: any) => answer.ordem === order
            );

        const nDialogue = dialogueMessageShuffed.length;

        const { newUserAnswers, newRightAnswers } = Array.from(
            Array(nDialogue).keys()
        ).reduce(
            (
                { offset, newUserAnswers, newRightAnswers }: any,
                index: number
            ) => {
                const order = index + 1;
                const dialogueMessage = getDialogueByOrder(order);
                if (dialogueMessage) {
                    const nInput = getNInputByDialogueMessage(dialogueMessage);
                    Array.from(Array(nInput).keys()).forEach(() => {
                        const answers = getAllAnswersByOrder(offset + 1);
                        newUserAnswers[offset] = answers.reduce(
                            (
                                userAnswers: any,
                                { resposta }: any,
                                index: number
                            ) => {
                                return { ...userAnswers, [index]: resposta };
                            },
                            {}
                        );
                        newRightAnswers[offset] = true;
                        offset++;
                    });
                }
                return { offset, newUserAnswers, newRightAnswers };
            },
            { offset: 0, newUserAnswers: [], newRightAnswers: [] }
        );

        const score = this.calculaScore(newUserAnswers);
        this.props.onChange(this.props.id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: newUserAnswers,
        });

        this.setState({
            respostasUsuario: newUserAnswers,
            rightAnswers: newRightAnswers,
        });
    }

    getValue = (indexFirstAnswer: number, indexInput: number) => {
        const {
            state: { respostasUsuario },
        } = this;

        return Object.values(
            respostasUsuario[indexFirstAnswer + indexInput] || {}
        ).reduce(
            (value, answer) => (value != "" ? value + " \n " + answer : answer),
            ""
        );
    };

    render() {
        let {
            props: { exercise, attempts, isStudent, isDisabledViewAnswers },
            state: {
                respostasUsuario,
                loading,
                rightAnswers,
                dialogueMessageShuffed,
                collapse,
                answerMaxSize,
            },
            handleChange2,
        } = this;

        if (loading) {
            return <Loader />;
        }

        const midiaYConnect = exercise.midia_Yconnect.length > 0 ? exercise.midia_Yconnect[0]
            : (exercise.exercicio_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect.length > 0) ? exercise.exercicio_Yconnect.midia_Yconnect[0]
                : {}

        const hasAudio =
            (midiaYConnect || {}).tipo_midia === "audio";
        const IMAGESTYPESNAME = ["imagem","image","img"];
        return (
            <>
                {!isDisabledViewAnswers && (
                    <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                        {attempts >= 2 || !isStudent ? (
                            <HeaderButtonStyle
                                className="float-right"
                                label="View Answers"
                                onClick={this.showCorrectAnswers}
                                icon="checkedGreen2"
                            />
                        ) : (
                            <>
                                <HeaderButtonStyle
                                    id="completeResultToolTip"
                                    className="float-right disabled  view-answers"
                                    label="View Answers"
                                    icon="checkedGreen2"
                                />
                                <Tooltip
                                    placement="right"
                                    isOpen={this.state.tooltipOpen}
                                    target="completeResultToolTip"
                                    toggle={() =>
                                        this.setState((state) => ({
                                            tooltipOpen: !state.tooltipOpen,
                                        }))
                                    }
                                >
                                    {this.state.language == "br" && (
                                        <span>
                                            Oops, você só poderá saber a
                                            resposta correta depois de verificar
                                            suas próprias respostas na primeira
                                            e segunda tentativa.
                                        </span>
                                    )}

                                    {this.state.language == "en" && (
                                        <span>
                                            Oops, you'll only be able to know
                                            the correct answers once you've
                                            checked your own answers on the 1st
                                            and 2nd attempts.
                                        </span>
                                    )}
                                </Tooltip>
                            </>
                        )}
                    </div>
                )}
                {exercise.ajuda ||
                exercise.texto ||
                exercise.conteudo_exercicio ||
                hasAudio ? (
                    <ExerciseSubStatementStyle className="w-100 pr-5 pl-5">
                        {/** Ajuda */}
                        <div className="text-center">
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: GetNewHtmlReplaceImg(
                                        formatText(exercise.ajuda)
                                    ),
                                }}
                            ></h2>
                        </div>
                         {/** Conteudo exercicio */}
                         {  exercise.conteudo_exercicio ?(
                            <div className="text-center">
                                {
                                    exercise.conteudo_exercicio &&  !exercise.conteudo_exercicio.includes(midiaYConnect.url) &&
                                    <p
                                    dangerouslySetInnerHTML={{
                                        __html: GetNewHtmlReplaceImg(
                                            formatText(exercise.conteudo_exercicio)
                                        ),
                                    }}
                                ></p>
                                }
                            </div>
                        ): null }

                        {/** texto */}
                        {exercise.texto ? (
                            <ExerciseRowStyle className="exercise-dialogue">
                                <DivCollapseStyle
                                    toggler="#toggler"
                                    dangerouslySetInnerHTML={{
                                        __html: GetNewHtmlReplaceImg(
                                            formatText(exercise.texto)
                                        ),
                                    }}
                                />
                                <HrCollapseStyle />
                                <ButtonCollapseStyle
                                    color="link"
                                    id="toggler"
                                    size="md"
                                    onClick={() =>
                                        this.setState((state) => ({
                                            collapse: !state.collapse,
                                        }))
                                    }
                                >
                                    {collapse
                                        ? "- Collapse text"
                                        : "+ Expand text"}
                                </ButtonCollapseStyle>
                            </ExerciseRowStyle>
                        ) : null
                        }

                        {/** audio */}
                        {hasAudio ? (
                            <AudioPlayerColStyle
                                className=" mb-3"
                                xs="12"
                                md={{ size: 6, offset: 3 }}
                                lg={{ size: 6, offset: 3 }}
                            >
                                <AudioPlayer
                                    src={getStaticUrl(
                                        midiaYConnect.url
                                    )}
                                />
                            </AudioPlayerColStyle>
                        ) : (
                            ""
                        )}
                    </ExerciseSubStatementStyle>
                ) : (
                    ""
                )}

                {/** Sub Enunciado */}
                {exercise.conteudo_exercicio && !!exercise.texto &&  exercise.midia && !exercise.conteudo_exercicio.includes(midiaYConnect.url) ? (
                    <ExerciseRowStyle className="exercise-dialogue mt-4 pr-5 pl-5">
                        <Col
                            className="exercise-dialogue-content text-center w-100"
                            md="12"
                            lg="12"
                        >
                            <div className="exercise-dialogue-text">
                                <h4
                                    dangerouslySetInnerHTML={{
                                        __html: GetNewHtmlReplaceImg(
                                            formatText(exercise.conteudo_exercicio)
                                        ),
                                    }}
                                ></h4>
                            </div>
                        </Col>
                    </ExerciseRowStyle>
                ) : (
                    ""
                )}

                {/** exercicios */}
                <div className="pr-3 pl-3 pr-lg-5 pl-lg-5 pt-5">
                    {dialogueMessageShuffed.map((item: any, key) => {
                        const imgUrl = (
                            exercise.midia_Yconnect[item.ordem_dialogo - 1] ||
                            {}
                        ).url;

                        const mediaType = (
                            exercise.midia_Yconnect[
                                item.ordem_dialogo - 1
                            ] || {}
                        ).tipo_midia;

                        const hasImg = IMAGESTYPESNAME.some(type => mediaType === type) && !isEmpty(imgUrl);

                        const pergunta = this.segment(item.texto);

                        const orderFistAnswer =
                            this.getOrderFirstAnswerByOrderDialogue(
                                item.ordem_dialogo
                            );

                        const isRightOrWrong = () => {
                            const nInputs = pergunta.filter(
                                ({ isInput }: any) => isInput
                            ).length;

                            if (nInputs > 0) {
                                return Array.from(Array(nInputs).keys()).reduce(
                                    (acc, inputIndex) => {
                                        const indexAnswer =
                                            orderFistAnswer + inputIndex - 1;
                                        if (acc === "right") {
                                            if (
                                                rightAnswers[indexAnswer] ===
                                                true
                                            )
                                                return "right";

                                            if (
                                                rightAnswers[indexAnswer] ===
                                                false
                                            )
                                                return "wrong";

                                            return "";
                                        }
                                        return acc;
                                    },
                                    "right"
                                );
                            } else {
                                return "";
                            }
                        };
                        return (
                            <ExerciseRowStyle
                                className={`exercise-dialogue borderBottom ${
                                    hasImg && !imgUrl.includes("midia:audio")  ? "row" : ""
                                } ${isRightOrWrong()}`}
                                key={`dialoguePhrase-${key}`}
                            >
                                {hasImg && !imgUrl.includes("midia:audio") && (
                                    <Col xs="12" sm="5" md="4" lg="4" xl="4">
                                        <figure>
                                            <ImgStyle
                                                src={getStaticUrl(imgUrl)}
                                                alt=""
                                            />
                                        </figure>
                                    </Col>
                                )}

                                <Col
                                    xs="12"
                                    sm={`${hasImg ? 7 : 12}`}
                                    md={`${hasImg ? 8 : 12}`}
                                    lg={`${hasImg ? 8 : 12}`}
                                    xl={`${hasImg ? 8 : 12}`}
                                    className="d-flex flex-row flex-wrap align-self-start"
                                    style={{ lineHeight: "2.5em" }}
                                >
                                    {pergunta.map((trecho: any, key: any) => {
                                        if (trecho.quebraLinha) {
                                            return (
                                                <span
                                                    key={key}
                                                    className="breakLine"
                                                />
                                            );
                                        }

                                        if (!trecho.isInput) {
                                            return (
                                                <span
                                                    key={key}
                                                    dangerouslySetInnerHTML={{
                                                        __html: `${
                                                            trecho.texto
                                                        }${"&nbsp;"}`,
                                                    }}
                                                ></span>
                                            );
                                        }

                                        const value: any = this.getValue(
                                            orderFistAnswer - 1,
                                            trecho.identificador
                                        );
                                        const maxWidth: number = value
                                            ? value.length > 36
                                                ? 52
                                                : value.length
                                            : 5;
                                        // const maxWidth: number = respostasUsuario[orderFistAnswer - 1] ?
                                        //     Object.values(respostasUsuario[orderFistAnswer - 1])
                                        //         .reduce((newRow: number, prop: string) => newRow > (prop.indexOf('\n') != -1 ? prop.indexOf('\n').length : prop.length)
                                        //             ? newRow : (prop.indexOf('\n') != -1 ? prop.indexOf('\n').length : prop.length), 5)
                                        //     : 5;

                                        let rows: number =
                                            (rightAnswers[orderFistAnswer] ||
                                                (value &&
                                                    value.length > maxWidth)) &&
                                            respostasUsuario[
                                                orderFistAnswer - 1
                                            ]
                                                ? Object.values(
                                                      respostasUsuario[
                                                          orderFistAnswer - 1
                                                      ]
                                                  ).length
                                                : 1;

                                        return (
                                            <>
                                            {pergunta.length === 1 &&
                                                <span style={{
                                                    fontWeight: "bold", marginRight: '10px'
                                                }}>Answer: </span>}

                                                <InputEditableStyle
                                                    key={key}
                                                    rows={
                                                        ( value.length > 64 || value.indexOf('\n') != -1) &&
                                                        rows == 1
                                                            ? (rows = 2)
                                                            : rows
                                                    }
                                                    type="text"
                                                    maxLength={answerMaxSize}
                                                    data-index-answer={
                                                        orderFistAnswer +
                                                        trecho.identificador -
                                                        1
                                                    }
                                                    onChange={handleChange2}
                                                    style={{
                                                        minWidth: (pergunta.length === 1 ? 20 : 4) + "ch",
                                                        width: maxWidth + 6 + "ch",
                                                        overflowY: "hidden",
                                                        maxHeight:
                                                            (rows != 0 ? rows : 1) *
                                                                2 +
                                                            "rem",
                                                    }}
                                                    value={value}
                                                />
                                            </>
                                        );
                                    })}
                                </Col>
                            </ExerciseRowStyle>
                        );
                    })}
                </div>
            </>
        );
    }
}

export default TypeInField;

import * as React from "react";
import { Modal, FormGroup } from "react-bootstrap";
import { ButtonStyle } from "YConnect/Components/Button";
import styled from "styled-components";

const PStyle = styled.p`
  font-size: 1.125rem;
`



const ConfirmStudentMode = ({ hasShow, onConfirm, onClose }: any) => (
    <Modal show={hasShow} onHide={onClose} centered>
        <Modal.Header className="px-4" closeLabel="Close" closeButton>
            <h2>Student Mode</h2>
        </Modal.Header>
        <Modal.Body className="px-4" centered>
            <PStyle>Are you sure you want to go to student mode?</PStyle>
            <div className="d-flex flex-row justify-content-between pt-1">
                <ButtonStyle
                    type="button"
                    label="Cancel"
                    size="190"
                    className="mr-2 justify-content-center"
                    onClick={onClose}
                />
                <ButtonStyle
                    green
                    label="Yes, I'm sure"
                    size="190"
                    onClick={onConfirm}
                    className="justify-content-center"
                />
            </div>
        </Modal.Body>
    </Modal>
);

export default ConfirmStudentMode;

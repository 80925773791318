import * as React from "react"
import styled from "styled-components"

const CardStyle = styled.div`
    align-items: center;
    border: 1px solid #E1E6E7;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 223px;
`


const CardLoadingStyle = styled(CardStyle)`
.loading {
    left: calc(50% - 20px);
    position: relative;
    width: 40px;
    height: 40px;
    border: 4px transparent solid;
    border-top: 4px ${props => props.theme.main.lightPurple} solid;
    border-radius: 30px;
    animation: preload .7s infinite;
    animation-timing-function: linear;
}

    
@-moz-keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
`

const CardLoading = ({className}:any) => 
    <CardLoadingStyle className={className}>
        <div className="loading"></div>
    </CardLoadingStyle>

export default CardLoading
const CONFIG_TABLE_SECTION:Array<ColumnConfigType> = [
    {
        keystone : "Name",
        label    : "Name"
    },
    {
        keystone : "Order",
        label    : "Order"
    },
    {
        keystone : "UnitId",
        label    : "Unit",
        selectDataName : "UnitId"
    }
]

export default CONFIG_TABLE_SECTION
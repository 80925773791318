import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

import { ButtonStyle } from "YConnect/Components/Button"
import AudioMainView from "YConnect/PagesComponents/CMS/Views/AudioMain.view"
import FormContainer from "YConnect/Containers/Form.container"
import AudioFilesModal from "YConnect/PagesComponents/CMS/Modals/AudioFiles.modal"

import AnswersChooseMultipleTable from "YConnect/PagesComponents/CMS/Editors/ChooseMultiple.editor/AnswersChooseMultipleTable"
import AUDIO_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Audio.template.json"
import DEFAULT_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Default.template.json"

type ChooseMultipleAudioControlTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const ChooseMultipleAudioControlTemplateEditor = ({
    question,
    onChangeValues
}:ChooseMultipleAudioControlTemplateEditorProps)=>{

    const [isShowAudioFilesModal, setShowAudioFilesModal] = useState(false)

    const [values, setValues]   = useState(question)    
    const [Answers, setAnswers] = useState<Array<AnswerType>>(question.Answers)

    const handleChangeValues = (values:any) => addValues(values)
    const handleChangeAnswer = (values:Array<AnswerType>) => setAnswers(values)

    const addValues = (newValues:any) => {
        
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }

    useEffect(() => {
        setValues({...values, Answers})
        onChangeValues({...values, Answers})
    }, [Answers])

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])

    const addMainExerciseMedia = ({Source, MediaType}:any) => {
        const newValues = {
            ...values,
            ExerciseMedias: [{Order:0, Source, MediaType}, ...values.ExerciseMedias.filter(({Order}) => Order !== 0)]
        }
        setValues(newValues)
        onChangeValues(newValues)
    }

    const getMainExerciseMedia = () =>
        values && values.ExerciseMedias.find(({Order}) => Order === 0)

    const handleOpenModalAudioFiles = () => setShowAudioFilesModal(true)
    const handleCloseModalAudioFiles = () => setShowAudioFilesModal(false)
    const handleAddAudio = ({Source, MediaType}:any) => {
        addMainExerciseMedia({Source, MediaType})
        setShowAudioFilesModal(false)
    }
    const handleRemoveExerciseMedia = () => {
        const newValues = {
            ...values,
            ExerciseMedias: values.ExerciseMedias
            .filter(({Order}) => Order !== 0 )
        }

        setValues(newValues)
        onChangeValues(newValues)
    }

    return  <>
            <AudioMainView medias={values && values.ExerciseMedias}/>
            {
                !(getMainExerciseMedia()
                && getMainExerciseMedia().MediaType.toLowerCase() === "audio")
                && <Row className="py-3">
                        <Col md="auto">
                            <ButtonStyle
                                green
                                disabled  = {getMainExerciseMedia() && getMainExerciseMedia().MediaType === "image"}
                                size      = "190"
                                onClick   = {handleOpenModalAudioFiles}
                                icon      = "audioWhite"
                                label     = "Add Audio"/>
                        </Col>
                    </Row>
            }
            {
                getMainExerciseMedia()
                && <Row className="py-3 justify-content-center">
                        <Col md="auto">
                            <ButtonStyle
                                className = "mb-2"
                                size      = "130"
                                onClick   = {handleOpenModalAudioFiles}
                                icon      = "reset"
                                label     = "Change"/>
                        </Col>
                        <Col md="auto">
                            <ButtonStyle
                                size      = "130"
                                onClick   = {handleRemoveExerciseMedia}
                                icon      = "remove"
                                label     = "Remove"/>
                        </Col>
                    </Row>
            }
            <Row>
                <Col md={12}>
                    <FormContainer 
                        values   = {values}
                        onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                        template = {[...DEFAULT_TEMPLATE.map((field) => ({...field}))]}/>
                </Col>
                <Col md={12} className="mt-3">
                    <h3 className="font-weight-bold">Answers{Answers.length > 0 && ` (${Answers.length})`}</h3>
                    <AnswersChooseMultipleTable 
                        nextQuestionOrder = {question.nextQuestionOrder}
                        values            = {Answers}
                        onChange          = {(values:Array<AnswerType>) => handleChangeAnswer(values)}/>
                </Col>
            </Row>
            {
                isShowAudioFilesModal
                && <AudioFilesModal 
                    onSelect={handleAddAudio}
                    onClose={handleCloseModalAudioFiles}/>
            }
            </>
}
          
export default ChooseMultipleAudioControlTemplateEditor
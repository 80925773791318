import styled from "styled-components"

const LineProgressStyled = styled.div `
    margin: auto;
    width: 65%;
    @media screen and (min-width: 1024px) {
        padding: 16px 0;
        width: 100%;
    }


    &.bestStudent {
        @media screen and (min-width: 1024px) {
            border-top: none;
            width: 55%;
        }
   
    }
    
    .progress-percentage {
        color: ${props => props.theme.main.mainPurple};
        font-weight: 600;
    }

    .line {
        background-color: ${props => props.theme.main.backgroundBtDisabled};
        border-radius: 2px;
        width: 100%;
        height: 3px;
        margin-top: 10px;
    }

    .line-progress {
        border-radius: 4.5px 0 0  4.5px;
        background-color: ${props => props.theme.main.mainPurple};
        height: 3px;
        margin-top: -4px;
        box-shadow: 1px 0px 3px 2px rgba(100, 62, 147, 0.6);
    }

    .pointer-progress {
        margin-top: -7px;
        background-color: ${props => props.theme.main.backgroundWhite};
        border: 2px solid ${props => props.theme.main.mainPurple};
        border-radius: 100%;
        width: 12px;
        height: 12px;
    }

    .line-start {
        color: ${props => props.theme.main.title};
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
`

export default LineProgressStyled
import * as React               from "react"
import { Fragment }             from "react"
import { useState, useEffect }  from "react"
import { CSSTransition }        from "react-transition-group"
import { FormGroup }            from "reactstrap"

import { ButtonStyle } from "YConnect/Components/Button"

const ViewBookFilterModal = ({ 
    onFilterMenu, 
    hasFilterMenu, 
    unitsFilter, 
    filterSection, 
    filterByStatus, 
    filterByUnit, 
    filterBySection 
}: any) => {

    useEffect(() => {
    }, [])

    return (
        <CSSTransition classNames="dialog YC-lateral-menu" timeout={300} in={hasFilterMenu} unmountOnExit>
            <div className="YC-lateral lateral-filter shadow d-flex flex-column justify-content-between pb-0" style={{zIndex: 9999}} >
                <div>
                    <h4 className="align-items-center d-flex flex-row justify-content-between mb-2">
                        Filter By
                        <button className="btn btn-link text-clickable pr-1" onClick={() => onFilterMenu(!hasFilterMenu)}>Close x</button>
                    </h4>
                    <div className="header-filters w-100">
                        <div className="form-group w-100">
                            <label className="form-label label-select" htmlFor="unit">Unit</label>
                            <select className="form-control" id="unit" onChange={filterByUnit}>
                                {
                                    unitsFilter.map((unit: any) => <option value={unit.id_unidade} key={unit.id_unidade}>{`Unit ${unit.ordem} - ${unit.nome}`}</option>)
                                }
                            </select>
                        </div>
                        <div className="form-group w-100">
                            <label className="form-label label-select" htmlFor="section">Section</label>
                            <select className="form-control" id="section" onChange={filterBySection}>
                                <option value={'all'} key={Math.random()}>All</option>
                                {
                                    filterSection ?
                                        filterSection.map((task: any) => <option value={task.nome_secao} key={task.id_exercicio}>{`${task.nome_secao}`}</option>)
                                        :
                                        null
                                }
                            </select>
                        </div>
                        <div className="form-group w-100">
                            <label className="form-label label-select" htmlFor="task">Task Status</label>
                            <select className="form-control" id="task" onChange={filterByStatus}>
                                <option value="all">All</option>
                                <option value="homework">To do</option>
                                <option value="todo">Done</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="w-100 d-flex justify-content-end">
                    <ButtonStyle
                        green
                        className = "mb-3"
                        label     = "Apply filter"
                        icon      = "arrowRight"
                        onClick   = {() => onFilterMenu(!hasFilterMenu)}
                        size      = "medium"
                        maxWidth  = {true}
                    />
                </div>
            </div>

        </CSSTransition>
    )
}

export default ViewBookFilterModal

const CONFIG_TABLE_BOOK:Array<ColumnConfigType> = [
    {
        keystone : "Title",
        label    : "Title"
    },
    {
        keystone : "Author",
        label    : "Author"
    },
    {
        keystone : "Publishing",
        label    : "Publishing"
    },
    {
        keystone : "Description",
        label    : "Description"
    }
]

export default CONFIG_TABLE_BOOK
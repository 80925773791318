import AccountDetailsTemplate from "YConnect/Configs/Templates/AccountDetails.template.json"

const _ = JSON.parse(JSON.stringify(AccountDetailsTemplate))

export default {
    "onMapState": (keystone: string, value: any, state: Array<object>) => state.map((field: any) => {
        if (keystone == "custom" && field.keystone == "gender") {
            if (value == "")
                field.disabled = false
            else
                field.disabled = true
        }
        return field
    }),
    "template": _.map((field:any) => {
        if(field.keystone === "email_aluno"){
            field.messageBlocker = "Contact your Pedagogical Coordinator to change your e-mail"
            field.disabled = true
        }
        return field
    })
}
import * as React              from "react"

import TabStyle        from "YConnect/Styles/Tab.style"
import TabContentStyle from "YConnect/PagesComponents/PrePostReport/Components/TabContent.style"
import TabReport       from "YConnect/PagesComponents/PrePostReport/Pearson.report/TabReport"
import useReport       from "YConnect/PagesComponents/PrePostReport/Pearson.report/useReport"

const TabsResult = ({ 
    semesterIdSelected,
    regionalIdSelected,
    establishmentIdSelected,
    courseIdSelected,
    classIdSelected
} : any) => {

    const {
        schoolInfo,
        bestClass
    } = useReport({
        semesterIdSelected, 
        regionalIdSelected, 
        establishmentIdSelected, 
        courseIdSelected, 
        classIdSelected
    })

    return <TabStyle defaultActiveKey="pre">

            <TabContentStyle 
                eventKey  = "pre" 
                title     = "Pre test"  
                className = "yc-tab">
                <TabReport 
                    semesterIdSelected = {semesterIdSelected}
                    regionalIdSelected = {regionalIdSelected}
                    schoolInfo         = {schoolInfo}
                    bestClass          = {bestClass} />
            </TabContentStyle>

            <TabContentStyle 
                eventKey  = "post" 
                title     = "Post test" 
                className = "yc-tab">
                <TabReport 
                    isPost
                    semesterIdSelected = {semesterIdSelected}
                    regionalIdSelected = {regionalIdSelected}
                    schoolInfo         = {schoolInfo}
                    bestClass          = {bestClass}/>
            </TabContentStyle>
        </TabStyle>
}

export default TabsResult
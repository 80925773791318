import * as React   from "react"
import {useContext, useEffect} from "react"
import {Container, Row} from "reactstrap"

import UserContext from "YConnect/User.context"
import { BreadcrumbOnlineTearch } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle   from "YConnect/Components/PageTitle"

import {ButtonStyle} from "YConnect/Components/Button"
import * as chat     from "YConnect/Assets/svg/chat.svg"

import Toastify from "toastify-js"

const OnlineTeacherPage = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = window.lpTag=window.lpTag||{},'undefined'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:'18943792'||'',section:lpTag.section||'',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:'1.10.0',_tagCount:1,protocol:'https:',events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0===e?(this._defB=this._defB||[],this._defB.push(t)):1===e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+'//'+(this.ovr&&this.ovr.domain?this.ovr.domain:'lptag.liveperson.net')+'/tag/tag.js?site='+this.site);var o=document.createElement('script');o.setAttribute('charset',e?e:'UTF-8'),i&&o.setAttribute('id',i),o.setAttribute('src',n),document.getElementsByTagName('head').item(0).appendChild(o)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent('onload',function(){t._domReady('domReady')}):(window.addEventListener('DOMContentLoaded',function(){t._domReady('contReady')},!1),window.addEventListener('load',function(){t._domReady('domReady')},!1)),'undefined'===typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger('LPT','DOM_READY',{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;

        document.body.appendChild(script);
      }, []);

    useEffect(() => {
        const chat = document.querySelector('.LPMcontainer') as any;
        if (chat)
           chat.style.inset = "auto 0px 60px auto";
      }, []);

    const {primeiroAcessoGameficacao}:any = useContext(UserContext)

    const isFirstLogin = !(primeiroAcessoGameficacao && primeiroAcessoGameficacao.dt_primeiro_acesso)

    const openChat = () => {
        const chat = document.querySelector('.LPMcontainer') as any
        const button = document.querySelector('#buttonFlag') as HTMLButtonElement

        if (chat) {
            chat.click()
            button.disabled = false
        }

        else {
            Toastify({
                text: "Please, wait a few seconds and try again.",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }

    const _popupCenter = (url: string, title: string, w: number, h: number) => {
        // Fixes dual-screen position                         Most browsers      Firefox
        const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX
        const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

        const systemZoom = width / window.screen.availWidth
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title, "scrollbars=yes, width=" + w / systemZoom + ", height=" + h / systemZoom + ", top=" + top + ", left=" + left)

        // Puts focus on the newWindow
        if (window.focus) newWindow.focus()
    }

    return <Container>
        <BreadcrumbOnlineTearch isFirstLogin={isFirstLogin}/>
        <PageTitle label="Online teacher" />
        <Row className="m-0 mb-5">
            <div className="online-teacher card shadow col-12">
                <div className="d-flex chat-text flex-column flex-wrap">
                    <h2 className="chat-text-title">Feeling lost? Our online teachers are here to guide you</h2>
                    <p className="chat-text-subtitle">
                        Consult an expert teacher to help you with your assignments or
                        to ask questions about English, through chat or direct message.
                    </p>
                    <div className="chat-text-cta" id="YConnect_Chat_Button">
                        <ButtonStyle
                            id        = "buttonFlag"
                            label     = "Talk to an Online Teacher"
                            className = "mb-2"
                            icon      = "messageWhite"
                            onClick   = {() => openChat()}
                            size      = "large"
                            maxWidth  = {window.innerWidth < 1024 ? true : false}
                        />
                        <p className="chat-direct-message text-secondary">
                            <img src={chat} className="pr-2" />
                            Direct message and chat available!
                        </p>
                    </div>
                </div>
                <div className="chat-schedule">
                    <p className="chat-schedule-title">Chat Schedule</p>
                    <p className="m-0 text-secondary"><b>Monday to Thursday</b> from 1:00 PM to 9:00 PM*</p>
                    <p className="m-0 text-secondary"><b>Friday</b> from 2:00 PM to 9:00 PM*</p>
                    <p className="text-secondary"><b>Saturday</b> from 8:00 AM to 1:00 PM*</p>
                    <span className="small">*GMT-3 (Brasilia time)</span>
                </div>

            </div>
        </Row>
    </Container>
}

export default OnlineTeacherPage



import * as React from "react"
import styled from "styled-components"

import convertPhoto        from "YConnect/Utils/ConvertPhoto.util"
import BestClassScoreStyle from "YConnect/PagesComponents/PrePostReport/School.components/BestClassScore.style"
import ProfilePicture      from "YConnect/Components/ProfilePicture"

import CardLoading  from "YConnect/PagesComponents/PrePostReport/Components/CardLoading"

const CardStyle = styled.div`
    align-items: center;
    border: 1px solid #E1E6E7;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 223px;
`

const H2Style = styled.h2`
    color: #565656;
    font-size: 20px;
    font-weight: 600;
`

const BestClassScore = ({
    isPost,
    bestClassScore,
    className
}:any) => {

    const bestTeacher = bestClassScore 
    ? bestClassScore.filter((high: any) => high && high.fl_is_pre === (isPost?false:true))[0]
    : undefined

    return <div className={className}>
        <H2Style>Best class score</H2Style>
        {
            (bestClassScore === undefined || bestClassScore.filter((high: any) => high && high.fl_is_pre === (isPost?false:true)).length > 0)
            ? (
                bestTeacher 
                ? <BestClassScoreStyle>
                    <header>
                        <span className="class-name">{bestTeacher.nome}</span>
                        <span className="class-percent" style={{ color: bestTeacher.mediaTurma > 0.7 ? "#00A281" : "#FF1A4A" }}>
                            {(bestTeacher.mediaTurma * 100).toFixed(1)}%
                        </span>
                    </header>
                    <hr />
                    <div className="teacher">
                        <ProfilePicture
                            border  = "5px solid #bdbdbd"
                            height  = {70}
                            picture = {convertPhoto(bestTeacher.professor && bestTeacher.professor.foto)}
                            width   = {70}/>
                        <div className="teacher-info">
                            <span className="role">Teacher</span>
                            <span className="name">{bestTeacher.professor && bestTeacher.professor.nomeCompleto}</span>
                        </div>
                    </div>
                </BestClassScoreStyle>
                : <CardLoading/>)
            : <CardStyle className="align-items-center d-flex flex-row justify-content-center">
                    No data recorded!
                </CardStyle>
        }
    </div>
    
}
     

export default BestClassScore
import * as React from "react"

import { LinkStyle }  from "YConnect/Components/Button"

class OpenQuestionReviewButton extends React.Component<{
  attempts: number,
  hasReviews: boolean,
  isStudent: boolean,
  onClick: any
}, {}> {
  render() {
    const {attempts, hasReviews, isStudent, onClick} = this.props

    if (attempts < 1 || (isStudent && !hasReviews)) return null

    const label = isStudent ? 'Click here to see your reviews' : 'Click here to add reviews'

    return (
      <div className="open-question__status-card__review-button">
        <div className="open-question__status-card__review-button-wrapper">
          <LinkStyle
            size="medium"
            label={label}
            onClick={onClick}
            className="review-button"
          />
        </div>
      </div>
    );
  }
}

export default OpenQuestionReviewButton
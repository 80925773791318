import * as React from "react"
import { Col }    from "reactstrap"
import isBoolean  from "lodash/isBoolean"

import * as userBlue from "YConnect/Assets/svg/user-blue.svg"

import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"

import ExerciseDialogueStyle     from "YConnect/Fields/Exercises/DialoguePhrase.field/ExerciseDialogue.style"
import ExerciseRowStyle          from "YConnect/Fields/Exercises/DialoguePhrase.field/ExerciseRow.style"

const setClass = (index: number) => {
    return index % 2 === 0 ? "" : "reverse"
}

type ExerciseRowProps = {
    index           : number
    id              : string
    item            : any
    rightAnswers    : Array<Boolean>
    answer          : any
    exerciseAnswers : any
    onChange        : any
    errorMessage    ?: string[]
}

const ExerciseRow = ({
    id,
    index,
    item,
    rightAnswers,
    answer,
    exerciseAnswers,
    onChange,
    errorMessage
}:ExerciseRowProps) => {

    const {answerIndex, avatar, options} = item


    return <ExerciseRowStyle 
                className = {`borderBottom ${setClass(index)}`}>
                <Col
                    className = "exercise-dialogue-username font-weight-bolder d-md-none"
                    xs        = "12">
                    { avatar && <span>{avatar.nome}</span>}
                </Col>
                <Col
                    className = "exercise-dialogue-photo mb-2 mb-md-1 text-left text-md-center"
                    xs        = "1"
                    sm        = "1" 
                    md        = "2" 
                    lg        = "2" 
                    xl        = "2">
                    {
                        avatar
                            ? <>
                                    <img  src={getStaticUrl(avatar.url_foto)} alt=""/>
                                    <span className="ml-1 ml-md-0 d-none d-md-block">{avatar.nome}</span>
                                </>
                            : <img src={userBlue} alt=""/>
                    }
                </Col>
                <ExerciseDialogueStyle
                    className = {` ${setClass(index)} ${isBoolean(rightAnswers[answerIndex]) ? (rightAnswers[answerIndex] ? "right" : "wrong") : ""}`}
                    xs        = "10" 
                    sm        = "10" 
                    md        = "10" 
                    lg        = "10" 
                    xl        = "10">
                    <div className={`exercise-dialogue-text ${setClass(index)}`}>
                        <div className="text">
                            {
                                item.texto.map((texto:string, key:number) => 
                                    texto 
                                    ? <p 
                                        key                     = {key} 
                                        className               = "mb-0" 
                                        dangerouslySetInnerHTML = {{ __html: texto }} />
                                    : (
                                        answer.resposta 
                                        ? <span
                                            className="resposta"
                                            key={key} 
                                            dangerouslySetInnerHTML={{ __html: answer.resposta }} />
                                        : " ____ "
                                    )
                                )
                            }
                        </div>
                    </div>

                    {
                        options 
                        && <div className="exercise-dialogue-options mt-3">
                            <div className={`form-group ${errorMessage[index] ? 'border-danger' : ''}`}>
                                <label className="form-label label-select">Select to complete</label>
                                <select
                                    className = "form-control pr-4"
                                    id        = {id}
                                    value     = {answer.id_resposta || ""}
                                    onChange  = {onChange}
                                    data-answerIndex = {answerIndex}>
                                    <option />
                                    {
                                        exerciseAnswers
                                        .map(({id_resposta, resposta}:any, key:number) => 
                                            <option key={`option-${key}`} value={id_resposta}>{resposta}</option>)
                                    }
                                </select>
                            </div>
                            <span className="text-danger">{errorMessage[index]}</span>
                        </div>
                    }
                </ExerciseDialogueStyle>
            </ExerciseRowStyle> 
}


export default ExerciseRow
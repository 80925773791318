const isToday = (someDate: Date) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}


const formatDate = (date: string, convertToHour = true) => {
    const receivedDate = new Date(date);
    if (date && !convertToHour) return ('0' + receivedDate.getDate()).slice(-2) + '/' + ('0' + (receivedDate.getMonth() + 1)).slice(-2) + '/' + receivedDate.getFullYear();
    if (date) {
        if (isToday(receivedDate)) {
            return `${receivedDate.getHours()}:${receivedDate.getMinutes() < 10 ? '0' : ''}${receivedDate.getMinutes()}`
        } else {
            return ('0' + receivedDate.getDate()).slice(-2) + '/' + ('0' + (receivedDate.getMonth() + 1)).slice(-2) + '/' + receivedDate.getFullYear();
        }
    }
}

export default formatDate
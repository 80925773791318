import styled from "styled-components"
import { Row } from "reactstrap"

//TODO ExerciseTimelineStyle e ExerciseTimeline2Style muito parecido
const ExerciseTimeline2Style = styled(Row)`
    background-color: ${props => props.theme.main.mainPurple};
    border-bottom: 1px solid ${props => props.theme.main.borderLightGray};;
    height: 3px;

    .exercise-task {
        background-color: ${props => props.theme.main.backgroundRadioActive};
        border: 1px solid ${props => props.theme.main.borderLightGray};
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        border-radius: 50px;
        height: 20px;
        padding: 4px;
        transform: translateY(-50%);
        width: 20px;

        img {
            filter: brightness(0.6);
            align-items: center;
            display: flex;
            justify-content: center;
            width: 10px;
        }

        &.active {
            background-color: ${props => props.theme.main.mainPurple};
            border: 1px solid ${props => props.theme.main.borderLightGray};

            img {
                filter: none;
            }
        }

        &.checked {
            background-color: ${props => props.theme.main.clickable};
            border: 1px solid ${props => props.theme.main.clickable};
        }
    }
`

export default ExerciseTimeline2Style
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from "styled-components"
import { Row, Col } from "reactstrap"
import { ButtonStyle, LinkStyle } from "YConnect/Components/Button"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    '/public/pdfWorker.js',
    import.meta.url,
  ).toString();

const ButtonStyleNew = styled(ButtonStyle)`
    width: 100%
    place-content: center;
    max-width: 9rem;
    flex-direction: column;

    @media (min-width: 768px) {
        //width: 60%
        width: 25%
    }

    //font-size: 1rem !important;

    &.rotate{
        transform: rotate(180deg);
    }

    img{
        padding: 0px  !important;
    }
`
const CurrentPage = styled.p`
    // font-size: 1rem !important;
    text-align: -webkit-center;
    top: 10px;
    position: relative;
`


const ContainerStyle = styled.div`

    .react-pdf__Page__canvas {
        /* margin: 0 50%; */
    }
    .react-pdf__Page__svg {
        width: -webkit-fill-available !important;
        height: auto !important;
        overflow-x: overlay !important;
    }
    .react-pdf__Page__textContent{
        display:none;
    }

    .react-pdf__message,
    .react-pdf__message--error{
        font-size: 1rem;
    }

    .annotationLayer {
        display: none;
    }
`

const ResizedFont = styled.div`
    * {
        font-size: 1px;
    }

    g > path:nth-child(1)
    {
        fill-opacity: 1;
    }
`

const CentralizedPDF = styled.div`
    * {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
`

function PDFViwer(props: any) {
    const [numPages, setNumPages] = useState<number>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageDown, setPageDown] = useState<boolean>(false);
    const [scaleController, setScaleController] = useState<number>(1.05);
    const [canvasPDF, setCanvasPDF] = useState();
    const [convertedHeight, setConvertedHeight] = useState();
    const [convertedWidth, setConvertedWidth] = useState();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setCanvasPDF(document.getElementsByClassName('react-pdf__Page__canvas'))
    }

    useEffect(() => {
        setTimeout(() => {
            if (canvasPDF && canvasPDF.length > 0) {
                setConvertedHeight(parseInt(canvasPDF[0]?.style.height, 10))
                setConvertedWidth(parseInt(canvasPDF[0]?.style.width, 10))

                if (convertedWidth > 1000) {
                    setConvertedWidth(1052)
                }
            }
        }, 100);
    }, [numPages, pageNumber])

    function loadResolutionPDF () {
        canvasPDF[0].style.height = (`${convertedHeight.toString()+'px'}`)
        canvasPDF[0].style.width = (`${convertedWidth.toString()+'px'}`)
    }

    useEffect(() => {
        if (convertedWidth > 1000) {
            canvasPDF[0].style.width = '1052px'
        }
    }, [convertedWidth, pageNumber])

    function setPage(showZoom = false) {
        return (
            numPages != null
                ? <>
                    <Row className="mt-3 mb-3" >
                        <Col md={4} xs={3} style={{ textAlign: '-webkit-right' }}>
                            {pageNumber > 1
                                ? <ButtonStyleNew
                                    icon="arrowRightGreen"
                                    onClick={() => {
                                        setTimeout(() => {
                                            setPageDown(true)
                                        }, 3000);
                                        setTimeout(() => {
                                            loadResolutionPDF();
                                        }, 100);
                                        setPageDown(false);
                                        setPageNumber(pageNumber - 1);
                                    }}
                                    size="xlarge"
                                    className="rotate" />
                                : null
                            }
                        </Col>
                        <Col md={4} xs={6}>
                            <CurrentPage >Page {pageNumber} of {numPages}</CurrentPage>
                        </Col>
                        <Col md={4} xs={3} style={{ textAlign: '-webkit-left', fontSize: '1rem !important' }}>
                            {pageNumber < numPages
                                ? <ButtonStyleNew
                                    icon="arrowRightGreen"
                                    onClick={() => {
                                        setTimeout(() => {
                                            setPageDown(true)
                                        }, 3000);
                                        setTimeout(() => {
                                            loadResolutionPDF();
                                        }, 100);
                                        setPageDown(false);
                                        setPageNumber(pageNumber + 1);
                                }}
                                    size="xlarge"
                                />
                                : null
                            }
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3" style={{ textAlign: '-webkit-center' }} >
                        {
                            window.innerWidth > 768 && showZoom
                                ? <>
                                    <Col sm="2" md={{ size: 1, offset: 5 }}>
                                        <ButtonStyleNew
                                            label="-"
                                            disabled={convertedHeight < 191 && convertedWidth < 117}
                                            onClick={() => {
                                                canvasPDF[0].style.height = (`${(convertedHeight - 50).toString()+'px'}`)
                                                canvasPDF[0].style.width = (`${(convertedWidth - 50).toString()+'px'}`)

                                                setConvertedHeight(parseInt(canvasPDF[0].style.height, 10))
                                                setConvertedWidth(parseInt(canvasPDF[0].style.width, 10))
                                            }}
                                            size="xlarge"
                                        />
                                    </Col>
                                    <Col sm="2" md={{ size: 1, offset: 0 }}>
                                        <ButtonStyleNew
                                            disabled={convertedHeight > 829 && convertedWidth > 1052}
                                            icon="plusGreen"

                                            onClick={() => {
                                                canvasPDF[0].style.height = (`${(convertedHeight + 50).toString()+'px'}`)
                                                canvasPDF[0].style.width = (`${(convertedWidth + 50).toString()+'px'}`)

                                                setConvertedHeight(parseInt(canvasPDF[0].style.height, 10))
                                                setConvertedWidth(parseInt(canvasPDF[0].style.width, 10))

                                            }}
                                            size="xlarge"
                                        />
                                    </Col>
                                </>
                                : null
                        }
                    </Row>
                </>
                : null
        )
    }

    return (
        <ContainerStyle className="w-100">
            <div style={{ marginBottom: '3rem' }}>
                {setPage(true)}
                <ResizedFont>
                    <Document
                        file={props.file}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <CentralizedPDF>
                            <Page
                                scale={screen.width > 768 ? scaleController : 0.5}//1.76 página de pé // 0.86 página deitada
                                // renderMode="svg"
                                pageNumber={pageNumber}
                            />
                        </CentralizedPDF>
                    </Document>
                </ResizedFont>
                {pageDown && screen.width > 768 ? setPage() : null}
            </div >
        </ContainerStyle >
    );
}

export default PDFViwer;

import * as React from "react"
import {useContext, useEffect} from "react"
import { Container, Row } from "reactstrap"
import TabStyle from "YConnect/Styles/Tab.style"
import { BreadcrumbTechnicalSupport } from "YConnect/Components/BreadCrumbList"
import PageTitle   from "YConnect/Components/PageTitle"
import UserContext from "YConnect/User.context"
import UnitQuestion from "YConnect/PagesComponents/TechnicalSupport/UnitQuestion";
import { Card, Col, Tab, Tabs } from "react-bootstrap";

import TechnicalSupportTemplate from "YConnect/Configs/Templates/TechnicalSupport.template.json";


const TechnicalSupportPage = () => {
    const userContext: any = useContext(UserContext)

    const isInstructor = () => {
        return userContext.profiles.indexOf(15) > -1 || userContext.profiles.indexOf(12) > -1;
    }

    return <Container>
           <BreadcrumbTechnicalSupport/>
           <PageTitle label="Technical Support" />
           <Row className="mt-5">
                <Col md={12}>
                    <Card className="shadow p-0 pb-3">
                        {
                            !isInstructor() ?
                            <>
                                {
                                    TechnicalSupportTemplate.yconnect_student.map((question: any, key: any) => {
                                        return <UnitQuestion title={question.title} content={question.content} key={key}></UnitQuestion>
                                    }
                                )}
                            </>
                            :
                            <>
                                <TabStyle defaultActiveKey="yconnect-questions"
                                    onSelect={(eventKey: string) => { }} className="ml-0">

                                    {userContext.profiles.indexOf(6) > -1 && <Tab eventKey="howtouse-questions" title="Admin" className="px-md-4 mt-5">
                                    {
                                        TechnicalSupportTemplate.admin.map((question: any, key: any) => {
                                            return <UnitQuestion title={question.title} content={question.content}></UnitQuestion>
                                        })
                                    }
                                    </Tab>}
                                    <Tab eventKey="yconnect-questions" title="YConnect" className="px-md-4 mt-5">
                                    {
                                        TechnicalSupportTemplate.yconnect.map((question: any, key: any) => {
                                            return <UnitQuestion title={question.title} content={question.content}></UnitQuestion>
                                        })
                                    }
                                    </Tab>
                                    <Tab eventKey="placement-test-questions" title="Placement Test" className="px-4 mt-5">
                                    {
                                        TechnicalSupportTemplate.placement_test.map((question: any, key: any) => {
                                            return <UnitQuestion title={question.title} content={question.content}></UnitQuestion>
                                        })
                                    }
                                    </Tab>
                                    <Tab eventKey="sponte-questions" title="Sponte" className="px-4 mt-5">
                                    {
                                        TechnicalSupportTemplate.sponte.map((question: any, key: any) => {
                                            return <UnitQuestion title={question.title} content={question.content}></UnitQuestion>
                                        })
                                    }
                                    </Tab>
                            </TabStyle>
                            </>
                        }

                    </Card>
                </Col>
           </Row>

    </Container>
}

export default TechnicalSupportPage

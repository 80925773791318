import styled from "styled-components"

import * as checked  from "YConnect/Assets/svg/checked.svg"
import * as remove   from "YConnect/Assets/svg/close-btn.svg"

const Div2Style = styled.div`
    cursor: pointer;
    padding: 4px;
    background: #e6ecef;
    margin: 1%;
    width: 31%;
    position: relative;
    height: fit-content;

    img {
        width: 100%;
        height: auto;
    }

    &.item-active:before {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.9;
        background: ${props => props.theme.main.lightGreen};
    }

    &.item-active:after {
        font-size: 3.45em;
        font-weight: bold;
        color: #FFF;
        display: block;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -20px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
    }

    &.wrong:before {
        background: ${props => props.theme.main.errorColor};
        opacity: 0.9;
    }

    &.wrong:after {
        content: url(${remove});
        filter: ${props => props.theme.main.brightness};
        top: 35%;

        @media screen and (min-width: 768px) {
            transform: scale(3);
            top: 30%;
        }
    }

    &.correct:before {
        background: ${props => props.theme.main.successColor};
        opacity: 0.9;
    }

    &.correct:after {
        content: url(${checked});
        filter: ${props => props.theme.main.brightness};
        top: 35%;

        @media screen and (min-width: 768px) {
            transform: scale(3);
            top: 30%;
        }
    }
`
export default Div2Style
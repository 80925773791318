
import * as React             from "react"

import Dashboard   from "YConnect/PagesComponents/Dashboard/Dashboard"

const DashboardPage = ({
    history,
    onCloseModalFirstAccess,
    backpacks,
    onUpdatePrePost
}: any) => <Dashboard 
                history                 = {history}
                backpacks               = {backpacks}
                onCloseModalFirstAccess = {onCloseModalFirstAccess} 
                onUpdatePrePost         = {onUpdatePrePost}/>


export default DashboardPage




import * as React from "react"
import ExerciseLineStyled from "YConnect/Modals/TaskStarterInfo.modal/ExerciseLine.styled"

const InteractionStudentPresence = ({
    listaAlunoSecao,
    onPresenceStudent,
    contrast
}: any) =>
    <div className={`scroll-y`}>
        {
            listaAlunoSecao.map((aluno: any, key: any) =>
                <div key={key}>
                    <ExerciseLineStyled className={`line-${key} d-flex flex-row-nowrap justify-content-between`}>
                    <div className="student-name">
                        <span title={`${aluno.nome} ${aluno.sobrenome}`}>{`${aluno.nome} ${aluno.sobrenome}`}</span>
                    </div>
                    <button
                            className={`btn btn-success ${contrast}`}
                            onClick={() => onPresenceStudent(aluno.id)}>
                            {" "}Attendance confirmed
                    </button>
                    </ExerciseLineStyled>
                    <hr />
                </div>
            )
        }
    </div>

export default InteractionStudentPresence
import * as React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import ReactPlayer from "react-player"


const ReactPlayerStyled = styled(ReactPlayer)`
   
   height: auto !important;
   width: auto !important;

   iframe {
        display: block;
        margin: 0 auto ;
        min-width: 640px !important;
        min-height: 360px !important;
        width: 100% !important;
        @media (max-width: 768px) {
            //width: calc(100vw - 32px) !important;
            min-width: 80vw !important;
            min-height: 50vh !important;
        }
   }
`

const RowGreyStyled = styled(Row)`
    background-color: #f0f5f6;
`

const ColAudioStyled =  styled(Col)`
    background-color: #fff;
`

type VideoViewMainProps = {
    medias:Array<ExerciseMediaType>
}

const VideoViewMain = ({
    medias
}:VideoViewMainProps) => {

    const getMainExerciseMedia = () =>
        medias.find(({Order}) => Order === 0)

    return <>
    {
        getMainExerciseMedia()
        && getMainExerciseMedia().MediaType.toLowerCase() === "video"
        && <RowGreyStyled className="justify-content-center">
                <Col md={8} className="my-5">
                    <ReactPlayerStyled url={`${getMainExerciseMedia().Source}?autopause=0`} controls={true} />
                </Col>
            </RowGreyStyled>
    }
    </>
}

export default VideoViewMain
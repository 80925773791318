import * as React               from "react"
import { useState, useEffect }  from "react"
import qs                       from "querystring"
import {Button}                 from "reactstrap"
import styled                   from "styled-components"

import * as logo        from "YConnect/Assets/png/yconnect-logo-purple.png"
import * as yazigiLogo  from "YConnect/Assets/png/logo-yazigi-purple.png"
import * as GSE         from "YConnect/Assets/png/logo-GSE.png"


import API              from "YConnect/API"


const ButtonStyle = styled(Button)`
	margin-bottom: 10px;
	background: #79b100;
    border: 2px solid #79b100;
    border-radius: 50px;
`

const ConfirmEmailPage = ({location}: any) => {

    const [loading, setLoading] = useState()
    const [message, setMessage] = useState()

    useEffect(() => {
        const {
            token,
            idUsuario
        } = qs.parse(location.search.substr(1))

        setLoading(true)

        API
        .ConfirmarEmail
        .Put({idUsuario})
        .then(({data}:any) => setMessage("Endereço de e-mail confirmado com sucesso."))
        .catch((err: any) => setMessage("Erro ao tentar confirmar endereço de e-mail."))
        .finally(() => setLoading(false))

    }, [location])



    return <main 
                className = "container-fluid d-flex flex-column flex-wrap justify-content-between align-items-center m-0 p-0" 
                style     = {{height: "100vh"}}>
                <section className="d-flex flex-column flex-wrap align-items-center justify-content-around h-100">
                    <header>
                        <img 
                            src   = {logo} 
                            alt   = "YConnect logo" 
                            width = "200" />
                    </header>
                    <div className="mt-5 w-70 text-center">
                        {message && <h1>{message}</h1>}
                        {loading && <div className="loader"></div>}
                       

                    </div>
                    {message && <div className="mb-5 mt-5 w-70 text-center">
                        <ButtonStyle onClick={() => window.location.href = "#/login"}>
							<strong>Acessar YConnect</strong>
						</ButtonStyle>
                    </div>}
                    <footer>
                        <img
                            src       = {yazigiLogo} 
                            alt       = "Global Scale of English Logo" 
                            className = "pr-5"
                            height    = "35px"/>
                        <img
                            src     = {GSE}
                            alt     = "Yazigi Logo"
                            height  = "55px"/>
                    </footer>
                </section>
            </main>
}

export default ConfirmEmailPage
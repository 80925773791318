const formatText = (text: string) => {  // Format the text to include bold
    let i = 0;
    if (text && text.indexOf("src=\"img/") == -1) {
        text = text.replaceAll('___','®®®')
        do {
            if (i % 2 == 0) { // start
                text = text.replace("**", "<strong>");  // bold
                text = text.replace("_", "<em>");       // italic
                text = text.replace("++", "<u>");       // italic
            } else {          // end
                text = text.replace("**", "</strong>"); // bold
                text = text.replace("_", "</em>");      // italic
                text = text.replace("++", "</u>");      // italic
            }
            i++;
        } while ((text.indexOf("**") !== -1) || (text.indexOf("_") !== -1) || (text.indexOf("++") !== -1))
        text = text.replaceAll('®®®','___',)
    }

    return text;
}

export default formatText

import * as React     from "react"
import { withRouter } from "react-router"
import { FormGroup }  from "react-bootstrap"
import {
    useContext,
    useState,
    useEffect
}                     from "react"

import * as user          from "YConnect/Assets/svg/user.svg"
import * as arrowNext     from "YConnect/Assets/svg/arrow-next.svg"
import * as arrowPrevious from "YConnect/Assets/svg/arrow-previous.svg"

import API             from "YConnect/API"
import UserContext     from "YConnect/User.context"
import Loader          from "YConnect/Components/Loader"
import { MessageLine } from "YConnect/Styles/MessageLine.style"
import formatDate      from "YConnect/Utils/FormatDate.util"
import convertPhoto    from "YConnect/Utils/ConvertPhoto.util"
import getConfig       from "YConnect/Utils/GetRequestConfig.util"

const renderMessageCounter = (isRead: string) => {
    if (isRead !== 'S') {
        return <span className="message-notification align-items-end badge badge-pill badge-warning"> </span>
    }
    return
}

const getAmountPages = (amountUsers:number)=>{
    const AMOUNT_MESSAGES_LAST_PAGE = amountUsers % 10
    return (amountUsers-AMOUNT_MESSAGES_LAST_PAGE) / 10 + (AMOUNT_MESSAGES_LAST_PAGE > 0 ? 1 : 0)
}

const InboxTab = (props: any) => {

    const userContext: any = useContext(UserContext)

    const [inboxMessages, setInboxMessages]       = useState([{}]);
    const [totalCount, setTotalCount]             = useState(0);
    const [amountPages, setAmountPages]           = useState(1)
    const [isLoading, setLoading]                 = useState(false);
    const [currentPage, setCurrentPage]           = useState(1)

    const hasPrevious   = currentPage > 1
    const hasNext       = currentPage < amountPages
    const startMessages = currentPage === 1 ? 1 : 10 * (currentPage - 1) + 1;
    const endMessages   = currentPage === 1 ? currentPage + 9 : 10 * (currentPage - 1) + 10;

    useEffect(() => {
        setLoading(true);
        API.Note.Get2({
            id: userContext.idUsuario,
            tipo: 'R',
            pageNumber: currentPage,
            qntdItens: 10,
            dashboard: false
        }, getConfig())
            .then((res: any) => {
                let messages = JSON.parse(JSON.stringify(res));
                messages.data.listaMensagens.forEach((element: any) => {
                    element['isChecked'] = false;
                });
                setInboxMessages(messages.data.listaMensagens);
                setAmountPages(getAmountPages(messages.data.qtdMensagens))
                setTotalCount(messages.data.qtdMensagens);
            })
            .catch((err: any) => { })
            .finally(() => setLoading(false))
    }, [currentPage]);

    const redirectMessagePage = (message: any) => {
        props.history.push({
            pathname: `/my-message/${message.id_notes}`,
            state: {
                message,
                isAllMessage: false,
                selectedTab: 'inboxTab'
            }
        });
    }

    const selectPage = (e: any) => setCurrentPage(parseInt(e.target.value, 10));

    const handleNextPage     = () => hasNext && setCurrentPage(currentPage+1);
    const handlePreviousPage = () => hasPrevious && setCurrentPage(currentPage-1);


    const renderByViewport = () => {
        if (!inboxMessages || !inboxMessages.length) {
            return <p className="text-center">No messages</p>
        }
        return (
            props.pageWidth > 1024 ?
                inboxMessages.map(
                    (message: any, key: any) => {
                        return (
                            <MessageLine
                                key={key}
                                className={`message ${message.isChecked ? 'active': ''} ${message.status_lido === 'S' ? '' : 'newMessage'}`}
                                >
                                <div
                                    className={`align-items-center d-flex flex-row justify-content-around w-100 pointer`}
                                    onClick={() => redirectMessagePage(message)}
                                >
                                    <header className="message-header align-items-center d-flex flex-row justify-content-start">
                                        <figure className="m-0">
                                            <img src={convertPhoto(message.foto ? atob(message.foto) : user)} className="message-photo" />
                                            {renderMessageCounter(message.status_lido)}
                                        </figure>
                                        <span className="message-author" style={{ marginLeft: message.isRead !== 'S' ? '20px' : '0' }}>
                                            <b>
                                                {message.nome_remetente}
                                            </b>
                                        </span>
                                    </header>
                                    <span className="message-subject">
                                        {message.assunto}:
                                </span>
                                    <div className="message-body" dangerouslySetInnerHTML={{__html: message.anotacao}}>
                                    </div>
                                    <span className="message-time">
                                        {formatDate(message.data)}
                                    </span>
                                </div>
                            </MessageLine>
                        )
                    }
                )
                :
                inboxMessages.map(
                    (message: any, key: any) => {
                        return (
                            <div key={key} onClick={() => redirectMessagePage(message)}>
                                <MessageLine className={`message ${message.status_lido === 'S' ? '' : 'newMessage'}`}>
                                    <header className="message-header">
                                        <figure className="m-0">
                                            <img src={convertPhoto(message.foto ? atob(message.foto) : user)} className="message-photo" />
                                            {renderMessageCounter(message.status_lido)}
                                        </figure>
                                    </header>
                                    <div className="message-mobile-body">
                                        <div className="mobile-body-header">
                                            <span className="message-author">
                                                {message.nome_remetente}
                                            </span>
                                            <span className="message-time">
                                                {formatDate(message.data)}
                                            </span>
                                        </div>
                                        <span className="message-subject">
                                            {message.assunto}:
                                        </span>
                                        <div className="mobile-body-message">
                                            {message.contentWithoutHtml && message.contentWithoutHtml.length > 100
                                                ?`${message.contentWithoutHtml.slice(0,100)}...`
                                                : message.contentWithoutHtml
                                            }
                                        </div>

                                    </div>
                                </MessageLine>
                            </div>
                        )
                    }
                )

        )
    }

    return (
        <div className="inbox">
            {isLoading && <Loader />}

            <header className="inbox-header">
                <div className="email-input">
                </div>
                <div className="email-filter">
                    {(inboxMessages || inboxMessages.length) && <div className="pagination align-items-center d-none d-lg-flex flex-row flex-wrap justify-content-end">
                        <img
                            src={arrowPrevious}
                            className="pr-4"
                            style={{
                                cursor: hasPrevious ? 'pointer' : 'not-allowed',
                                filter: hasPrevious ? 'grayscale(0)' : 'grayscale(100%)'
                            }}
                            onClick={() => handlePreviousPage()}
                        />
                        <span>
                            Showing <b>{startMessages} - {hasNext?endMessages:totalCount} </b> of {totalCount}
                        </span>
                        <img
                            src={arrowNext}
                            className="pl-4"
                            style={{
                                cursor: hasNext ? 'pointer' : 'not-allowed',
                                filter: hasNext ? 'grayscale(0)' : 'grayscale(100%)'
                            }}
                            onClick={() => handleNextPage()}
                        />
                    </div>}
                </div>
            </header>

            <div className="messages pl-1">
                {renderByViewport()}

                {(inboxMessages || inboxMessages.length) && <div className="pagination-footer">
                    <span>Go to page</span>
                    <FormGroup className="select-page">
                        <label className="form-label text-clickable label-select">Select page</label>
                        <select className="form-control" value={currentPage} onChange={selectPage} id="js-selectPageList">
                            {Array.from(Array(amountPages).keys()).map((key) => <option key={key + 1}>{key + 1}</option>)}
                        </select>
                    </FormGroup>
                </div>}
            </div>
        </div>
    )
}

export default withRouter(InboxTab)

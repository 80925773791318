import * as React from "react";
import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import UserContext from "YConnect/User.context";

import TaskStarter from "./TaskStarter";

import TaskStarterInfo from "../../Modals/TaskStarterInfo.modal";

import * as lock from "YConnect/Assets/png/Group 105@2x.png";

const BookStyle = styled.div`
    .book-header {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .lock {
            cursor: default;
            width: 1.5rem;
            height: 2rem;
        }

        @media screen and (min-width: 1024px) {
            .lock {
                    width: 2rem;
            }
        }

        h2 {
            width: 50%;
            @media screen and (min-width: 1024px) {
                width: 40%;
            }

            .subtitle {
                font-size: small;
            }
        }
    }

    .header-filters {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        width: 50%;

        @media screen and (min-width: 1024px) {
            justify-content: space-around;
            width: 60%;
        }

        .form-group {
            width: 25%;
        }
    }
`;

const TasksStyle = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 44px;

    &.hide {
        display: none;
    }

    .task-card {
        padding: 0;
        margin-bottom: 0;
        margin-top: 44px;
        height: 148px;
    }

    .task-link {
        border-bottom: ${(props) => props.theme.main.clickable} solid 3px;
        border-radius: 8px;
        color: ${(props) => props.theme.main.textColor};
        margin: 16px 8px 0;
        text-decoration: none;
        width: 240px;
        min-height: 195px;
        height: 100% !important;
        cursor: pointer;
        /*width: 25%;*/
        &.done {
            border-bottom: ${(props) => props.theme.main.greentextColor} solid
                3px !important;
        }
        &.presential {
            border-bottom: ${(props) => props.theme.main.textColor} solid 3px !important;
        }
        &.to-do {
            border-bottom: ${(props) => props.theme.main.lightPurple} solid 3px !important;
        }

        &.blocked {
            border-bottom: ${(props) => props.theme.main.textBlocked} solid 3px !important;
            cursor: not-allowed;
        }

        @media screen and (min-width: 460px) and (max-width: 990px) {
            margin: 16px 8px 0;
            top: 10px;
            /*width: 240px;*/
            width: 29%;
        }

        @media screen and (max-width: 460px) {
            margin: 16px 8px 0;
            top: 10px;
            /*width: 240px;*/
            width: 44%;
        }

        @media screen and (max-width: 300px) {
            top: 10px;
            /*width: 240px;*/
            width: 100%;
        }
    }

    .task-card-header {
        .header-icon {
            align-items: center;
            box-shadow: 3px 2px 6px 0 rgba(0, 0, 0, 0.23);
            border-radius: 50%;
            color: ${(props) => props.theme.main.textWhite};
            display: flex;
            flex-flow: column wrap;
            height: 55px;
            justify-content: center;
            padding: 12px;
            text-align: center;
            transform: translateY(-50%);
            width: 55px;

            &.to-do {
                background-color: ${(props) => props.theme.main.lightPurpleInvert};
            }

            &.done {
                background-color: ${(props) => props.theme.main.successColor};
            }

            .task-card-img {
                height: 25px;
                width: 25px;
                filter: ${(props) => props.theme.main.brightness};
            }
        }
    }

    .task-card-body {
        text-align: center;

        .subtitle {
            font-size: 0.75rem;
            position: relative;
            top: -8px;
        }

        .title {
            display: block;
            font-size: 1rem;
            font-weight: bold;
            text-align: center;

            @media screen and (min-width: 1024px) {
                font-size: 1.25rem;
            }
        }
    }

    .task-card-footer {
        padding: 8px 0 16px;
    }
`;

const BookTasks = ({
    courseId,
    bookTasks,
    unitsFilter,
    fetchUnitContent,
    unitId,
    sections,
    isBlocked
}: any) => {
    const [isLoading, setLoading] = useState(false);
    const [selectedBookTasks, setSelectedBookTasks] = useState();
    const [filterSection, setFilterSection] = useState();
    const [selectedSection, setSelectedSection] = useState();

    const [selectedStatus, setSelectedStatus] = useState("all");
    const [filteredByStatus, setFilteredByStatus] = useState();

    const [filterUnit, setUnitFilter] = useState(unitsFilter[0].id_unidade);
    const [filterMenu, setFilterMenu] = useState(false);
    const [secaoSelecionada, setSecaoSelecionada] = useState(null);

    const [
        listaExerciciosSelecionados,
        setlistaExerciciosSelecionados,
    ] = useState(false);

    const [activeTaskStarterInfo, showactiveTaskStarterInfo] = useState(false);

    const {
        idProfessor,
        observador,
        colaborador,
        usuarioTrial,
        usuarioDegustacao,
        idCursoAtual,
    } = useContext(UserContext);

    const isStudent = () => {
        return (
            idProfessor == 0 &&
            !observador &&
            !colaborador &&
            !usuarioTrial &&
            !usuarioDegustacao
        );
    };

    const handleClick = (secao: any) => {
        setlistaExerciciosSelecionados(
            bookTasks.filter(
                (exercicio: any) => exercicio.nome_secao === secao.nome
            )
        );
        setSecaoSelecionada(secao);
        showactiveTaskStarterInfo(true);
    };

    useEffect(() => {
        fetchBooksTasks();
    }, []);

    useEffect(() => {
        fetchBooksTasks();
    }, [bookTasks]);

    const fetchBooksTasks = () => {
        const uniqueSections = bookTasks.reduce((unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.nome_secao === o.nome_secao)) {
                unique.push(o);
            }
            return unique;
        }, []);
        setSelectedSection("all");
        setSelectedBookTasks(bookTasks);
        setFilteredByStatus(bookTasks);
        setFilterSection(uniqueSections);
    };

    return (
        <BookStyle className={`col-12 book`}>
            <div
                className={`${window.innerWidth > 1024 ? "card shadow " : ""}`}
            >
                <header className ={`book-header`}>
                    <h2>Starter Classes</h2>
                    { isBlocked && <img src={lock} className ={`lock`} /> }
                </header>
                {isLoading ? (
                    <p>Loading...</p>
                ) : isBlocked ? (
                    <span className="font-weight-bold text-blocked">
                        {" "}To unlock this course, you will need to complete the previous course.{" "}
                    </span>
                ) : (
                        <TasksStyle className="tasks">
                            {sections
                                ? sections.map((secao: any, key: any) => {
                                    return (
                                        <div
                                            className={`task-link ${
                                                isStudent()
                                                    ? secao.bloqueado
                                                        ? "blocked"
                                                        : secao.objetivo_linguistico.toUpperCase() ===
                                                            "INTERACTION_CLASS"
                                                            ? "presential"
                                                            : secao.porcentagem_secao ==
                                                                100
                                                                ? "done"
                                                                : "to-do"
                                                    : secao.objetivo_linguistico.toUpperCase() ===
                                                        "INTERACTION_CLASS"
                                                        ? "presential"
                                                        : "to-do"
                                                }`}
                                            key={key}
                                            onClick={() => {
                                                isStudent()
                                                    ? secao.bloqueado
                                                        ? ""
                                                        : handleClick(secao)
                                                    : handleClick(secao);
                                            }}
                                        >
                                            {
                                                <TaskStarter
                                                    task={secao}
                                                    unit={unitId}
                                                />
                                            }
                                        </div>
                                    );
                                })
                                : null}
                        </TasksStyle>
                    )}
            </div>
            {activeTaskStarterInfo && (
                <TaskStarterInfo
                    exercicios={listaExerciciosSelecionados}
                    taskStarterInfoAtivo={activeTaskStarterInfo}
                    onClose={showactiveTaskStarterInfo}
                    filterUnit={filterUnit}
                    secao={secaoSelecionada}
                    courseId={courseId}
                />
            )}
        </BookStyle>
    );
};

export default BookTasks;

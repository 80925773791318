import * as React from "react"

import PanelCrud from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"

import {
    GetAll, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/Level.resources"

import CONFIG_TABLE_LEVEL from "YConnect/PagesComponents/ControlPanel/Tables/Level.table.config"
import LEVEL_TEMPLATE from "YConnect/PagesComponents/ControlPanel/Templates/Level.template"

const LevelsPanel = () =>{

    return <PanelCrud
                idName            = "levelId"
                labelButtonCreate = "Create Level"
                labelForm         = "Level"
                template          = {LEVEL_TEMPLATE}
                configTable       = {CONFIG_TABLE_LEVEL}
                GetAll            = {GetAll}
                GetById           = {GetById}
                Create            = {Create}
                UpdateById        = {UpdateById}
                DeleteById      = {DeleteById} />
}

export default LevelsPanel
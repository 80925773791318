import * as React          from 'react'
import { useEffect, useRef } from 'react'

export default function MapComponent({ options, onMount, className }: any) {
    const divProps = { ref: useRef(), className }

    useEffect(() => {
        const onLoad = () => {
            const map = new window.google.maps.Map(divProps.ref.current, options)
            onMount && onMount(map)
        }
        if (!window.google) {
            const script = document.createElement(`script`)
            script.type = `text/javascript`
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAgKcyBuH0kOsaYa_N16d6CfQyCB0Xg0CE&language=en`
            const headScript = document.getElementsByTagName(`script`)[0]
            headScript.parentNode.insertBefore(script, headScript)
            script.addEventListener(`load`, onLoad)
            return () => script.removeEventListener(`load`, onLoad)
        } else onLoad()
    }, [divProps.ref, onMount, options])

    return (
        <div
            style={{height: '100%', margin: '1em 0'}}
            {...divProps}
        />
    )
}

MapComponent.defaultProps = {
    options: {
        streetViewControl: true,
        center: { lat: 48, lng: 8 },
        zoom: 5,
    },
}
import * as React     from "react"
import {useContext} from "react"
import { withRouter } from "react-router"

import UserContext    from "YConnect/User.context"

import BookCard       from "YConnect/Components/BookCard"
import PageTitle      from "YConnect/Components/PageTitle"
import { ButtonStyle} from "YConnect/Components/Button"

import { useState, useEffect } from "react"

import * as CourseResource  from "YConnect/ResourcesBackEnd/Course.resources"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"

// DONE TODO EDUARDO trocar ImagesBook para API.Book.GetCover({idCurso})

const StudentContent = ({history}:any) => {

    const { cursosVigentes, idCursoAtual }: any = useContext(UserContext)

    const cursoAtual = cursosVigentes.find(({id_curso}:any) => id_curso === idCursoAtual)

    const [bookCoverUrl, setBookCoverUrl] = useState("")

    useEffect(() => {
        fetchCapaDeLivro()
    }, [])

    const responseHandler = (response: any) => {
        const hasBookCover = response.data.capas[0] ? true : false
        if (hasBookCover) return response.data.capas[0].url_capa
        return ""
    }

    const fetchCapaDeLivro = () : void => {
        const idCourseExists = cursoAtual.id_curso ? true : false
        if(!idCourseExists) return null
        CourseResource
        .GetCover(cursoAtual.id_curso)
        .then((response:any) => {
            const capa_do_livro = responseHandler(response)
            const urlCoverBookCMS = getStaticUrl(capa_do_livro)
            setBookCoverUrl(urlCoverBookCMS)
        })
    }

    const redirectPage = (course: any) => {
        history.push({
            pathname: `/courses/${course.id_curso}/view-book`,
            state: {
                course
            }
        });
    }

    const isUnavailableCourse = (tag :String)   => tag[0] === "3";

    const renderViewPort = () => {

        if (cursoAtual.curso_corrente == 1) {
            if (window.innerWidth < 1024) {
                return (
                    <div className="col-12 courses card shadow mb-4">
                        <header className="courses-header d-flex flex-row justify-content-start">
                            <figure className="m-0">
                            {
                                cursoAtual
                                 && cursoAtual.id_curso
                                 && <img src={`${bookCoverUrl}`} />
                            }
                            </figure>
                            <div className="courses-header-title pl-3">
                                <span className="h5 font-weight-bold">
                                    {cursoAtual && cursoAtual.titulo_curso}
                                </span>
                            </div>
                        </header>
                        <div className="course-info mb-3 mt-3">
                            <ButtonStyle green
                                label     = "Go to your current course"
                                icon      = "arrowRight"
                                onClick   = {() => redirectPage(cursoAtual)}
                                size      = {'large'}
                            />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="col-12 courses courses-desktop card shadow mb-4">
                        <figure className="m-0">
                            {
                                cursoAtual
                                 && cursoAtual.id_curso
                                 && <img src={`${bookCoverUrl}`} />}
                        </figure>
                        <div className="courses-info">
                            <span className="h5 font-weight-bold w-40">
                                {cursoAtual && cursoAtual.titulo_curso}
                            </span>
                            <p className="course-description mt-4 font-weight-bold">
                               {cursoAtual && cursoAtual.descr_curso}
                            </p>
                            <ButtonStyle green
                                label     = "Go to your current course"
                                icon      = "arrowRight"
                                onClick   = {() => redirectPage(cursoAtual)}
                                size      = {'large'}
                            />
                        </div>

                    </div>
                )
            }
        }
    }

    return <>
            <section className="row m-0 mb-5">
                <PageTitle label={'Courses'} className={'p-0'} />
                {renderViewPort()}
            </section>
            <PageTitle label={'Your books'} className={'col-xs-12 col-md-6 mb-3 d-flex flex-row flex-wrap justify-content-between'}>
            </PageTitle>

            <section className="book-courses student-card lateral-scroll-books row m-0 mb-5">
                {
                    cursosVigentes.map((book:any, key: any) => {
                        return book.curso_corrente == 1 && <BookCard book={book} key={key} history={history}
                        isBlocked={false
                            // isUnavailableCourse(cursoAtual.id_curso !== book.id_curso && book.ordem_curso)
                        }
                        currentCourse = {cursoAtual.titulo_curso} />
                    })
                }
            </section>
        </>
}

export default withRouter(StudentContent)

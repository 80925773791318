import * as React from "react"


type SectionType = {
    keystone:string, 
    label:string, 
    path:string, 
    component?:any,
}

type ListSectionType = Array<SectionType>

type SidebarMenuProps = {
    panelNameSelected?:SectionType
    list:ListSectionType
}

const SidebarMenu = ({panelNameSelected, list}:SidebarMenuProps) =>
    <ul className="list-group list-group-flush">
        {
            list.map(({keystone, label, path}, key) => 
            <li 
                key={key} 
                style = {panelNameSelected && keystone === panelNameSelected.keystone ? {"backgroundColor" : "#e9ecef"} : {}}
                className="list-group-item">
                <a href={`#/control-panel${path}`}><strong>{label}</strong></a>
            </li>)
        }
    </ul>

export default SidebarMenu
import * as React from "react"
import { useRef } from "react"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components";

import AlphaBlackStyle from "YConnect/Styles/AlphaBlack.style"
import AccessibilityStyle from "YConnect/Styles/Accessibility.style"
import { LinkStyle } from "YConnect/Components/Button"

import useOutsideClick from "YConnect/Hooks/useOutsideClick"

const StyledWrapper = styled.div`
  .good-looking-modal {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    .good-looking-modal {
      width: 50%;
    }
  }
`

const GoodLookingModal = ({visible, onClose, title, children}:any) => {
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, onClose)

  
  return <>
          {visible && <AlphaBlackStyle/>}
          <CSSTransition classNames="dialog accessibility-menu" timeout={300} in={visible} unmountOnExit>
            <StyledWrapper>
              <AccessibilityStyle 
                  className="YC-lateral good-looking-modal lateral-header testimonial-modal d-flex d-lg-flex flex-column justify-content-start" 
                  ref={wrapperRef}
                  style={{zIndex: 9999, padding: "36px"}}>
                      <h4 className="align-items-center d-flex flex-row justify-content-between mb-4 good-looking-modal__header">
                          {title}
                          <LinkStyle label="Close x" onClick={onClose} className="close-modal text-right header-btn" />
                      </h4>
                      <div className="good-looking-modal__body">
                        {children}
                      </div>
              </AccessibilityStyle>
            </StyledWrapper>
          </CSSTransition>
        </>
}

export default GoodLookingModal
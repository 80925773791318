import styled from "styled-components"

const ButtonsBookStyle = styled.div `
    display: flex;
    justify-content: center;

    button {
        margin: 20px;
        padding: 15px;
    }
`

export default ButtonsBookStyle

const CONFIG_TABLE_LEARNING_OBJECTIVE:Array<ColumnConfigType> = [
    {
        keystone : "Objective",
        label    : "Objective"
    },
    {
        keystone : "Description",
        label    : "Description"
    }
]

export default CONFIG_TABLE_LEARNING_OBJECTIVE
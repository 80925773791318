import * as React                      from "react"
import { useEffect, useRef, useState } from "react"
import { CSSTransition }               from "react-transition-group"
import {Fragment}                      from "react"
import {Row, Col, Card}                from "reactstrap"


import AlphaBlackStyle    from "YConnect/Styles/AlphaBlack.style"
import AccessibilityStyle from "YConnect/Styles/Accessibility.style"
import { LinkStyle }      from "YConnect/Components/Button"

import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

const getDataFormat = (strDate:string) => {

    const date = new Date(strDate)
    const [
        year, month, day
    ] = [
        date.getFullYear(),
        date.getMonth()+1,
        date.getDate()
    ]

    return `${day<10?"0"+day:day}/${month<10?"0"+month:month}/${year}`
}

const HistoricoDeIntegracaoModal = ({hasShow, idAluno, onClose}:any) => {

    const [historyList, setHistoryList] = useState()

    useEffect(() => {
        API.HistoricoReintegracao.Get({id:idAluno}, getConfig())
        .then(({data}:any) => setHistoryList(data))
    }, [])

    useEffect(() => {
        if(hasShow){
            API.HistoricoReintegracao.Get({id:idAluno}, getConfig())
            .then(({data}:any) => setHistoryList(data))
        }
    }, [hasShow])

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose()
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside)
            return () => document.removeEventListener("mousedown", handleClickOutside)
        })
    }

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef)


    return <Fragment>
                {hasShow && <AlphaBlackStyle/>}
                <CSSTransition classNames="dialog accessibility-menu" timeout={300} in={hasShow} unmountOnExit>
                    <AccessibilityStyle 
                        className="YC-lateral lateral-header testimonial-modal d-flex d-lg-flex flex-column justify-content-start" 
                        ref={wrapperRef}
                        style={{zIndex: 9999, padding: "36px"}}>
        
                            <h4 className="align-items-center d-flex flex-row justify-content-between mb-4">
                                Histórico de Integração
                                <LinkStyle label="Close x" onClick={onClose} className="close-modal text-right header-btn" />
                            </h4>
                            {
                                historyList
                                && historyList.map(({data_historico, responsavel, curso, motivo}:any, key:number) => 
                                <Row key={key} className="mb-0 mr-0 justify-content-center">
                                    <Col md={12}>
                                        <Card>
                                            <strong>{getDataFormat(data_historico)}</strong>
                                            <span>Colaborador {responsavel} integrou o aluno no {curso}</span>
                                            <i>"{motivo}"</i>
                                        </Card>
                                    </Col>
                                </Row>)
                            }
                    </AccessibilityStyle>
                </CSSTransition>
            </Fragment>
}

export default HistoricoDeIntegracaoModal
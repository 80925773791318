import * as React               from "react"
import { useEffect, useState }  from "react"
import { Row, Col }             from "reactstrap"

import API                    from "YConnect/API"
import getRequestConfig       from "YConnect/Utils/GetRequestConfig.util"
import CardAverageResultScore from "YConnect/PagesComponents/PrePostReport/Components/CardAverageResultScore/index"

const AverageResultScore = ({
    semesterIdSelected,
    establishmentIdSelected,
    courseIdSelected,
    classIdSelected,
    teacherIdSelected,
    regionalIdSelected
}: any) => {

    useEffect(() => {

        if(semesterIdSelected && establishmentIdSelected){
            setResponse(undefined)
            API
            .PrePostReport
            .GetReport({
                idAnoSemestre : semesterIdSelected,
                nsuEstab      : establishmentIdSelected,
                idCurso       : courseIdSelected || "",
                idClasse      : classIdSelected || "",
                ...regionalIdSelected
                    ? {regional : regionalIdSelected}
                    : teacherIdSelected
                        ? {idProfessor   : teacherIdSelected || ""}
                        :{}
            }, getRequestConfig())
            .then(({data}:any) =>{
                if(data && data.scoreQuestions){
                    setResponse(data.scoreQuestions)
                }else{
                    setResponse(data)
                }
            })
        }
        
    }, [
        semesterIdSelected, 
        establishmentIdSelected,
        courseIdSelected,
        classIdSelected,
        teacherIdSelected
    ])

    const [response, setResponse] = useState()

    const { bestQuestion, worstQuestion} = response || {}

    const mediaPreTest  = (
        response 
        && response.medias 
        && response.medias.filter((media: any) => media.fl_is_pre === true)[0]) 
        || {}

    const mediaPostTest = (
        response 
        && response.medias 
        && response.medias.filter((media: any) => media.fl_is_pre === false)[0]) 
        || {}
    
    return <Row>
            {
                semesterIdSelected 
                && establishmentIdSelected
                &&
                <Col xs={12}>
                    <div className="card shadow d-flex flex-row flex-wrap justify-content-between">
                    <small className="w-100">*All comparisons are based on the data from last semester</small>

                    <CardAverageResultScore 
                            bestQuestion  = {bestQuestion}
                            worstQuestion = {worstQuestion}
                            {...mediaPreTest}/>
                    <CardAverageResultScore
                            isPost
                            bestQuestion  = {bestQuestion}
                            worstQuestion = {worstQuestion}
                            {...mediaPostTest}/>
                        
                    </div>
                </Col>
            }
        </Row>
}

export default AverageResultScore
import styled from "styled-components"

const RetractPlayerStyle = styled.section`
    align-items: center;
    background: ${props => props.theme.main.backgroundWhite};
    bottom: 0;
    color: ${props => props.theme.main.textColor};
    height: 56px;
    position: fixed;
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    left: 0;
    width: 100%;
    z-index: 99;

    @media screen and (min-width: 768px) {
        height: 80px;
    }

    @media screen and (min-width: 1024px) {
        border-radius: 8px 0 0 8px;
        flex-flow: row wrap;
        left: auto;
        right: 0;
        top: 100px;
        width: 480px;
    }

    .retract-arrow {
        background-color: ${props => props.theme.main.clickable};
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        height: 100%;
        width: 48px;
        transform: rotate(180deg);
        z-index: 10;

        @media screen and (min-width: 1024px) {
            transform: rotate(0deg);
            border-radius: 8px 0 0 8px;
            width: 30px;

            img {
                transform: rotate(90deg)
            }
        }

    }

    .retract-audio {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 85%;
        
        @media screen and (min-width: 1024px) {
            width: 90%;
        }

        .retract-header {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            width: 35%;

            @media screen and (min-width: 1024px) {
                width: 55%;
            }

            .marquee {
                padding: 0 16px;
                width: 100%;
            }   
            .marquee p {
                animation: marquee 15s linear infinite;
                display: inline-block;
                padding-left: 100%;
                white-space: nowrap;
                @media screen and (min-width: 1024px) {
                    animation: none;
                    padding-left: 0;
                }
            }


            p { 
                font-size: 0.875rem;
                margin: 0;
                @media screen and (min-width: 1024px) {
                    font-size: 1rem;
                }
            }

            .time {
                padding: 0 16px;
            }
        }

        .retract-options {
            background-color: ${props => props.theme.main.backgroundWhite};
            padding: 0 16px 0 0;
            width: 65%;
            z-index: 10;
            @media screen and (min-width: 1024px) {
                width: 40%;
            }
        }

        @keyframes marquee {
            0%   { transform: translate(0, 0); }
            100% { transform: translate(-110%, 0); }
        }
    }
`

export default RetractPlayerStyle
import * as React              from "react"
import { useState, useEffect } from "react"
import { Link }                from "react-router-dom"
import styled                  from "styled-components"

import Task from "./Task"

import ViewBookFilterModal from "./ViewBookFilter.modal";

import * as filter from "YConnect/Assets/svg/filter.svg"
import * as arrowLeft from "YConnect/Assets/svg/arrow-left.svg"
import * as arrowDown from "YConnect/Assets/svg/arrow-down.svg"
import * as lock from "YConnect/Assets/png/Group 105@2x.png";

const idsBooksUnitsNameFilter = [ "69", "70", "82", "83","85","86","87"];

const BookStyle = styled.div`
    &.hide {
        height: 4em;

        @media screen and (min-width: 1024px) {
            height: auto;
        }
    }
    .book-header {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;

        h2 {
            width: 40%;

            @media screen and (min-width: 1024px) {
                width: 25%;
            }

            .subtitle {
                font-size: small;
            }
        }

        .arrow {
            cursor: pointer;
            width: 1rem;
            height: auto;

            @media screen and (min-width: 1024px) {
                .arrow {
                    width: 2rem;
                }
            }
        }

        .lock {
            cursor: default;
            width: 1rem;
            height: auto;

            @media screen and (min-width: 1024px) {
                .lock {
                    width: 2rem;
                }
            }
        }
    }

    .header-filters {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        width: 40%;

        @media screen and (min-width: 1024px) {
            justify-content: space-around;
            width: 70%;
        }

        .form-group {
            width: 25%;
        }

        .task {
            width: 15%;
        }

        .unit {
            width: 41%;
        }

        &.hide {
            visibility: hidden;
        }
    }
`

const TasksStyle = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 44px;

    &.hide {
        display: none;
    }

    .task-card {
        padding: 0;
        margin-bottom: 0;
        margin-top: 44px;
    }

    .task-link {
        border-bottom: ${props => props.theme.main.clickable} solid 3px;
        border-radius: 8px;
        color: ${props => props.theme.main.textColor};
        margin: 16px 8px 0;
        text-decoration: none;
        width: 240px;

        @media screen and (min-width: 460px) and (max-width: 990px) {
            margin: 16px 8px 0;
            top: 10px;
            width: 29%
        }

        @media screen and (max-width: 460px) {
            margin: 16px 8px 0;
            top: 10px;
            /*width: 240px;*/
            width: 44%
        }

        @media screen and (max-width: 300px) {
            top: 10px;
            /*width: 240px;*/
            width: 100%
        }
    }

    .task-card-header {
        .header-icon {
            align-items: center;
            box-shadow: 3px 2px 6px 0 rgba(0, 0, 0, 0.23);
            border-radius: 50%;
            color: ${props => props.theme.main.textWhite};
            display: flex;
            flex-flow: column wrap;
            height: 55px;
            justify-content: center;
            padding: 12px;
            text-align: center;
            transform: translateY(-50%);
            width: 55px;

            &.to-do {
                background-color: ${props => props.theme.main.lightPurple};
            }

            &.done {
                background-color: ${props => props.theme.main.successColor};
            }

            .task-card-img {
                height: 25px;
                width: 25px;
                filter: ${props => props.theme.main.brightness};
            }
        }
    }

    .task-card-body {
        text-align: center;

        .subtitle {
            font-size: 0.75rem;
            position: relative;
            top: -8px;
        }

        .title {
            display: block;
            font-size: 1rem;
            font-weight: bold;
            text-align: center;

            @media screen and (min-width: 1024px) {
                font-size: 1.25rem;
            }
        }
    }

    .task-card-footer {
        padding: 8px 0 16px;
    }
`

const UnitTasks = ({ unitType, courseId, tasks, availableUnits, fetchUnitContent, unitOrder, isBlocked }: any) => {
    if (!tasks || tasks.length === 0) {
        return null
    }

    const [isLoading, setLoading]                    = useState(false)
    const [selectedTasks, setTasks]                  = useState()
    const [filterSection, setFilterSection]          = useState()
    const [selectedSection, setSelectedSection]      = useState()

    const [selectedStatus, setSelectedStatus]        = useState('all')
    const [filteredByStatus, setFilteredByStatus]    = useState()

    const [filterUnit, setUnitFilter]                = useState(availableUnits[0].id_unidade)
    const [filterMenu, setFilterMenu]                = useState(false)

    const [taskDisplay, setTaskDisplay]              = useState({collapseClass: "", arrow: arrowDown, lock:lock})

    useEffect(()=>{
        if (isBlocked){
            setTaskDisplay({...taskDisplay, collapseClass: "hide"})
        }else {
            setTaskDisplay({...taskDisplay, collapseClass: "" })
        }
    },[])

    useEffect(() => {
        setTasksData();
    }, [])

    useEffect(() => {
        setTasksData();
    }, [tasks])

    const setTasksData = () => {
        const uniqueSections = tasks.reduce((unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.nome_secao === o.nome_secao)) {
                unique.push(o);
            }
            return unique;
        }, []);
        setSelectedSection('all')
        setTasks(tasks)
        setFilteredByStatus(tasks)
        setFilterSection(uniqueSections)
    }

    const filterByUnit = (e: any) => {
        setLoading(true);
        fetchUnitContent(e.target.value)
        setUnitFilter(e.target.value)
        setSelectedStatus('all');
        setSelectedSection('all')
        setLoading(false);
    }

    const filterBySection = (e: any) => {
        if (e.target.value === 'all') {
            setTasks(tasks);
            setFilteredByStatus(tasks);
            setSelectedStatus('all')
        } else {
            setTasks(tasks.filter((task: any) => task.nome_secao === e.target.value));
            setFilteredByStatus(tasks.filter((task: any) => task.nome_secao === e.target.value));
        }
        setSelectedSection(e.target.value);
        setSelectedStatus('all');
    }

    const filterByStatus = (e: any) => {
        if (e.target.value === 'homework') {
            setTasks(filteredByStatus.filter((task: any) => task.total_done !== task.total_telas));
            setSelectedStatus(e.target.value);
        } else if (e.target.value === 'todo') {
            setTasks(filteredByStatus.filter((task: any) => task.total_done === task.total_telas));
            setSelectedStatus(e.target.value);
        } else {
            setTasks(filteredByStatus);
            setSelectedStatus('all');
        }
    }

    const toggleTaskDisplay = () => {
        if (taskDisplay.collapseClass) {
            setTaskDisplay({collapseClass: "", arrow: arrowDown})
        } else {
            setTaskDisplay({collapseClass: "hide", arrow: arrowLeft})
        }
    }

    return (
        <BookStyle className={`col-12 book ${taskDisplay.collapseClass}`}>
            <div className={`${window.innerWidth > 1024 ? 'card shadow ' : ''}`}>
                <header className="book-header">
                    <h2>{unitType}</h2>
                    {
                        window.innerWidth < 1024 ?
                            <>
                                <div className="header-filters">
                                    <img src={filter} onClick={() => setFilterMenu(true)} />
                                </div>
                                <ViewBookFilterModal
                                    onFilterMenu={setFilterMenu}
                                    hasFilterMenu={filterMenu}
                                    unitsFilter={availableUnits}
                                    filterByUnit={filterByUnit}
                                    filterByStatus={filterByStatus}
                                    filterBySection={filterBySection}
                                    filterSection={filterSection}
                                />
                                <img src={isBlocked ? taskDisplay.lock : taskDisplay.arrow}
                                     className={ isBlocked ? "lock" : "arrow"}
                                     onClick={ !isBlocked ? toggleTaskDisplay : null} />
                            </>
                            :
                            <>
                                <div className={`header-filters ${taskDisplay.collapseClass}`}>
                                    <span className="align-self-center">Filters by</span>
                                    <div className="form-group unit">
                                        <label className="form-label label-select" htmlFor={`unit-${unitType}`}>Unit</label>
                                        <select className="form-control" id={`unit-${unitType}`} onChange={filterByUnit}>
                                            {
                                                idsBooksUnitsNameFilter.includes(courseId) ?
                                                    availableUnits.map((unit: any) => <option value={unit.id_unidade} key={unit.id_unidade}>{`${unit.nome}`}</option>)
                                                : availableUnits.map((unit: any) => <option value={unit.id_unidade} key={unit.id_unidade}>{`Unit ${unit.ordem} - ${unit.nome}`}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label label-select" htmlFor={`section-${unitType}`}>Section</label>
                                        <select className="form-control" id={`section-${unitType}`} value={selectedSection} onChange={filterBySection}>
                                            <option value={'all'} key={Math.random()}>All</option>
                                            {
                                                filterSection ?
                                                    filterSection.map((task: any) => <option value={task.nome_secao} key={task.id_exercicio}>{`${task.nome_secao}`}</option>)
                                                    :
                                                    null
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group task">
                                        <label className="form-label label-select" htmlFor={`task-${unitType}`}>Task Status</label>
                                        <select className="form-control" id={`task-${unitType}`} value={selectedStatus} onChange={filterByStatus}>
                                            <option value={'all'}>All</option>
                                            <option value={'homework'}>To do</option>
                                            <option value={'todo'}>Done</option>
                                        </select>
                                    </div>
                                </div>
                                <img src={isBlocked ? taskDisplay.lock : taskDisplay.arrow}
                                     className={ isBlocked ? "lock" : "arrow"}
                                     onClick={ !isBlocked ? toggleTaskDisplay : null} />
                            </>
                    }
                </header>
                {
                    isLoading ?
                        <p>Loading...</p>
                        :
                        <TasksStyle className={`tasks ${taskDisplay.collapseClass}`}>
                            {
                                selectedTasks ?
                                    selectedTasks.map((book: any, key: any) => {
                                        return (
                                            <Link
                                                to={`/courses/${courseId}/unit/${filterUnit}/view-book/${book.nome_secao}/${book.ordem_exercicio}/exercise/1`}
                                                className="task-link"
                                                key={key}
                                                onClick={() => { localStorage.setItem("bookInfo", JSON.stringify(book))} }>
                                                <Task task={book} unit={unitOrder} courseId={courseId}/>
                                            </Link>
                                        )
                                    })
                                    :
                                    null
                            }
                        </TasksStyle>
                }
            </div>
        </BookStyle>
    )
}

export default UnitTasks


import API3 from "YConnect/API3"

export default (user: string, key: string) => {
    API3.Auth.Post({ user, key })
        .then(({ data }: any) => {
            if (data.sucessoNoLogin) {
                localStorage.setItem("dataPl", JSON.stringify(data))
                localStorage.setItem("AuthorizationPl", data.token)
            }
        })
}
import * as React from "react"
import { useState } from "react"
import styled from "styled-components"

import OpenQuestionTextArea from "YConnect/Fields/Exercises/OpenQuestion.field/OpenQuestionTextArea.field"

const Wrapper = styled.div`
    width: 85%;
    margin: 0 auto;
`

const OpenQuestionPreview = () => {
    const [value, setValue] = useState("")

    const onChange = ({target}:any) => setValue(target.value)

    return (
        <Wrapper className="open-question__preview py-4">
            <OpenQuestionTextArea 
                maxLength={4000}
                onChange={onChange}
                rows={10}
                value={value}
            />
        </Wrapper>
    )
}

export default OpenQuestionPreview
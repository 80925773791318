import styled from "styled-components"

const ProgressBarWrapperStyle = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    flex: 10 0 auto;

    .time {
        color: ${props => props.theme.main.textColor};
        font-size: 13px;
    }

    .sound-progress {
        background: ${props => props.theme.main.greentextColor};
        height: 3px;
        left: 0;
        border-radius: 3px;
        margin-top: -3px;
        opacity: 0.8;
        cursor: pointer;
    }
`

export default ProgressBarWrapperStyle
import * as React from "react"

import PanelCrud from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"

import {
    GetAll, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/ExerciseType.resources"

import CONFIG_TABLE_EXERCISE_TYPE from "YConnect/PagesComponents/ControlPanel/Tables/ExerciseType.table.config"
import EXERCISE_TYPE_TEMPLATE from "YConnect/PagesComponents/ControlPanel/Templates/ExerciseType.template"

const ExerciseTypesPanel = () =>{

    return <PanelCrud
                idName            = "typeId"
                labelButtonCreate = "Create Exercise Type"
                labelForm         = "Exercise Type"
                template          = {EXERCISE_TYPE_TEMPLATE}
                configTable       = {CONFIG_TABLE_EXERCISE_TYPE}
                GetAll            = {GetAll}
                GetById           = {GetById}
                Create            = {Create}
                UpdateById        = {UpdateById}
                DeleteById      = {DeleteById} />
}

export default ExerciseTypesPanel
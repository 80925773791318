import * as React from "react"
import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Card, Tooltip } from "reactstrap"
import moment from "moment"

import * as pencil from "YConnect/Assets/svg/pencil.svg"
import * as pencilGreen from "YConnect/Assets/svg/pencilGreen.svg"
import * as checked from "YConnect/Assets/svg/checked.svg"
import * as remove from "YConnect/Assets/svg/close-btn.svg"
import * as warning from "YConnect/Assets/svg/warning.svg"

import Breadcrumb from "YConnect/Components/Breadcrumb"
import PageTitle from "YConnect/Components/PageTitle"
import Loader from "YConnect/Components/Loader"
import ServerErrorPage from "YConnect/Pages/ServerError.page"
import { BackLinkStyle } from "YConnect/Styles/BackLink.style"
import { ButtonStyle, YLinkButtonStyle } from "YConnect/Components/Button"
import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"
import DescryptoValue       from "YConnect/Utils/DescryptoValue";
import ExerciseTemplate, { getTemplateType } from "YConnect/Enums/ExerciseTemplate"

import HeaderExerciseStyle from "YConnect/Styles/HeaderExercise.style"
import ExerciseTimelineStyle from "YConnect/Styles/ExerciseTimeline.style"
import HeaderScoreStyle from "YConnect/Styles/HeaderScore.style"
import FormStyle from "YConnect/Styles/Form.style"
import FooterStyle from "YConnect/Styles/Footer.style"
import HomeworkLabelStyle from "YConnect/Styles/HomeworkLabel.style"

import UserContext from "YConnect/User.context"
import PrePostContext from "YConnect/PrePost.context"

import styled from "styled-components"

import OrientationToBattery from "YConnect/Modals/OrientationFinalTestToBattery.modal"
import OrientationFinalTestAproved from "YConnect/Modals/OrientationAprovedFinalTest.modal"
import Toastify from "toastify-js";

const H3Style = styled.h3`
    i{
        font-size: 22px;
    }

    u{
        font-size: 22px;
    }

    b{
        font-size: 19px;
    }
`

const TitleCategoryStyle = styled.h1`
    color: #79b100;
`

const TimerDivStyle = styled.div`
    background-color: ${props => props.theme.main.backgroundWhite};
    border-radius: 8px;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.11);
    width: 198px;
    height: 65px;
    text-align: center;
    line-height: 4em;
    margin-bottom: 20px;

    span {
        color: ${props => props.theme.main.successColor};
        font-weight: bold;
    }
`
const FormStyleFinal = styled(FormStyle)`
    .view-answers{
        display: none;
    }
`

const FooterStyleFlexEnd = styled(FooterStyle)`
        justify-content: flex-end;
`

const AssessmentFinal2Page = ({
    onUpdatePrePost,
    match,
    location,
    onCheckTag
}: any) => {

    const userContext: any = useContext(UserContext)
    const prePostUserContext: any = useContext(PrePostContext)


    const [width, setWidth] = useState(window.innerWidth)

    const [formValues, setFormValues] = useState()
    const [canSubmit, setSubmit] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [assessmentExercise, setAssessmentExercise] = useState([])
    const [time, setTime] = useState(moment.duration(0))
    const [numQuestion, setNumQuestion] = useState(0)
    const [rerenderTemplate, setRerenderTemplate] = useState(true)
    const [answeredQuestions, setAnsweredQuestions] = useState<Array<any>>([])
    const [showResult, setShowResult] = useState(false)
    const [error, setError] = useState(false)
    const [totalQuestions, setTotalQuestions] = useState(0)

    const [modalToBatery, setModalToBatery] = useState(false);
    const [modalAproved, setModalAproved] = useState(false)

    const [followPontuation, setFollowPontuation] = useState(Number);
    const [dataFinalTest, setDataFinalTest] = useState();

    const [isSegundaProva, setIsSegundaProva] = useState(false);
    const [wtSended, setWTSended] = useState()

    const [testFinished, setTestFinished] = useState(false)

    const idsMultinivel = [69, 70, 82, 83, 85, 86, 87];

    const {
        params: { idCurso, idCourse, idUnidade, sectionName, orderId, idExercise }
    } = match

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })

    useEffect(() => {
        setLoading(true)
        fetchExerciseContentAssessment()
        setSubmit(false)
        getPontuacaoFinal()
    }, [idCourse])

    useEffect(() => {
        setLoading(true)
    }, [idUnidade, sectionName, orderId])

    useEffect(() => {
        setShowResult(false)
    }, [idExercise])

    useEffect(() => {
        setSubmit(false)
        if (formValues && formValues.valuesUpdated.exercise) {
            const answers = formValues.valuesUpdated.exercise
            if (assessmentExercise[numQuestion].template_Yconnect.nome === ExerciseTemplate.TYPE_IN
                || assessmentExercise[numQuestion].template_Yconnect.nome === ExerciseTemplate.CATYPE_IN
            ) {
                let quant = 0
                answers.resposta.map(item => {
                    if (item) {
                        quant += Object.keys(item).length
                    }
                })

                if (quant === answers.qntd_respostas) {
                    setSubmit(true)
                }
            } else if (assessmentExercise[numQuestion].template_Yconnect.nome === ExerciseTemplate.CHOOSE_MULTIPLE
                || assessmentExercise[numQuestion].template_Yconnect.nome === ExerciseTemplate.CACHOOSE_MULTIPLE
                || assessmentExercise[numQuestion].template_Yconnect.nome === ExerciseTemplate.VIDEO_CHOOSE_MULTIPLE
            ) {
                if (answers.resposta) {
                    setSubmit(true)
                }
            } else if (answers.qntd_respostas === (answers.resposta.filter(Boolean)).length) {
                setSubmit(true)
            }
            answeredQuestions[numQuestion] = answers
            setAnsweredQuestions(answeredQuestions)
        }
    }, [formValues])

    useEffect(() => {
        if (rerenderTemplate == false) {
            setRerenderTemplate(true)
        }
    }, [rerenderTemplate])


    const handleModalBatery = () => {
        setModalToBatery(!modalToBatery)
    }

    const handleModalAproved = () => {
        setModalAproved(!modalAproved)
    }

    const DecryptFinalTest = (content:any) => {

         const dataDescrypt = content &&  content.map((elem : object) => {
          elem.enunciado = DescryptoValue.descryptoString( elem.enunciado);
          elem.nome_categoria = DescryptoValue.descryptoString( elem.nome_categoria);
          elem.conteudo_exercicio = DescryptoValue.descryptoString( elem.conteudo_exercicio);
          elem.texto = DescryptoValue.descryptoString( elem.texto);
          elem.respostas_Exercicio_Yconnect = elem.respostas_Exercicio_Yconnect.map( (respostas: Object)=>
              DescryptoValue.descryptoObject( respostas )
          );

          elem.exercicio_Yconnect.midia_Yconnect = elem.exercicio_Yconnect.midia_Yconnect && elem.exercicio_Yconnect.midia_Yconnect.map((midiaRespostas:object) =>
            DescryptoValue.descryptoObject( midiaRespostas
            ));

          elem.midia_Yconnect = elem.midia_Yconnect.map( (midia: Object)=>
              DescryptoValue.descryptoObject( midia )
            );

          return elem;
         }
        );
       return dataDescrypt;
   }

    const fetchExerciseContentAssessment = async () => {
        try {
            const { idUsuario } = userContext

            const { data } = await API.AssessmentFinalTest.GetPerguntasRespostasProva({
                id_curso: idCurso,
                id_aluno_prova: idUsuario
            }, getConfig())

            const dataDescrypt =  DecryptFinalTest(data);
            setAssessmentExercise(dataDescrypt)
            setTotalQuestions(data.length)
            setLoading(false)
        } catch (error) {
            setError(true)
            setLoading(false)
        }
    }

    const getPontuacaoFinal = async () => {
        const { idUsuario } = userContext

        const finalTestScore = await API.AssessmentFinalTest.GetNotasFinal(
            {
                id_curso: idCurso,
                id_aluno_nota_final: idUsuario
            },
            getConfig()
        );
        setDataFinalTest(finalTestScore.data)
    }

    useEffect(() => {
        if (dataFinalTest && dataFinalTest.length > 0) {
            setIsSegundaProva(true)
            setWTSended(dataFinalTest[0].data_finalizacao_written_test)
        }

        alreadyFinishedTest()
    }, [dataFinalTest])

    const fetchSaveProva = async (
        idUsuario: number,
        idCurso: number,
        answers: any) => {

        window.innerWidth > 1024 ? window.scrollTo(0, 0) : window.scrollTo(0, 170);

        try {
            await API.AssessmentFinalTest
                .saveRespostasProva({
                    provaFinal: {
                        id_aluno: idUsuario,
                        id_curso: idCurso,
                        pontuacao: Math.round(answers.reduce((acc: number, act: any) => acc + act.correto, 0) * (1 / totalQuestions))
                    },
                    listaProva: answers
                }, getConfig())

            setFollowPontuation(Math.round(answers.reduce((acc: number, act: any) => acc + act.correto, 0) * (1 / totalQuestions)))
            if(idsMultinivel.includes(idCurso)) {
                if (Math.round(answers.reduce((acc: number, act: any) => acc + act.correto, 0) * (1 / totalQuestions)) >= 70) {
                    handleModalAproved()
                }
                else if (Math.round(answers.reduce((acc: number, act: any) => acc + act.correto, 0) * (1 / totalQuestions)) < 70) {
                    handleModalBatery()
                }
                setLoading(false)
                setShowResult(true)
            } else {
                handleModalAproved()
                setLoading(false)
                setShowResult(true)
            }


        } catch (e) {
            setError(true)
            setLoading(false)
            
            Toastify({
                text: "Error! <br> unable to finish!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }

    const saveAsssessmentFinal = () => {
        const { idUsuario } = userContext
        const { params } = match

        setLoading(true)
        fetchSaveProva(idUsuario, params.idCurso, getAnswers())
    }


    const nextExercise = () => {
        // getModalPontuacao()
        setSubmit(false)
        setRerenderTemplate(false)
        if (numQuestion + 1 < totalQuestions) {
            setNumQuestion(numQuestion + 1)
        }
    }

    const getBreadcrumbLinks = () => {
        const curso = userContext.cursosVigentes.find(({ id_curso }: any) => id_curso == match.params.idCurso) || {titulo_curso:'Current course'}

        return [
            { path: '/', label: 'Homepage' },
            { path: '/courses', label: 'Courses' },
            { path: `/courses/${match.params.idCurso}/view-book`, label: curso.titulo_curso },
            { path: '', label: 'Final Test' }
        ]
    }

    const renderBookType = () => {
        return <HomeworkLabelStyle className="task-card-homework">Final Test</HomeworkLabelStyle>
    }

    const renderBookLifeTime = () => <div className="w-100 p-5 mt-3 d-none d-lg-block">
        <ExerciseTimelineStyle className="exercise-line d-flex flex-row justify-content-around mb-4">
            {[...Array(assessmentExercise.length)].map((x, i) => {
                if (i <= numQuestion) {
                    return <a key={i} className={`exercise-task ${i < numQuestion ? 'checked' : 'active'}`}>
                        <img src={showResult ? checked : (i >= numQuestion ? pencil : null)} alt="" />
                    </a>
                }

                return <a
                    key={i}
                    className={`exercise-task`}
                    onClick={() => { }}
                >
                    <img src={pencil} alt="" />
                </a>
            })}

        </ExerciseTimelineStyle>
    </div>

const renderBookPencil = () => <div className="w-100 p-5 mt-3 d-none d-lg-block">
    <ExerciseTimelineStyle className="exercise-line d-flex flex-row justify-content-around mb-4">
        <a
            className={`exercise-task`}
            onClick={() => { }}
        >
            <img src={pencil} alt="" />
        </a>

    </ExerciseTimelineStyle>
    </div>

    const getTemplate = (position: number) => {
        const { idUsuario } = userContext
        let type;

        if (assessmentExercise[position]) {
            type = getTemplateType(assessmentExercise[position].template_Yconnect)
        }

        let template = [
            {
                "id": "exercise",
                "type": type,
                "exercise": assessmentExercise[position],
                "studentId": idUsuario,
                "attempts": 0,
                "isStudent": false,
            }
        ]

        position++;

        return template
    }

    const getPageTitle = () => {
        return `Final Test`
    }


    useEffect(() => {
        let timer
        if (!showResult)
            timer = setInterval(() => setTime(time + 1000), 1000)
        return () => clearInterval(timer);
    }, [time, showResult])

    const renderTime = () => {
        const duration = moment.duration(time)
        return (
            <TimerDivStyle>
                Your time: <span>{moment.utc(duration.asMilliseconds()).format("HH:mm:ss")}</span>
            </TimerDivStyle>
        )
    }

    const isStudent = () => {
        const { idProfessor, observador, colaborador, usuarioTrial, usuarioDegustacao } = userContext
        return (idProfessor == 0 && !observador && !colaborador && !usuarioTrial && !usuarioDegustacao)
    }

    const getAnswers = () => {
        const answers = []
        for (let i = 0; i < answeredQuestions.length; i++) {
            const answer = answeredQuestions[i]
            answers[i] = {
                correto: Number((answer.qntd_acertos / answer.qntd_respostas).toFixed(2))*100,
                id_exercicio: assessmentExercise[i].id_exercicio,
                resposta: typeof answer.resposta === "string" || typeof answer.resposta === "number"
                    ? answer.resposta : JSON.stringify(answer.resposta)
            }
        }
        return answers;
    }

    const alreadyFinishedTest = () => {
        const paramCourse = Number(match.params.idCurso)

        if (isStudent && idsMultinivel.includes(paramCourse) && dataFinalTest && dataFinalTest.length > 1) {
            setTestFinished(true)
        }

        else if (isStudent && !idsMultinivel.includes(paramCourse) && dataFinalTest && dataFinalTest.length > 0 && dataFinalTest[0].pontuacao >= 0) {
            setTestFinished(true)
        }
    }

    // console.log(Math.round(getAnswers().reduce((acc: number, act: any) => acc + act.correto, 0) * (100 / totalQuestions)))

    const renderResult = () => {
        const answers = getAnswers();
        let qntd_meia_resposta = 0;
        let qntd_acertos = 0;
        let qntd_erros = 0;

        for(let index = 0; index < answers.length; index++) {
            const act = answers[index].correto;
            if(act < 100 && act > 0){
                qntd_meia_resposta++;
            }
            else if(act === 100){
                qntd_acertos++;
            }
            else{
                qntd_erros++;
            }
        }

        // const qntd_acertos = answers.reduce((acc: number, act: any) => acc + act.correto, 0)
        // const qntd_erros = totalQuestions - qntd_acertos - qntd_meiaResposta;
        const { params } = match

        return <div className="w-100 pt-5 pt-lg-0">
            <Col md="12" className="text-center">
                <h1>
                    {qntd_erros > 0 ? 'Keep Trying!' : 'Nice work!'} Your score is:
                        </h1>
            </Col>
            <HeaderScoreStyle md="12" className="text-center mt-2">
                <h2>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={2} className="ml-3 ml-lg-0 text-lg-center text-left" style={{margin: '5px 0px'}}>
                                <span className="icon-x">
                                    <img src={checked} alt="" />
                                </span>

                                <span className="right">{qntd_acertos} right</span>
                            </Col>

                            <Col lg={2} className="ml-3 ml-lg-0 text-lg-center text-left" style={{margin: '5px 0px'}}>
                                <span className="icon-x">
                                    <img src={remove} alt="" />
                                </span>
                                <span className="wrong">{qntd_erros} wrong</span>
                            </Col>

                            <Col lg={3} className="ml-3 ml-lg-0 text-lg-center text-left" style={{margin: '5px 0px'}}>
                                <span className="icon-x">
                                    <img style={{transform: 'scale(1.4)'}} src={warning} alt="" />
                                </span>
                                <span className="half">{qntd_meia_resposta} half the answer</span>
                            </Col>

                        </Row>
                    </Container>
                </h2>
            </HeaderScoreStyle>
            <Col md="12" className="text-center mt-2">
                <h3>Keep studying and getting better</h3>
            </Col>
            <Col md="12" className="d-none d-md-flex flex-row justify-content-center mt-5 mb-5">
                <YLinkButtonStyle
                    green
                    size="medium"
                    label="Return to courses"
                    icon="arrowRight"
                    onClick={() => { setShowResult(false) }}
                    link="/courses"
                />
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none mt-5">
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none text-center mt-2">
                <span className="p-2">Or</span>
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none mt-2 mb-5">
                <YLinkButtonStyle
                    green
                    size="medium"
                    label="Return to course"
                    icon="arrowRight"
                    onClick={() => { setShowResult(false) }}
                    link={`/courses/${params.idCurso}/`}
                />
            </Col>
        </div>
    }

    if (error) return <ServerErrorPage location={location} />

    const _popupCenter = (url: string, title: string, w: number, h: number) => {
        // Fixes dual-screen position                         Most browsers      Firefox
        const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title, 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

        if (window.focus) newWindow.focus();
    }

    return <Container>
        {isLoading
            ? <Loader />
            : <>
                <Breadcrumb links={getBreadcrumbLinks()} />
                <Row>
                    <Col xs="12" sm="12" md="12" lg="8" xl="8">
                        <PageTitle label={getPageTitle()}></PageTitle>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4" className="d-flex flex-row justify-content-end mt-lg-1 mb-2">
                        {renderTime()}
                    </Col>
                </Row>

                <OrientationToBattery isShow={modalToBatery} onClose={handleModalBatery} idCourse={match.params.idCurso} segundaProva={isSegundaProva} username={userContext.nome} WTFinished={wtSended}/>

                <OrientationFinalTestAproved isShow={modalAproved} onClose={handleModalAproved} idCourse={match.params.idCurso} username={userContext.nome} WTFinished={wtSended}/>

                {assessmentExercise.length > 0 && !testFinished &&
                    <Row className="m-0 mb-5">
                        <div className="exercise card shadow d-flex flex-row flex-wrap justify-content-between col-12 p-0">
                            {renderBookType()}
                            {assessmentExercise.length < 20 ? renderBookLifeTime() : renderBookPencil()}
                            {
                                showResult
                                    ? renderResult()
                                    : <>
                                        <HeaderExerciseStyle className="exercise-header d-flex flex-column justify-content-center w-100 pb-3 pl-3 pr-3">
                                            <TitleCategoryStyle className="text-center h3 mb-3 font-weight-bolder">
                                                {`${assessmentExercise[numQuestion].nome_categoria}`}
                                            </TitleCategoryStyle>
                                            <h1 className="text-center h3 mb-3 font-weight-bolder">
                                                {`Question ${numQuestion + 1} of ${totalQuestions}`}
                                            </h1>
                                            <H3Style
                                                className="text-center mt-2"
                                                dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(assessmentExercise[numQuestion] && assessmentExercise[numQuestion].enunciado) }}
                                            ></H3Style>
                                        </HeaderExerciseStyle>
                                        {rerenderTemplate &&
                                            <FormStyleFinal
                                                className="exercise-body w-100"
                                                onChange={setFormValues}
                                                row={false}
                                                template={getTemplate(numQuestion).map((field:any) =>{
                                                    field.isDisabledViewAnswers = true
                                                    return field
                                                })}/>
                                        }
                                    </>
                            }

                            {showResult
                                ? <FooterStyleFlexEnd className="exercise-footer d-flex flex-row p-2 p-lg-4 w-100">
                                    <BackLinkStyle
                                        size="medium"
                                        link={`/courses/${match.params.idCurso}/view-book`}
                                        icon="arrowPrevious"
                                        label="Go Back to Book tasks" />
                                </FooterStyleFlexEnd>
                                : <FooterStyleFlexEnd className="exercise-footer d-flex flex-row p-2 p-lg-4 w-100">
                                    {numQuestion + 1 < totalQuestions ?
                                        <ButtonStyle
                                            green
                                            size="large"
                                            disabled={!canSubmit}
                                            label={`Answer Question  ${numQuestion + 1} / ${totalQuestions}`}
                                            onClick={nextExercise}
                                            icon="checked"
                                        />
                                        :
                                        <ButtonStyle
                                            green
                                            size="small"
                                            disabled={!canSubmit}
                                            label="Finish Test"
                                            onClick={saveAsssessmentFinal}
                                            icon="checked" />
                                    }
                                </FooterStyleFlexEnd>
                            }
                        </div>
                    </Row>
                }

                {testFinished &&
                    <Container>
                        <Card className="text-center">
                            <Row>
                                <Col><img src={pencilGreen} className="mb-3"/></Col>
                            </Row>
                            <h1>You already finished the test! </h1>
                            <p style={{fontSize: '16px'}}>You can check your result in <a target="_blank" href={`/#/reports/view-report-student/${match.params.idCurso}`}> reports! </a></p>
                        </Card>
                    </Container>
                }
            </>
        }
    </Container>
}

export default AssessmentFinal2Page

import * as React                          from "react"
import { useState, useEffect, useContext } from "react"
import {
    Container, Col,
    Row
}                                          from "reactstrap"
import ReCAPTCHA                           from "react-google-recaptcha"

import { BreadcrumbSupport } from "YConnect/Components/BreadCrumbList.tsx"
import AboutYouTemplate from "YConnect/Configs/Templates/Support.template.json"
import PageTitle        from "YConnect/Components/PageTitle"
import { ButtonStyle }  from "YConnect/Components/Button"
import Form             from "YConnect/Containers/Form.container"
import Loader           from "YConnect/Components/Loader"
import getConfig        from "YConnect/Utils/GetRequestConfig.util"
import UserContext      from "YConnect/User.context"
import API              from "YConnect/API"
import Toastify from "toastify-js"




const SupportPage = () => {

    const {nomeCompleto, email, primeiroAcessoGameficacao}: any = useContext(UserContext)
    
    const isFirstLogin = !(primeiroAcessoGameficacao && primeiroAcessoGameficacao.dt_primeiro_acesso)
    
    const [supportValues, setSupport]       = useState({nome: nomeCompleto, email, mensagem: ''})
    const [invalidFields, setInvaliFields]  = useState([])
    const [recaptchaValue, setRecaptcha]    = useState({})
    const [isLoading, setIsLoading]         = useState(false)
    const [isValidate, setIsValidate]       = useState(false)
    const [isValidated, setValidated]       = useState(false)
    const [hasShowSupportForm, setHasShowSupportForm] = useState(true)

    const onChangeRecaptcha = (value: any) => setRecaptcha(value);

    useEffect(() => {
        if (isValidated) {
            sendMessageSupport()
        }
    }, [isValidated])


    const sendMessageSupport = () => {

        if (invalidFields.length === 0) {
            setIsLoading(true)

            const {nome, email} = supportValues;

            const formValues = { ...supportValues, recaptcha: recaptchaValue }
            API.Suporte.sendMessage(formValues, getConfig())
                .then((res: any) => {
                    Toastify({
                        text: "Success <br> Message sent with success!",
                        duration: 3000,
                        className: "toastify-success",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();

                    setSupport({nome, email, mensagem: ''})
                    setRecaptcha({})
                    setHasShowSupportForm(false)
                    setTimeout(()=>{
                        setHasShowSupportForm(true)
                    }, 100)
                })
                .catch((err: any) => {
                    if (err.response.data === 'Captcha incorreto') {
                        Toastify({
                            text: "Error! <br> Wrong Captcha!",
                            duration: 3000,
                            className: "toastify-error",
                            newWindow: true,
                            close: true,
                            gravity: "top",
                            position: "right",
                            stopOnFocus: true,
                            escapeMarkup: false,
                            onClick: function(){} 
                          }).showToast();
                    } else {
                        Toastify({
                            text: "Error! <br>" +  err.response.data,
                            duration: 3000,
                            className: "toastify-error",
                            newWindow: true,
                            close: true,
                            gravity: "top",
                            position: "right",
                            stopOnFocus: true,
                            escapeMarkup: false,
                            onClick: function(){} 
                          }).showToast();
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                })
        } else {
            Toastify({
                text: invalidFields.map(({ label }) => label).join("<br/>") + "required fields",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }

    const handleSendMessageSupport = () => {
        setIsValidate(true)
        if (isValidated && isValidate) {
            sendMessageSupport()
        }
    }

    return <Container>
            {isLoading ? <Loader /> : null}
            <BreadcrumbSupport isFirstLogin={isFirstLogin}/>
            <PageTitle label="Support" />
            <Row className="mb-5">
                <Col md={12}>
                    <div className="card shadow support">
                        <div className="support-info">
                            <h2>Are you facing any problem?</h2>
                            <p className="mb-4">Our support will reply as soon as possible after you send us a message.</p>
                        </div>

                        {hasShowSupportForm && <div className="support-form">
                            <span className="text-muted">
                                * All fields are required
                            </span>
                            <Form
                                template={AboutYouTemplate}
                                values={supportValues}
                                onChange={({valuesUpdated, invalidFields}:any) => {
                                    setSupport({...supportValues, ...valuesUpdated})
                                    setInvaliFields(invalidFields)
                                }} 
                                isValidate={isValidate} 
                                onValidated={() => setValidated(true)}/>
                                <div className="d-block support-recaptcha">
                                    <ReCAPTCHA
                                        sitekey="6Lc7Mq8ZAAAAAIK_29an9Y-cu5u-8o2jJtptpo5I"
                                        onChange={onChangeRecaptcha}
                                        size={window.innerWidth <= 768 ? 'compact' : 'normal'}
                                    />
                                </div>
                                <div className="btn-fixed d-flex btn-profile">
                                    <ButtonStyle 
                                        green 
                                        size     = {'medium'}
                                        label    = "Send message" 
                                        icon     = "messageWhite" 
                                        onClick  = {handleSendMessageSupport} 
                                        
                                    />
                                </div>
                        </div>}
                    </div>
                </Col>
            </Row>
    </Container>
}

export default SupportPage



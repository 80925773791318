import * as React                          from "react"
import { useState, useContext, useEffect } from "react"
import { Container }                       from "reactstrap"

import UserContext    from "YConnect/User.context"
import { BreadcrumbCourses } from "YConnect/Components/BreadCrumbList.tsx"
import TeacherContent from "YConnect/PagesComponents/Courses/Teacher.content"
import StudentContent from "YConnect/PagesComponents/Courses/Student.content"
import Loader         from "YConnect/Components/Loader"
import API            from "YConnect/API"
import getConfig      from "YConnect/Utils/GetRequestConfig.util"

const CoursesPage = (props: any) => {

    const { profiles, cursosVigentes }: any = useContext(UserContext)
    const { history } = props;

    const [downloadMenu, setDownloadMenu]   = useState(false)
    const [coursesBooks, setCoursesBooks]   = useState()
    const [isLoading, setLoading]           = useState(true)
    const [width, setWidth]                 = useState(window.innerWidth)

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                const result = await API.Course.GetAll({isDegustacao : false}, getConfig())
                setCoursesBooks(result.data)
            } catch (error) {}
            setLoading(false)
        };

        fetchData()
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const getBookInfo = () => {
        if (cursosVigentes.length < 1 && profiles.indexOf(11) > -1 && profiles.length <= 1) {
            return <div className="courses card shadow mb-4 recommended-card">
                <header className="courses-header d-flex flex-row justify-content-start">
                    <div className="courses-header-title">
                        <span className="h5 font-weight-bold">
                            You are not in any course yet!
                        </span>
                    </div>
                </header>
            </div>
        }

        return profiles.indexOf(15) > -1 || profiles.indexOf(12) > -1
                ? <TeacherContent
                    data            = {coursesBooks}
                    history         = {history}
                    onDownloadMenu  = {setDownloadMenu}
                    hasDownloadMenu = {downloadMenu} />
                : <StudentContent history={history} />
        
    }

    return <Container className="courses-page" fluid={width <= 1024}>
        <BreadcrumbCourses/>
        {isLoading ?
            <section className="row">
                <div className="col-12 ">
                    <div className="card p-0 shadow d-flex flex-row flex-wrap justify-content-around profile">
                        <Loader/>
                    </div>
                </div>
            </section>
            : getBookInfo()
            
        }

    </Container>

}

export default CoursesPage

import styled from "styled-components"

const OpenQuestionContainer = styled.div`
    .textLarge {
        color: ${props => props.theme.main.textColor2};
        background: ${props => props.theme.main.borderLightGray};
    }
`

export default OpenQuestionContainer

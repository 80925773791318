import * as React              from "react"
import { useState, useEffect } from "react"

const QrReader = () => {
    const [qrCode, setQrCode]           = useState("")
    const [loadedVideo, setLoadedVideo] = useState(false)
    const [stream, setStream] = useState()

    useEffect(() => {
        enableCamera();
    }, [])

    useEffect(() => {
        return ()=>  {
            if(stream){
                stream.getVideoTracks().forEach((track:any) => {
                    track.stop()
                  })
              }
            }
    }, [stream])

    const enableCamera = () => {
        const $video              = document.createElement("video")
        const $canvasElement:any  = document.getElementById("canvas")
        const $loadingMessage     = document.getElementById("loadingMessage")
        const $outputContainer    = document.getElementById("output")

        const canvas = $canvasElement.getContext("2d")

        function drawLine(begin: any, end: any, color: any) {
            canvas.beginPath()
            canvas.moveTo(begin.x, begin.y)
            canvas.lineTo(end.x, end.y)
            canvas.lineWidth = 4
            canvas.strokeStyle = color
            canvas.stroke()
        }

        // Use facingMode: environment to attemt to get the front camera on phones
        navigator.mediaDevices.getUserMedia({video:  {
            facingMode: "environment"
        } }).then((stream) => {
            setStream(stream)
            $video.srcObject = stream
            $video.setAttribute("playsinline", "true") // required to tell iOS safari we don"t want fullscreen
            $video.play()
            requestAnimationFrame(tick)
        })
        
        const tick = async () => {
            if ($video.readyState === $video.HAVE_ENOUGH_DATA) {
                setLoadedVideo(true)
                $loadingMessage.hidden = true
                $canvasElement.hidden = false
                $outputContainer.hidden = false
                // $canvasElement.height = 200
                // $canvasElement.width = 200
                canvas.drawImage($video, 0, 0)

                const imageData = canvas.getImageData(0, 0, $canvasElement.width, $canvasElement.height)

                const jsQR:any = (await import(/* webpackChunkName: "map" */ "jsqr"))
                .default(imageData.data, imageData.width, imageData.height )

                if (jsQR) {
                    
                    const {
                        location:{
                            topLeftCorner,
                            topRightCorner,
                            bottomRightCorner,
                            bottomLeftCorner
                        },
                        data
                    } = jsQR

                    drawLine(topLeftCorner,     topRightCorner, "#FF3B58")
                    drawLine(topRightCorner,    bottomRightCorner, "#FF3B58")
                    drawLine(bottomRightCorner, bottomLeftCorner, "#FF3B58")
                    drawLine(bottomLeftCorner,  topLeftCorner, "#FF3B58")
                    setQrCode(data)
                }
            }
            requestAnimationFrame(tick)
        }
    }

    return <>
                {
                    qrCode 
                    && <div>
                            <p className="text-center mt-0 mb-2">
                                Here is your link:
                            </p>
                            <a href={qrCode} className="d-block text-center">{qrCode}</a>
                        </div>
                }
                <div id="loadingMessage">
                    { 
                    loadedVideo 
                    ? "⌛ Loading video..."
                    : "🎥 Unable to access video stream (please make sure you have a camera enabled)"
                    }
                </div>
                <canvas id="canvas" hidden/>
                <div id="output" hidden/>
            </>
}

export default QrReader
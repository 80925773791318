import * as React  from "react"
import {
    useState, 
    useContext
}                  from "react"
import { Row, Col, Card} from "reactstrap"

import AverageResultScore from "YConnect/PagesComponents/PrePostReport/Components/AverageResultScore"
import StudentVision      from "YConnect/PagesComponents/PrePostReport/Components/StudentVision"
import Filters            from "YConnect/Components/Filters"
import useReport          from "YConnect/PagesComponents/PrePostReport/Components/useReport"
import useQueryParams     from "YConnect/PagesComponents/PrePostReport/Components/useQueryParams"

import useFilter  from "YConnect/PagesComponents/PrePostReport/School.report/useFilter"
import useCombo   from "YConnect/PagesComponents/PrePostReport/School.report/useCombo"
import TabsResult from "YConnect/PagesComponents/PrePostReport/School.report/TabsResult"

import UserContext from "YConnect/User.context"

const ReportContainer = ({
    queryParams,
    onChangeQueryParams
}:any) => {

    const { idUsuario, estabsSupervisor }: any    = useContext(UserContext)
    const [hasFilterLoading, setHasFilterLoading] = useState(false)

    const {
        fiterState : [
            {
                establishmentIdSelected,
                semesterIdSelected,
                courseIdSelected,
                teacherIdSelected,
                classIdSelected,
                studentIdSelected,
                periodIdSelected
            },
            setIdSelected
        ],
        comboState:[
            {
                establishmentsCombo,
                semestersCombo,
                coursesCombo,
                teachersCombo,
                classesCombo,
                studentsCombo,
                periodCombo
            }, 
            setCombo
        ]
    } = useReport()

    useQueryParams({
        idSelecteds:{
            establishmentIdSelected,
            semesterIdSelected,
            courseIdSelected,
            teacherIdSelected,
            classIdSelected,
            studentIdSelected,
            periodIdSelected
        },
        setIdSelected,
        queryParams,
        onChangeQueryParams
    })
    
    useFilter({
        establishmentIdSelected,
        semesterIdSelected,
        courseIdSelected,
        teacherIdSelected,
        classIdSelected,
        studentIdSelected,
        setIdSelected
    })

    useCombo({
        establishmentIdSelected,
        semesterIdSelected,
        courseIdSelected,
        teacherIdSelected,
        classIdSelected,
        studentIdSelected,
        setHasFilterLoading,
        setCombo
    })

    const handleChangeFilter = (keystone:string, value:any) => setIdSelected({[keystone]:value})

    return <>
        {
        !estabsSupervisor.length
        ? <Row className="mb-5">
             <Col md={12}>
                 <Card className="users-table shadow">
                     <header className="users-table-header justify-content-start mb-2">
                        <p className="d-block text-center w-100">You are not associated in any establishment!</p>
                     </header>
                 </Card>
             </Col>
         </Row>
         :  <>
                <Row className="mb-5">
                    <Col md={12}>
                        <Filters
                            isLoading = {hasFilterLoading}
                            filterProps={[
                                {
                                    keystone : "establishmentIdSelected",
                                    label    : "Establishment",
                                    value    : establishmentIdSelected,
                                    onChange : handleChangeFilter,
                                    values   : establishmentsCombo
                                },
                                {
                                    keystone : "semesterIdSelected",
                                    label    : "Semester",
                                    value    : semesterIdSelected,
                                    disabled : !(establishmentIdSelected && establishmentIdSelected !== ""),
                                    onChange : handleChangeFilter,
                                    values   : semestersCombo
                                },
                                {
                                    keystone : "courseIdSelected",
                                    label    : "Course",
                                    value    : courseIdSelected,
                                    onChange : handleChangeFilter,
                                    disabled : !(semesterIdSelected && semesterIdSelected !== ""),
                                    values   : coursesCombo
                                },
                                {
                                    keystone : "teacherIdSelected",
                                    label    : "Teacher",
                                    value    : teacherIdSelected,
                                    disabled : !teachersCombo || teachersCombo.length === 0,
                                    onChange : handleChangeFilter,
                                    values   : teachersCombo
                                },
                                {
                                    keystone : "classIdSelected",
                                    label    : "Class",
                                    value    : classIdSelected,
                                    disabled : !classesCombo || classesCombo.length === 0,
                                    onChange : handleChangeFilter,
                                    values   : classesCombo
                                },
                                {
                                    keystone : "studentIdSelected",
                                    label    : "Student",
                                    value    : studentIdSelected,
                                    disabled : !studentsCombo || studentsCombo.length === 0,
                                    onChange : handleChangeFilter,
                                    values   : studentsCombo
                                },
                                {
                                    keystone : "periodIdSelected",
                                    label    : "Period",
                                    value    : periodIdSelected,
                                    disabled : !periodCombo || periodCombo.length === 0,
                                    onChange : handleChangeFilter,
                                    values   : periodCombo
                                }
                            ]}/>
                    </Col>
                </Row>
                    {
                        studentIdSelected
                            ? <StudentVision
                                studentIdSelected       = {studentIdSelected}
                                establishmentIdSelected = {establishmentIdSelected}
                                courseIdSelected        = {courseIdSelected}
                                classIdSelected         = {classIdSelected}
                                semesterIdSelected      = {semesterIdSelected}
                                periodIdSelected        = {periodIdSelected} />
                            : semesterIdSelected
                                && !studentIdSelected
                                ? <>
                                    <AverageResultScore
                                        semesterIdSelected      = {semesterIdSelected}
                                        establishmentIdSelected = {establishmentIdSelected}
                                        courseIdSelected        = {courseIdSelected}
                                        classIdSelected         = {classIdSelected}
                                        teacherIdSelected       = {teacherIdSelected || ""} />
                                    <TabsResult
                                        idUsuario               = {idUsuario}
                                        semesterIdSelected      = {semesterIdSelected || ""}
                                        courseIdSelected        = {courseIdSelected || ""}
                                        establishmentIdSelected = {establishmentIdSelected || ""}
                                        teacherIdSelected       = {teacherIdSelected || ""}
                                        classIdSelected         = {classIdSelected || ""} />
                                </>
                                : null
                    }
            </>
        }
    </>
}

export default ReportContainer
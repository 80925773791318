import * as React from "react"
import { Col } from "reactstrap"

import MessageEditableTable from "YConnect/PagesComponents/CMS/Editors/Components/Message.editableTable"

type MessageEditorProps = {
    order:number
    messages:Array<DialogueMessageType>
    onChange:any
}

const MessageEditor = ({
    order,
    messages,
    onChange
}:MessageEditorProps) => {

    return  <>
                <Col md={12}>
                    <h4 className="font-weight-bold"> Message {order}</h4>
                    <hr/>
                </Col>
                <Col md={12}>
                    <MessageEditableTable 
                        values={messages} 
                        onChange={onChange}/>
                </Col>
            </>
}

export default MessageEditor
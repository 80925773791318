import * as React from "react"
import getCourseName from "YConnect/Utils/CourseUnitName.util"

export const CONFIG_TABLE_REPORT:Array<any> = [

    {
        label    : "Concluded sections",
        formatter : ({concludedFirstTry, concludedLastTry}: any) => <div className="d-flex">
                                                                        <div className="d-flex flex-column mr-4">
                                                                            <span>{concludedFirstTry}%</span>
                                                                            <span className="small text-secondary">1st attempt</span>
                                                                        </div>
                                                                        <div className="d-flex flex-column mr-4">
                                                                            <span>{concludedLastTry}%</span>
                                                                            <span className="small text-secondary">2nd attempt</span>
                                                                        </div>
                                                                    </div>
    },
    {
        label     : "Average score",
        formatter : ({scoreFirstAttempt, scoreSecondAttempt}: any) => <div className="d-flex">
                                                                            <div className="d-flex flex-column mr-4">
                                                                                <span>{scoreFirstAttempt || scoreFirstAttempt ===0 ? scoreFirstAttempt : "-"}</span>
                                                                                <span className="small text-secondary">1st attempt</span>
                                                                            </div>
                                                                            <div className="d-flex flex-column mr-4">
                                                                                <span>{scoreSecondAttempt || scoreSecondAttempt ===0 ? scoreSecondAttempt : "-"}</span>
                                                                                <span className="small text-secondary">2nd attempt</span>
                                                                            </div>
                                                                        </div>
    },
    {
        keystone : "finalScore",
        label    : "Final Score"
    }

]

const CONFIG_TABLE_REPORT_UNIT:Array<ColumnConfigType> = [
    {
        label    : "Unit",
        formatter : ({unidade, idCurso, nomeUnidade}: any) => getCourseName(parseInt(idCurso), unidade, nomeUnidade)
    }].concat(CONFIG_TABLE_REPORT)

export default CONFIG_TABLE_REPORT_UNIT

import * as React from "react"
import { useState, useEffect } from "react"
import {
    Card,
    Row,
    Col
} from "reactstrap"
import styled from "styled-components"

const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
`

type LearningCardProps = {
    name: string
    started: string
    current: string
    value: number
}

const LearningCard = ({
    name,
    started,
    current,
    value,
}: LearningCardProps) => {

    const [newValue, setNewValue] = useState(-1)

    useEffect(() => {
        updateValue(value)
    }, [newValue])

    useEffect(() => {
        updateValue(value)
    }, [value])


    const updateValue = (val: number) => {
        if (newValue != value) {
            setNewValue(value)
        }
        /*if (newValue < val) {
            setNewValue(newValue + 1)
        }
        if (newValue > val) {
            setNewValue(newValue - 1)
        }*/
    }


    return <Card className="m-0 p-3">
        <Row>
            <Col xs={12}>
                <div className="learning-card-name">{name}</div>
            </Col>
        </Row>
        <Row>
            <StyledCol xs={7}>
                <div className="learning-card-current">{current}</div>
                <div className="learning-card-started">Started on: <div>{started}</div></div>
            </StyledCol>
            <Col xs={5} className="learning-card-porcent">
                <Row className="m-0 learning-card-porcent-border" >
                    {value > 0 ?
                        <div className="pie">
                            {/* <div className="pie__segment"
                            style={{
                                "--offset": 15,
                                "--value": newValue,
                                "--bg": "#C8CA2A",
                                "--over50": newValue > 50 ? 1 : 0
                            }} /> */}
                            <div className="pie__segment"
                                style={{
                                    "--offset": 60 - (newValue / 2),
                                    "--value": newValue / 2 + (newValue / 2 > 0 ? 1 : 0),
                                    "--over50": newValue / 2 == 50 ? 1 : 0,
                                    "--bg": "#C8CA2A",
                                }} />
                            <div className="pie__segment"
                                style={{
                                    "--offset": 60,
                                    "--value": (newValue / 2) + (newValue / 2 == 50 ? 1 : 0),
                                    "--over50": newValue / 2 == 50 ? 1 : 0,
                                    "--bg": "#C8CA2A",
                                }} />
                        </div>
                        : ''}
                    <div className="learning-card-porcent-circle">
                        <div className="learning-card-porcent-value">
                            <div>
                                {`${value}%`}
                            </div>
                        </div>
                    </div>
                </Row>
            </Col>
        </Row>
    </Card >
}


export default LearningCard
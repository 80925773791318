import * as React               from "react"
import { useState, useContext } from "react"
import { Container }            from "reactstrap"


import { BreadcrumbViewReportStudent, BreadcrumbViewFullReport, addBreadcrumbData } from "YConnect/Components/BreadCrumbList"
import UserContext         from "YConnect/User.context"
import ViewReportContainer from "YConnect/Containers/ViewReport.container"

const ViewReportStudentPage = ({match}:any) => {
    const [reportAluno, setReportAluno] = useState()

    const {idUsuario}:any               = useContext(UserContext)

    const { idCurso, idAluno } = match.params


    addBreadcrumbData([{ page: 'ViewReportStudentPage', data: {idCurso, idUsuario, reportAluno} }])

    const handleChangeReportAluno = (reportAluno:any) => setReportAluno(reportAluno)

    return  <Container>
                {reportAluno && <BreadcrumbViewReportStudent idCurso={idCurso} idUsuario={idUsuario} reportAluno={reportAluno}/>}
                <ViewReportContainer
                    idCurso={idCurso}
                    idAluno={idUsuario}
                    onChangeReportAluno={handleChangeReportAluno}/>
            </Container>

}


export default ViewReportStudentPage

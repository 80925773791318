import { useEffect, useContext } from "react"

import UserContext from "YConnect/User.context"

import {
    GetSemestersCombo,
    GetCoursesCombo,
    GetTeachersCombo,
    GetClassesCombo,
    GetStudentsCombo,
    GetPeriodsCombo
} from "YConnect/PagesComponents/PrePostReport/Components/Combo.util"

const useCombo = ({
    establishmentIdSelected,
    semesterIdSelected,
    courseIdSelected,
    teacherIdSelected,
    classIdSelected,
    studentIdSelected,
    setHasFilterLoading,
    setCombo
}:any) => {

    const { 
        idUsuario, 
        idProfessor, 
        estabsSupervisor,
        estabelecimentos
    }: any = useContext(UserContext)
    
    useEffect(()=>{
        setHasFilterLoading(true)
        Promise.all([
            GetCoursesCombo(),
            GetSemestersCombo({
                idProfile   : idUsuario, 
                idProfessor : idProfessor
            })
        ])
        .then(([coursesCombo, semestersCombo]) => {
            setCombo({coursesCombo})
            setCombo({semestersCombo})
        })
        .finally(() => setHasFilterLoading(false))
        
        setCombo({
            establishmentsCombo: estabelecimentos.map(({nsu_estab, nm_estab}:any)=>({id:nsu_estab,value:nm_estab}))
        })
    }, [])

    useEffect(()=>{
        setCombo({teachersCombo:[]})
        if(courseIdSelected && courseIdSelected!==""){
            setHasFilterLoading(true)
            Promise.all([
                GetClassesCombo({
                    idAnoSemestre     : semesterIdSelected || "",
                    idEstabelecimento : estabsSupervisor[0].nsu_estab || "",
                    idCurso           : courseIdSelected || "",
                    idProfessor       : teacherIdSelected || ""
                }),
                GetTeachersCombo({
                    idAnoSemestre     : semesterIdSelected,
                    idEstabelecimento :establishmentIdSelected || "",
                    idCurso           : courseIdSelected,
                })
            ])
            .then(([classesCombo, teachersCombo]) => {
                setCombo({classesCombo})
                setCombo({teachersCombo})
            })
            .finally(() => setHasFilterLoading(false))
        }
    }, [courseIdSelected])

    useEffect(()=>{
        setCombo({classesCombo:[]})
        if(semesterIdSelected && teacherIdSelected!==""){
            setHasFilterLoading(true)
            GetClassesCombo({
                idAnoSemestre     : semesterIdSelected || "",
                idProfessor       : teacherIdSelected || "",
                idCurso           : courseIdSelected || "",
                idEstabelecimento : establishmentIdSelected || ""
            })
            .then((classesCombo:any) => setCombo({classesCombo}))
            .finally(() => setHasFilterLoading(false))
        }
    }, [teacherIdSelected])

    useEffect(()=>{
        setCombo({studentsCombo:[]})
        if(classIdSelected && classIdSelected!==""){
            setHasFilterLoading(true)
            GetStudentsCombo({idGrupo: classIdSelected})
            .then((studentsCombo:any) => setCombo({studentsCombo}))
            .finally(() => setHasFilterLoading(false))
        }
    }, [classIdSelected])

    useEffect(()=>{
        if(studentIdSelected && studentIdSelected!==""){
            setCombo({periodCombo:[]});
            setHasFilterLoading(true);

            GetPeriodsCombo({id_curso_matricula:courseIdSelected, id_aluno_matricula:studentIdSelected})
            .then((periodCombo: any) => setCombo({periodCombo}))
            .finally(() => setHasFilterLoading(false));
        }
    }, [studentIdSelected])
}

export default useCombo
import API2 from "YConnect/API2"
import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
export const GetCover = (id_curso_capa : any) =>
    API
    .Course
    .GetCover({id_curso_capa}, getConfig())

export const GetAll = () => 
    API2
    .Course
    .GetAll({}, getConfig())

export const Create = (course:any) => 
    API2
    .Course
    .Create(course, getConfig())
    
export const UpdateById = (course:any) => 
    API2
    .Course
    .UpdateById(course, getConfig())

export const GetById = (courseId:any) => 
    API2
    .Course
    .GetById({courseId}, getConfig())

export const DeleteById = (courseId:any) => 
    API2
    .Course
    .DeleteById({courseId}, getConfig())
import * as React       from "react"
import { Tab }    from "react-bootstrap"
import TabStyle from "YConnect/Styles/Tab.style"

const Tabs = ({defaultTabKey, tabs}:any) =>
<TabStyle defaultActiveKey={defaultTabKey} id="score-table" className={'auto'}>
    {
        tabs.map(({keystone, title, children}:any, key:any)=>
        <Tab key={key} eventKey={keystone} title={title} className="yc-tab">
            {
                window.location.hash.includes('manage-users')
                ? <span className="text-muted mb-4">Only the email and password can be changed</span>
                :  keystone === 'contact-information' 
                ? <small className="form-text text-muted text-left mb-4 text-clickable">Contact your Pedagogical Coordinator to change your contact information</small>
                : <span className="text-muted mb-4">* Marked fields are required</span>
            }
            {children}
        </Tab>)
    }
</TabStyle>

export default Tabs
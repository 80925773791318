import * as React from "react";
import { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";
import { Card, Row, Col } from "reactstrap";
import moment from "moment";

import generateObjectPDF from "YConnect/Utils/GenerateObjectPDF.util";
import getConfig from "YConnect/Utils/GetRequestConfig.util";
import API from "YConnect/API";

import TabStyle from "YConnect/Styles/Tab.style";
import SelectFieldStyle from "YConnect/Styles/SelectField.style";

import Loader from "YConnect/Components/Loader";
import ScoreUnit from "YConnect/PagesComponents/ViewReportStudent/ScoreUnit";
import ScoreTask from "YConnect/PagesComponents/ViewReportStudent/ScoreTask";
import FinalScore from "YConnect/PagesComponents/ViewReportTeacher/FinalScore"
import AverageResultStudent from "YConnect/Containers/ViewReport.container/AverageResultStudent";
import Toastify from "toastify-js";

const idCursoStarter = 67;

const fixDate = (date: string) => {
    const receivedDate = new Date(date);
    return (
        ("0" + receivedDate.getDate()).slice(-2) +
        "/" +
        ("0" + (receivedDate.getMonth() + 1)).slice(-2) +
        "/" +
        receivedDate.getFullYear() +
        " " +
        ("0" + receivedDate.getHours()).slice(-2) +
        ":" +
        ("0" + receivedDate.getMinutes()).slice(-2)
    );
};

const getPDFMake = async () => {
    return new Promise((resolve, reject) => {
        import(
            /* webpackChunkName: "pdfMake" */ "pdfmake/build/pdfmake.min"
        ).then((pdfMake) => {
            import(
                /* webpackChunkName: "pdfFonts" */ "pdfmake/build/vfs_fonts"
            ).then((pdfFonts) => {
                pdfMake.default.vfs = pdfFonts.default.pdfMake.vfs;
                resolve(pdfMake.default);
            });
        });
    });
};

const ReportsFinalScore = ({ reportEtapa }: any) => (
    <Col xs={12} md={6} className="final-score">
        <div className="card shadow">
            <h5 className="font-weight-bold mb-4">Report's final score</h5>
            <div className="d-flex justify-content-between">
                {reportEtapa.map(({ descricao }: any, key: any) => (
                    <span key={key} className="title">
                        {descricao}
                    </span>
                ))}
            </div>
            <div className="d-flex justify-content-between">
                {reportEtapa.map(({ nota }: any, key: any) => (
                    <span key={key} className="subtitle small">
                        Grade: {nota}
                    </span>
                ))}
            </div>
        </div>
    </Col>
);

const ViewReportContainer = ({
    disabledDonwnload,
    disabledPrint,
    idCurso,
    idAluno,
    filterComponent,
    onChangeReportAluno,
    idProfessor
}: any) => {
    const isCursoStarter: boolean = parseInt(idCurso, 10) === idCursoStarter;

    const [headerText, setHeaderText] = useState(
        isCursoStarter ? "" : "per Unit"
    );
    const [hasLoading, setHasLoading] = useState(true);
    const [reportAluno, setReportAluno] = useState<any>();
    const [reportEtapa, setReportEtapa] = useState();
    const [reportExercicio, setReportExercicio] = useState();
    const [reportUnidade, setReportUnidade] = useState<any>();
    const [hasPrePost, setHasPrePost] = useState(false);
    const [periodParam, setPeriodParam] = useState<number>(0);
    const [listPeriod, setListPeriod] = useState<any>([]);

    useEffect(
        () =>
            reportAluno &&
            onChangeReportAluno &&
            onChangeReportAluno(reportAluno),
        [reportAluno]
    );

    useEffect(() => {
        if (idCurso) {
            fetchData();
        }
    }, [idCurso]);

    useEffect(() => {
        if (periodParam && idCurso) {
            fetchData();
        }
    }, [periodParam]);

    const fetchData = async () => {
        setHasLoading(true);
        try {
            await fetchReportIndividual();
            await fetchReport();
            await fetchPeriods();
        } catch (error) {
            console.error(error);
        } finally {
            setHasLoading(false);
        }
    }

    const fetchReportIndividual = async () => {
        const { data }: any = await API.ReportIndividual.Get(
            { id_aluno: idAluno, id_curso: idCurso },
            getConfig()
        );

        setReportAluno(data);
    };

    const fetchReport = async () => {
        const {
            data: { reportEtapa, reportExercicio, reportUnidade },
        }: any = await API.Report.GetTipoUnidade(
            { id_aluno: idAluno, id: idCurso, tipo_unidade: "BOOK_TASK", id_matricula: periodParam },
            getConfig()
        );

        const units = await fetchUnits();

        insertUnitOnReport(reportUnidade, units);
        insertUnitOnReport(reportExercicio, units);

        setReportEtapa(reportEtapa);
        setReportExercicio(reportExercicio);
        setReportUnidade(reportUnidade);
    };

    const fetchUnits = async () => {

        const { data } = await API.Course.getById({
            id_curso: idCurso, id_usuario: idAluno, isDegustacao: false },
            getConfig());

        return data;

    }

    const fetchPeriods = async () => {
        try {
            const response = await API.Aluno.GetMatriculaAluno({
                id_curso_matricula: idCurso, id_aluno_matricula: idAluno },
                getConfig());

            if(response.data && response.data.length > 1){
                const list: any = [];

                response.data.forEach((element: any) => {
                    list.push({
                        id: element.id_Matricula,
                        value: `${element.id_Matricula === 0 ? "Current" : moment(element.data_inicial).format("DD/MM/YYYY")} - ${moment(element.data_final).format("DD/MM/YYYY")}`
                    });
                });

                setListPeriod(list);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const insertUnitOnReport = (report: any, units: any) =>{

        report.forEach((itemReport: any, index:number) => {
            const unidadeObj = units.filter((item: any) => item.tipo_unidade === "BOOK_TASK" &&
                                                          item.ordem === itemReport.unidade)[0];

            report[index].nomeUnidade = unidadeObj.nome;
            report[index].idCurso = idCurso;

        });
    }

    const getHasPrePost = () => {
        API.PrePostTest.Get(
            {
                id_curso: idCurso,
                id_aluno: idAluno,
            },
            getConfig()
        )
            .then(({ data }: any) => {
                if (
                    data &&
                    data.listPptTestAlunoYConnect &&
                    data.listPptTestAlunoYConnect.length > 0 &&
                    data.listPptTestAlunoYConnect[0].cursoTemPrePostTest
                ) {
                    setHasPrePost(true);
                } else {
                    setHasPrePost(false);
                }
            })
            .catch((e: any) => {
                Toastify({
                    text: e.response.data.message,
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            });
    };

    useEffect(() => {
        getHasPrePost();
    }, []);

    const getFilenameReport = (type: any) => {
        const [part1, part2, part3] = [
            reportAluno.nomeCompleto.split(" ").join("-"),
            type === "UNIT" ? "01" : type === "TASK" ? "02" : "",
            reportAluno.date.split("T")[0],
        ];

        return `${part1} ${part2} ${part3}.pdf`;
    };

    const getDataForReport = (type: string) =>
        type === "UNIT"
        ? { reportAluno, reportUnidade, reportEtapa }
        : type === "TASK"
            ? { reportAluno, reportExercicio }
            : {};

    const handleDownload = (type: any) => async () => {
        const pdfMake: any = await getPDFMake();
        pdfMake
            .createPdf(generateObjectPDF(getDataForReport(type)))
            .download(getFilenameReport(type));
    };

    const handlePrint = (type: any) => async () => {
        const pdfMake: any = await getPDFMake();
        pdfMake.createPdf(generateObjectPDF(getDataForReport(type))).print();
    };

    const handleHeader = (eventKey: string) => {
        if (eventKey === "task") {
            setHeaderText("per Task");
        } else if (eventKey === "unit") {
            if (isCursoStarter) {
                setHeaderText("");
            } else {
                setHeaderText("per Unit");
            }

        } else if (eventKey === 'Final Score') {
            setHeaderText(" per Final Test")
        }
        else {
            setHeaderText("per Pre and Post test");
        }
    };

    const handleSelect = (keystone: string, value: number) => {
        const sets: any = {
            period: setPeriodParam
        }

        if(sets[keystone]) sets[keystone](value);
    }

    return !hasLoading ? (
        <>
            <Row className="mb-0 mr-0">
                <Col md={6} xs={12}>
                    <h2>
                        {reportAluno.nomeCompleto}: Score {headerText}
                    </h2>
                    <div className="mb-1"><strong>Course: </strong>{reportAluno.course}</div>
                    <div className="mb-3"><strong>Last Visit: </strong>
                    { reportAluno.last_visit ? fixDate(reportAluno.last_visit) : ' - ' }</div>
                    {
                        listPeriod && listPeriod.length > 0 &&
                        <SelectFieldStyle
                            keystone="period"
                            label="Period"
                            className="select-period"
                            value={periodParam}
                            onChange={handleSelect}
                            values={listPeriod}
                            hasOptionEmpty={true} />
                    }
                </Col>
                <ReportsFinalScore reportEtapa={reportEtapa} />
            </Row>

            <Row className="mb-5">
                <Col md={12}>
                    <Card className="shadow p-0">
                        <TabStyle
                            defaultActiveKey="unit"
                            onSelect={(eventKey: string) =>
                                handleHeader(eventKey)
                            }
                        >
                            <Tab
                                eventKey="unit"
                                title={
                                    !isCursoStarter ? "Score per Unit" : "Score"
                                }
                                className="px-4"
                            >
                                <ScoreUnit
                                    report={reportUnidade || []}
                                    onDownload={
                                        !disabledDonwnload &&
                                        handleDownload("UNIT")
                                    }
                                    onPrint={
                                        !disabledPrint && handlePrint("UNIT")
                                    }
                                    filterComponent={filterComponent}
                                    showUnit={!isCursoStarter}
                                />
                            </Tab>
                            <Tab
                                eventKey="task"
                                title="Score per Task"
                                className="px-4"
                            >
                                <ScoreTask idCurso={idCurso} idAluno={idAluno} period={periodParam} report={reportExercicio || []}
                                    onDownload={
                                        !disabledDonwnload &&
                                        handleDownload("TASK")
                                    }
                                    onPrint={
                                        !disabledPrint && handlePrint("TASK")
                                    }
                                />
                            </Tab>
                            {!isCursoStarter && hasPrePost ? (
                                <Tab
                                    eventKey="prepost"
                                    title="Pre and Post test"
                                    className="px-4"
                                >
                                    <AverageResultStudent
                                        className="student-report-card mb-3 mt-3"
                                        idCourse={idCurso}
                                        idAluno={idAluno}
                                        idMatricula={periodParam}
                                    />
                                </Tab>
                            ) : (
                                    ""
                                )}
                            {
                                // idProfessor == undefined &&
                                <Tab
                                    eventKey="Final Score"
                                    title="Final Test"
                                    className="px-4"
                                >
                                <FinalScore idAluno={idAluno} idCurso={idCurso} idMatricula={periodParam}/>
                            </Tab>
                            }

                        </TabStyle>
                    </Card>
                </Col>
            </Row>
        </>
    ) : (
            <section className="row">
                <div className="col-12 ">
                    <div className="card p-0 shadow d-flex flex-row flex-wrap justify-content-around profile">
                        <Loader />
                    </div>
                </div>
            </section>
        );
};

export default ViewReportContainer;

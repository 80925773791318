import * as React   from "react"
import clone from "lodash/clone"

import ExerciseQuestionStyle     from "YConnect/Fields/Exercises/PrePostTest.field/ExerciseQuestion.style"
import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/PrePostTest.field/ExerciseSubStatement.style"

class PrePostTestField extends React.Component<ExerciseFieldPropsType, {
    answer  : any, 
    options : any
}> {
    constructor(props: any) {
        super(props)
		this.state = {
            answer: props.exercise.respostaUsuario,
            options: this.embaralhaOpRespostas(
                props
                .exercise
                .questao
                .ppT_Alternativa_Yconnect
                ).reduce((acc:any, item:any)=>{

                    if(item.descricao === "<i>I don't know the answer.</i>")
                        return [...acc, item]
                    else
                        return [item, ...acc]
                }, []),
        }

        if (props.exercise.respostaUsuario) {
            props.onChange(props.id, {
                resposta: props.exercise.respostaUsuario
            })
        }

        this.handleChange = this.handleChange.bind(this)
    }

	handleChange(event: any) {
		const {
            props: { id, onChange }
        } = this
        const { currentTarget: { value } } = event

        onChange(id, {
            resposta: value
        })
        this.setState({ answer: value })
    }

    embaralhaOpRespostas (respostas: any) {
        let retorno = clone(respostas)

        if (respostas) {
            // Embaralha as respostas, e armazena nas opções de respostas
            for (var i = 0; i < respostas.length; i++) {
                var r1 = Math.floor(Math.random() * respostas.length)
                var r2 = Math.floor(Math.random() * respostas.length)

                var aux = retorno[r2]
                retorno[r2] = retorno[r1]
                retorno[r1] = aux
            }
        }

        return retorno
    }

	render() {
		const {
			props: {
                exercise
            },
            state: {
                options,
                answer
            },
			handleChange
        } = this

        return (
            <>
                {exercise.questao.subenunciado ?
                    <ExerciseSubStatementStyle className="w-100">
                        {/** Sub enunciado */}
                        <div className="w-100 text-center">
                            <h3 dangerouslySetInnerHTML={{ __html: exercise.questao.subenunciado}}></h3>
                        </div>
                    </ExerciseSubStatementStyle>
                    :
                    ''
                }

                {/** Opções */}
                <ExerciseQuestionStyle className="exercise-dialogue-options text-center w-100 mt-4">
                    <div className="btn-group btn-group-toggle m-auto" data-toggle="buttons">
                        {
                            options.map((option: any, key: number) => {
                                return (
                                    <label
                                        key={key}
                                        className={`btn btn-radio ${`${answer}` === `${option.id_alternativa}` ? 'active': ''}`}
                                    >
                                        <input
                                            type="radio"
                                            id={option.id_alternativa}
                                            value={option.id_alternativa}
                                            onClick={handleChange}
                                        />

                                        <span dangerouslySetInnerHTML={{ __html: option.descricao}} />
                                    </label>
                                )
                            })
                        }
                    </div>
                </ExerciseQuestionStyle>
            </>
        )
	}
}

export default PrePostTestField

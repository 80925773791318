import styled from "styled-components"

const StudentInfoStyle = styled.div`
    display:flex;
    flex-flow: column wrap;
    padding: 0 32px;
    width: 100%;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        width: 50%;
    }

    .student-photo {
        margin: 0 auto;
    }
    .student-info {
        width: 100%;
        @media screen and (min-width: 1024px) {
            flex-direction: row;
            width: 50%;
        }
    }
`

export default StudentInfoStyle
import * as React from "react"
import { Modal }  from "react-bootstrap"
import styled from "styled-components"

import Slider from "infinite-react-carousel"

//import * as welcomeVideo   from "YConnect/Assets/videos/how-to.mp4"
//import * as imgFirstAccess from "YConnect/Assets/png/bg-video-first-login.png"

import * as img1 from "YConnect/Assets/png/first-login/slider1.png"
import * as img2 from "YConnect/Assets/png/first-login/slider2.png"
import * as img3 from "YConnect/Assets/png/first-login/slider3.png"
import * as img4 from "YConnect/Assets/png/first-login/slider4.png"
import * as img5 from "YConnect/Assets/png/first-login/slider5.png"


import * as arrowPrev from "YConnect/Assets/svg/arrow-previous.svg"
import * as arrowNext from "YConnect/Assets/svg/arrow-next.svg"

const ITEMS = [
    {
        img:img1,
        title:"A New Dashboard",
        text:"Apresentamos uma dashboard mais funcional e agradável. Informações mais claras sobre suas notas, suas mensagens e sua coleção de países viajados te esperam!"
    },
    {
        img:img2,
        title:"New Accessibility mode",
        text:"O novo modo de acessibilidade permite a exibição da tela em modo noturno e em alto contraste, além da alternativa de fonte especial para dislexia."
    },
    {
        img:img3,
        title:"New layout, same activities",
        text:"As atividades agora apresentam um layout mais agradável e user friendly. Além disso a navegação por celular ou tablet ficou simples e intuitiva. Aprenda como sempre aprendeu, mas agora em grande estilo!"
    },
    {
        img:img4,
        title:"New courses, more knowledge",
        text:"Além das atividades do seu curso atual, agora é possível também revisitar o(s) outro(s) curso(s) que você já fez no YConnect. Você pode rever suas notas e atividades, e até mesmo refazê-las. Desta forma você nunca esquecerá o que já aprendeu enquanto estudar conosco!"
    },
    {
        img:img5,
        title:"YConnect presents: Pre and Post Tests",
        text:"A nova ferramenta pedagógica do YConnect vai medir o seu conhecimento de inglês no início e no término de cada curso. Desta forma, você conseguirá ver sua evolução ao longo do semestre!"
    }
]

type VideoFirstAccessModalProps = {
    isShow: boolean
    onClose: () => void
}

    const DivStyle = styled.div`
        text-align :center;
    
        h2{
            margin-top:15px;
        }
        padding: 30px;
    `

    const SliderStyle = styled(Slider)`
       
        .carousel-dots li.carousel-dots-active button:before{
            color:#79b100;
        }

        .carousel-dots li button:before{
            color:#79b100;
        }
    `

   const VideoFirstAccessModal = ({ isShow, onClose }: VideoFirstAccessModalProps) =>

   <Modal keyboard={false} show={isShow} onHide={onClose} size="lg" className="modal-background first-access">
       <Modal.Header closeLabel={'Close'} closeButton className="first-access">
           Welcome to YConnect
       </Modal.Header>
       <Modal.Body style={{ "backgroundColor":"#fff"}}>
            <SliderStyle 
                dots
                duration={20} shift={100} prevArrow={<img style={{"padding":"10px"}} src={arrowPrev}/>} nextArrow={<img style={{"padding":"10px"}} src={arrowNext}/>} adaptiveHeight>
                {
                    ITEMS.map(({img, title, text}:any, key:any) => 
                    <DivStyle key={key}>
                        <img src={img}/>
                        <h2>{title}</h2>
                        <span>{text}</span>
                    </DivStyle>)
                }
                </SliderStyle>
       </Modal.Body>
   </Modal>

export default VideoFirstAccessModal
import * as React from "react"
import styled from "styled-components"

import CardLoading  from "YConnect/PagesComponents/PrePostReport/Components/CardLoading"

const H2Style = styled.h2`
    color: #565656;
    font-size: 20px;
    font-weight: 600;
`

const CardStyle = styled.div`
    align-items: center;
    border: 1px solid #E1E6E7;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 223px;
`

const AveragePositionStyle = styled.p`
    padding: 0 30px 0;
`

const SchoolAverageScoreStyle = styled.div`
    border: 1px solid #E1E6E7;
    border-radius: 8px;

    .averageScore {
        display:flex;
        flex-flow: column wrap;
        padding: 20px 20px 0;

        .percent {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            .title {
                font-size: 42px;
                margin-right: 20px;
            }
            .subtitle {
                font-size: 14px;
            }
        }
        .score {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            small {
                color: #989898;
                font-size: 12px;
            }
            b {
                color: #565656;
                font-size: 16px;
            }
        }
    }
`

const SchoolAverageScore = ({
    isPost,
    averageScore
}:any) => {

    const newAverageScore = averageScore 
        ? averageScore.filter((avg: any) => avg.fl_is_pre === !isPost?true:false)[0] || {}
        : {}

    return <>
            <H2Style>School average score</H2Style>
            {
                (averageScore === undefined || averageScore.filter((avg: any) => avg.fl_is_pre === !isPost?true:false).length > 0)  || Object.keys(newAverageScore).length !== 0
                ?(
                    averageScore && newAverageScore && Object.keys(newAverageScore).length > 0
                    ? <SchoolAverageScoreStyle>
                            <div className="averageScore">
                                <div className="percent">
                                    <span className="title" style={{ color: newAverageScore.mediaEstablish > 0.7 ? "#00A281" : "#FF1A4A" }}>
                                        {(newAverageScore.mediaEstablish * 100).toFixed(1)}%
                                    </span>
                                    <span className="subtitle" style={{ color: newAverageScore.porcentagemAvanco >= 0 ? "#00A281" : "#FF1A4A" }}>
                                        {newAverageScore.porcentagemAvanco}% {newAverageScore.porcentagemAvanco >= 0 ? "better" : "worse"} than last semester
                                    </span>
                                </div>
                                <div className="score">
                                    <small>National score: <b>{(newAverageScore.porcentagemNacional * 100).toFixed(1)}%</b></small>
                                    <small>Regional score: <b>{(newAverageScore.porcentagemRegional * 100).toFixed(1)}%</b></small>
                                </div>
                            </div>
                            <hr />
                            <AveragePositionStyle><span># {newAverageScore.rankRegional}</span> on regional ranking </AveragePositionStyle>
                            <AveragePositionStyle><span># {newAverageScore.rankNacional}</span> on national ranking </AveragePositionStyle>
                        </SchoolAverageScoreStyle>
                    : <CardLoading/>)
                : <CardStyle className="align-items-center d-flex flex-row justify-content-center">
                        No data recorded!
                    </CardStyle>
            }
        </>
}


export default SchoolAverageScore
import {useState, useEffect} from "react"

const useEditableTableState = (initialDataState:any) => {

    const [dataState, setDataState] = useState<Array<any>>(initialDataState)

    const updateLine = (key:number, property:any, value:any) =>{
        const newDataState = dataState.reduce((newState:Array<any>, state:any, index:number) => {
            if(index === key){
                return [
                        ...newState, 
                        {
                            ...state, 
                            ...{ 
                                type: value !== "" ? "ADDED" : "NEW", 
                                row:{
                                    ...state.row, 
                                    [property]:value
                                }
                            }
                        }
                    ]
            }else{
                return [...newState, state]
            }
        }, [])

        setDataState(newDataState)
        return newDataState.filter(({type}:any) => type !== "NEW").map(({row}:any) => row)
    }

    const addDataNewLine = () =>{
        setDataState([
            ...dataState.map((state:any) => ({...state, type:"ADDED"})),
            {
                type:"NEW",
                row:{}
            }
        ])
    }


    useEffect(() => {

        if(dataState){
            const data = dataState.filter(({type}:any) => type === "NEW")
            if(data){
                if(data.length === 0){
                    addDataNewLine()
                }else if(data.length > 1){
                    const index = dataState.indexOf(data[0])
                    removeLine(index)
                }
            }
        }
        
    }, [dataState])

    const removeLine = (key:number) => {
        const newDataState = dataState.reduce((newDataState, state, index) => {
            if(index !== key){
                return [...newDataState, {...state, row:{...state.row}}]
            }else{
                return newDataState
            }
        }, [])

        setDataState(newDataState)
        return newDataState.filter(({type}:any) => type !== "NEW").map(({row}:any) => row)
    }

    const getValues = (newDataState:Array<any>) =>{
        return (newDataState || dataState)
            .map(({row}:any) => row)
            //TODO Só vale para Answer
            .filter(({Awnser}:any) => Awnser && Awnser !== "")
            .map((row:any) => ({...row, IsCorret:!!row.IsCorret})) //Order:idx+1,
    }


    return {
        dataState,
        getValues,
        updateLine,
        addDataNewLine,
        removeLine,
        setDataState
    }
}

export default useEditableTableState
import * as React            from "react"
import { useState,useEffect} from "react"
import {Container}           from "reactstrap"

import API               from "YConnect/API"
import getForms          from "YConnect/Containers/Profile.container/GetForms.function"
import { BreadCrumbProfile } from "YConnect/Components/BreadCrumbList"
import Loader            from "YConnect/Components/Loader"
import FormTabsContainer from "YConnect/Containers/Profile.container/FormTabs.container"
import PageTitle         from "YConnect/Components/PageTitle"
import getConfig         from "YConnect/Utils/GetRequestConfig.util"
import Toastify from "toastify-js"


const ProfileContainer = ({
    id,
    label,
    isMyProfile,
    defaultTabKey,
    formAbout,
    formContact,
    formAccount,
    onBeforeUpdate,
    disabledPictureEdit
}:any) => {

    const [profile, setProfile] = useState<any>()
    const [hasLoading, setHasLoading] = useState(false)

    useEffect(()=>{
        fetch()
    }, [])

    const fetch = async () => {
        try{
            setProfile(undefined)
            const {data} = await API.Profile.Get({id}, getConfig())
            if(data){
                setProfile(data)
            }else{
                setProfile({})
            }
        }catch({response}){
            Toastify({
                text: "Error! <br>" + response.message,
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }

    const handleUpdate = async (values:any) => {
        
        if(Object.keys(values).length > 0){       
            try{
                setHasLoading(true)
                const valuesForRequest = {...profile, ...values, id}
                await API.Profile.Put(valuesForRequest, getConfig())
                Toastify({
                    text: "Profile updated successfully",
                    duration: 3000,
                    className: "toastify-success",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
                onBeforeUpdate(valuesForRequest)
                fetch()

            }catch(e){
                if(e.response && e.response.status === 409){
                    Toastify({
                        text: "Error! <br> E-mail already registered. Use another email address.",
                        duration: 3000,
                        className: "toastify-error",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                    
                }else if(e.response && e.response.status === 400){
                    Toastify({
                        text: "Error! <br> Bad Request!",
                        duration: 3000,
                        className: "toastify-error",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }else{
                    Toastify({
                        text: "Error! <br> Error updating!",
                        duration: 3000,
                        className: "toastify-error",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }
            }finally{
                setHasLoading(false)
            }
        }else{
            Toastify({
                text: "There is no data to update",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        } 
    }

    const handleUpdateEmail = (newEmail:string) => {

        const {id_aluno} = profile
        setHasLoading(true)

        API.Usuario.ChangeEmail({
            id    : id_aluno,
            email : newEmail
        })
        .then(()=>{
            Toastify({
                text: "E-mail updated successfully",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            onBeforeUpdate({...profile,  email_aluno:newEmail})
        })
        .catch((e:any) => {
            Toastify({
                text: "Email! <br> Error updating!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
        .finally(()=>{
            setHasLoading(false)
            fetch()
        })

    }

    const handleUpdatePassword = ({oldPassword, newPassword}:any) => {
        setHasLoading(true)
        const valuesForRequest = {...profile, ...{senha_aluno:oldPassword, nova_senha_aluno:newPassword}, id}
        API.Profile.Put(valuesForRequest, getConfig())
        .then(()=>{
            Toastify({
                text: "Password updated successfully",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            fetch()
        })
        .catch((e:any) => {
            Toastify({
                text: "Error! <br> Error updating password!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
        .finally(()=>{
            setHasLoading(false)
        })
    }

    return  <Container>
                <BreadCrumbProfile isMyProfile={isMyProfile}/>
                { hasLoading &&  <Loader/> }
                {
                    profile ?
                    (
                        Object.keys(profile).length > 0
                        ? [
                            <PageTitle key={0} label={`${label} ${profile.nome_aluno} ${profile.sobrenome_aluno}`} className="col-12 courses" />,
                            <FormTabsContainer 
                                isMyProfile         = {isMyProfile}
                                key                 = {1}
                                disabledPictureEdit = {disabledPictureEdit}
                                defaultTabKey       = {defaultTabKey}
                                response            = {Object.keys(profile).reduce((acc:any, property:any)=>{
                                    if(property === "telefone_aluno"){
                                        const {ddd_aluno, telefone_aluno} = profile
                                        acc["phone_number"] = `${ddd_aluno}${telefone_aluno}`
                                    }

                                    acc[property] = profile[property]
                                    
                                    return acc
                                }, {})} 
                                onUpdate            = {handleUpdate}
                                onUpdatePassword    = {handleUpdatePassword}
                                onUpdateEmail       = {handleUpdateEmail}
                                onMapFormTabs       = {getForms({isMyProfile, formAbout, formContact, formAccount})}/>
                        ]
                        : <section className="row">
                                <div className="col-12 ">
                                    <div className="card p-5 shadow d-flex flex-row flex-wrap justify-content-around profile">
                                        <h3>Profile data not found!</h3>
                                    </div>
                                </div>
                            </section>
                    )
                    : <section className="row">
                            <div className="col-12 ">
                                <div className="card p-0 shadow d-flex flex-row flex-wrap justify-content-around profile">
                                    <Loader/>
                                </div>
                            </div>
                        </section>
                }
            </Container>
}

export default ProfileContainer
import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

import FormContainer from "YConnect/Containers/Form.container"
import DEFAULT_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Default.template.json"

import AnswersEditor from "YConnect/PagesComponents/CMS/Editors/Dialogue.editor/Answers.editor"
import AvatarsEditor from "YConnect/PagesComponents/CMS/Editors/Dialogue.editor/Avatars.editor"
import MessagesEditor from "YConnect/PagesComponents/CMS/Editors/Components/Messages.editor"

type DialogueTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const DialogueTemplateEditor = ({
    question,
    onChangeValues
}:DialogueTemplateEditorProps) => {

    const [values, setValues] = useState<QuestionType>(question)

    const handleChangeValues = (values:any) => addValues(values)
  
    const addValues = (newValues:any) => setValues({...values, ...newValues})

    useEffect(() => {
        if(values){
            onChangeValues(values)
        }
    }, [values])

    return  <Row>
                <Col md={12}>
                    <FormContainer
                        values={values}
                        onChange={({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                        template={[...DEFAULT_TEMPLATE.map((field) => ({...field}))]}/>
                </Col>
                <AvatarsEditor 
                    Order={1}
                    question={values}
                    onChange={handleChangeValues}/>
                <AvatarsEditor 
                    Order={2}
                    question={values}
                    onChange={handleChangeValues}/>
                <AnswersEditor 
                    question={values}
                    onChange={handleChangeValues}/>
                <MessagesEditor
                    question={values}
                    onChange={handleChangeValues}/>
            </Row>
}

export default DialogueTemplateEditor

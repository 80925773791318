const MainTheme = {
/** background color */
backgroundPage  : "#f0f5f6",
backgroundWhite : "#fff",

/** main colors */
mainPurple         : "#4e2d78",
errorShadowColor   : "#ff1a4a59",
errorColor         : "#FF1A4A",
successShadowColor : "#30dab7b0",
successColor       : "#00A281",
errorColor2        : "#FF1A4A",
successColor2      : "#00A281",
lightPurple        : "#643E94",
lightPurpleInvert  : "#643E94",
lightIvory         : "#EEEEE0",
lightGray          : "#f5f5f5",
lightGreen         : "#BFD747",
lightBlue          : "#00b5d3",
darkPurple         : "#2C134C",
borderLightGray    : "#f0f5f6",
filterSvg          : "unset",
filterSvgGreen     : "unset",
blockedSvg         : "invert(1)",
line               : "#c9c9c9",
boxShadow          : "rgba(0, 0, 0, 0.11)",
blocked            : "#e5e5e5",

/** text color */
textColor      : "#424242",
textColor2     : "#424242",
textLightGray  : "#c5cbcc",
mutedTextColor : "#6c757d",
greentextColor : "#79b100",
title          : "#565656",
textWhite      : "#fff",
textWhite2     : "#fff",
linkHoverColor : "#000",
grayScale      : "grayscale(1)",
brightness     : "brightness(100)",
textBlocked    : "#6a6a6a",
textInvert     : "#fff",


/** buttons */
clickable                 : "#79b100",
backgroundBtDisabled      : "#E1E6E7",
colorBtDisabled           : "#989898",
backgroundRadioActive     : "#F0F5F6",
backgroundRadioActiveText : "#79b100",
inputDisabled             : "#e9ecef",
btnDisabledText           : "#989898",

/** Global style */
fontFamily   : "Panton-Regular, arial",
bodyFontSize : "16px",

/** Nanbar desktop */
paddingLink   : "0 10px",
paddingLinkLx : "0 23px",

/** Messages */
selectedLine : "#00B5D3",
hoverMessage : "#f5f5f5",
focusSelect  : "#F5F5F5",
selectText   : "#fff",

/** Tabs */
tabTextActive: "#4e2d78",

/** Tables */
lineTable: "#4e2d78",

}

export default MainTheme

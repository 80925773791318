import * as React from 'react'
import { useContext } from "react"
import * as downloadGreen  from "YConnect/Assets/svg/download.svg"
import GoodLookingModal from "YConnect/Modals/GoodLooking.modal"
import Table from "YConnect/Components/Table"
import UserContext from "YConnect/User.context";
import getStaticUrl       from "YConnect/Utils/GetStaticUrl.util"

const EpubsModal = ({onClose, visible, epubs}:any) => {
  const {
    idProfessor,
    observador,
    colaborador,
    usuarioTrial,
    usuarioDegustacao,
  }: any = useContext(UserContext);

  const isStudent = () =>
    idProfessor == 0 &&
    !observador &&
    !colaborador &&
    !usuarioTrial &&
    !usuarioDegustacao;

  const CONFIG_TABLE_BOOK: Array<ColumnConfigType> = [
    {
        label: "Title",
        formatter: (data: any) => <div className="download-title" title={data.title}>{data.title}<br /></div>
    },
    {
        label: "Link",
        formatter: (data: any) => {

          if(data.isDownloadable){
            return <a
                    className="pointer text-clickable"
                    href={getStaticUrl(`${!data.name_File.includes('midia:') ? 'arquivos_downloads/':''}${data.name_File}`)}
                    download
                    >  Download <img src={downloadGreen} />
                </a>
          }

            return <div className="pointer text-clickable">
                <a href={data.name_File} target="_blank" className="mt-1">
                  Click here to open
                </a>
            </div>
        }
    }
  ]

  return (
    <GoodLookingModal onClose={onClose} visible={visible} title="E-pub">
      <Table data={!isStudent() ? epubs : epubs.filter((each:any) => each.isForStudent === true)} config={CONFIG_TABLE_BOOK} />
    </GoodLookingModal>
  )
}

export default EpubsModal


import {useState} from "react"

const useSelectData = () => {

    const [selectData, setSelectData] = useState<any>({})

    const AddSelectData = (keystone:string, idLabel:string, valueLabel:string, data:any) => {
            const keystoneData = data ? data.map((row:any) => ({id: row[idLabel], value: row[valueLabel]})): []
            setSelectData((prevState:any) => ({...prevState, [keystone]: keystoneData}))
    }

    const RemoveSelectData = (keystone:string) => 
        setSelectData(Object.keys(selectData)
        .reduce((acc:any, key:any) => {
            if(key !== keystone) return {...acc, [key]:selectData[key]}
            return acc
        }, {}))

    return {
        selectData,
        AddSelectData,
        RemoveSelectData
    }

}

export default useSelectData
import * as React from "react";
import { useEffect, useRef, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { LinkStyle } from "YConnect/Components/Button";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Tab } from "react-bootstrap";

import InteractionTeacher from "YConnect/Modals/TaskStarterInfo.modal/InteractionTeacher";
import InteractionAluno from "YConnect/Modals/TaskStarterInfo.modal/InteractionAluno";
import InteractionStudentPresence from "YConnect/Modals/TaskStarterInfo.modal/InteractionStudentPresence";

import API from "YConnect/API";
import getConfig from "YConnect/Utils/GetRequestConfig.util";

import AlphaBlackStyle from "YConnect/Styles/AlphaBlack.style";

import UserContext from "YConnect/User.context";

import * as naoFeito from "YConnect/Assets/png/Group 106@2x.png";
import * as checkfeito from "YConnect/Assets/png/Icon awesome-check-circle@2x.png";
import * as setaDireita from "YConnect/Assets/png/Icon awesome-chevron-right@2x.png";
import * as balao from "YConnect/Assets/png/Icon metro-bubble@2x.png";
import TabStyle from "YConnect/Styles/Tab.style";
import ExerciseLineStyled from "YConnect/Modals/TaskStarterInfo.modal/ExerciseLine.styled"
import Toastify from "toastify-js";

const TaskStarterInfoStyle = styled.div`
    h3 {
        color: ${(props) => props.theme.main.mainPurple};
        font-weight: bold;
    }

    .lateral-header--title {
        border-bottom: 2px solid ${(props) => props.theme.main.mainPurple}
        margin-bottom: 20px
    }

    hr {
        margin: 30px -16px 20px;
        border: 0;
        border-top: 1px solid ${(props) => props.theme.main.mainPurple};
    }

    p {
        position: relative;
        bottom: 4px;
        font-weight: bold;
        &.feito {
            color: ${(props) => props.theme.main.greentextColor};
            font-weight: bold;
        }

        @media screen and (max-width: 768px) {
            max-width: 16.8rem;
            padding: 0px 10px 0px 36px;
        }
    }

    img {
        width: 25px;
        height: 25px;
        &.seta {
            width: 9px !important;
            height: 15px !important;
        }
    }

`;

const LinkStyled = styled(Link)`
    color: ${(props) => props.theme.main.textWhite} !important;
    self-align: flex-end;
`;


const InteractionStyled = styled.div`
    padding: 8px;

    .interaction-content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding-right: 32px;
        padding-bottom: 50px;

        > div {
            max-width: 50%;
        }

        h2 {
            margin-bottom: 4px;
            color: ${(props) => props.theme.main.mainPurple};
            font-weight: bold;
        }

        h3 {
            color: ${(props) => props.theme.main.mainPurple};
            font-weight: bold;
        }
        p {
            margin: 24px 0 12px;
            width: 85%;
            color: ${(props) => props.theme.main.textBlocked};
        }
        a {
            color: ${(props) => props.theme.main.greentextColor};
            cursor: pointer;
        }

        margin-top: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    img {
        width: 25%;
        height: auto;
    }

`;

const TaskStarterInfo = ({
    courseId,
    exercicios,
    taskStarterInfoAtivo,
    onClose,
    key,
    filterUnit,
    secao,
}: any) => {
    const {
        idUsuario,
        nome,
        idProfessor,
        observador,
        colaborador,
        usuarioTrial,
        usuarioDegustacao,
        idCursoAtual,
    } = useContext(UserContext);
    const [contrast, setContrast] = useState("");
    const [listaAlunoSecao, setListaAlunoSecao] = useState([]);
    const isStudent = () => {
        return (
            idProfessor == 0 &&
            !observador &&
            !colaborador &&
            !usuarioTrial &&
            !usuarioDegustacao
        );
    };

    useEffect(() => {
        if (!isStudent()) {
            GetAlunosSecao();
        }
        setContrast(localStorage.getItem("contrast"))
    }, []);

    const GetAlunosSecao = () => {
        API.Starter.GetAlunoSecao(
            {
                id_secao: secao.id_secao,
                id_professor: idProfessor,
            },
            getConfig()
        )
            .then(({ data }: any) => {
                setListaAlunoSecao(data);
            })
            .catch((e: any) => {
                Toastify({
                    text: e.response.data.message,
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            });
    };

    const handlePresenceStudent = (id_aluno: any) => {
        //67 é o id do curso Starter
        API.Starter.UpdatePresenceClass(
            {
                idCurso: 67,
                idAluno: id_aluno,
            },
            getConfig()
        )
            .then(({ data }: any) => {
                Toastify({
                    text: "Success!",
                    duration: 3000,
                    className: "toastify-success",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
                GetAlunosSecao();
            })
            .catch((e: any) => {
                Toastify({
                    text: e.response.data.message,
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            });
    };

    const handleNotifySchool = () => {
        API.Starter.NotifySchool(
            {
                id: courseId,
                idAluno: idUsuario,
            },
            getConfig()
        )
            .then(({ data }: any) => {
                Toastify({
                    text: "Success! <br> The school will contact you within 48 hours to schedule this class.",
                    duration: 3000,
                    className: "toastify-success",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            })
            .catch((e: any) => {
                Toastify({
                    text: e.response.data.message,
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            });
    };

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose(false);
            }
        };
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    };
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);
    const isMobile = window.innerWidth < 768;

    return (
        <>
            {taskStarterInfoAtivo && <AlphaBlackStyle />}

            <TaskStarterInfoStyle key={key}>
                <CSSTransition
                    classNames="dialog YC-lateral-menu"
                    timeout={300}
                    in={taskStarterInfoAtivo}
                    unmountOnExit
                >
                    <div
                        style={{
                            zIndex: 10000,
                            overflowY: 'scroll',
                            width: isMobile ? '100vw' : '650px',
                        }}
                        className={`YC-lateral lateral-header lateral-header-course d-flex flex-column`}
                        ref={wrapperRef}
                    >
                        <h4
                            className="lateral-header--title"
                        >
                            {secao.nome}
                            <LinkStyle
                                label="Close x"
                                className={'close-modal text-right header-btn'}
                                onClick={() => onClose(!taskStarterInfoAtivo)}
                            />
                        </h4>
                        {secao.objetivo_linguistico !== "INTERACTION_CLASS" &&
                            exercicios[0].objetivo_secao.toUpperCase() !==
                            "INTERACTION CLASS" ? (
                                exercicios.map((exercicio: any, key: any) => {
                                    return (
                                        <Link
                                            to={`/courses/67/unit/${filterUnit}/view-book/${exercicios[0].nome_secao}/${exercicios[key].ordem_exercicio}/exercise/1`}
                                            className="pointer text-clickable"
                                            key={key}
                                            onClick={() => {
                                                localStorage.setItem(
                                                    "bookInfo",
                                                    JSON.stringify(null)
                                                );
                                            }}
                                        >
                                            <div key={key}>
                                                <ExerciseLineStyled
                                                    className={`line-${key}`}
                                                >
                                                    {isStudent() && (
                                                        <img className={`${contrast} ${exercicio.exercicio_feito ? '' : 'gray'}`}
                                                            src={
                                                                exercicio.exercicio_feito
                                                                    ? checkfeito
                                                                    : naoFeito
                                                            }
                                                        />
                                                    )}

                                                    <p
                                                        className={
                                                            isStudent() &&
                                                                exercicio.exercicio_feito
                                                                ? "feito"
                                                                : ""
                                                        }
                                                    >
                                                        {" "}
                                                    Task{" "}
                                                        {
                                                            exercicio.ordem_exercicio
                                                        }{" "}
                                                    -{" "}
                                                        {exercicio.titulo_exercicio}
                                                    </p>
                                                    <img
                                                        className="seta"
                                                        src={setaDireita}
                                                    />
                                                </ExerciseLineStyled>
                                            </div>
                                        </Link>
                                    );
                                })
                            ) : (
                                <InteractionStyled>
                                    {isStudent() ? (
                                        <InteractionAluno nome={nome} balao={balao} aula={secao.nome} onNotifySchool={handleNotifySchool} />
                                    ) : !isStudent() ? (
                                        <TabStyle
                                            className="schedule-tab grade-student"
                                            defaultActiveKey="active-tab"
                                            id="explore-tab"
                                        >
                                            <Tab
                                                eventKey="active-tab"
                                                title={"Information"}
                                                className="yc-tab"
                                            >
                                                <InteractionTeacher nome={nome} balao={balao} />
                                            </Tab>
                                            <Tab
                                                eventKey="explore"
                                                title={"Attendance"}
                                                className="yc-tab w-100"
                                            >
                                                <InteractionStudentPresence listaAlunoSecao={listaAlunoSecao} onPresenceStudent={handlePresenceStudent} contrast={contrast} />
                                            </Tab>
                                        </TabStyle>
                                    ) : (
                                                ""
                                            )}
                                </InteractionStyled>
                            )}
                    </div>
                </CSSTransition>
            </TaskStarterInfoStyle>
        </>
    );
};
export default TaskStarterInfo;

import * as React from "react"


class RadioField extends React.Component<RadioFieldPropsType, {checked: string}>{

    constructor(props: any) {
        super(props)
		this.state = { checked: '' }
		this.handleChange = this.handleChange.bind(this)
    }
	
	handleChange(event: any) {
		const { props: { keystone } } = this
		const { currentTarget: { value } } = event
		this.props.onChange(keystone, value)
		this.setState({checked: value})
	}


	render() {
		let {
			props: {
				keystone,
				required,
				label,
				value,
				className,
				disabled,
				options,
				messageBlocker,
				id,
				isInvalid
			},
			handleChange
		} = this

        return <div className={`${className || ""}`}>
            {(label) ? <label className="form-label">{`${label}${required ? "*" : ""}`}</label> : ""}
			<div className={`btn-group btn-group-toggle d-flex flex-row flex-wrap  ${messageBlocker ? '' : 'mb-4'}`} 
				data-toggle="buttons" 
				id={`${id ? id: keystone}`}>
                {
                    options.map((option, key) => {
                        return (
                            <label key={key} className={`btn btn-radio ${isInvalid === true && "isInvalidRadio"} ${value === option.name ? 'active': ''} ${disabled ? 'input-disabled' : ''}`}>
								<input 
									type="radio" 
									name='gender' 
									id={option.id} 
									value={option.name} 
									disabled={disabled}
									onChange={(e) => this.handleChange(e)} 
								/> 
								{option.label}
                            </label>
                        )
                    })
                }
            </div>
            {(messageBlocker) ? <small className="form-text text-muted mb-3 text-clickable">{messageBlocker}</small> : ''}
        </div>
	}
}

export default RadioField
import { useEffect } from "react"

const useFilter = ({
    establishmentIdSelected,
    semesterIdSelected,
    courseIdSelected,
    teacherIdSelected,
    classIdSelected,
    studentIdSelected,
    setIdSelected
}:any) => {

    useEffect(() => setIdSelected({semesterIdSelected:""}), [establishmentIdSelected])
    useEffect(() => setIdSelected({courseIdSelected:""}),   [semesterIdSelected])
    useEffect(() => setIdSelected({teacherIdSelected:""}),  [courseIdSelected])
    useEffect(() => setIdSelected({classIdSelected:""}),    [teacherIdSelected])
    useEffect(() => setIdSelected({studentIdSelected:""}),  [classIdSelected])
    useEffect(() => setIdSelected({periodIdSelected:"0"}),  [studentIdSelected])
}

export default useFilter
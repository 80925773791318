import * as React from "react"
import { Modal }  from "react-bootstrap"

import {ButtonStyle} from "YConnect/Components/Button"

type RemoveQuestionModalProps = {
    show:Boolean
    question:QuestionType
    onCancel:any
    onRemove:any
}

const RemoveQuestionModal = ({
    show, 
    question, 
    onCancel, 
    onRemove
}:RemoveQuestionModalProps) =>{

    return <Modal show={show} onHide={onCancel} size="lg">
                <Modal.Header
                    closeButton 
                    closeLabel={"Close"} 
                    className="first-access" 
                    style={{zIndex: 10}}>
                        {question && <Modal.Title>Are you sure want to remove <b>Question {question.QuestionOrder}</b> ?</Modal.Title>}
                </Modal.Header>
                <Modal.Footer>
                    {question && <ButtonStyle 
                            onClick={onRemove}
                            label="Yes" 
                            icon="checkedGreen2" />
                    }
                    <ButtonStyle 
                            onClick={onCancel}
                            label="No" 
                            icon="closeGreen" />
                </Modal.Footer>
            </Modal>
}


export default RemoveQuestionModal
import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

import VideoMainView from "YConnect/PagesComponents/CMS/Views/VideoMain.view"

import FormContainer from "YConnect/Containers/Form.container"
import AnswersChooseMultipleTable from "YConnect/PagesComponents/CMS/Editors/ChooseMultiple.editor/AnswersChooseMultipleTable"

import VIDEO_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Video.template.json"

import { ButtonStyle } from "YConnect/Components/Button"

type ChooseMultipleVideoEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const ChooseMultipleVideoControlTemplateEditor = ({
    question,
    onChangeValues
}:ChooseMultipleVideoEditorProps)=>{

    const [values, setValues]           = useState(question)    
    const [Answers, setAnswers]         = useState<Array<AnswerType>>(question.Answers)
    const [urlNewVideo, setURLNewVideo] = useState<string>()

    const handleChangeValues = (values:any) => addValues(values)
    const handleChangeAnswer = (values:Array<AnswerType>) => setAnswers(values)

    const addValues = (newValues:any) => {
        
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }

    useEffect(() => {
        setValues({...values, Answers})
        onChangeValues({...values, Answers})
    }, [Answers])

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])


    const addMainExerciseMedia = ({Source, MediaType}:any) => {
        const newValues = {
            ...values,
            ExerciseMedias: [{Order:0, Source, MediaType}, ...values.ExerciseMedias.filter(({Order}) => Order !== 0)]
        }
        setValues(newValues)
        onChangeValues(newValues)
    }

    const getMainExerciseMedia = () =>
        values && values.ExerciseMedias.find(({Order}) => Order === 0)

    const handleAddNewVideo = () => {
        addMainExerciseMedia({Source:urlNewVideo, MediaType:"Video"})
        setURLNewVideo("")
    }

    const handleRemoveExerciseMedia = () => {
        const newValues = {
            ...values,
            ExerciseMedias: values.ExerciseMedias
            .filter(({Order}) => Order !== 0 )
        }

        setValues(newValues)
        onChangeValues(newValues)
    }

    return  <>
                <VideoMainView medias={values && values.ExerciseMedias}/>
                {
                    getMainExerciseMedia()
                    && <Row className="py-3 justify-content-center">
                            <Col md="auto">
                                <ButtonStyle
                                    size      = "130"
                                    onClick   = {handleRemoveExerciseMedia}
                                    icon      = "remove"
                                    label     = "Remove"/>
                            </Col>
                        </Row>
                }
                {
                     !(getMainExerciseMedia()
                     && getMainExerciseMedia().MediaType.toLowerCase() === "video")
                     && <Row className="m-0 align-items-center mt-3">
                            <Col md={12} className="form-group mb-0">
                                    <label className="form-label">video url</label>
                                    <input
                                        type="text"
                                        className={`form-control`}
                                        value={urlNewVideo}
                                        onChange={({ target: { value } }) => setURLNewVideo(value)}/>
                            </Col>
                        </Row>
                }
                
                {
                    !(getMainExerciseMedia()
                    && getMainExerciseMedia().MediaType.toLowerCase() === "video")
                    && <Row className="py-3">
                            <Col md="auto">
                                <ButtonStyle
                                    green
                                    disabled  = {!urlNewVideo}
                                    size      = "190"
                                    onClick   = {() => handleAddNewVideo()}
                                    icon      = "plusGreen"
                                    label     = "Add Video"/>
                            </Col>
                        </Row>
                }
                
                <Row>
                    <Col md={12}>
                        <FormContainer 
                            values   = {values}
                            onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                            template = {[...VIDEO_TEMPLATE.map((field) => ({...field}))]}/>
                    </Col>
                    <Col md={12} className="mt-3">
                        <h3 className="font-weight-bold">Answers{Answers.length > 0 && ` (${Answers.length})`}</h3>
                        <AnswersChooseMultipleTable
                            nextQuestionOrder = {question.nextQuestionOrder}
                            values            = {Answers}
                            onChange          = {(values:Array<AnswerType>) => handleChangeAnswer(values)}/>
                    </Col>
            </Row>
        </>
}
          
export default ChooseMultipleVideoControlTemplateEditor
import * as React from "react"
import orderBy    from "lodash/orderBy"

import CardBestRankedStudentsStyle from "YConnect/PagesComponents/PrePostReport/School.components/BestRankedStudents/CardBestRankedStudents.style"

const CardBestRankedStudents = ({
    isPost,
    rankedStudentEvo,
    bestRankedStudents
}:any) =>
    <CardBestRankedStudentsStyle>
        {(rankedStudentEvo === "evolution"
            ? orderBy(bestRankedStudents, "evolucao", "desc")
            : bestRankedStudents
        )
        .filter(({flIsPre}:any) => flIsPre === !isPost)
        .map((student: any, key: any) => 
                <div className="ranked-card" key={key}>
                    <div className="content">
                        <span className="title">
                            Name
                        </span>
                        <span className="information">
                            {student.nome}
                        </span>
                    </div>
                    <div className="content">
                        <span className="title">
                            Class
                        </span>
                        <span className="information">
                            {student.nomeTurma}
                        </span>
                    </div>
                    <div className="content">
                        <span className="title">
                            Teacher
                        </span>
                        <span className="information">
                            {student.nomeProfessor}
                        </span>
                    </div>
                    <div className="content">
                        <span className="title">
                            Correct answers
                        </span>
                        <span className="information">
                            {student.respCorretas}
                        </span>
                    </div>
                    <div className="content">
                        <span className="title">
                            {rankedStudentEvo === "evolution" ? "Evolution" : "Score"}
                        </span>
                        <span className="information">
                            {
                                rankedStudentEvo === "evolution"
                                    ? `${student.evolucao}%`
                                    : `${(student.media * 100).toFixed(1)}%`
                            }
                        </span>
                    </div>
                </div>
        )}
    </CardBestRankedStudentsStyle>

export default CardBestRankedStudents
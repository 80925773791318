import styled from "styled-components"

const ExerciseSubStatementStyle = styled.div`
    color: ${props => props.theme.main.textColor};
    background: ${props => props.theme.main.backgroundPage};
    border: ${props => props.theme.main.borderLightGray};
    padding: 50px 16px;
    border-style: solid none;

    @media screen and (min-width: 1024px) {
        padding: 50px 0;
    }

    .align-image-cms {
        display: flex;
        justify-content: center
    }
`
export default ExerciseSubStatementStyle
import * as React from "react";

import {ButtonStyle} from "YConnect/Components/Button";
import Table         from "YConnect/Components/Table";
import styled        from "styled-components";

import {CONFIG_TABLE_REPORT} from "YConnect/Configs/Tables/ReportFinalTest.table.config";

import * as closeRed from "YConnect/Assets/svg/closeRed.svg";
import * as checkedGreen from "YConnect/Assets/svg/checkedGreen.svg";
import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg";

const ReportUnitStyled = styled.div`
    border-bottom: 3px solid ${props => props.theme.main.borderLightGray};
    margin-bottom: 24px;

    .report-body {
        border-bottom: 1px solid ${props => props.theme.main.lightGray};
        margin-bottom: 24px;
        &:last-child {
            border-bottom: 1px solid transparent;
        }
        .title {
            font-weight: bold;
            width: 45%;
        }
        .info {
            width: 45%;
        }

        .report-score {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 45%;
        }
    }
`

const ScoreFinalTest = ({
    reportsFinal,
    idCurso,
    idNotaFinal
}:any) => {

    const questionOrder = {
        label: "Question",
        formatter: ({id_prova_final_resposta}: any, key: any) => <span>Question {key+1} of {reportsFinal.length}</span>
    }

    return (
        <div className="score">
            <br />
            {
                window.innerWidth > 1024
                ? <Table data={reportsFinal} config={[questionOrder, ...CONFIG_TABLE_REPORT]} />
                : reportsFinal.map((report: any, index: number) => {
                    return <ReportUnitStyled key={index}>
                    <div className="report-body d-flex">
                        <span className="title">Question</span>
                        <span className="info">Question {index+1} of {reportsFinal.length}</span>
                    </div>
                    <div className="report-body d-flex">
                        <span className="title">Type</span>
                        <span className="info">{report.categoria_execicio.nome}</span>
                    </div>
                    <div className="report-body d-flex">
                        <span className="title">Status</span>
                        {report.provaDTO.prova_Final_Resposta.correto
                                    ? <img src={checkedGreen} alt="" className="pl-2" />
                                    : <img src={closeRed} alt="" className="pl-2" />
                        }
                    </div>
                    <div className="report-body d-flex">
                        <span className="title">Score</span>
                        {report.provaDTO.prova_Final_Resposta.correto
                                    ? <img src={checkedGreen} alt="" className="pl-2" />
                                    : <img src={closeRed} alt="" className="pl-2" />
                        }
                    </div>
                    <div className="report-body d-flex">
                        <span className="title">Details</span>
                        <span className="info">
                            <a href={`#/reports/view-report-final-test/${report.provaDTO.provaFinal.id_curso}/student/${report.provaDTO.provaFinal.id_aluno}/score/${report.provaDTO.provaFinal.id_prova_final}/exercise/${report.provaDTO.prova_Final_Resposta.id_exercicio}`}>
                                Consult
                                <img src={arrowRight} alt="" className="pl-2" />
                            </a>
                        </span>
                    </div>
                </ReportUnitStyled>
                })
            }
        </div>
    )
}

export default ScoreFinalTest

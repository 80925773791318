import * as React                  from "react"
import {
    useEffect,
    useState,
    useContext,
    useRef
}                                  from "react"
import {Container, Row, Col, Card} from "reactstrap"
import styled                      from "styled-components"

import Loader           from "YConnect/Components/Loader"
import { BreadcrumbCard } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle        from "YConnect/Components/PageTitle"
import API              from "YConnect/API"
import UserContext      from "YConnect/User.context"
import { ButtonStyle, LinkStyle }  from "YConnect/Components/Button"
import Table            from "YConnect/Components/Table"

import getConfig        from "YConnect/Utils/GetRequestConfig.util"

import AlphaBlackStyle from "YConnect/Styles/AlphaBlack.style"
import { CSSTransition } from "react-transition-group"

import * as remove          from "YConnect/Assets/svg/remove.svg"
import * as arrowNext       from "YConnect/Assets/svg/arrow-next.svg"
import * as arrowPrevious   from "YConnect/Assets/svg/arrow-previous.svg"
import { FormGroup } from "react-bootstrap"
import formatDate from "YConnect/Utils/FormatDate.util"

import Axios from "axios"
import getBaseURLWebApi from "YConnect/Utils/GetBaseURLWebAPI.util"
import Toastify from "toastify-js"

const FormGroupStyled = styled.div`
    @media (min-width: 768px) {
        width: 50%
    }
`

const ButtonStyleNew = styled(ButtonStyle)`
    width: 100%
    @media (min-width: 768px) {
        width: 25%
    }

    img {
        filter: grayscale(100%) brightness(0) invert(1)
    }
`

const ButtonStyleWhiteIcon = styled(ButtonStyle)`
    img {
        filter: invert(1)
        width: 33px
    }
`

const CardPage = () => {

    const CONFIG_TABLE_EXPLORE: Array<ColumnConfigType> = [
        {
            label: "Title & file",
            formatter: (data: any) => <div className="download-title">{data.title_file}<br /><small className="text-muted">{data.name_file}</small></div>
        },
        {
            label: "Type & weight",
            formatter: (data: any) => <>{data.explore_type}<br /><small className="text-muted">{data.size_file} kb</small></>
        },
        {
            label: "Date",
            formatter: (data: any) => formatDate(data.data_file, false)
        },
        {
            label: "Action",
            formatter: (data: any) => {
                return <span className="download-link pointer text-clickable text-right" onClick={() => removeExploreSchool(data.exploreId)}><img src={remove} /> Remove</span>
            }
        }
    ]

    const [exploreCards, setExploreCards]             = useState([])
    const [hasNextExplore, setNextExplore]            = useState(false)
    const [hasPreviousExplore, setPreviousExplore]    = useState(false)
    const [totalExplore, setTotalExplore]             = useState(0)
    const [currentPage, setCurrentPage]               = useState(1)


    const [uploadNameCard, setNameCard] = useState("")
    const [uploadTypeCard, setTypeCard] = useState("art")


    const [exploreSearch, setExploreSearch] = useState("")
    const [composeMenu, setComposeMenu]     = useState(false)
    const [cardFile, setCardFile]           = useState()
    const [invalidFormat, setInvalidFormat] = useState(false)
    const [isLoading, setLoading]           = useState(false)
    const [isSaving, setSaving]             = useState(false)

    const { idUsuario }:any = useContext(UserContext)

    const startSchools = currentPage === 1 ? 1 : 10 * (currentPage - 1) + 1
    const endSchools   = currentPage === 1 ? currentPage + 9 : 10 * (currentPage - 1) + 10

    const getSchoolCards = (cardName = "", type = "", page = 1) => {
        setLoading(true)
        API.Downloads.Explore({ nomeFicha: cardName, type: type, pagina: page }, getConfig())
            .then((response: any) => {
                setPreviousExplore(response.data.fichasExplorePaginado.hasPrev)
                setNextExplore(response.data.fichasExplorePaginado.hasNext)
                setTotalExplore(response.data.fichasExplorePaginado.totalFichas)
                setExploreCards(response.data.fichasExplorePaginado.fichaExplore)
            })
            .finally(() => setLoading(false))
    }

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setComposeMenu(false)
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        })
    }

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef)


    useEffect(() => {
        getSchoolCards()
    }, [])


    const handleSearch = () => {
        setCurrentPage(1)
        getSchoolCards(exploreSearch)
    }

    const handleNextPage = () => {
        if (hasNextExplore) {
            getSchoolCards(exploreSearch, "", currentPage + 1)
            setCurrentPage(currentPage + 1)
        }
    }

    const handlePreviousPage = () => {
        if (hasPreviousExplore) {
            getSchoolCards(exploreSearch, "", currentPage - 1)
            setCurrentPage(currentPage - 1)
        }
    }

    const addNewCard = async () => {
        setSaving(true)
        const data = new FormData()
        data.append("type", uploadTypeCard.toUpperCase())

        for (const file of cardFile) {
            data.append("ficha[]", file)
        }

        data.append("idUsuario", idUsuario)

        API.ExploreReport.UploadCard(data, getConfig())
        .then((res: any) => {
            setComposeMenu(false)
            Toastify({
                text: "Success! <br> Uploaded with success!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            getSchoolCards()
            setNameCard("")
        })
        .catch((err: any) => {
            Toastify({
                text: "Error! <br>" + err.response.data.message,
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
        .finally(() => setSaving(false))
    }

    const removeExploreSchool = (id: number) => {
        setCurrentPage(1)
        Axios.delete(`${getBaseURLWebApi()}/api/explore?exploreId=${id}&idUsuario=${idUsuario}`, {
            headers: {
                Authorization: localStorage.getItem("Authorization")
            }
        })
        .then((res: any) => {
            setComposeMenu(false)
            Toastify({
                text: "Success! <br> Card has been removed with success!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            getSchoolCards()
        }).catch((err: any) => {
            Toastify({
                text: "Error! <br>" + err.response.data.message,
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
    }

    return <>
        <Container>
            <BreadcrumbCard/>
            <PageTitle label="Upload cards" className={"col-xs-12 col-md-6 d-flex flex-row flex-wrap justify-content-between"}>
                <div className="btn-fixed col-md-6 d-flex justify-content-end">
                    <ButtonStyleWhiteIcon green
                        type    = "submit"
                        label   = "Add card"
                        onClick = {() => setComposeMenu(true)}
                        size    = {"small"}
                        icon    = {"plus"}
                    />
                </div>
            </PageTitle>

            {
                isLoading
                ? <Loader/>
                : <Row className="mb-5">
                <Col md={12}>
                    <Card className="users-table shadow">
                        <header className="users-table-header mb-2">

                            <FormGroupStyled className={`form-group`}>
                                <label className="form-label">Cards</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search for a card"
                                    value={exploreSearch}
                                    onChange={(e) => setExploreSearch(e.target.value)}
                                    onKeyPress={(e) => e.key === "Enter" ? handleSearch() : null}
                                />
                            </FormGroupStyled>

                            <ButtonStyleNew
                                green
                                label="Search card"
                                icon="search"
                                className="mb-3"
                                onClick={handleSearch} />
                        </header>

                        <header className="inbox-header">
                            <div className="email-input">
                            </div>
                            <div className="email-filter">
                                {<div className="pagination align-items-center d-none d-lg-flex flex-row flex-wrap justify-content-end">
                                    <img
                                        src={arrowPrevious}
                                        className="pr-4"
                                        style={{
                                            cursor: hasPreviousExplore ? "pointer" : "not-allowed",
                                            filter: hasPreviousExplore ? "grayscale(0)" : "grayscale(100%)"
                                        }}
                                        onClick={() => handlePreviousPage()}
                                    />
                                    <span>
                                        Showing <b>{startSchools} - {hasNextExplore ? endSchools : totalExplore} </b> of {totalExplore}
                                    </span>
                                    <img
                                        src={arrowNext}
                                        className="pl-4"
                                        style={{
                                            cursor: hasNextExplore ? "pointer" : "not-allowed",
                                            filter: hasNextExplore ? "grayscale(0)" : "grayscale(100%)"
                                        }}
                                        onClick={() => handleNextPage()}
                                    />
                                </div>}
                            </div>
                        </header>

                        {
                            window.innerWidth > 768
                            ? <Table data={exploreCards} config={CONFIG_TABLE_EXPLORE} className={"explore-table"}/>
                            : exploreCards.map((item: any, key: any) => {
                                return (
                                    <div className="download-mobile" key={key}>
                                        <div className="book">
                                            <div className="book-row d-flex flex-row">
                                                <span className="w-40">Name</span>
                                                <span className="w-40">{item.nm_estab}</span>
                                            </div>
                                            <div className="book-row d-flex flex-row">
                                                <span className="w-40">Action</span>
                                                <span className="download-link pointer text-clickable" onClick={() => removeExploreSchool(item.exploreId)}>
                                                    Remove <img src={remove} />
                                                </span>
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            })
                        }
                    </Card>
                </Col>
            </Row>
            }

        </Container>


        {composeMenu && <AlphaBlackStyle />}
        <CSSTransition classNames="dialog YC-lateral-menu" timeout={300} in={composeMenu} unmountOnExit>
            <div style={{ zIndex: 10000 }} className="YC-lateral lateral-header lateral-header-messages shadow d-flex flex-column" ref={wrapperRef}>
                <h4 className="align-items-center d-flex flex-row justify-content-between mb-3">
                    Add new card
                    <LinkStyle
                        label="Close x"
                        className="close-modal text-right header-btn"
                        onClick={() => setComposeMenu(false)}
                    />
                </h4>
                <FormGroup>
                    <label className="form-label text-clickable label-select">Type</label>
                    <select className="form-control" onChange={(e) => setTypeCard(e.target.value)} value={uploadTypeCard}>
                        <option value="all">All</option>
                        <option value="art">Art</option>
                        <option value="body">Body</option>
                        <option value="business">Business</option>
                        <option value="tech">Tech</option>
                    </select>
                </FormGroup>
                <FormGroup>
                    <label className="form-label">File</label>
                    <input
                        type     = "file"
                        accept   = "application/pdf"
                        onChange = {(e) => setCardFile(e.target.files)}
                        multiple
                    />
                </FormGroup>
                {invalidFormat && <small className="form-text text-danger">Invalid file format! Please, insert a PDF file.</small>}
                <div className="w-100 mt-3 d-flex justify-content-end">
                    <ButtonStyleNew
                        green
                        label={`${isSaving ? "Saving..." : "Save"}`}
                        icon="save"
                        className="mb-3"
                        onClick={addNewCard}
                        disabled={!uploadTypeCard || !cardFile || isSaving}
                    />
                </div>
            </div>
        </CSSTransition>
    </>
}

export default CardPage

import * as React from "react"

import {ButtonStyle} from "YConnect/Components/Button"
import Table         from "YConnect/Components/Table"
import styled        from "styled-components"

import CONFIG_TABLE_REPORT_UNIT, {CONFIG_TABLE_REPORT} from "YConnect/Configs/Tables/ReportResourceBookTaskUnit.table.config"

const ReportUnitStyled = styled.div`
    border-bottom: 3px solid ${props => props.theme.main.borderLightGray};
    margin-bottom: 24px;

    .report-body {
        border-bottom: 1px solid ${props => props.theme.main.lightGray};
        margin-bottom: 24px;
        &:last-child {
            border-bottom: 1px solid transparent;
        }
        .title {
            font-weight: bold;
            width: 45%;
        }
        .info {
            width: 65%;
        }

        .report-score {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 65%;
        }
    }
`

const ScoreUnit = ({
    report,
    onDownload,
    onPrint,
    filterComponent,
    showUnit
}:any) => <div className="score">
        <header className="score-header flex-row">
        <div className="btn-fixed d-flex text-right btn-profile">
            {(onDownload || onPrint) && <div className="header-cta header-student d-flex flex-row">
                {onDownload && <ButtonStyle label="Download" icon="download" onClick={onDownload} />}
                {onPrint && <ButtonStyle label="Print" icon="print" onClick={onPrint} />}
            </div>}
        </div>

            {filterComponent}
        </header>
                {
                    window.innerWidth > 1024
                    ? <Table data={report} config={showUnit ? CONFIG_TABLE_REPORT_UNIT : CONFIG_TABLE_REPORT} />
                    : report.map((report: any, key: any) => {
                        return <ReportUnitStyled key={key}>
                        <div className="report-body d-flex">
                            <span className="title">Unit</span>
                            <span className="info">{report.idCurso == 69 || report.idCurso == 70 || report.idCurso == 82 || report.idCurso == 83 || report.idCurso == 85 || report.idCurso == 86 || report.idCurso == 87 ? report.nomeUnidade : report.unidade}</span>
                        </div>
                        <div className="report-body d-flex">
                            <span className="title">Concluded sections</span>
                            <div className="report-score">
                                <div className="d-flex flex-column">
                                    <span>{report.concludedFirstTry}%</span>
                                    <span>1st attempt</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span>{report.concludedLastTry}%</span>
                                    <span>2nd attempt</span>
                                </div>
                            </div>
                        </div>
                        <div className="report-body d-flex">
                            <span className="title">Average score</span>
                            <div className="report-score">
                                <div className="d-flex flex-column">
                                    <span>{report.scoreFirstAttempt || report.scoreFirstAttempt ===0 ? report.scoreFirstAttempt : "-"}</span>
                                    <span>{report.scoreFirstAttempt || report.scoreFirstAttempt ===0 ? "1st attempt" : null }</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span>{report.scoreSecondAttempt || report.scoreSecondAttempt ===0 ? report.scoreSecondAttempt : "-"}</span>
                                    <span>{report.scoreSecondAttempt || report.scoreSecondAttempt ===0 ? "2nd attempt" : null }</span>
                                </div>
                            </div>
                        </div>
                        <div className="report-body d-flex">
                            <span className="title">Final Score</span>
                            <span className="info">{report.finalScore}</span>
                        </div>
                    </ReportUnitStyled>
                    })
                }
            </div>

export default ScoreUnit

import * as React from "react"
// import  { useState } from "react"
import {
    Card,
    Row,
    Col
} from "reactstrap"
import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"

import QuestionCardHeader from "YConnect/PagesComponents/CMS/CardHeaders/Question.cardHeader"
import QuestionView from "YConnect/PagesComponents/CMS/Components/QuestionView"

type QuestionCardProps = {
    question      : QuestionType
    listTemplates : Array<TemplateType>
    onEdit        : any
    onRemove      : any
}


const QuestionCard = ({
    question,
    listTemplates,
    onEdit,
    onRemove
}:QuestionCardProps)=>{

    const {
        Id,
        QuestionOrder,
        TemplateId
    } = question

    const [colapsed, setColapsed] = React.useState(true)

    const CMSButtonStyle = styled(ButtonStyle)`
        @media screen and (max-width: 768px) {
            margin: 0.5rem 6rem;
        }
    `

    return <Card className="p-3">
                <div className="enable-link">
                    <span />
                </div>
                <QuestionCardHeader
                    questionOrder          = {QuestionOrder}
                    listTemplates          = {listTemplates}
                    templateIdSelected     = {TemplateId}
                    onColapse              = {()=>{setColapsed(!colapsed)}}
                    colapsed               = {colapsed}/>
                {!colapsed &&
                <>
                    <QuestionView
                        listTemplates={listTemplates}
                        question={question}/>
                    <Row className="pt-3">
                        <Col md={9}></Col>
                        <Col md={3}>
                            <Row>
                                <CMSButtonStyle
                                    size      = "130"
                                    className = "cms-button mr-md-2"
                                    onClick   = {() => onRemove(question)}
                                    icon      = "remove"
                                    label     = "Remove"/>
                                <CMSButtonStyle
                                    size      = "130"
                                    className = "cms-button"
                                    onClick   = {() => onEdit(Id)}
                                    icon      = "pencilGreen"
                                    label     = "Edit"/>
                            </Row>
                        </Col>
                    </Row>
                </>
                }
            </Card>
}


export default QuestionCard

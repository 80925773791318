export default [
    {
        keystone: "Name",
        className: "col-md-12",
        type: "input",
        label: "Name"
    },
    {
        keystone: "Description",
        className: "col-md-12",
        type: "textarea",
        label: "Description"
    }
    
]
import styled from "styled-components"

const TabReportStyle = styled.div`
    background-color: ${props => props.theme.main.backgroundWhite};

    header {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
`

export default TabReportStyle
import styled from "styled-components"

const AudioDivStyle = styled.div`
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
    background-color: ${props => props.theme.main.backgroundWhite};
    position: relative;
`

export default AudioDivStyle
import styled from "styled-components"

const ExerciseLineStyled = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 16px 12px 12px 12px;;
    align-items: baseline;
    border-bottom: 1px solid #f5f5f5;

    &.line-0 {
        padding-top: 0;
    }

    p {
        width: 90%;
        padding: 0px 20px;
        margin-bottom: 0;
    }

    + hr {
        border-top-color: ${(props) => props.theme.main.line} !important;
        margin: 4px -16px 4px !important;
    }

    img {
        &.high{
            &.gray{
                filter: grayScale(100%) !important;
            }
        }
    }

    .student-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        margin-bottom: 1em;

        @media screen and (min-width: 768px) {
            width: 50%;

        }
    }
`;
export default ExerciseLineStyled
import * as React   from "react"
import ReCAPTCHA    from "react-google-recaptcha";


class InputField extends React.Component<RecaptchaInputPropsType, {}>{

  handleChange(event: any) {
    const { props: { keystone } } = this
    const { target: { value } } = event
    this.props.onChange(value)
  }

  render() {
    let {
      props: {
        keystone,
        id,
        badge
      },
      handleChange
    } = this

    return <ReCAPTCHA
        sitekey="6LdjKLMUAAAAAK-jd4Mt_Y3kwmZfhozeoDFmxeB2"
        onChange={handleChange.bind(this)}
        badge={badge ? badge : 'inline'}
    />
  }
}

export default InputField
import styled from "styled-components"
import { Row } from "reactstrap"

const ExerciseTimelineStyle = styled(Row)`
    background-color: ${props => props.theme.main.mainPurple};
    border-bottom: 1px solid ${props => props.theme.main.borderLightGray};;
    height: 3px;

    .exercise-task {
        background-color: ${props => props.theme.main.backgroundRadioActive};
        border: 1px solid ${props => props.theme.main.borderLightGray};
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        border-radius: 50px;
        height: 40px;
        padding: 10px;
        transform: translateY(-50%);
        width: 40px;

        img {
            filter: brightness(0.6);
            align-items: center;
            display: flex;
            justify-content: center;
            width: 20px;
        }

        &.active {
            background-color: ${props => props.theme.main.mainPurple};
            border: 1px solid ${props => props.theme.main.borderLightGray};

            img {
                filter: none;
            }
        }

        &.checked {
            background-color: ${props => props.theme.main.clickable};
            border: 1px solid ${props => props.theme.main.clickable};

            img {
                filter: ${props => props.theme.main.filterSvgGreen};
            }
        }

        &.checked-item {
            background-color: ${props => props.theme.main.backgroundWhite};
            border: 1px solid ${props => props.theme.main.clickable};

            .circle {
                position: absolute;
                background: ${props => props.theme.main.clickable};
                border-radius: 50%;
                height: 20px;
                width: 20px;
                top: 20%;
                left: 24%;
            }
        }
    }
`

export default ExerciseTimelineStyle
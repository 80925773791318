import { useEffect, useContext} from "react"

import API              from "YConnect/API"
import UserContext      from "YConnect/User.context"
import getRequestConfig from "YConnect/Utils/GetRequestConfig.util"

import {
    GetSemestersCombo,
    GetCoursesCombo,
    GetClassesCombo2,
    GetStudentsCombo,
    GetPeriodsCombo
} from "YConnect/PagesComponents/PrePostReport/Components/Combo.util"

const useCombo = ({
    semesterIdSelected,
    establishmentIdSelected,
    courseIdSelected,
    classIdSelected,
    teacherIdSelected,
    studentIdSelected,
    setHasFilterLoading,
    setCombo
}:any) => {

    const { 
        idUsuario, 
        idProfessor, 
        profiles, 
        estabelecimentos
    }: any = useContext(UserContext)

    useEffect(()=>{
        setHasFilterLoading(true)
        Promise.all([
            GetCoursesCombo(),
            GetSemestersCombo({
                idProfile   : idUsuario, 
                idProfessor : idProfessor
            })
        ])
        .then(([coursesCombo, semestersCombo]) => {
            setCombo({coursesCombo})
            setCombo({semestersCombo})
        })
        .finally(() => setHasFilterLoading(false))

        if (profiles.indexOf(36) > -1) {
            API.PrePostReport.getAllEstablishments({idEstab: 1}, getRequestConfig())
            .then((response:any) =>{
                setCombo({
                    establishmentsCombo: response.data.map(({nsu_estab, nm_estab}:any)=>({id:nsu_estab, value:nm_estab}))
                })
            })
        } else {
            setCombo({
                establishmentsCombo: estabelecimentos.map(({nsu_estab, nm_estab}:any)=>({id:nsu_estab, value:nm_estab}))
            })
        }

    }, [])

    useEffect(()=>{
        if(courseIdSelected && courseIdSelected!==""){
            setHasFilterLoading(true)
            GetClassesCombo2({
                idAnoSemestre     : semesterIdSelected,
                idEstabelecimento : establishmentIdSelected,
                idCurso           : courseIdSelected,
                idProfessor       : teacherIdSelected
            })
            .then((classesCombo:any) => setCombo({classesCombo}))
            .finally(() => setHasFilterLoading(false))
        }
    }, [courseIdSelected])

    useEffect(()=>{
        if(classIdSelected && classIdSelected!==""){
            setCombo({studentsCombo:[]})
            setHasFilterLoading(true)
            GetStudentsCombo({idGrupo: classIdSelected})
            .then((studentsCombo:any) => setCombo({studentsCombo}))
            .finally(() => setHasFilterLoading(false))
        }
    }, [classIdSelected])

    useEffect(()=>{
        if(studentIdSelected && studentIdSelected!==""){
            setCombo({periodCombo:[]});
            setHasFilterLoading(true);

            GetPeriodsCombo({id_curso_matricula:courseIdSelected, id_aluno_matricula:studentIdSelected})
            .then((periodCombo: any) => setCombo({periodCombo}))
            .finally(() => setHasFilterLoading(false));
        }
    }, [studentIdSelected])
}

export default useCombo
import styled from "styled-components";

const AttemptsIconStyle = styled.span`
    color: ${(props) => props.theme.main.greentextColor};
    border: 2px solid ${(props) => props.theme.main.greentextColor};
    font-size: 12px;
    border-radius: 100%;
    margin-left: 15px;
    padding: 2px 5px 1px 5px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.main.mainPurple};
        border: 2px solid ${(props) => props.theme.main.mainPurple};
    }

    &.info {
        color: ${(props) => props.theme.main.mainPurple};
        border: 2px solid ${(props) => props.theme.main.mainPurple};
        height: 25px;
        width: 25px;
        text-align: center;
        position: relative;
        bottom: 5px;
    }
`;

export default AttemptsIconStyle;

import * as React              from "react"
import {useEffect, useState}   from "react"
import { UncontrolledTooltip, Row } from "reactstrap"

import API              from "YConnect/API"
import getRequestConfig from "YConnect/Utils/GetRequestConfig.util"

import MapLineStyle     from "YConnect/PagesComponents/PrePostReport/Pearson.components/BestResultsByRegion/MapLine.style"
import BrazilMap        from "YConnect/PagesComponents/PrePostReport/Pearson.components/BestResultsByRegion/BrazilMap"
import ContentCardStyle from "YConnect/PagesComponents/PrePostReport/Pearson.components/BestResultsByRegion/ContentCard.style"
import HeaderStyle      from "YConnect/PagesComponents/PrePostReport/Pearson.components/Header.style"

import CardLoading  from "YConnect/PagesComponents/PrePostReport/Components/CardLoading"

const coordinates = [
    { "coordinates": [-70.55, -8.77],  "sigla": "AC" },
    { "coordinates": [-35.73, -9.71],  "sigla": "AL" },
    { "coordinates": [-61.66, -3.07],  "sigla": "AM" },
    { "coordinates": [-51.77, 1.41],   "sigla": "AP" },
    { "coordinates": [-38.51, -12.96], "sigla": "BA" },
    { "coordinates": [-38.54, -3.71],  "sigla": "CE" },
    { "coordinates": [-47.86, -15.83], "sigla": "DF" },
    { "coordinates": [-40.34, -19.19], "sigla": "ES" },
    { "coordinates": [-49.31, -16.64], "sigla": "GO" },
    { "coordinates": [-44.30, -2.55],  "sigla": "MA" },
    { "coordinates": [-55.42, -12.64], "sigla": "MT" },
    { "coordinates": [-54.54, -20.51], "sigla": "MS" },
    { "coordinates": [-44.38, -18.10], "sigla": "MG" },
    { "coordinates": [-52.29, -5.53],  "sigla": "PA" },
    { "coordinates": [-35.55, -7.06],  "sigla": "PB" },
    { "coordinates": [-51.55, -24.89], "sigla": "PR" },
    { "coordinates": [-35.07, -8.28],  "sigla": "PE" },
    { "coordinates": [-43.68, -8.28],  "sigla": "PI" },
    { "coordinates": [-43.15, -22.84], "sigla": "RJ" },
    { "coordinates": [-36.52, -5.22],  "sigla": "RN" },
    { "coordinates": [-62.80, -11.22], "sigla": "RO" },
    { "coordinates": [-51.22, -30.01], "sigla": "RS" },
    { "coordinates": [-61.22, 1.89],   "sigla": "RR" },
    { "coordinates": [-49.44, -27.33], "sigla": "SC" },
    { "coordinates": [-37.07, -10.90], "sigla": "SE" },
    { "coordinates": [-46.64, -23.55], "sigla": "SP" },
    { "coordinates": [-48.25, -10.25], "sigla": "TO" },
]

const BestResultsByRegion = ({
    isPost,
    semesterIdSelected,
    regionalIdSelected
}:any) => {

    const [mapInfo, setMapInfo]                         = useState()
    const [regionalsData, setRegionalsData]             = useState()
    const [mapCoordinates, setMapCoordinates]           = useState([])
    const [mapFilter, setMapFilter]                     = useState("national")
    const [regionalCoordinates, setRegionalCoordinates] = useState([])

    useEffect(()=>{

        setMapInfo(undefined)
        setRegionalsData(undefined)
        setMapCoordinates([])
        setRegionalCoordinates([])
        setMapFilter("national")

        

        if (semesterIdSelected && semesterIdSelected !== "") {
            API.PrePostTest.GetInformacoesRegiao({
                idAnoSemestreIr : semesterIdSelected, 
                mock            : false, 
                pagina          : 1, 
                regionalIr      : regionalIdSelected
            }, getRequestConfig())
            .then((response:any) => {
                setMapInfo(response.data.score)
            })

            API.PrePostTest.GetInformacoesRegional({
                idAnoSemestreRegiao : semesterIdSelected, 
                regional            : regionalIdSelected
            }, getRequestConfig())
            .then((response:any) => {
                
                setRegionalsData(response
                    .data
                    .score
                    .region.filter(({fl_is_pre}:any) => fl_is_pre === (isPost?false:true)))
            })
        }
    }, [semesterIdSelected, regionalIdSelected])

    useEffect(() => {

        if(mapInfo && mapInfo.region){
            const pointsMap = mapInfo
            .region
            .reduce((acc:any, region:any) => {
                const state =  coordinates.find((state) => state.sigla === region.sigla)
                if(state)
                    acc.push({...state, ...region})

                return acc
            }, [])
            setMapCoordinates(pointsMap.filter((city: any) => city.fl_is_pre === (isPost?false:true)).slice(0, 10))
        }

    }, [mapInfo])


    useEffect(() => {

        if(regionalsData && coordinates) {

            const pointsMap = regionalsData
            .reduce((acc:any, region:any) => {
                const state =  coordinates.find((state) => state.sigla === region.sigla)
                if(state)
                    acc.push({...state, ...region})
                
                return acc
            }, [])

            setRegionalCoordinates(regionalsData.filter((city: any) => city.fl_is_pre === (isPost?false:true)).slice(0, 10))
        }

    }, [regionalsData])

    return <>
                <HeaderStyle>
                    <h2 className="tabscore-title">Best results by region</h2>
                    <div className="order mb-3" style={{ zIndex: 10 }}>
                        <label className="form-label mr-2">Show</label>
                        <div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(e: any) => setMapFilter(e.target.value)}>
                            <label className={`btn btn-radio ${mapFilter === "national" ? "active" : ""}`}>
                                <input type="radio" name="order" value="national" />
                                National
                            </label>
                            <label className={`btn btn-radio ${mapFilter === "regional" ? "active" : ""}`}>
                                <input type="radio" name="order" value="regional" />
                                Regional
                            </label>
                        </div>
                    </div>
                </HeaderStyle>
                {!mapInfo && <CardLoading/>}
                {
                    mapInfo 
                    && <ContentCardStyle >
                            <Row>
                                {
                                    window.innerWidth > 1024 
                                    && <BrazilMap mapInfo={mapFilter === "national" ? mapCoordinates : regionalCoordinates.reduce((acc:any, region:any) => {
                                        const state =  coordinates.find((state) => state.sigla === region.sigla)
                                        if(state)
                                            acc.push({...state, ...region})
                                        
                                        return acc
                                    }, [])} />
                                }
                                <MapLineStyle className="col-md-6">
                                    {
                                    mapFilter === "national"
                                        ? mapCoordinates 
                                            && mapCoordinates.length > 0
                                            ? mapCoordinates.map((city: any, key: any) => {
                                                return <div className="map-line" key={key}>
                                                    <div className="map-line-city">
                                                        <span className="counter">{key + 1}</span>
                                                        <span className="city">
                                                            <span className="student-name" id={`city-${key}`}>{city.nome}</span>
                                                            <UncontrolledTooltip placement="top" target={`city-${key}`}>
                                                                {city.nome}
                                                            </UncontrolledTooltip >
                                                        </span>
                                                    </div>
                                                    <p className="map-line-percent">
                                                        <span className="total-percent">{(city.mediaRegion * 100).toFixed(1)}%</span>
                                                    </p>
                                                </div>
                                            })
                                            : <p>No data!</p>
                                        : regionalCoordinates 
                                            && regionalCoordinates.length > 0
                                            ? regionalCoordinates.map((city: any, key: any) => {
                                                return <div className="map-line" key={key}>
                                                    <div className="map-line-city">
                                                        <span className="counter">{key + 1}</span>
                                                        <span className="city">
                                                            <span className="student-name" id={`city-${key}`}>{city.nome}</span>
                                                            <UncontrolledTooltip placement="top" target={`city-${key}`}>
                                                                {city.nome}
                                                            </UncontrolledTooltip >
                                                        </span>
                                                    </div>
                                                    <p className="map-line-percent">
                                                        <span className="total-percent">{(city.mediaRegion * 100).toFixed(1)}%</span>
                                                    </p>
                                                </div>
                                            })
                                            : <p>No data!</p>
                                    }
                                </MapLineStyle>
                            </Row>
                        </ContentCardStyle>}
            </>
}

export default BestResultsByRegion
import styled from "styled-components"

const FooterStyle = styled.footer`
    border-top: 1px solid ${props => props.theme.main.borderLightGray};

    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        z-index: 10;
        background: ${props => props.theme.main.backgroundWhite};
        right: 0;
        left: 0;
        min-height: 56px;
        box-shadow: 0 0.5rem 1rem ${props => props.theme.main.borderLightGray};
    }
`
export default FooterStyle
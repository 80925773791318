import * as React from "react"

interface ProgressBarProps { percent: number, className?: string}

const ProgressBar = ({percent, className = ''}: ProgressBarProps) => 
    <div className={`progress ${className} ${percent === 100 ? 'completed' : ''}`}>
        <div className="progress-bar align-self-center" style={{ "width": `${percent}%` }}></div>
    </div>


export default ProgressBar
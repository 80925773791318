import * as React   from "react"
import { Modal }    from "react-bootstrap"
import styled       from "styled-components"

import { YLinkButtonStyle }  from "YConnect/Components/Button"

import * as world from "YConnect/Assets/svg/world.svg"

/** Style */
const GeneralStyle = styled(Modal)`
    .modal-content {
        width: 120% !important;
    }
`

const BodyStyle = styled(Modal.Body)`
    background: ${props => props.theme.main.backgroundWhite};
    padding: 20px !important;
    border-radius: 10px 10px 0 0 !important;

    h1 {
        text-align: center;
        margin-top: 30px;
    }

    .body-message {
        text-align: center;
        margin-top: 25px;
    }
`

const DivOvalStyle = styled.div`
    border-radius: 100%;
    box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.11);
    width: 204px;
    height: 204px;
    background: #643e94;
    padding: 19px;
    margin: auto;
    margin-top: -115px;

    div {
        background: white;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.11);
        text-align: center;
        padding: 30px;

        img {
            margin: auto;
            width: 100%;
            height: auto;
        }
    }
`

const FooterStyle = styled(Modal.Footer)`
    background: ${props => props.theme.main.backgroundWhite};
    justify-content: space-between;
`

const ButtonCloseStyle = styled(YLinkButtonStyle)`
    border: none;
    padding: 0;

    img {
        padding-left: 10px !important;
        width: 25px;
    }
`


type PostTestModalProps = {
    isShow: boolean
    onClose: () => void
    username: string
    prePost: any
}

const PostTestModal = ({ isShow, onClose, username, prePost }: PostTestModalProps) =>
    <GeneralStyle show={isShow} onHide={onClose} className="modal-background first-access">
        <Modal.Header closeLabel={'Close'} closeButton className="first-access" style={{zIndex: 10}}></Modal.Header>
        <BodyStyle>
            <div className="w-100">
                <DivOvalStyle>
                    <div>
                        <img src={world} />
                    </div>
                </DivOvalStyle>
            </div>

            <h1>Hello, {username}! :)</h1>

            <div className="body-message">
                What do you think about reviewing everything you learned this semester?  Complete a quick test to measure your current English level.
            </div>
        </BodyStyle>
        <FooterStyle className="d-flex ">
            <ButtonCloseStyle
                link    = "#"
                icon    = "reset"
                onClick = {onClose}
                label   = "Do it Later" />
            <YLinkButtonStyle
                green
                size  = "medium"
                label = "Letʼs get it started"
                icon  = "arrowRight"
                link  = {`/pre_post/test/${
                    prePost
                    && prePost.listPptTestAlunoYConnect
                    && prePost.listPptTestAlunoYConnect[0]
                    && prePost.listPptTestAlunoYConnect[0].aluno_curso.ppT_Teste_Yconnect.id_curso}/-1`}
            />
        </FooterStyle>
    </GeneralStyle>

export default PostTestModal

import * as React from "react"

import TabStyle        from "YConnect/Styles/Tab.style"
import TabContentStyle from "YConnect/PagesComponents/PrePostReport/Components/TabContent.style"
import TabReport       from "YConnect/PagesComponents/PrePostReport/Teacher.report/TabReport"
import useReport       from "YConnect/PagesComponents/PrePostReport/Teacher.report/useReport"

const TabsResult = ({
    classIdSelected,
    courseIdSelected,
    semesterIdSelected,
    establishmentIdSelected,
    teacherIdSelected
}: any) => {

    const {
        teacherInfo,
        bestStudents,
        bestStudents2
    } = useReport({
        classIdSelected,
        courseIdSelected,
        semesterIdSelected,
        establishmentIdSelected,
        teacherIdSelected
    })

    return <TabStyle defaultActiveKey="pre">
                <TabContentStyle
                    eventKey  = "pre" 
                    title     = "Pre test"  
                    className = "yc-tab">
                    <TabReport 
                        teacherInfo             = {teacherInfo} 
                        bestStudents            = {bestStudents}
                        bestStudents2           = {bestStudents2}
                        semesterIdSelected      = {semesterIdSelected}
                        establishmentIdSelected = {establishmentIdSelected}/>
                </TabContentStyle>
                <TabContentStyle 
                    eventKey  = "post" 
                    title     = "Post test" 
                    className = "yc-tab">
                    <TabReport 
                        isPost 
                        teacherInfo             = {teacherInfo} 
                        bestStudents            = {bestStudents}
                        bestStudents2           = {bestStudents2}
                        semesterIdSelected      = {semesterIdSelected}
                        establishmentIdSelected = {establishmentIdSelected}/>
                </TabContentStyle>
            </TabStyle>
}

export default TabsResult
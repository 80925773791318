import * as React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import * as search          from "YConnect/Assets/png/search.png"
import * as searchWhite          from "YConnect/Assets/png/search-white.png"
import * as save            from "YConnect/Assets/svg/save.svg"
import * as saveGreen       from "YConnect/Assets/svg/saveGreen.svg"
import * as remove          from "YConnect/Assets/svg/remove.svg"
import * as print           from "YConnect/Assets/svg/print.svg"
import * as download        from "YConnect/Assets/svg/download.svg"
import * as downloadWhite   from "YConnect/Assets/svg/download-white.svg"
import * as arrowPrevious   from "YConnect/Assets/svg/arrow-breadcrumb-previous.svg"
import * as arrowRight      from "YConnect/Assets/svg/arrow-right.svg"
import * as arrowRightGreen from "YConnect/Assets/svg/arrow-right-green.svg"
import * as logout          from "YConnect/Assets/svg/logout.svg"
import * as messageGreen    from "YConnect/Assets/svg/message.svg"
import * as messageWhite    from "YConnect/Assets/svg/message-white.svg"
import * as uploadIcon      from "YConnect/Assets/svg/upload.svg"
import * as pencil          from "YConnect/Assets/svg/pencil.svg"
import * as pencilGreen     from "YConnect/Assets/svg/pencilGreen.svg"
import * as reply           from "YConnect/Assets/svg/reply.svg"
import * as reset           from "YConnect/Assets/svg/reset.svg"
import * as share           from "YConnect/Assets/svg/share.svg"
import * as warning         from "YConnect/Assets/svg/warning.svg"
import * as unlock          from "YConnect/Assets/svg/unlock.svg"
import * as checkedGreen2   from "YConnect/Assets/svg/checkedGreen2.svg"
import * as arrowDownIcon   from "YConnect/Assets/svg/arrow-down.svg"
import * as plus            from "YConnect/Assets/svg/plus-icon.svg"
import * as plusGreen       from "YConnect/Assets/svg/plus.svg"
import * as checked         from "YConnect/Assets/svg/checked.svg"
import * as close           from "YConnect/Assets/svg/close-btn.svg"
import * as eyesGreen       from "YConnect/Assets/svg/eyesGreen.svg"
import * as eyes            from "YConnect/Assets/svg/eyesWhite.svg"
import * as removeWhite     from "YConnect/Assets/svg/removeWhite.svg"
import * as closeGreen      from "YConnect/Assets/svg/closeGreen.svg"
import * as plusWhite       from "YConnect/Assets/svg/plusWhite.svg"
import * as cancelWhite     from "YConnect/Assets/svg/cancelWhite.svg"
import * as cancelGreen     from "YConnect/Assets/svg/cancelGreen.svg"
import * as question        from "YConnect/Assets/svg/question2.svg"
import * as audioGreen      from "YConnect/Assets/svg/control-panel/audioGreen.svg"
import * as audioWhite      from "YConnect/Assets/svg/control-panel/audioWhite.svg"
import * as imageGreen      from "YConnect/Assets/svg/control-panel/imageGreen.svg"
import * as imageWhite      from "YConnect/Assets/svg/control-panel/imageWhite.svg"
import * as printWhite      from "YConnect/Assets/png/print-white.png"

const ICONS = {
    save,
    saveGreen,
    remove,
    print,
    download,
    downloadWhite,
    arrowPrevious,
    arrowRight,
    pencil,
    pencilGreen,
    messageWhite,
    reply,
    uploadIcon,
    logout,
    arrowRightGreen,
    messageGreen,
    search,
    share,
    reset,
    warning,
    unlock,
    checkedGreen2,
    arrowDownIcon,
    plus,
    checked,
    plusGreen,
    close,
    eyesGreen,
    eyes,
    removeWhite,
    closeGreen,
    plusWhite,
    cancelWhite,
    cancelGreen,
    question,
    audioGreen,
    audioWhite,
    imageGreen,
    imageWhite,
    printWhite,
    searchWhite
}

interface CommonProps {
    id?         : any,
    children?   : any,
    className?  : string,
    label?      : string,
    icon?       : "save"|"remove"|"saveGreen"
                    |"print"|"download"|"downloadWhite"
                    |"arrowPrevious"|"arrowRight"|"message"
                    |"messageWhite"|"reply"|"logout" | "pencil"|"pencilGreen"
                    |"arrowRightGreen"|"messageGreen"|"search" | "plusGreen"
                    |"arrowDownIcon"|"uploadIcon"|"reset"|"warning"
                    |"share"|"unlock"|"checkedGreen2"|"plus"|"checked"|"close"|"eyesGreen"|"eyes"|"removeWhite"
                    |"closeGreen" | "plusWhite"|"cancelWhite"|"cancelGreen"|"question"|"audioGreen"|"audioWhite"
                    |"imageGreen"|"imageWhite"|"printWhite" |"searchWhite",

    iconLeft?    : "save"|"remove"|"saveGreen"
    |"print"|"download"|"downloadWhite"
    |"arrowPrevious"|"arrowRight"|"message"
    |"messageWhite"|"reply"|"logout" | "pencil"|"pencilGreen"
    |"arrowRightGreen"|"messageGreen"|"search" | "plusGreen"
    |"arrowDownIcon"|"uploadIcon"|"reset"|"warning"
    |"share"|"unlock"|"checkedGreen2"|"plus"|"checked"|"close"|"eyesGreen"|"eyes"|"removeWhite"
    |"closeGreen" | "plusWhite"|"cancelWhite"|"cancelGreen"|"question"|"audioGreen"|"audioWhite"
    |"imageGreen"|"imageWhite",
    disabled?   : boolean,
    onClick?    : any,
    green?      : boolean
    size?       : string
    maxWidth?   : boolean
    title?      : string
}

interface ButtonProps extends CommonProps {
    type?       : any,
}

interface LinkProps extends CommonProps {
    link?       : any,
    target?     : string
}

const calcSize = (size: string) => {
    if (window.innerWidth >= 768 ) {
        switch (size) {
            case "forTable":
                return "85px"
            case "130":
                return "130px"
            case "190":
                return "190px"
            case "small":
                return "170px"
            case "medium":
                return "254px"
            case "large":
                return "288px"
            case "xlarge":
                return "340px"
            default:
                return "170px"
        }
    } else {
        switch (size) {
            case "forTable":
                return "85px"
            case "130":
                return "130px"
            case "190":
                return "190px"
            case "small":
                return "154px"
            case "medium":
                return "198px"
            case "large":
                return "256px"
            case "xlarge":
                return "340px"
            default:
                return "154px"
        }
    }
}

const ImageStyled = styled.img`
    width: ${window.innerWidth > 1024 ? 'auto': '27px'};
`

const Button = ({children, type, label, icon, disabled, className, onClick, id}:ButtonProps) =>
    <button
        type={type || "button"}
        id={id}
        className={`btn ${className} ${disabled ? "disabled" : "" }`}
        disabled={disabled || false}
        onClick={onClick}>
        {label}
        {children}
        {icon && <ImageStyled src={ICONS[icon]} className={`pl-3 ${icon}`} />}
    </button>

const LinkButton = ({children, label, link, icon, iconLeft, disabled, className, target, onClick }:LinkProps) =>
    <Link
        to={link}
        className={`btn ${className} ${disabled ? "disabled" : "" }`}
        target={target || "_self"}
        onClick={onClick}>
        {iconLeft && <ImageStyled src={ICONS[iconLeft]} className={`ml-2 pl-3 mr-0 ${iconLeft}`} />}
        {label}
        {children}
        {icon && <ImageStyled src={ICONS[icon]} className={`pl-3 ${icon}`} />}
    </Link>

const LinkButtonExternal = ({children, label, link, icon, disabled, className, target }:LinkProps) =>
    <a
        href={link}
        className={`btn ${className} ${disabled ? "disabled" : "" }`}
        target={target || "_self"}>
        {label}
        {children}
        {icon && <ImageStyled src={ICONS[icon]} className={`pl-3 ${icon}`} />}
    </a>

const ProfileButton = ({id, label, icon, className, onClick}:LinkProps) =>
    <label htmlFor={id} className={className} onClick={onClick}>
        {label}
        <ImageStyled src={ICONS[icon]} className="pl-3" />
    </label>


const ButtonCommonStyle = styled(Button)`
    font-weight: bold;
    font-size: 0.75rem;
    max-width: ${props => props.maxWidth ? calcSize(props.size) : "100%"};
    width: 100%;
    @media (min-width: 768px) {
        font-size: 1rem;
        width: ${props => calcSize(props.size)}
    }
`

const ButtonStyle = styled(ButtonCommonStyle)`
    align-items: center;
    background: ${props => props.green ? props.theme.main.greentextColor : props.theme.main.backgroundWhite};
    border: 2px solid ${props => props.theme.main.greentextColor};
    border-radius: 50px;
    color: ${props => !props.green ? props.theme.main.greentextColor : props.theme.main.textWhite2};
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 42px;
    padding: 6px 16px;
    :hover {
        color: ${props => props.green ? props.theme.main.textWhite2 : props.theme.main.greentextColor};
    }
    img {
        filter: ${props => props.green ? props.theme.main.filterSvgWhite : "unset"};
        width: ${window.innerWidth > 1024 ? 'auto': '27px'};
    }

    img.arrowRightGreen,
    img.messageGreen,
    img.checkedGreen2,
    img.reset {
        filter: ${props => props.green ? props.theme.main.filterSvgGreen : props.theme.main.filterSvg};
    }
`

const UnitPageStyle = styled.section`
    margin-left: 2em;
    margin-top: 2em;
`;

const ProfileButtonStyle = styled(ProfileButton)`
    align-items: center;
    background: ${props => props.disabled !== true
                    ? (props.green ? props.theme.main.greentextColor : props.theme.main.backgroundWhite)
                    : "#e9ecef"};

    border: 1px solid ${props => props.disabled !== true ? props.theme.main.greentextColor : "#989898"};
    border-radius: 50px;
    color: ${props =>  props.disabled !== true
        ? (!props.green ? props.theme.main.greentextColor : props.theme.main.textWhite2)
        : "#989898"};
    cursor: ${props => props.disabled !== true ? "pointer":"not-allowed"};
    display: flex;
    flex-flow: row wrap;
    font-size: 0.75rem;
    font-weight: bold;
    justify-content: space-between;
    height: 42px;
    margin: 32px 0;
    padding: 6px 16px;
    max-width: ${props => props.maxWidth ? calcSize(props.size) : "100%"};
    width: 100%;
    :hover {
        color: ${props =>  props.disabled !== true
        ?(props.green ? props.theme.main.textWhite2 : props.theme.main.greentextColor)
        : "#989898"};
    }
    img {
        filter: ${props => props.green ? props.theme.main.filterSvgWhite : "unset"};
    }

    img.arrowRightGreen {
        filter: ${props => props.green ? props.theme.main.filterSvgGreen : "unset"};
    }
    @media (min-width: 768px) {
        font-size: 1rem;
        width: ${props => calcSize(props.size)}
    }
`

const YLinkButton = styled(LinkButton)``
const YLinkButtonStyle = styled(YLinkButton)`
    align-items: center;
    font-size: 0.75rem;
    background: ${props => props.green ? props.theme.main.greentextColor : props.theme.main.backgroundWhite};
    border: 2px solid ${props => props.theme.main.greentextColor};
    border-radius: 50px;
    color: ${props => !props.green ? props.theme.main.greentextColor : props.theme.main.textWhite2};
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-height: 42px;
    padding: 6px 16px;

    :hover {
        color: ${props => props.green ? props.theme.main.textWhite2 : props.theme.main.greentextColor};
    }

    img {
        filter: ${props => props.green ? props.theme.main.filterSvgWhite : "unset"};
    }

    img.arrowRightGreen {
        filter: ${props => props.green ? props.theme.main.filterSvgGreen : "unset"};
    }

    @media (min-width: 768px) {
        font-size: 1rem;
        width: ${props => calcSize(props.size)}
    }
`
const YLinkButtonExternal = styled(LinkButtonExternal)`
    align-items: center;
    background: ${props => props.green ? props.theme.main.greentextColor : props.theme.main.backgroundWhite};
    border: 2px solid ${props => props.theme.main.greentextColor};
    border-radius: 50px;
    color: ${props => !props.green ? props.theme.main.greentextColor : props.theme.main.textWhite2};
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    height: 42px;
    padding: 6px 16px;
    :hover {
        color: ${props => props.green ? props.theme.main.textWhite2 : props.theme.main.greentextColor};
    }
    img {
        filter: ${props => props.green ? props.theme.main.filterSvgWhite : "unset"};
    }
    img.arrowRightGreen {
        filter: ${props => props.green ? props.theme.main.filterSvgGreen : "unset"};
    }
`


const LinkStyle = styled(ButtonCommonStyle)`
    color: ${props => props.theme.main.greentextColor};
    :hover {
        text-decoration: underline;
        color: ${props => props.theme.main.linkHoverColor};
    }

    img {
        filter: ${props => props.theme.main.filterSvg};
    }
`

export {LinkStyle, ButtonStyle, YLinkButtonStyle, YLinkButtonExternal, ProfileButtonStyle, UnitPageStyle}

import getStaticUrl from "./GetStaticUrl.util";

import * as placeholderPicture  from "YConnect/Assets/png/placeholder-profile.png"

const convertPhoto = (foto: string) => {

    if (typeof foto == "string" && foto.indexOf('../') > -1) {
        const photo = foto.split('../../')[1];
        return `${getStaticUrl(photo)}`
    } else if (typeof foto == "string" && (foto.indexOf('/stamp/') > -1 || foto.indexOf('/tags/') > -1 || foto.indexOf('/flags/') > -1)) {
        return `${getStaticUrl(`assets/${foto}`)}`;
    } else if (typeof foto == "string" && foto.indexOf('data:image') > -1) {
        return foto
    } else if (!foto || foto === null) {
        return placeholderPicture
    } else {
        try{
            return atob(foto)
        }catch{
            return foto
        }
    }
}

export default convertPhoto
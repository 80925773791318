import styled from "styled-components"

const LegendMapStyle = styled.div`
    bottom: 10%;
    position: absolute;

    .legend-line {
        display:flex;
        margin: 8px 0;
    }

    .legend {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
    }

    .circle {
        border-radius: 50%;
        display: block;
        height: 20px;
        margin-right: 11px;
        width: 20px;
    }
`

export default LegendMapStyle
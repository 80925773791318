import * as React from "react"
import {useState} from "react"
import { Modal }  from "react-bootstrap"
import * as QuestionResource          from "YConnect/ResourcesBackEnd/Question.resources"

import {ButtonStyle} from "YConnect/Components/Button"

const QuestionDrag = ({
    show,
    Id,
    onHide,
    Name,
    List,
    refresh
}:any) =>{

    const [hasShowLoader, setShowLoader] = useState<boolean>(false)

    const reorderExercise = () => {
        setShowLoader(true)
        QuestionResource
        .UpdateById({listQuestion: List}).then(() => {

        }).finally(() => {
            setShowLoader(false)
            onHide(false);
        })
    }

    const handleReorder = () => reorderExercise()

    return <Modal show={show} onHide={onHide} size="lg">
                {
                    !hasShowLoader
                    && <>
                        <Modal.Header
                            closeButton
                            closeLabel={"Close"}
                            className="first-access"
                            style={{zIndex: 10}}>
                                <Modal.Title>Are you sure you want to reorder these questions?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <ButtonStyle
                                    onClick={handleReorder}
                                    label="Yes"
                                    icon="checkedGreen2" />
                            <ButtonStyle
                                    onClick={() => {
                                        onHide()
                                        refresh()
                                    }}
                                    label="No"
                                    icon="close" />
                        </Modal.Footer>
                    </>
                }
                {
                    hasShowLoader && <div className="loader"></div>
                }
            </Modal>
}


export default QuestionDrag

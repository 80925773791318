import * as React             from "react"
import {Fragment, useEffect}  from "react"
import { connect }            from "react-redux"
import { bindActionCreators } from "redux"

import {
    Container,
    Row,
    Col
} from "reactstrap"

//@ts-ignore
import qs from "querystring"


import QueryParamsActionsCreator from "YConnect/Actions/QueryParams.actionsCreator"

import LIST_SECTION_TYPES from "YConnect/Configs/ControlPanelPage/CRUID.config"

import SidebarMenu from "YConnect/PagesComponents/ControlPanel/Components/SidebarMenu"


const Developer = ({
    match, 
    location,
    history,
    QueryParams,
    SetQueryParams
}:any) => {

    const queryParams = qs.parse(location.search.substr(1))

    useEffect(() => {
		if(Object.keys(queryParams).length > 0){
			SetQueryParams(queryParams)
		}
    }, [])

	useEffect(() => {
		const search = qs.stringify(QueryParams)
		history.push({search: `?${search}`})
	}, [QueryParams])

    const panelName = LIST_SECTION_TYPES
    .find(({keystone}) => keystone == (match.params.panelName || "")
    .toUpperCase())

    return <Fragment>
                <Container fluid={true}>
                    <Row style={{height: "117px"}}>
                        <Col md={3} lg={2} className="p-4" style={{ "backgroundColor": "#4e2d78", "color": "#fff" }}>
                            <h4 className="m-0" style={{ "color": "#79b100" }}><strong>YConnect Control Panel</strong></h4>
                            {
                                panelName && <h1>{panelName.label}</h1>
                            }
                        </Col>

                        <Col 
                            md={9} lg={10}
                            style={{ "backgroundColor": "#fff", "borderBottom": "1px solid #79b100" }}>
                            
                        </Col>
                    </Row>
                </Container>
                <Container fluid={true}>
                    <Row>
                        <Col md={3} lg={2} style={{"backgroundColor": "#fff","position": "fixed","borderRight": "1px solid #79b100","top": "117px","bottom": "0","left": "0"}}>
                            <SidebarMenu
                                panelNameSelected={panelName} 
                                list={LIST_SECTION_TYPES}/>
                        </Col>

                        <Col md={9} lg={10} className="p-3" style={{"position": "fixed","top": "117px","bottom": "0","right": "0", "overflow":"auto"}}>
                        {panelName && React.createElement(panelName.component, {}, null)}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
    
}

const mapDispatchToProps = (dispatch:any) => bindActionCreators({
	SetQueryParams : QueryParamsActionsCreator.SetQueryParams
}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(Developer)

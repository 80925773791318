
import styled from "styled-components"

const ButtonGreenStyled = styled.button`
    border: 2px solid #79b100;
    border-radius: 50px;
    background: #79b100;
    color: #fff;
    padding: 5px 15px 5px 15px;
    font-weight:bold;

    &:hover {
        background: #fff;
        color: #79b100;
    }
`

export default ButtonGreenStyled
const CONFIG_TABLE_EXERCISE_TYPE:Array<ColumnConfigType> = [
    {
        keystone : "Weight",
        label    : "Weight"
    },
    {
        keystone : "Description",
        label    : "Description"
    }
]

export default CONFIG_TABLE_EXERCISE_TYPE
import * as React             from "react"
import {useEffect}            from "react"
import { connect }            from "react-redux"
import { bindActionCreators } from "redux"

import QueryParamsActionsCreator from "YConnect/Actions/QueryParams.actionsCreator"
import CMSContainer from "YConnect/PagesComponents/CMS/CMS.container"

//@ts-ignore
import qs from "querystring"

const CMSPage = ({
    history,
    location,
    QueryParams,
    SetQueryParams
}:any) => {
    const queryParams = qs.parse(location.search.substr(1))

    useEffect(() => {
		if(Object.keys(queryParams).length > 0){
			SetQueryParams(queryParams)
		}
    }, [])

	useEffect(() => {
        //if(Object.keys(QueryParams).length > 0){
            const search = qs.stringify(QueryParams)
            history.push({search: `?${search}`})
        //}
		
    }, [QueryParams])

    return <CMSContainer/>
}

const mapDispatchToProps = (dispatch:any) => bindActionCreators({
    AddQueryParam : QueryParamsActionsCreator.AddQueryParam,
    SetQueryParams : QueryParamsActionsCreator.SetQueryParams
}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(CMSPage)
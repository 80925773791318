import * as React                        from "react"
import {useState, useEffect, useContext} from "react"
import {Container, Row, Col, FormGroup}             from "reactstrap"
import styled                            from "styled-components"

import API         from "YConnect/API"
import { BreadcrumbCoursesControl } from "YConnect/Components/BreadCrumbList"
import Loader      from "YConnect/Components/Loader"
import UserContext from "YConnect/User.context"
import getConfig   from "YConnect/Utils/GetRequestConfig.util"

import Table              from "YConnect/Components/Table"

import CONFIG_COURSES_CONTROL  from "YConnect/Configs/Tables/CoursesControl.config"


import {ButtonStyle} from "YConnect/Components/Button"


import ModalArchiveCourse from "YConnect/Modals/ArchiveCourse.modal";
import ModalExpirationDate from "YConnect/Modals/ExpirationDate.modal";
import ArchiveHistoryModal from "YConnect/Modals/ArchiveHistoryModal"
import { maskForEmail } from "YConnect/Utils/MaskFormat.util"
import Toastify from "toastify-js"

const NewButtonStyle = styled(ButtonStyle)`
    width:auto!important;
`

const ContainerPersonalInfos = styled.div `
    display: flex;
    flex-direction: column;

    @media (min-width: 768px){
        width: 50%;
    }

    span {
        padding: 5px;
        background-color: #fff;
        border: 1px solid #fff;
        margin: 5px 0;
    }
`

const ContainerTableCoursesControl = styled.div `
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;

    .users-dropdown button {
        background-color: transparent !important;
        border: none;
        color: #79b100 !important;
        padding: 0;
    }

    th {
        color: #79b100 !important;
        border-bottom: 2px solid #dee2e6 !important;
    }
`

const ContainerButtonPrint = styled.div `
    display:flex;
    margin-top: 40px;
    justify-content: center;
`

const ButtonStyle180 = styled(ButtonStyle)`
    width: 180px !important;
`

const ReintegrateStudentPage = ({match}:any) => {

    const [listCourses, setListCourses]                  = useState()
    const [student, setStudent]                          = useState()
    const [listCoursesWithModal, setListCoursesWithModal]                          = useState()

    const [idCourse, setIdCourse]                        = useState()
    const [motive, setMotive]                            = useState()

    const [hasLoading, setHasLoading]                     = useState(true)
    const [showModalHistory, setShowModalHistory]         = useState(false)
    const [showModalArchiveHistory, setShowModalArchiveHistory]         = useState(false)
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
    const [showModalExpirationDate, setShowModalExpirationDate] = useState(false)
    const [hasShowForm, setHasShowForm]                   = useState(true)
    const [dataForModal, setDataForModal] = useState('');
    const {idUsuario}:any = useContext(UserContext)

    const {idAluno} = match.params

    useEffect(() => {
        API.Aluno.Buscar({id:idAluno, opt:1}, getConfig())
        .then(({data}:any) => setStudent(data))


        API.Usuario.GetCursos({idAluno, opt:1}, getConfig())
        .then(({data}:any) => setListCourses(data))
    }, [])

    useEffect(()=>{
        if(student) setHasLoading(false)
    }, [student])

    const handleConfirmReintegrate = () => {

        setShowModalConfirmacao(false)
        setHasLoading(true)

        API.ZerarNota.Put({
            codigoResponsavel : idUsuario,
            codigoUsuario     : idAluno,
            codigoCurso       : idCourse,
            descricao         : motive
        })
        .then(({data}:any) => {


            setHasShowForm(false)
            setTimeout(()=>  setHasShowForm(true), 500)

            Toastify({
                text: data,
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();

        })
        .catch(({response:{data}}:any) => {

            Toastify({
                text: data,
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
        .finally(()=> setHasLoading(false))

    }

    const handleInputChange = (keystone:string, value:any) =>{
        if(keystone === "course")
            setIdCourse(value)
        else if(keystone === "motive")
            setMotive(value)
    }

    const archiveCourseModal = ((dataModal) => {
        setShowModalHistory(true)
        setDataForModal(dataModal)
    })

    const expirationDateModal = ((dataModal) => {
        setShowModalExpirationDate(true)
        setDataForModal(dataModal)
    })

    const converterFormatoData = ((dataString: string) => {
        // Divide a string em três partes: ano, mês e dia
        const [ano, mes, dia] = dataString.split("-");

        // Cria uma nova string no formato "DD/MM/AAAA"
        const novaDataString = `${dia}/${mes}/${ano}`;

        return novaDataString;
    })

    const converterStringParaData = ((dataString) => {
        const partesData = dataString.split("/");
        const dia = parseInt(partesData[0], 10);
        const mes = parseInt(partesData[1], 10) - 1;
        const ano = parseInt(partesData[2], 10);
        return new Date(ano, mes, dia);
    })

      const compararDatasComHoje = ((dataString) => {
        const dataFornecida = converterStringParaData(dataString);
        const dataAtual = new Date();

        if (dataFornecida > dataAtual) {
            return "In progress"
            // Comportamento desejado para as datas futuras
        } else if (dataFornecida < dataAtual) {
            return "Approved"
            // Comportamento desejado para as datas passadas
        } else {
            return "In progress"
            // comportamento desejado para a data de hoje
        }
    })

    useEffect(() => {
        if(listCourses) {
            var filteredListCourses = listCourses.map((key) => {
                if (key.data_final) {
                    var getOnlyDate = key.data_final.split('T')
                }

                return {
                    ...key,
                    openArchiveModal: archiveCourseModal,
                    openExpirationDateModal: expirationDateModal,
                    messageStatus: compararDatasComHoje(converterFormatoData(getOnlyDate[0])),
                }
            })

            setListCoursesWithModal(filteredListCourses)
        }
    }, [listCourses])

    return  <Container>
                <BreadcrumbCoursesControl />
                {student
                    && <Row className="mb-0 mr-0">
                        <Col md={12}>
                            <h2>Courses Control</h2>
                            <b>Student’s Information</b>
                        </Col>
                    </Row>}
                {student &&
                    <ContainerPersonalInfos>
                        <span><b>ID: </b>{student.id_aluno}</span>
                        <span><b>Name: </b>{student.nome_aluno+" "+student.sobrenome_aluno}</span>
                        <span><b>User Name: </b>{student.apelido_aluno}</span>
                        <span><b>E-mail: </b>{maskForEmail(student.email_aluno)}</span>
                    </ContainerPersonalInfos>}


                    <Row className="mb-0 mr-0 mt-4 mb-4">
                        <Col md={12}>
                            <b>Courses Bougth by the Student</b>
                        </Col>
                    </Row>



                    <ContainerTableCoursesControl>
                        <Table data={listCoursesWithModal} config={CONFIG_COURSES_CONTROL} />
                    </ContainerTableCoursesControl>

                    <ContainerButtonPrint>
                        <ButtonStyle180 size = "medium" onClick = {() => setShowModalArchiveHistory(true)} className = "mr-2 btn-width-180">
                            <div className="d-flex align-items-center">
                                <span className="ml-2 mr-2">Archive history</span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.75 1H4.8C3.47 1 2.805 1 2.29675 1.25887C1.84984 1.4865 1.4865 1.84984 1.25887 2.29675C1 2.805 1 3.47 1 4.8V16.2C1 17.53 1 18.195 1.25887 18.7033C1.48657 19.1501 1.84988 19.5134 2.29675 19.7411C2.80381 20 3.46881 20 4.79644 20H5.75M5.75 1H16.2C17.53 1 18.195 1 18.7021 1.25887C19.1497 1.48687 19.5131 1.84906 19.7411 2.29675C20 2.80381 20 3.46881 20 4.79644V16.2048C20 17.5324 20 18.1962 19.7411 18.7033C19.513 19.1502 19.1493 19.5135 18.7021 19.7411C18.195 20 17.5312 20 16.2036 20H5.75M5.75 1V20M10.5 9.3125H15.25M10.5 5.75H15.25" stroke="#A9CE71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </ButtonStyle180>

                        <ButtonStyle green icon="printWhite" onClick={window.print}>
                            Print
                        </ButtonStyle>
                    </ContainerButtonPrint>

                    {
                        <ModalArchiveCourse
                                hasShow={showModalHistory}
                                dataCourse={dataForModal}
                                onConfirm = {handleConfirmReintegrate}
                                onClose={() => setShowModalHistory(false)}/>
                    }

                    {
                        <ModalExpirationDate
                                hasShow={showModalExpirationDate}
                                dataCourse={dataForModal}
                                onConfirm = {handleConfirmReintegrate}
                                onClose={() => setShowModalExpirationDate(false)}/>
                    }

                    {
                        <ArchiveHistoryModal
                                idAluno={idAluno}
                                courses={listCourses}
                                isShow={showModalArchiveHistory}
                                onClose={() => setShowModalArchiveHistory(false)}/>
                    }

            </Container>
}

export default ReintegrateStudentPage

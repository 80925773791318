import API2 from "YConnect/API2"

import getConfig from "YConnect/Utils/GetRequestConfig.util"
// adicionar função aqui(API2)

export const GetAll = () => 
    API2
    .Book
    .GetAll({}, getConfig())

export const Create = (book:any) => 
    API2
    .Book
    .Create(book, getConfig())
    
export const UpdateById = (book:any) => 
    API2
    .Book
    .UpdateById(book, getConfig())

export const GetById = (bookId:any) => 
    API2
    .Book
    .GetById({bookId}, getConfig())

export const DeleteById = (bookId:any) => 
    API2
    .Book
    .DeleteById({bookId}, getConfig())
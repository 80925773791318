import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAll = () => 
    API2
    .LearningObjective
    .GetAll({}, getConfig())

export const Create = (learningObjective:any) => 
    API2
    .LearningObjective
    .Create(learningObjective, getConfig())
    
export const UpdateById = (learningObjective:any) => 
    API2
    .LearningObjective
    .UpdateById(learningObjective, getConfig())

export const GetById = (learningObjectiveId:any) => 
    API2
    .LearningObjective
    .GetById({learningObjectiveId}, getConfig())

export const DeleteById = (learningObjectiveId:any) => 
    API2
    .LearningObjective
    .DeleteById({learningObjectiveId}, getConfig())
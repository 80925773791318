import * as React                  from "react"
import { useState, useEffect }     from "react"
import { Popover, OverlayTrigger } from "react-bootstrap"
import { Container, FormGroup }    from "reactstrap"
import styled from "styled-components"

import Toastify from 'toastify-js' 

import * as show               from "YConnect/Assets/svg/show-password.svg"
import * as hide               from "YConnect/Assets/svg/hide-password.svg"
import * as logo               from "YConnect/Assets/png/yconnect-logo-purple.png"
import * as logoPearson        from "YConnect/Assets/png/logoPearson.png"
import * as yazigiLogo         from "YConnect/Assets/png/logo-yazigi-purple.png"
import * as GSE                from "YConnect/Assets/png/logo-GSE.png"
import * as alwaysLearning     from "YConnect/Assets/png/always-learning.png"
import * as yconnectHomeQrcode from "YConnect/Assets/svg/yconnect-home-qrcode.svg"

import IOSAddScreenModal from "YConnect/Modals/IOSAddScreen.modal"

import Loader          from "YConnect/Components/Loader"
import { ButtonStyle } from "YConnect/Components/Button"
import API             from "YConnect/API"

import DescryptoValue          from "YConnect/Utils/DescryptoValue"
import { userDataLayer }       from  "YConnect/Utils/DataLayer"
const DivQrCodeStyle = styled.div`

    display:none;
    width: 400px;
    height: 200px;
    padding: 40px;
    margin: 18px 0;
    background-color: #FFFFFF;
    
    img{
        width: 100px;
    }
    
    @media screen and (min-width: 1024px) {
        display:flex;
    }
`


type LoginPageProps = {
    onLogin: Function
}

const LoginPage = ({ onLogin }: LoginPageProps) => {

    const [userNameReset, setUserNameReset]         = useState("")
    const [userName, setUserName]                   = useState("")
    const [password, setPassword]                   = useState("")
    const [showPassword, setShowPassword]           = useState(false)
    const [width, setWidth]                         = useState(window.innerWidth)
    const [isLoading, setLoading]                   = useState(false)
    const [loginInvalidError, setLoginInvalidError] = useState(false)

    const [showModalIOS, setShowModalIOS]           = useState(false)

    useEffect(() => {
 
        const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        const isInStandaloneMode = () =>
            (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')

        const notShown = localStorage.getItem("notShown")
    

        if(!isInStandaloneMode() && isIOS && !notShown){
            setShowModalIOS(true)
        }

        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const handleLogin = async (e: any) => {
        e.preventDefault();
        setLoginInvalidError(false)
        setLoading(true)

        try{
            let responseAuth    = await API.Auth.Get({id:userName, senha:password, mobile:false})
            responseAuth.data.apelido = DescryptoValue.descryptoString(responseAuth.data.apelido);
            responseAuth.data.nomeCompleto = DescryptoValue.descryptoString(responseAuth.data.nomeCompleto);
            responseAuth.data.email = DescryptoValue.descryptoString(responseAuth.data.email);
            const {data}          = responseAuth

            if(data && data.emailConfirmado && !data.isBlocked){
                const authToken       = responseAuth.headers["auth-token"]
                let responseUsuario = await API.Usuario.Get({id:data.idUsuario}, {headers:{"Authorization": authToken}})
                responseUsuario.data = await DescryptoValue.descryptoObject(responseUsuario.data, true);
                
                const newUserData     = {
                    ...data, 
                    nome:responseUsuario.data.nome_aluno,
                   // primeiroAcessoGameficacao:{...data.primeiroAcessoGameficacao, dt_primeiro_acesso:null}
                }
                localStorage.setItem("Authorization", authToken)
                localStorage.setItem("data", JSON.stringify(newUserData))
    
                if(newUserData.primeiroAcessoGameficacao && newUserData.primeiroAcessoGameficacao.dt_primeiro_acesso){
                    localStorage.setItem("afterLogin", "true")
                }
                userDataLayer.SigninClick(data.idUsuario);
                onLogin(newUserData, password)
            }else if(data && data.isBlocked){
                Toastify({
                    text: "Blocked user! <br> Contact your school manager",
                    duration: 3000,
                    className: "toastify-warning",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            }else if(data && data.emailConfirmado === false){
                Toastify({
                    text: "Email not confirmed! <br> Please check your email to confirm",
                    duration: 3000,
                    className: "toastify-warning",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            }else{
                Toastify({
                    text: "Authentication error! <br> Username or password incorrect",
                    duration: 3000,
                    className: "toastify-warning",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            }
            
        }catch(erro){
            Toastify({
                text: "Error! <br> Unable to login",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }finally{
            setLoading(false)
        }
    }

    const _resetPassword = (e: any): void => {
        setLoading(true)
        e.preventDefault()

        API.Auth.ResetPassword({
            id: '',
            emailAluno: `${userNameReset}`,
        }).then((res: any) => {
            if (res === undefined) {
                Toastify({
                    text: "Error! <br> E-mail not found!",
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            }
            document.getElementById("js-forgot-password").click();
            Toastify({
                text: "Success! <br> E-mail was sent!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
        .catch((err: any) => {
            Toastify({
                text: "Error! <br> E-mail not found!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
        .finally(() => {
            setLoading(false)
            setUserNameReset('')
        })

    }

    const isValidEmail = () => {
        return !(userNameReset && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userNameReset))
    }

    //TODO tirar daqui
    const popover = <Popover id="forgotPassword" title="Forgot password">
        <p>Inform your e-mail registered to receive a step-by-step to reset your password!</p>
        <form>
            <FormGroup>
                <label className="form-label" htmlFor="userNameForgot">E-mail</label>
                <input
                    id="userNameForgot"
                    type="email"
                    className="form-control"
                    name="username"
                    placeholder="Enter your e-mail"
                    value={userNameReset}
                    required
                    onChange={(e: any) => setUserNameReset(e.target.value)}
                />
            </FormGroup>
            <div className="d-flex flex-row flex-wrap justify-content-end pr-2 mt-4">
                <ButtonStyle green
                    type="submit"
                    label="Send"
                    icon="arrowRight"
                    onClick={_resetPassword}
                    disabled={isValidEmail()} />
            </div>
        </form>
    </Popover>


    return <>
        <Container fluid={true} className="login m-0 p-0">
            {isLoading && <Loader />}

            <div className="bg-login p-0 m-0">
                <header className="header-container">
                    <img src={logo} alt="YConnect logo" className="header-logo" />
                    <span className="header-separator"></span>
                    <h1 className="login-body-title">Aprenda a ir além do óbvio, <strong>é lá que o mundo está</strong></h1>
                </header>

                <section className="content-container">
                    <div className="login-body">
                        <span className="login-label">Já é aluno? <strong>Faça o Login</strong></span>
                        <form onSubmit={handleLogin}>
                            <FormGroup>
                                <label className="form-label" htmlFor="userName">Username</label>
                                <input
                                    id="userName"
                                    type="text"
                                    className="form-control"
                                    name="userName"
                                    placeholder="Enter your username"
                                    value={userName}
                                    onChange={(e: any) => setUserName(e.target.value)}
                                />
                            </FormGroup>

                            <div className="input-password">
                                <FormGroup>
                                    <label className="form-label" htmlFor="password">Password</label>
                                    <input
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        name="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e: any) => setPassword(e.target.value)}
                                    />
                                    <img src={showPassword?show:hide} className="input-toggle-password" onClick={() => setShowPassword(!showPassword)} />
                                </FormGroup>
                            </div>
                            {loginInvalidError && <small className="form-text text-danger">Invalid Username or Password</small>}
                            <OverlayTrigger
                                trigger="click"
                                rootClose={true}
                                placement={width < 768 ? "top" : "right"}
                                overlay={popover}>
                                <span id="js-forgot-password" className="login-forgot d-block text-right pointer">
                                    Forgot password?
                                </span>
                            </OverlayTrigger>

                            <div className="d-flex flex-row flex-wrap justify-content-center">
                                <ButtonStyle green maxWidth
                                    type={'submit'}
                                    label="Sign In"
                                    icon="arrowRight"
                                    disabled={!userName || !password}
                                    size={'small'}
                                />
                            </div>
                        </form>
                    </div>

                    <div className="optional-features">
                        <div className="placement-link-container">
                            <span className="placement-label">Ainda não é aluno? </span>
                            <span className="placement-label"><strong>Faça o teste de nível</strong></span>
                            <br/>
                            <ButtonStyle green maxWidth
                                type="button"
                                label="Click Here"
                                icon="arrowRight"
                                onClick={() => window.open("/placement/#/login") }
                                size={'small'}
                            />
                        </div>

                        <DivQrCodeStyle className="row">
                            <div className="col-md-auto">
                                <img src={yconnectHomeQrcode}/>
                            </div>
                            <div className="col p-0 align-self-center">
                                <strong>
                                    Scan here to access on your mobile phone
                                </strong>
                            </div>
                        </DivQrCodeStyle>
                    </div>

                </section>

            </div>

            <IOSAddScreenModal isShow={showModalIOS} onClose={(checked:any) => {
                if(checked){
                    localStorage.setItem("notShown", "true")
                }
                setShowModalIOS(false)
            }}/>
                
        </Container>
        <footer>
            <section className="login-footer-yconnect">
                <img src={yazigiLogo} alt="Yazigi Logo" className="logo-yazigi" />
                <span className="logo-separator"></span>
                <img src={GSE} alt="Global Scale of English Logo" className="logo-gse" />
            </section>
            <section className="login-footer-pearson">
                <img src={logoPearson} className="logo-pearson" />
                <img src={alwaysLearning} className="logo-always" />
            </section>
        </footer>
    </>
}

export default LoginPage


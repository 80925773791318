import * as React               from "react"
import { useState, useEffect }  from "react"
import qs                       from "querystring"

import * as show        from "YConnect/Assets/svg/show-password.svg"
import * as logo        from "YConnect/Assets/png/yconnect-logo-purple.png"
import * as yazigiLogo  from "YConnect/Assets/png/logo-yazigi-purple.png"
import * as GSE         from "YConnect/Assets/png/logo-GSE.png"

import API              from "YConnect/API"
import {ButtonStyle}    from "YConnect/Components/Button"
import Toastify from "toastify-js"

const ResetPasswordPage = ({location}: any) => {

    const [token, setToken]                             = useState()
    const [userId, setUserId]                           = useState()
    const [password, setPassword]                       = useState("")
    const [confirmPassword, setConfirmPassword]         = useState("")
    const [showPassword, setShowPassword]               = useState(false)
    const [showConfirmPassword, setConfirmShowPassword] = useState(false)


    useEffect(() => {
        const {idUsuario, token} = qs.parse(location.search.substr(1))
        setToken(token)
        setUserId(idUsuario)

        API
        .Auth
        .CheckTokenPassword({hash_solicitacao: token, id_aluno: idUsuario})
        .catch((err: any) => {
            Toastify({
                text: "Error! <br> Token already used. Make a new password change request!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            window.location.href = "#/login"
        })

    }, [location])

    const handleAlterPassword = (): void => {
        API
        .Auth
        .AlterPassword({hash_solicitacao: token, id_aluno: userId, senha: password}, {})
        .then(()=>{
            Toastify({
                text: "Password updated successfully",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();

            window.location.href = "#/login"
        })
        .catch((e:any) => {
            Toastify({
                text: "Error! <br> Error updating password!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
    }

    return <main 
                className = "container-fluid d-flex flex-column flex-wrap justify-content-between align-items-center m-0 p-0" 
                style     = {{height: "100vh"}}>
                <section className="d-flex flex-column flex-wrap align-items-center justify-content-around h-100">
                    <header>
                        <img 
                            src   = {logo} 
                            alt   = "YConnect logo" 
                            width = "200" />
                    </header>
                    <div className="mb-5 mt-5 w-70">
                        <h1>Reset your password by a new one</h1>
                            <form onSubmit={(e: any) => {
                                e.preventDefault()
                                handleAlterPassword()
                            }}>

                                <div className="input-password">
                                    <div className="form-group">
                                        <label className="form-label">Password</label>
                                        <input
                                            type        = {showPassword ? "text" : "password"}
                                            className   = "form-control"
                                            name        = "password"
                                            placeholder = "Password"
                                            value       = {password}
                                            onChange    = {({target:{value}}: any) => setPassword(value)}/>
                                        <img 
                                            src       = {show} 
                                            className = "input-toggle-password" 
                                            onClick   = {() => setShowPassword(showPassword ?false:true)} />
                                    </div>
                                </div>

                                {   
                                    ( 
                                        password 
                                        && (password.length < 6 || password.length > 12)
                                    ) && <small className="form-text text-danger mb-3">
                                            The password must have between 6 and 12 characters!
                                        </small>
                                }

                                {
                                    (/\s/g.test(password))
                                    && <small className="form-text text-danger mb-3">
                                            The password can"t have space character
                                        </small>
                                }

                                <div className="input-password">
                                    <div className="form-group">
                                        <label className="form-label">Confirm your password</label>
                                        <input
                                            type        = {showConfirmPassword ? "text" : "password"}
                                            className   = "form-control"
                                            name        = "confirmPassword"
                                            placeholder = "Confirm your password"
                                            value       = {confirmPassword}
                                            onChange    = {({target:{value}}: any) => setConfirmPassword(value)}/>
                                        <img 
                                            src       = {show}
                                            className = "input-toggle-password"
                                            onClick   = {() => setConfirmShowPassword(showConfirmPassword ?false:true)} />
                                    </div>
                                </div>

                                {   
                                    (
                                        confirmPassword
                                        && (password !== confirmPassword)
                                    ) && <small className="form-text text-danger mb-3">
                                            The passwords must match!
                                        </small>
                                }

                                <div className=" d-flex flex-row flex-wrap justify-content-end pr-2 mt-4">
                                    <ButtonStyle 
                                        green
                                        type     = "submit"
                                        label    = "Reset Password"
                                        icon     = "arrowRight"
                                        size     = "medium"
                                        disabled = {
                                            (password !== confirmPassword) 
                                            || (password.length < 6 || password.length > 12)
                                            || /\s/g.test(password)
                                        } />
                                </div>
                            </form>
                    </div>
                    <footer>
                        <img
                            src       = {yazigiLogo} 
                            alt       = "Global Scale of English Logo" 
                            className = "pr-5"
                            height    = "35px"/>
                        <img
                            src     = {GSE}
                            alt     = "Yazigi Logo"
                            height  = "55px"/>
                    </footer>
                </section>
            </main>
}

export default ResetPasswordPage
import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"

import FormContainer from "YConnect/Containers/Form.container"

import DEFAULT_TEMPLATE   from "YConnect/PagesComponents/CMS/Templates/Default.template.json"

import DragAndDropTextAndImageEditor from "YConnect/PagesComponents/CMS/Editors/DragAndDropTextAndImage.editor/DragAndDropTextAndImage.editor"

type DragAndDropTextAndImageTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const DragAndDropTextAndImageTemplateEditor = ({
    question,
    onChangeValues 
}: DragAndDropTextAndImageTemplateEditorProps
) => {
    const [values, setValues] = useState<QuestionType>(question)


    const addValues = (newValues:QuestionType) => {
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }

    const handleChangeValues   = (values:QuestionType) => addValues(values)

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])

 
    return  <>
                <Row>
                    <Col md={12}>
                        <FormContainer
                            values   = {values}
                            onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                            template = {[...DEFAULT_TEMPLATE.map((field) => ({...field}))]}/>
                    </Col>
                    <DragAndDropTextAndImageEditor 
                        question={values}
                        onChange={handleChangeValues}/>
                </Row>
            </>
}

export default DragAndDropTextAndImageTemplateEditor

import styled from "styled-components"

const FooterTopStyle = styled.div`
    background-color: ${props => props.theme.main.mainPurple};
    color: ${props => props.theme.main.textWhite};

    @media screen and (min-width: 768px) {
        height: 450px;
    }
    @media screen and (min-width: 1024px) {
        height: 290px;
    }

    &.withoutOnlineTeacher {
        height: 200px;

        .footer-top-message {
            padding-top: 150px;
        }

        @media screen and (max-width: 1024px) {
            height: 250px;

            .footer-top-message {
                padding-top: 140px;
            }
        }

        @media screen and (max-width: 768px) {
            .footer-top-message {
                padding-top: 70px;
            }
        }
    }

    .footer-top {
        background-color: ${props => props.theme.main.mainPurple};
        height: 100%;
        @media screen and (min-width: 1024px) {
            padding: 0;
        }
    }

    .footer-top-message {
        transform: translateY(-10%);
        @media screen and (min-width: 1024px) {
            height: 150px;
            padding: 0 15px;
            transform: translateY(-50%);
        }
    }

    .footer-top-about {
        flex-flow: column wrap;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

        .about-logos {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            margin-bottom: 14px;
            width: 100%;

            @media screen and (min-width: 1024px) {
                margin-bottom: 0;
                width: 25%;
            }

            .col-6{
                text-align: center;
            }

/*
            .footer-logo-img:first-child {
                padding-right: 30px;
                @media screen and (min-width: 1024px) {
                    padding-right: 15px;
                    width: 120px;
                }
            }

            .footer-logo-img:last-child {
                padding-left: 30px;
                @media screen and (min-width: 1024px) {
                    padding-left: 15px;
                    width: 120px;
                }
            }
            */
        }

        .footer-logo-img {
            /*width: 130px;*/
            @media screen and (max-width: 1024px) {
                margin-bottom: 15px;
            }
        }

        .about-links {
            border-bottom: 1px solid ${props => props.theme.main.backgroundPage};
            text-align: right;
            width: 100%;
            @media screen and (min-width: 1024px) {
                height: 40px;
            }

            a {
                color: ${props => props.theme.main.textWhite};
                font-weight: bold;
                text-decoration: none;
                &:hover {
                    color: ${props => props.theme.main.lightGreen}!important;
                }
            }

            @media screen and (min-width: 1024px) {
                width: 60%;
            }

            @media not all and (min-resolution:.001dpcm)
            { @supports (-webkit-appearance:none) {

                /*Safari browser*/
                a{
                    font-size: 0.9375rem !important;
                }
            }}
        }

        .social-media {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            margin: 24px 0;
            width: 100%;
            @media screen and (min-width: 1024px) {
                border-bottom: 1px solid ${props => props.theme.main.backgroundPage};
                height: 40px;
                justify-content: space-between;
            }

            a {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                padding: 5px;
                width: 48px;
                @media screen and (min-width: 1024px) {
                    height: 30px;
                    width: 30px;
                }
                &:hover {
                    background-color: $lightPurple;
                    border-radius: 50%;
                }
            }

            @media screen and (min-width: 1024px) {
                margin: 0;
                width: 15%;
            }
        }

        .social-media-img {
            height: 20px;
            width: 20px;
        }
    }
`

export default FooterTopStyle

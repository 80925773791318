import * as React              from "react"
import { useState, useEffect } from "react"
import {useContext}            from "react"
import { Tab, Badge }          from "react-bootstrap"
import { CSSTransition }       from "react-transition-group"
import { FormGroup }           from "reactstrap"
import Select                  from "react-select"
import styled                  from "styled-components"

import UserContext              from "YConnect/User.context"
import { BreadcrumbMessage }    from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle                from "YConnect/Components/PageTitle"
import InboxTab                 from "YConnect/PagesComponents/Message/InboxTab"
import SentTab                  from "YConnect/PagesComponents/Message/SentTab"
import getConfig                from "YConnect/Utils/GetRequestConfig.util"
import {LinkStyle, ButtonStyle} from "YConnect/Components/Button"
import Loader                   from "YConnect/Components/Loader"
import API                      from "YConnect/API"
import TabStyle                 from "YConnect/Styles/Tab.style"
import AlphaBlackStyle          from "YConnect/Styles/AlphaBlack.style"
import Toastify from "toastify-js"

const CharacterLimit = styled.div`
    display: flex;
    justify-content: flex-end;
    border: none;
    margin-top: -10px;
`

const MessagesPage = () => {

    const userContext:any  = useContext(UserContext)

    const typeOptions = [{'value': 1, 'label': 'Individual'},{'value': 2, 'label': 'Class'}];

    const [composeMenu, setComposeMenu]             = useState(false)
    const [inboxTabTitle, setInboxTabTitle]         = useState()
    const [recipient, setRecipient]                 = useState()
    const [subject, setSubject]                     = useState("")
    const [message, setMessage]                     = useState("")
    const [isLoading, setLoading]                   = useState(false)
    const [isSendingMessage, setSendingMessage]     = useState(false)
    const [messagePageTitle, setMessagePageTitle]   = useState('Inbox')
    const [senderList, setSenderList]               = useState([])

    //TODO messagesList não usado pq?
    const [messagesList, setMessagesList]           = useState()

    const [pageWidth, setPageWidth] = useState()

    // Options for filter users
    const [establishments, setEstablishments]         = useState()
    const [selectedEstab, setSelectedEstab]           = useState()
    const [coursesMessage, setCoursesMessage]         = useState()
    const [groups, setGroups]                         = useState()
    const [selectedGroup, setSelectedGroup]           = useState()
    const [selectedCourseMessage, setSelectedCourse]  = useState()
    const [searchMessage, setSearchMessage]           = useState('')
    const [selectedTypeOption, setSelectedTypeOption] = useState()
    const [flag, setFlag]                             = useState()
    const [idSearch, setIdSearch]                     = useState()


    useEffect(() => {
        _loadMessage();
        _loadUsersFilter();
        userContext.profiles.indexOf(15) > -1 ? null : _loadTeacherInfo();
        setPageWidth(window.innerWidth)
    }, [])

    const _loadTeacherInfo = () => {
        API.Aluno.Get({id: userContext.idUsuario}, getConfig())
        .then((res: any) => {
            if (res.data.length > 0) {
                setSearchMessage('');
                let searchedUsers: any[] = [];
                res.data.forEach((user: any) => {
                    searchedUsers.push({'value': user.id_profObs, 'label': user.nome_profObs})
                });
                setSenderList(searchedUsers);
                setSelectedTypeOption('1')
            }
        })
    }

    const _loadMessage = () => {
        setLoading(true);
        API.Note.Get2({
            id          : userContext.idUsuario,
            tipo        : 'R',
            pageNumber  : 1,
            qntdItens   : 10,
            dashboard   : false
        }, getConfig())
        .then((res: any) => {
            setMessagesList(res.data.listaMensagens);
            let hasNewMessage = res.data.listaMensagens.filter((msg: any) => msg.status_lido === 'N');
            hasNewMessage = hasNewMessage.length > 99 ? '99+' : hasNewMessage.length;
            if (hasNewMessage) {
                let inboxTabTitleHTML = <div>
                    <span className="pr-3">Inbox</span>
                    <Badge variant="warning" className="pr-3 pl-3 pt-1 pb-1">{hasNewMessage || ""}{window.innerWidth > 768 ? " new!" : ""}</Badge>
                </div>
                setInboxTabTitle(inboxTabTitleHTML)

            } else {
                let inboxTabTitleHTML = <div>
                    <span className="pr-3">Inbox</span>
                </div>
                setInboxTabTitle(inboxTabTitleHTML)
            }
        })
        .catch((err: any) => {
            let inboxTabTitleHTML = <div>
                <span className="pr-3">Inbox</span>
            </div>
            setInboxTabTitle(inboxTabTitleHTML)
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const _loadUsersFilter = () => {
        setEstablishments(userContext.estabelecimentos);
        if (userContext.cursosVigentes.length) {
            setCoursesMessage(userContext.cursosVigentes);
        } else {
            API.Course.GetAll({
                isDegustacao : false
            }, getConfig())
            .then((res: any) => {
                setCoursesMessage(res.data);
            })
            .catch((err: any) => {
            })
            .finally(() => {
            })
        }
    }

    const _sendMessage = () => {

        if (parseInt(selectedTypeOption, 10) === 2) {
            selectedGroup.forEach((group: any) => {
                setLoading(true);
                setSendingMessage(true);
                if(message.length > 3000){
                    Toastify({
                        text: "Wait, the message over 3000 characteres!",
                        duration: 3000,
                        className: "toastify-warning",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                } else {
                    const formBody = {
                        anotacao: message,
                        assunto: `${subject}`,
                        caminho: userContext.profiles.indexOf(15) > -1 ? "PA" : "AP",
                        data: new Date(),
                        id_destino: '',
                        id_grupo: group.value,
                        id_remetente: userContext.idUsuario
                    }
                    API.Note.sendMessage({...formBody}, getConfig())
                    .then((res: any) => {
                        setComposeMenu(false);
                    })
                    .finally(() => {
                        if (userContext.profiles.indexOf(15) > -1) {
                            setSenderList([])
                            setSelectedCourse(null)
                            setSelectedEstab(null)
                            setSelectedTypeOption(null)
                        }
                        setLoading(false)
                        setSendingMessage(false)
                        setSubject('')
                        setMessage('')
                        setRecipient([])
                    })
                    Toastify({
                        text: "Success! <br> Message sent with success!",
                        duration: 3000,
                        className: "toastify-success",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }
            });
        } else {
            recipient.forEach((user: any) => {
                setLoading(true);
                setSendingMessage(true);
                if(message.length > 3000){
                    Toastify({
                        text: "Wait, the message over 3000 characteres!",
                        duration: 3000,
                        className: "toastify-warning",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                } else {
                    const formBody = {
                        anotacao: message,
                        assunto: `${subject}`,
                        caminho: userContext.profiles.indexOf(15) > -1 ? "PA" : "AP",
                        data: new Date(),
                        id_destino: user.value,
                        id_grupo: '',
                        id_remetente: userContext.idUsuario
                    }
                    API.Note.sendMessage({...formBody}, getConfig())
                    .then((res: any) => {
                        setComposeMenu(false);
                    })
                    .finally(() => {
                        if (userContext.profiles.indexOf(15) > -1) {
                            setSenderList([])
                            setSelectedCourse(null)
                            setSelectedEstab(null)
                            setSelectedTypeOption(null)
                        }
                        setLoading(false)
                        setSendingMessage(false)
                        setSubject('')
                        setMessage('')
                        setRecipient([])
                    })
                    Toastify({
                        text: "Success! <br> Message sent with success!",
                        duration: 3000,
                        className: "toastify-success",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }
            });
        }

    }

    const _changeType = (e: any) => {
        setSelectedTypeOption(e.target.value);
        if (e.target.value === '2' && senderList) {
            setRecipient(senderList)
        }
    }

    const _setFlag = (estab: any) => {
        setSelectedEstab(estab)
        let flagSelected = false;
        for (let i = 0; i < userContext.estabsSupervisor.length; i++) {
            if (parseInt(estab, 10) === userContext.estabsSupervisor[i].nsu_estab) {
                flagSelected = true;
                break;
            }
        }
        if (userContext.IdProfessor != 0) {
            setIdSearch(flagSelected ? 0 : userContext.idProfessor)
            setFlag(flagSelected ? 0 : 1)
        } else if (userContext.colaborador) {
            setIdSearch(userContext.idUsuario)
            setFlag(3)
        } else if (userContext.observador) {
            setIdSearch(userContext.idUsuario);
            setFlag(2)
        }
    }

    const _verifyCharacteres = (e : any) => {
        setMessage(e.target.value)
        var qtdcaracteres = e.target.value.length;
        var restantes = 3000 - qtdcaracteres;

        if (restantes < 1) {
            e.value = e.target.value.slice(0, 3000);
            document.getElementById('carResTxtVoce').innerHTML = "0";
        }
        document.getElementById('carResTxtVoce').innerHTML = qtdcaracteres.toString();
    }

    const _searchUsers = () => {
        setSenderList([]);
        if (parseInt(selectedTypeOption, 10) === 2) {
            API.Grupo.Get({
                idEstab  : selectedEstab,
                idCurso  : selectedCourseMessage
            },getConfig())
            .then((res: any) => {
                if (res.data.turmas.length > 0) {
                    setSearchMessage('');
                    let searchedUsers: any[] = [];
                    res.data.turmas.forEach((user: any) => searchedUsers.push({'value': user.id_grupo, 'label': user.nome_grupo, 'alunos': user.alunos}));
                    setGroups(res.data.turmas);
                    setSenderList(searchedUsers);
                    setRecipient(searchedUsers)
                } else {
                    setSearchMessage('You are not related to any current student / class at this establishment and course')
                }

            })
            .catch((err: any) => {
            })
            .finally(() => {
            })
        } else {
            API.Usuario.getUsersByClass({
                id       : idSearch,
                idEstab  : selectedEstab,
                idCurso  : selectedCourseMessage,
                flag     : flag
            },getConfig())
            .then((res: any) => {
                if (res.data.length > 0) {
                    setSearchMessage('');
                    let searchedUsers: any[] = [];
                    res.data.forEach((user: any) => searchedUsers.push({'value': user.id_aluno, 'label': user.nome_completo}));
                    setSenderList(searchedUsers);
                } else {
                    setSearchMessage('You are not related to any current student / class at this establishment and course')
                }

            })
            .catch((err: any) => {
            })
            .finally(() => {
            })
        }

    }

    const showTabs = () => {
        if (userContext.profiles.indexOf(6) > -1 || userContext.profiles.indexOf(12) > -1 ) {
            return (
                <TabStyle defaultActiveKey="inbox" id="message-table">

                    <Tab eventKey="inbox" title={inboxTabTitle} className="yc-tab">
                        <InboxTab pageWidth={pageWidth}/>
                    </Tab>

                    <Tab eventKey="sent" title="Sent mail" className="yc-tab">
                        <SentTab pageWidth={pageWidth}/>
                    </Tab>
                </TabStyle>
            )
        } else {
            return (
                <TabStyle defaultActiveKey="inbox" id="message-table" className="TesteNovo2">

                    <Tab eventKey="inbox" title={inboxTabTitle} onEntered={() => setMessagePageTitle('Inbox')}  className="yc-tab" >
                        <InboxTab pageWidth={pageWidth}/>
                    </Tab>

                    <Tab eventKey="sent" title="Sent mail" onEntered={() => setMessagePageTitle('Sent Mail')} className="yc-tab">
                        <SentTab pageWidth={pageWidth}/>
                    </Tab>
                </TabStyle>
            )
        }
    }


    return <main className="container">

        {isLoading && <Loader />}
        <BreadcrumbMessage/>
        <PageTitle label={`My messages: ${messagePageTitle}`} className={"col-xs-12 col-md-6 d-flex flex-row flex-wrap justify-content-between"}>
            <div className="mb-4 col-md-6 d-flex justify-content-end">
                <ButtonStyle green
                    type="submit"
                    label="Compose"
                    icon="pencil"
                    onClick={() => setComposeMenu(true)}
                    size={'medium'} />
            </div>
        </PageTitle>

        <section className="row mb-5 messages">

            <div className="col-12">

                <div className="card nopadding shadow">
                    {isSendingMessage ? null : showTabs()}
                </div>

            </div>

        </section>

        {composeMenu && <AlphaBlackStyle/>}
        <CSSTransition classNames="dialog YC-lateral-menu" timeout={300} in={composeMenu} unmountOnExit>
            <div style={{zIndex:10000}} className="YC-lateral lateral-header lateral-header-messages shadow d-flex flex-column" >
                <h4 className="align-items-center d-flex flex-row justify-content-between mb-3">
                    New message
                    <LinkStyle
                        label="Close x"
                        className="close-modal text-right header-btn"
                        onClick={() => { setComposeMenu(false); userContext.profiles.indexOf(15) > -1 ? setSenderList([]) : null }} />
                </h4>
                {
                    userContext.profiles.indexOf(15) > -1
                    ? <div className="d-flex flex-row justify-content-between flex-wrap mb-3">
                        <FormGroup className="d-flex flex-row flex-wrap" style={{ minHeight: '60px', width: '100%' }}>
                            <label className="form-label label-select" htmlFor="estabOptionInput">Establishments</label>
                            <select className="form-control" onChange={(e: any) => _setFlag(e.target.value)} id="estabOptionInput">
                                <option>Select an option</option>
                                {establishments && establishments.map((establishment: any) => <option key={establishment.nsu_estab} value={establishment.nsu_estab}>{establishment.nm_estab}</option>)}
                            </select>
                        </FormGroup>

                        <FormGroup className="d-flex flex-row flex-wrap" style={{ minHeight: '60px', width: '45%' }}>
                            <label className="form-label label-select" htmlFor="estabOptionInput">Course</label>
                            <select className="form-control" onChange={(e: any) => setSelectedCourse(e.target.value)} id="estabOptionInput">
                                <option value="0" >Select an option</option>
                                {coursesMessage ? coursesMessage.map((course: any) => <option key={course.id_curso} value={course.id_curso}>{course.titulo_curso}</option>) : null}
                            </select>
                        </FormGroup>

                        <FormGroup className="d-flex flex-row flex-wrap" style={{ minHeight: '60px', width: '45%' }}>
                            <label className="form-label label-select" htmlFor="estabOptionInput">Type</label>
                            <select className="form-control" onChange={(e: any) => _changeType(e)} id="estabOptionInput">
                                <option value="0" >Select an option</option>
                                {typeOptions.map((option: any) => <option key={option.value} value={option.value}>{option.label}</option>)}
                            </select>
                        </FormGroup>

                        <ButtonStyle
                            className={`${!selectedEstab || !selectedCourseMessage || !selectedTypeOption ? "disabled" : ""}`}
                            label="Search"
                            icon="search"
                            disabled={!selectedEstab || !selectedCourseMessage || !selectedTypeOption}
                            onClick={_searchUsers}
                            size={'medium'}
                        />

                    </div>
                    : null
                }

                {
                    senderList.length > 0
                        ?<>
                            <form>
                                {
                                    selectedTypeOption === '1'
                                    ?<FormGroup className="d-flex flex-row flex-nowrap" style={{ minHeight: '60px' }}>
                                        <label className="form-label label-select" htmlFor="recipientInput">Recipients</label>
                                        <Select
                                            id="recipientInput"
                                            name="userName"
                                            options={senderList}
                                            onChange={(e: any) => setRecipient(e)}
                                            isMulti={true}
                                            isSearchable={true}
                                            placeholder="destination"
                                            classNamePrefix="message"
                                            className="option-message"
                                            isDisabled={senderList.length < 0}
                                        />
                                    </FormGroup>
                                    : <FormGroup className="d-flex flex-row flex-nowrap" style={{ minHeight: '60px' }}>
                                        <label className="form-label label-select" htmlFor="classroomInput">Classroom</label>
                                        <Select
                                            id="classroomInput"
                                            name="userName"
                                            options={senderList}
                                            onChange={(e: any) => setSelectedGroup(e)}
                                            isMulti={true}
                                            isSearchable={true}
                                            placeholder="destination"
                                            classNamePrefix="message"
                                            className="option-message"
                                            isDisabled={senderList.length < 0}
                                        />
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <label className="form-label" htmlFor="subjectInput">Subject</label>
                                    <input
                                        id="subjectInput"
                                        className="form-control"
                                        type="text"
                                        name="userName"
                                        placeholder="Message subject"
                                        maxLength={40}
                                        value={subject}
                                        required
                                        onChange={(e: any) => setSubject(e.target.value)}
                                        disabled={senderList.length < 0}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="form-label" htmlFor="messageInput">Message</label>
                                    <textarea maxlength="3000"
                                        id="messageInput"
                                        className="form-control"
                                        value={message}
                                        style={{ height: "95px" }}
                                        placeholder="Type your message"
                                        rows={5}
                                        disabled={senderList.length < 0}
                                        onChange={(e: any) => _verifyCharacteres(e)}
                                    ></textarea>
                                </FormGroup>
                                <CharacterLimit><span id="carResTxtVoce">0</span><span>/3000</span></CharacterLimit>
                            </form>

                            <footer className="d-flex flex-row justify-content-end mt-2">
                                <ButtonStyle green
                                    className={`${!recipient || !subject || !message ? "disabled" : ""}`}
                                    type="submit"
                                    label="Send message"
                                    icon="messageWhite"
                                    disabled={!recipient || !subject || !message}
                                    onClick={() => _sendMessage()}
                                    size={'large'}
                                />
                            </footer>
                        </>
                        : <p>{searchMessage}</p>
               }

            </div>
        </CSSTransition>
        {composeMenu && <AlphaBlackStyle/>}
    </main>
}

export default MessagesPage

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        font-size: ${(props) => props.theme.main.bodyFontSize};
    }

    body {
        font-family: ${(props) => props.theme.main.fontFamily};
        color: ${(props) => props.theme.main.textColor};
        background-color: ${(props) => props.theme.main.backgroundPage};
    }

    img.YC-icon-svg {
        filter: ${(props) => props.theme.main.filterSvg};
    }

    .text-muted {
        color: ${(props) => props.theme.main.mutedTextColor} !important;
        font-size: 0.75rem;
    }

    .text-error {
        color: ${(props) => props.theme.main.errorColor} !important;
    }

    .text-clickable {
        color: ${(props) => props.theme.main.greentextColor} !important;
        a {
            color: ${(props) => props.theme.main.greentextColor} !important;
        }
        img {
            filter: ${(props) => props.theme.main.filterSvg};
         }
    }

    /** YC-lateral */
    .YC-lateral {
        background-color: ${(props) => props.theme.main.backgroundWhite};
        color: ${(props) => props.theme.main.textColor};
        height: 100vh;
        padding: 36px;
        position: fixed;
        transition: transform 1s cubic-bezier(0, .52, 0, 1);
        top: 0;
        z-index: 10;
        @media screen and (min-width: 768px) {
            padding: 36px;
        }

        .header-btn {
            padding-right: 0;
            text-align: right;
            width: 80px;
        }
    }
    .lateral-header {
        right: 0;
        padding: 62px 16px 34px;
        width: 80%;
        z-index: 9;
        @media screen and (min-width: 1024px) {
            padding: 45px 16px 34px;
            width: 50%;
        }

        .lateral-header--title {
            align-items: center;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            @media screen and (min-width: 1024px) {
                padding: 0 8px;
            }
        }

        .header-filter {
            align-self: end;
            align-items: center;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            width: 100%;
        }
        &.with-navbar {
            padding: 64px 16px 34px;
        }
    }

    .lateral-header-course {
        width: 100%;

        @media screen and (min-width: 768px) {
            width: 80%;
        }

        &.starter{
            width: 1024px;
            @media screen and (min-width: 854px) and (max-width: 1279px) {
                width: 840px;
            }

            @media screen and (min-width: 640px) and (max-width: 767px) {
                width: 500px;
            }

            @media screen and (min-width: 360px) and (max-width: 640px) {
                width: 360px;
            }
        }
    }

    .lateral-menu-mobile {
        height: calc(100vh - 55px);
        overflow-y: scroll;
        max-height: calc(100vh - 55px);
        left: 0;
        padding: 0;
        top: 55px;
        width: 80%;
        @media screen and (min-width: 768px) {
            display: none;
            height: calc(100vh - 80px);
            top: 80px;
            width: 50%;
        }
    }

    .lateral-course-stamps {
        right: 0;
        transform: translate3d(700px, 0, 0);
        width: 700px;
        &.active {
            transform: translate3d(0vw, 0, 0);
            transition: transform .8s cubic-bezier(0, .52, 0, 1);
        }
    }

    .lateral-audio {
        right: 0;
        transform: translate3d(650px, 0, 0);
        width: 100vw;
        @media screen and (min-width: 1024px) {
            width: 650px;
        }
        &.active {
            transform: translate3d(0vw, 0, 0);
            transition: transform .8s cubic-bezier(0, .52, 0, 1);
        }
    }

    .lateral-progress {
        right: 0;
        transform: translate3d(800px, 0, 0);
        width: 800px;
        &.active {
            transform: translate3d(0vw, 0, 0);
            transition: transform .8s cubic-bezier(0, .52, 0, 1);
        }
    }

    button:disabled {
        cursor: not-allowed;
    }

    /** Buttons */
    button:disabled {
        cursor: not-allowed;
    }

    .btn-success {
        background-color: ${(props) => props.theme.main.clickable};
        border-color: ${(props) => props.theme.main.clickable};

        &:hover {
            background-color: darken(${(props) =>
                props.theme.main.clickable}, 3%);
        }

        &.disabled {
            background-color: ${(props) =>
                props.theme.main.backgroundBtDisabled};
            border-color: ${(props) => props.theme.main.backgroundBtDisabled};
            color: ${(props) => props.theme.main.colorBtDisabled};
            font-weight: 700;
            img {
                filter: invert(50%);
            }
        }

        &.high{
            background-color: white !important;
            color: black !important;
            &:hover{
                border: none !important;
            }
        }
    }

    .btn-fixed {
        background-color: ${(props) => props.theme.main.backgroundWhite};
        border-top: 1px solid ${(props) => props.theme.main.mainPurple};
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        padding: 9px 16px;
        position: fixed;
        width: 100%;
        z-index: 5;


        @media screen and (min-width: 768px) {
            background-color: transparent;
            border-top: none;
            display: block;
            margin-bottom: 24px;
            position: relative;
            width: auto;
        }

        > button {
            font-size: 0.875rem;
            @media screen and (min-width: 1024px) {
                font-size: 1rem;
                max-width: 100%;
            }
        }
    }

    .btn-radio {
        color: ${(props) => props.theme.main.title};
        cursor: pointer;
        background-color: ${(props) => props.theme.main.backgroundWhite};
        border-color: ${(props) => props.theme.main.clickable};

        &:first-child {
            border-radius: 50px 0 0 50px;
        }

        &:last-child {
            border-radius: 0 50px 50px 0;
        }

        &:hover, &.active {
            background-color: ${(props) =>
                props.theme.main.backgroundRadioActive};
            color: ${(props) =>
                props.theme.main.backgroundRadioActiveText} !important;
            font-weight: bold;

            img {
                filter: grayscale(100%);
            }
        }
    }

    .checkmark {
        background-color: ${(props) => props.theme.main.backgroundWhite};
        border: 2px solid ${(props) => props.theme.main.clickable};
        border-radius: 4px;
        height: 25px;
        left: 0;
        position: absolute;
        width: 25px;
    }

    .container-input:hover input~.checkmark {
        background-color: ${(props) => props.theme.main.backgroundWhite};
    }

    .container-input input:checked~.checkmark {
        background-color: ${(props) => props.theme.main.backgroundWhite};
        border: 2px solid ${(props) => props.theme.main.clickable};
    }

    /** TABLE */
    .table {
        min-height: 150px;
        thead th {
            border-bottom: 2px solid ${(props) => props.theme.main.lineTable};
        }

        td, th {
            border-top: none;
            color: ${(props) => props.theme.main.textColor};
            vertical-align: middle;
        }

        .table-line {
            border-bottom: 1px solid ${(props) => props.theme.main.lightGray};
        }

        .task-link {
            a {
                color: ${(props) => props.theme.main.clickable};
            }
        }

    }


    /** CARD */
    .card {
        border-radius: 8px;
        margin: 0 0 30px;
        padding: 24px 16px;
        background-color: ${(props) => props.theme.main.backgroundWhite};

        @media screen and (min-width: 768px) {
            padding: 40px;
        }

        &.nopadding {
            padding: 0;
        }

    }

    .card-padding {
        padding: 24px 16px;
        @media screen and (min-width: 768px) {
            padding: 40px;
        }
    }

    .btn-group{
        .input-disabled {
            border-color: #989898;
            label {
                color:  ${(props) =>
                    props.theme.main.btnDisabledText} !important;
            }
            select, input, textarea {
                color:  ${(props) =>
                    props.theme.main.btnDisabledText} !important;
            }
        }
    }

    /** Form Group */
    .form-group, .input-group {
        background-color: ${(props) => props.theme.main.backgroundWhite};
        border: 0.5px solid #E1E6E7;
        border-radius: 4px 4px 0 0;
        padding: 1px 1px 0px 1px;

        .form-label, .control-label {
            color: ${(props) => props.theme.main.clickable};
            font-size: 0.75rem;
            font-weight: bold;
            padding: 8px 15px 4px;

            &.label-select {
                margin: 8px 0;
                padding: 0;
                position: absolute;
                text-indent: 15px;
            }
        }

        .form-control {
            background-color:  ${(props) => props.theme.main.backgroundWhite};
            color: ${(props) => props.theme.main.textColor};
            border: 0;
            border-bottom: 1px solid ${(props) => props.theme.main.clickable};
            border-radius: 0;
            height: 25px;
            padding: 0 15px;
            transition: border-bottom ease 0.3s;
            &:focus {
                color:  ${(props) => props.theme.main.textColor};
                background-color:  ${(props) =>
                    props.theme.main.backgroundWhite};
                border-color: transparent;
                border-bottom: 2px solid ${(props) =>
                    props.theme.main.clickable};
                outline: 0;
                box-shadow: none;
            }

            &:disabled {
                background-color:  ${(props) =>
                    props.theme.main.inputDisabled}!important;
            }
        }

        select.form-control {
            cursor: pointer;
            margin: 8px 0 0 0;
            height: 55px;
            padding: 0;
            text-indent: 10px;
            display: -webkit-box;
            -webkit-box-align: end; /* align-items */
        }

        .isInvalid{
            border-bottom: 2px solid #f00;
        }

        .input-icon {
            filter: ${(props) => props.theme.main.filterSvgGreen};
            position: absolute;
            right: 16px;
            top: calc(50% - 10px);
            z-index: 5;
        }

        textarea {
            min-height: 95px!important;
        }



        &.input-disabled {
            background-color: ${(props) => props.theme.main.inputDisabled};
            border-bottom: 1px solid #989898;
            cursor: not-allowed;

            .form-control{
                border-bottom: 1px solid #989898;
                color:#989898;
            }
            label {
                color:#989898;
            }

            img {
                filter: grayscale(100%);
            }

            input {
                cursor: not-allowed;
            }


        }

        &.has-error {
            border-bottom: 1px solid $error;
            .form-label, .control-label {
                color: $error;
            }
        }
    }

    /* Online teacher */
    .chat-text-cta {
        background-color: ${(props) => props.theme.main.backgroundWhite};
    }
    /* Travel */
    .countrypage {
        .tab-content {
            background-color: ${(props) => props.theme.main.backgroundWhite};
            border-radius: 8px;

            .tab-pane {
                margin: 38px 0;
                @media screen and (min-width: 1024px) {
                    border: 1px solid ${(props) => props.theme.main.mainPurple};
                    border-radius: 8px;
                }
            }
        }
    }
    .testimonial-modal {
        .testimonial-card {
            margin: 0;
            width: 100%;
        }
    }

    .testimonial-card {
        display: flex;
        flex: none;
        flex-flow: column wrap;
        justify-content: space-between;
        height: auto;
        margin: 0 16px 0 0;
        min-height: 400px;
        max-height: 500px;
        width: 80%;
        @media screen and (min-width: 1024px) {
            padding-right: 20px;
            max-height: unset;
            min-height: unset;
            width: 100%;
        }

        &.borderLine {
            border-bottom: 1px solid #C5CBCC;
            margin: 16px 0;
            padding: 0 0 30px 0;
        }
        header {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            @media screen and (min-width: 1024px) {
                align-items: center;
            }

            .author-name {
                display: block;
                font-weight: bold;
            }

            .author-date {
                display: block;
            }

            .testimonial-author {
                width: 70%;
                @media screen and (min-width: 1024px) {
                    width: 50%;
                }
            }

            .testimonial-likes {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
                @media screen and (min-width: 1024px) {
                    justify-content: space-between;
                    margin: 0;
                    width: 33%;
                }
                .testimonial-like {
                    color: ${(props) => props.theme.main.clickable};
                    @media screen and (max-width: 1024px) {
                        position: relative;
                        margin-left: 25px;
                    }


                    img {
                        margin-left: 10px;
                    }
                }

                .testimonial-amount {
                    background-color: #F0F5F6;
                    border-radius: 8px;
                    color: #000;
                    font-size: 0.875rem;
                    margin: 0 8px;
                    padding: 0px 6px;
                    @media screen and (min-width: 1024px) {
                        margin: 0 16px;
                        padding: 0 16px;
                    }
                }
            }
        }

        .testimonial-text {
            font-style: italic;
            padding: 26px 0;
            word-break: break-word;
            @media screen and (min-width: 1024px) {
                padding-right: 50px;
            }
        }

        .testimonial-dropdown {
            .dropdown-toggle {
                color: ${(props) => props.theme.main.clickable}!important;
                background-color: transparent!important;
                border: transparent!important;
            }
            .dropdown-toggle::after {
                display: none!important;
            }
        }

        footer {
            border-top: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;

            .testimonial-btn {
                img {
                    width: 30px;
                }

                .warning {
                    filter: hue-rotate(180deg);
                }
            }
        }
    }


    /** Trumps Styles */
    .ReactCrop {
        background-color: transparent!important;
    }

    .yc-tab {
        background-color:  ${(props) =>
            props.theme.main.backgroundWhite}!important;
        padding: 16px;
        @media screen and (min-width: 768px) {
            padding: 40px 38px;
        }
    }

    .decoration-none {
        text-decoration: none;
    }

    .white-icon {
        filter: grayscale(100%) brightness(0) invert(1);
    }
    .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
            width: 100%;
        }
    }
    .shadow {
        box-shadow: 2px 4px 8px ${(props) =>
            props.theme.main.boxShadow}!important;
    }

    .message__control {
        background-color:  ${(props) =>
            props.theme.main.backgroundWhite}!important;

        &--menu-is-open {
            background-color:  ${(props) =>
                props.theme.main.backgroundWhite}!important;
        }

    }
    .message__menu-list {
        background-color:  ${(props) =>
            props.theme.main.backgroundWhite}!important;
        color:  ${(props) => props.theme.main.textColor}!important;

        .message__option {
            background-color:  ${(props) =>
                props.theme.main.backgroundWhite}!important;
            color:  ${(props) => props.theme.main.textColor}!important;
        }
        .message__option--is-focused {
            background-color:  ${(props) =>
                props.theme.main.focusSelect}!important;
            color:  ${(props) => props.theme.main.textColor}!important;
        }
    }

    .unlock-filter {
        padding: 0 0 0 16px;
        width: 100%;
        @media screen and (min-width: 1024px) {
            padding: 0 15px;
            width: 50%;
        }
    }

    @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
        .explore-tab {
            margin-bottom: 80px;
        }

        .header-filter {
            margin: 24px 0;
            position: static;
        }
    }

    .download-header-tab {
        margin: 100px 0 24px;
    }

    .tooltip {
        z-index: 10000 !important;
    }
`;

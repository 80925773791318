import * as React from "react"
import getCourseName from "YConnect/Utils/CourseUnitName.util"

export const CONFIG_TABLE_REPORT_TASK: Array<any> = [
    {
        label: "Task",
        formatter: ({ nome_exercicio, ordem_exercicio }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span>{nome_exercicio}</span>
                <span className="small text-secondary">Task {ordem_exercicio}</span>
            </div>
        </div>
    },
    {
        keystone: "descricao_tipo",
        label: "Type"
    },
    {
        keystone: "peso",
        label: "Weight"
    }

]

const CONFIG_TABLE_REPORT_TASK_UNIT: Array<ColumnConfigType> = [
    {
        label: "Unit",
        formatter: ({ unidade, nomeUnidade, idCurso }: any) => getCourseName(parseInt(idCurso), unidade, nomeUnidade)
    }].concat(CONFIG_TABLE_REPORT_TASK)

export default CONFIG_TABLE_REPORT_TASK_UNIT

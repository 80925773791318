import * as React from "react"
import styled from "styled-components"

import ClassTeacherStyle from "YConnect/PagesComponents/PrePostReport/Teacher.components/ClassTeacher.style"
import ReportTeacherInfo from "YConnect/PagesComponents/PrePostReport/Components/ReportTeacherInfo"


const CardStyle = styled.div`
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 223px;
`


const CardLoadingStyle = styled(CardStyle)`
.loading {
    left: calc(50% - 20px);
    position: relative;
    width: 40px;
    height: 40px;
    border: 4px transparent solid;
    border-top: 4px ${props => props.theme.main.lightPurple} solid;
    border-radius: 30px;
    animation: preload .7s infinite;
    animation-timing-function: linear;
}

    
@-moz-keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
`

const ClassTeacher = ({
    teacherInfo
}:any) =>
<ClassTeacherStyle>
    <p className="titlepage">Class' teacher</p>
    {
        teacherInfo 
        ? <ReportTeacherInfo teacherInfo={teacherInfo}/>
        : <CardLoadingStyle>
                <div className="loading"></div>
            </CardLoadingStyle>
    }
</ClassTeacherStyle>


export default ClassTeacher
import React, { useEffect, useState } from "react"
import { EditorState, ContentState, convertToRaw, convertFromHTML } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"


import styled from "styled-components"

import htmlToDraft from "html-to-draftjs"
import draftToHtml from "draftjs-to-html"
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"


const EditorStyled = styled(Editor)`
    .editorClassName{

    }
`

const EditorField = ({
    keystone,
    className,
    label,
    required,
    value,
    onChange,
    toolbar
}: any) => {

    const [editorState, setEditorState] = useState<any>()
    
    useEffect(()=>{
        if(value){
            const contentBlock = htmlToDraft(`${GetNewHtmlReplaceImg(value)}`)

            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                setEditorState(EditorState.createWithContent(contentState))
            }
        }else{
            setEditorState(undefined)
        }
    }, [value])


    const handleChange = (editorState: any) => {
        const html=draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if(html && html.substring(0,html.length-1) != "<p></p>"){
            onChange(keystone, draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }else{
            onChange(keystone, undefined)
        }
      
        setEditorState(editorState)
    }

    return <div className={`${className || ""}`}>
        <div className={`form-group`}>
            {(label) ? <label className="form-label">{`${label}${required ? "*" : ""}`}</label> : ""}
            <div>
                {
                    editorState
                    &&<EditorStyled
                        defaultEditorState={editorState}
                        onEditorStateChange={handleChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName p-3"
                        toolbar={toolbar}/>
                }
                {
                    !editorState
                    &&<EditorStyled
                        defaultEditorState={editorState}
                        onEditorStateChange={handleChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName p-3"
                        toolbar={toolbar}/>
                }
            </div>
        </div>
    </div>

}


export default EditorField
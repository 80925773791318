import * as React from "react"
import {Fragment} from "react"
import styled from "styled-components"
import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg"

const ClickStyle = styled.span`
    font-weight:bold;
    color: ${props => props.theme.main.greentextColor};
    cursor:pointer;
`
const SuccessStyle = styled.span`
    font-weight:bold;
    color: ${props => props.theme.main.successColor};
`

const CONFIG_TABLE_GRADE_SENDING:Array<ColumnConfigType> = [
    {
        keystone : "units",
        label    : "Unit"
    },
    {
        label: "Student",
        formatter: ({ nome_aluno, nome_curso }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span>{nome_aluno}</span>
                <span className="small text-secondary">{nome_curso}</span>
            </div>
        </div>
    },
    {
        keystone : "unitsScore",
        label    : "Unit's Score",
        formatter : (unitsScore: any) => <div className="d-flex">
                                             {
                                                 unitsScore.map(({unidade, finalScore}:any, key:any) =>
                                                 <div key={key} className="d-flex flex-column mr-4">
                                                    <span>{finalScore}</span>
                                                    <span className="small text-secondary">{unidade}</span>
                                                </div> )
                                             }
                                         </div>
    },
    {
        keystone : "finalScore",
        label    : "Final score"
    },
    {
        label     : "Sending date",
        formatter : (({error, data_envio, etapa_enviada, onTryAgain}:any)=>{
            if(data_envio){
                const sendDate = new Date(data_envio)

                const [
                    year, month, day, hours, minute
                ] = [
                    sendDate.getFullYear(),
                    sendDate.getMonth()+1,
                    sendDate.getDate(),
                    sendDate.getHours(),
                    sendDate.getMinutes()
                ]

                return <div className="d-flex">
                            <div className="d-flex flex-column mr-4">
                                <span>{`${day}/${month<10?"0"+month:month}/${year} - ${hours}:${minute<10?"0"+minute:minute}`}</span>
                                {
                                    error
                                    && etapa_enviada !== true
                                    && <Fragment>
                                            <span className="small text-danger">Error sending!</span>
                                            <ClickStyle className="small" onClick={onTryAgain}>Try again</ClickStyle>
                                        </Fragment>
                                }
                                {etapa_enviada === true && <SuccessStyle>Sent successfully</SuccessStyle>}
                            </div>
                        </div>
            }

            return <></>
        }
    )
    },
    {
        label     : "Full report",
        formatter :  ({id_aluno, id_curso, idCurso}:any) =>
        <a href={`#/reports/view-full-report/${id_curso ? id_curso : idCurso}/${id_aluno}`}>Consult
        <img src={arrowRight} alt="" className="pl-2" /> </a>
    }
,
]

export default CONFIG_TABLE_GRADE_SENDING

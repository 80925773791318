import { useEffect, useReducer } from "react"

import API              from "YConnect/API"
import getRequestConfig from "YConnect/Utils/GetRequestConfig.util"

type ReportAction = {
    name     : string
    report  ?: any 
}

const useReport = ({
    semesterIdSelected, 
    regionalIdSelected, 
    establishmentIdSelected, 
    courseIdSelected, 
    classIdSelected
} : any) => {
    
    const [{
        schoolInfo,
        bestClass
    }, setReport] = useReducer((state:any, {name, report}:ReportAction) => {
        return  {...state, [name]:report}
    }, {})

    useEffect(()=>{
        if (semesterIdSelected && semesterIdSelected !== "") {
            setReport({name:"bestClass", report: undefined})
            API.PrePostTest.GetBestClass({
                idAnoSemestrebc     : semesterIdSelected, 
                idCursobc           : courseIdSelected, 
                idEstabelecimentobc : establishmentIdSelected, 
                idClassebc          : classIdSelected,
                regional            : regionalIdSelected
            
            }, getRequestConfig())
            .then((response:any) => setReport({name:"bestClass", report: response.data.scores || []}))

            setReport({name:"schoolInfo", report: undefined})
            API.PrePostTest.GetInformacoesEscolas({ 
                idAnoSemestreEscolas : semesterIdSelected, 
                idCurso              : courseIdSelected, 
                idEstabelecimento    : establishmentIdSelected, 
                idClasse             : classIdSelected,
                regional             : regionalIdSelected,
            }, getRequestConfig())
            .then((response: any) => setReport({name:"schoolInfo", report: response.data.score}))
        }
    }, [ 
        semesterIdSelected, 
        regionalIdSelected, 
        establishmentIdSelected, 
        courseIdSelected, 
        classIdSelected
    ])

    return {schoolInfo, bestClass}
}

export default useReport
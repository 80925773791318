import * as React from "react"
import {
    useState,
    useEffect,
    useContext
}                 from "react"
import { Modal }  from "react-bootstrap"
import { Link }   from "react-router-dom"

import * as logoIcon          from "YConnect/Assets/png/logo.png"
import * as accessibilityIcon from "YConnect/Assets/png/accessibility.png"
import * as QRIcon            from "YConnect/Assets/svg/qr-code.svg"
import * as mailIcon          from "YConnect/Assets/svg/mail.svg"
import * as menuIcon          from "YConnect/Assets/svg/menu-mobile.svg"
import * as closeIcon         from "YConnect/Assets/svg/close-btn.svg"

import UserContext               from "YConnect/User.context"
import PrePostContext            from "YConnect/PrePost.context"
import AccessibilityOptionsModal from "YConnect/Modals/AccessibilityOptions.modal"
import QrReader                  from "YConnect/Components/QrReader"
import UserInfoDropdown          from "YConnect/Components/UserInfo.dropdown"
import UserInfoMobileComponent   from "YConnect/Components/UserInfoMobile.component"
import Loading                   from "YConnect/Components/Loader"
import API                       from "YConnect/API"
import getConfig                 from "YConnect/Utils/GetRequestConfig.util"
import HelpInfoDropdown from "YConnect/Components/HelpInfo.dropdown"

import NavStyle              from "YConnect/Styles/Nav.style"
import ContainerStyle        from "YConnect/Styles/Container.style"
import ColNavbarLogoStyle    from "YConnect/Styles/ColNavbarLogo.style"
import DivNavbarLogoStyle    from "YConnect/Styles/DivNavbarLogo.style"
import FigureStyle           from "YConnect/Styles/Figure.style"
import DivNavbarStyle        from "YConnect/Styles/DivNavbar.style"
import NavLinkStyle          from "YConnect/Styles/NavLink.style"
import ColMessageStyle       from "YConnect/Styles/ColMessage.style"
import ColAccessibilityStyle from "YConnect/Styles/ColAccessibility.style"
import ColUserInfoStyle      from "YConnect/Styles/ColUserInfo.style"
import ColHelpInfoStyle      from "YConnect/Styles/ColHelpInfo.style"
import AttemptsIconStyle     from "YConnect/Styles/AttemptsIcon.style"
import { Tooltip } from 'reactstrap'
type HeaderProps = {
    configs          : Array<PageConfig>
    keystones        : Array<string>
    onLogout         : Function
    contrast         : string
    reading          : string
    onChangeContrast : Function
    onChangeReading  : Function
}

const filterPerKeystones = (keystones: Array<string>) => ({ keystone }: PageConfig) => keystones.indexOf(keystone) > -1;

const USER_DEFAULT = {
    foto                       : "",
    avatar                     : "",
    firstName                  : "",
    email                      : "",
    agreedQr                   : false,
    cursoAtualBloqueadoPreTest : false
}

const Header = ({ configs, keystones, onLogout, contrast, reading, onChangeContrast, onChangeReading }: HeaderProps) => {

    const userContext:any        = useContext(UserContext)
    const prePostUserContext:any = useContext(PrePostContext)

    const location = window.location

    const [hasLoading, setLoading]                          = useState(false)
    const [accessibilityMenu, setAccessibilityMenu]         = useState(false)
    const [menuMobile, setMenuMobile]                       = useState(false)
    const [user, setUser]                                   = useState(USER_DEFAULT)
    const [width, setWidth]                                 = useState(window.innerWidth)
    const [showModal, setShowModal]                         = useState(false)
    const [totalCountNewMessages, setTotalCountNewMessages] = useState(0)
    const [tooltipOpen, setTooltip] = useState(false);
    useEffect(() => {
        setUser(userContext);

        let id: any;
        if (userContext) {
            //checkNotification();
            id = setInterval(() => checkNotification(), 120000)
        }
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
            clearInterval(id)
        }
    }, [])

    useEffect(() => {
    }, [userContext])

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("data"))
        if (userInfo && user && (userInfo.cursoAtualBloqueadoPreTest != user.cursoAtualBloqueadoPreTest)) {
            user.cursoAtualBloqueadoPreTest = userInfo.cursoAtualBloqueadoPreTest
            setUser(user)
        }
    })

    const checkNotification = () => {
        API.Note.Get2({
            id          : userContext.idUsuario,
            tipo        : 'R',
            pageNumber  : 1,
            qntdItens   : 10,
            dashboard   : false
        }, getConfig())
        .then((res: any) => {
            let amountNewMessage = res.data.listaMensagens.filter((msg: any) => msg.status_lido === 'N');
            amountNewMessage = amountNewMessage.length > 99 ? '99+' : amountNewMessage.length;
            setTotalCountNewMessages(amountNewMessage)
        })
        .catch((err: any) => {})
    }

    const handleLogout = async () => {
        setLoading(true)
        onLogout()
    }

    const closeQRModal = () => {
        setShowModal(false)
        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
            stream.getTracks().forEach(function (track) {
                track.stop()
                track.enabled = false
            })
        })
    }

    const messageIconStyle = () => {
        let display = 'inherit'

        if (totalCountNewMessages > 0) {
            display = 'block'

            if (width <= 768) {
                display = 'inherit'
            }
        }

        return display
    }

    return user &&
        <NavStyle className="navbar YC-navbar align-items-center fixed-top d-flex flex-row justify-content-between p-0">
            {hasLoading && <Loading />}
            <ContainerStyle>
                <ColNavbarLogoStyle
                    xs        = "6"
                    sm        = "6"
                    md        = "6"
                    lg        = "1"
                    xl        = "1"
                    className = "d-lg-block p-0">
                    <span className="d-lg-none" onClick={() => setMenuMobile(!menuMobile)}>
                        <img className="YC-icon-svg" src={menuMobile ? closeIcon : menuIcon} alt="" />
                    </span>
                    <DivNavbarLogoStyle className="navbar-logo">
                        <FigureStyle>
                            <a href={keystones.length > 0 ?"#/" :undefined}>
                                <img className="img-fluid p-0" src={logoIcon} />
                            </a>
                        </FigureStyle>
                    </DivNavbarLogoStyle>
                </ColNavbarLogoStyle>
                {
                    !user.cursoAtualBloqueadoPreTest
                || (
                    prePostUserContext
                    && prePostUserContext.listPptTestAlunoYConnect
                    && prePostUserContext.listPptTestAlunoYConnect[0]
                    && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso
                    && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre
                    && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso.fl_feito === false
                )
                ?   <DivNavbarStyle lg="8" xl="8" className="navbar-links d-none d-lg-flex">
                        {
                            configs
                                .filter(filterPerKeystones(keystones))
                                .map(({ label, path }: any, key) => {
                                    if(path === '/technical-suppport') return true;
                                    if (path === '/online-teacher') {
                                        if (location.href.includes('pre_post/test')) {
                                            return true
                                        }
                                    }

                                    return (
                                        <NavLinkStyle className="px-3" key={key} to={`${path}`} id={`${key}`} activeClassName="active">
                                            <span className="d-flex flex-column justify-content-center h-100">
                                                {label}
                                            </span>
                                        </NavLinkStyle>
                                    )
                                })
                        }
                    </DivNavbarStyle>
                    :
                    ''
                }
                <DivNavbarStyle xs="6" sm="6" md="6" lg="3" xl="3" className="navbar-actions">
                    {
                        keystones.length > 0
                        && <ColMessageStyle
                            xs="3" sm="4" md="2" lg="2"
                            style={{ display: messageIconStyle() }}
                            className="messages align-items-center">
                            <Link
                                to="/my-message"
                                style={{ marginTop: totalCountNewMessages > 0 && width <= 375 ? '-15px' : '0' }}
                            >
                                <span
                                    className="message-notification badge badge-pill badge-warning"
                                    style={{ display: totalCountNewMessages > 0 && width < 768 ? 'initial' : 'none' }}>
                                    {totalCountNewMessages}
                                </span>
                                <img
                                    className="YC-icon-svg"
                                    src={mailIcon} />
                            </Link>
                        </ColMessageStyle>
                    }
                    <ColUserInfoStyle md="8" lg="8" className="user-info align-items-center d-none d-md-block flex-row justify-content-around">
                        <UserInfoDropdown user={userContext} onLogout={handleLogout} />
                    </ColUserInfoStyle>
                    <ColAccessibilityStyle
                        xs="2" sm="4" md="2" lg="2"
                        className="align-items-center pointer">
                        {
                            width < 1024
                                ? <label
                                    htmlFor="uploadSound"
                                    style={{ marginBottom: "0" }}
                                    onClick={() => setShowModal(true)}>
                                    <img src={QRIcon} />
                                </label>
                                : <img className="YC-icon-svg" src={accessibilityIcon} onClick={() => setAccessibilityMenu(!accessibilityMenu)} />
                        }
                    </ColAccessibilityStyle>
                    <ColHelpInfoStyle xs="1" sm="1" md="1" lg="1"
                        className="align-items-center pointer">
                            <HelpInfoDropdown width={width} />
                    </ColHelpInfoStyle>
                    <UserInfoMobileComponent
                        configs                    = {configs.filter(filterPerKeystones(keystones))}
                        isShow                     = {menuMobile}
                        user                       = {userContext}
                        cursoAtualBloqueadoPreTest = {user.cursoAtualBloqueadoPreTest}
                        onOpenAccessibility={() => {
                            setMenuMobile(!menuMobile)
                            setAccessibilityMenu(!accessibilityMenu)
                        }}
                        onLogout                   = {handleLogout}/>

                    <AccessibilityOptionsModal
                        isShow           = {accessibilityMenu}
                        contrast         = {contrast}
                        reading          = {reading}
                        userId           = {userContext.idUsuario}
                        onClose          = {() => setAccessibilityMenu(!accessibilityMenu)}
                        onChangeContrast = {(contrast: string) => onChangeContrast(contrast)}
                        onChangeReading  = {(reading: string) => onChangeReading(reading)} />
                </DivNavbarStyle>
            </ContainerStyle>
            <Modal show={showModal} onHide={() => closeQRModal()} size="lg" className="modal-qrcode">
                <Modal.Header closeButton>
                    Scanning the QR Code
                </Modal.Header>
                <Modal.Body className="d-flex flex-column justify-content-center">
                    <QrReader />
                </Modal.Body>
            </Modal>
        </NavStyle>
}

export default Header

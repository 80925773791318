import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Tab } from "react-bootstrap";
import { Col, Row, Tooltip } from 'reactstrap'
import styled from "styled-components"

import AttemptsIconStyle from "YConnect/Styles/AttemptsIcon.style"

import TabStyle from "YConnect/Styles/Tab.style";

const ButtonStyled = styled.button`
    color: ${(props) => props.theme.main.textInvert} !important;
`


const DivStyled = styled(Row)`
    margin: 0;
    div { max-width: 100% !important}
    padding-right: 0px !important;

    img {
        width: 50% !important;

        @media screen and (max-width: 768px) {
            width: 100% !important;
        }

        &.high{
            filter: brightness(100)
        }
    }

    p {
        @media screen and (max-width: 768px) {
            padding: 0 !important;
        }
    }
    h2, h3 {
        &.high{
            color : white !important;
        }
    }
`

const InteractionAluno = ({
    nome,
    balao,
    onNotifySchool,
    aula
}: any) => {
    const [contrast, setContrast] = useState("");
    const [language, setLanguage] = useState(true);
    const [tooltipOpen, setTooltip] = useState(false);

    useEffect(() => {
        setContrast(localStorage.getItem("contrast"))
    }, []);

    const controlLanguage = () => {
        setLanguage(!language)
        setTooltip(!tooltipOpen)
    }

    return (
        <>
            <DivStyled className="interaction-content">
                {
                    language
                    ? <Row>
                        <div className="col-7">
                            <div style={{display: 'flex'}}>
                                <h2 className={`${contrast}`}>{nome}, </h2>

                                    <div style={{marginTop :'5px'}}>
                                    <AttemptsIconStyle  id="TooltipAttempt" onClick={controlLanguage}>
                                                    ?
                                    </AttemptsIconStyle>
                                        <Tooltip style={{marginTop: '-13px'}} placement="right" onClick={() => setTooltip(!tooltipOpen)} isOpen={tooltipOpen} target="TooltipAttempt" toggle={() => setTooltip(!tooltipOpen)}>
                                            Click here to translate the text!
                                        </Tooltip>
                                    </div>
                            </div>
                            <h3 className={`${contrast}`}>a teacher will work on this class with you!</h3>
                        </div>

                        <div className="col-5">
                            <img src={balao} className={`${contrast}`} />


                        </div>
                        <Col>
                            <p>Congratulations for getting so far!
                            Click on the button to contact your school.
                            They will schedule the class for you!
                            </p>

                            {aula &&
                                <p>
                                    {`Watch Video_Starter_${aula.replace(' ', '_')} to prepare for your class. Have fun!`}
                                </p>
                            }
                            <ButtonStyled className={`btn btn-success ${contrast} mb-3`} onClick={onNotifySchool} >
                                Schedule this class!
                            </ButtonStyled>
                        </Col>
                    </Row>
                    : <Row>
                        <div className="col-7">
                            <div style={{display: 'flex'}}>
                                <h2 className={`${contrast}`}>{nome}, </h2>

                                    <div style={{marginTop :'6px'}}>
                                    <AttemptsIconStyle  id="TooltipAttempt" onClick={controlLanguage}>
                                                    ?
                                    </AttemptsIconStyle>
                                        <Tooltip style={{marginTop: '-13px'}} placement="right" onClick={() => setTooltip(!tooltipOpen)} isOpen={tooltipOpen} target="TooltipAttempt" toggle={() => setTooltip(!tooltipOpen)}>
                                            Click here to translate the text!
                                        </Tooltip>
                                    </div>
                            </div>
                            <h3 className={`${contrast}`}>um professor vai trabalhar nessa aula com você!</h3>
                        </div>

                        <div className="col-5">
                            <img src={balao} className={`${contrast}`} />

                        </div>
                        <Col>
                            <p>Parabéns por chegar tão longe!
                            Clique no botão para entrar em contato com sua escola.
                            Eles vão agendar a aula para você!
                            </p>
                            {aula &&
                                <p>
                                    {`Assista ao Video_Starter_${aula.replace(' ', '_')} para se preparar para sua aula. Divirta-se!`}
                                </p>
                            }
                            <ButtonStyled className={`btn btn-success ${contrast} mb-3`} onClick={onNotifySchool} >
                                Agende esta aula!
                            </ButtonStyled>
                        </Col>
                    </Row>
                }
            </DivStyled>
        </>
    )
}
export default InteractionAluno

import { useEffect}  from "react"

const useUpdateListByFilter = (
    setList:Function,
    getList:Function,
    filterValue:Array<any>,
    getListParams:Array<any>,
    onBeforeUpdate:Function,
    onFinally:Function,
) => {
    useEffect(() => {
        if (filterValue) {
            onBeforeUpdate()
            getList(...getListParams)
            .then((response:any) => {
                const {data} = response
                setList(data)
            })
            .finally(onFinally)
        }
    }, [filterValue])
}

export default useUpdateListByFilter
import styled from "styled-components"

const BestClassScoreStyle = styled.div`
    border: 1px solid #E1E6E7;
    border-radius: 8px;

    header {
        align-items: center;
        display:flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 16px 16px 0;
    }

    .class-name {
        color: #565656;
        font-size: 20px;
        font-weight: 600;
    }
    
    .class-percent {
        font-size: 42px;
    }

    .teacher {
        display: flex;
        flex-flow: row wrap;
        padding: 16px;

        .teacher-info {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            @media screen and (min-width: 1024px) {
                padding-left: 16px;
                width: 75%;
            }
        }

        .name {
            color: #565656;
            font-size: 20px;
        }
    }
`

export default BestClassScoreStyle
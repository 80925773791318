import * as React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import ExerciseOptionStyle from "YConnect/Fields/Exercises/Unscramble.field/ExerciseOption.style"
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

const UnscrambleItem = styled(ExerciseOptionStyle)`
    cursor: default;
`

const UnscrambleView = ({ Text }: any) => {
    return <Row className="pt-3">
            <Col md={12}>
                <UnscrambleItem className="d-flex">
                    <div className="enable-link">
                        <span />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(Text) }} />
                </UnscrambleItem>
            </Col>
        </Row>
}

export default UnscrambleView
import * as React from "react"
import { Fragment } from "react"
import { FormGroup } from "reactstrap";

import { ButtonStyle } from "YConnect/Components/Button"
import CourseDownloadModal from "YConnect/PagesComponents/Courses/CourseDownload.modal"
import PageTitle from "YConnect/Components/PageTitle";
import TeacherBookCard from "YConnect/Components/TeacherBook.card"

import * as searchIcon from "YConnect/Assets/png/search.png"

const TeacherContent = ({ data, history, onDownloadMenu, hasDownloadMenu, }: any) => {

    return (
        <Fragment>
            <PageTitle label={`Courses`} className={"col-xs-12 col-md-9 align-items-baseline d-flex flex-row flex-wrap justify-content-between"}>
                <div className="btn-fixed col-md-3 d-flex justify-content-end">
                    <ButtonStyle green size={'medium'} label="Course downloads" icon="downloadWhite" onClick={() => onDownloadMenu(true)} />
                </div>
            </PageTitle>
            <CourseDownloadModal hasDownloadMenu={hasDownloadMenu} onDownloadMenu={onDownloadMenu} />
            <div className="row">
                <section className="book-courses lateral-scroll-books teacher-card p-3">
                    {data.map((book: any, key: any) => <TeacherBookCard key={key} book={book} history={history} />)}
                </section>
            </div>
        </Fragment>
    )
}
export default TeacherContent
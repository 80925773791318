import * as React from "react"

import * as closeRed from "YConnect/Assets/svg/closeRed.svg";
import * as checkedGreen from "YConnect/Assets/svg/checkedGreen.svg";
import * as warning from "YConnect/Assets/svg/warning.svg";

import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg";

export const CONFIG_TABLE_REPORT:Array<any> = [
    {
        label    : "Type",
        formatter : ({categoria_execicio}: any) => <span>{categoria_execicio.nome}</span>
    },
    {
        label     : "Status",
        formatter : ({provaDTO}: any) =>
            <span>
                {provaDTO.prova_Final_Resposta.correto == 0 ? <img src={closeRed} alt="" className="pl-2" width={30} height={20}/>
                : provaDTO.prova_Final_Resposta.correto == 100 ? <img src={checkedGreen} alt="" className="pl-2" width={30} height={20}/>
                : <img src={warning} alt="" className="pl-2" width={30} height={20}/>
            }

            </span>
    },
    {
        label     : "Score",
        formatter : ({provaDTO}: any) =>
            <span>
                {provaDTO.prova_Final_Resposta.correto/100} of 1
            </span>
    },
    {
        label     : "Details",
        formatter : ({provaDTO}: any) =>
            <a href={`#/reports/view-report-final-test/${provaDTO.provaFinal.id_curso}/student/${provaDTO.provaFinal.id_aluno}/score/${provaDTO.provaFinal.id_prova_final}/exercise/${provaDTO.prova_Final_Resposta.id_exercicio}`}>
                Consult
                <img src={arrowRight} alt="" className="pl-2" />
            </a>
    }

]

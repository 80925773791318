import * as React       from "react"

import ProfilePicture   from "YConnect/Components/ProfilePicture"
import convertPhoto     from "YConnect/Utils/ConvertPhoto.util"
import TeacherInfoStyle from "YConnect/PagesComponents/PrePostReport/Components/ReportTeacherInfo/TeacherInfo.style"

const ReportTeacherInfo = ({ teacherInfo }: any) => 
    teacherInfo
    ? <TeacherInfoStyle>
            <ProfilePicture
                border    = "8px solid #bdbdbd"
                height    = {120}
                picture   = {convertPhoto(teacherInfo && teacherInfo.foto)}
                width     = {120}
                className = "teacher-picture"
            />
            <div className="teacher-data">
                <span>Teacher</span>
                <h4>{teacherInfo && teacherInfo.nome_completo || teacherInfo.nomeCompleto}</h4>
            </div>
        </TeacherInfoStyle>
    : <></>

export default ReportTeacherInfo
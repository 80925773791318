const CONFIG_TABLE_QUESTION:Array<ColumnConfigType> = [
    {
        keystone : "QuestionOrder",
        label    : "Order"
    },
    {
        keystone : "TemplateId",
        label    : "Template",
        selectDataName : "TemplateId"
    },
    {
        keystone : "ExerciseId",
        label    : "Exercise",
        selectDataName : "ExerciseId"
    },
    {
        keystone : "Enunciated",
        label    : "Title",
    },
    {
        keystone : "Content",
        label    : "Content"
    }
]

export default CONFIG_TABLE_QUESTION

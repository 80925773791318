import * as React from "react";
import { Fragment } from "react";
import { Row, Col } from "reactstrap";

import styled from "styled-components";

import getStaticUrlAssets from "YConnect/Utils/GetStaticUrlAssets.util";

const ColStyled = styled(Col)`
    input.typein-input{
        border: 0;
        border-bottom: 2px solid #00b5d3;
        color: #8fa7ac;
        text-align: center;
        font-weight: bold;
    }
`;

const RowStyled = styled(Row)`
    border-bottom: 1px solid #f5f5f5;
`;

const ImageStyled = styled.img`
    width: 100%;
    height: auto;
`;

const Line = ({ Text, imageSrc }: any) => {
    const splited = Text.split("___");

    const result = splited.join("<input type=\"text\" class=\"typein-input\" style=\"width: 120px;\"></input>")
/*    const itemsForRender = splited
        .reduce((acc: Array<any>, value: string, index: number) => {
            if (index < splited.length - 1) {
                return [
                    ...acc,
                    value,
                    <InputEditableStyled
                        key={index}
                        type="text"
                        style={{ width: "120px" }}
                    />,
                ];
            } else {
                return [...acc, value];
            }
        }, [])
        .reduce((acc: Array<any>, value: string, index: number) => {
            if (typeof value === "string" && value.indexOf("<br>") > -1) {
                const _splited = value.split("<br>");

                const newValue = _splited.reduce(
                    (_acc: Array<any>, _value: string, _index: number) => {
                        if (_index < _splited.length - 1) {
                            return [..._acc, _value, <br />];
                        } else {
                            return [..._acc, _value];
                        }
                    },
                    []
                );

                return [...acc, newValue];
            } else {
                return [...acc, value];
            }
        }, []);

    const hasP = () =>
        itemsForRender[0].indexOf("<p>") > -1 &&
        itemsForRender[itemsForRender.length - 1].indexOf("</p>") > -1;

    const itemsForRenderFinal = hasP() ? (
        <p>
            {itemsForRender.map((item: any, index: number) => {
                if (index === 0) return item.replace("<p>", "");
                if (index === itemsForRender.length - 1)
                    return item.replace("</p>", "");
                return item;
            })}
        </p>
    ) : (
        itemsForRender
    );
*/
    return (
        <RowStyled className="p-3 my-4">
            {imageSrc && (
                <Col md={4}>
                    <ImageStyled src={getStaticUrlAssets(imageSrc)} />
                </Col>
            )}
            <ColStyled md={imageSrc ? 8 : 12}>
                <h2 dangerouslySetInnerHTML={{ __html: result}}></h2>
            </ColStyled>
            {/*<Col md={imageSrc ? 8 : 12}>{itemsForRenderFinal}</Col>*/}
        </RowStyled>
    );
};

type TypeInViewProps = {
    Medias: Array<ExerciseMediaType>;
    DialogueMessages: Array<DialogueMessageType>;
};

const TypeInView = ({ DialogueMessages, Medias }: TypeInViewProps) => {
    return (
        <Fragment>
            {DialogueMessages &&
                DialogueMessages.map(({ Text, OrderMessage }, key: number) => (
                    <Line
                        key={key}
                        Text={Text}
                        imageSrc={
                            Medias.find(
                                ({ Order }) => Order === OrderMessage
                            ) &&
                            Medias.find(({ Order }) => Order === OrderMessage)
                                .Source
                        }
                    />
                ))}
        </Fragment>
    );
};

export default TypeInView;

const parseFileName = (fileName: string) => {  // Format the text to include bold
    if (fileName.includes("midia:")) {
        const fileNameCopy = (' ' + fileName).slice(1);
        const fileNameWithoutPrefix = fileNameCopy.split('/').splice(1, 1).join('/');
        const extension = fileName.split('.').pop();

        // Here we are splitting the hash and removing it, while also appending the extension at the end
        return fileNameWithoutPrefix.split('-').slice(0, -1).join('-') + '.' + extension;
    }

    return fileName;
}

export default parseFileName

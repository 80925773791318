export default [
    {
        keystone: "Description",
        className: "col-md-10",
        type: "input",
        label: "Description"
    },
    {
        keystone: "Weight",
        className: "col-md-2",
        type: "input",
        label: "Weight"
    }
    
]
import * as React from "react"
import {
    useEffect, useState, useContext
} from "react"
import { Container, Row, Col, Card } from "reactstrap"
import styled from "styled-components"

import { BreadcrumbCMSSections } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle from "YConnect/Components/PageTitle"
import UserContext from "YConnect/User.context"

import Sections from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Sections.panel"

const ReportCardStyle = styled.div`
    align-items: center;
    border-bottom: 2px solid ${props => props.theme.main.clickable};
    color: ${props => props.theme.main.textColor};
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }

    .report-type {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin-left: 20px;

        h4, p {
            margin: 0;
        }
    }

`

const OpenningCMS = () => {

    const [lastSearches, setLastSearches] = useState(JSON.parse(localStorage.getItem("lastSearches")))
    const [lastSearchesRB, setLastSearchesRB] = useState(JSON.parse(localStorage.getItem("lastSearchesRB")))

    const { idUsuario }: any = useContext(UserContext)

    useEffect(() => {
        if (!lastSearches) {
            const newLastSearches: any = {
                [idUsuario]: []
            }
            setLastSearches(newLastSearches)
            localStorage.setItem("lastSearches", JSON.stringify(newLastSearches))
        }
        if (!lastSearchesRB) {
            const newLastSearches: any = {
                [idUsuario]: []
            }
            setLastSearchesRB(newLastSearches)
            localStorage.setItem("lastSearchesRB", JSON.stringify(newLastSearches))
        }
    }, [])

    return <Container>
        <BreadcrumbCMSSections/>
        <PageTitle label="CMS Sections" />
        <Sections/>
    </Container>

}


export default OpenningCMS

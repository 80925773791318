import * as React from "react"
import {useState, Fragment, useEffect} from "react"
import { connect }            from "react-redux"
import { bindActionCreators } from "redux"

import AdvancedEditor       from "YConnect/PagesComponents/CMS/Editors/Advanced.editor"

import ChooseMultipleTemplateEditor             from "YConnect/PagesComponents/CMS/TemplateEditors/ChooseMultiple.templateEditor"
import ChooseMultipleAudioControlTemplateEditor from "YConnect/PagesComponents/CMS/TemplateEditors/ChooseMultipleAudioControl.templateEditor"
import ChooseMultipleLongTextTemplateEditor     from "YConnect/PagesComponents/CMS/TemplateEditors/ChooseMultipleLongText.templateEditor"
import ChooseMultipleVideoControlTemplateEditor from "YConnect/PagesComponents/CMS/TemplateEditors/ChooseMultipleVideoControl.templateEditor"
import ChooseMultipleWithImageTemplateEditor    from "YConnect/PagesComponents/CMS/TemplateEditors/ChooseMultipleWithImage.templateEditor"
import TypeInTemplateEditor                     from "YConnect/PagesComponents/CMS/TemplateEditors/TypeIn.templateEditor"
import TypeInWithImageTemplateEditor            from "YConnect/PagesComponents/CMS/TemplateEditors/TypeInWithImage.templateEditor"
import TypeInLongTextTemplateEditor             from "YConnect/PagesComponents/CMS/TemplateEditors/TypeInLongText.templateEditor"
import TypeInAudioControlTemplateEditor         from "YConnect/PagesComponents/CMS/TemplateEditors/TypeInAudioControl.templateEditor"
import DialogueTemplateEditor                   from "YConnect/PagesComponents/CMS/TemplateEditors/Dialogue.templateEditor"
import DragAndDropTemplateEditor                from "YConnect/PagesComponents/CMS/TemplateEditors/DragAndDrop.templateEditor"
import DragAndDropVideoTemplateEditor           from "YConnect/PagesComponents/CMS/TemplateEditors/DragAndDropVideo.templateEditor"
import DragAndDropLongTextTemplateEditor        from "YConnect/PagesComponents/CMS/TemplateEditors/DragAndDropLongText.templateEditor"
import DragAndDropAudioControlTemplateEditor    from "YConnect/PagesComponents/CMS/TemplateEditors/DragAndDropAudioControl.templateEditor"
import DragAndDropWithImageTemplateEditor       from "YConnect/PagesComponents/CMS/TemplateEditors/DragAndDropWithImage.templateEditor"
import DragAndDropTextAndImageTemplateEditor    from "YConnect/PagesComponents/CMS/TemplateEditors/DragAndDropTextAndImage.templateEditor"
import OpenQuestionTemplateEditor               from "YConnect/PagesComponents/CMS/TemplateEditors/OpenQuestion.templateEditor"
import UnscrambleTemplateEditor                 from "YConnect/PagesComponents/CMS/TemplateEditors/Unscramble.templateEditor"
import UnscrambleWithImageTemplateEditor        from "YConnect/PagesComponents/CMS/TemplateEditors/UnscrambleWithImage.templateEditor"
import UnscramblAudioControlTemplateEditor      from "YConnect/PagesComponents/CMS/TemplateEditors/UnscrambleAudioControl.templateEditor"
import MatchTextTemplateEditor                  from "YConnect/PagesComponents/CMS/TemplateEditors/MatchText.templateEditor"
import ClickAudioWithImageTemplateEditor        from "YConnect/PagesComponents/CMS/TemplateEditors/ClickAudioWithImage.templateEditor"

import ExerciseTemplate from "YConnect/Enums/ExerciseTemplate"
import ExerciseDescription from "YConnect/Enums/ExerciseDescription"

type QuestionEditorProps = {
    listTemplates : Array<TemplateType>
    question      : QuestionType
    onChange      : any
    QueryParams   : any
}

const QuestionEditor = ({
    listTemplates,
    question,
    onChange,
    QueryParams
}:QuestionEditorProps)=>{

    const [values, setValues] = useState(question)

    useEffect(()=>{
        if(question){
            setValues(question)
        }
    }, [question])

    const getTemplate = ():TemplateType|any => listTemplates && listTemplates.find(({Id}:TemplateType)=> question.TemplateId == Id) || {}

    const handleChangeValues = (newValues:any) => {
        setValues(newValues)
        onChange(newValues)
    }

    return <Fragment>
                {
                     (!!QueryParams.advancedEditor
                     || (
                            getTemplate().Description !== ExerciseDescription.TYPE_IN
                            && getTemplate().Description !== ExerciseDescription.TYPE_IN_IMG
                            && getTemplate().Description !== ExerciseDescription.TYPE_IN_LONG_TEXT
                            && getTemplate().Description !== ExerciseDescription.TYPE_IN_AUDIO
                            && getTemplate().Description !== ExerciseDescription.CHOOSE_MULTIPLE
                            && getTemplate().Description !== ExerciseDescription.CHOOSE_MULTIPLE_IMG
                            && getTemplate().Description !== ExerciseDescription.CHOOSE_MULTIPLE_AUDIO
                            && getTemplate().Description !== ExerciseDescription.CHOOSE_MULTIPLE_LONG_TEXT
                            && getTemplate().Description !== ExerciseDescription.DIALOGO
                            && getTemplate().Description !== ExerciseDescription.DRAG_DROP
                            && getTemplate().Description !== ExerciseDescription.DRAG_DROP_VIDEO
                            && getTemplate().Description !== ExerciseDescription.DRAG_DROP_LONG_TEXT
                            && getTemplate().Description !== ExerciseDescription.DRAG_DROP_AUDIO
                            && getTemplate().Description !== ExerciseDescription.DRAG_DROP_IMG
                            && getTemplate().Description !== ExerciseDescription.DRAG_DROP_TEXT_IMG
                            && getTemplate().Name !== ExerciseTemplate.OPEN_QUESTION
                            && getTemplate().Description !== ExerciseDescription.UNSCRAMBLE
                            && getTemplate().Description !== ExerciseDescription.UNSCRAMBLE_IMG
                            && getTemplate().Description !== ExerciseDescription.UNSCRAMBLE_AUDIO
                            && getTemplate().Description !== ExerciseDescription.CHOOSE_MULTIPLE_VIDEO
                            && getTemplate().Description !== ExerciseDescription.TYPE_IN_ORDENADO
                            && getTemplate().Description !== ExerciseDescription.MATCH_TEXT
                            && getTemplate().Description !== ExerciseDescription.CLICK_LISTEN_IMG
                     )
                    )&& <AdvancedEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.CHOOSE_MULTIPLE
                    && <ChooseMultipleTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.CHOOSE_MULTIPLE_IMG
                    && <ChooseMultipleWithImageTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.CHOOSE_MULTIPLE_AUDIO
                    && <ChooseMultipleAudioControlTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.CHOOSE_MULTIPLE_LONG_TEXT
                    && <ChooseMultipleLongTextTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.CHOOSE_MULTIPLE_VIDEO
                    && <ChooseMultipleVideoControlTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Name === ExerciseTemplate.OPEN_QUESTION
                    && <OpenQuestionTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.TYPE_IN_ORDENADO
                    && <TypeInTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.TYPE_IN
                    && <TypeInTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.TYPE_IN_IMG
                    && <TypeInWithImageTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.TYPE_IN_LONG_TEXT
                    && <TypeInLongTextTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.TYPE_IN_AUDIO
                    && <TypeInAudioControlTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.DIALOGO
                    && <DialogueTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.DRAG_DROP
                    && <DragAndDropTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.DRAG_DROP_VIDEO
                    && <DragAndDropVideoTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.DRAG_DROP_LONG_TEXT
                    && <DragAndDropLongTextTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.DRAG_DROP_AUDIO
                    && <DragAndDropAudioControlTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.DRAG_DROP_IMG
                    && <DragAndDropWithImageTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.DRAG_DROP_TEXT_IMG
                    && <DragAndDropTextAndImageTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.UNSCRAMBLE
                    && <UnscrambleTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.UNSCRAMBLE_IMG
                    && <UnscrambleWithImageTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.UNSCRAMBLE_AUDIO
                    && <UnscramblAudioControlTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.MATCH_TEXT
                    && <MatchTextTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }
                {
                    !QueryParams.advancedEditor
                    && getTemplate().Description === ExerciseDescription.CLICK_LISTEN_IMG
                    && <ClickAudioWithImageTemplateEditor
                            question={question}
                            onChangeValues={handleChangeValues}/>
                }

        </Fragment>
}


const mapDispatchToProps = (dispatch:any) => bindActionCreators({

}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionEditor)

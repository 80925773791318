import * as React from "react"
import {useState} from "react"
import { Modal }  from "react-bootstrap"
import Toastify from "toastify-js";

import {ButtonStyle} from "YConnect/Components/Button"

const RemoveModal = ({
    show,
    Id,
    onHide,
    onRemoved,
    DeleteById,
    Name
}:any) =>{

    const [hasShowLoader, setShowLoader] = useState<boolean>(false)

    const deleteRecord = () => {
        setShowLoader(true)
        DeleteById(Id)
        .then((response:any) => {
            onRemoved()
        })
        .catch((e:any) => {
            Toastify({
                text: e.response.data.message,
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
            }).showToast();
        })
        .finally(() => {
            setShowLoader(false)
            onHide(false);
        })
    }

    const handleRemove = () => deleteRecord()

    return <Modal show={show} onHide={onHide} size="lg">
                {
                    !hasShowLoader
                    && <>
                        <Modal.Header
                            closeButton
                            closeLabel={"Close"}
                            className="first-access"
                            style={{zIndex: 10}}>
                                <Modal.Title>Are you sure want to remove <b>record {Name}</b> ?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <ButtonStyle
                                    onClick={handleRemove}
                                    label="Yes"
                                    icon="checkedGreen2" />
                            <ButtonStyle
                                    onClick={onHide}
                                    label="No"
                                    icon="close" />
                        </Modal.Footer>
                    </>
                }
                {
                    hasShowLoader && <div className="loader"></div>
                }
            </Modal>
}


export default RemoveModal

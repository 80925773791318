import * as React from "react";
import { useEffect, useState, useRef, Fragment,useContext} from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { LinkStyle } from "YConnect/Components/Button"
import AlphaBlackStyle     from "YConnect/Styles/AlphaBlack.style"
import SelectFieldNewStyle from "YConnect/Components/SelectFieldNew.style";
import { Col, Row, Table } from "react-bootstrap";
import API from "YConnect/API";

const ArchiveHistoryStyle = styled.div` // Define the styles for the ArchiveHistoryModal
  padding: 16px;
  overflow: auto;
  z-index: 10000;
`;

type ArchiveHistoryModalProps = {
  isShow: boolean;
  onClose: Function;
  courses: any;
  idAluno: any;
};

const CustomTable = styled(Table)`
  font-size: 14px;
  color: #848484;
  width: 100%;

  th {
    font-weight: bold;
    padding: 8px;
  }

  th, td {
    padding: 8px;
    border-bottom: 2px solid #E8E8E8;
  }

  tbody tr:hover {
    background-color: #f2f2f2;
  }

  td {
    font-size: 13px;
    line-height: 3.5;
  }
`;

const ArchiveHistoryModal = ({ isShow, onClose, courses, idAluno }: ArchiveHistoryModalProps) => {
  const willClose = () => {
    onClose();
  };

  const [courseParam, setCourseParam] = useState("")
  const [historyContent, sethistoryContent] = useState([])

  const findHistory = (idCursoMudanca: any) => {
    API.Usuario.getArchiveHistory({
        idCursoMudanca: idCursoMudanca,
        idAluno
    })
    .then((res: any) => {
        sethistoryContent(res.data)
    })
    .catch((err: any) => {
    })
    .finally(() => {
    })
  }

  useEffect(() => {
    findHistory(0);
  }, []);

  const handleChangeFilter = (keystone:string, value:any) => {
    const sets: any = {
        course     : setCourseParam,
    }
    if(sets[keystone]) sets[keystone](value)

    findHistory(value)
  }

  const useOutsideClick = (ref: any) => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const coursesForSelect = courses
    ? courses.map((course: any) => ({
        id: course.id,
        value: course.titulo_curso
      }))
    : [];

  return (
    <Fragment>
      {isShow && <AlphaBlackStyle />}
      <CSSTransition
        classNames="dialog accessibility-menu"
        timeout={300}
        in={isShow}
        unmountOnExit
      >
        <ArchiveHistoryStyle
          style={{ zIndex: 10000 }}
          className="YC-lateral lateral-header d-flex d-lg-flex flex-column"
          ref={wrapperRef}
        >
        <div className="d-flex justify-content-end">
          <LinkStyle label="Close x" onClick={willClose} className="close-modal text-right header-btn" />
        </div>
          <h4
                className="align-items-center d-flex flex-row"
                style={{
                fontSize: "32px",
                color: "#525252",
                padding: "8px",
                marginBottom: "16px"
                }}
            >
            Archive History
          </h4>

          <hr style={{ border: "2px solid #E8E8E8", marginBottom: "16px" }} />
          <Row className="mt-5">
            <Col>
                <div className="d-flex align-items-center">
                    <span className="mr-5">Filter by:</span>
                    <SelectFieldNewStyle
                        className="col-9"
                        keystone="course"
                        label="Course Name"
                        value={courseParam}
                        onChange={handleChangeFilter}
                        values={coursesForSelect}/>
                </div>
             </Col>
            </Row>

          <Row className="mt-4">
            <Col>
            {courses && courses.length > 0 && (
              <CustomTable>
                <thead>
                  <tr>
                    <th>Course</th>
                    <th>Filing date</th>
                    <th>Accountable</th>
                    <th>Justification</th>
                  </tr>
                </thead>
                <tbody>
                    {historyContent.map((historyItem, index) => {
                        // Formatar a data no novo formato
                        const formattedDate = new Date(historyItem.data_modificacao).toLocaleString('pt-BR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                        });

                        return (
                        <tr key={index}>
                            <td>{courses.find((course: any) => course.id === historyItem.id_curso_mudanca)?.titulo_curso || ""}</td>
                            <td>{formattedDate}</td>
                            <td>{historyItem.accountable}</td>
                            <td>{historyItem.motivo}</td>
                        </tr>
                        );
                    })}
                </tbody>

              </CustomTable>
            )}
            </Col>
          </Row>
        </ArchiveHistoryStyle>
      </CSSTransition>
    </Fragment>
  );
};

export default ArchiveHistoryModal;

import styled from "styled-components"

const SoundDragStyle = styled.div`
    position: absolute;
    cursor: pointer;
    width: 10px;
    height: 10px;
    bottom: -3px;
    background: ${props => props.theme.main.greentextColor};
    border-radius: 50px;
    box-shadow: #fff 0 0 5px;
`

export default SoundDragStyle
import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

import { ButtonStyle } from "YConnect/Components/Button"

import AudioMainView from "YConnect/PagesComponents/CMS/Views/AudioMain.view"
import AudioFilesModal from "YConnect/PagesComponents/CMS/Modals/AudioFiles.modal"
import AnswersEditor from "YConnect/PagesComponents/CMS/Editors/DragAndDrop.editor/Answers.editor"
import FormContainer from "YConnect/Containers/Form.container"

import MessagesEditor from "YConnect/PagesComponents/CMS/Editors/Components/Messages.editor"
import AUDIO_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Audio.template.json"

type DragAndDropAudioControlTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const DragAndDropAudioControlTemplateEditor = ({
    question,
    onChangeValues
}:DragAndDropAudioControlTemplateEditorProps) => {

    const [isShowAudioFilesModal, setShowAudioFilesModal] = useState(false)
    const [values, setValues] = useState<QuestionType>(question)

    const handleChangeValues = (values:any) => addValues(values)
  
    const addValues = (newValues:any) => setValues({...values, ...newValues})

    useEffect(() => {
        if(values){
            onChangeValues(values)
        }
    }, [values])
    
    const addMainExerciseMedia = ({Source, MediaType}:any) => {
        const newValues = {
            ...values,
            ExerciseMedias: [{Order:0, Source, MediaType}, ...values.ExerciseMedias.filter(({Order}) => Order !== 0)]
        }
        setValues(newValues)
        onChangeValues(newValues)
    }

    const getMainExerciseMedia = () =>
        values && values.ExerciseMedias.find(({Order}) => Order === 0)

    const handleOpenModalAudioFiles = () => setShowAudioFilesModal(true)
    const handleCloseModalAudioFiles = () => setShowAudioFilesModal(false)
    const handleAddAudio = ({Source, MediaType}:any) => {
        addMainExerciseMedia({Source, MediaType})
        setShowAudioFilesModal(false)
    }
    const handleRemoveExerciseMedia = () => {
        const newValues = {
            ...values,
            ExerciseMedias: values.ExerciseMedias
            .filter(({Order}) => Order !== 0 )
        }
        setValues(newValues)
        onChangeValues(newValues)
    }

    return  <>
            <AudioMainView medias={values && values.ExerciseMedias}/>
            {
                !(getMainExerciseMedia()
                && getMainExerciseMedia().MediaType.toLowerCase() === "audio")
                && <Row className="py-3">
                        <Col md="auto">
                            <ButtonStyle
                                green
                                disabled  = {getMainExerciseMedia() && getMainExerciseMedia().MediaType === "image"}
                                size      = "190"
                                onClick   = {handleOpenModalAudioFiles}
                                icon      = "audioWhite"
                                label     = "Add Audio"/>
                        </Col>
                    </Row>
            }
            {
                getMainExerciseMedia()
                && <Row className="py-3 justify-content-center">
                        <Col md="auto">
                            <ButtonStyle
                                className = "mb-2"
                                size      = "130"
                                onClick   = {handleOpenModalAudioFiles}
                                icon      = "reset"
                                label     = "Change"/>
                        </Col>
                        <Col md="auto">
                            <ButtonStyle
                                size      = "130"
                                onClick   = {handleRemoveExerciseMedia}
                                icon      = "remove"
                                label     = "Remove"/>
                        </Col>
                    </Row>
            }
            <Row>
                <Col md={12}>
                    <FormContainer
                        values={values}
                        onChange={({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                        template={[...AUDIO_TEMPLATE.map((field) => ({...field}))]}/>
                </Col>
                <AnswersEditor 
                    question={values}
                    onChange={handleChangeValues}/>
                <MessagesEditor
                    question={values}
                    onChange={handleChangeValues}/>
            </Row>
            {
                isShowAudioFilesModal
                && <AudioFilesModal 
                    onSelect={handleAddAudio}
                    onClose={handleCloseModalAudioFiles}/>
            }
            </>
}

export default DragAndDropAudioControlTemplateEditor

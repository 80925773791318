import * as React from "react";
import { useState } from "react";
import styled from "styled-components";

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util";

import HeaderExerciseStyle from "YConnect/Styles/HeaderExercise.style";

const H2Style = styled.h2`
    i{
        font-size: 22px;
    }

    u{
        font-size: 22px;
    }

    b{
        font-size: 19px;
    }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  textarea {
    width: 100%;
    padding: 1em;
    cursor: not-allowed;
    border: 1px solid #79b100;
  }

  .open-question__char-counter {
    width: 100%;
    text-align: right;
  }
`

const ScoreWrittenTest = ({
    report
}:any) => {

return (
    <div className="score">
        <br />
        {
            report && report.question && report.answer && <>
                <HeaderExerciseStyle className="exercise-header d-flex flex-column justify-content-center w-100 pb-3 pl-3 pr-3">
                    <h1 className="text-center h3 mb-3 font-weight-bolder">
                        Question 1 of 1
                    </h1>
                    <H2Style
                        className="text-center mt-2"
                        dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(report.question.enunciado) }}
                    ></H2Style>
                    <H2Style
                        className="text-center mt-2"
                        dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(report.question.conteudo_exercicio) }}
                    ></H2Style>
                </HeaderExerciseStyle>
                <Wrapper className="open-question__answer">
                    <textarea 
                        maxLength={1996} 
                        rows={10} 
                        disabled={true} 
                        value={report.answer.resposta}>
                    </textarea>
                </Wrapper>
            </>
        }
    </div>
    )
}

export default ScoreWrittenTest
import * as React from "react"
import {useState, useEffect} from "react"
import styled from "styled-components"

import ProfilePicture from "YConnect/Components/ProfilePicture"
import convertPhoto   from "YConnect/Utils/ConvertPhoto.util"

import API              from "YConnect/API"
import getRequestConfig from "YConnect/Utils/GetRequestConfig.util"


const LoadingStyle = styled.div`
    .loading {
        left: calc(50% - 20px);
        position: relative;
        width: 40px;
        height: 40px;
        border: 4px transparent solid;
        border-top: 4px ${props => props.theme.main.lightPurple} solid;
        border-radius: 30px;
        animation: preload .7s infinite;
        animation-timing-function: linear;
    }

        
    @-moz-keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`

const ProfilePictureById = ({
    border,
    height,
    idUsuario,
    width
}:any) =>{

    const [foto, setFoto] = useState()

    useEffect(()=>{
        setFoto(undefined)
        if(idUsuario){
            API
            .Aluno
            .ProfilePicture({alunoFoto: idUsuario}, getRequestConfig())
            .then((response: any) => setFoto(response.data || ""))
        }
    }, [idUsuario])

    return foto || foto === ""
            ?<ProfilePicture
                border  = {border}
                height  = {height}
                picture = {convertPhoto(foto)}
                width   = {width}/>
            :<figure className="mb-0">
                <LoadingStyle>
                    <div className="loading"/>
                </LoadingStyle>
            </figure>
}


export default ProfilePictureById



import * as React from "react"

import * as clock    from "YConnect/Assets/svg/clock.svg"
import * as verified from "YConnect/Assets/svg/verified.svg"
import * as cancel   from "YConnect/Assets/svg/cancel.svg"
import * as question from "YConnect/Assets/svg/question.svg"

import LineProgressStyle from "YConnect/Styles/LineProgress.style"

import ResultStyle            from "YConnect/PagesComponents/PrePostReport/Components/CardAverageResultScore/Result.style"
import ResultCardContentStyle from "YConnect/PagesComponents/PrePostReport/Components/CardAverageResultScore/ResultCardContent.style"
import ProgressPercentStyle   from "YConnect/PagesComponents/PrePostReport/Components/CardAverageResultScore/ProgressPercent.style"
import ReportSummaryStyle     from "YConnect/PagesComponents/PrePostReport/Components/CardAverageResultScore/ReportSummary.style"

import styled from "styled-components"

const CardLoadingStyle = styled(ResultCardContentStyle)`
    .loading {
        left: calc(50% - 20px);
        position: relative;
        width: 40px;
        height: 40px;
        border: 4px transparent solid;
        border-top: 4px ${props => props.theme.main.lightPurple} solid;
        border-radius: 30px;
        animation: preload .7s infinite;
        animation-timing-function: linear;
    }

        
    @-moz-keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`



const CardLoading = () => 
<CardLoadingStyle>
    <div className="loading"></div>
</CardLoadingStyle>

const CardAverageResultScore = ({
    isPost,
    bestQuestion, 
    worstQuestion,
    porcentagemAvanco,
    media, 
    qtsTestesConsolidados, 
    tempo, 
    idk
}:any) => 
    <ResultStyle>
        <h2>{isPost?"Post":"Pre"} test: Average score</h2>
        {
            porcentagemAvanco        === undefined
            && media                 === undefined
            && qtsTestesConsolidados === undefined
            && tempo                 === undefined
            && idk                   === undefined
            && bestQuestion          === undefined
            && worstQuestion         === undefined
            ? <CardLoading/>
            : <ResultCardContentStyle>
                    {
                        porcentagemAvanco        !== undefined
                        && media                 !== undefined
                        && qtsTestesConsolidados !== undefined
                        && tempo                 !== undefined
                        && idk                   !== undefined
                        && bestQuestion          !== undefined
                        && worstQuestion         !== undefined
                        ? <>
                                <ProgressPercentStyle>
                                    <div>
                                        <small style={{color: porcentagemAvanco >= 0 ? "#00A281" : "#FF1A4A"}}>{porcentagemAvanco}% better than last semester</small>
                                        <h3 className="percent-title" style={{color: media > 0.7 ? "#00A281" : "#FF1A4A"}}>{(media * 100).toFixed(2)}%</h3>
                                    </div>
                                    <LineProgressStyle>
                                        <span className="progress-percentage" style={{ marginLeft: `calc(${media}* 90%)` }}>{(media * 100).toFixed(2)}%</span>
                                        <div className="line" />
                                        <div className="line-progress" style={{ width: `calc(${media}* 90%)` }}/>
                                        <div className="pointer-progress" style={{ marginLeft: `calc(${media}* 90%)` }}/>
                                        <div className="d-flex justify-content-between">
                                            <span className="line-start">0</span>
                                            <span className="line-start" style={{ marginLeft: `calc(0.70 * 90%)` }}>70%</span>
                                            <span className="line-start">100%</span>
                                        </div>
                                    </LineProgressStyle>
                                    <small>{qtsTestesConsolidados} students have done the {isPost?"post":"pre"} test</small>
                                </ProgressPercentStyle>
            
                                <ReportSummaryStyle>
                                    <div className="report">
                                        <img src={clock} alt="" />
                                        <div className="report-info">
                                            <span className="title">Average duration:</span>
                                            <span className="value">{Math.ceil(tempo / 60)} minutes</span>
                                        </div>
                                    </div>
                                    <div className="report">
                                        <img src={verified} alt="" />
                                        <div className="report-info">
                                            <span className="title">Best rated questions:</span>
                                            <span className="value">
                                                {bestQuestion && bestQuestion.map((best: any) => best.fl_is_pre === (isPost?false:true) && best.ordem).filter((value:any) => value).join(" ")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="report">
                                        <img src={cancel} alt="" />
                                        <div className="report-info">
                                            <span className="title">Worst rated questions:</span>
                                            <span className="value">
                                                {worstQuestion && worstQuestion.map((worst: any) => worst.fl_is_pre === (isPost?false:true) && worst.ordem).filter((value:any) => value).join(" ")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="report">
                                        <img src={question} alt="" />
                                        <div className="report-info">
                                            <span className="title">"I don"t know" answers:</span>
                                            <span className="value">{idk}%</span>
                                        </div>
                                    </div>
                                </ReportSummaryStyle>
                            </>
                        : <p className="text-center w-100">No one student have the {isPost?"post":"pre"}-test done</p>  
                    }
            </ResultCardContentStyle>
        }
    </ResultStyle>
    

export default CardAverageResultScore
import * as React from "react"
import {useEffect, useState, useCallback, useRef} from "react"
import {Row, Col} from "reactstrap"
import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"

import SelectField from "YConnect/Fields/Forms/Select.field"

type ExerciseCardHeaderProps = {
    Order                     : number
    newExerciseType           ?: number
    newExerciseTitle          ?: string
    newLearningObjective      ?: number
    LearningObjectiveId       ?: number
    listExerciseType          ?: Array<ExerciseTypeType>
    listLearningObjective     ?: Array<LearningObjectiveType>
    ExerciseType              ?: ExerciseTypeType
    ExerciseTitle             ?: string
    onCancel                  ?: any
    onUpdate                  ?: any
    onEditQuestion            ?: any
    onChangeNewExerciseType   ?: any
    onChangeNewExerciseTitle  ?: any
    onChangeLearningObjective ?: any
    onRemove                  ?: any
    Removable                 ?: any
    isLearningTrack           ?: boolean
    isNew                     ?: boolean
    onChangeEditOpen          ?: any
    editOpen                  ?: number
    index                     ?: number
    isCreating                ?: boolean
}

const SelectFieldStyle = styled(SelectField)`
    width:200px;
    .form-group{
        margin:0px!important;
    }
`
const StyledInput = styled.input`
    height: 4rem;
    padding: 1rem;
    width:90%;

    ::placeholder {
        color: #bbb;
        opacity: 1;
      }
`
const ButtonRotatedIcon = styled.div`
    transform: rotate(180deg);
`

const StyledCeterCol = styled(Col)`
    place-self: center;
`

const ExerciseCardHeader = ({
    ExerciseType,
    ExerciseTitle,
    listExerciseType,
    listLearningObjective,
    newExerciseType,
    newExerciseTitle,
    newLearningObjective,
    LearningObjectiveId,
    Order,
    onEditQuestion,
    onCancel,
    onUpdate,
    onChangeNewExerciseType,
    onChangeNewExerciseTitle,
    onChangeLearningObjective,
    onRemove,
    Removable,
    isLearningTrack,
    isNew,
    onChangeEditOpen,
    editOpen,
    index,
    isCreating
}:ExerciseCardHeaderProps) => {

    const [colapsed, setColapsed] = useState(true)
    const [isOpen] = useState(editOpen === index)

    const inputRef = useRef(null)

    const onColapse =() =>{
        setColapsed(!colapsed)

        if(colapsed){
            onChangeNewExerciseType(ExerciseType.Id)
            onChangeNewExerciseTitle(ExerciseTitle)
        }
    }

    useEffect(() => {
        if(inputRef.current) {
            inputRef.current.focus()
        }
    }, [editOpen])

    const handleEdit = useCallback(() => {
        if(isOpen) {
            onChangeEditOpen(-1)
        } else {
            onChangeEditOpen(index)
        }
    }, [])

    useEffect(()=>{
        if(ExerciseType && isLearningTrack && onChangeNewExerciseType){
            onChangeNewExerciseType(ExerciseType.Id)
        }
    }, [ExerciseType])

    const colapseButton = <ButtonStyle
                            size      = "forTable"
                            onClick   = {onColapse}
                            icon      = "arrowDownIcon"/>

    const editButton = <ButtonStyle
                            size      = "forTable"
                            onClick   = {handleEdit}
                            icon      = "arrowDownIcon"/>

    return <>
            <Row className="justify-content-between">
                <div className="enable-link p-1">
                    <span />
                </div>
                {
                    isNew ?
                    <Col md={ExerciseType? 3 : "auto"} className="align-self-center ml-3 p-1">
                        <h2 className="font-weight-bold m-0">Exercise {Order} / {
                        ExerciseType && <span className="align-bottom">{ExerciseType.Description}</span>
                        }</h2>
                    </Col>
                    : <Col md={ExerciseType? 3 : "auto"} className="align-self-center ml-4 p-1">
                        <h2 className="font-weight-bold m-0">Exercise {Order} / {
                        ExerciseType && <span className="align-bottom">{ExerciseType.Description}</span>
                        }</h2>
                      </Col>
                }

                {
                    !ExerciseType
                    && <Col md={3} className="row">
                            <SelectFieldStyle
                                keystone="ExerciseType"
                                label="Type"
                                value={newExerciseType}
                                values={listExerciseType && listExerciseType.map(({Id, Description}) => ({id:Id, value:Description}))}
                                onChange={(_:string, value:any) => onChangeNewExerciseType && onChangeNewExerciseType(value)}/>
                    </Col>
                }
                 {
                    (!ExerciseType || isLearningTrack) && isNew
                    && <Col md={6} className="row">
                            <StyledInput
                                label="Title"
                                placeholder="Exercise title (optional)"
                                onChange = {(e) => {onChangeNewExerciseTitle && onChangeNewExerciseTitle(e.target.value)}}
                                />
                    </Col>
                }
                {<StyledCeterCol>
                        <div>{ExerciseTitle}</div>
                    </StyledCeterCol>
                }
                 {
                    Removable
                    && <Col md={"auto"}>
                        <Row className="px-0" style={{float: 'right'}}>
                            <ButtonStyle
                            size      = "130"
                            label     = "Remove"
                            className = "mr-2"
                            icon      = "remove"
                            onClick   = {onRemove}/>
                        </Row>
                    </Col>
                }
                {
                    onCancel
                    && <Col md="auto">
                            <ButtonStyle
                                size    = "forTable"
                                onClick = {onCancel}
                                icon    = "closeGreen"/>
                            </Col>
                }
                {
                    isLearningTrack && isNew
                    && <SelectFieldStyle
                            className = "col-md-6 mt-3"
                            keystone  = "LearningObjective"
                            label     = "Learning Objective"
                            value     = {newLearningObjective}
                            values    = {listLearningObjective && listLearningObjective.map(({Id, Objective, Description}) => ({id:Id, value:`${Objective} - ${Description}`}))}
                            onChange  = {(_:string, value:any) => onChangeLearningObjective && onChangeLearningObjective(value)}/>
                }
                {
                    onEditQuestion
                    && <Col md="auto">
                            <ButtonStyle
                                label     = "Question Editor"
                                size      = "190"
                                className = "mr-2"
                                onClick   = {onEditQuestion}
                                icon      = "question"/>
                    </Col>
                }


                { onEditQuestion &&
                        <Col md="auto">
                            {
                                isOpen?
                                editButton
                            :
                                <ButtonRotatedIcon>
                                    {editButton}
                                </ButtonRotatedIcon>
                            }
                        </Col>
                }
            </Row>
            {
                isOpen && editOpen !== -1 && isCreating !== undefined
                && <Row className="mt-3">
                        {
                        !isLearningTrack &&
                        <Col md={3}>
                            <SelectFieldStyle
                                keystone="ExerciseType"
                                label="Type"
                                value={newExerciseType}
                                values={listExerciseType && listExerciseType.map(({Id, Description}) => ({id:Id, value:Description}))}
                                onChange={(_:string, value:any) => onChangeNewExerciseType && onChangeNewExerciseType(value)}/>
                        </Col>}

                        {
                            isLearningTrack
                            && <SelectFieldStyle
                                    className = "col-md-4"
                                    keystone  = "LearningObjective"
                                    label     = "Learning Objective"
                                    value     = {newLearningObjective || LearningObjectiveId}
                                    values    = {listLearningObjective && listLearningObjective.map(({Id, Objective, Description}) => ({id:Id, value:`${Objective} - ${Description}`}))}
                                    onChange  = {(_:string, value:any) => onChangeLearningObjective && onChangeLearningObjective(value)}/>
                        }
                        <Col md={6} className={newLearningObjective?"mt-3":""}>
                            <StyledInput
                                label="Title"
                                placeholder="Exercise title (optional)"
                                value={newExerciseTitle}
                                onChange = {(e) => {onChangeNewExerciseTitle && onChangeNewExerciseTitle(e.target.value)}}
                                ref={inputRef}
                            />
                        </Col>
                        <Row className="justify-content-end">
                            <StyledCeterCol md="auto">
                                <ButtonStyle
                                    green
                                    disabled = {newExerciseType == undefined || newExerciseType.length == 0}
                                    size     = "130"
                                    onClick  = {onUpdate}
                                    icon     = "save"
                                    label    = "Save"/>
                            </StyledCeterCol>
                        </Row>
                    </Row>
            }
            </>

}

export default ExerciseCardHeader

const HighTheme = {

 /** background color */
 backgroundPage  : "#000",
 backgroundWhite : "#000",

 /** main colors */
 mainPurple         : "#000",
 errorShadowColor   : "#fff",
 errorColor         : "#fff",
 successShadowColor : "#fff",
 successColor       : "#fff",
 errorColor2        : "#000",
 successColor2      : "#000",
 lightPurple        : "#000",
 lightPurpleInvert  : "#fff",
 lightIvory         : "#fff",
 lightGray          : "#fff",
 lightGreen         : "#fff",
 lightBlue          : "#000",
 darkPurple         : "#000",
 filterSvg          : "grayscale(100) brightness(100) !important",
 filterSvgGreen     : "grayscale(100%) brightness(0)",
 filterSvgWhite     : "invert(1)",
 blockedSvg         : "#000",
 line               : "#fff",
 boxShadow          : "rgba(255, 255, 255, 0.11)",
 borderLightGray    : "#fff",
 brightness         : "brightness(0)",
 grayScale          : "grayscale(100) brightness(0)",
 blocked            : "#000",

 /** text color */
 textColor      : "#fff",
 textColor2     : "#000",
 mutedTextColor : "#fff",
 greentextColor : "#fff",
 title          : "#fff",
 textWhite      : "#fff",
 textWhite2     : "#000",
 linkHoverColor : "#8bfffb",
 textInvert     : "#000",
 textBlocked    : "#fff",

 /** buttons */
 clickable                 : "#fff",
 backgroundBtDisabled      : "#fff",
 colorBtDisabled           : "#000",
 backgroundRadioActive     : "#fff",
 backgroundRadioActiveText : "#000",
 inputDisabled             : "#C5CBCC",
 btnDisabledText           : "#000",

 /** Messages */
 selectedLine : "#fff",
 hoverMessage : "#212121",
 selectText   : "#000",

 /** Tabs */
 tabTextActive:'#fff',

 /** Tables */
 lineTable:"#fff",
}

export default HighTheme

import React  from 'react';
import Select from "react-select"

const LiveSearch = ({ id, className, messageBlocker, disabled, label, required, onChange, keystone, values }: SelectFieldPropsType) => {
  
    return (
        <div className={`${className || ""}`}>
            <div className={`form-group is-invalid ${messageBlocker ? 'mb-0' : 'mb-4'} ${disabled ? 'input-disabled' : ''}`}>
                {(label) ? <label className="form-label">{`${label}${required ? "*" : ""}`}</label> : ""}
                <Select
                    id={id}
                    options={values.map(el => { return { value: el.id, label: el.value } })}
                    onChange={(e: any) => onChange(keystone, e.value)}
                    isMulti={true}
                    isSearchable={true}
                    classNamePrefix="message"
                    className="option-message"
                    isDisabled={disabled}
                />
            </div>
            {(messageBlocker) ? <small className="form-text text-muted text-right mb-3 text-clickable">{messageBlocker}</small> : ''}
        </div>
    );
}


export default LiveSearch
import * as React from "react"
import {
    useState,
    useEffect,
    useContext,
    Fragment
} from "react"
import { Tab } from "react-bootstrap"
import {
    Container, Card,
    Row, Col, Badge
} from "reactstrap"
import styled from "styled-components"
import qs from "querystring"

import {
    BreadcrumbViewReportTeacher,
    removeBreadcrumbData,
    addBreadcrumbData,
} from "YConnect/Components/BreadCrumbList"
import TabStyle from "YConnect/Styles/Tab.style"
import Loader from "YConnect/Components/Loader"
import API from "YConnect/API"
import UserContext from "YConnect/User.context"

import LearningTrack from "YConnect/PagesComponents/ViewReportTeacher/LearningTrack"
import GradeSending from "YConnect/PagesComponents/ViewReportTeacher/GradeSending"
import ScoreUnitClass from "YConnect/PagesComponents/ViewReportTeacher/ScoreUnitClass"
import ScoreUnitStudent from "YConnect/PagesComponents/ViewReportTeacher/ScoreUnitStudent"
import FinalScore from "YConnect/PagesComponents/ViewReportTeacher/FinalScore"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import Toastify from "toastify-js"

const BadgeStyle = styled(Badge)`
    background-color: #FF1A4A;
`

const idCursoStarter = 67

const ViewReportTeacherPage = ({ match, location }: any) => {

    const [messagePageTitle, setMessagePageTitle] = useState('')
    const [hasLoading, setHasLoading] = useState(true)
    const [grupos, setGrupos] = useState<any>()
    const [turma, setTurma] = useState<any>()
    const [currentSearch, setCurrentSearch] = useState<any>()
    const [totalSendingErrors, setTotalSendingErrors] = useState<any>()
    const [courses, setCourses] = useState()
    const [multinivel, setMultinivel] = useState(false)

    const userContext: any = useContext(UserContext)
    const { idEstab, idCurso, idGrupo } = match.params
    const { current } = qs.parse(location.search.substr(1))

    const [flagLT, setFlagLT] = useState(false)

    const [learningTrack, setLearningTrack] = useState<any>([])

    const [studentsTurma, setStudentsTurma] = useState()
    const [studentsMultiseriado, setStudentsMultiseriado] = useState<any>()

    const [idStudentMultiseriado, setIdStudentMultiseriado] = useState(69)
    const [newConsulta, setNewConsulta] = useState()

    const [hasStudents, setHasStudents] = useState(false)

    const cursoStarter: boolean = parseInt(idCurso, 10) === idCursoStarter

    addBreadcrumbData([{ page: 'ViewReportTeacherPage', data: { currentSearch, idEstab, idCurso, idGrupo } }])
    removeBreadcrumbData([{ page: 'ViewReportResourceBookPage' }])

    const getCourseType = () => {
        API.Course.GetTipoCurso({ id_curso_tipo: idCurso }, getConfig())
            .then((response: any) => {
                setMultinivel(response.data == 2)
                setFlagLT(true)
            })
            .catch((e: any) => {
                Toastify({
                    text: e.response.data.message,
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            });
    }


    useEffect(() => {
        fetchCourseType();
    }, [ idCurso ])

    useEffect(() => {
        fetchGroup();
    }, [])

    useEffect(() => {
        if (multinivel)
            fetchMultinivel()
    }, [turma, multinivel])

    useEffect(() => {
        if(studentsTurma)
            fetchStudentCourse()
    }, [studentsTurma])

    useEffect(() => {
        if(turma)
            setStudentsTurma(turma.alunos)
    }, [turma])

    useEffect(() => {
        if(studentsTurma) {
            API.Aluno.GetAlunoCurso({idTurma: Number(idGrupo), idCurso: Number(idCurso) }, getConfig())
            .then((res) => setStudentsMultiseriado(res.data))
        }
    }, [studentsTurma])

    useEffect(() => {
        if(multinivel && studentsMultiseriado) {
            studentsMultiseriado.map((key) => {
                if(key.id_curso == idCurso) {
                    setNewConsulta(key)
                    return key
                }
            })

            if (turma != undefined && studentsMultiseriado)
                fetchGroupSituation()
        }
    }, [studentsMultiseriado, newConsulta, multinivel, flagLT])

    useEffect(() => {
        filterGrupos();
    }, [grupos])

    useEffect(() => {
        setMessagePageTitle(`${current === "true" && !cursoStarter ? "Grade Sending" : "Class Score per Unit"}`);
    }, [current])

    const fetchCourseType = async () => {
        try {
            const response = await API.Course.GetTipoCurso({ id_curso_tipo: idCurso }, getConfig());
            setMultinivel(response.data == 2);
            setFlagLT(true)
        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching de course type",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        }
    }

    const fetchGroup = async () => {
        try {
            const fl_vigentes = current;
            const response = await API.Grupo.Get({ idEstab, idCurso, fl_vigentes }, getConfig());
            setGrupos(response.data)
        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching the group",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        }
    }

    const fetchStudentCourse = async () => {
        try {
            const response = await API.Aluno.GetAlunoCurso({idTurma: Number(idGrupo), idCurso: Number(idCurso) }, getConfig());
            setStudentsMultiseriado(response.data);
        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching the students course",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        }
    }

    const fetchMultinivel = async () => {
        try {
            const response = await API.Course.GetAll({ isDegustacao: false }, getConfig())
            setCourses(response.data)
        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching the multinivel",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        }
    }


    const fetchGroupSituation = async () => {
        try {
            const response = await API.Report.GetSituacaoTurma(
                { idProfessor: turma.idProfessor, idTurma: Number(idGrupo) , idCurso:idCurso},
                getConfig()
            );
            const learningTrackObj = await fetchLearningTrack(response.data);
            setLearningTrack(learningTrackObj);
        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching the group situation",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        } finally {
            setHasLoading(false);
            setHasStudents(true)
        }
    }

    const fetchLearningTrack = async (data: any) => {
        const newObject:any = data;
        try {
            for(let i: number = 0; i < data.length; i++){
                const response = await API.Aluno.GetAlunoLearningTrack({nsu_estab: idEstab, id_aluno: data[i].id_aluno}, getConfig());
                const matriculas = response.data.matriculas

                let matriculaByCourseId

                const valueCourseId = matriculas.map((key) => {
                    if(key.idCurso == idCurso) {
                        matriculaByCourseId = key
                        return key
                    }
                })

                data[i].initialLesson = matriculaByCourseId.initialLesson ? matriculaByCourseId.initialLesson : "-";
                data[i].currentLesson = response.data.matriculas && response.data.matriculas.length > 0
                                      ? matriculaByCourseId.currentLesson : "-";
                newObject[i] = data[i];
            }
        } catch (error) {
            Toastify({
                text: "An error has occurred on fetching the learning track",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            console.error(error);
        }finally{
            return newObject;
        }
    }

    const filterGrupos = () => {
        if (grupos && grupos.turmas && grupos.turmas.length > 0) {
            setTurma(grupos.turmas.find(({ id_grupo }: any) => id_grupo == Number(idGrupo)));
            setHasLoading(false)
            refreshCurrentSearch()
        }
    }

    const refreshCurrentSearch = () => {
        const lastSearches = JSON.parse(localStorage.getItem("lastSearches"))
        if (lastSearches && lastSearches[userContext.idUsuario]) {
            const currentSearch = lastSearches[userContext.idUsuario].find(({ classParam }: any) => classParam == idGrupo)
            localStorage.setItem("reportAluno", JSON.stringify(currentSearch))
            setCurrentSearch(currentSearch)
        }
    }
    const defaultTab = () => {
        if (cursoStarter) {
            return 'students-score-unit'
        } else if (current === "true") {
            if (multinivel) {
                return 'learning-track'
            }
            return 'grade-sending'
        }

        return 'class-score-unit'
    }

    const renderGradeSendingTabName = () =>
        <Fragment>
            Grade Sending
        {
                (totalSendingErrors && totalSendingErrors > 0)
                    ? <BadgeStyle className="ml-2" pill>{
                        totalSendingErrors === 1
                            ? `${totalSendingErrors} error`
                            : `${totalSendingErrors} errors`
                    }</BadgeStyle>
                    : ""
            }
        </Fragment>

    return !hasLoading
        ? <Container>
            {currentSearch && <BreadcrumbViewReportTeacher
                currentSearch={currentSearch}
                idEstab={idEstab}
                idCurso={idCurso}
                idGrupo={idGrupo} />}
            <Row className="mb-5 mr-0">
                <Col md={6} xs={12}>
                    <h2>Reports: {messagePageTitle}</h2>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col md={12}>
                    <Card className="shadow p-0">
                    <TabStyle defaultActiveKey={defaultTab()}
                            onSelect={(eventKey: string) => { }}>
                            {
                                //somente cursos mutinivel
                                multinivel &&
                                <Tab eventKey="learning-track" title="Learning Track" className="px-4" onEntered={() => setMessagePageTitle(currentSearch ? currentSearch.class : '')}>
                                    {
                                        courses &&
                                        turma && learningTrack &&
                                        <LearningTrack
                                            situacaoTurma={learningTrack}
                                            courses={courses}
                                            hasStudents={hasStudents} />

                                    }
                                </Tab>
                            }
                            {
                                !cursoStarter
                                && <Tab eventKey="grade-sending" title={renderGradeSendingTabName()} className="px-4" onEntered={() => setMessagePageTitle('Grade Sending')}>
                                    {
                                        turma &&
                                        studentsMultiseriado
                                        && <GradeSending
                                            onChangeSendErrors={setTotalSendingErrors}
                                            idEstab={idEstab}
                                            idCurso={idCurso}
                                            idGrupo={idGrupo}
                                            turma={turma}
                                            studentsSeriados={studentsMultiseriado}
                                            isMultinivel={multinivel}
                                            />
                                    }
                                </Tab>
                            }

                            {!cursoStarter && studentsMultiseriado
                                && <Tab eventKey="class-score-unit" title="Class score per unit" onEntered={() => setMessagePageTitle('Class score per unit')} className="px-4 mt-5">
                                    <ScoreUnitClass idGrupo={idGrupo} idCurso={idCurso} idUsuario={userContext.idUsuario} />
                                </Tab>
                            }
                            {studentsMultiseriado &&
                            <Tab eventKey="students-score-unit" title={cursoStarter ? "Students Score" : "Students Score per Unit"} onEntered={() => setMessagePageTitle('Students Score per Unit')} className="px-4">
                                <ScoreUnitStudent idGrupo={idGrupo} idCurso={idCurso} isMultinivel={multinivel} studentsSeriados={studentsMultiseriado}/>
                            </Tab>
                            }
                            {studentsMultiseriado
                                && <Tab eventKey="final-score" title="Final Score" onEntered={() => setMessagePageTitle('Final Score')} className="px-4">
                                    <FinalScore idGrupo={idGrupo} idCurso={idCurso} isMultinivel={multinivel} studentsSeriados={studentsMultiseriado}/>
                            </Tab>
                            }

                        </TabStyle>
                    </Card>
                </Col>
            </Row>
        </Container>
        : <section className="row">
            <div className="col-12 ">
                <div className="card p-0 shadow d-flex flex-row flex-wrap justify-content-around profile">
                    <Loader />
                </div>
            </div>
        </section>
}

export default ViewReportTeacherPage

import * as React from "react"
import {useEffect, useState} from "react"
import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"
import getStaticUrlAssets from "YConnect/Utils/GetStaticUrlAssets.util"

import { Row, Col, Card } from "reactstrap"

import EditorField from "YConnect/Fields/Forms/Editor.field"

import ImageFilesModal from "YConnect/PagesComponents/CMS/Modals/ImageFiles.modal"

type DialogueEditorProps = {
    hasImage ?: Boolean
    idx      ?: number
    item     ?: ItemTypeInType
    isNew    ?: Boolean
    onChange ?: any
    onAdd    ?: any
    onRemove ?: any
}

const ImageStyled = styled.img`
    width: 100%;
    height: auto;
`

const CardStyled = styled(Card)`
    ${props => props.isNew ? "background: aliceblue!important;" : ""}
`

const getNAnswers = (Text:string) => {
    const nChunk = Text ? Text.split("___").length : 0
    if(nChunk > 1){
        return (nChunk-1)
    }
    return 0
}

const  blankAnswer = (answers:Array<AnswerType>) =>{

    if(!answers || answers.length == 0){
        return false
    }

    for(let i=0; i< answers.length;i++ ){
        if(answers[i].Awnser.length==0){
            return true
        }
    }

    return false
}

const DialogueTypeInEditor = ({
    hasImage,
    idx,
    isNew,
    item,
    onAdd,
    onRemove,
    onChange=(()=>{})
}:DialogueEditorProps) => {

    const [textState, setTextState]       = useState<string>(item && item.text)
    const [mediaState, setMediaState]     = useState<ExerciseMediaType>(item && item.media)
    const [answersState, setAnswersState] =  useState<Array<AnswerType>>(item && item.answers)


    const [isShowImageFilesModal, setShowImageFilesModal] = useState(false)

    const [nAnswers, setNAnswers] = useState(item && item.answers.length)

    const [nRegisteredAnswers, setNRegisteredAnswers] = useState(0)
 
    const handleChangeDilogue = (value:string) => {
        setTextState(value)
        onChange({
            text:value,
            media:mediaState,
            answers:answersState,
            dialogMessage: item? { ...item.dialogMessage, Text:value} 
            :  { OrderMessage:idx+1, OrderSubMessage:idx+1, Text:value},
        })
    }

    const handleOpenModalImageFiles =  () => setShowImageFilesModal(true)
    const handleCloseModalImageFiles = () => setShowImageFilesModal(false)

    const handlAddImage = ({Source, MediaType}:any) => {
        setMediaState({Source, MediaType, Order:idx+1 })
        setShowImageFilesModal(false)
        onChange({
            text:textState,
            media:{Source, MediaType, Order:idx+1},
            answers:answersState,
            dialogMessage: item? { ...item.dialogMessage, Text:textState} 
            : { OrderMessage:idx+1, OrderSubMessage: idx+1, Text:textState}
        })
    }

    const handleRemoveImage = () => {
        setMediaState(undefined)
    }

    const handleChangeAnswer = (index:number, answer:string) => {
        const newAnswers = answersState.map((value, i) => i === index ? {...value, Awnser:answer} : value)
        setAnswersState(newAnswers)
        onChange({
            text:textState,
            media:mediaState,
            answers:newAnswers,
            dialogMessage: item? { ...item.dialogMessage, Text:textState} 
            : { OrderMessage:idx+1, OrderSubMessage: idx+1, Text:textState}
        })
    }

    const updateState = (item:ItemTypeInType) => {
      
        if(item){
            setTextState(item.text)
            setMediaState(item.media)
            setAnswersState(item.answers)
            setNRegisteredAnswers(item.answers.length)
        }else{
            setTextState("")
            setMediaState(undefined)
            setAnswersState([])
        }
    }

    useEffect(()=>{
        updateState(item)
    }, [item])

    useEffect(()=>{
        if(textState){
            const nAnswers = getNAnswers(textState)
            setNAnswers(nAnswers)
            if(item != null){
                setNRegisteredAnswers(item.answers.length)
            }else{
                setNRegisteredAnswers(0)
            }
        }
    }, [textState])

    useEffect(()=>{
        if(nAnswers){
            if(answersState.length == 0){
                setAnswersState(Array.from(Array(nAnswers).keys())
                .map((i)=> answersState && answersState[i] || {Awnser:"", Order:idx+1, IsCorret:true}))
                setNRegisteredAnswers(1)
            }
        }else{
            setAnswersState([])
        }
    }, [nAnswers])

    useEffect(()=>{
        if(nRegisteredAnswers){
                setAnswersState(Array.from(Array(nRegisteredAnswers).keys())
                .map((i)=> answersState && answersState[i] || {Awnser:"", Order:idx+1, IsCorret:true}))
        }
    }, [nRegisteredAnswers])

    const handleAddDialogue = () => {
        onAdd({
            text:textState,
            media:mediaState,
            answers:answersState,
            dialogMessage:{ OrderMessage:idx+1, OrderSubMessage:idx+1, Text:textState}
        })
    }

    const handleRemoveDialogue = () =>{
        setTextState(null)
        setAnswersState(null)
        onRemove(idx + 1)
    }

    return <CardStyled md={12} isNew={isNew} className="shadow p-3 m-3">
        <Row>
            {
                hasImage
                && mediaState
                && mediaState.Source 
                && <Col md={3} className="align-self-center">
                        <ImageStyled src={getStaticUrlAssets(mediaState.Source)}/>
                    </Col>
            }
            {
                hasImage
                && <Col md="auto" className="align-self-center">
                        {
                            !mediaState
                            && <ButtonStyle
                                    green
                                    size      = "130"
                                    onClick   = {handleOpenModalImageFiles}
                                    icon      = "imageWhite"
                                    label     = "Add"/>
                        }
                        {
                            mediaState
                            && mediaState.Source 
                            && <ButtonStyle
                            className = "mb-2"
                            size      = "130"
                            onClick   = {handleOpenModalImageFiles}
                            icon      = "reset"
                            label     = "Change"/>}
                        {/*
                            mediaState
                            && mediaState.Source 
                            && <ButtonStyle
                            size      = "130"
                            onClick   = {handleRemoveImage}
                            icon      = "remove"
                            label     = "Remove"/>
                        */}
                    </Col>
            }
            <EditorField 
                value={textState}
                className={hasImage? "col-8":"col-10"} 
                keystone="dialogue"
                onChange={(_, value:string) => handleChangeDilogue(value)}
                toolbar={{"options":  ["inline"], "inline": {"options": ["bold", "italic", "underline", "strikethrough"]}}}/>
            <Col md="auto" className="align-self-center">
                {isNew && <ButtonStyle
                    disabled  = {!textState|| nAnswers > 1 || blankAnswer(answersState)}
                    size      = "130"
                    onClick   = {handleAddDialogue}
                    icon      = "plusGreen"
                    label     = "Add"/>}
                {!isNew && <ButtonStyle
                    className = "mb-2"
                    size      = "130"
                    onClick   = {handleRemoveDialogue}
                    icon      = "remove"
                    label     = "Remove"/>
                }
            </Col>
            {  answersState && answersState.length>0?
                <Col md={12} className="mb-3">
                    <strong>Answers</strong>
                </Col>
                :null
            }

            {
                answersState && answersState.length>0 &&
                answersState
                .map((answer, index) => 
                    <React.Fragment>
                    <Col md={8} className="pb-2" key={index}>
                        <input 
                        defaultValue = {answer.Awnser}
                        type         = "text" 
                        className    = "form-control" 
                        onChange     = {({ target: { value } }:any) => handleChangeAnswer(index, value)}/>
                    </Col>
                    {index == answersState.length-1? 
                    <Col md={2}> 
                        <ButtonStyle
                        className = ""
                        size      = "0"
                        onClick   = {()=>{ setNRegisteredAnswers(nRegisteredAnswers+1)}}
                        icon      = "plusGreen"
                        label     = "Add"/>
                    </Col>
                    :null}
                      {index == answersState.length-1 && index >0? 
                    <Col md={2}> 
                        <ButtonStyle
                        className = ""
                        size      = "0"
                        onClick   = {()=>{ setNRegisteredAnswers(nRegisteredAnswers-1)}}
                        icon      = "closeGreen"
                        label     = "Remove"/>
                    </Col>
                    :null}
                    </React.Fragment>)
            }
        </Row>
        {
            isShowImageFilesModal
            && <ImageFilesModal onSelect={handlAddImage} 
            onClose={handleCloseModalImageFiles}/>
        }
    </CardStyled>
}

export default DialogueTypeInEditor
import * as React    from "react"
import * as ReactDOM from "react-dom"
import { Provider }                     from "react-redux"
import { combineReducers, createStore } from "redux"

import "bootstrap/dist/css/bootstrap.min.css"
import "./index.scss"

import LIST_PAGES_CONFIG from "YConnect/Configs/Pages.config"
import YConnectContainer from "YConnect/YConnect.container"
import ErrorBoundary     from "YConnect/Components/ErrorBoundary"

import QueryParamsReducer    from "YConnect/Reducers/QueryParams.reducer"


const reducer = combineReducers({
	QueryParams :  QueryParamsReducer
})

const store = createStore(reducer)

ReactDOM.render(
<ErrorBoundary>
    <Provider store={store}>
        <YConnectContainer configs={LIST_PAGES_CONFIG}/>
    </Provider>
</ErrorBoundary>, document.getElementById("gui"))


import styled      from "styled-components"
import { NavLink } from "react-router-dom"

const NavLinkStyle = styled(NavLink)`
    border-bottom: 4px solid transparent;
    color: ${props => props.theme.main.textWhite};
    font-weight: bold;
    height: 100%;
    text-align: center;
    line-height: 1.2em;
    padding: ${props => props.theme.main.paddingLink};

    @media (min-width: 1200px) {
        padding: ${props => props.theme.main.paddingLinkLx};
    }

    &:hover,
    &.active {
        color: ${props => props.theme.main.textWhite};
        background-color: ${props => props.theme.main.lightPurple};
        text-decoration: none;
    }

    &.active {
        border-bottom: 4px solid ${props => props.theme.main.lightGreen};
        color: ${props => props.theme.main.lightGreen};
    }
`

export default NavLinkStyle
import { useEffect } from "react"

const useQueryParams = ({
    idSelecteds,
    setIdSelected,
    queryParams,
    onChangeQueryParams
}:any) => {

    useEffect(() => {
        if(idSelecteds){
            const newQueryParams = Object.keys(idSelecteds)
            .reduce((newQueryParams:any, paramName:string)=>{
                if(idSelecteds[paramName] && idSelecteds[paramName]!==""){
                    return {...newQueryParams, [paramName]:idSelecteds[paramName]}
                }
                return newQueryParams
            }, {})

            onChangeQueryParams(newQueryParams)
        }
    }, [...Object.keys(idSelecteds).map((paramName) => idSelecteds[paramName])])

    
    useEffect(()=>{
        setIdSelected(queryParams)
    }, [])
}

export default useQueryParams
import * as React from "react"
import {useEffect} from "react"
import {
    Row,
    Col
} from "reactstrap"
import styled from "styled-components"

import Table from "YConnect/Components/Table"

import { ButtonStyle } from "YConnect/Components/Button"
import useEditableTableState from "YConnect/PagesComponents/CMS/Hooks/useEditableTableState"


const TableStyled = styled(Table)`
    .table{
        min-height: auto;
    }
    td, th {
        padding:10px;
    }
`

const INITIAL_DATA_STATE = [
    {
        type:"NEW",
        row:{}
    }
]

const GetStateByValues = (values:Array<DialogueAvatarType>) => 
    values && values.length > 0
    ? [...values.map((dialogueAvatar) => ({type:"ADDED", row:dialogueAvatar.Avatar})), {type:"NEW",row:{}}]
    : INITIAL_DATA_STATE


type DialogueAvatarsEditableTableProps = {
    values?:Array<DialogueAvatarType>
    onChange:any
}

const DialogueAvatarsEditableTable = ({
    values,
    onChange
}:DialogueAvatarsEditableTableProps) => {

    const {
        dataState,
        getValues,
        updateLine,
        addDataNewLine,
        removeLine
    } = useEditableTableState(GetStateByValues(values))

    const handleUpdateRow = (key:number, property:string, value:any) => updateLine(key, property, value)
    const handleAddNewRow = () => addDataNewLine()
    const handleRemoveRow = (key:number) => removeLine(key)

    useEffect(()=>{
        if(dataState){
            const values = getValues()
            .filter(({Source}:any) => Source && Source !== "")
            .map((row:any, key:number) => ({Avatar:row, Order:key+1}))

            onChange(values)
        }
    }, [dataState])

    const config : Array<ColumnConfigType> = [
        {
            label     : "Source",
            formatter : (state:any, key:number) => 
                <input 
                    type="text" 
                    className="form-control" 
                    onChange={({ target: { value } }:any) => handleUpdateRow(key, "Source", value)}
                    value={state.row["Source"] || ""}/>
        },
        {
            label     : "Name",
            formatter : (state:any, key:number) => 
                <input 
                    type="text" 
                    className="form-control" 
                    onChange={({ target: { value } }:any) => handleUpdateRow(key, "Name", value)}
                    value={state.row["Name"] || ""}/>
        },
        {
            formatter : (state:any, key:any) => 
            <Col>
                {
                    state.type === "ADDED"
                    && <Row className="px-0">
                        <ButtonStyle 
                            size      = "forTable"
                            className = "mr-2"
                            icon      = "remove"
                            onClick   = {() => handleRemoveRow(key)}/>
                    </Row>
                }
            </Col>
        }
    ]

    return <TableStyled 
                data={dataState}
                config={config} />

}

export default DialogueAvatarsEditableTable
import getBaseURLNewApi from "YConnect/Utils/GetBaseURLNewAPI.util"

const getStaticUrlAssets = (url: string) => {
    if (url.includes('midia:')) {
        const urlCMSFormatted = url.replace('midia:', '')

        return `https://cms-cdn.pearsonbr.com/${urlCMSFormatted}`
    }

    else {
       return `${getBaseURLNewApi()}/Assets/${url}`
    }
}
export default getStaticUrlAssets


import * as React from "react"

const Loader = () =>
    <div className="bg-loader">
        <div className="loader-bg"></div>
        <div className="loader"></div>
    </div>

export default Loader



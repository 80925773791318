import * as React           from "react"
import { Modal, FormGroup } from "react-bootstrap"
import { ButtonStyle }      from "YConnect/Components/Button"

const ConfirmacaoDeReintegracaoModal = ({
    hasShow,
    onConfirm,
    onClose
}:any) => <Modal show={hasShow} onHide={onClose} size="lg" centered>
                    <Modal.Header closeLabel="Close" closeButton>
                        <h2>Reset Activities</h2>
                    </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to reset this student's reports?
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex flex-row justify-content-end">
                                <ButtonStyle
                                    type      = "button"
                                    label     = "Cancelar"
                                    size      = "small"
                                    className = "mr-2"
                                    onClick   = {onClose} />
                                <ButtonStyle
                                    green
                                    label    = "Confirm"
                                    size     = "small"
                                    onClick  = {onConfirm}/>
                            </div>
                        </Modal.Footer>
                </Modal>

export default ConfirmacaoDeReintegracaoModal

import * as React from "react"
import { Modal }  from "react-bootstrap"

import {ButtonStyle} from "YConnect/Components/Button"

type RemoveExerciseModalProps = {
    show:Boolean
    exercise:ExerciseType
    onCancel:any
    onRemove:any
}

const RemoveExerciseModal = ({
    show,
    exercise,
    onCancel,
    onRemove,
}:RemoveExerciseModalProps) =>{

    return <Modal show={show} onHide={onCancel} size="lg">
                <Modal.Header
                    closeButton
                    closeLabel={"Close"}
                    className="first-access"
                    style={{zIndex: 10}}>
                        {exercise && <Modal.Title>Are you sure want to remove <b>Exercise {exercise.Order}</b> ?</Modal.Title>}
                </Modal.Header>
                <Modal.Footer>
                    {exercise && <ButtonStyle
                            onClick={onRemove}
                            label="Yes"
                            icon="checkedGreen2" />
                    }
                    <ButtonStyle
                            onClick={onCancel}
                            label="No"
                            icon="closeGreen" />
                </Modal.Footer>
            </Modal>
}


export default RemoveExerciseModal
import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

import FormContainer from "YConnect/Containers/Form.container"
import TypeInItemsEditor from "YConnect/PagesComponents/CMS/Editors/TypeIn.editor/TypeInItems.editor"
import DEFAULT_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Default.template.json"

type TypeInWithImageTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const TypeInWithImageTemplateEditor = ({
    question,
    onChangeValues
}:TypeInWithImageTemplateEditorProps)=>{

    const [values, setValues] = useState(question)    

    const addValues = (newValues:any) => {
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }
    
    const handleChangeValues   = (values:any) => addValues(values)

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])

    return  <>
                <Row>
                    <Col md={12}>
                        <FormContainer 
                            values   = {values} 
                            onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                            template = {[...DEFAULT_TEMPLATE.map((field) => ({...field}))]}/>
                    </Col>
                    <TypeInItemsEditor 
                            hasImage
                            values={values} 
                            onChange={(values:any) => {
                                handleChangeValues(values)
                            }}/>
                </Row>
            </>
}

export default TypeInWithImageTemplateEditor

import styled       from "styled-components"
import { Dropdown } from "react-bootstrap"

const DropdownProfileStyle = styled(Dropdown.Menu)`
    background-color: ${props => props.theme.main.backgroundWhite};
    color: ${props => props.theme.main.textColor};
    border-radius: 0 0 8px 8px;
    border: 1px solid ${props => props.theme.main.textColor};
    border-top: none;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    left: -185px !important;
    top: -2px !important;
    transform: translate3d(0px, 80px, 0px) !important;
    width: 394px;

    a {
        &:hover {
            color: $clickable;
            text-decoration: none;
        }
    }
`

export default DropdownProfileStyle
import styled from "styled-components"

const DivNavbarLogoStyle = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 100px;
    align-items: center;

    @media screen and (min-width: 768px) {
        width: 120px;
    }

    @media screen and (min-width: 1024px) {
        width: 150px;
    }

    @media screen and (min-width: 1024px) {
        width: 180px;
    }

    @media screen and (max-width: 768px) {
        margin-left: 24px;
    }
`

export default DivNavbarLogoStyle
import * as React from "react"
import {useState, useEffect} from "react"
import styled from "styled-components"

import * as crown          from "YConnect/Assets/svg/crown.svg"
import BestClassScoreStyle from "YConnect/PagesComponents/PrePostReport/Pearson.components/BestClassScore.style"
import ReportTeacherInfo   from "YConnect/PagesComponents/PrePostReport/Components/ReportTeacherInfo"
import HeaderStyle         from "YConnect/PagesComponents/PrePostReport/Pearson.components/Header.style"

const CardStyle2 = styled.div`
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 223px;
`


const CardLoadingStyle = styled(CardStyle2)`
.loading {
    left: calc(50% - 20px);
    position: relative;
    width: 40px;
    height: 40px;
    border: 4px transparent solid;
    border-top: 4px ${props => props.theme.main.lightPurple} solid;
    border-radius: 30px;
    animation: preload .7s infinite;
    animation-timing-function: linear;
}

    
@-moz-keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes preload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
`

const CardStyle = styled.div`
align-items: center;
display: flex;
flex-flow: row wrap;
min-height: 223px;
`

const BestClassScore = ({
    isPost,
    bestClass
}:any) => {

    const [score, setScore] = useState()

    useEffect(()=>{

        if(bestClass){
            setScore(bestClass.find((score:any) => score && score.fl_is_pre === !isPost) || {})
        }

    }, [bestClass])

    return <>
                <HeaderStyle>
                    <h2 className="tabscore-title">Best class score</h2>
                </HeaderStyle>

                <BestClassScoreStyle>
                    {
                        score
                            ? Object.keys(score).length > 0
                                ? <>
                                    <div className="best-class">
                                        <div className="best-class-icon">
                                            <img src={crown} alt="" />
                                        </div>
                                        <div className="best-class-name">
                                            <span className="title">{score.nome}</span>
                                            <span className="address">{score.endereco}</span>
                                        </div>
                                    </div>
                                    <div className="best-percent">
                                        <h3 className="title">Overall percentage:</h3>
                                        <span className="percent"
                                            style={{ color: score.mediaTurma > 0.7 ? "#00A281" : "#FF1A4A" }}
                                        >
                                            {(score.mediaTurma * 100).toFixed(1)}%
                                            </span>
                                    </div>
                                    <div className="teacher-info">
                                        <ReportTeacherInfo teacherInfo={score && score.professor} />
                                    </div>
                                </> 
                                : <CardStyle className="align-items-center d-flex flex-row justify-content-center">
                                    <p>No one student have the post-test done</p>
                                </CardStyle>
                            : <CardLoadingStyle>
                                    <div className="loading"></div>
                                </CardLoadingStyle>
                    }
                </BestClassScoreStyle>
            </>
}

export default BestClassScore
import * as React from "react"
import {useState} from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

import ButtonCollapseStyle from "YConnect/Fields/Exercises/ChooseMultiple.field/ButtonCollapse.style"
import DivCollapseStyle from "YConnect/Fields/Exercises/ChooseMultiple.field/DivCollapse.style"
import HrCollapseStyle from "YConnect/Fields/Exercises/ChooseMultiple.field/HrCollapse.style"

const RowGreyStyled = styled(Row)`
    background-color: #f0f5f6;
`

type LongTextViewProps = {
    Text:string
}

const LongTextView = ({Text}:LongTextViewProps) => {

    const [collapse, setCollapse] = useState(false)

    return <>
                {
                    Text
                    && Text !== ""
                    && <RowGreyStyled className="justify-content-center">
                            <Col md={12} className="p-5">
                                <DivCollapseStyle toggler="#toggler" dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(Text)}} />
                                        <HrCollapseStyle />
                                        <ButtonCollapseStyle
                                            color="link"
                                            id="toggler"
                                            size="md"
                                            onClick={() => setCollapse(!collapse)}>
                                            {collapse ? '- Collapse text' : '+ Expand text' }
                                        </ButtonCollapseStyle>
                            </Col>
                        </RowGreyStyled>
                }
            </>
}


export default LongTextView
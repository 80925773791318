import * as React from "react"
import {useState} from "react"

import BestRankedStudentsStyle from "YConnect/PagesComponents/PrePostReport/School.components/BestRankedStudents.style"
import TableBestRankedStudents from "YConnect/PagesComponents/PrePostReport/School.components/BestRankedStudents/TableBestRankedStudents"
import CardBestRankedStudents  from "YConnect/PagesComponents/PrePostReport/School.components/BestRankedStudents/CardBestRankedStudents"

import CardLoading  from "YConnect/PagesComponents/PrePostReport/Components/CardLoading"

const BestRankedStudents = ({
    isPost,
    isOrderable, 
    bestRankedStudents
}:any) => {
    const [rankedStudentEvo, setRankedStudentEvo] = useState("score")

    const setOrder = (e: any) => setRankedStudentEvo(e.target.value)

    return <BestRankedStudentsStyle> 
        <header className="order-header">
            <h2 className="tabscore-title">Best ranked students</h2>
            {
                isOrderable
                && <div className="order mb-3">
                    <label className="form-label mr-2">Order by higher</label>
                    <div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(e) => setOrder(e)}>
                        <label className={`btn btn-radio ${rankedStudentEvo === "score" ? "active": ""}`}>
                            <input type="radio" name="order" value="score"/>
                            Score
                        </label>
                        <label className={`btn btn-radio ${rankedStudentEvo === "evolution" ? "active": ""}`}>
                            <input type="radio" name="order" value="evolution"/>
                            Evolution
                        </label>
                    </div>
                </div>
            }
        </header>
        {
            bestRankedStudents 
            ? window.innerWidth > 1024 
                ? <TableBestRankedStudents
                        isPost             = {isPost}
                        rankedStudentEvo   = {rankedStudentEvo}
                        bestRankedStudents = {bestRankedStudents}/>
                : <CardBestRankedStudents
                        isPost             = {isPost}
                        rankedStudentEvo   = {rankedStudentEvo}
                        bestRankedStudents = {bestRankedStudents}/>
            : <CardLoading/>
            
            /*<CardStyle className="align-items-center d-flex flex-row justify-content-center"><p>No students!</p></CardStyle>*/
        }
    </BestRankedStudentsStyle>
}

export default BestRankedStudents
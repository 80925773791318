import * as React           from "react"
import { Modal, FormGroup } from "react-bootstrap"
import { ButtonStyle }      from "YConnect/Components/Button"

const InformPassword = ({
    isShow,
    onClose,
    onChangePassword,
    onSaveChange,
    currentPassword
}:any) => <Modal show={isShow} onHide={onClose} size="lg" centered>
                    <Modal.Header closeLabel="Close" closeButton>
                        Inform your password
                    </Modal.Header>
                        <Modal.Body>
                            <FormGroup>
                                <label className="form-label" htmlFor="pasword">Inform your <strong>current</strong> password</label>
                                <input
                                    type        = "password"
                                    className   = "form-control"
                                    placeholder = "Enter your password"
                                    value       = {currentPassword}
                                    onChange    = {(e: any) => onChangePassword(e.target.value)}
                                />
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className=" d-flex flex-row justify-content-end">
                                <ButtonStyle type="button" label="Cancel" size={'small'} className={'mr-2'} onClick={onClose} />
                                <ButtonStyle
                                    green
                                    onClick  = {onSaveChange}
                                    disabled = {!currentPassword || currentPassword === ""}
                                    label    = "Save changes"
                                    size     = "small"
                                    icon     = "save"
                                />
                            </div>
                        </Modal.Footer>
                </Modal>

export default InformPassword

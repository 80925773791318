import * as React from "react"
import { Col, Tooltip } from "reactstrap"
import styled from "styled-components"

import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style"

import Loader       from "YConnect/Components/Loader"
import AudioPlayer  from "YConnect/Components/AudioPlayer"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"
import getConfig    from "YConnect/Utils/GetRequestConfig.util"
import API          from "YConnect/API"

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

import find  from "lodash/find"
import clone from "lodash/clone"

import AudioPlayerColStyle       from "YConnect/Fields/Exercises/ChooseMultiple.field/AudioPlayerCol.style"
import ButtonCollapseStyle       from "YConnect/Fields/Exercises/ChooseMultiple.field/ButtonCollapse.style"
import DivCollapseStyle          from "YConnect/Fields/Exercises/ChooseMultiple.field/DivCollapse.style"
import ExerciseQuestionStyle     from "YConnect/Fields/Exercises/ChooseMultiple.field/ExerciseQuestion.style"
import ExerciseRowStyle          from "YConnect/Fields/Exercises/ChooseMultiple.field/ExerciseRow.style"
import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/ChooseMultiple.field/ExerciseSubStatement.style"
import HrCollapseStyle           from "YConnect/Fields/Exercises/ChooseMultiple.field/HrCollapse.style"
import formatText from "YConnect/Utils/FormatText.util";

const SpanStyled = styled.span`

    b, strong {
        font-weight:normal!important;
        font-family:Panton-Bold;
    }
`

class ChooseMultiple extends React.Component<ExerciseFieldPropsType, {
    loadingAnwers   : boolean
    respostaUsuario : any
    answer          : any
    collapse        : boolean
    options         : any
    tooltipOpen     : boolean
    isDisabledViewAnswers : boolean
}> {
    constructor(props: any) {
        super(props)

        if (this.props.innerRef) {
            this.props.innerRef.current.selectLanguage = this.selectLanguage
        }

		this.state = {
            loadingAnwers: false,
            answer: null,
            respostaUsuario: null,
            options: this.embaralhaOpRespostas(props.exercise.respostas_Exercicio_Yconnect),
            collapse: false,
            tooltipOpen: false,
            language: this.props.translated,
        }

        this.handleChange = this.handleChange.bind(this)
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this)
    }

    componentDidMount() {
        if (this.props.attempts > 0)
            this.fetchUserAnswer()
    }

    selectLanguage = (language: string) => {
        this.setState({...this.state, language})
    }

    async fetchUserAnswer() {
        this.setState({ loadingAnwers: true })
        try {
            const response = await API.Exercicio.getRespostaUsuario({
                userId: this.props.studentId,
                exer: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem,
                tentativa: 'U',
                idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
            }, getConfig())

            this.setState({
                loadingAnwers: false,
                respostaUsuario: response.data.resposta,
                answer: response.data.resposta,
            })
        } catch (error) {}
    }

	handleChange(event: any) {
		const {
            props: { id, onChange, exercise }
        } = this
        const { currentTarget: { value } } = event

        const answer = find(exercise.respostas_Exercicio_Yconnect, { ordem: Number(value)})
        onChange(id, {
            qntd_acertos: answer.correta ? 1 : 0,
            qntd_respostas: 1,
            resposta: value
        })

        this.setState({ respostaUsuario: null, answer: value })
    }

    embaralhaOpRespostas (respostas: any) {
        let retorno = clone(respostas)

        if (respostas) {
            // Embaralha as respostas, e armazena nas opções de respostas
            for (var i = 0; i < respostas.length; i++) {
                var r1 = Math.floor(Math.random() * respostas.length)
                var r2 = Math.floor(Math.random() * respostas.length)

                var aux = retorno[r2]
                retorno[r2] = retorno[r1]
                retorno[r1] = aux
            }
        }

        return retorno
    }

    showCorrectAnswers() {
        const {
            props: { id, onChange, exercise: { respostas_Exercicio_Yconnect } }
        } = this

        const answer = find(respostas_Exercicio_Yconnect, { correta: true})
        onChange(id, {
            qntd_acertos: answer.correta ? 1 : 0,
            qntd_respostas: 1,
            resposta: answer.ordem
        })

        this.setState({ respostaUsuario: answer.ordem, answer: answer.ordem })
    }

	render() {
		const {
			props: {
                id,
                exercise,
                attempts,
                isStudent,
                isDisabledViewAnswers
            },
            state: {
                respostaUsuario,
                loadingAnwers,
                collapse,
                options,
                answer
            },
			handleChange
        } = this

        if (loadingAnwers) {
            return <Loader />
        }

        const midiaYConnect = exercise.midia_Yconnect.length > 0 ? exercise.midia_Yconnect[0] : {} ;

        const hasAudio = (midiaYConnect || {}).tipo_midia === 'audio'

        return (
            <>
                {
                    !isDisabledViewAnswers
                     && <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                   {(attempts >= 2 || !isStudent) ?
                        <HeaderButtonStyle
                            className="float-right"
                            label="View Answers"
                            onClick={this.showCorrectAnswers}
                            icon="checkedGreen2"
                        />
                        :
                        <>
                            <HeaderButtonStyle
                                id="completeResultToolTip"
                                className="float-right disabled view-answers"
                                label="View Answers"
                                icon="checkedGreen2"
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="completeResultToolTip" toggle={() => this.setState(state => ({ tooltipOpen: !state.tooltipOpen }))}>
                                    {
                                        this.state.language == "br" &&
                                        <span>Oops, você só poderá saber a resposta correta depois de verificar suas próprias respostas na primeira e segunda tentativa.</span>
                                    }

                                    {
                                        this.state.language == "en" &&
                                        <span>Oops, you'll only be able to know the
                                    correct answers once you've checked your own
                                    answers on the 1st and 2nd attempts.</span>
                                    }
                            </Tooltip>
                        </>
                    }
                </div>}
                {exercise.ajuda || exercise.texto || hasAudio ?
                    <ExerciseSubStatementStyle className="w-100">
                        {/** Ajuda */}
                        <div className="w-100 text-center">
                            <h2 dangerouslySetInnerHTML={{ __html: formatText(exercise.ajuda)}}></h2>
                        </div>

                        {/** texto */}
                        {exercise.texto ?
                            <ExerciseRowStyle className="exercise-dialogue pr-5 pl-5">
                                <Col className="exercise-dialogue-content" md="12" lg="12">
                                    <div className="exercise-dialogue-text mb-5 d-block w-100">
                                        <DivCollapseStyle toggler="#toggler" dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.texto))}} />
                                        <HrCollapseStyle />
                                        <ButtonCollapseStyle
                                            color="link"
                                            id="toggler"
                                            size="md"
                                            onClick={() => this.setState(state => ({ collapse: !state.collapse }))}
                                        >
                                            {collapse ? '- Collapse text' : '+ Expand text' }
                                        </ButtonCollapseStyle>
                                    </div>
                                </Col>
                            </ExerciseRowStyle>
                            :
                            ''
                        }

                        {/** audio */}
                        {hasAudio ?
                            <AudioPlayerColStyle
                                className=" mb-3"
                                xs="12"
                                md={{ size: 6, offset: 3 }}
                                lg={{ size: 6, offset: 3 }}
                            >
                                <AudioPlayer
                                    src={getStaticUrl(midiaYConnect.url)}
                                />
                            </AudioPlayerColStyle>
                            :
                            ''
                        }
                    </ExerciseSubStatementStyle>
                    :
                    ''
                }

                {/** Sub Enunciado */}
                {exercise.conteudo_exercicio ?
                    <ExerciseRowStyle className="exercise-dialogue mt-4 pr-5 pl-5">
                        <Col className="exercise-dialogue-content text-center w-100" md="12" lg="12">
                            <div className="exercise-dialogue-text">
                                <h4 dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.conteudo_exercicio)) }}></h4>
                            </div>
                        </Col>
                    </ExerciseRowStyle>
                    :
                    ''
                }

                {/** Midia file */}                {
                Object.keys(midiaYConnect).length > 0 && !hasAudio &&
                    <ExerciseSubStatementStyle className="w-100 pr-5 pl-5">
                        <Col className="exercise-dialogue-question align-image-cms" xs="12" md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }}>
                            <img style={{width: '100%'}} src={getStaticUrl(midiaYConnect.url)} />
                        </Col>
                    </ExerciseSubStatementStyle>
                }

                {/** Opções */}
                <ExerciseQuestionStyle className={`text-center w-100 ${exercise.conteudo_exercicio ? '' : 'mt-4'}`}>
                    <div className="btn-group btn-group-toggle m-auto"
                        data-toggle="buttons"
                        id={id}>
                        {
                            options.map((option: any, key: number) => {
                                return (
                                    <label
                                        key={key}
                                        className={`btn btn-radio ${`${answer}` === `${option.ordem}` ? 'active': ''} ${`${respostaUsuario}` === `${option.ordem}` ?  option.correta ? 'correct': 'wrong' : ''}`}
                                    >
                                        <input
                                            type="radio"
                                            id={option.id_resposta}
                                            value={option.ordem}
                                            onClick={handleChange}
                                        />
                                        <SpanStyled dangerouslySetInnerHTML={{ __html: formatText(option.resposta)}} />
                                    </label>
                                )
                            })
                        }
                    </div>
                </ExerciseQuestionStyle>
            </>
        )
	}
}

export default ChooseMultiple

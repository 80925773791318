import * as React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

const RowGreyStyled = styled(Row)`
    background-color: #f0f5f6;
`

type ContentViewProps = {
    Content:string
    Help:string
    Text:string
    rowGrey:boolean
}

const ContentView = ({ Content, Help, rowGrey }: ContentViewProps) => {

    const getContent = () => {

        if (Content && Content !== "" && Text) {
            if(rowGrey){
                return (
                    <RowGreyStyled className="justify-content-center">
                        <Col md={12} className="py-4">
                            <p className="text-center m-0" dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(Content) }} />
                        </Col>
                    </RowGreyStyled>
                )
            }
            return (
                <Row className="justify-content-center">
                    <Col md={12} className="py-4">
                        <p className="text-center m-0" dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(Content) }} />
                    </Col>
                </Row>
            )
        }
        return null;
    }


    return <>
        {getContent()}
        {   Help
            && Help !== "" ?
            <RowGreyStyled className="justify-content-center">
                <Col md={12} className={`pb-5 ${!(!Help || Help ==="") || "pt-5"}`}>
                    <p className="text-center m-0" dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(Content) }}/>
                </Col>
            </RowGreyStyled>
            :null
        }
    </>

}



export default ContentView
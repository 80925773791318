import * as React        from "react"
import {Container, Card, Row, Col} from "reactstrap"

import * as pageNotFound from "YConnect/Assets/svg/page-not-found.svg"

const PageNotFoundPage = () => 
    <Container className="py-4">
        <Card className="text-center">
            <Row>
                <Col><img src={pageNotFound} className="mb-3"/></Col>
            </Row>
            <h1>Page not found!</h1>
            <p>We're sorry, the page you requested could not be found. Please go back, or contact support if needed.</p>
        </Card>
    </Container>

export default PageNotFoundPage
import { useEffect } from "react"

const useFilter = ({
    establishmentIdSelected,
    courseIdSelected,
    classIdSelected,
    studentIdSelected,
    setIdSelected
}:any) => {

    useEffect(() => setIdSelected({courseIdSelected:""}),  [establishmentIdSelected])
    useEffect(() => setIdSelected({classIdSelected:""}),   [courseIdSelected])
    useEffect(() => setIdSelected({studentIdSelected:""}), [classIdSelected])
    useEffect(() => setIdSelected({periodIdSelected:""}), [studentIdSelected])
}

export default useFilter
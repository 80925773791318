import styled from "styled-components"
import {Col} from "reactstrap"

import * as checked from "YConnect/Assets/svg/checked.svg"
import * as remove  from "YConnect/Assets/svg/closeRed.svg"

const ExerciseDialogueStyle = styled(Col)`
    .exercise-dialogue-text {
        position: relative;
        color: ${props => props.theme.main.textWhite};
        background: ${props => props.theme.main.lightBlue};
        border: 1px solid ${props => props.theme.main.lightIvory};
        padding: 15px 25px;
        border-radius: 10px;
        width: fit-content;
        max-width: 100%;

        .resposta {
            text-decoration: underline;
        }
    }

    .exercise-dialogue-options {
        width: fit-content;
        min-width: 150px;
    }

    &.borderBottom:not(:last-child) {
        border-bottom: 1px solid #eee;
        margin-bottom: 30px;
        padding-bottom: 10px
    }

    &.right {
        .exercise-dialogue-text {
            border: 1px solid ${props => props.theme.main.successColor} !important;

            &::after {
                content: url(${checked});
                filter: ${props => props.theme.main.filterSvg};
                position: absolute;
                right: 2px;
                top: 2px;
                transform: scale(0.7);
            }
        }

        .exercise-dialogue-options {
            label {
                color: ${props => props.theme.main.successColor};
            }

            select {
                border-bottom-color: ${props => props.theme.main.successColor};
            }
        }
    }

    &.wrong {
        .exercise-dialogue-text {
            border: 1px solid ${props => props.theme.main.errorColor} !important;

            &::after {
                content: url(${remove});
                filter: ${props => props.theme.main.filterSvg};
                position: absolute;
                right: 5px;
                top: 5px;
                transform: scale(0.8);
            }
        }

        .exercise-dialogue-options {
            label {
                color: ${props => props.theme.main.errorColor};
            }

            select {
                border-bottom-color: ${props => props.theme.main.errorColor};
            }
        }
    }

    .selectAnswerDragDrop {
        text-decoration: underline;
    }
`

export default ExerciseDialogueStyle

import * as React            from "react"
import {useState, useEffect} from "react"
import styled                from "styled-components"

import * as placeholderPicture  from "YConnect/Assets/png/placeholder-profile.png"


interface ProfilePictureProps {
    border    ?: string
    className ?: string
    width     ?: number
    picture   ?: string
    user      ?: string
    height    ?: number
    minHeight ?: number
    minWidth  ?: number
}

const FigureStyle = styled.figure`
    text-align: center;
`

const ProfilePicture = (props: ProfilePictureProps) => {
    const [imgHasError, setImgHasError] = useState(false)

    useEffect(() => {
        setImgHasError(false)
    }, [props])

    const userImg = (picture: string): string => imgHasError || picture === null || picture === "" ? placeholderPicture : picture;

    const pictureStyle = {
        borderRadius : '50%',
        border       : props.border           || "",
        height       : `${props.height}px`    || "",
        width        : `${props.width}px`     || "",
        minWidth     : `${props.minWidth}px`  || "",
        minHeight    : `${props.minHeight}px` || "",

    }

    return <FigureStyle className="mb-0">
                <img 
                    src       = {userImg(props.picture)} 
                    alt       = {`Profile picture from ${props.user || `user.`}`}
                    style     = {pictureStyle}
                    className = {props.className}
                    onError   = {() => setImgHasError(true)}
                />
            </FigureStyle>
}

export default ProfilePicture
import * as React              from "react"
import { Row, Col }            from "reactstrap"

import RankedProgressBar   from "YConnect/PagesComponents/PrePostReport/Components/RankedProgressBar/index"
import TabReportStyle      from "YConnect/PagesComponents/PrePostReport/Pearson.report/TabReport.style"

import BestResultsByRegion from "YConnect/PagesComponents/PrePostReport/Pearson.components/BestResultsByRegion"
import BestClassScore      from "YConnect/PagesComponents/PrePostReport/Pearson.components/BestClassScore"

const TabReport = ({ 
    isPost,
    schoolInfo, 
    bestClass, 
    semesterIdSelected,
    regionalIdSelected
}: any) => {

    return <TabReportStyle>
    <Row>
        <Col xs={12} lg={8} className="mb-3">
            <BestResultsByRegion
                isPost             = {isPost}
                semesterIdSelected = {semesterIdSelected}
                regionalIdSelected = {regionalIdSelected}/>
        </Col>

        <Col xs={12} lg={4}>
            <BestClassScore 
                isPost    = {isPost}
                bestClass = {bestClass} />
        </Col>
    </Row>
    <Row className="mt-3">
        <Col xs={12} lg={6} className="mb-3">
            <RankedProgressBar
                title       = "Best ranked Schools"
                isOrderable = {(isPost?true:false)}
                prePost     = {schoolInfo && schoolInfo.highest.filter((high: any) => high.fl_is_pre === (isPost?false:true))} />
            
        </Col>
        <Col xs={12} lg={6}>
            <RankedProgressBar
                title       = "Lowest ranked Schools"
                isOrderable = {(isPost?true:false)}
                prePost     = {schoolInfo && schoolInfo.lowest.filter((high: any) => high.fl_is_pre === (isPost?false:true))} />
        </Col>
    </Row>
</TabReportStyle>
}
    
export default TabReport
import * as React   from "react"
import { Link }     from 'react-router-dom'
import styled       from "styled-components"

import * as arrow from "YConnect/Assets/svg/arrow-breadcrumb.svg"
import * as arrowPrevious from "YConnect/Assets/svg/arrow-breadcrumb-previous.svg"

const BreadcrumbStyle = styled.nav`
    background-color: transparent;
    padding: 0;

    ol {
        background-color: transparent;
        padding: 0;
    }
`
const BreadcrumbItemStyle = styled.li`
    font-size: .75rem;
    padding: 0;
    display: inline-block !important;

    a, span {
        color: ${props => props.theme.main.clickable};
        font-size: .75rem;
        font-weight: bold;
        display: inline-block;
        margin: -2em;
        padding: 2em;
        position: relative;
        text-decoration: none;
        z-index: 1;
    }

    .breadcrumb-arrow {
        padding: 0 0 0 5px;
    }

    &.active {
        color: ${props => props.theme.main.textColor};
    }

    &:not(:first-child)::before {
        content: "";
    }

    & + .breadcrumb-item::before {
        padding-right: 0;
    }
`



interface BreadcrumbProps { links: Array<{ id?: number, path: string, label: string }> }

const Breadcrumb = (props: BreadcrumbProps) => {
    const lastLink = props.links[props.links.length - 1]
    const lastPage = props.links[props.links.length - 2].path

    if (window.innerWidth < 768 && window.location.hash.includes('my-message/')) {
        return (
            <div className="row mb-3" style={{marginTop: '80px'}}>
                <div className="col-12">
                    <BreadcrumbStyle aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <BreadcrumbItemStyle className="breadcrumb-item d-flex align-items-center">
                                <img src={arrowPrevious} className="breadcrumb-arrow pr-3" />
                                <Link to="/my-message">
                                    Back
                                </Link>
                            </BreadcrumbItemStyle>
                        </ol>
                    </BreadcrumbStyle>
                </div>
            </div>
        )
    }

    if (window.innerWidth < 768 && window.location.hash.includes('countries/')) {
        return (
            <div className="row mb-3" style={{marginTop: '80px'}}>
                <div className="col-12">
                    <BreadcrumbStyle aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <BreadcrumbItemStyle className="breadcrumb-item d-flex align-items-center">
                                <img src={arrowPrevious} className="breadcrumb-arrow pr-3" />
                                <Link to="/travel-the-world">
                                    Back
                                </Link>
                            </BreadcrumbItemStyle>
                        </ol>
                    </BreadcrumbStyle>
                </div>
            </div>
        )
    }

    if (window.innerWidth < 768 && window.location.hash.includes('/manage-users')) {
        return (
            <div className="row mb-3" style={{marginTop: '80px'}}>
                <div className="col-12">
                    <BreadcrumbStyle aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <BreadcrumbItemStyle className="breadcrumb-item d-flex align-items-center">
                                <img src={arrowPrevious} className="breadcrumb-arrow pr-3" />
                                <Link to="/settings">
                                    Back
                                </Link>
                            </BreadcrumbItemStyle>
                        </ol>
                    </BreadcrumbStyle>
                </div>
            </div>
        )
    }

    if (window.innerWidth < 768 && lastPage) {
        return (
            <div className="row mb-3" style={{marginTop: '80px'}}>
                <div className="col-12">
                    <BreadcrumbStyle aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <BreadcrumbItemStyle className="breadcrumb-item d-flex align-items-center">
                                <img src={arrowPrevious} className="breadcrumb-arrow pr-3" />
                                <Link to={lastPage}>
                                    Back
                                </Link>
                            </BreadcrumbItemStyle>
                        </ol>
                    </BreadcrumbStyle>
                </div>
            </div>
        )
    }

    return (
        <div className="row" style={{margin: '30px 0 14px'}}>
            <div>
                <BreadcrumbStyle aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        {
                            props.links
                                .filter((link: any) => link !== lastLink)
                                .map((link: any, key) => {
                                    return (
                                        <BreadcrumbItemStyle key={key} className="breadcrumb-item">
                                            {link.path ?
                                                <Link to={link.path}>
                                                    {link.label}
                                                </Link>
                                                :
                                                <span>
                                                    {link.label}
                                                </span>
                                            }
                                            { !(key ==props.links.length -2 && lastLink.label =="") &&
                                                    <img src={arrow} className="breadcrumb-arrow" />
                                            }
                                        </BreadcrumbItemStyle>
                                    )
                                })
                        }
                        <BreadcrumbItemStyle className="breadcrumb-item active" aria-current="page">
                            {lastLink.label}
                        </BreadcrumbItemStyle>
                    </ol>
                </BreadcrumbStyle>
            </div>
        </div>
    )

}

export default Breadcrumb

import * as React from "react"
import { Link } from "react-router-dom"
import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg"

import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"

const OralTestLayout = styled.div`
    display: flex;
    flex-direction: row;
`;

const SpanGrade = styled.span `
    margin-top: 12px;
`;

export const CONFIG_TABLE_FINAL_SCORE: Array<any> = [
    {
        label: "Student",
        formatter: ({ nome_aluno, nome_curso }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span>{nome_aluno}</span>
                <span className="small text-secondary">{nome_curso}</span>
            </div>
        </div>
    },
    {
        label: "Final Test",
        formatter: ({ prova_Final_Resposta, pontuacao }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{prova_Final_Resposta ? prova_Final_Resposta.pontuacao : pontuacao } </span>
            </div>
        </div>
    },
    {
        label: "Number of Tries",
        formatter: ({ nro_tentativas }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{nro_tentativas}</span>
            </div>
        </div>
    },
    {
        label: "Written Test",
        formatter: ({ prova_Final_Resposta, pontuacaoOpenQuestion }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{prova_Final_Resposta ? prova_Final_Resposta.pontuacaoOpenQuestion : pontuacaoOpenQuestion}</span>
            </div>
        </div>
    },
    {
        label: "Oral Test",
        formatter: ({ prova_Final_Resposta, editFunction, pontuacaoOralTest, id_prova_final }: any) => <OralTestLayout className="d-flex">
            {/* <div className="d-flex flex-column mr-4"> */}
                <SpanGrade className="text-secondary">{prova_Final_Resposta ? prova_Final_Resposta.pontuacaoOralTest : pontuacaoOralTest}</SpanGrade>
                {
                    (prova_Final_Resposta || id_prova_final) &&
                    <ButtonStyle
                        green
                        label="Calculate Score"
                        className="ml-1 mt-1 w-45"
                        onClick={() => editFunction({id_prova_final})}
                    />
                }
            {/* </div> */}
        </OralTestLayout>
    },
    {
        label: "Final Score",
        formatter: ({ prova_Final_Resposta, pontuacaoFinal }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{prova_Final_Resposta ? prova_Final_Resposta.pontuacaoFinal : pontuacaoFinal}</span>
            </div>
        </div>
    },
    {
        label: "Details",
        formatter: ({ prova_Final_Resposta, id_curso, id_aluno, id_prova_final, id_prova_final_ultima_Tentativa }: any) =>
            <a href={`#/reports/view-report-final-test/${id_curso}/student/${id_aluno}/score/${id_prova_final}/`}>
                Consult this Test
                <img src={arrowRight} alt="" className="pl-2" />
            </a>
    },
]

const CONFIG_TABLE_REPORT_FINAL_SCORE: Array<ColumnConfigType> = [
    {
        label: "Unit",
        formatter: ({ unidade }: any) => `Unit ${unidade}`
    }
].concat(CONFIG_TABLE_FINAL_SCORE)

export default CONFIG_TABLE_FINAL_SCORE

import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAll = () => 
    API2
    .ExerciseType
    .GetAll({}, getConfig())

export const Create = (type:any) => 
    API2
    .ExerciseType
    .Create(type, getConfig())
    
export const UpdateById = (type:any) => 
    API2
    .ExerciseType
    .UpdateById(type, getConfig())

export const GetById = (typeId:any) => 
    API2
    .ExerciseType
    .GetById({typeId}, getConfig())

export const DeleteById = (typeId:any) => 
    API2
    .ExerciseType
    .DeleteById({typeId}, getConfig())
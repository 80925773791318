import styled      from "styled-components"
import SelectField from "YConnect/Fields/Forms/Select.field"

const SelectFieldStyle = styled(SelectField)`
            width: 100%;
            @media (min-width: 768px) {
                padding: 0 8px;
                width: 40%;
            }
`

export default SelectFieldStyle
import styled    from "styled-components"
import { Modal } from "react-bootstrap"

const ModalBodyUnlockStyle = styled(Modal.Body)`
    background-color: #fff;
    padding: 16px!important;
    text-align: center;

    .tag-img {
        align-items: center;
        background-color: ${props => props.theme.main.backgroundPage};
        border-radius: 100%;
        border: 16px solid ${props => props.theme.main.mainPurple};
        box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.11);
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        height: 204px;
        margin: 0 auto;
        position: relative;
        margin-top: -100px;
        width: 204px;
    }

    @media (min-width: 1024px) {
        h2 {
            margin-bottom: 30px;
        }
        p {
            margin-bottom: 10px;
        }
    }

    .footer-modal-tag {
        border-top: 1px solid #E0E0E0;
        display: flex;
        justify-content: flex-end;
        padding: 32px 0 16px;
    }
`

export default ModalBodyUnlockStyle
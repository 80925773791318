import styled from "styled-components"

const ProgressPercentStyle = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    height: 250px;
    padding: 18px;
    width: 100%;

    @media screen and (min-width: 1024px) {
        min-height: 293px;
        width: 55%;
    }

    small {
        color: #989898;
        font-size: 14px;
    }

    .percent-title {
        font-size: 42px;
        margin-top: 10px;
    }

    .percent-progress {
        left: calc(50% - 20px);
        margin-bottom: 14px;
        position: relative;
        width: 100%;
    }
    .percent-counter {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
`

export default ProgressPercentStyle
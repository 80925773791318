import * as React from "react"
import {
    useEffect, useState, useContext
} from "react"
import { Container, Row, Col, Card } from "reactstrap"
import styled from "styled-components"

import { BreadcrumbReportTeacher } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle from "YConnect/Components/PageTitle"
import UserContext from "YConnect/User.context"

import * as classGradeIcon from "YConnect/Assets/svg/class-grades.svg"
import * as preAndPostTest from "YConnect/Assets/svg/pre-and-post-test.svg"
import * as ReportByStudent from "YConnect/Assets/svg/reportbystudent.svg"


const ReportCardStyle = styled.div`
    align-items: center;
    border-bottom: 2px solid ${props => props.theme.main.clickable};
    color: ${props => props.theme.main.textColor};
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    text-decoration: none;
    @media (min-width: 768px) {
        height: 150px;
    }
    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }

    .report-type {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin-left: 20px;

        h4, p {
            margin: 0;
        }
    }

`

const ReportTeacherPage = () => {

    const { profiles }:any = useContext(UserContext)

    const [lastSearches, setLastSearches] = useState(JSON.parse(localStorage.getItem("lastSearches")))
    const [lastSearchesRB, setLastSearchesRB] = useState(JSON.parse(localStorage.getItem("lastSearchesRB")))

    const { idUsuario }: any = useContext(UserContext)

    useEffect(() => {
        if (!lastSearches) {
            const newLastSearches: any = {
                [idUsuario]: []
            }
            setLastSearches(newLastSearches)
            localStorage.setItem("lastSearches", JSON.stringify(newLastSearches))
        }
        if (!lastSearchesRB) {
            const newLastSearches: any = {
                [idUsuario]: []
            }
            setLastSearchesRB(newLastSearches)
            localStorage.setItem("lastSearchesRB", JSON.stringify(newLastSearches))
        }
    }, [])

    return <Container>
        <BreadcrumbReportTeacher/>
        <PageTitle label="Reports" />
        <Row>
            <Col xs={12} md={6}>
                <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/reports-class-grades"}>
                    <figure>
                        <img src={classGradeIcon} alt="" />
                    </figure>
                    <div className="report-type">
                        <h4>Class grades</h4>
                        <p>Review or send student's grades across courses and tasks.</p>
                    </div>
                </ReportCardStyle>
            </Col>

            <Col xs={12} md={6}>
                <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/pre-post-report"}>
                    <figure>
                        <img src={preAndPostTest} alt="" />
                    </figure>
                    <div className="report-type">
                        <h4>Pre and post test</h4>
                        <p>Review and compare student's score across Pre and Post Test.</p>
                    </div>
                </ReportCardStyle>
            </Col>

            <Col xs={12} md={6}>
                <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/report-resource-book"}>
                    <figure>
                        <img src={classGradeIcon} alt="" />
                    </figure>
                    <div className="report-type">
                        <h4>Resource book</h4>
                        <p>Review student's grades across resource book tasks.</p>
                    </div>
                </ReportCardStyle>
            </Col>

            {
                profiles.indexOf(6) > -1
                && <Col xs={12} md={6}>
                    <ReportCardStyle className="card shadow" onClick={() => window.location.href = "#/reports-by-student"}>
                        <figure>
                            <img src={ReportByStudent} alt="" />
                        </figure>
                        <div className="report-type">
                            <h4>Report search by student</h4>
                            <p>Find individual student reports.</p>
                        </div>
                    </ReportCardStyle>
                </Col>
            }


        </Row>

    </Container>

}


export default ReportTeacherPage

import styled from "styled-components"
import { Col } from "reactstrap"
import * as checked  from "YConnect/Assets/svg/checkedGreen.svg"
import * as remove   from "YConnect/Assets/svg/closeRed.svg"

const ExerciseQuestionStyle = styled(Col)`
    padding: 0 40px;

    @media screen and (max-width: 768px) {
        padding: 0px;
    }

    .exercise-dialogue-options {
        width: fit-content;
    }

    .btn-group-toggle {
        display: flex;
        align-items: center;
        flex-flow: column wrap;
        width: fit-content;
        border-radius: 25px !important;

        .btn-radio {
            align-items: center;
            border-radius: 25px;
            display: flex;
            flex-flow: row wrap;
            padding: 8px 24px;
            width: fit-content;
            margin: 5px;

            &:not(:last-child):not(.dropdown-toggle) {
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
            }
            &:not(:first-child) {
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
            }

            &:last-child {
                margin-bottom: 40px;
            }

            &.wrong {
                color: ${props => props.theme.main.errorColor2} !important;
                border-color: ${props => props.theme.main.errorColor2} !important;

                span {
                    margin-right: 10px;
                }

                &::after {
                    content: url(${remove});
                    margin-left: 20px;
                    position: absolute;
                    filter: ${props => props.theme.main.filterSvgGreen};
                    transform: scale(0.8);
                    right: 10px;
                    top: 10px;
                }
            }

            &.correct {
                color: ${props => props.theme.main.successColor2} !important;;
                border-color: ${props => props.theme.main.successColor2} !important;

                span {
                    margin-right: 15px;
                }

                &::after {
                    content: url(${checked});
                    margin-left: 20px;
                    position: absolute;
                    filter: ${props => props.theme.main.filterSvgGreen};
                    transform: scale(0.7);
                    right: 10px;
                    top: 7px;

                    @media screen and (max-width: 768px) {
                        transform: scale(0.7)
                    }
                }
            }
        }
    }
`
export default ExerciseQuestionStyle
import * as React            from "react"
import {useState, useEffect} from "react"

import Table     from "YConnect/Components/Table"
import API       from "YConnect/API"
import Loader    from "YConnect/Components/Loader"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import getUnitName from "YConnect/Utils/CourseUnitName.util"

import CONFIG_TABLE_REPORT_UNIT from "YConnect/Configs/Tables/ReportUnit.table.config"
import SelectFieldStyle from "YConnect/Styles/SelectField.style"

const ScoreUnitClass = ({idGrupo, idCurso, idUsuario}:any) => {

    const [report, setReport]           = useState([]);
    const [hasLoading, setHasLoading]   = useState(true);
    const [unitParam, setUnitParam]     = useState<any>("ALL");
    const [originalReport, setOriginal] = useState<any>();
    const [selectData, setSelectData] = useState<any>();
    const [units, setUnits] = useState<any>([]);

    useEffect(()=>{
        fetchUnits()
    }, [])

    useEffect(()=>{
        if (units.length !== 0){
            fetch()
            refreshSelectData();
        }
    }, [units])

    const fetch = async () => {
        const {data:{reportUnidade}} = await API.Report.GetByClass({idGrupo}, getConfig())

        insertUnitOnReport(reportUnidade);
        
        setReport(reportUnidade)
        setOriginal(reportUnidade)
        setHasLoading(false)
    }

    const fetchUnits = async () => {

        const { data } = await API.Course.getById({ 
            id_curso: idCurso, id_usuario: idUsuario, isDegustacao: false }, 
            getConfig());

        setUnits(data);

    }

    const insertUnitOnReport = (report: any) =>{
        
        report.forEach((itemReport: any, index:number) => {
            const unidadeObj = units.filter((item: any) => item.tipo_unidade === "BOOK_TASK" && 
                                                          item.ordem === itemReport.unidade)[0]; 

            report[index].nomeUnidade = unidadeObj.nome;
            report[index].idCurso = idCurso;
            
        });
    }

    const refreshSelectData = () => {
        const newSelectData = units.filter((item: any) => item.tipo_unidade === "BOOK_TASK").map((unit: any) => ({ 
            id: unit.ordem, 
            value: getUnitName(parseInt(idCurso), unit.ordem, unit.nome)
        }));
        setSelectData(newSelectData)
    }

    const handleChangeFilter = (keystone:string, value:any) => {
        if(value === "" || value === "ALL") {
            setReport(originalReport)
        } else {
            setReport(originalReport.filter((unit:any) => parseInt(unit.unidade, 10) === parseInt(value, 10)))
        }
        setUnitParam(value);
    }
    
    return <div className="score">
            {
                report && report.length > 0 && <header className="score-header ">
                    <div className="w-100 d-flex justify-content-end">
                        <div className="score-filter">
                            <span className="filter-label  pr-5">Filter by</span>
                            <SelectFieldStyle
                                keystone = "unit"
                                label    = "Unit"
                                value    = {unitParam}
                                onChange = {handleChangeFilter}
                                values={[
                                    { id: "ALL", value: "All units" }, ...selectData
                                ]}
                                hasOptionEmpty={true}
                            />
                        </div>
                    </div>
                </header>
            }
            {
                report && report.length > 0
                ? <Table
                data={report}
                config={CONFIG_TABLE_REPORT_UNIT} />
                : <p className="text-center m-3">No data found</p> 
            }
            {hasLoading && <Loader/>}
        </div>
}

export default ScoreUnitClass
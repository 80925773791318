
import BooksPanel              from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Books.panel"
import LevelsPanel             from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Levels.panel"
import CoursesPanel            from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Courses.panel"
import UnitsPanel              from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Units.panel"
import SectionsPanel           from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Sections.panel"
import LearningObjectivesPanel from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/LearningObjectives.panel"
import ExerciseTypesPanel      from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/ExerciseTypes.panel"
import ExercisesPanel          from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Exercises.panel"
import TemplatesPanel          from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Templates.panel"
import QuestionsPanel          from "YConnect/PagesComponents/ControlPanel/Panels/CRUD/Questions.panel"

const LIST_PANEL_CONFIG:Array<ConfigPanelType> = [
    {
        keystone: "BOOKS",
        label: "Books",
        path: "/books",
        component: BooksPanel
    },
    {
        keystone: "LEVELS",
        label: "Levels",
        path: "/levels",
        component: LevelsPanel
    },
    {
        keystone: "COURSES",
        label: "Courses",
        path: "/courses",
        component: CoursesPanel
    },
    {
        keystone: "UNITS",
        label: "Units",
        path: "/units",
        component: UnitsPanel
    },
    {
        keystone: "SECTIONS",
        label: "Sections",
        path: "/sections",
        component: SectionsPanel
    },
    {
        keystone: "LEARNING-OBJECTIVES",
        label: "Learning Objectives",
        path: "/learning-objectives",
        component: LearningObjectivesPanel
    },
    {
        keystone: "EXERCISE-TYPES",
        label: "Exercise Types",
        path: "/exercise-types",
        component: ExerciseTypesPanel
    },
    {
        keystone: "EXERCISES",
        label: "Exercises",
        path: "/exercises",
        component: ExercisesPanel
    },
    {
        keystone: "TEMPLATES",
        label: "Templates",
        path: "/templates",
        component: TemplatesPanel
    },
    {
        keystone: "QUESTIONS",
        label: "Questions",
        path: "/questions",
        component: QuestionsPanel
    }
]

export default LIST_PANEL_CONFIG
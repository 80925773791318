
import * as ReactGA from "react-ga"

import gaTrackingCode from "YConnect/Configs/Others.config"

import gtmTrackingCode from "YConnect/Configs/Others.config"

import TagManager from 'react-gtm-module'
const { PROFILE } = server.env
const ENV = 'DEPLOY';

const tagManagerArgs = {
    gtmId: gtmTrackingCode.GTM_TRACKING_CODE
}
if (PROFILE == ENV) {
    ReactGA.initialize(gaTrackingCode.GA_TRACKING_CODE)

    TagManager.initialize(tagManagerArgs)
}
const PageView = () => {
    if (PROFILE == ENV) {
        ReactGA.set({ page: window.location.hash })
        ReactGA.pageview(window.location.hash)
    }
}

export default PageView
import * as React              from "react"
import { useState, useEffect } from "react"

import * as arrowDownIcon from "YConnect/Assets/svg/arrow-down.svg"
import AudioPlayer        from "YConnect/Components/AudioPlayer"
import RetractPlayerStyle from "YConnect/Styles/RetractPlayer.style"
import getStaticUrl       from "YConnect/Utils/GetStaticUrl.util"

const addHeadingZero = (num: any) => (num > 9 ? num.toString() : `0${num}`)

interface RetractPlayerProps {
    audioFiles       : any
    audioPlaying    ?: any
    onToggleRetract ?: Function
    onPlaying        : Function
}

const RetractPlayer = ({
    audioFiles,
    audioPlaying,
    onToggleRetract,
    onPlaying
}: RetractPlayerProps) => {
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        if (audioPlaying.status === "ENDED") {
            const itemPlaying = audioFiles.find(({ title }: any) => title === audioPlaying.title);
            if (itemPlaying) {
                if (audioFiles.indexOf(itemPlaying) > -1 &&
                    audioFiles.indexOf(itemPlaying) + 1 < audioFiles.length) {
                    const nextPlaying = audioFiles[audioFiles.indexOf(itemPlaying) + 1];
                    onPlaying({
                        title: nextPlaying.title,
                        currentTime: 0,
                        src: getStaticUrl(nextPlaying.name_File),
                        status: "PLAYING",
                    });
                }
            }
        }
    }, [audioPlaying])

    return <RetractPlayerStyle>
            <div className="retract-arrow pointer" onClick={onToggleRetract}>
                <img src={arrowDownIcon} className="rotate" id="js-rotate-icon" />
            </div>
            <div className="retract-audio">
                <div className="retract-header">
                    <div className="marquee">
                        <p>{audioPlaying.title}</p>
                    </div>
                </div>
                <div className="retract-options">
                     <AudioPlayer
                        isPlayingCurrent
                        hasNextButton
                        autoPlay
                        audioPlaying = {audioPlaying}
                        src          = {audioPlaying.src}
                        onDuration   = {setDuration}
                        onNext       = {() => onPlaying({...audioPlaying, status:"ENDED"})}
                        onChangeTime = {
                            (currentTime: number) =>
                            onPlaying({...audioPlaying, currentTime})
                        }
                        onEnded      = { () => onPlaying({...audioPlaying, status:"ENDED"})}/>
                </div>
            </div>
        </RetractPlayerStyle>
    
}

export default RetractPlayer
import * as React                        from "react"
import {useState, useEffect, useContext} from "react"
import {Container, Row}                  from "reactstrap"

import { BreadcrumbUnlockScore,addBreadcrumbData } from "YConnect/Components/BreadCrumbList.tsx"

import API                 from "YConnect/API"
import ViewReportContainer from "YConnect/Containers/ViewReport.container"
import SelectField         from "YConnect/Fields/Forms/Select.field"
import { ButtonStyle }     from "YConnect/Components/Button"
import Loader              from "YConnect/Components/Loader"
import UserContext         from "YConnect/User.context"
import Toastify from "toastify-js"

const UnlockScorePage = ({match}:any) => {

    const [courses, setCourses]         = useState()
    const [selectData, setSelectData]   = useState()
    const [courseParam, setCourseParam] = useState()
    const [hasLoading, setHasLoading]   = useState(true)

    const {idUsuario}:any               = useContext(UserContext)
    const {idAluno} = match.params

    addBreadcrumbData([{ page:'UnlockScorePage', data:{ idAluno }}])

    useEffect(() => {
        setHasLoading(true)
        API.Usuario.Buscar({idAluno})
        .then(({data}:any) => setCourses(data))
        .finally(() => setHasLoading(false))
    }, [])

    useEffect(()=>{
        if(courseParam) setHasLoading(false)
    }, [courseParam])

    useEffect(()=>{
        if(courses) {
            const uniqueCourses = courses.reduce((unique: any, o: any) => {
                if (!unique.some((obj: any) => obj.idCurso === o.idCurso)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            setSelectData(uniqueCourses.map(({idCurso, nomeCurso}:any) => ({id:idCurso, value:nomeCurso})))
        }
    }, [courses])

    useEffect(()=>{
        if(selectData && selectData.length > 0) setCourseParam(selectData[0].id)
        if(selectData && selectData.length === 0) setHasLoading(false)
    }, [selectData])

    const handleChangeFilter = (keystone:string, value:any) => {
        const sets: any = {
            course  : setCourseParam
        }
        if(sets[keystone]) sets[keystone](value)
    }

    const handleUnlock = async () => {
        try{
            setHasLoading(true)
            await API.DecongelarNotas.Put({
                codigoCurso       : courseParam,
                codigoResponsavel : idUsuario,
                codigoUsuario     : idAluno
            })
            Toastify({
                text: "Score unlocked successfully!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }catch({response}){
            Toastify({
                text: "Error trying to unlock student grade!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }finally{
            setHasLoading(false)
        }
    }

    return  <Container>
                <BreadcrumbUnlockScore/>
                {courseParam && <ViewReportContainer 
                    disabledDonwnload
                    disabledPrint
                    idCurso = {courseParam} 
                    idAluno = {idAluno}
                    filterComponent = {
                        <Row className="flex-row flex-nowrap">
                            <SelectField 
                                keystone       = "course"
                                label          = "Course"
                                className      = "unlock-filter"
                                value          = {courseParam}
                                onChange       = {handleChangeFilter} 
                                values         = {selectData}
                                hasOptionEmpty = {true}/>
                            <div className="btn-fixed d-flex justify-content-end">
                                <ButtonStyle green
                                    className = "ml-3"
                                    label     = "Unlock scores"
                                    icon      = "unlock"
                                    onClick   = {handleUnlock}/>
                            </div>
                        </Row>
                    }/>}
                {hasLoading && <Loader/>}
            </Container>
}

export default UnlockScorePage
import * as React                          from "react"
import { useEffect, useState, useContext } from "react"
import { Tab }                             from "react-bootstrap"
import { Col, Container, Row, FormGroup }  from "reactstrap"

import styled from "styled-components"

import API              from "YConnect/API"
import MapComponent     from "YConnect/Components/MapComponent"
import Loader           from "YConnect/Components/Loader"
import getBaseURLWebApi from "YConnect/Utils/GetBaseURLWebAPI.util"
import UserContext      from "YConnect/User.context"
import PageTitle        from "YConnect/Components/PageTitle"
import { BreadcrumbCountry } from "YConnect/Components/BreadCrumbList"
import { ButtonStyle }  from "YConnect/Components/Button"
import ProfilePicture   from "YConnect/Components/ProfilePicture"
import getConfig        from "YConnect/Utils/GetRequestConfig.util"
import AllTestimonials  from "YConnect/Modals/AllTestimonials.modal"
import Testimonials     from "YConnect/PagesComponents/Country/Testimonials"
import getStaticUrl     from "YConnect/Utils/GetStaticUrl.util"
import convertPhoto     from "YConnect/Utils/ConvertPhoto.util"
import TabStyle         from "YConnect/Styles/Tab.style"

import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg"
import * as countryPlaceholder from "YConnect/Assets/png/placeholder-countries.png"

import PeopleIconSVG             from "YConnect/PagesComponents/Country/SVG/PeopleIcon.svg"
import GastronomyIconSVG         from "YConnect/PagesComponents/Country/SVG/GastronomyIcon.svg"
import MusicIconSVG              from "YConnect/PagesComponents/Country/SVG/MusicIcon.svg"
import TouristAttractionsIconSVG from "YConnect/PagesComponents/Country/SVG/TouristAttractionsIcon.svg"
import CuriositiesIconSVG        from "YConnect/PagesComponents/Country/SVG/CuriositiesIcon.svg"
import Toastify from "toastify-js"

const AlignedImages = styled.div`
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.11) !important;
    display: flex;
    flex-flow: row wrap;
    height: 90px;
    justify-content: center;
    position: absolute;
    margin-top: -71px;
    right: 20%;
    width: 90px;

    @media screen and (min-width: 360px) and (max-width: 767px) {
        margin-top: 89px;
        right: calc(28% - 17px);
        width: 90px;
    }

    @media screen and (min-width: 768px){
        margin-top: 89px;
        right: calc(33% - 17px);
        width: 90px;
    }

    @media screen and (min-width: 992px){
        margin-top: 89px;
        right: calc(37% - 14px);
        width: 90px;
    }

    @media screen and (min-width: 1024px) {
        margin-top: 152px;
        right: calc(55% - 17px);
        width: 90px;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 152px;
        right: calc(59% - 17px);
        width: 90px;
    }
`

const ICON_TAGS = {
    "People": <div className="tabheader-icon">
        <PeopleIconSVG/>
        <span>People</span>
    </div>,
    "Gastronomy": <div className="tabheader-icon">
        <GastronomyIconSVG/>
        <span>Gastronomy</span>
    </div>,
    "Music": <div className="tabheader-icon">
        <MusicIconSVG/>
        <span>Music</span>
    </div>,
    "Tourist Attractions": <div className="tabheader-icon">
        <TouristAttractionsIconSVG/>
        <span>Tourism</span>
    </div>,
    "Curiosities": <div className="tabheader-icon">
        <CuriositiesIconSVG/>
        <span>Curiosities</span>
    </div>
}

const CountryPage = ({
    history,
    match,
    onCheckTag
}: any) => {
    const { idUsuario, profiles, foto, nome, nomeCompleto, idCursoAtual }: any = useContext(UserContext)

    const [isLoading, setLoading]                 = useState(true)
    const [isLoadingBackpack, setLoadingBackpack] = useState(true)
    const [currentCountry, setCurrentCountry]     = useState()
    const [backpack, setBackpack]                 = useState()
    const [mapProps, setMapProps]                 = useState()
    const [backFree, setBackFree]                 = useState()

    const [loadingTestimonials, setLoadingTestimonials] = useState()
    const [testimonials, setTestimonials]               = useState()
    const [testimonialMessage, setTestimonialMessage]   = useState()
    const [allTestimonials, setAllTestimonials]         = useState()

    const [showAll, setShowAll]         = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [loadingMore, setLoadingMore] = useState(false)

    useEffect(() => {
        if (history.location.state !== undefined && history.location.state.selectedCountry) {
            const { selectedCountry } = history.location.state
            setCurrentCountry(selectedCountry)
            fetchCountry()
        } else {
            fetchCountry()
        }
        fetchTestimonials()

    }, [])


    useEffect(()=>{

        if(!isLoadingBackpack && backFree && backFree.length > 0){
            handleChangeTagTab(backFree[0].tag.tipo_tag.nome)
            changePosition()
        }
    }, [isLoadingBackpack, backFree])

    const fetchCountry = async () => {
        setLoading(true)
        try {
            const result = await API.Stamp.getStampById({idStamp: match.params.id}, getConfig())
            const mapProps = {
                options: {
                    center: { lat: result.data.latitude, lng: result.data.longitude },
                    zoom: result.data.zoom,
                    disableDefaultUI: false
                },
            }
            setMapProps(mapProps)
            setCurrentCountry(result.data)
            fetchBackpack()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const fetchBackpack = async () => {
        setLoadingBackpack(true)
        try {
            const result = await API.Tag.getTagById({
                idAluno   : idUsuario,
                idStamp   : match.params.id,
                isAluno   : profiles.indexOf(15) > -1 ? false : true,
                myTravels : true
            }, getConfig())
            setBackFree(result.data.filter((tag: any) => tag.liberada === true))
            setBackpack(result.data)
            setLoadingBackpack(false)
        } catch (error) {
            setLoadingBackpack(false)
        }
    }

    const fetchTestimonials = async () => {
        setLoadingTestimonials(true)
        try {
            const result = await API.Comentario.GetAll({
                id_stamp   : match.params.id,
                id_usuario : idUsuario,
                pagina     : currentPage
            }, getConfig())
            setTestimonials(window.innerWidth >= 1024 ? result.data.slice(0,1) : result.data.slice(0,4))
            setAllTestimonials(result.data)
        } catch (error) {}
        setLoadingTestimonials(false)
    }

    const handleShareTestimonial = async () => {
        setLoadingTestimonials(true)
        const formValues = {id_usuario: idUsuario, id_stamp: match.params.id, comentario: testimonialMessage}
        API.Comentario.Post(formValues, getConfig())
        .then((res: any) => {
            const newTestimonial = {
                nome            : nomeCompleto,
                data_comentario : new Date(),
                num_curtidas    : 0,
                comentario      : testimonialMessage,
                id_usuario      : idUsuario,
                foto            : foto,
                id_comentario   : res.data.id_comentario
            }
            setTestimonials(window.innerWidth >= 1024 ? [newTestimonial] : [newTestimonial, ...testimonials])
            setTestimonialMessage('')
            onCheckTag({
                idAluno    : idUsuario,
                id         : match.params.id,
                idSituacao : 4,
                idCurso    : idCursoAtual
            })
            if (window.innerWidth < 1024) {
                window.scrollTo(0, window.pageYOffset - window.innerHeight);
            }
            Toastify({
                text: "Success! <br> Testimonial added with success!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
        .finally(() => setLoadingTestimonials(false))
    }

    const loadMore = async () => {
        setLoadingMore(true)
        try {
            const result = await API.Comentario.GetAll({
                id_stamp   : match.params.id,
                id_usuario : idUsuario,
                pagina     : currentPage + 1
            }, getConfig)
            setAllTestimonials([...allTestimonials, ...result.data])
            setCurrentPage(currentPage + 1)
            setLoadingMore(false)
        } catch (error) {
            setLoadingMore(false)
        }
    }

    const handleChangeTagTab = (tagName:string)=>{

        const {id_tag} = (backpack || [])
                        .map(({tag:{id_tag, tipo_tag:{nome}}}:any) => ({id_tag, nome}))
                        .find(({nome}:any) => nome === tagName)

        onCheckTag({
            idAluno    : idUsuario,
            id         : id_tag,
            idSituacao : 5,
            idCurso    : idCursoAtual
        })
    }

    const orderCategories = (value) => {
        if (value.props.eventKey === 'People') {
            return -1
        }
    }

    const changePosition = () => {
        const changePositionCategories = (arr, from, to) => {
            arr.splice(to, 0, arr.splice(from, 1)[0]);
            return arr;
        }

        let arr = backpack;

        if (arr[3].tag.tipo_tag.nome === 'Music') {
            arr = changePositionCategories(arr, 2, 3);
        }

        if (arr[4].tag.tipo_tag.nome === 'Tourist Attractions') {
            arr = changePositionCategories(arr, 4, 3);
        }
    }

    return !isLoading
    ? <>
        <Container className='countrypage'>
            <BreadcrumbCountry country={currentCountry.nome_pais}/>
            <PageTitle label={currentCountry.nome_pais} />

            <Row className="">
                <Col xs="12">
                    <div className="card shadow">
                        <p dangerouslySetInnerHTML={{ __html: currentCountry.curiosidade }} className={'country-curiosity'}></p>

                        {
                            window.innerWidth < 1024
                                ? <div className="country-about">
                                    <div className="w-100 about-img-and-stamp">
                                        <img src={`${getBaseURLWebApi()}/assets/${currentCountry.img_bandeira}`} alt="" className="country-about-img" />
                                        <div className="about-img-stamp">
                                            <img src={getStaticUrl(`assets/${currentCountry.img_stamp}`)} />
                                        </div>
                                    </div>
                                    <h3 className="country-about-subtitle">About the country</h3>
                                    <p className="country-about-description" dangerouslySetInnerHTML={{ __html: currentCountry.descricao }}></p>

                                    <h3 className="country-about-subtitle">Capital</h3>
                                    <p className="country-about-description">{currentCountry.nome_capital}</p>

                                    <h3 className="country-about-subtitle">Population</h3>
                                    <p className="country-about-description">{currentCountry.populacao}</p>

                                    <h3 className="country-about-subtitle">Where it is</h3>
                                    <div className="country-about-map">
                                        <MapComponent {...mapProps} />
                                    </div>

                                </div>
                                : <div className="country-about-desktop">

                                    <div className='w-100'>
                                        <img src={`${getBaseURLWebApi()}/assets/${currentCountry.img_bandeira}`} alt="" className="country-about-img" />
                                        <div className="about-img-stamp">
                                            <img src={getStaticUrl(`assets/${currentCountry.img_stamp}`)} />
                                        </div>
                                    </div>

                                    <div className="about-country">
                                        <h3 className="country-about-subtitle">About the country</h3>
                                        <p className="country-about-description" dangerouslySetInnerHTML={{ __html: currentCountry.descricao }}></p>

                                        <h3 className="country-about-subtitle">Capital</h3>
                                        <p className="country-about-description">{currentCountry.nome_capital}</p>

                                        <h3 className="country-about-subtitle">Population</h3>
                                        <p className="country-about-description">{currentCountry.populacao}</p>
                                    </div>

                                    <div className="about-map">
                                        <h3 className="country-map-subtitle">Where it is</h3>
                                        <div className="country-about-map">
                                            <MapComponent {...mapProps} />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </Col>
            </Row>

            <Row >
                <Col className="tags">
                    {!isLoadingBackpack
                        && <TabStyle defaultActiveKey={`${!isLoadingBackpack && backFree.length ? backFree[0].tag.tipo_tag.nome : ''}`} id="tags-tab" onSelect={handleChangeTagTab}>
                            {
                                backpack && backpack.map((tag: any, key: any) => {
                                    const img = new Image();
                                    img.src = `${getBaseURLWebApi()}/assets/${tag.tag.midia}`

                                    return <Tab eventKey={tag.tag.tipo_tag.nome} title={ICON_TAGS[tag.tag.tipo_tag.nome]} disabled={!tag.liberada} key={key}>
                                        <div className="countrytab">
                                            <div className="countrytab-content1">
                                                {
                                                    tag.tag.midia && tag.tag.midia.includes('.mp4')
                                                        ? <video className="img-fluid" controls preload="false">
                                                            <source src={`${getBaseURLWebApi()}/assets/${tag.tag.midia}`} type="video/mp4" />
                                                        </video>
                                                        : tag.tag.midia !== null
                                                            ? [
                                                                (img.naturalWidth > img.naturalHeight
                                                                    ? <img src={`${getBaseURLWebApi()}/assets/${tag.tag.midia}`} alt="" className="countrytab-tag-padronizing bigger-width"/>
                                                                    : <img src={`${getBaseURLWebApi()}/assets/${tag.tag.midia}`} alt="" className="countrytab-tag-padronizing bigger-height" />
                                                                ), ]
                                                            : <img src={countryPlaceholder} style={{ filter: `invert(0.2)`, maxHeight: '355px', marginTop: '20px    '}} alt="" className="countrytab-tag-padronizing default-image" />
                                                }
                                                {
                                                    img.naturalWidth > img.naturalHeight
                                                        ? <div className="countrytab-tag" >
                                                            <img src={`${getBaseURLWebApi()}/assets/${tag.tag.img_tag}`} alt="" className="countrytab-picture" />
                                                          </div>
                                                        : [
                                                            (tag.tag.midia && tag.tag.midia.includes('.mp4')
                                                                ? <AlignedImages className="video-tab">
                                                                        <img src={`${getBaseURLWebApi()}/assets/${tag.tag.img_tag}`} alt="" className="countrytab-picture" />
                                                                    </AlignedImages>
                                                                : <AlignedImages>
                                                                    <img src={`${getBaseURLWebApi()}/assets/${tag.tag.img_tag}`} alt="" className="countrytab-picture" />
                                                                </AlignedImages>
                                                        ), ]
                                                }
                                            </div>
                                            <div className="countrytab-content2">
                                                <h3 className="countrytab-title">{tag.tag.tipo_tag.nome} on {currentCountry.nome_pais}</h3>
                                                <p dangerouslySetInnerHTML={{ __html: tag.tag.descricao }}></p>
                                            </div>
                                        </div>
                                    </Tab>
                                }).sort(orderCategories)
                            }
                        </TabStyle>
                    }
                </Col>
            </Row>

            <Row>
                <Col xs={12} lg={6}>
                    <h2>What our students have to say about <br className="d-none d-lg-block"/> {currentCountry.nome_pais}</h2>
                    {currentCountry && testimonials && <section className="d-flex lateral-scroll-books testimonials">
                        {
                            !loadingTestimonials && currentCountry && testimonials.map((testimonial: any, key: any) =>
                                <Testimonials
                                    testimonial        = {testimonial}
                                    updateTestimonials = {fetchTestimonials}
                                    classNameCard      = "card shadow"
                                    currentCountry     = {currentCountry}
                                    key                = {key}
                                />)
                        }
                    </section>}
                    <p className="text-clickable text-right pointer mt-3" onClick={() => setShowAll(true)}>
                        View all
                        <img src={arrowRight} alt="" className="pl-2" />
                    </p>
                    {allTestimonials && currentCountry &&
                        <AllTestimonials
                            isShow              = {showAll}
                            onClose             = {() => setShowAll(!showAll)}
                            testimonials        = {allTestimonials}
                            updateTestimonials  = {fetchTestimonials}
                            loadMore            = {loadMore}
                            loadingTestimonials = {loadingTestimonials}
                            loadingMore         = {loadingMore}
                            currentCountry      = {currentCountry}
                        />
                    }

                    {
                        !(allTestimonials && currentCountry)
                        && <>
                            <div className="loader-bg"></div>
                            <div className="loader"></div>
                        </>
                    }
                </Col>
                <Col xs={12} lg={6}>
                    <h2>How about you? What do you know and what do you think about {currentCountry.nome_pais}?</h2>
                    <div className="share-card card shadow">
                        <ProfilePicture
                            picture   = {convertPhoto(foto)}
                            user      = {nome}
                            border    = "0"
                            height    = {40}
                            width     = {40}
                            className = "mr-3"
                        />
                        <FormGroup>
                            <label className="form-label" htmlFor="messageInput">Testimonial</label>
                            <textarea
                                id          = "messageInput"
                                className   = "form-control"
                                style       = {{ height: "210px" }}
                                onChange    = {(e: any) => setTestimonialMessage(e.target.value)}
                                value       = {testimonialMessage}
                                placeholder = "Enter your testimonial"
                                rows        = {5}
                                maxLength   = {140}
                            ></textarea>
                        </FormGroup>
                    </div>
                    <footer className="share-footer">
                        <ButtonStyle green
                            size      = "medium"
                            label     = "Share your testimonial"
                            icon      = "share"
                            className = "share-btn"
                            maxWidth  = {true}
                            disabled  = {!testimonialMessage}
                            onClick   = {handleShareTestimonial}
                        />
                    </footer>
                </Col>
            </Row>

        </Container>
    </>
    : <Loader />

}

export default CountryPage



import * as React             from "react"
import {useEffect, useState}  from "react"
import {Container, Card}      from "reactstrap"
import qs                     from "querystring"

import * as yconnectIcon from "YConnect/Assets/png/favicon-256x256.png"
import API               from "YConnect/API"
import getConfig         from "YConnect/Utils/GetRequestConfig.util"

const RedirectPage = ({location}:any) => {

        const queryParams = qs.parse(location.search.substr(1))

        const [link, setLink] = useState()
        
        useEffect(()=>{
            if(queryParams && queryParams.link) setLink(queryParams.link)
        }, [queryParams])

        useEffect(()=>{
            if(link) redirect()
        }, [link])

        const redirect = () => {
            API.UrlExterna.Get({id: link}, getConfig())
            .then(({data}:any) =>{
                window.location = data
            })
        }
        
        return <Container className="py-4">
                    <Card className="text-center">
                        <p><img src={yconnectIcon} className="mb-3"/></p>
                        <h1>Please, wait a moment</h1>
                        <p>You are being redirected.</p>
                    </Card>
                </Container>
    
}

export default RedirectPage



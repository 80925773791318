
import * as React                          from "react"
import { useContext, useState, useEffect } from "react"

import * as heart      from "YConnect/Assets/svg/heart.svg"
import * as heartLiked from "YConnect/Assets/svg/heart-liked.svg"
import UserContext     from "YConnect/User.context"
import API             from "YConnect/API"
import getConfig       from "YConnect/Utils/GetRequestConfig.util"
import { ButtonStyle } from "YConnect/Components/Button"
import ProfilePicture  from "YConnect/Components/ProfilePicture"
import convertPhoto    from "YConnect/Utils/ConvertPhoto.util"
import formatDate      from "YConnect/Utils/FormatDate.util"
import { DropdownButton, Dropdown } from "react-bootstrap"
import Toastify from "toastify-js"

type TestimonialsProps = {
    testimonial         : any
    classNameCard?      : string
    updateTestimonials  : Function
    currentCountry      : any
}

//TODO Analisar o que é isso 
const Testimonials = ({ testimonial, updateTestimonials, classNameCard, currentCountry }: TestimonialsProps) => {

    const { idUsuario, profiles }: any = useContext(UserContext)

    const [amountLike, setAmountLike] = useState("")
    const [isLiked, setLiked]         = useState()

    useEffect(() => {
        setAmountLike(testimonial.num_curtidas);
        setLiked(testimonial.curtiu)
    }, [])

    const _likePhoto = async (idComentario: number) => {
        const formValues = { id_usuario: idUsuario, id_comentario: idComentario };
        API.Curtida.Put(formValues, getConfig())
        .then(() => {
            setLiked(isLiked ? 0 : 1)
            setAmountLike(isLiked ? `${parseInt(amountLike, 10) - 1}` : `${parseInt(amountLike, 10) + 1}`);
        })
        .finally(() => {})
    }

    const _deleteTestimonial = (idComentario: number) => {
        API.Comentario.Delete({
            id_comentario : idComentario,
            id_teacher    : idUsuario,
            remove        : "method: DELETE",
        }, getConfig())
        .then((res: any) => {
            updateTestimonials()
        })
    }

    
    const _reportTestimonial = (idComentario: number) => {
        const formValues = {IdUsuario: idUsuario, IdStamp: currentCountry.id_stamp, IdComentario: idComentario, NomeStamp: currentCountry.nome_pais}
        API.Comentario.Put(formValues, getConfig())
        .then((res: any) => {
            Toastify({
                text: "Success <br> Testimonial reported!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }).catch((err: any) => {
            Toastify({
                text: "Error! <br> You already reported this testimonial!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        })
    }


    return (
        <div className={`testimonial-card ${classNameCard}`} key={testimonial.id_comentario}>
            <header>
                <ProfilePicture
                    picture={convertPhoto(testimonial.foto)}
                    user={`${testimonial.nome} ${testimonial.sobrenome}`}
                    border={"0"}
                    height={40}
                    width={40}
                />
                <div className="testimonial-author">
                    <span className="author-name">{testimonial.nome} {testimonial.sobrenome}</span>
                    <small className="author-date">{formatDate(testimonial.data_comentario)}</small>
                </div>
                <div className="testimonial-likes">
                    <span className="testimonial-like pointer" onClick={() =>_likePhoto(testimonial.id_comentario)}>
                        {isLiked
                            ? <>Dislike<img src={heartLiked} ></img></>
                            : <>Like<img src={heart} style={{marginRight: "15px"}}></img></>
                        }
                    </span>
                    <span className="testimonial-amount">{amountLike}</span>
                </div>

            </header>
            <p className="testimonial-text">{testimonial.comentario}</p>
            <footer>
                {
                    window.innerWidth < 1024
                        ? <DropdownButton
                            alignRight
                            drop="down"
                            key="down"
                            title="Actions +"
                            id={`${testimonial.id_comentario}`}
                            className="testimonial-dropdown">
                            <Dropdown.Item eventKey="4">
                                {
                                    profiles.indexOf(30) > -1
                                    || profiles.indexOf(12) > -1
                                    || testimonial.id_usuario === idUsuario
                                        ? <ButtonStyle
                                            className="mb-0 mr-3"
                                            label="Delete"
                                            icon="remove"
                                            maxWidth={true}
                                            size={'medium'}
                                            onClick={() => _deleteTestimonial(testimonial.id_comentario)}
                                        />
                                        : null
                                }
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="4">
                                <ButtonStyle
                                    size={'medium'}
                                    label="Report"
                                    icon="warning"
                                    className={'testimonial-btn'}
                                    maxWidth={true}
                                    onClick={() => _reportTestimonial(testimonial.id_comentario)}
                                />

                            </Dropdown.Item>
                        </DropdownButton>
                        : <>
                            {
                                profiles.indexOf(30) > -1
                                    || profiles.indexOf(12) > -1
                                    || testimonial.id_usuario === idUsuario
                                    ? <ButtonStyle
                                        className="mb-0 mr-1"
                                        label="Delete"
                                        icon="remove"
                                        size={'medium'}
                                        maxWidth={true}
                                        onClick={() => _deleteTestimonial(testimonial.id_comentario)}
                                    />
                                    : null
                            }
                            <ButtonStyle
                                size={'medium'}
                                label="Report"
                                icon="warning"
                                className={'testimonial-btn'}
                                maxWidth={true}
                                onClick={() => _reportTestimonial(testimonial.id_comentario)}
                            />
                        </>
                }
                
            </footer>
        </div>
    )
}

export default Testimonials;
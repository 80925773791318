import * as React from "react"
import { Link } from "react-router-dom"
import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg"

import { ButtonStyle } from "YConnect/Components/Button"

export const CONFIG_TABLE_FINAL_SCORE: Array<any> = [
    {
        label: "Final Test",
        formatter: ({ prova_Final_Resposta, pontuacao }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{prova_Final_Resposta ? prova_Final_Resposta.pontuacao : pontuacao } </span>
            </div>
        </div>
    },
    {
        label: "Number of Tries",
        formatter: ({ nro_tentativas }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{nro_tentativas}</span>
            </div>
        </div>
    },
    {
        label: "Written Test",
        formatter: ({ prova_Final_Resposta, pontuacaoOpenQuestion }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{prova_Final_Resposta ? prova_Final_Resposta.pontuacaoOpenQuestion : pontuacaoOpenQuestion}</span>
            </div>
        </div>
    },
    {
        label: "Oral Test",
        formatter: ({ prova_Final_Resposta, editFunction, pontuacaoOralTest, id_prova_final }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{prova_Final_Resposta ? prova_Final_Resposta.pontuacaoOralTest : pontuacaoOralTest}</span>
                {/* {
                    (prova_Final_Resposta || id_prova_final) &&
                    <ButtonStyle
                        green
                        label="Calculate Score"
                        className="mt-3"
                        onClick={() => editFunction({id_prova_final})}
                    />
                } */}
            </div>
        </div>
    },
    {
        label: "Final Score",
        formatter: ({ prova_Final_Resposta, pontuacaoFinal }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span className="text-secondary">{prova_Final_Resposta ? prova_Final_Resposta.pontuacaoFinal : pontuacaoFinal}</span>
            </div>
        </div>
    },
    {
        label: "Details",
        formatter: ({ id_curso, id_prova_final, id_aluno, id_prova_final_ultima_Tentativa }: any) => 
            <a href={`#/reports/view-report-final-test/${id_curso}/student/${id_aluno}/score/${id_prova_final}`}>
                Consult this Test
                <img src={arrowRight} alt="" className="pl-2" />
            </a>
    },
]

const CONFIG_TABLE_REPORT_FINAL_SCORE: Array<ColumnConfigType> = [
    {
        label: "Unit",
        formatter: ({ unidade }: any) => `Unit ${unidade}`
    }
].concat(CONFIG_TABLE_FINAL_SCORE)

export default CONFIG_TABLE_FINAL_SCORE

import * as React            from "react"
import { useEffect, useRef } from "react"
import { CSSTransition }     from "react-transition-group"
import {Fragment}            from "react"

import * as downloadIcon from "YConnect/Assets/svg/download.svg"

import AudioPlayer     from "YConnect/Components/AudioPlayer"
import { LinkStyle }   from "YConnect/Components/Button"
import getStaticUrl    from "YConnect/Utils/GetStaticUrl.util"
import AlphaBlackStyle from "YConnect/Styles/AlphaBlack.style"

type PlayerModalProps = {
    hasShow             : boolean,
    modalTitle          : string,
    audioFiles          : any
    audioPlaying        : any
    onPlaying           : Function
    onOpenRetract       : Function
    OnCloseAudio        : Function
}

const PlayerModal = ({
    hasShow,
    modalTitle,
    OnCloseAudio,
    audioFiles,
    audioPlaying,
    onPlaying,
    onOpenRetract 
}: PlayerModalProps) => {

    const faixas = audioFiles

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                OnCloseAudio()
            }
        }
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        })
    }

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef)

    useEffect(()=>{
        if(audioPlaying.status === "ENDED"){
            const itemPlaying = faixas.find(({title}:any) => title === audioPlaying.title)
            if(itemPlaying){
                if(faixas.indexOf(itemPlaying) > -1 && (faixas.indexOf(itemPlaying)+1) < faixas.length){
                    const nextPlaying = faixas[faixas.indexOf(itemPlaying)+1]
                    onPlaying({
                        title       : nextPlaying.title, 
                        currentTime : 0, 
                        src         : getStaticUrl(nextPlaying.name_File), 
                        status      : "PLAYING"
                    })
                }
            }
        }
    }, [audioPlaying])

    const handlePlay = ({title, src}:any) => () => onPlaying({title, src:getStaticUrl(src), status:"PLAYING"})
   
    const handlePause = ({title, src}:any) => (currentTime: number) => {
        if(audioPlaying.src === getStaticUrl(src))
            onPlaying({title, src:getStaticUrl(src), currentTime, status:"PAUSE"})
    }

    const handleChangeTime = ({title, src}:any) => (currentTime: number) => {
        if(audioPlaying.src === getStaticUrl(src))
            onPlaying({title, src:getStaticUrl(src), currentTime, status:audioPlaying.status})
    }

    const isRetractBack = (src:string) => 
    audioPlaying
    && audioPlaying.status
    && audioPlaying.status === "PLAYING"
    && audioPlaying.src === src
    
    function downloadURL(url:string, filename:string) {
        fetch(url).then(function(t) {
            return t.blob().then((b)=>{
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename);
                a.click();
            })
        }
    )}

    return <Fragment>
                {hasShow && <AlphaBlackStyle/>}
                <CSSTransition classNames="" timeout={300} in={hasShow} unmountOnExit>
                        <div className={`YC-lateral lateral-audio ${hasShow ? "active" : ""} d-flex flex-column justify-content-start`} style={{ overflowX: "auto" }} ref={wrapperRef}>
                            <h4 className="align-items-center d-flex flex-row justify-content-between mb-4">
                                {modalTitle}: audio files
                                {
                                    window.innerWidth > 1024
                                    && audioPlaying 
                                    && audioPlaying.status === "PLAYING"
                                    && <LinkStyle
                                        type="submit"
                                        label="Retract player" icon="arrowRightGreen" onClick={onOpenRetract} />
                                }
                                {
                                    window.innerWidth < 1024
                                    && audioPlaying
                                    && audioPlaying.status === "PLAYING"
                                    && <div className="btn-fixed d-flex message-reply--btn justify-content-end">
                                        <LinkStyle
                                            type        = "submit"
                                            label       = "Retract player" 
                                            icon        = "arrowDownIcon"
                                            className   = "d-flex align-items-center" 
                                            onClick     = {onOpenRetract} />
                                    </div>
                                }
                                <LinkStyle 
                                    type      = "submit"
                                    label     = "Close x"
                                    className = "close-modal"
                                    onClick   = {OnCloseAudio} />
                            </h4>

                            <div className="audios">
                                {
                                    audioFiles
                                    && faixas.length > 0
                                    && faixas.map(({title:title, name_File:src}: any, key: any) => 
                                            <div key={key} className="audio-task ">
                                                    <div className="audio-task--player">
                                                        <p className="audio-title p-0 font-weight-bold">{title}</p>
                                                        <p className="audio-title p-0 font-weight-bold">Extension: {src.split('.').pop()}</p>
                                                        <AudioPlayer
                                                            isPlayingCurrent = {isRetractBack(getStaticUrl(src))}
                                                            autoPlay         = {isRetractBack(getStaticUrl(src))}
                                                            audioPlaying     = {audioPlaying}
                                                            src              = {getStaticUrl(src)}
                                                            onPlay           = {handlePlay({title, src})}
                                                            onPause          = {handlePause({title, src})}
                                                            onChangeTime     = {handleChangeTime({title, src})}
                                                            onEnded          = {() => onPlaying({...audioPlaying, status:"ENDED"})}
                                                            onClose          = {() => onPlaying({})}/>
                                                    </div>
                                                    <div
                                                        className="audio-task--download"
                                                        onClick={()=>{downloadURL(getStaticUrl(src), src.substring(src.lastIndexOf('\\')+1, src.length))}}
                                                        style={{'cursor':'pointer'}}
                                                    >
                                                        {
                                                            window.innerWidth > 460 &&
                                                            <span className="align-self-end">Download</span>
                                                        }
                                                        <img src={downloadIcon} />
                                                    </div>
                                                </div>)
                                }
                            </div>
                        </div>
                    </CSSTransition>
            </Fragment>
}

export default PlayerModal
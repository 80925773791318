import * as React              from "react"
import { useState, useEffect } from "react"
import { Modal, FormGroup } from "react-bootstrap"
import { ButtonStyle }      from "YConnect/Components/Button"
import ReactCrop from 'react-image-crop';

import * as placeholderPicture from "YConnect/Assets/png/placeholder-profile.png"

import {ProfileButtonStyle} from "YConnect/Components/Button"


const getBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
})


const PictureContainer = ({ response, onAddNewPicture, disabledPictureEdit }: any) => {

    const [newImage, setNewImage] = useState()
    const [hasError, setHasError] = useState(false)
    
    const [hasCropModal, setCropModal]          = useState(false)
    const [imgToCrop, setImgToCrop]             = useState()
    const [crop, setCrop]                       = useState({aspect: 1, unit: 'px', width: 50, height: 50})
    const [croppedImageUrl, setCroppedImageUrl] = useState()
    const [imgRef, setImageRef]                 = useState()

    useEffect(() => {
        if (newImage !== "") {
            const newImageBytes = newImage
                ? Array.from(Array(newImage.length).keys()).map(key => newImage.charCodeAt(key))
                : undefined
            onAddNewPicture(newImageBytes)
        } else {
            onAddNewPicture("")
        }

    }, [newImage])

    const handleRemoveNewImage = () => {
        if(!disabledPictureEdit){
            if(newImage && newImage !== ""){
                setNewImage(undefined)
            }else{
                setNewImage("")
            }
        }
    }

    const getImage = () =>{
        if(newImage !== undefined) {
            return newImage !== "" ? newImage : placeholderPicture
        } else{
            return (response.foto && atob(response.foto)) || placeholderPicture
        }
    }

    const isShowDelete = () => !(newImage === "" || response.foto == null);

    const savePhoto = () => {
        setCropModal(false)
        setNewImage(croppedImageUrl)
    }

    //Crop options
    const onImageLoaded = (image: any) => setImageRef(image);

    const onSelectFile = async ({ target: { files } }: any) => {
        setCrop({aspect: 1, unit: 'px', width: 50, height: 50})
        if (files && files.length > 0) {
            const file = files[0]
            if (file.size / 1024 / 1024 > 2) {
                setHasError(true)
            } else {
                const newImage64 = await getBase64(file)
                setHasError(false)
                setImgToCrop(newImage64)
                setCropModal(true)
            }
        }
    };

    const onCropComplete = (crop: any) => makeClientCrop(crop);

    const onCropChange = (crop: any, percentCrop: any) => setCrop(crop);

    const makeClientCrop = async (crop: any) => {
        if (imgRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imgRef,
                crop,
                'newphoto.jpeg'
            );
            setCroppedImageUrl(croppedImageUrl);
        }
    }

    const getCroppedImg = (image: any, crop: any, fileName: any) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return canvas.toDataURL('image/png;base64');
    }

    return <div className="profile-picture p-3 pt-5">
        <figure className="mb-0">
            <img
                src       = {getImage()}
                alt       = {`Profile picture from ${response.nome_aluno || `user.`}`}
                style     = {{ border: "5px solid #e0e0e0", borderRadius: "50%", height: "100px", width: "100px" }}
                className = "img-fluid rounded-circle"
                onError   = {handleRemoveNewImage} />
        </figure>
        {
            hasError &&
            <small className="form-text text-danger mb-3">File size exceeds 2 MB. Please, try again!</small>
        }
        <input
            disabled={disabledPictureEdit}
            id="uploadPhoto"
            type="file"
            accept="image/*"
            onChange={onSelectFile} style={{ display: "none" }} 
            onClick={(event: any) => event.target.value = null}/>
        <ProfileButtonStyle 
            disabled  = {disabledPictureEdit} 
            className = "profile-picture--updatePhoto" 
            id        = "uploadPhoto" 
            icon      = "uploadIcon" 
            size      = "medium" 
            maxWidth  = {true}
            label     = "Upload new picture"/>

        {isShowDelete() && 
            <ProfileButtonStyle 
                disabled  = {disabledPictureEdit} 
                label     = "Delete picture" 
                className = "mt-0" 
                icon      = "remove" 
                size      = "medium" 
                maxWidth  = {true}
                onClick   = {handleRemoveNewImage}/>}

        <Modal show={hasCropModal} onHide={() => setCropModal(false)} size="lg" centered>
            <Modal.Header closeLabel="Close" closeButton>
                Crop your profile picture!
            </Modal.Header>
            <Modal.Body style={{margin: 'auto'}}>
                <ReactCrop 
                    src={imgToCrop} 
                    crop={crop} 
                    onImageLoaded={onImageLoaded}
                    ruleOfThirds
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className=" d-flex flex-row justify-content-end">
                    <ButtonStyle type="button" label="Cancel" size={'small'} className={'mr-2'} onClick={() => setCropModal(false)} />
                    <ButtonStyle
                        green
                        onClick={savePhoto}
                        label="Crop photo"
                        size="small"
                        icon="save"
                    />
                </div>
            </Modal.Footer>
        </Modal>
    </div>
}



export default PictureContainer
import * as React             from "react"
import {useEffect}            from "react"
import {Card}                 from "reactstrap"
import { connect }            from "react-redux"
import { bindActionCreators } from "redux"

import PanelCrud              from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"
import Filters                   from "YConnect/Components/Filters2"
import useSelectData             from "YConnect/Hooks/useSelectData"
import useFilterState            from "YConnect/Hooks/useFilterState"
import useChangeQueryParamByFilter from "YConnect/PagesComponents/CMS/Hooks/useChangeQueryParamByFilter"
import useQueryParamLoader from "YConnect/PagesComponents/CMS/Hooks/useQueryParamLoader"
import useUpdateFilterHierarchy from "YConnect/PagesComponents/CMS/Hooks/useUpdateFilterHierarchy"
import QueryParamsActionsCreator from "YConnect/Actions/QueryParams.actionsCreator"

import * as CourseResource            from "YConnect/ResourcesBackEnd/Course.resources"
import * as UnitResource              from "YConnect/ResourcesBackEnd/Unit.resources"
import * as SectionResource           from "YConnect/ResourcesBackEnd/Section.resources"
import * as ExerciseTypeResource      from "YConnect/ResourcesBackEnd/ExerciseType.resources"
import * as LearningObjectiveResource from "YConnect/ResourcesBackEnd/LearningObjective.resources"

import {
    GetAllBySectionId, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/Exercise.resources"

import CONFIG_TABLE_SECTION from "YConnect/PagesComponents/ControlPanel/Tables/Exercise.table.config"
import SECTION_TEMPLATE     from "YConnect/PagesComponents/ControlPanel/Templates/Exercise.template"

const ExercisesPanel = ({
    AddQueryParam,
    RemoveQueryParam,
    QueryParams
}:any) =>{

    const  {
        selectData,
        AddSelectData,
        RemoveSelectData
    } = useSelectData()


    const [
        {
            courseIdSelected,
            unitTypeSelected,
            unitIdSelected,
            sectionIdSelected
        },
        setIdSelected
    ] = useFilterState()

    
    useEffect(()=>{
        updateCourseSelectData()
        updateLearningObjectiveSelectData()
        updateExerciseTypeSelectData()
        updateUnitTypeSelectData()
    }, [])

    useEffect(() => {
        if (sectionIdSelected) {
            AddQueryParam("section", sectionIdSelected)
        } else {
            RemoveQueryParam("section")
        }
    }, [sectionIdSelected])

    // QueryParams hooks
    useQueryParamLoader(courseIdSelected, "courseIdSelected", "course", [], QueryParams, setIdSelected)
    useQueryParamLoader(unitTypeSelected, "unitTypeSelected", "unitType", ["course"], QueryParams, setIdSelected)
    useQueryParamLoader(unitIdSelected, "unitIdSelected", "unit", ["course", "unitType"], QueryParams, setIdSelected)
    useQueryParamLoader(sectionIdSelected, "sectionIdSelected", "section", ["course", "unitType", "unit"], QueryParams, setIdSelected)

    // Data fetchers
    const updateCourseSelectData = () =>
        CourseResource
        .GetAll()
        .then((response:any) => {
            const {data} = response
            AddSelectData("CourseId", "Id", "Title", data)
        })

    const updateUnitTypeSelectData = () =>
        UnitResource
        .GetUnitTypes()
        .then((response:any) => {
            const {data} = response
            AddSelectData("UnitType", "Value", "Key", data)
        })

    const updateUnitSelectData = () =>
        UnitResource
        .GetAllByCourseId(courseIdSelected, unitTypeSelected)
        .then((response:any) => {
            const {data} = response
            AddSelectData("UnitId", "Id", "Name", data)
        })
    
    const updateSectionSelectData = () =>
        SectionResource
        .GetAllByUnitId(unitIdSelected)
        .then((response:any) => {
            const {data} = response
            AddSelectData("SectionId", "Id", "Name", data)
        })

    const updateLearningObjectiveSelectData = () =>
        LearningObjectiveResource
        .GetAll()
        .then((response:any) => {
            const {data} = response
            AddSelectData("LearningObjectiveId", "Id", "Description", data)
        })
    
    const updateExerciseTypeSelectData = () =>
        ExerciseTypeResource
        .GetAll()
        .then((response:any) => {
            const {data} = response
            AddSelectData("ExerciseTypeId", "Id", "Description", data)
        })

    useEffect(() => {
        if (unitTypeSelected) updateUnitSelectData()
    }, [unitTypeSelected])

    useEffect(() => {
        if (unitIdSelected) updateSectionSelectData()
    }, [unitIdSelected])

    // Filter hooks
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "course", courseIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "unitType", unitTypeSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "unit", unitIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "section", sectionIdSelected)

    useUpdateFilterHierarchy(courseIdSelected, ["unitType", "unit", "section"], RemoveQueryParam, 
        ["unitTypeSelected", "unitIdSelected", "sectionIdSelected"], setIdSelected);
    useUpdateFilterHierarchy(unitTypeSelected, ["unit", "section"], RemoveQueryParam, 
        ["unitIdSelected", "sectionIdSelected"], setIdSelected);
    useUpdateFilterHierarchy(unitIdSelected, ["section"], RemoveQueryParam, 
        ["sectionIdSelected"], setIdSelected);

    const handleChangeFilter = (keystone:string, value:any) => setIdSelected({[keystone]:value})

    return <>
            <Card className="px-4 pt-4 pb-0 mb-3">
                <Filters
                    filterProps={[
                        {
                            keystone : "courseIdSelected",
                            label    : "Course",
                            value    : courseIdSelected,
                            onChange : handleChangeFilter,
                            values   : selectData ? selectData["CourseId"] : []
                        },
                        {
                            keystone : "unitTypeSelected",
                            label    : "Unit Type",
                            value    : unitTypeSelected,
                            onChange : handleChangeFilter,
                            disabled : !courseIdSelected,
                            values   : selectData ? selectData["UnitType"] : []
                        },
                        {
                            keystone : "unitIdSelected",
                            label    : "Unit",
                            value    : unitIdSelected,
                            onChange : handleChangeFilter,
                            disabled : !unitTypeSelected,
                            values   : selectData ? selectData["UnitId"] : []
                        },
                        {
                            keystone : "sectionIdSelected",
                            label    : "Section",
                            value    : sectionIdSelected,
                            onChange : handleChangeFilter,
                            disabled : !unitIdSelected,
                            values   : selectData ? selectData["SectionId"] : []
                        }
                    ]}/>
            </Card>
            {
            courseIdSelected 
            && unitTypeSelected
            && unitIdSelected
            && sectionIdSelected
            && <PanelCrud
                        idName            = "exerciseId"
                        labelButtonCreate = "Create Exercise"
                        labelForm         = "Exercise"
                        template          = {SECTION_TEMPLATE}
                        configTable       = {CONFIG_TABLE_SECTION}
                        selectData        = {selectData}
                        GetAll            = {GetAllBySectionId}
                        paramGetAll       = {sectionIdSelected}
                        GetById           = {GetById}
                        Create            = {Create}
                        paramCreate       = {{sectionId:sectionIdSelected, SectionId:sectionIdSelected}}
                        UpdateById        = {UpdateById}
                        DeleteById        = {DeleteById} />
            }
            </>
}

const mapDispatchToProps = (dispatch:any) => bindActionCreators({
    AddQueryParam : QueryParamsActionsCreator.AddQueryParam,
    RemoveQueryParam : QueryParamsActionsCreator.RemoveQueryParam
}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(ExercisesPanel)
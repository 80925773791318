import * as React  from "react"
import {
    useState, 
    useContext
}                  from "react"
import { Row, Col} from "reactstrap"

import AverageResultScore from "YConnect/PagesComponents/PrePostReport/Components/AverageResultScore"
import StudentVision      from "YConnect/PagesComponents/PrePostReport/Components/StudentVision"
import Filters            from "YConnect/Components/Filters"
import useReport          from "YConnect/PagesComponents/PrePostReport/Components/useReport"
import useQueryParams     from "YConnect/PagesComponents/PrePostReport/Components/useQueryParams"

import useFilter  from "YConnect/PagesComponents/PrePostReport/Teacher.report/useFilter"
import useCombo   from "YConnect/PagesComponents/PrePostReport/Teacher.report/useCombo"
import TabsResult from "YConnect/PagesComponents/PrePostReport/Teacher.report/TabsResult"

import UserContext from "YConnect/User.context"

const ReportContainer = ({
    queryParams,
    onChangeQueryParams
}:any) => {

    const [hasFilterLoading, setHasFilterLoading] = useState(false)
    const { idProfessor }: any                    = useContext(UserContext)

    const {
        fiterState : [
            {
                semesterIdSelected,
                establishmentIdSelected,
                courseIdSelected,
                classIdSelected,
                studentIdSelected,
                periodIdSelected
            },
            setIdSelected
        ],
        comboState:[
            {
                semestersCombo,
                establishmentsCombo,
                coursesCombo,
                classesCombo,
                studentsCombo,
                periodCombo
            }, 
            setCombo
        ]
    } = useReport()

    useQueryParams({
        idSelecteds:{
            semesterIdSelected,
            establishmentIdSelected,
            courseIdSelected,
            classIdSelected,
            studentIdSelected,
            periodIdSelected
        },
        setIdSelected,
        queryParams,
        onChangeQueryParams
    })

    useFilter({
        semesterIdSelected,
        establishmentIdSelected,
        courseIdSelected,
        classIdSelected,
        studentIdSelected,
        setIdSelected
    })

    useCombo({
        semesterIdSelected,
        establishmentIdSelected,
        courseIdSelected,
        classIdSelected,
        teacherIdSelected: idProfessor|| "",
        studentIdSelected,
        setHasFilterLoading,
        setCombo
    })
  
    const handleChangeFilter = (keystone:string, value:any) => setIdSelected({[keystone]:value})

    return <>
                <Row className="mb-5">
                    <Col md={12}>
                        <Filters
                            isLoading = {hasFilterLoading}
                            filterProps={[
                                {
                                    keystone : "semesterIdSelected",
                                    label    : "Semester",
                                    value    : semesterIdSelected,
                                    onChange : handleChangeFilter,
                                    values   : semestersCombo
                                },
                                {
                                    keystone : "establishmentIdSelected",
                                    label    : "Establishment",
                                    value    : establishmentIdSelected,
                                    onChange : handleChangeFilter,
                                    values   : establishmentsCombo
                                },
                                {
                                    keystone : "courseIdSelected",
                                    label    : "Course",
                                    value    : courseIdSelected,
                                    onChange : handleChangeFilter,
                                    values   : coursesCombo
                                },
                                {
                                    keystone : "classIdSelected",
                                    label    : "Class",
                                    value    : classIdSelected,
                                    disabled : !classesCombo || classesCombo.length === 0,
                                    onChange : handleChangeFilter,
                                    values   : classesCombo
                                },
                                {
                                    keystone : "studentIdSelected",
                                    label    : "Student",
                                    hasOptionEmpty : false,
                                    value    : studentIdSelected,
                                    disabled : !studentsCombo || studentsCombo.length === 0,
                                    onChange : handleChangeFilter,
                                    values   : studentsCombo
                                },
                                {
                                    keystone : "periodIdSelected",
                                    label    : "Period",
                                    hasOptionEmpty : false,
                                    value    : periodIdSelected,
                                    disabled : !periodCombo || periodCombo.length <= 1,
                                    onChange : handleChangeFilter,
                                    values   : periodCombo
                                }
                            ]}/>
                    </Col>
                </Row>
                    {
                        studentIdSelected
                            ? <StudentVision
                                studentIdSelected       = {studentIdSelected}
                                establishmentIdSelected = {establishmentIdSelected}
                                courseIdSelected        = {courseIdSelected}
                                classIdSelected         = {classIdSelected}
                                semesterIdSelected      = {semesterIdSelected}
                                periodIdSelected        = {periodIdSelected} />
                            : semesterIdSelected
                                && !studentIdSelected
                                ? <>
                                    <AverageResultScore
                                        semesterIdSelected      = {semesterIdSelected}
                                        establishmentIdSelected = {establishmentIdSelected}
                                        courseIdSelected        = {courseIdSelected}
                                        classIdSelected         = {classIdSelected} 
                                        teacherIdSelected       = {idProfessor|| ""}/>
                                    <TabsResult
                                        classIdSelected         = {classIdSelected || ""}
                                        courseIdSelected        = {courseIdSelected || ""}
                                        semesterIdSelected      = {semesterIdSelected || ""}
                                        establishmentIdSelected = {establishmentIdSelected || ""}
                                        teacherIdSelected       = {idProfessor || ""} />
                                </>
                                : null
                    }
            </>
}

export default ReportContainer
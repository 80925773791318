import styled from "styled-components"
import { Row } from "reactstrap"

import * as checked  from "YConnect/Assets/svg/checkedGreen.svg"
import * as remove   from "YConnect/Assets/svg/closeRed.svg"

const ExerciseRowStyle = styled(Row)`
    position: relative;

    &.borderBottom {
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
    }

    .exercise-dialogue-options {
        align-self: center;
    }

    .exercise-dialogue-question {
        font-weight: bold;
        align-self: center;
        line-height: 2em;
        padding: 10px;
        color: ${props => props.theme.main.textColor};

        @media (min-width: 576px) and (max-width: 768px) {
            padding-top: 30px;
        }

        span.option {
            color: ${props => props.theme.main.mainPurple};
            background: ${props => props.theme.main.textWhite};
            border: 2px solid ${props => props.theme.main.lineTable};
            font-weight: bold;
            border-radius: 100%;
            padding: 5px 5px;
            margin-right: 10px;
            height: 35px;
            line-height: normal;

            span {
                max-width: 20px;
                width: 20px;
                min-width: 20px;
                display: inline-block;
                text-align: center;
            }
        }

        span.selected-option {
            color: ${props => props.theme.main.textWhite};
            border: 2px solid ${props => props.theme.main.lineTable};
            font-weight: bold;
            background: ${props => props.theme.main.mainPurple};
            border-radius: 100%;
            padding: 5px 5px;
            margin-right: -10px;
            height: 35px;
            line-height: normal;

            span {
                max-width: 20px;
                width: 20px;
                min-width: 20px;
                display: inline-block;
                text-align: center;
            }
        }

        &.right {
            border: 1px solid ${props => props.theme.main.successColor};
            border-radius: 10px;

            &::after {
                content: url(${checked});
                position: absolute;
                filter: ${props => props.theme.main.filterSvg};
                right: 2px;
                top: 2px;
                transform: scale(0.7);
            }
        }

        &.wrong {
            border: 1px solid ${props => props.theme.main.errorColor};
            border-radius: 10px;

            &::after {
                content: url(${remove});
                position: absolute;
                filter: ${props => props.theme.main.filterSvg};
                right: 5px;
                top: 5px;
                transform: scale(0.8);
            }
        }
    }

    &:last-child {
        padding-bottom: 30px;
    }
`

export default ExerciseRowStyle
import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import Table from "YConnect/Components/Table"

import { ButtonStyle } from "YConnect/Components/Button"
import useEditableTableState from "YConnect/PagesComponents/CMS/Hooks/useEditableTableState"
import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"
import ImageFilesModal from "YConnect/PagesComponents/CMS/Modals/ImageFiles.modal"

const ImageStyled = styled.img`
    width: 100%;
    height: auto;
`

const TableStyled = styled(Table)`
    .table{
        min-height: auto;
    }
    td, th {
        padding:10px;
    }
`

const INITIAL_DATA_STATE = [
    {
        type:"NEW",
        row:{}
    }
]

const GetStateTextAndImagesByOrder = (textAndImagesByOrder:any) => 
    textAndImagesByOrder
    && Object.keys(textAndImagesByOrder).length > 0
    ? [
        ...Object.keys(textAndImagesByOrder).map(order => ({type:"ADDED", row:textAndImagesByOrder[order]})),
        {type:"NEW", row:{}}
    ]
    : INITIAL_DATA_STATE

type DragAndDropTextAndImageEditableTableProps = {
    textAndImagesByOrder:any
    onChange:any
}
    
const DragAndDropTextAndImageEditableTable = ({
    textAndImagesByOrder,
    onChange
}:DragAndDropTextAndImageEditableTableProps) => {
    const [isShowImageFilesModal, setShowImageFilesModal] = useState(false)
    const [imageIndexForUpdate, setImageIndexForUpdate] = useState<number>()

    const {
        dataState,
        //getValues,
        updateLine,
        //addDataNewLine,
        removeLine,
        setDataState
    } = useEditableTableState(GetStateTextAndImagesByOrder(textAndImagesByOrder))

    const handleUpdateRow = (key:number, property:string, value:any) => {
        const values  = updateLine(key, property, value)  
        onChange(values)
    }

    const handleRemoveRow = (key:number) => {
        const values  = removeLine(key)
        onChange(values)
    }

    const handleShowModalImage = (key:any) => {
        setShowImageFilesModal(true)
        setImageIndexForUpdate(key)
    }

    const handleCloseModalImage = (key:any) => {
        setShowImageFilesModal(false)
        setImageIndexForUpdate(undefined)
    }

    const handlSelectImage = ({Source, MediaType}:{Source:string, MediaType:string}) => {

        handleUpdateRow(imageIndexForUpdate, "exerciseMedia", {
            ...(
                dataState[imageIndexForUpdate]
                && dataState[imageIndexForUpdate].row.exerciseMedia 
                ? dataState[imageIndexForUpdate].row.exerciseMedia
                :{}
            ),
            Source, 
            MediaType
        })
        setImageIndexForUpdate(undefined)
        setShowImageFilesModal(false)
    }

    const config : Array<ColumnConfigType> = [
        {
            label     : "Text",
            formatter : (state:any, key:number) => 
            <Col md="auto">
                <textarea 
                    className="form-control" 
                    onChange={(e:any) => handleUpdateRow(key, "answer", {...state.row["answer"], Awnser:e.target.value})}
                    value={state.row["answer"] && state.row["answer"]["Awnser"] || ""}/>
            </Col>
            
        },
        {
            label     : "Image",
            formatter : (state:any, key:number) => 
            state.row["exerciseMedia"] 
            ? <Row>
                <Col md="auto">
                    <ImageStyled
                        className="img-fluid"
                        style={{width: "220px"}}
                        src={getStaticUrlAssests(state.row["exerciseMedia"]["Source"])}/>
                </Col>
                <Col md="auto" className="align-self-center">
                    <ButtonStyle
                        className = "mt-3"
                        size      = "small"
                        onClick   = {() => handleShowModalImage(key)}
                        label     = "Choose Image"/>
                </Col>
            </Row>
            : <ButtonStyle
                disabled  = {!(state.row["answer"] && state.row["answer"]["Awnser"] && state.row["answer"]["Awnser"] !== "")}
                green
                size      = "190"
                onClick   = {() => handleShowModalImage(key)}
                icon      = "imageWhite"
                label     = "Add Image"/>
        },
        {
            formatter : (state:any, key:any) => 
            <>
                {
                    state.type === "ADDED"
                    &&<Col> <Row className="px-0">
                        <ButtonStyle 
                            size      = "forTable"
                            className = "mr-2"
                            icon      = "remove"
                            onClick   = {() => handleRemoveRow(key)}/>
                    </Row></Col>
                }
            </>
        }
    ]

    return <>
                <TableStyled 
                    data={dataState}
                    config={config} />
                {
                    isShowImageFilesModal
                    && <ImageFilesModal onSelect={handlSelectImage} 
                    onClose={handleCloseModalImage}/>
                }
            </>
}

export default DragAndDropTextAndImageEditableTable
import * as React from "react"
import {
    useEffect, useState, useContext
} from "react"
import { Container, Row, Col, Card } from "reactstrap"
import styled from "styled-components"

import { BreadcrumbReportClassGrade } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle from "YConnect/Components/PageTitle"
import API from "YConnect/API"
import UserContext from "YConnect/User.context"
import { ButtonStyle } from "YConnect/Components/Button"
import SelectFieldStyle from "YConnect/Styles/SelectField.style"
import SelectStyleNew from "YConnect/Styles/SelectDigitable.style"

import Table from "YConnect/Components/Table"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

import CONFIG_TABLE_LAST_SEARCHES from "YConnect/Configs/Tables/LastSearches.table.config"

const SelectFieldNewStyle = styled(SelectFieldStyle)`
    @media (min-width: 768px) {
        width: 20%;
    }
`

const SelectFieldNew2Style = styled(SelectFieldStyle)`
    @media (min-width: 768px) {
        width: 15%;
    }
`

const ButtonStyleNew = styled(ButtonStyle)`
    width: 100%;
    @media (min-width: 768px) {
        width: 25%;
    }

    img {
        filter: grayscale(100%) brightness(0) invert(1);
    }
`

const SelectLabelNew = styled.label`
    position: relative;
    left: 15px;
    z-index: 9990;
    /* bottom: 20px; */
    color: #79b100;
    font-size: 0.75rem;
    font-weight: bold;
    top: 5px;

`

const SelectDivNew = styled.div`

    @media (min-width: 768px) {
        width: 30%;

    }

    @media (max-width: 767px) {
        width: 100% !important;
    }

`

const ReportClassGrade = () => {

    const [grupos, setGrupos] = useState()
    const [establishments, setEstablishments] = useState()
    const [courses, setCourses] = useState()
    const [classes, setClasses] = useState()

    const [establishmentParam, setEstablishmentParam] = useState()
    const [courseParam, setCourseParam] = useState()
    const [classParam, setClassParam] = useState()
    const [currentParam, setCurrentParam] = useState("true")

    const [lastSearches, setLastSearches] = useState(JSON.parse(localStorage.getItem("lastSearches")))

    const { estabelecimentos, idUsuario }: any = useContext(UserContext)

    const [selectedOption, setSelectedOption] = useState(null);


    useEffect(() => {
        if (!lastSearches) {
            const newLastSearches: any = {
                [idUsuario]: []
            }
            setLastSearches(newLastSearches)
            localStorage.setItem("lastSearches", JSON.stringify(newLastSearches))
        }
        API.Course.GetAll({ id_User_Report: idUsuario  }, getConfig())
            .then((response: any) => {
                setCourses(response.data.map(({ id_curso, titulo_curso }: any) => ({ id: id_curso, value: titulo_curso })))
            })
    }, [])

    useEffect(() => {
        if (lastSearches && lastSearches[idUsuario]) {
            const onlyResourceBookList = lastSearches[idUsuario].filter((search: any) => !search.isResourceBook);
            lastSearches[idUsuario] = onlyResourceBookList;
            localStorage.setItem("lastSearches", JSON.stringify(lastSearches))
        }
    }, [lastSearches])

    useEffect(() => {
        if (estabelecimentos) {
            setEstablishments(estabelecimentos.map(({ nsu_estab, nm_estab }: any) => ({ id: nsu_estab, value: nm_estab })))
        }
    }, [estabelecimentos])

    useEffect(() => {
        if (establishmentParam) {
            setCourseParam("")
        }
    }, [establishmentParam])


    useEffect(() => {
        if (courseParam && courseParam !== "") {
            setClasses([])
            setClassParam("")
            const paramForFetch = {
                idEstab: establishmentParam,
                idCurso: courseParam,
                ...currentParam && currentParam !== "" ? { fl_vigentes: currentParam } : {}
            }

            API.Grupo.Get(paramForFetch, getConfig())
                .then((response: any) => setGrupos(response.data))
        }
    }, [courseParam, currentParam])

    useEffect(() => {
        if (grupos && grupos.turmas.length > 0) {
            setClasses(grupos.turmas.map(({ id_grupo, nome_grupo, idCurso }: any) => ({ id: [id_grupo, idCurso], value: nome_grupo })))
        } else {
            setClasses([])
        }
    }, [grupos])

    const saveSearch = () => {
        const classParamSplit = classParam.split(',')

        const newSearchData = {
            establishment: establishments.find(({ id }: any) => id == establishmentParam).value,
            course: courses.find(({ id }: any) => id == courseParam).value,
            class: classes.find(({ id }: any) => id == classParam).value,
            establishmentParam,
            courseParam,
            classParam: classParamSplit[0],
            currentParam
        }

        const lastSearchesUser = lastSearches[idUsuario] || []
        setLastSearches({
            ...lastSearches,
            [idUsuario]: [newSearchData, ...lastSearchesUser.slice(0, 4)]
        })

    }

    const handleChangeFilter = (keystone: string, value: any) => {
        const sets: any = {
            establishment: setEstablishmentParam,
            course: setCourseParam,
            class: setClassParam,
            current: setCurrentParam
        }

        if (sets[keystone]) sets[keystone](value)
    }

    const handleSearch = () => {
        if (classParam && classParam !== "") {
            const classParamSplit = classParam.split(',')
            saveSearch()
            window.location.href = `#/reports/view-report-teacher/${establishmentParam}/${classParamSplit[1]}/${classParamSplit[0]}${(currentParam === "true" || currentParam === "false") ? "?current=" + currentParam : ""}`
        }
    }

    const handleChange = (selectedOption) => {
    if (selectedOption) {
        handleChangeFilter(selectedOption.value, selectedOption.id);
        setEstablishmentParam(selectedOption.id)
        setSelectedOption(selectedOption);
        }
    };


    return <Container>
        <BreadcrumbReportClassGrade/>
        <PageTitle label="Students and class reports" />
        <h4>Select parameters above to visualize and send reports - <small>* Required fields!</small></h4>
        <Row className="mb-5">
            <Col md={12}>
                <Card className="users-table shadow">
                    <header className="users-table-header mb-2">
                        <SelectDivNew>
                            <SelectLabelNew>Establishment *</SelectLabelNew>
                            <SelectStyleNew
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.id}
                                className=""
                                options={establishments}
                                keystone="establishment"
                                label="Establishment *"
                                value={selectedOption}
                                onChange={handleChange}
                                values={establishments}
                                placeholder=""
                                noOptionsMessage={() => "Sem resultados"}
                            />
                            </SelectDivNew>

                        {/* <SelectFieldNewStyle
                            keystone="establishment"
                            label="Establishment *"
                            value={establishmentParam}
                            onChange={handleChangeFilter}
                            values={establishments} /> */}
                        <SelectFieldNewStyle
                            keystone="course"
                            label="Course *"
                            value={courseParam}
                            onChange={handleChangeFilter}
                            values={courses} />
                        <SelectFieldNew2Style
                            keystone="current"
                            label="Current"
                            value={currentParam}
                            onChange={handleChangeFilter}
                            values={[{ id: true, value: "Yes" }, { id: false, value: "No" }]}
                            hasOptionEmpty={true} />
                        <SelectFieldNewStyle
                            keystone="class"
                            label="Class *"
                            disabled={!classes || classes.length === 0}
                            value={classParam}
                            onChange={handleChangeFilter}
                            values={classes} />

                        <ButtonStyleNew
                            green
                            label="Search Report"
                            icon="search"
                            className="mb-3"
                            disabled={!establishmentParam || !courseParam || !currentParam || classes.length === 0}
                            onClick={handleSearch} />
                    </header>
                </Card>
            </Col>
        </Row>
        <h4>Or consult again your last searches</h4>
        <Row className="mb-5">
            <Col md={12}>
                <Card className="users-table shadow">
                    <Table data={lastSearches ? (lastSearches[idUsuario] || []) : []} config={CONFIG_TABLE_LAST_SEARCHES} />
                </Card>
            </Col>
        </Row>
    </Container>

}


export default ReportClassGrade

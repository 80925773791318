import * as React    from "react"
import {useContext}  from "react"

import ProfileContainer from "YConnect/Containers/Profile.container"
import UserContext      from "YConnect/User.context"

import MyAboutYouForm           from "YConnect/Configs/Forms/MyAboutYou.form"
import MyContactInformationForm from "YConnect/Configs/Forms/MyContactInformation.form"
import MyAccountDetailsForm     from "YConnect/Configs/Forms/MyAccountDetails.form"

const enableEmail = (field:any) => {
    if(field.keystone === "email_aluno"){
        field.disabled = false
        field.messageBlocker = ""
    }
    return field
}
const enableFieldsContactInformation = (field:any) => {
        field.disabled = false
        field.messageBlocker = ""
    return field
}


const MyProfilePage = ({onLogout, onUpdateUserData}:any) => {
    
    const {idUsuario:id, profiles, primeiroAcessoGameficacao}:any = useContext(UserContext)

    const handleBeforeUpdate = (profile:any) => onUpdateUserData({
        ...{
            nomeCompleto : `${profile.nome_aluno} ${profile.sobrenome_aluno}`,
            nome         : profile.nome_aluno,
            email        : profile.email_aluno,
            foto         : profile.foto 
                                ? (
                                    typeof profile.foto == "object"
                                    ? profile.foto.map((item:any) => String.fromCharCode(item)).join("")
                                    : atob(profile.foto)
                                )
                                : ""    
        },
        ...(
            !primeiroAcessoGameficacao 
            || !primeiroAcessoGameficacao.dt_primeiro_acesso
        )
         ? {passwordForLogin : profile.senha_aluno}
         : {}
    })

    const isAdmin = profiles.indexOf(6) > -1 ||  profiles.indexOf(12) > -1 

    return  <ProfileContainer
                id              = {id}
                isMyProfile
                defaultTabKey   = "about-you"
                label           = "My passport:"
                formAbout       = {MyAboutYouForm}
                formContact     = { 
                    !isAdmin
                    ?  MyContactInformationForm
                    : ({...MyContactInformationForm,template:MyContactInformationForm.template.map(enableFieldsContactInformation)})}
                formAccount     = {
                    !isAdmin
                    ? MyAccountDetailsForm 
                    : ({...MyAccountDetailsForm, template:MyAccountDetailsForm.template.map(enableEmail)})}
                onLogout        = {onLogout}
                onBeforeUpdate  = {handleBeforeUpdate}/>
}

export default MyProfilePage
import DashboardPage from "YConnect/Pages/Dashboard.page"
import CoursesPage from "YConnect/Pages/Courses.page"
import ViewBookPage from "YConnect/Pages/ViewBook.page"
import MyProfilePage from "YConnect/Pages/MyProfile.page"
import ReportStudentPage from "YConnect/Pages/ReportStudent.page"
import ViewFullReportPage from "YConnect/Pages/ViewFullReport.page"
import ViewFullReportResourceBookPage from "YConnect/Pages/ViewFullReportResourceBook.page"
import ViewReportStudentPage from "YConnect/Pages/ViewReportStudent.page"
import ViewReportTeacherPage from "YConnect/Pages/ViewReportTeacher.page"
import ViewReportResourceBookPage from "YConnect/Pages/ViewReportResourceBook.page"
import OnlineTeacherPage from "YConnect/Pages/OnlineTeacher.page"
import VirtualClassPage from "YConnect/Pages/VirtualClass.page"
import LoginPage from "YConnect/Pages/Login.page"
import MessagesPage from "YConnect/Pages/Messages.page"
import UnlockScorePage from "YConnect/Pages/UnlockScore.page"
import CoursesControlPage from "YConnect/Pages/CoursesControl.page"
import TravelWorldPage from "YConnect/Pages/TravelWorld.page"
import SupportPage from "YConnect/Pages/Support.page"
import AboutPage from "YConnect/Pages/About.page"
import ExercisePage from "YConnect/Pages/Exercise.page"
import ExerciseReportPage from "YConnect/Pages/ExerciseReport.page"
import ManageUsersPage from "YConnect/Pages/ManageUsers.page"
import ResetPasswordPage from "YConnect/Pages/ResetPassword.page"
import ResetPasswordPage2 from "YConnect/Pages/ResetPassword2.page"
import ProfilePage from "YConnect/Pages/Profile.page"
import ControlPanelPage from "YConnect/Pages/ControlPanel.page"
import ReadMessage from "YConnect/Pages/ReadMessage.page"
import CountryPage from "YConnect/Pages/Country.page"
import ReportClassGrade from "YConnect/Pages/ReportClassGrade.page"
import ReportTeacherPage from "YConnect/Pages/ReportTeacher.page"
import ReportResourceBook from "YConnect/Pages/ReportResourceBook.page"
import ReportByStudent from "YConnect/Pages/ReportByStudent.page"
import PrePostReport from "YConnect/Pages/PrePostReport.page"
import ServerErrorPage from "YConnect/Pages/ServerError.page"
import PageNotFoundPage from "YConnect/Pages/PageNotFound.page"
import PrePostPage from "YConnect/Pages/PrePost.page"
import ExtraSupportPage from "YConnect/Pages/ExtraSupport.page"
import FinalTestPage from "YConnect/Pages/FinalTest.page"
import ExplorePage from "YConnect/Pages/Explore.page"
import SettingsPage from "YConnect/Pages/Settings.page"
import CardPage from "YConnect/Pages/Card.page"
import RedirectPage from "YConnect/Pages/Redirect.page"
import PressPage from "YConnect/Pages/Press.page"
import ReintegrateStudentPage from "YConnect/Pages/ReintegrateStudent.page"
import ConfirmEmailPage from "YConnect/Pages/ConfirmEmail.page"
import CMSPage from "YConnect/Pages/CMS.page"
import OpenningCMS from "YConnect/Pages/OpenningCMS.page"
import Units from "YConnect/Pages/Units.page"
import Sections from "YConnect/Pages/Sections.page"
import VirtualClassroom from "YConnect/Pages/VirtualClassroom.page"
import WrittenTestPage from "YConnect/Pages/WrittenTest.page"
import WrittenTestReviewPage from "YConnect/Pages/WrittenTestReview.page"
import YazVirtua from "YConnect/Pages/YazVirtua.page"
import ViewReportFinalTestPage from "YConnect/Pages/ViewReportFinalTest.page"
import FinalTestExerciseReport from "YConnect/Pages/FinalTestExerciseReport.page"
import BooksBlink from "YConnect/Pages/BooksBlink.page"
import ElosIntegrationPage from "YConnect/Pages/ElosIntegration.page"
import TechnicalSupportPage from "YConnect/Pages/TechnicalSupport.page"

export default [
    {
        keystone: "CMS",
        path: "/cms",
        label: "YConnect CMS",
        page: OpenningCMS
    },
    {
      keystone: "CMS_Exercises",
      path: "/cms/exercises",
      label: "YConnect CMS",
      page: CMSPage
    },
    {
      keystone: "CMS_Units",
      path: "/cms/units",
      label: "YConnect CMS",
      page: Units
    },
    {
      keystone: "CMS_Sections",
      path: "/cms/sections",
      label: "YConnect CMS",
      page: Sections
    },
    {
        keystone: "PRESS",
        path: "/imprensa/chamada/:id",
        label: "Press",
        page: PressPage
    },
    {
        keystone: "BOOKS_BLINK",
        path: "/blink/:id",
        label: "Books Blink",
        page: BooksBlink
    },
    {
        keystone: "DASHBOARD",
        path: "/",
        label: "Homepage(Dashboard)",
        page: DashboardPage,
    },
    {
        keystone: "YCONNECT_CONTROL_PANEL",
        path: "/control-panel",
        label: "YConnect Control Panel",
        page: ControlPanelPage,
        disableHeader: true,
        disableFooter: true
    },
    {
        keystone: "YCONNECT_CONTROL_PANEL_SECTIONS",
        path: "/control-panel/:panelName",
        label: "YConnect Control Panel",
        page: ControlPanelPage,
        disableHeader: true,
        disableFooter: true
    },
    {
        keystone: "COURSES",
        path: "/courses",
        label: "Courses",
        page: CoursesPage
    },
    {
        keystone: "COUNTRIES:COUNTRY",
        path: "/countries/:id",
        label: "Country",
        page: CountryPage
    },
    {
        keystone: "COURSES:VIEW_BOOK",
        path: "/courses/:id/view-book",
        label: "View Book",
        page: ViewBookPage
    },
    {
        keystone: "COURSES:VIEW_BOOK:EXERCISE",
        path: "/courses/:idCourse/unit/:idUnidade/view-book/:sectionName/:orderId/exercise/:idExercise",
        label: "Exercise",
        page: ExercisePage
    },
    {
        keystone: "COURSES:VIEW_BOOK:EXERCISE:STUDENT",
        path: "/courses/:idCourse/unit/:idUnidade/view-book/:sectionName/:orderId/exercise/:idExercise/student/:idStudent",
        label: "Exercise Student Report",
        page: ExerciseReportPage
    },
    {
        keystone: "PRE_POST_TESTE",
        path: "/pre_post/test/:idCurso/:idTeste",
        label: "Pre Post Test",
        page: PrePostPage
    },
    {
        keystone : "ASSESSMENT_FINAL",
        path     : "/final_test/:idCurso",
        label    : "Final Test",
        page     : FinalTestPage
    },
    {
        keystone : "EXTRA_SUPPORT",
        path     : "/extra_support/:idCurso",
        label    : "Extra Suport",
        page     : ExtraSupportPage
    },
    {
        keystone : "WRITTEN_TEST:STUDENT",
        path     : "/written_test/:idCurso/student/:idStudent",
        label    : "Written Test Student Review",
        page     : WrittenTestReviewPage
    },
    {
        keystone : "WRITTEN_TEST",
        path     : "/written_test/:idCurso",
        label    : "Written Test",
        page     : WrittenTestPage
    },
    {
        keystone: "REPORT_EXERCISE_FINAL_TEST",
        path: "/reports/view-report-final-test/:idCurso/student/:idAluno/score/:idNotaFinal/exercise/:idExercicio/",
        label: "Exercise Final Test",
        page: FinalTestExerciseReport
    },
    {
        keystone: "REPORTS",
        path: "/reports",
        label: "Reports",
        page: ReportStudentPage
    },
    {
        keystone: "REPORTS_TEACHER",
        path: "/reports-teacher",
        label: "Reports",
        page: ReportTeacherPage
    },
    {
        keystone: "REPORTS_RESOURCE_BOOK",
        path: "/report-resource-book",
        label: "Resource Book",
        page: ReportResourceBook
    },
    {
        keystone: "REPORTS_TEACHER_CLASS",
        path: "/reports-class-grades",
        label: "Class Grades",
        page: ReportClassGrade
    },
    {
        keystone: "REPORTS_BY_STUDENT",
        path: "/reports-by-student",
        label: "Report By Student",
        page: ReportByStudent
    },
    {
        keystone: "PRE_POST_REPORT",
        path: "/pre-post-report",
        label: "Pre Post Report",
        page: PrePostReport
    },
    {
        keystone: "REPORTS:VIEW_REPORT_STUDENT",
        path: "/reports/view-report-student/:idCurso",
        label: "View Report",
        page: ViewReportStudentPage
    },
    {
        keystone: "REPORTS:VIEW_FULL_REPORT",
        path: "/reports/view-full-report/:idCurso/:idAluno",
        label: "View Full Report",
        page: ViewFullReportPage
    },
    {
        keystone: "REPORTS:VIEW_FINAL_TEST_REPORT",
        path: "/reports/view-report-final-test/:idCurso/student/:idAluno/score/:idNotaFinal",
        label: "View Report Final Test",
        page: ViewReportFinalTestPage
    },
    {
        keystone: "REPORTS:VIEW_FULL_REPORT_RESOURCE_BOOK",
        path: "/reports/view-full-report-resource-book/:idCurso/:idAluno",
        label: "View Full Report Resource Book",
        page: ViewFullReportResourceBookPage
    },
    {
        keystone: "REPORTS:VIEW_REPORT_TEACHER",
        path: "/reports/view-report-teacher/:idEstab/:idCurso/:idGrupo",
        label: "View Report",
        page: ViewReportTeacherPage
    },
    {
        keystone: "REPORTS:VIEW_REPORT_RESOURCE_BOOK",
        path: "/reports/view-report-resource-book/:idEstab/:idCurso/:idGrupo",
        label: "View Report Resource Book",
        page: ViewReportResourceBookPage
    },
    {
        keystone: "UNLOCK_SCORE",
        path: "/unlock-score/:idAluno",
        label: "Unlock Score",
        page: UnlockScorePage
    },
    {
        keystone: "REINTEGRATE_STUDENT",
        path: "/reset-student/:idAluno",
        label: "Reset Activities",
        page: ReintegrateStudentPage
    },
    {
        keystone: "COURSES_CONTROL",
        path: "/courses-control/:idAluno",
        label: "Courses Control",
        page: CoursesControlPage
    },
    {
        keystone: "TRAVEL_THE_WORLD",
        path: "/travel-the-world",
        label: "Travel the World",
        page: TravelWorldPage
    },
    {
        keystone: "ONLINE_TEACHER",
        path: "/online-teacher",
        label: "Online Teacher",
        page: OnlineTeacherPage
    },
    {
        keystone: "ONLINE_TEACHER_MENU",
        path: "/online-teacher-contact",
        label: "Online Teacher",
        page: OnlineTeacherPage
    },
    {
        keystone: "VIRTUAL_CLASSROOM",
        path: "/virtual-classes",
        label: "Virtual Classroom",
        page: ElosIntegrationPage
        // Quando o BBB estiver disponível novamente para ir para produção só voltar com a linha de baixo novamente
        // page: VirtualClassPage
    },
    {
        keystone: "VIRTUAL_CLASSROOM_ELOS",
        path: "/virtual-classes-elos",
        label: "Virtual Classroom Elos",
        page: ElosIntegrationPage
    },
    {
        keystone: "MY_MESSAGE",
        path: "/my-message",
        label: "My Message",
        page: MessagesPage
    },
    {
        keystone: "TECHNICAL_SUPPORT",
        path: "/technical-suppport",
        label: "Technical Support",
        page: TechnicalSupportPage
    },
    {
        keystone: "READ_MESSAGE",
        path: "/my-message/:messageId",
        label: "Read Message",
        page: ReadMessage
    },
    {
        keystone: "MY_PROFILE",
        path: "/my-profile",
        label: "Profile",
        page: MyProfilePage
    },
    {
        keystone: "MANAGE_PROFILE",
        path: "/manage-users/:id",
        label: "Manage Profile",
        page: ProfilePage
    },
    {
        keystone: "MANAGE_USERS",
        path: "/manage-users",
        label: "Manage Users",
        page: ManageUsersPage
    },
    {
        keystone: "UPLOAD_CARD",
        path: "/upload-card",
        label: "Upload Card",
        page: CardPage
    },
    {
        keystone: "ABOUT_YCONNECT",
        path: "/about",
        label: "About YConnect",
        page: AboutPage
    },
    {
        keystone: "SUPPORT",
        path: "/support",
        label: "Support",
        page: SupportPage
    },
    {
        keystone: "TEST_ENGLISH",
        path: "/test-english",
        label: "Test Your English"
    },
    {
        keystone: "EXPLORE",
        path: "/explore",
        label: "Explore",
        page: ExplorePage
    },
    {
        keystone: "SETTINGS",
        path: "/settings",
        label: "Settings",
        page: SettingsPage
    },
    {
        keystone: "LOGIN",
        path: "/login",
        label: "Login",
        page: LoginPage,
        disableHeader: true,
        disableFooter: true
    },
    {
        keystone: "RESET_PASSWORD",
        path: "/password-reset",
        label: "Reset Password",
        page: ResetPasswordPage,
        disableHeader: true,
        disableFooter: true
    },
    {
        keystone: "REDEFINE_SENHA",
        path: "/redefine-senha",
        label: "Redefinir Senha",
        page: ResetPasswordPage2,
        disableHeader: true,
        disableFooter: true
    },
    {
        keystone: "CONFIRMAR_EMAIL",
        path: "/confirma-email",
        label: "Confirmar Email",
        page: ConfirmEmailPage,
        disableHeader: true,
        disableFooter: true
    },
    {
        keystone: "SERVER_ERROR",
        path: "/server-error",
        page: ServerErrorPage
    },
    {
        keystone: "REDIRECT",
        path: "/ext",
        page: RedirectPage
    },
    {
        keystone: "PAGE_NOT_FOUND",
        page: PageNotFoundPage
    }
]

import * as React from "react"
import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"

import * as arrowDownIcon from "YConnect/Assets/svg/arrow-down.svg"
import * as arrowRightGreenIcon from "YConnect/Assets/svg/arrow-right-green.svg"

import ProfilePicture from "YConnect/Components/ProfilePicture"
import { ButtonStyle } from "YConnect/Components/Button"

import convertPhoto from "YConnect/Utils/ConvertPhoto.util"

import ButtonToolbarStyle       from "YConnect/Styles/ButtonToolbar.style"
import DropdownUserProfileStyle from "YConnect/Styles/DropdownUserProfile.style"
import ToggleUserProfileStyle   from "YConnect/Styles/ToggleUserProfile.style"
import DropdownProfileStyle     from "YConnect/Styles/DropdownProfile.style"
import ProfilePictureStyle      from "YConnect/Styles/ProfilePicture.style"

const toggleClass = (() => {
    const arrowIcon = document.getElementById("js-rotate-icon")
    arrowIcon.classList.toggle("down")
})

type UserInfoDropdownProps = {
    user: any
    onLogout: Function
}

const UserInfoDropdown = ({ user, onLogout }: UserInfoDropdownProps) => {
    return (
        <ButtonToolbarStyle>
            <DropdownUserProfileStyle onToggle={toggleClass}>
                <ToggleUserProfileStyle id="menu-profile">
                    <ProfilePicture
                        picture={convertPhoto(user.foto)}
                        user={user.nome}
                        border={"4px solid rgba(255, 255, 255, 0.3)"}
                        height={40}
                        width={40}
                    />
                    <strong className="menu-profile--firstname ml-1">{user.nome}</strong>
                    <img src={arrowDownIcon} className="rotate YC-icon-svg" id="js-rotate-icon" />
                </ToggleUserProfileStyle>

                <DropdownProfileStyle className="profile-dropdown">
                    <div className="p-4">
                        <p className="text-muted mb-4">
                            This account is managed by <span className="text-clickable font-weight-bold">Yazigi</span>
                        </p>
                        <div className="d-flex flex-row flex-wrap justify-content-start">
                            <ProfilePictureStyle
                                picture={convertPhoto(user.foto)}
                                user={user.nome}
                                className={"img-fluid rounded-circle"}
                                height={80}
                                width={80}
                            />

                            <div className="d-flex flex-column flex-wrap mt-2">
                                <span className="font-weight-bold">{user.nome}</span>
                                <span className="font-weight-bold text-clickable small">{user.email}</span>
                                <Link to="/my-profile" className="mt-4 text-clickable font-weight-bold">
                                    Consult or edit your profile
                                    <img src={arrowRightGreenIcon} className="pl-3 YC-icon-svg" />
                                </Link>
                            </div>
                        </div>

                        <Dropdown.Divider />
                        <div className="d-flex flex-row flex-wrap justify-content-end pr-2 pt-2 mt-4">
                            <ButtonStyle green label="Logout" onClick={onLogout} icon="logout" />
                        </div>
                    </div>
                </DropdownProfileStyle>
            </DropdownUserProfileStyle>
        </ButtonToolbarStyle>
    )
}

export default UserInfoDropdown

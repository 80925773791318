import * as React  from "react"
import ProfileContainer from "YConnect/Containers/Profile.container"

import AboutYouForm           from "YConnect/Configs/Forms/AboutYou.form"
import ContactInformationForm from "YConnect/Configs/Forms/ContactInformation.form"
import AccountDetailsForm     from "YConnect/Configs/Forms/AccountDetails.form"

const ProfilePage = ({onLogout, match, onUpdateUserData}:any) => {

    const id = match.params.id

    const handleBeforeUpdate = (profile:any) => {

        onUpdateUserData({
            nomeCompleto : `${profile.nome_aluno} ${profile.sobrenome_aluno}`,
            nome         : profile.nome_aluno,
            email        : profile.email_aluno,
            foto         : profile.foto 
                                ? (
                                    typeof profile.foto == "object"
                                    ? String.fromCharCode.apply(null, profile.foto)
                                    : atob(profile.foto)
                                )
                                : ""           
    })
    }

    return  <ProfileContainer
                id                  = {id}
                label               = "Edit user:"
                disabledPictureEdit = {true}
                defaultTabKey       = "account-details"
                formAbout           = {AboutYouForm}
                formContact         = {ContactInformationForm}
                formAccount         = {AccountDetailsForm}
                onLogout            = {onLogout}
                onBeforeUpdate      = {handleBeforeUpdate}/>

}

export default ProfilePage
import * as React           from "react"
import { Modal, FormGroup } from "react-bootstrap"
import { ButtonStyle }      from "YConnect/Components/Button"
import styled from "styled-components";
import { useState, useEffect } from "react";
import API         from "YConnect/API"
import getConfig   from "YConnect/Utils/GetRequestConfig.util"

const ContainerDates = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;

    span {
        font-weight: bold;
        font-size: 18px;
    }
`

const TextAlert = styled.span`
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
`

const ButtonsOptions = styled.div`
    margin-top: 20px;

    button {
        justify-content: center;
    }
`

const MessageError = styled.span`
    color: red;
    text-align: center;
    font-size: 18px;
`

function converterFormatoData(dataString: string, mesDia?) {
    // Divide a string em três partes: ano, mês e dia
    const [ano, mes, dia] = dataString.split("-");

    // Cria uma nova string no formato "DD/MM/AAAA"
    if (mesDia) {
        const novaDataString = `${mes}/${dia}/${ano}`;
        return novaDataString;


    } else {
        const novaDataString = `${dia}/${mes}/${ano}`;
        return novaDataString;

    }

}

const ExpirationDateModal = ({
    hasShow,
    onConfirm,
    onClose,
    dataCourse
}:any) => {


    const [newDate, setNewDate] = useState('');
    const [dataInitial, setDataInitial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [dataInvalida, setDataInvalida] = useState(false);

    const converterStringParaData = ((dataString) => {
        const partesData = dataString.split("/");
        const dia = parseInt(partesData[0], 10);
        const mes = parseInt(partesData[1], 10) - 1;
        const ano = parseInt(partesData[2], 10);
        return new Date(ano, mes, dia);
    })

    const compararDatasComHoje = ((dataStringInicio, dataStringFinal, dateForRequest) => {
        const dataFornecidaInicio = converterStringParaData(dataStringInicio);
        const dataFornecidaFinal = converterStringParaData(dataStringFinal);

        if (dataFornecidaInicio > dataFornecidaFinal) {
            setDataInvalida(true)
        } else if (dataFornecidaInicio < dataFornecidaFinal) {
            setDataInvalida(false)

            API.Usuario.changeExpirationDate({idCursoMudanca: dataCourse.id, expirationDate: dateForRequest}, getConfig())
            .then(({data}:any) => window.location.reload())
        }
    })

    const updateExpirationDate = (() => {
        const format = converterFormatoData(newDate, true)
        const dateConverted = format.replaceAll('-', '/')


        const formatNewDate = converterFormatoData(newDate)
        const dateConvertedNewDate = formatNewDate.replaceAll('-', '/')

        compararDatasComHoje(dataInitial, dateConvertedNewDate, dateConverted)
    })

    useEffect(() => {
        if (dataCourse) {
            var getOnlyDate = dataCourse.data_final.split('T')
            var formatedDate = converterFormatoData(getOnlyDate[0])

            setDataFinal(formatedDate)

            var getOnlyDateInitial = dataCourse.data_inicial.split('T')
            var formatedDateInitial = converterFormatoData(getOnlyDateInitial[0])

            setDataInitial(formatedDateInitial)
        }
    }, [newDate])

    if (dataCourse) {
        var getOnlyDate = dataCourse.data_final.split('T')
        var formatedDate = converterFormatoData(getOnlyDate[0])
    }

    return  <Modal show={hasShow} onHide={onClose} size="lg" centered>
                    <Modal.Header closeLabel="Close" closeButton>
                        <h2>Edit Expiration Date</h2>

                    </Modal.Header>
                        <ContainerDates>
                            <span>Current Expiration Date: {formatedDate}</span>
                            <FormGroup style={{ minHeight: '60px', width: '45%' }}>
                                <label className="form-label" htmlFor="subjectInput">Select New Expiraction Date</label>
                                <input
                                    id="subjectInput"
                                    className="form-control"
                                    type="date"
                                    name="userName"
                                    placeholder="Select New Expiraction Date"
                                    maxLength={40}
                                    value={newDate}
                                    onChange={(e) => setNewDate(e.target.value)}
                                    required
                                />
                            </FormGroup>
                        </ContainerDates>
                        {dataInvalida && <MessageError>The new expiration date must be a date after start of the course: {dataInitial}</MessageError>}
                        <TextAlert>
                            Are you sure you want to change the expiration date?
                        </TextAlert>
                        <ButtonsOptions>
                            <div className="d-flex flex-row justify-content-center">
                                <ButtonStyle
                                    type      = "button"
                                    label     = "Cancel"
                                    size      = "medium"
                                    className = "mr-2"
                                    onClick   = {onClose} />
                                <ButtonStyle
                                    green
                                    label    = "Yes, I want to change the date"
                                    size     = "medium"
                                    onClick  = {updateExpirationDate}/>
                            </div>
                        </ButtonsOptions>
                </Modal>}

export default ExpirationDateModal

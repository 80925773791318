import { dispatchEvent } from "YConnect/Utils/Gtm.util";

export const user = {
    SigninClick: (userId:string) =>
        dispatchEvent({
            event: "login",
            userId,
        }),
    ResendUserId: (userId:string) =>
        dispatchEvent({
            userId,
        }),
};

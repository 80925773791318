import styled from "styled-components"

const StudentScoreStyle = styled.div`

    .student-score-border {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 8px;
        display:flex;
        min-height: 400px;
    }

    header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        h2 {
            color: #565656;
            font-size: 20px;
            font-weight: 600;
        }
    }

    tr {
        td:last-child {
            color: ${props => props.theme.main.mainPurple};
            font-weight: 600;
        }
    }
    .bestStudents {
        tr:first-child {
            td:last-child {
                color: ${props => props.theme.main.greentextColor};
            }
        }
    }

    .user-avatar {
        align-items: center;
        display: flex;
        flex-flow: row wrap;

        figure {
            margin-right: 8px;
        }
        .best-crown {
            background-color: #4e2d78;
            border-radius: 50%;
            height: 25px;
            margin-left: 25px;
            margin-top: -15px;
            position: relative;
            width: 25px;
            @media screen and (min-width: 1024px) {
                margin-top: 10px;
                position: absolute;
            }
            .white-icon {
                height: 25px;
                padding: 3px;
                width: 25px;
            }
        }
        .student-name {
            max-width: 100px;
            overflow: hidden !important;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
`

export default StudentScoreStyle
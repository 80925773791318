import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAllByExerciseId = (exerciseId:any) => 
    API2
    .Question
    .GetAllByExerciseId({exerciseId}, getConfig())

export const Create = (question:any) => 
    API2
    .Question
    .Create(question, getConfig())
    
export const UpdateById = (question:any) => 
    API2
    .Question
    .UpdateById(question, getConfig())

export const GetById = (questionId:any) => 
    API2
    .Question
    .GetById({questionId}, getConfig())

export const DeleteById = ({questionId}:any) => 
    API2
    .Question
    .DeleteById({questionId}, getConfig())
import * as React from "react"

import Input            from "YConnect/Fields/Forms/Input.field"
import DatePicker       from "YConnect/Fields/Forms/DatePicker.field"
import Select           from "YConnect/Fields/Forms/Select.field"
import Password         from "YConnect/Fields/Forms/Password.field"
import Radio            from "YConnect/Fields/Forms/Radio.field"
import Recaptcha        from "YConnect/Fields/Forms/Recaptcha.field"
import Textarea         from "YConnect/Fields/Forms/Textarea.field"
import Telephone        from "YConnect/Fields/Forms/Telephone.field"
import Zipcode          from "YConnect/Fields/Forms/Zipcode.field"
import EditorField      from "YConnect/Fields/Forms/Editor.field"
import LiveSearch       from "YConnect/Fields/Forms/LiveSearch.field"

/** Exercises Form Fields */
import ClickAudio       from "YConnect/Fields/Exercises/ClickAudio.field"
import DialoguePhrase   from "YConnect/Fields/Exercises/DialoguePhrase.field"
import DialogueLong     from "YConnect/Fields/Exercises/DialogueLong.field"
import DialogueAudio    from "YConnect/Fields/Exercises/DialogueAudio.field"
import MatchText        from "YConnect/Fields/Exercises/MatchText.field"
import ChooseMultiple   from "YConnect/Fields/Exercises/ChooseMultiple.field"
import DragDropTxtImg   from "YConnect/Fields/Exercises/DragDropTxtImg.field"
import TypeIn           from "YConnect/Fields/Exercises/TypeIn.field"
import TypeInOrdered    from "YConnect/Fields/Exercises/TypeInOrdered.field"
import Unscramble       from "YConnect/Fields/Exercises/Unscramble.field"
import DragDropImg      from "YConnect/Fields/Exercises/DragDropImg.field"
import VideoControlChooseMultiple from "YConnect/Fields/Exercises/VideoControlChooseMultiple.field"
import VideoControlDialogueLong from "YConnect/Fields/Exercises/VideoControlDialogueLong.field"
import OpenQuestion from "YConnect/Fields/Exercises/OpenQuestion.field"

/** PrePost Test */
import PrePostTest      from "YConnect/Fields/Exercises/PrePostTest.field"

import ExerciseTemplate from "YConnect/Enums/ExerciseTemplate"
import ExerciseDescription from "YConnect/Enums/ExerciseDescription"

//TODO Transforma em config
const COMPONENTS = {
    "DATE"          : DatePicker,
    "INPUT"         : Input,
    "PASSWORD"      : Password,
    "SELECT"        : Select,
    "RADIO"         : Radio,
    "RECAPTCHA"     : Recaptcha,
    "TEXTAREA"      : Textarea,
    "TEL"           : Telephone,
    "ZIPCODE"       : Zipcode,
    "EDITOR"        : EditorField,
    "LIVESEARCH"    : LiveSearch,


    /** Exercises Form Fields */
    [ExerciseTemplate.DIALOGO_FRASE]        : DialoguePhrase,
    [ExerciseTemplate.DIALOGO_LONGO]        : DialogueLong,
    [ExerciseTemplate.DIALOGO_AUDIO]        : DialogueAudio,
    [ExerciseTemplate.MATCH_TEXT]           : MatchText,
    [ExerciseTemplate.CHOOSE_MULTIPLE]      : ChooseMultiple,
    [ExerciseTemplate.DRAG_DROP_TEXT_IMG]   : DragDropTxtImg,
    [ExerciseTemplate.TYPE_IN]              : TypeIn,
    [ExerciseTemplate.TYPE_IN_ORDENADO]     : TypeInOrdered,
    [ExerciseTemplate.CLICK_AUDIO]          : ClickAudio,
    [ExerciseTemplate.UNSCRAMBLE]           : Unscramble,
    [ExerciseTemplate.DRAG_DROP_IMG]        : DragDropImg,
    [ExerciseTemplate.VIDEO_CHOOSE_MULTIPLE]: VideoControlChooseMultiple,
    [ExerciseTemplate.DRAG_DROP_VIDEO]      : VideoControlDialogueLong,
    [ExerciseTemplate.OPEN_QUESTION]        : OpenQuestion,

    /** Pre Post test */
    "PREPOSTTEST"       : PrePostTest
}

const getComponent = (type:string):any => {
    switch(type.toUpperCase()){
        case "DATE" :
            return DatePicker
        case "INPUT" :
            return Input
        case "PASSWORD" :
            return Password
        case "SELECT" :
            return Select
        case "RADIO" :
            return Radio
        case "RECAPTCHA" :
            return Recaptcha
        case "TEXTAREA" :
            return Textarea
        case "TEL" :
            return Telephone
        case "ZIPCODE" :
            return Zipcode
        case "EDITOR" :
            return EditorField
        case "LIVESEARCH" :
            return LiveSearch

        /** Exercises Form Fields */
        case ExerciseTemplate.DIALOGO_FRASE.toUpperCase():
            return DialoguePhrase
        case ExerciseTemplate.DIALOGO_LONGO.toUpperCase():
            return DialogueLong
        case ExerciseTemplate.DIALOGO_AUDIO.toUpperCase():
            return DialogueAudio
        case ExerciseTemplate.MATCH_TEXT.toUpperCase():
            return MatchText
        case ExerciseTemplate.CHOOSE_MULTIPLE.toUpperCase():
            return ChooseMultiple
        case ExerciseTemplate.DRAG_DROP_TEXT_IMG.toUpperCase():
            return DragDropTxtImg
        case ExerciseTemplate.TYPE_IN.toUpperCase():
            return TypeIn
        case ExerciseTemplate.TYPE_IN_ORDENADO.toUpperCase():
            return TypeInOrdered
        case ExerciseTemplate.CLICK_AUDIO.toUpperCase():
            return ClickAudio
        case ExerciseTemplate.UNSCRAMBLE.toUpperCase():
            return Unscramble
        case ExerciseTemplate.DRAG_DROP_IMG.toUpperCase():
            return DragDropImg
        case ExerciseTemplate.VIDEO_CHOOSE_MULTIPLE.toUpperCase():
            return VideoControlChooseMultiple
        case ExerciseTemplate.DRAG_DROP_VIDEO.toUpperCase():
            return VideoControlDialogueLong
        case ExerciseTemplate.OPEN_QUESTION.toUpperCase():
            return OpenQuestion

        /** Pre Post test */
        case "PREPOSTTEST":
            return PrePostTest

        default:
            return undefined
    }
}

/**
 * Encapsula os campos do Formulario
 *
 * @param {*} props
 */
const FieldWrapper = (props:FieldType) => {
    const component = getComponent(props.type)
    if(component){
        return React.createElement(component, props, null)
    }else{
        throw `The ${props.type.toUpperCase()} type field does not exist the available fields ${Object.keys(COMPONENTS).join(", ").toUpperCase()}`
    }

}

export default FieldWrapper

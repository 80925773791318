import styled from "styled-components"
import { Col } from "reactstrap"

import * as checked      from "YConnect/Assets/svg/checked.svg"
import * as checkedGreen from "YConnect/Assets/svg/checkedGreen.svg"
import * as remove       from "YConnect/Assets/svg/closeRed.svg"

const ExerciseDialogueStyle = styled(Col)`
    padding: 0 40px;

    .exercise-dialogue-enunciado {
        color: ${props => props.theme.main.textColor};
        border: 1px solid ${props => props.theme.main.lightBlue};
        margin-bottom: 1em;
    }

    .exercise-dialogue-text {
        position: relative;
        color: ${props => props.theme.main.textWhite};
        background: ${props => props.theme.main.lightBlue};
        border: 1px solid ${props => props.theme.main.lightIvory};
        padding: 15px 25px;
        border-radius: 10px;
        width: fit-content;

        .resposta {
            text-decoration: underline;
        }

        &.reverse {
            float: right;
            color: ${props => props.theme.main.textColor};
            background: ${props => props.theme.main.backgroundPage};

            &::before {
                right: -11px;
                left: unset;
                top: -1px;
                border: 11px solid;
                border-color: ${props => props.theme.main.lightIvory} transparent transparent transparent;
            }

            &::after {
                left: unset;
                right: -10px;
                border-color: ${props => props.theme.main.backgroundPage} transparent transparent transparent;
            }
        }

        &::before {
            content: " ";
            position: absolute;
            left: -11px;
            top: -1px;
            border: 11px solid;
            border-color: ${props => props.theme.main.lightIvory} transparent transparent transparent;
        }

        &::after {
            content: ' ';
            position: absolute;
            left: -10px;
            top: 0px;
            border: 11px solid;
            border-color: ${props => props.theme.main.lightBlue} transparent transparent transparent;
        }
    }

    .exercise-dialogue-options {
        width: fit-content;
        min-width: 150px;
    }

    &.reverse {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap-reverse;
    }

    &.right {
        .exercise-dialogue-text {
            border: 1px solid ${props => props.theme.main.successColor} !important;

            &::before {
                content: " ";
                position: absolute;
                left: -11px;
                top: -1px;
                border: 11px solid;
                border-color: ${props => props.theme.main.successColor} transparent transparent transparent;
            }

            &::after {
                left: -9px;
                border: 10px solid;
                border-color: ${props => props.theme.main.lightBlue} transparent transparent transparent;
            }

            &.reverse {
                &::before {
                    content: " ";
                    position: absolute;
                    right: -11px;
                    left: unset;
                    top: -1px;
                    border: 11px solid;
                    border-color: ${props => props.theme.main.successColor} transparent transparent transparent;
                }

                &::after {
                    left: unset;
                    right: -9px;
                    border-color: ${props => props.theme.main.backgroundPage} transparent transparent transparent;
                }
            }

            .text {
                &::before {
                    content: url(${checked});
                    position: absolute;
                    filter: ${props => props.theme.main.filterSvg};
                    right: 2px;
                    top: 2px;
                    transform: scale(0.6);
                }
            }

            &.reverse {
                .text:before {
                    content: url(${checkedGreen});
                    right: unset;
                    left: 5px;
                }
            }
        }

        .exercise-dialogue-options {
            label {
                color: ${props => props.theme.main.successColor};
            }

            select {
                border-bottom-color: ${props => props.theme.main.successColor};
            }
        }
    }

    &.wrong {
        .exercise-dialogue-text {
            border: 1px solid ${props => props.theme.main.errorColor} !important;

            &::before {
                content: " ";
                position: absolute;
                left: -11px;
                top: -1px;
                border: 11px solid;
                border-color: ${props => props.theme.main.errorColor} transparent transparent transparent;
            }

            &::after {
                left: -9px;
                border: 10px solid;
                border-color: ${props => props.theme.main.lightBlue} transparent transparent transparent;
            }

            &.reverse {
                &::before {
                    content: " ";
                    position: absolute;
                    right: -11px;
                    left: unset;
                    top: -1px;
                    border: 11px solid;
                    border-color: ${props => props.theme.main.errorColor} transparent transparent transparent;
                }

                &::after {
                    left: unset;
                    right: -9px;
                    border-color: ${props => props.theme.main.backgroundPage} transparent transparent transparent;
                }
            }

            .text {
                &::before {
                    content: url(${remove});
                    filter: ${props => props.theme.main.filterSvg};
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    transform: scale(0.7);
                }
            }

            &.reverse {
                .text:before {
                    right: unset;
                    left: 5px;
                }
            }
        }

        .exercise-dialogue-options {
            label {
                color: ${props => props.theme.main.errorColor};
            }

            select {
                border-bottom-color: ${props => props.theme.main.errorColor};
            }
        }
    }
`
export default ExerciseDialogueStyle
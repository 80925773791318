import * as React from "react"
import { useState, useEffect, Fragment } from "react"
import { Row, Col } from "reactstrap"
import { connect }            from "react-redux"
import { bindActionCreators } from "redux"

import { ButtonStyle } from "YConnect/Components/Button"
import CardNewStyled from "YConnect/PagesComponents/CMS/Styleds/CardNew.styled"

import QuestionCardHeader from "YConnect/PagesComponents/CMS/CardHeaders/Question.cardHeader"
import QuestionEditor     from "YConnect/PagesComponents/CMS/Components/QuestionEditor"
import QuestionView       from "YConnect/PagesComponents/CMS/Components/QuestionView"

import InputField  from "YConnect/Fields/Forms/Input.field"
import ExerciseTemplate from "YConnect/Enums/ExerciseTemplate"

type QuestionNewCardProps = {
    nextQuestionOrder      : number
    listTemplates          : Array<TemplateType>
    templateIdSelected     : number
    onSave                 : any
    onCancel               : any
    onToggleAdvantedEditor : any
    onChangeMagicUrl       : any
    magicQuestion          : any
    QueryParams            : any
    magicUrl               : string
}

const INTIAL_QUESTION_VALUES:QuestionType = {
    Content:null,
    Enunciated:"",
    Answers:[],
    Dialogues:[],
    ExerciseMedias:[]
}

const QuestionNewCard = ({
    magicQuestion,
    nextQuestionOrder,
    listTemplates,
    templateIdSelected,
    onSave,
    onCancel,
    onToggleAdvantedEditor,
    onChangeMagicUrl,
    QueryParams,
    magicUrl
}: QuestionNewCardProps) => {
    const [values, setValues] = useState({...INTIAL_QUESTION_VALUES, TemplateId:templateIdSelected})
    const [showPreview, setShowPreview] = useState<Boolean>(false)

    useEffect(()=>{
        if(magicQuestion){
            setValues(magicQuestion)
        }

    }, [magicQuestion])

    const isValidValues = () => {
        if (!values || !values.TemplateId || !listTemplates) {
            return false
        }

        const selectedTemplate = listTemplates.find((t) => t.Id === parseInt(values.TemplateId))

        return ((
                values.TemplateId == 8
                || values.TemplateId == 31
                || values.TemplateId == 32
                || selectedTemplate && selectedTemplate.Name === ExerciseTemplate.OPEN_QUESTION
                || values.Answers.length > 0
                )
                && values.Enunciated != null
                && values.Enunciated !== ""
                && values.Answers.reduce((acc:boolean, {Awnser}:AnswerType) => {
                        if (acc && Awnser && Awnser !== "") {
                            return true
                        } else {
                            return false
                        }
                }, true))
                && ((  values.TemplateId == 4
                    || values.TemplateId == 19
                    || values.TemplateId == 22
                    || values.TemplateId == 24
                    || values.TemplateId == 25
                    || values.TemplateId == 29
                ) ? values.Answers.filter(({IsCorret}) => IsCorret).length === 1
                    && values.Answers.length > 0
                : true)
                &&(
                    values.TemplateId == 6 ?
                    values.Answers.length == values.Dialogues[0].DialogueMessages.length
                    : true
                )
                // && ()//media templates
    }

    const handleSave = () => {delete values.nextQuestionOrder; onSave(values)}
    const handleChangeMagicUrl = (_:string, value:string) => onChangeMagicUrl(value)
    const handleChangeValues = (newValues:any) => setValues(newValues)

    const handleShowPreview = () => setShowPreview(true)
    const handleShowEditor  = () => setShowPreview(false)

    const isPreviewAvailable = () => {
        const selectedTemplate = listTemplates.find((t) => t.Id === parseInt(values.TemplateId))

        return values.Dialogues.length > 0 || values.Answers.length > 0 || (selectedTemplate && selectedTemplate.Name === ExerciseTemplate.OPEN_QUESTION && values.Enunciated)
    }

    return <CardNewStyled className="p-3">
                <QuestionCardHeader
                    questionOrder          = {nextQuestionOrder}
                    listTemplates          = {listTemplates}
                    templateIdSelected     = {templateIdSelected}
                    onToggleAdvantedEditor = {onToggleAdvantedEditor}
                    onCancel               = {onCancel}/>
                
                {/*
                    !showPreview
                    && <Fragment>
                        <hr/>
                        <InputField 
                            keystone="magicUrl"
                            className="col-md-12" 
                            label="url"
                            placeholder="paste the question url here"
                            onChange={handleChangeMagicUrl}/>
                        <hr/>
                    </Fragment>
                */}
                {
                    showPreview
                    && <QuestionView 
                        listTemplates      = {listTemplates}
                        question           = {values}/>
                }
                {
                    !showPreview
                    && (!magicUrl || magicUrl==="")
                    && <QuestionEditor 
                        question           = {{...values, nextQuestionOrder}}
                        listTemplates      = {listTemplates}
                        onChange           = {handleChangeValues}/>
                }

                <Row className="pt-3 justify-content-end">
                    <Col md="auto">
                        {
                            !showPreview
                            && <ButtonStyle
                            disabled = {!isPreviewAvailable()}
                            size      = "130"
                            onClick   = {handleShowPreview}
                            icon      = "eyesGreen"
                            label     = "Preview"/>
                        }
                         {
                            showPreview 
                            && <ButtonStyle
                            size      = "130"
                            onClick   = {handleShowEditor}
                            icon      = "pencilGreen"
                            label     = "Editor"/>
                        }
                    </Col>
                    <Col md="auto">
                        <ButtonStyle
                            disabled = {!isValidValues()}
                            green
                            size      = "130"
                            onClick   = {handleSave}
                            icon      = "save"
                            label     = "Save"/>
                    </Col>
                </Row>
        </CardNewStyled>
}


const mapDispatchToProps = (dispatch:any) => bindActionCreators({

}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionNewCard)

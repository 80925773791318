import styled from "styled-components"

const ImgStyle = styled.img`
    cursor: pointer;
    width: auto;
    height: auto;
    max-height: 100px;

    @media screen and (min-width: 768px) {
        max-width: 50%;
    }

    @media screen and (max-width: 767px) {
        width: 33vw;
    }
`

export default ImgStyle
import * as React from "react"
import qs from "querystring"
import styled from "styled-components"
import {Container, Card, Col, Row} from "reactstrap"

import * as warning from "YConnect/Assets/svg/warning.svg"

const OutputErrorStyle = styled(Col)`
    color: red;
    text-align: initial;
    border: 1px solid rgba(0, 0, 0, 0.125);
    tr>td:first-child{
        padding-right: 10px;
    }
`

const ServerErrorPage = ({location}:any) => {
        const error = qs.parse(location.search.substr(1))
        return <Container className="py-4">
                    <Card className="text-center">
                        <Row>
                            <Col><img src={warning} className="mb-3"/></Col>
                        </Row>
                        <h1>Server Error</h1>
                        <p>Sorry, something went wrong. Please go back, or contact support if needed.</p>
                        {error.path && error.errorType && error.message &&<OutputErrorStyle className="p-3">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><strong>path</strong></td>
                                        <td>{error.path}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>error type</strong></td>
                                        <td>{error.errorType}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>message</strong></td>
                                        <td>{error.message}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </OutputErrorStyle>}
                    </Card>
                </Container>
    
}

export default ServerErrorPage



import * as React from "react"

const CuriositiesIconSVG = () => 
<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
<g id="Travel-the-World" stroke="none" fill="none" fillRule="evenodd">
    <g id="View-country" transform="translate(-1440.000000, -1134.000000)" fillRule="nonzero">
        <g id="Tab" transform="translate(339.000000, 1095.000000)">
            <g id="1-copy-4">
                <g transform="translate(992.000000, 30.000000)">
                    <g id="star" transform="translate(109.000000, 9.000000)">
                        <path d="M29.9588378,10.9591574 C29.8603201,10.6536973 29.6017113,10.4355116 29.2876864,10.3856406 L19.9285116,9.03912296 L15.7168829,0.467540843 C15.5752638,0.180782459 15.2858683,0 14.9718433,0 C14.6578184,0 14.3684229,0.180782459 14.2268038,0.473774721 L10.0705913,9.07029235 L0.711416438,10.4791488 C0.397391494,10.5290198 0.138782716,10.7472055 0.0402650861,11.0526655 C-0.0582525436,11.3581255 0.0279503824,11.6947549 0.255772401,11.9129407 L7.0473315,18.5769561 L5.47720677,28.0150473 C5.42179061,28.3329751 5.551095,28.6509028 5.80970378,28.8379192 C5.95132287,28.9438951 6.12372872,29 6.29613457,29 C6.43159631,29 6.5609007,28.9688306 6.68404774,28.900258 L15.0395742,24.4180997 L23.4197301,28.8503869 C23.5428771,28.9127257 23.6721815,28.9438951 23.8014859,28.9438951 C24.2571299,28.9438951 24.6327284,28.5636285 24.6327284,28.1023216 C24.6327284,28.0337489 24.626571,27.9714101 24.608099,27.9090714 L22.9887155,18.5270851 L29.7371731,11.8256664 C29.9773098,11.6012468 30.0573554,11.2646174 29.9588378,10.9591574 Z M21.5232657,17.6356406 C21.3262305,17.8288908 21.2400275,18.1094153 21.2892863,18.3837059 L22.6993199,26.5687876 L15.42133,22.722485 C15.1811933,22.5915735 14.8917978,22.5978074 14.6455037,22.722485 L7.3921432,26.6124248 L8.75291796,18.4148753 C8.79601943,18.1405847 8.7098165,17.8662941 8.51278124,17.6730439 L2.62635287,11.8880052 L10.7479,10.6661651 C11.0188234,10.6225279 11.2528028,10.4542132 11.3697925,10.2048581 L14.9780007,2.74290628 L18.6354677,10.1861565 C18.7586147,10.4355116 18.9925941,10.6038263 19.2635176,10.6474635 L27.391222,11.8194325 L21.5232657,17.6356406 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>

export default CuriositiesIconSVG
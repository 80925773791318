import * as React from "react"
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker,
} from "react-simple-maps"

import { Col } from "reactstrap"

import BRAZIL_MAP from "YConnect/Configs/Sources/brazil"

import LegendMapStyle from "YConnect/PagesComponents/PrePostReport/Pearson.components/BestResultsByRegion/BrazilMap/LegendMap.style"

const getCircleColor = (color: any) => {
    if(color <= 60) return "#F7B700"
    else if (color > 60 && color <= 79) return "#00B5D3"
    else return "#643E94"
}

const BrazilMap = ({mapInfo}: any) => {
    
    //const wrapperStyles = {transform: `translate(10%, -25%)`}
    const wrapperStyles = {}

    return <Col md={6}>
                <div style={wrapperStyles}>
                    <ComposableMap
                        projectionConfig = {{ scale: 600 }}
                        width            = {500}
                        height           = {345}>
                        <ZoomableGroup center={[-43, -15]} >
                            <Geographies geography={BRAZIL_MAP}>
                                {(geographies, projection) => geographies.map((geography: any, key: any) => (
                                    <Geography
                                        key        = {key}
                                        geography  = {geography}
                                        projection = {projection}
                                        style      = {{
                                            default: {
                                                fill        : "#BFD747",
                                                stroke      : "#E1E6E7",
                                                strokeWidth : 1,
                                                outline     : "none",
                                            },
                                            hover: {
                                                fill        : "#BFD850",
                                                stroke      : "#E1E6E7",
                                                strokeWidth : 0.75,
                                                outline     : "none",
                                            },
                                            pressed: {
                                                fill        : "#BFD747",
                                                stroke      : "#E1E6E7",
                                                strokeWidth : 0.75,
                                                outline     : "none",
                                            },
                                        }}
                                    />
                                ))}
                            </Geographies>
                            <Markers>
                                {
                                    mapInfo && mapInfo.map((city: any, i: any) => (
                                        <Marker key={i} marker={city}>
                                            <circle
                                                cx          = {0}
                                                cy          = {0}
                                                r           = {city.mediaRegion <= 0 ? 1: ((city.mediaRegion * 100) / 7)}
                                                fill        = {getCircleColor((city.mediaRegion * 100).toFixed(1))}
                                                stroke      = "#fff"
                                                strokeWidth = "2"
                                            />
                                        </Marker>
                                    ))
                                }
                            </Markers>
                        </ZoomableGroup>
                    </ComposableMap>
                </div>

                <LegendMapStyle>
                    <div className="legend-line">
                        <div className="circle" style={{backgroundColor: "#643E94"}}></div>
                        <span>> 80%</span>
                    </div>
                    <div className="legend-line">
                        <div className="circle" style={{backgroundColor: "#00B5D3"}}></div>
                        <span>>= 70%</span>
                    </div>
                    <div className="legend-line">
                        <div className="circle" style={{backgroundColor: "#F7B700"}}></div>
                        <span> &#60; 70%</span>
                    </div>
                </LegendMapStyle>
            </Col>
}


export default BrazilMap
import * as React from "react"
import { useEffect } from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import Table from "YConnect/Components/Table"

import { ButtonStyle } from "YConnect/Components/Button"
import useEditableTableState from "YConnect/PagesComponents/CMS/Hooks/useEditableTableState"


const TableStyled = styled(Table)`
    .table {
        min-height: auto;
    }
    td, th {
        padding:10px;
        width: 15%;
        &:first-child {
            width: 70%;
        }
    }
`


const INITIAL_DATA_STATE = [
    {
        type:"NEW",
        row:{}
    }
]


const GetStateByDialogue = (dialogue:DialogueType) => {
    const {DialogueMessages} = dialogue
    return DialogueMessages.map(({Id,Text}) => ({
        type:"ADDED",
        row:{Id,Text}
    }))
}


const ItemsEditableTable = ({
    values,
    onChange
}:any) => {

    const {
        dataState,
        getValues,
        updateLine,
        addDataNewLine,
        removeLine,
        setDataState
    } = useEditableTableState((values && values.Dialogues[0]) ? GetStateByDialogue( values.Dialogues[0]):INITIAL_DATA_STATE)

    const handleUpdateRow = (key:number, property:string, value:any) => updateLine(key, property, value)
    const handleRemoveRow = (key:number) => removeLine(key)

    useEffect(()=>{
        if(dataState){
            const newDialogue = [
                {
                    ...values.Dialogues[0] ?values.Dialogues[0]:{},
                    ...{
                        DialogueMessages:dataState.filter(({type}:any) => type !== "NEW").map(({row}:any) => row)
                        .map((item, index) => {
                            return {
                                Id: item.Id,
                                OrderMessage:index+1,
                                OrderSubMessage:index+1,
                                Text:item.Text,
                            }
                        }),
                        DialogueAvatars:[]
                    }
                }
            ]

            const newValues = {
                ...values,
                Dialogues:newDialogue
            }

            onChange(newValues)
        }
    }, [dataState])


    const config : Array<ColumnConfigType> = [
        {
            label     : "Text",
            formatter : (state:any, key:number) =>
                <textarea
                    className="form-control"
                    rows={2}
                    onChange={({ target: { value } }:any) => handleUpdateRow(key, "Text", value)}
                    value={state.row["Text"] || ""}/>
        },
        {
            label     : "Correct Order",
            formatter : (state:any, key:number) => {
                return <p>{key + 1}</p>
            }
        },
        {
            formatter : (state:any, key:number) =>
            <Col>
                {
                    state.type === "ADDED"
                    && <Row className="px-0">
                        <ButtonStyle
                            className = "mr-2"
                            icon      = "remove"
                            size      = "forTable"
                            onClick   = {() => handleRemoveRow(key)}/>
                    </Row>
                }
            </Col>
        }
    ]

    return <TableStyled
                data={dataState}
                config={config} />

}

export default ItemsEditableTable

import * as React   from "react"

import * as clock    from "YConnect/Assets/svg/clock.svg"
import * as test     from "YConnect/Assets/svg/test.svg"
import * as question from "YConnect/Assets/svg/question.svg"

import LineProgressStyled from "YConnect/Styles/LineProgress.style"

import StudentContentCardStyle from "YConnect/PagesComponents/PrePostReport/Teacher.components/BestStudentScore/StudentContentCard.style"

const StudentContentCard = ({student}: any) => {

    return student 
        ? <StudentContentCardStyle>
            <div className="students-data">
                <div className="d-flex flex-row mr-2">
                    <img src={clock} alt="" />
                    <div className="student-data">
                        <span className="title">Duration:</span>
                        <span className="value">{Math.ceil(student.duracao / 60)} minutes</span>
                    </div>
                </div>
                <div className="d-flex flex-row mr-2">
                    <img src={test} alt="" />
                    <div className="student-data">
                        <span className="title">Correct answers:</span>
                        <span className="value">{student.respCorretas}</span>
                    </div>
                </div>
                <div className="d-flex flex-row mr-2">
                    <img src={question} alt="" />
                    <div className="student-data">
                        <span className="title">"I don't know" anwers:</span>
                        <span className="value">{student.respidk}%</span>
                    </div>
                </div>
            </div>
            <div className="percent">
                <LineProgressStyled className="bestStudent">
                    <span
                        className="progress-percentage"
                        style={{ marginLeft: `calc(${student.media}* 90%)` }}
                    >
                        {(student.media * 100).toFixed(1)}%
                    </span>
                    <div className="line" />
                    <div
                        className="line-progress"
                        style={{ width: `calc(${student.media}* 100%)` }}
                    />
                    <div
                        className="pointer-progress"
                        style={{ marginLeft: `calc(${student.media}* 100%)` }}
                    />
                    <div className="d-flex justify-content-between">
                        <span className="line-start">0</span>
                        <span className="line-start" style={{ marginLeft: `calc(0.70 * 90%)` }}>70%</span>
                        <span className="line-start">100%</span>
                    </div>
                </LineProgressStyled>
                <div className="percent-overall">
                    <span className="overall">Overall percentage:</span>
                    <span className="number" style={{color: student.media > 0.7 ? "#00A281" : "#FF1A4A"}}>
                        {student && (student.media * 100).toFixed(1)}%
                    </span>
                </div>
            </div>
        </StudentContentCardStyle>
        : null
}

export default StudentContentCard
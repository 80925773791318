export const maskForEmail = (key) => {
    var email = key.replace(/^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c
    );

    return email;
}

export const maskForFields = (key) => {
    if (key != null) {
        var field = key.replace(/^(.)(.*)$/,
        (_, a, b, c) => a + b.replace(/./g, '*') + c
        );
    }
    return field;
}
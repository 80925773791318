import styled from "styled-components"

const NavStyle = styled.nav`
    color: ${props => props.theme.main.textWhite};
    height: 55px;
    background: ${props => props.theme.main.mainPurple};
    position: fixed !important;

    @media screen and (min-width: 768px) {
        height: 80px;
        position: initial !important;
    }
`

export default NavStyle

import * as React from "react"
import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg"

const CONFIG_TABLE_LAST_SEARCHES:Array<ColumnConfigType> = [
    {
        keystone : "establishment",
        label    : "Establishment"
    },
    {
        keystone : "course",
        label    : "Course",
    },
    {
        keystone : "class",
        label    : "Class"
    },
    {
        className: "text-right p-0",
        formatter :  ({establishmentParam, courseParam, classParam, currentParam}:any) => 
            <a href={`#/reports/view-report-teacher/${establishmentParam}/${courseParam}/${classParam}${(currentParam === "true" || currentParam === "false")?"?current="+currentParam:""}`}>
                Consult again <img src={arrowRight} alt="" className="pl-2"/>
            </a>
    }

]

export default CONFIG_TABLE_LAST_SEARCHES
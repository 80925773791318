import * as React from "react";
import { Fragment } from "react";
import { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import ReactPaginate from "react-paginate";
import { Row, Col } from "reactstrap";
import { Tab } from "react-bootstrap";
import orderBy from "lodash/orderBy";
import { saveAs } from "file-saver";

import API from "YConnect/API";
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util";
import getConfig from "YConnect/Utils/GetRequestConfig.util";
import formatDate from "YConnect/Utils/FormatDate.util";

import { LinkStyle } from "YConnect/Components/Button";
import Table from "YConnect/Components/Table";

import AlphaBlackStyle from "YConnect/Styles/AlphaBlack.style";
import TabStyle from "YConnect/Styles/Tab.style";

import * as downloadGreen from "YConnect/Assets/svg/download.svg";
import Toastify from "toastify-js";

const CONFIG_TABLE_BOOK: Array<ColumnConfigType> = [
    {
        keystone: "livro",
        label: "Book",
    },
    {
        label: "Title & file",
        formatter: (data: any) => (
            <div className="download-title" title={data.titulo}>
                {data.titulo}
                <br />
                <small title={data.filename} className="text-muted">
                    {data.filename}
                </small>
            </div>
        ),
    },
    {
        label: "Type & weight",
        formatter: (data: any) => (
            <Fragment>
                {data.tipo}
                <br />
                <small className="text-muted">
                    {data.size}
                    {data.sizeMedida}
                </small>
            </Fragment>
        ),
    },
    {
        keystone: "date",
        label: "Date",
    },
    {
        label: "File",
        formatter: (data: any) => {
            return (
                <a
                    className="download-link"
                    href={getStaticUrl(`${!data.filename.includes('midia:') ? `arquivos_downloads/${data.filename}`:`${data.filename}`}`)}
                    download
                >
                    Download <img src={downloadGreen} />
                </a>
            );
        },
    },
];

const CONFIG_TABLE_EXPLORE: Array<ColumnConfigType> = [
    {
        label: "Title & file",
        formatter: (data: any) => (
            <div className="download-title" style={{ maxWidth: "300px" }}>
                {data.title_file}
                <br />
                <small className="text-muted">{data.name_file}</small>
            </div>
        ),
    },
    {
        label: "Type & weight",
        formatter: (data: any) => (
            <Fragment>
                {data.explore_type}
                <br />
                <small className="text-muted">{data.size_file} kb</small>
            </Fragment>
        ),
    },
    {
        label: "Date",
        formatter: (data: any) => formatDate(data.data_file, false),
    },
    {
        label: "File",
        formatter: (data: any) => {
            return (
                <span
                    className="download-link pointer text-clickable"
                    onClick={() =>
                        downloadItemExplore(data.exploreId, data.title_file)
                    }
                >
                    Download <img src={downloadGreen} />
                </span>
            );
        },
    },
];

const downloadItemExplore = async (exploreId: number, fileName: string) => {
    const idUsuario = JSON.parse(localStorage.getItem("data")).idUsuario;

    API.Downloads.ExploreDownload(
        {
            id_usuario: idUsuario,
            id_explore: exploreId,
        },
        {
            headers: {
                Authorization: localStorage.getItem("Authorization"),
            },
            responseType: "blob",
        }
    )
        .then((res: any) => {
            saveAs(res.data, `${fileName}.pdf`);
        })
        .catch((err: any) => {
            Toastify({
                text: "Error! <br>" + err.response.data.message,
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        });
};

const arrayUnique = (arr: any) =>
    arr.filter((item: any, index: any) => arr.indexOf(item) >= index);

const CourseDownloadModal = ({ onDownloadMenu, hasDownloadMenu }: any) => {
    const [downloadItems, setDownloadItems] = useState();
    const [paginatedItems, setPaginatedItems] = useState();
    const [filteredItems, setFilteredItems] = useState();

    const [isFiltering, setIsFiltering] = useState(false);
    const [isFilteringBook, setIsFilteringBook] = useState(false);
    const [filterBooks, setFilterBooks] = useState();
    const [filterTypes, setFilterTypes] = useState();
    const [filteredBook, setFilteredBook] = useState();

    const [exploreType, setExploreType] = useState();
    const [currentPage, setCurrentPage] = useState(0);

    const [schoolCards, setSchoolCards] = useState();
    const [isExplore, setExplore] = useState(false);

    const useOutsideClick = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onDownloadMenu(false);
            }
        };
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    };

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    useEffect(() => {
        const fetchData = async () => {
            try {
                API.Downloads.get({}, getConfig()).then((res: any) => {
                    setDownloadItems(res.data);
                    setFilteredItems(res.data);
                    paginateItems({ selected: 0 }, res.data);
                    const bookItens = res.data.map((item: any) => item.livro);
                    const typeItens = res.data.map((item: any) => item.tipo);
                    setFilterTypes(orderBy(arrayUnique(typeItens)));
                    setFilterBooks(orderBy(arrayUnique(bookItens)));
                    filterByBook("-", res.data);
                });
            } catch (error) {}
        };

        fetchData();
        fetchExplore();
        setExplore(JSON.parse(localStorage.getItem("data")).isEscolaExplore);
    }, []);

    useEffect(() => {
        fetchExplore(1);
    }, [exploreType]);

    const fetchExplore = async (page = 1) => {
        setCurrentPage(page - 1);
        const exploreResponse = await API.Downloads.Explore(
            {
                nomeFicha: "",
                // level: exploreLevel || '',
                type: exploreType || "",
                pagina: page,
            },
            getConfig()
        );

        setSchoolCards(exploreResponse.data.fichasExplorePaginado);
    };

    const filterByBook = async (e: any, data?: any) => {
        setIsFiltering(true);
        setIsFilteringBook(true);

        setFilteredItems(downloadItems);

        const itemsToFilter = downloadItems || data;

        const book = e.target !== undefined ? e.target.value : "All books";

        // if (book === 'all') {
        //     setFilteredItems(downloadItems)
        //     paginateItems({selected: 0}, downloadItems).then(() => {
        //         setIsFiltering(false);
        //         setIsFilteringBook(false)
        //     })
        // } else {
        //     const filteredSelectedItems = downloadItems.filter((item: any) => item.livro === book);
        //     setFilteredItems(filteredSelectedItems);
        //     setFilteredBook(filteredSelectedItems);
        //     paginateItems({selected: 0}, filteredSelectedItems).then(() => {
        //         setIsFiltering(false);
        //         setIsFilteringBook(false)
        //     })
        // }
        const filteredSelectedItems = itemsToFilter.filter(
            (item: any) => item.livro === book
        );
        setFilteredItems(filteredSelectedItems);
        setFilteredBook(filteredSelectedItems);
        paginateItems({ selected: 0 }, filteredSelectedItems).then(() => {
            setIsFiltering(false);
            setIsFilteringBook(false);
        });
    };

    const filterByType = async (e: any) => {
        setIsFiltering(true);
        setFilteredItems(downloadItems);

        const type = e.target !== undefined ? e.target.value : e;

        if (type === "all") {
            setFilteredItems(filteredBook);
            paginateItems({ selected: 0 }, filteredBook).then(() => {
                setIsFiltering(false);
            });
        } else {
            setFilteredItems(
                filteredBook.filter((item: any) => item.tipo === type)
            );
            paginateItems(
                { selected: 0 },
                filteredBook.filter((item: any) => item.tipo === type)
            ).then(() => {
                setIsFiltering(false);
            });
        }
    };

    const paginateItems = async (data: any, array?: any) => {
        const items = array ? array : filteredItems;
        const paginated = items.slice(
            data.selected * 10,
            (data.selected + 1) * 10
        );
        setPaginatedItems(paginated);
        await Promise.resolve(paginated);
    };

    const CourseDownloadContent = (helperClass?: string) => (
        <>
            <div className="header-filter">
                <span className="filter-label">Filter by</span>
                <div className="form-group header-filter--select">
                    <label
                        className="form-label text-clickable label-select"
                        htmlFor="filterBook"
                    >
                        Book
                    </label>
                    <select
                        id="filterBook"
                        className="form-control"
                        onChange={filterByBook}
                    >
                        {filterBooks &&
                            filterBooks.map((book: any, key: any) => (
                                <option
                                    value={book}
                                    key={key}
                                >{`${book}`}</option>
                            ))}
                    </select>
                </div>
                {!isFilteringBook && (
                    <div className="form-group header-filter--select">
                        <label className="form-label text-clickable label-select">
                            Type
                        </label>
                        <select
                            className="form-control"
                            onChange={filterByType}
                        >
                            <option value="all" key="all">
                                All
                            </option>
                            {filterTypes &&
                                orderBy(
                                    filterTypes,
                                    "type"
                                ).map((type: any, key: any) => (
                                    <option
                                        value={type}
                                        key={key}
                                    >{`${type}`}</option>
                                ))}
                        </select>
                    </div>
                )}
            </div>
            {window.innerWidth > 768 ? (
                !isFiltering && paginatedItems ? (
                    <>
                        <div
                            className={`table-responsive download-menu ${helperClass}`}
                            style={{ maxHeight: "70%" }}
                        >
                            <Table
                                data={paginatedItems}
                                config={CONFIG_TABLE_BOOK}
                            />
                        </div>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(
                                filteredItems && filteredItems.length / 10
                            )}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            containerClassName={"pagination YC-Pagination"}
                            activeClassName={"active"}
                            onPageChange={paginateItems}
                        />
                    </>
                ) : (
                    <p>Loading...</p>
                )
            ) : paginatedItems ? (
                <>
                    {!isFiltering ? (
                        paginatedItems.map((item: any, key: any) => {
                            return (
                                <div className="download-mobile" key={key}>
                                    <div className="book">
                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">Book</span>
                                            <span className="w-40">
                                                {item.livro}
                                            </span>
                                        </div>
                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">
                                                Title & file
                                            </span>
                                            <span className="w-40">
                                                {item.titulo} <br />
                                                <small>{item.filename}</small>
                                            </span>
                                        </div>
                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">
                                                Type & weight
                                            </span>
                                            <span className="w-40">
                                                {item.tipo}
                                                <br />
                                                <small>
                                                    {item.size}
                                                    {item.sizeMedida}
                                                </small>{" "}
                                                <br />
                                                <small>{item.date}</small>
                                            </span>
                                        </div>
                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">Date</span>
                                            <span className="w-40">
                                                {item.date}
                                            </span>
                                        </div>
                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">File</span>
                                            <a
                                                className="text-clickable w-40 d-flex flex-row align-items-center"
                                                href={getStaticUrl(
                                                    `arquivos_downloads/${item.filename}`
                                                )}
                                                target="_blank"
                                                download
                                            >
                                                Download{" "}
                                                <img
                                                    src={downloadGreen}
                                                    className="pl-3"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p>Loading...</p>
                    )}
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            filteredItems && filteredItems.length / 10
                        )}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        containerClassName={"pagination YC-Pagination"}
                        activeClassName={"active"}
                        onPageChange={paginateItems}
                    />
                </>
            ) : (
                <p>Loading...</p>
            )}
        </>
    );

    const ExploreContent = (helperClass?: string) => (
        <>
            <div className="header-filter  justify-content-start">
                <span className="filter-label mr-3">Filter by</span>
                <div className="form-group header-filter--select">
                    <label
                        className="form-label text-clickable label-select"
                        htmlFor="filterBook"
                    >
                        Type
                    </label>
                    <select
                        id="filterBook"
                        className="form-control"
                        onChange={(e) => setExploreType(e.target.value)}
                    >
                        <option value="">All</option>
                        <option value="art">Art</option>
                        <option value="body">Body</option>
                        <option value="business">Business</option>
                        <option value="tech">Tech</option>
                    </select>
                </div>
            </div>
            {window.innerWidth > 768 ? (
                !isFiltering && schoolCards ? (
                    <>
                        <div
                            className={`table-responsive download-menu ${helperClass}`}
                            style={{ maxHeight: "70%" }}
                        >
                            <Table
                                data={schoolCards.fichaExplore}
                                config={CONFIG_TABLE_EXPLORE}
                            />
                        </div>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(schoolCards.totalFichas / 10)}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            containerClassName={"pagination YC-Pagination"}
                            activeClassName={"active"}
                            onPageChange={(data: any) =>
                                fetchExplore(data.selected + 1)
                            }
                            forcePage={currentPage}
                        />
                    </>
                ) : (
                    <p>Loading...</p>
                )
            ) : schoolCards ? (
                <>
                    {!isFiltering ? (
                        schoolCards.fichaExplore.map((item: any, key: any) => {
                            return (
                                <div className="download-mobile" key={key}>
                                    <div className="book">
                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">Type</span>
                                            <span className="w-40">
                                                {item.explore_type}
                                            </span>
                                        </div>

                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">
                                                Title & file
                                            </span>
                                            <span className="w-40">
                                                {item.title_file} <br />
                                                <small>{item.name_file}</small>
                                            </span>
                                        </div>
                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">
                                                Type & weight
                                            </span>
                                            <span className="w-40">
                                                {item.explore_type}
                                                <br />
                                                <small>
                                                    {item.size_file} kb
                                                </small>{" "}
                                                <br />
                                            </span>
                                        </div>

                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">Date</span>
                                            <span className="w-40">
                                                {formatDate(
                                                    item.data_file,
                                                    false
                                                )}
                                            </span>
                                        </div>

                                        <div className="book-row d-flex flex-row">
                                            <span className="w-40">File</span>
                                            <span
                                                className="download-link pointer text-clickable"
                                                onClick={() =>
                                                    downloadItemExplore(
                                                        item.exploreId,
                                                        item.title_file
                                                    )
                                                }
                                            >
                                                Download{" "}
                                                <img src={downloadGreen} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p>Loading...</p>
                    )}
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(schoolCards.totalFichas / 10)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        containerClassName={"pagination YC-Pagination"}
                        activeClassName={"active"}
                        onPageChange={(data: any) =>
                            fetchExplore(data.selected + 1)
                        }
                        forcePage={currentPage}
                    />
                </>
            ) : (
                <p>Loading...</p>
            )}
        </>
    );

    return (
        <>
            {hasDownloadMenu && <AlphaBlackStyle />}
            <CSSTransition
                classNames="dialog YC-lateral-menu"
                timeout={300}
                in={hasDownloadMenu}
                unmountOnExit
            >
                <div
                    style={{ zIndex: 10000 }}
                    className={`YC-lateral lateral-header lateral-header-course d-flex flex-column`}
                    ref={wrapperRef}
                >
                    <h4 className="lateral-header--title">
                        Downloads
                        <LinkStyle
                            label="Close x"
                            className={
                                "close-modal text-right text-right header-btn"
                            }
                            onClick={() => onDownloadMenu(!hasDownloadMenu)}
                        />
                    </h4>
                    {isExplore ? (
                        <div className="d-flex flex-column justify-content-between w-100">
                            <TabStyle
                                className="explore-tab"
                                defaultActiveKey="download"
                                id="explore-tab"
                            >
                                <Tab
                                    eventKey="download"
                                    title={"Course Downloads"}
                                    className="yc-tab"
                                >
                                    <Row className="mb-0 mr-0">
                                        <Col
                                            xs={12}
                                            className="download-header-tab"
                                        >
                                            {CourseDownloadContent(
                                                "download-tab"
                                            )}
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab
                                    eventKey="explore"
                                    title="Explore Downloads"
                                    className="yc-tab"
                                >
                                    <Row className="mb-0 mr-0">
                                        <Col
                                            xs={12}
                                            className="download-header-tab"
                                        >
                                            {ExploreContent("download-tab")}
                                        </Col>
                                    </Row>
                                </Tab>
                            </TabStyle>
                        </div>
                    ) : (
                        CourseDownloadContent()
                    )}
                </div>
            </CSSTransition>
        </>
    );
};

export default CourseDownloadModal;

import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;

  button:first-child {
    margin-right: 1em;
  }

  button:nth-child(3) {
    margin-left: 1em;
  }
`
export default Wrapper
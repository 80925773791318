
import * as React           from "react"
import { Modal, FormGroup } from "react-bootstrap"
import { ButtonStyle }      from "YConnect/Components/Button"
import { BackLinkStyle } from "YConnect/Styles/BackLink.style"

import styled from "styled-components";

import { YLinkButtonStyle }  from "YConnect/Components/Button"

import * as world from "YConnect/Assets/svg/world.svg"

/** Style */
const GeneralStyle = styled(Modal)`
    .modal-content {
        width: 120% !important;
    }
`

const BodyStyle = styled(Modal.Body)`
    background: ${props => props.theme.main.backgroundWhite};
    padding: 20px !important;
    border-radius: 10px 10px 0 0 !important;

    h1 {
        text-align: center;
        margin-top: 30px;
    }

    .body-message {
        text-align: center;
        margin-top: 25px;
    }
`

const DivOvalStyle = styled.div`
    border-radius: 100%;
    box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.11);
    width: 204px;
    height: 204px;
    background: #643e94;
    padding: 19px;
    margin: auto;
    margin-top: -115px;

    div {
        background: white;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.11);
        text-align: center;
        padding: 30px;

        img {
            margin: auto;
            width: 100%;
            height: auto;
        }
    }
`

const FooterStyle = styled(Modal.Footer)`
    background: ${props => props.theme.main.backgroundWhite};
    justify-content: space-between;
`

const ButtonCloseStyle = styled(YLinkButtonStyle)`
    border: none;
    padding: 0;

    img {
        padding-left: 10px !important;
        width: 25px;
    }
`

const AlignTextStudent = styled.div`
    display: flex;
    flex-direction: column;
`

const AlertReportStudent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    p {
        text-align:center;
        font-size: 20px;
    }

    button {
        justify-content: center;
    }
`

type NextUnitProps = {
    isShow: boolean
    onClose: () => void
}

const NextUnit = ({ isShow, onClose }: NextUnitProps) => {

    return <>
        <GeneralStyle show={isShow} onHide={onClose} className="modal-background first-access">
            <Modal.Header closeLabel={'Close'} closeButton className="first-access" style={{zIndex: 10}}></Modal.Header>
            <BodyStyle>
                <AlertReportStudent className="w-100 ">
                    <p>Hey!</p>
                    <p>Fill at the last one of the fields.</p>
                    {/* <button onClick={onClose}>Ok</button> */}
                    <ButtonStyle
                        green
                        label="Ok"
                        className="ml-1 mt-1 w-45"
                        onClick={onClose}
                    />
                </AlertReportStudent>
            </BodyStyle>
        </GeneralStyle>
    </>
    }

export default NextUnit

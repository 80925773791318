import styled from "styled-components"

const ProgressPercentStyle = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding: 30px 18px;
    width: 100%;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }

    .percent {
        width: 100%;
        @media screen and (min-width: 1024px) {
        width: 60%;
        }
    }

    .percent-title {
        font-size: 42px;
        margin-top: 10px;
    }

    .percent-progress {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 14px;
        position: relative;
        width: 100%;
        color: #565656;
    }
    .percent-counter {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .overall {
        align-items: flex-end;
        margin-left: 24px;
        text-align: center;
        width: 100%;
        @media screen and (min-width: 1024px) {
            width: calc(40% - 24px);
        }

        small {
            color: #565656;
            font-size: 17px;
            font-weight: 600;
        }
    }
`

export default ProgressPercentStyle
import styled from "styled-components"

const ClassTeacherStyle = styled.div`

    margin-bottom: 16px;
    width: 100%;
    @media screen and (min-width: 1024px) {
        margin-bottom: 0;
        width: 30%;
    }

    .titlepage {
        color: #565656;
        font-size: 20px;
        font-weight: 600;
    } 

`

export default ClassTeacherStyle
import * as React            from "react"
import {UncontrolledTooltip} from "reactstrap"
import orderBy               from "lodash/orderBy"

import * as crown     from "YConnect/Assets/svg/crown.svg"

import ProfilePictureById from "YConnect/PagesComponents/PrePostReport/Components/ProfilePictureById"

const TableBestRankedStudents = ({
    isPost,
    rankedStudentEvo,
    bestRankedStudents
}:any) => 
    <div className="table-responsive table-border">
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Class</th>
                    <th scope="col">Teacher</th>
                    <th scope="col">Correct answers</th>
                    <th scope="col">{rankedStudentEvo === "evolution" ? "Evolution" : "Score"}</th>
                </tr>
            </thead>
            <tbody>
                {
                    (
                        rankedStudentEvo === "evolution"
                        ? orderBy(bestRankedStudents, "evolucao", "desc")
                        : bestRankedStudents
                    )
                    .filter(({flIsPre}:any) => flIsPre === !isPost)
                    .map((student: any, key: any) => {
                        return <tr key={key}>
                            <td className="user-avatar">
                                <h4>{key + 1}</h4>
                                <ProfilePictureById
                                    height    = {42}
                                    idUsuario = {student.idaluno}
                                    width     = {42}/>
                                <span className="student-name" id={`${isPost?"post":"pre"}-tooltip-${key}-${student.idaluno}`}>{student.nome}</span>
                                {key === 0
                                    ? <div className="best-crown ">
                                        <img src={crown} className="white-icon" />
                                    </div>
                                    : null
                                }
                                <UncontrolledTooltip placement="top" target={`${isPost?"post":"pre"}-tooltip-${key}-${student.idaluno}`}>
                                    {student.nome}
                                </UncontrolledTooltip >
                            </td>
                            <td>{student.nomeTurma}</td>
                            <td>
                                <span className="best-teacher" id={`${isPost?"post":"pre"}-teacher-${key}`}>{student.nomeProfessor}</span>
                                <UncontrolledTooltip placement="top" target={`${isPost?"post":"pre"}-teacher-${key}`}>
                                    {student.nomeProfessor}
                                </UncontrolledTooltip >
                            </td>
                            <td>{student.respCorretas}</td>
                            <td>
                                {
                                    rankedStudentEvo === "evolution"
                                        ? `${student.evolucao}%`
                                        : `${(student.media * 100).toFixed(1)}%`
                                }
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    </div>

export default TableBestRankedStudents
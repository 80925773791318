import * as React                        from "react"
import {useState, useEffect, useContext} from "react"
import {Container, Row, Col}             from "reactstrap"
import styled                            from "styled-components"

import API         from "YConnect/API"
import { BreadcrumbReintegrateStudent } from "YConnect/Components/BreadCrumbList.tsx"
import Loader      from "YConnect/Components/Loader"
import UserContext from "YConnect/User.context"
import getConfig   from "YConnect/Utils/GetRequestConfig.util"

import SelectField   from "YConnect/Fields/Forms/Select.field"
import TextAreaField from "YConnect/Fields/Forms/Textarea.field"

import {ButtonStyle} from "YConnect/Components/Button"

import HistoricoDeIntegracaoModal     from "YConnect/Modals/HistoricoDeIntegracao.modal"
import ConfirmacaoDeReintegracaoModal from "YConnect/Modals/ConfirmacaoDeReintegracao.modal"
import Toastify from "toastify-js"

const NewButtonStyle = styled(ButtonStyle)`
    width:auto!important;
`

const ReintegrateStudentPage = ({match}:any) => {

    const [listCourses, setListCourses]                  = useState()
    const [student, setStudent]                          = useState()

    const [idCourse, setIdCourse]                        = useState()
    const [motive, setMotive]                            = useState()

    const [hasLoading, setHasLoading]                     = useState(true)
    const [showModalHistory, setShowModalHistory]         = useState(false)
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
    const [hasShowForm, setHasShowForm]                   = useState(true)

    const {idUsuario}:any               = useContext(UserContext)

    const {idAluno} = match.params

    useEffect(() => {
        API.Aluno.Buscar({id:idAluno, opt:1}, getConfig())
        .then(({data}:any) => setStudent(data))


        API.Usuario.GetCursos({idAluno, opt:1}, getConfig())
        .then(({data}:any) => setListCourses(data))
    }, [])

    useEffect(()=>{
        if(student) setHasLoading(false)
    }, [student])


    const handleConfirmReintegrate = () => {

        setShowModalConfirmacao(false)
        setHasLoading(true)

        API.ZerarNota.Put({
            codigoResponsavel : idUsuario,
            codigoUsuario     : idAluno,
            codigoCurso       : idCourse,
            descricao         : motive
        })
        .then(({data}:any) => {


            setHasShowForm(false)
            setTimeout(()=>  setHasShowForm(true), 500)

            Toastify({
                text: data,
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();

        })
        .catch(({response:{data}}:any) => {

            Toastify({
                    text: data,
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
        })
        .finally(()=> setHasLoading(false))

    }

    const handleInputChange = (keystone:string, value:any) =>{
        if(keystone === "course")
            setIdCourse(value)
        else if(keystone === "motive")
            setMotive(value)
    }

    return  <Container>
                <BreadcrumbReintegrateStudent />
                {student
                    && <Row className="mb-0 mr-0">
                        <Col md={12}>
                            <h2>Reset Activities : {student.nome_aluno+" "+student.sobrenome_aluno}</h2>
                        </Col>
                    </Row>}
                    {hasShowForm && <Row className="mb-0 mr-0 justify-content-center">
                        <Col md={6}>
                            <Row>
                                <SelectField
                                    keystone  = "course"
                                    className = "col-md-12"
                                    label     = "Which course do you want to reset student activity for?"
                                    onChange  = {handleInputChange}
                                    values    = {listCourses?listCourses.map(({id_curso, titulo_curso}:any) => ({id:id_curso, value:titulo_curso})):[]}/>

                                <TextAreaField
                                    keystone  = "motive"
                                    label     = "Why are you resetting student activities?"
                                    className = "col-md-12"
                                    onChange  =  {handleInputChange}/>
                            </Row>
                            <Row className="justify-content-end mb-3 px-0 mx-0">
                                <NewButtonStyle
                                    label="History of Reset "
                                    onClick={() => setShowModalHistory(true)}/>
                            </Row>
                            <Row className="justify-content-around mb-3">
                                <ButtonStyle
                                    green
                                    label="Cancel"
                                    onClick={() => window.location.href = "#/manage-users"}/>
                                <ButtonStyle
                                    green
                                    disabled = {!(idCourse && idCourse !== "" && motive && motive !== "")}
                                    label="Reset Activities"
                                    onClick={() => setShowModalConfirmacao(true)}/>
                            </Row>

                        </Col>
                    </Row>}

                {hasLoading && <Loader/>}

                {
                    <HistoricoDeIntegracaoModal
                            hasShow={showModalHistory}
                            idAluno = {idAluno}
                            onClose={() => setShowModalHistory(false)}/>

                }

                <ConfirmacaoDeReintegracaoModal
                    hasShow   = {showModalConfirmacao}
                    onConfirm = {handleConfirmReintegrate}
                    onClose   = {() => setShowModalConfirmacao(false)}/>
            </Container>
}

export default ReintegrateStudentPage

import {useEffect} from "react"

const useOutsideClick = (ref:any, onClose:Function) => {
  const onOutsideClick = (event:any) => {
      if (ref.current && !ref.current.contains(event.target)) {
          onClose()
      }
  }

  useEffect(() => {
      document.addEventListener("mousedown", onOutsideClick)
      return () => document.removeEventListener("mousedown", onOutsideClick)
  })
}

export default useOutsideClick
import * as React from "react"
import { Modal }  from "react-bootstrap"
import { Row, Col, FormGroup } from "reactstrap"
import {useEffect, useState} from "react"
import styled from "styled-components"
import Loader from "YConnect/Components/Loader"
import Table from "YConnect/Components/Table"
import AudioPlayer  from "YConnect/Components/AudioPlayer"
import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"

import * as FileResource from "YConnect/ResourcesBackEnd/File.resources"

import { ButtonStyle } from "YConnect/Components/Button"
import Toastify from "toastify-js"

const AudioPlayerStyled = styled(AudioPlayer)`
    width:250px;
`

const RowStyled = styled(Row)`
    overflow:auto;
    height: 450px;
`

const MAX_FILE_SIZE = 5*1024*1024

let files = 0

type AudioFilesModalProps = {
    onSelect:any
    onClose:any
}

const AudioFilesModal = ({
    onSelect,
    onClose
}:AudioFilesModalProps) =>{

    const [searchTitle, setSearchTitle]       = useState<string>()
    const [listAudioFiles, setListAudioFiles] = useState([])
    const [listFiltered, setListFiltered]     = useState([])
    const [pageIndex, setPageIndex]           = useState(0)
    const [hasError, setHasError]             = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [fileForUpload, setFileForUpload] = useState([])

    const MAX_PER_PAGE = 100

    const checkFileSize = (file:any) => {
        if (file.size > MAX_FILE_SIZE) {
            Toastify({
                text: "File size exceeds 5 MB.",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            setHasError(true)
            setFileForUpload([])
        } else {
            setHasError(false)
            fileForUpload.push(file)
            setFileForUpload(fileForUpload)
        }
    }

    const totalPages =
    ((
        listFiltered.length - (listFiltered.length % MAX_PER_PAGE)
    )/MAX_PER_PAGE)
    + (listFiltered.length % MAX_PER_PAGE === 0 ? 0:1)

    useEffect(()=>{
        setLoading(true)
        updateFileList()
        setLoading(false)
    }, [])

    useEffect(() =>{
        if(listAudioFiles){
            setListFiltered(listAudioFiles)
        }
    }, [listAudioFiles])

    useEffect(() => {
        if(searchTitle && searchTitle !== ""){
            setListFiltered(listAudioFiles
                .filter(({Title}:any) => Title.indexOf(searchTitle) > -1))
        }else{
            setListFiltered(listAudioFiles)
        }

    }, [searchTitle])

    const config : Array<ColumnConfigType> = [
        {
            label     : "Title",
            keystone  : "Title"
        },
        {
            formatter : (row:any) => {
                return <AudioPlayerStyled className="px-0" src={getStaticUrlAssests(row.Title)}/>
            }

        },
        {
            formatter : ({Title, Type}:any, key:any) =>
            <Col>
                <Row className="px-0">
                        <ButtonStyle
                            className = "mr-2"
                            icon      = "plusGreen"
                            size      = "forTable"
                            onClick   = {() => handleSelectMedia({Title, Type})}/>
                </Row>
            </Col>
        }
    ]

    const updateFileList = () => {
        FileResource
        .GetAll()
        .then(({data}) => {
            setListAudioFiles(data
                .filter(({Type}:any) => Type.toLowerCase() === "audio")
                .sort((a:any, b:any) => {
                    if ((new Date(a.Date)).getTime() > (new Date(b.Date)).getTime()) {
                        return -1
                    }
                    if ((new Date(a.Date)).getTime() < (new Date(b.Date)).getTime()) {
                        return 1
                    }
                    return 0
                }))
                setFileForUpload([])
        })
    }

    const uploadFile = async () => {
        if(fileForUpload && fileForUpload.length>0){
            setLoading(true)
            fileForUpload.forEach(async file=>{
                const data = new FormData()
                data.append("file[]", file)
                try{
                    const response = await FileResource
                    .Upload(data)
                    .then(({data}:any) => {
                        const {path} = data
                    // onSelect({Source:path, MediaType:"audio"})
                    })
                }catch(e){
                    Toastify({
                        text: e.response.data.error,
                        duration: 3000,
                        className: "toastify-warning",
                        newWindow: true,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        escapeMarkup: false,
                        onClick: function(){} 
                      }).showToast();
                }finally{
                    files+=1
                    if(fileForUpload.length == files){
                        files = 0
                        setLoading(false)
                        updateFileList()
                    }
                }
            })
        }
    }

    const handleUploadFile = () => uploadFile()

    const handleSelectMedia = ({Title, Type}:any) =>
        onSelect({Source:Title, MediaType:Type})


    return <Modal show={true} onHide={onClose} size="lg">
                {isLoading && <Loader/>}
                <Modal.Header
                    closeButton
                    closeLabel={"Close"}
                    className="first-access"
                    style={{zIndex: 10}}>
                        Audio Files
                </Modal.Header>
                <Modal.Body className="d-flex flex-column justify-content-center">
                    <Row>
                        <Col md="auto">
                            <FormGroup>
                                <label className="form-label">Upload New Audio</label>
                                <input
                                    type     = "file"
                                    accept   = ".mp3"
                                    multiple
                                    onChange = {(e) => {
                                        setFileForUpload([])
                                        for(let i=0; i< e.target.files.length;i++){
                                            checkFileSize(e.target.files[i])
                                        }
                                    }}/>
                             {hasError && (
                                <small className="form-text text-danger mb-3 ml-3">
                                    File size exceeds 5 MB. Please, try again!
                                </small>
                            )}
                            </FormGroup>
                        </Col>
                        <Col md="auto">
                        <ButtonStyle
                            green
                            disabled  = {!fileForUpload}
                            size      = "190"
                            onClick   = {handleUploadFile}
                            label     = "Upload"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <input
                                type="text"
                                placeholder="search audio"
                                className="form-control"
                                onChange={({ target: { value } }:any) => setSearchTitle(value)}/>
                        </Col>
                        {
                            listFiltered.length > 0 && <Col md="auto">
                                <strong>{listFiltered.length} audio files found</strong>
                            </Col>
                        }
                    </Row>
                    <RowStyled>
                        <Table
                            data={listFiltered.slice(pageIndex, MAX_PER_PAGE)}
                            config={config} />
                    </RowStyled>
                </Modal.Body>
            </Modal>
}


export default AudioFilesModal

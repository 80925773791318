import styled from "styled-components"

const HomeworkLabelStyle = styled.span`
    background-color: ${props => props.theme.main.lightPurpleInvert};
    border-radius: 20px;
    color: ${props => props.theme.main.textWhite2};
    font-size: 0.90rem;
    padding: 2px 16px;
    border-radius: 5px;
    left: -10px;
    padding: 0 8px;
    position: absolute;
    top: 12px;
    z-index: 5;

    &:before {
        border-top: 10px solid ${props => props.theme.main.lightPurpleInvert};
        border-left: 10px solid transparent;
        content: "";
        display: block;
        height: 0;
        left: 0px;
        position: absolute;
        top: 18px;
        width: 0;

    }
`

export default HomeworkLabelStyle
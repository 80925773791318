import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Col, Row } from 'reactstrap'
import styled from "styled-components"

const LinkStyled = styled(Link)`
    color: ${(props) => props.theme.main.textInvert} !important;
`

const DivStyled = styled(Row)`
    margin: 0;
    div { max-width: 100% !important}
    padding-right: 0px !important;

    img {
        width: 50% !important;

        @media screen and (max-width: 768px) {
            width: 100% !important;
        }

        filter: ${props => props.theme.main.filterSvg};
    }

    p {
        @media screen and (max-width: 768px) {
            padding: 0 !important;
        }
    }

    &.interaction-content {
        h2,h3 {
            color: ${props => props.theme.main.lightPurpleInvert} !important;
        }

        p{
            color: ${props => props.theme.main.textBlocked} !important;
        }
    }
`

const InteractionTeacher = ({
    nome,
    balao,
    aula
}: any) => {
    const [contrast, setContrast] = useState("");
    useEffect(() => {
        setContrast(localStorage.getItem("contrast"))
    }, []);

    return (
        <DivStyled className="interaction-content">
            <Row>
                <div className="col-7">
                    <h2>{nome}, </h2>
                    <h3>
                        you have to schedule this class with the
                        student.
                    </h3>
                </div>
                <div className="col-5">
                    <img src={balao} alt=""/>
                </div>
                <Col>
                    <p>
                        To schedule this class, contact the student via My messages or e-mail.
                        Find the guidelines for this class in the Resource area.
                    </p>
                    <LinkStyled
                        className={`btn btn-success ${contrast}`}
                        to="/my-message"
                    >
                        Access Direct Messages
                    </LinkStyled>
                </Col>
            </Row>
        </DivStyled>)
}
export default InteractionTeacher

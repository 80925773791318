
import getBaseURLWebApi from "YConnect/Utils/GetBaseURLWebAPI.util"

function replaceAll(str:string, needle:string, replacement:string) {
    return str.split(needle).join(replacement);
}

const GetNewHtmlReplaceImgUtil = (html:string) => {
    if (html) {
        html = replaceAll(html, "src=\"img/", `src="${getBaseURLWebApi()}/img/`)
    }

    return html
}


export default GetNewHtmlReplaceImgUtil

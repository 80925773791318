import * as React              from "react"
import { useState, useEffect } from "react"
import { Link }                from "react-router-dom"
import styled                  from "styled-components"


import * as filter from "YConnect/Assets/svg/filter.svg"
import * as arrowLeft from "YConnect/Assets/svg/arrow-left.svg"
import * as arrowDown from "YConnect/Assets/svg/arrow-down.svg"

import * as Imagem1_technical_supporte from "YConnect/Assets/jpeg/Imagem1-technical-supporte.jpg";
import * as Imagem2_technical_supporte from "YConnect/Assets/jpeg/Imagem2-technical-supporte.jpg";
import * as Imagem3_technical_supporte from "YConnect/Assets/jpeg/Imagem3-technical-supporte.jpg";
import * as Imagem4_technical_supporte from "YConnect/Assets/jpeg/Imagem4-technical-supporte.jpg";
import * as Imagem5_technical_supporte from "YConnect/Assets/jpeg/Imagem5-technical-supporte.png";
import * as Imagem6_technical_supporte from "YConnect/Assets/jpeg/Imagem6-technical-supporte.png";
import * as Imagem7_technical_supporte from "YConnect/Assets/jpeg/Imagem7-technical-supporte.png";
import * as Imagem8_technical_supporte from "YConnect/Assets/jpeg/Imagem8-technical-supporte.png";
import * as Imagem9_technical_supporte from "YConnect/Assets/jpeg/Imagem9-technical-supporte.png";
import * as Imagem10_technical_supporte from "YConnect/Assets/jpeg/Imagem10-technical-supporte.png";
import * as Imagem11_technical_supporte from "YConnect/Assets/jpeg/Imagem11-technical-supporte.png";
import * as Imagem12_technical_supporte from "YConnect/Assets/jpeg/Imagem12-technical-supporte.png";
import * as Imagem13_technical_supporte from "YConnect/Assets/jpeg/Imagem13-technical-supporte.png";
import * as Imagem14_technical_supporte from "YConnect/Assets/jpeg/Imagem14-technical-supporte.png";
import * as Imagem15_technical_supporte from "YConnect/Assets/jpeg/Imagem15-technical-supporte.png";
import * as Imagem16_technical_supporte from "YConnect/Assets/jpeg/Imagem16-technical-supporte.png";
import * as Imagem17_technical_supporte from "YConnect/Assets/jpeg/Imagem17-technical-supporte.jpg";
import * as Imagem18_technical_supporte from "YConnect/Assets/jpeg/Imagem18-technical-supporte.jpg";
import * as Imagem19_technical_supporte from "YConnect/Assets/jpeg/Imagem19-technical-supporte.jpg";
import * as Imagem20_technical_supporte from "YConnect/Assets/jpeg/Imagem20-technical-supporte.jpg";
import * as Imagem21_technical_supporte from "YConnect/Assets/jpeg/Imagem21-technical-supporte.jpg";
import * as Imagem22_technical_supporte from "YConnect/Assets/jpeg/Imagem22-technical-supporte.jpg";
import * as Imagem23_technical_supporte from "YConnect/Assets/jpeg/Imagem23-technical-supporte.jpg";
import * as Imagem24_technical_supporte from "YConnect/Assets/jpeg/Imagem24-technical-supporte.jpg";
import * as Imagem25_technical_supporte from "YConnect/Assets/jpeg/Imagem25-technical-supporte.jpg";
import * as Imagem26_technical_supporte from "YConnect/Assets/jpeg/Imagem26-technical-supporte.jpg";
import * as Imagem27_technical_supporte from "YConnect/Assets/jpeg/Imagem27-technical-supporte.jpg";
import * as Imagem29_technical_supporte from "YConnect/Assets/jpeg/Imagem29-technical-supporte.jpg";
import * as Imagem30_technical_supporte from "YConnect/Assets/jpeg/Imagem30-technical-supporte.jpg";
import * as Imagem31_technical_supporte from "YConnect/Assets/jpeg/Imagem31-technical-supporte.jpg";
import * as Imagem32_technical_supporte from "YConnect/Assets/jpeg/Imagem32-technical-supporte.jpg";
import * as Imagem33_technical_supporte from "YConnect/Assets/jpeg/Imagem33-technical-supporte.jpg";
import * as Imagem34_technical_supporte from "YConnect/Assets/jpeg/Imagem34-technical-supporte.jpg";
import * as Imagem35_technical_supporte from "YConnect/Assets/jpeg/Imagem35-technical-supporte.jpg";
import * as Imagem36_technical_supporte from "YConnect/Assets/jpeg/Imagem36-technical-supporte.jpg";
import * as Imagem37_technical_supporte from "YConnect/Assets/jpeg/Imagem37-technical-supporte.jpg";
import * as Imagem38_technical_supporte from "YConnect/Assets/jpeg/Imagem38-technical-supporte.jpg";
import * as Imagem39_technical_supporte from "YConnect/Assets/jpeg/Imagem39-technical-supporte.jpg";
import * as Imagem40_technical_supporte from "YConnect/Assets/jpeg/Imagem40-technical-supporte.jpg";
import * as Imagem41_technical_supporte from "YConnect/Assets/jpeg/Imagem41-technical-supporte.jpg";
import * as Imagem42_technical_supporte from "YConnect/Assets/jpeg/Imagem42-technical-supporte.jpg";
import * as Imagem43_technical_supporte from "YConnect/Assets/jpeg/Imagem43-technical-supporte.jpg";
import * as Imagem44_technical_supporte from "YConnect/Assets/jpeg/Imagem44-technical-supporte.jpg";
import * as Imagem45_technical_supporte from "YConnect/Assets/jpeg/Imagem45-technical-supporte.jpg";
import * as Imagem46_technical_supporte from "YConnect/Assets/jpeg/Imagem46-technical-supporte.jpg";
import * as Imagem47_technical_supporte from "YConnect/Assets/jpeg/Imagem47-technical-supporte.jpg";
import * as Imagem48_technical_supporte from "YConnect/Assets/jpeg/Imagem48-technical-supporte.jpg";
import * as Imagem49_technical_supporte from "YConnect/Assets/jpeg/Imagem49-technical-supporte.jpg";
import * as Imagem50_technical_supporte from "YConnect/Assets/jpeg/Imagem50-technical-supporte.jpg";
import * as Imagem51_technical_supporte from "YConnect/Assets/jpeg/Imagem51-technical-supporte.jpg";
import * as Imagem52_technical_supporte from "YConnect/Assets/jpeg/Imagem52-technical-supporte.jpg";

const QuestionStyle = styled.div`
    &.hide {

        @media screen and (min-width: 1024px) {
            height: auto;
        }
    }

    .question-header {
        cursor: pointer;
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;

        h4 {
            font-size:16px;
            line-height: 18px;
            color: #424242;
            padding: 20px 5px 10px 5px;
            width: 98%;
            .subtitle {
                font-size: small;
            }
            border-bottom: solid 1px #ccc;
        }

        .arrow {
            cursor: pointer;
            width: 1rem;
            height: auto;
        }
    }
    iframe{
        width: 662px;
        height: 373px;
    }
    .question {
        iframe {
            margin: 0 auto;
        }
        margin: 10px;
        text-align: justify;

        img{
            padding: 10px;
            width: 60%;
            margin: 0 auto;
        }
        p{
            width: 100%;
        }
    }
    .header-filters {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        width: 40%;

        @media screen and (min-width: 1024px) {
            justify-content: space-around;
            width: 70%;
        }

        .form-group {
            width: 25%;
        }

        .task {
            width: 15%;
        }

        .unit {
            width: 41%;
        }

        &.hide {
            visibility: hidden;
        }
    }

    @media screen and (max-width: 991px) {
        .question {
            margin: 5px;
        }
        .question-header {
            h4{

                width: 95%;
            }
        }
    }
    @media screen and (max-width: 429px) {
        iframe{
            width: 350px;
            height: 225px;
        }
        .question-header {
            h4{
                font-size:15px;
                width: 90%;
                padding:0px;
                margin-bottom: 5px;
                margin-top: 5px;
                line-height:25.2px;
            }
        }
        .question {
            p{
                font-size:14px;
                line-height:25.2px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .arrow {
            width: 2rem;
        }
    }
`
const QuestionContentStyle = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 44px;

    &.hide {
        display: none;
    }
`

const UnitQuestion = ({title, content, isBlocked = true, video}: any) => {

    const [questionDisplay, setQuestionDisplay] = useState({collapseClass: "", arrow: arrowDown})

    const [imageQuestions] = useState({
        image1: Imagem1_technical_supporte,
        image2: Imagem2_technical_supporte,
        image3: Imagem3_technical_supporte,
        image4: Imagem4_technical_supporte,
        image5: Imagem5_technical_supporte,
        image6: Imagem6_technical_supporte,
        image7: Imagem7_technical_supporte,
        image8: Imagem8_technical_supporte,
        image9: Imagem9_technical_supporte,
        image10: Imagem10_technical_supporte,
        image11: Imagem11_technical_supporte,
        image12: Imagem12_technical_supporte,
        image13: Imagem13_technical_supporte,
        image14: Imagem14_technical_supporte,
        image15: Imagem15_technical_supporte,
        image16: Imagem16_technical_supporte,
        image17: Imagem17_technical_supporte,
        image18: Imagem18_technical_supporte,
        image19: Imagem19_technical_supporte,
        image20: Imagem20_technical_supporte,
        image21: Imagem21_technical_supporte,
        image22: Imagem22_technical_supporte,
        image23: Imagem23_technical_supporte,
        image24: Imagem24_technical_supporte,
        image25: Imagem25_technical_supporte,
        image26: Imagem26_technical_supporte,
        image27: Imagem27_technical_supporte,
        image29: Imagem29_technical_supporte,
        image30: Imagem30_technical_supporte,
        image31: Imagem31_technical_supporte,
        image32: Imagem32_technical_supporte,
        image33: Imagem33_technical_supporte,
        image34: Imagem34_technical_supporte,
        image35: Imagem35_technical_supporte,
        image36: Imagem36_technical_supporte,
        image37: Imagem37_technical_supporte,
        image38: Imagem38_technical_supporte,
        image39: Imagem39_technical_supporte,
        image40: Imagem40_technical_supporte,
        image41: Imagem41_technical_supporte,
        image42: Imagem42_technical_supporte,
        image43: Imagem43_technical_supporte,
        image44: Imagem44_technical_supporte,
        image45: Imagem45_technical_supporte,
        image46: Imagem46_technical_supporte,
        image47: Imagem47_technical_supporte,
        image48: Imagem48_technical_supporte,
        image49: Imagem49_technical_supporte,
        image50: Imagem50_technical_supporte,
        image51: Imagem51_technical_supporte,
        image52: Imagem52_technical_supporte,
    })

    useEffect(()=>{
        if (isBlocked){
            setQuestionDisplay({...questionDisplay, collapseClass: "hide"})
        }else {
            setQuestionDisplay({...questionDisplay, collapseClass: "" })
        }
    },[])

    const toggleQuestionDisplay = () => {
        if (questionDisplay.collapseClass) {
            setQuestionDisplay({collapseClass: "", arrow: arrowLeft})
        } else {
            setQuestionDisplay({collapseClass: "hide", arrow: arrowDown})
        }
    }


    return (
        <QuestionStyle className={`col-12 book ${questionDisplay.collapseClass}`}>
            <div>
                <header className="question-header" onClick={toggleQuestionDisplay}>
                    <h4>{title}</h4>
                    <img src={questionDisplay.arrow}
                        className={"arrow"}
                         />
                </header>
                {
                    <QuestionContentStyle className={`question ${questionDisplay.collapseClass}`}>
                        {
                            typeof content != 'undefined' && content.map((contentItem: any, key: any) => {
                                if(contentItem.type === "p"){

                                    return <p dangerouslySetInnerHTML={{__html: contentItem.value}}></p>;
                                }
                                else if(contentItem.type === "img"){
                                    return <img src={imageQuestions[contentItem.value]} ></img>
                                }
                                else if(contentItem.type === "video"){
                                    return <iframe src={contentItem.value}
                                            title="Não estou conseguindo logar na plataforma Yconnect" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>;
                                }
                            })
                        }
                    </QuestionContentStyle>
                }
            </div>
        </QuestionStyle>
    )
}



export default UnitQuestion;

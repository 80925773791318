// Função para descriptografar valores vindos da rota API Get Usuários
export default {
  //Descriptografa Strings
  descryptoString(value: string){
      if (value !== null && value !== undefined)
      {
          let valueDescrypto = "";
          for (var i = 0; i < value.length; i++)
          {
    
              let carater = value[i];
              let codCarac = carater.charCodeAt(0);
    
              if (codCarac > 32)
              {
                  if (codCarac % 2 == 0)
                      codCarac -= 12;
                  else
                      codCarac -= 18;
              }
              valueDescrypto += String.fromCharCode(codCarac);
          }
          return valueDescrypto;
      }
      else{
          return value;
      }
  },

  //Descriptografa Arrays e objetos
  descryptoObject(value: any, containDate:any){
    for (let key in value) {
      if(typeof(value[key]) === 'string'){
        if (containDate) {
          if( String(new Date(value[key])) === "Invalid Date"){
            value[key] = this.descryptoString(value[key]);
          }
        } else {
            value[key] = this.descryptoString(value[key]);
        }
      }
    }
    return value;
  }
}
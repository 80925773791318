import * as React from "react";
import { useState, useEffect, useContext } from "react";

import * as user from "YConnect/Assets/svg/user-2.svg"
import UserContext from "YConnect/User.context";
import Loading from "YConnect/Components/Loader";
import styled from "styled-components";

import { ButtonStyle } from "./Button";
import DivStudentModeStripeStyle from "YConnect/Styles/DivStudentModeStripe.style";


type StudentMode = {
    onClick: Function;
};

const ButtonStyleNew = styled(ButtonStyle)`
    background: #7d7d7d;
    border: 0;
    border-radius: 5px;
    color: ${(props) => props.theme.main.textWhite2};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 41px;
    :hover {
        background: #b4b4b4;
        color: ${(props) => props.theme.main.textWhite2};
    }
`;

const ImgStripe = styled.img`
    height: 31px;
    margin-left: 1rem;
`;

const StudentMode = ({onClick}:StudentMode) => {
    const userContext: any = useContext(UserContext);
    const [hasLoading, setLoading] = useState(false);

    useEffect(() => {}, [userContext]);

    return (
        <DivStudentModeStripeStyle className="fixed-top d-flex flex-row justify-content-between">
            <div className="container d-flex flex-row justify-content-between">
                <div className="d-flex align-items-center justify-content-between px-3 w-auto">
                    <span className="flex-fill text-center">you are in <b>student mode</b></span>                                       
                    <ImgStripe src={user} alt="" />
                </div>

                <ButtonStyleNew
                    label="Exit student mode"
                    onClick={onClick}
                    size="small"
                    maxWidth
                />
            </div>
        </DivStudentModeStripeStyle>
    );
};

export default StudentMode;

import styled from "styled-components"
import { Col } from "reactstrap"

const ExerciseQuestionStyle = styled(Col)`
    padding: 0 40px;

    @media screen and (max-width: 768px) {
        padding: 0px;
    }

    .exercise-dialogue-options {
        width: fit-content;
    }

    .btn-group-toggle {
        display: flex;
        align-items: center;
        flex-flow: column wrap;
        width: fit-content;
        border-radius: 25px !important;

        .btn-radio {
            align-items: center;
            border-radius: 25px;
            display: flex;
            flex-flow: row wrap;
            padding: 8px 24px;
            width: fit-content;
            margin: 5px;

            &:not(:last-child):not(.dropdown-toggle) {
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
            }
            &:not(:first-child) {
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
            }

            &:last-child {
                margin-bottom: 40px;
            }
        }
    }
`

export default ExerciseQuestionStyle
import { useEffect, useContext } from "react"

import UserContext from "YConnect/User.context"

import {
    GetSemestersCombo,
    GetCoursesCombo,
    GetClassesCombo2,
    GetStudentsCombo,
    GetRegionalsCombo,
    GetEstablishmentsCombo,
    GetPeriodsCombo
} from "YConnect/PagesComponents/PrePostReport/Components/Combo.util"

const useCombo = ({
    regionalIdSelected,
    establishmentIdSelected,
    semesterIdSelected,
    courseIdSelected,
    classIdSelected,
    studentIdSelected,
    setHasFilterLoading,
    setCombo
}:any) => {

    const { 
        idUsuario, 
        idProfessor, 
        profiles, 
        estabelecimentos
    }: any = useContext(UserContext)
    
    useEffect(()=>{
        setHasFilterLoading(true)
        Promise.all([
            GetCoursesCombo(),
            GetSemestersCombo({
                idProfile   : idUsuario, 
                idProfessor : idProfessor
            }),
            GetRegionalsCombo()
        ])
        .then(([
                coursesCombo,
                semestersCombo,
                regionalsCombo
        ]) => {
            setCombo({coursesCombo})
            setCombo({semestersCombo})
            setCombo({regionalsCombo})
        })
        .finally(() => setHasFilterLoading(false))

        if (profiles.indexOf(36) > -1) {
            GetEstablishmentsCombo({regional: ""})
            .then(establishmentsCombo => setCombo({establishmentsCombo}))
        } else {
            setCombo({
                establishmentsCombo:estabelecimentos.map(({nsu_estab, nm_estab}:any)=>({id:nsu_estab,value:nm_estab}))
            })
        }
    }, [])

    useEffect(() => {
        setHasFilterLoading(true)
        GetEstablishmentsCombo({regional: regionalIdSelected})
        .then(establishmentsCombo => setCombo({establishmentsCombo}))
        .finally(() => setHasFilterLoading(false))
    }, [regionalIdSelected])


    useEffect(()=>{
        setCombo({coursesCombo:[]})
        setHasFilterLoading(true)
        GetCoursesCombo()
        .then((coursesCombo:any) => setCombo({coursesCombo}))
        .finally(() => setHasFilterLoading(false))
    }, [establishmentIdSelected])

    
    useEffect(()=>{
        setCombo({classesCombo:[]})
        if(courseIdSelected && courseIdSelected!==""){
            setHasFilterLoading(true)
            GetClassesCombo2({
                idAnoSemestre     : semesterIdSelected, 
                idEstabelecimento : establishmentIdSelected,
                idCurso           : courseIdSelected,
                idProfessor       : ""
            })
            .then((classesCombo:any) => setCombo({classesCombo}))
            .finally(() => setHasFilterLoading(false))
        } 
    }, [courseIdSelected])

    useEffect(()=>{
        setCombo({studentsCombo:[]})
        if(classIdSelected && classIdSelected!==""){
            setHasFilterLoading(true)
            GetStudentsCombo({idGrupo : classIdSelected})
            .then((studentsCombo:any) => setCombo({studentsCombo}))
            .finally(() => setHasFilterLoading(false))
        } 
    }, [classIdSelected])

    useEffect(()=>{
        if(studentIdSelected && studentIdSelected!==""){
            setCombo({periodCombo:[]});
            setHasFilterLoading(true);

            GetPeriodsCombo({id_curso_matricula:courseIdSelected, id_aluno_matricula:studentIdSelected})
            .then((periodCombo: any) => setCombo({periodCombo}))
            .finally(() => setHasFilterLoading(false));
        }
    }, [studentIdSelected])
}

export default useCombo
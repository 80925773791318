import styled from "styled-components"

const AlphaBlackStyle = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    height: calc(100% + 50px);
    left: 0;
    max-width: 100vw;
    position: fixed;
    top: -50px;
    width: 100%;
    z-index: 9999;
`

export default AlphaBlackStyle
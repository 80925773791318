import { indexOf } from "lodash"
import * as React            from "react"
import {useState, useEffect} from "react"

import { Modal }    from "react-bootstrap"

import {Col, Row} from "reactstrap"

import {ButtonStyle, UnitPageStyle} from "YConnect/Components/Button"

import FormContainer from "YConnect/Containers/Form.container"

const FormModal = ({
    idName,
    labelForm,
    template,
    selectData,
    show,
    Id,
    onHide,
    onUpdated,
    onCreated,
    GetById,
    Create,
    paramCreate,
    UpdateById,
    mode,
    num,
    type
}:any) =>{

    const CREATE = "CREATE";
    const EDIT = "EDIT";
    const UNIT = "Unit";
    const SECTION = "Section";
    const EXTRA_SUPPORT = "EXTRA_SUPPORT";
    const FINAL_TEST = "FINAL_TEST";

    const [initialOrder, setInitialOrder]       = useState<any>(0);
    const [initialRecord]                       = useState<any>({"CourseId":paramCreate.courseId, "UnitType":type})
    const [hasShowLoader, setShowLoader]        = useState<boolean>(false)
    const [recordList, setRecordList]           = useState([initialRecord]);

    function editItemInRecord(val:any, recordObj:any, index:number){
      if(mode == CREATE){
        if(initialOrder == 0){
          setInitialOrder(num + 1);
        }
        if(labelForm == UNIT){
          val["CourseId"] = paramCreate.courseId;
          val["UnitType"] = type;
          val["Order"] = initialOrder;
        } else if(labelForm == SECTION) {
          val["Order"] = initialOrder;
          val["UnitId"] = paramCreate.unitId;
        }
        const updatedRecordList = [...recordList];
        updatedRecordList[index] = val;
        setRecordList(updatedRecordList);
      } else {
          const updatedRecordList = [...recordList];
          if(labelForm == UNIT){
            recordObj.Name = val.Name != null ? val.Name : recordObj.Name;
            recordObj.Id = val.Id != null ? val.Id : recordObj.Id;
            recordObj.UnitType = val.UnitType != null ? val.UnitType : recordObj.UnitType;
          } else if(labelForm == SECTION) {
            recordObj.Name = val.Name != null ? val.Name : recordObj.Name;
            recordObj.UnitId = val.UnitId != null ? val.UnitId : recordObj.UnitId;
          }
        //   recordObj.Order = 4;
          updatedRecordList[index] = recordObj;
          setRecordList(updatedRecordList);
      }
    }

    function removeItemInRecord(index:number){
      const updatedRecordList = [...recordList];
      updatedRecordList.splice(index, 1);
      var x = 1;
      updatedRecordList.forEach(updatedRecord => {
        updatedRecord.Order = x;
        x++;
      });
      setRecordList(updatedRecordList);
    }


    function addItemInRecord(){
      setInitialOrder(initialOrder + 1);
      const updatedRecordList = [...recordList];
      updatedRecordList.push({});
      setRecordList(updatedRecordList);
    }

    useEffect(()=>{
        if(Id) get()
    }, [Id])

    const get = () => {
        setShowLoader(true)
        GetById(Id)
        .then((response:any) => {
            const {data} = response
            const updatedRecordList = [];
            updatedRecordList.push(data);
            setRecordList(updatedRecordList);
            //setRecord(data)
        })
        .finally(() => setShowLoader(false))
    }

    const create = () => {
        var clearList:any = [];
        recordList.forEach(recordUnit => {
          if( recordUnit.Name != undefined){
            clearList.push(recordUnit);
          }
        });
        if(labelForm == UNIT){
          paramCreate.listUnit = clearList;
        } else if(labelForm == SECTION){
          paramCreate.listSection = clearList;
        }
        if(recordList.length > 0){
            setShowLoader(true)
            Create({...paramCreate?paramCreate:{}})
            .then((response:any) => {
                onCreated()
            })
            .finally(() => {
                setShowLoader(false)
                setRecordList([initialRecord])
                setInitialOrder(initialOrder + 1);
            })
        }
    }


    const update = () => {
        var clearList:any = [];
        recordList.forEach(recordUnit => {
          if( recordUnit.Name != undefined){
            clearList.push(recordUnit);
          }
        });
        let paramUpdate = {"listUnit": 0, "unitId": 0, "listSection": 0, "sectionId": 0};
        if(labelForm == UNIT){
          paramUpdate.listUnit = clearList;
          paramUpdate.unitId = Id
        } else if(labelForm == SECTION){
          paramUpdate.listSection = clearList;
          paramUpdate.sectionId = Id
        }
        if(recordList.length > 0){
            setShowLoader(true)
            UpdateById({...paramUpdate?paramUpdate:{}})
            .then((response:any) => {
                onUpdated()
            })
            .finally(() => {
                setShowLoader(false)
            })
        }
    }

    const handleCreate = () => {
        create()
    }

    const handleUpdate = () => {
        update()
    }


    return <Modal show={show} onHide={onHide} size="xl">
                <Modal.Header
                    closeButton
                    closeLabel={"Close"}
                    className="first-access"
                    style={{zIndex: 10}}>
                        <Modal.Title><strong>{labelForm}</strong>{Id && ` Id (${Id})`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            !hasShowLoader
                            && show
                            && <Col md={12}>
                              {recordList.map((recordObj:any, index:number)=> {
                                    return (
                                    <>
                                      <Row>
                                      <FormContainer
                                          selectData={selectData}
                                          values={recordObj}
                                          template={[
                                              ...template
                                              .map((field:any) => ({
                                                  ...field,
                                                  ...paramCreate
                                                  && paramCreate[field.keystone]
                                                  ? {value: paramCreate[field.keystone]}
                                                  : {}
                                              }))

                                          ]}
                                          onChange={({valuesUpdated}:any) => editItemInRecord(valuesUpdated, recordObj, index)}
                                      />
                                      {mode == CREATE && (index + 1) == recordList.length
                                      && (type != FINAL_TEST && type != EXTRA_SUPPORT) &&
                                        <UnitPageStyle>
                                          <ButtonStyle
                                            onClick={() => addItemInRecord()}
                                            label="Add"
                                            icon="plusGreen"
                                            className="mb-3"
                                            size="130"
                                          />
                                        </UnitPageStyle>
                                      }
                                      {mode == CREATE && recordList.length > 1 && (index + 1) != recordList.length &&
                                        <UnitPageStyle>
                                          <ButtonStyle
                                          onClick={() => removeItemInRecord(index)}
                                          label="Remove"
                                          icon="remove"
                                          className="mb-3"
                                          size="130"
                                          />
                                        </UnitPageStyle>
                                      }
                                      </Row>
                                    </>
                                    )
                              })
                              }
                                </Col>
                        }
                        {
                            hasShowLoader && <div className="loader"></div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            !Id
                            && <ButtonStyle
                                onClick={handleCreate}
                                label="Save"
                                icon="saveGreen" />
                        }
                        {
                            Id
                            && <ButtonStyle
                                onClick={handleUpdate}
                                label="Update"
                                icon="saveGreen" />
                        }

                    </Modal.Footer>
            </Modal>
}


export default FormModal

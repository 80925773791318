const CONFIG_TABLE_EXERCISE:Array<ColumnConfigType> = [
    {
        keystone : "Order",
        label    : "Order"
    },
    {
        keystone : "SectionId",
        label    : "Section",
        selectDataName : "SectionId"
    },
    {
        keystone : "ExerciseTypeId",
        label    : "Exercise Type",
        selectDataName : "ExerciseTypeId"
    },
    {
        keystone : "LearningObjectiveId",
        label    : "Learning Objective",
        selectDataName : "LearningObjectiveId"
    }
]

export default CONFIG_TABLE_EXERCISE

import styled from "styled-components"

const StudentContentCardStyle = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
    padding: 16px;
    height: 100%;
    width: 100%;
    @media screen and (min-width: 1024px) {
        width: 70%;
    }

    .students-data {
        align-items:center;
        border-bottom: 1px solid #E1E6E7;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 0 16px 8px;
        width: 100%;
        @media screen and (min-width: 1024px) {
            flex-flow: row nowrap;
        }

        div {
            margin-bottom: 16px;
            @media screen and (min-width: 1024px) {
                margin-bottom: 0;
            }
        }
    }

    .student-data {
        display: flex;
        flex-flow: column wrap;
        margin-left: 8px;
        .title {
            color: #989898;
            font-size: 12px;
        }
        .value {
            color: #565656;
        }
    }

    .percent {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-top: 25px;
        width: 100%;

        .percent-bar {
            margin: 0 0 0 16px;
            width: calc(70% - 74px);
            .percent-progress {
                color: #565656;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;
                left: calc(50% - 20px);
                position: relative;
                width: 100%;
            }
            .percent-counter {
                display:flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }
        }

        .percent-overall {
            display: flex;
            flex-flow: column wrap;
            width: 100%;
            @media screen and (min-width: 1024px) {
                width: calc(30% + 16px);
            }
            .overall {
                color: #565656;
                font-size: 17px;
                font-weight: 600;
            }
            .number {
                color: #00A281;
                font-size: 42px;
                font-weight: 300;
            }
        }

    }
`


export default StudentContentCardStyle
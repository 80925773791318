enum ExerciseTemplate {
  CADDDIALOGO_LONGO = "CADDDialogoLongo",
  CACHOOSE_MULTIPLE = "CAChooseMultiple",
  CACLICK_AUDIO = "CAClickAudio",
  CATYPE_IN = "CATypeIn",
  CAUNSCRAMBLE = "CAUnscramble",
  CHOOSE_MULTIPLE = "ChooseMultiple",
  CLICK_AUDIO = "ClickAudio",
  DDDIALOGO_FRASE = "DDDialogoFrase",
  DDDIALOGO_LONGO = "DDDialogoLongo",
  DIALOGO_AUDIO = "DialogueAudio",
  DIALOGO_LONGO = "DialogueLong",
  DIALOGO_FRASE = "DialoguePhrase",
  DRAG_DROP_IMG = "DragDropImg",
  DRAG_DROP_TEXT_IMG = "DragDropTxtImg",
  DRAG_DROP_VIDEO = "DragDropVideo",
  LCLICK_AUDIO = "LClickAudio",
  MATCH_TEXT = "MatchText",
  OPEN_QUESTION = "OpenQuestion",
  TYPE_IN = "TypeIn",
  TYPE_IN_MULTIPLE_ANSWER = "TypeInMultipleAnswer",
  TYPE_IN_ORDENADO = "TypeInOrdered",
  UNSCRAMBLE = "Unscramble",
  VIDEO_CHOOSE_MULTIPLE = "VideoChooseMultiple",
}


export const getTemplateType = (template: any) => {
  const { nome, id_template } = template

  switch (nome) {
    case ExerciseTemplate.DDDIALOGO_FRASE:
      return ExerciseTemplate.DIALOGO_FRASE
    case ExerciseTemplate.DDDIALOGO_LONGO:
      return ExerciseTemplate.DIALOGO_LONGO
    case ExerciseTemplate.CADDDIALOGO_LONGO:
      return ExerciseTemplate.DIALOGO_AUDIO
    case ExerciseTemplate.MATCH_TEXT:
      return ExerciseTemplate.MATCH_TEXT
    case ExerciseTemplate.CHOOSE_MULTIPLE:
      return ExerciseTemplate.CHOOSE_MULTIPLE
    case ExerciseTemplate.DRAG_DROP_TEXT_IMG:
      return ExerciseTemplate.DRAG_DROP_TEXT_IMG
    case ExerciseTemplate.TYPE_IN:
    case "Type In":
      if (id_template === 20) {
        return ExerciseTemplate.TYPE_IN_ORDENADO
      }
      return ExerciseTemplate.TYPE_IN
    case ExerciseTemplate.TYPE_IN_MULTIPLE_ANSWER:
      return ExerciseTemplate.TYPE_IN_ORDENADO
    case ExerciseTemplate.CACLICK_AUDIO:
      return ExerciseTemplate.CLICK_AUDIO
    case ExerciseTemplate.UNSCRAMBLE:
      return ExerciseTemplate.UNSCRAMBLE
    case ExerciseTemplate.DRAG_DROP_IMG:
      return ExerciseTemplate.DRAG_DROP_IMG
    case ExerciseTemplate.VIDEO_CHOOSE_MULTIPLE:
      return ExerciseTemplate.VIDEO_CHOOSE_MULTIPLE
    case ExerciseTemplate.DRAG_DROP_VIDEO:
      return ExerciseTemplate.DRAG_DROP_VIDEO
    case ExerciseTemplate.OPEN_QUESTION:
      return ExerciseTemplate.OPEN_QUESTION

    // remover depois
    case ExerciseTemplate.CACHOOSE_MULTIPLE:
      return ExerciseTemplate.CHOOSE_MULTIPLE
    case ExerciseTemplate.CATYPE_IN:
      return ExerciseTemplate.TYPE_IN
    case ExerciseTemplate.CAUNSCRAMBLE:
      return ExerciseTemplate.UNSCRAMBLE
    case ExerciseTemplate.LCLICK_AUDIO:
      return ExerciseTemplate.CLICK_AUDIO
    //
    default:
      return ""
  }
}

export default ExerciseTemplate;
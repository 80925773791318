
import * as React                    from "react"
import {Fragment,useEffect,useState} from "react"

import PictureContainer    from "YConnect/Containers/Profile.container/Picture.container"
import Tabs                from "YConnect/Containers/Profile.container/Tabs"
import { ButtonStyle }     from "YConnect/Components/Button"
import API                 from "YConnect/API"
import Loader              from "YConnect/Components/Loader"
import InformPasswordModal from "YConnect/Modals/InformPassword.modal"
import getConfig           from "YConnect/Utils/GetRequestConfig.util"
import {maskForEmail, maskForFields}      from "YConnect/Utils/MaskFormat.util"
import Toastify from "toastify-js"

const normalizePhoneNumber = (phone_number:string) => phone_number.replace("(", "").replace(")", "").replace("-", "")

const normalizeValues = (values:any, selectData:any) => {

    const newValues = {
        ...values,
        ...values.id_idioma    ? {lingua_nativa:selectData.id_idioma.find(({id}:any)=> id == values.id_idioma).value} : {},
        ...values.phone_number ? {ddd_aluno     :normalizePhoneNumber(values.phone_number).substring(0, 2)}           : {},
        ...values.phone_number ? {telefone_aluno:normalizePhoneNumber(values.phone_number).substring(2)}              : {},
        ...values.cep_aluno    ? {cep_aluno:values.cep_aluno.replace("-", "")}                                        : {}
    }

    return newValues
}


const GetAllSelectData = () => Promise.all([
    API.Idioma.Get({}, getConfig()),
    API.PaisYconnect.Get({}, getConfig()),
    API.Estado.Get({}, getConfig())
])

const isValidFiels = (
    aboutInvaliFields   : Array<any>,
    contactInvaliFields : Array<any>,
    accountInvaliFields : Array<any>
) =>
aboutInvaliFields.length === 0
&& contactInvaliFields.length === 0
&& accountInvaliFields.length === 0

const FormTabsContainer = ({
    isMyProfile,
    defaultTabKey,
    response,
    onMapFormTabs,
    onUpdate,
    onUpdatePassword,
    onUpdateEmail,
    disabledPictureEdit
}: any) => {

    const [aboutValues,   setAbout]      = useState({})
    const [contactValues, setContact]    = useState({})
    const [accountValues, setAccount]    = useState({})
    const [isValidate,    setIsValidate] = useState(false)
    const [isValidated,   setValidated]  = useState(false)

    const [aboutInvaliFields,   setAboutInvaliFields]   = useState([])
    const [contactInvaliFields, setContactInvaliFields] = useState([])
    const [accountInvaliFields, setaccountInvaliFields] = useState([])

    const [newImage,              setNewImage]              = useState()
    const [messageErrorPassword,  setMessageError]          = useState()
    const [selectData,            setSelectData]            = useState()
    const [isLoading,             setLoading]               = useState(false)
    const [currentPassword,       setCurrentPassword]       = useState()
    const [typeSubmit,            setTypeSubmit]            = useState()
    const [hasShowInformPassword, setHasShowInformPassword] = useState(false)

    const handleClose = () => {
        setTypeSubmit(undefined)
        setHasShowInformPassword(false)
        setCurrentPassword("")
    }

    const handleValidate = (type: string) => {
            setTypeSubmit(type)
            if(type === "CHANGE_PASSWORD"){
                const { newPassword, confirmPassword }:any = accountValues
                if (validatePassword(newPassword, confirmPassword)) {
                    setHasShowInformPassword(true)
                }
            }else if(type === "UPDATE_PROFILE"){
                if(isMyProfile){
                    setIsValidate(true)
                    if(isValidated && isValidate){
                        if(validateFields()){

                            if(Object.keys(getValuesForUpdated()).length > 0){
                                setHasShowInformPassword(true)
                            }else{
                                Toastify({
                                    text: "There is no data to update",
                                    duration: 3000,
                                    className: "toastify-warning",
                                    newWindow: true,
                                    close: true,
                                    gravity: "top",
                                    position: "right",
                                    stopOnFocus: true,
                                    escapeMarkup: false,
                                    onClick: function(){} 
                                  }).showToast();
                            }

                        }
                    }
                }else {
                    updateEmail()
                }
            }

    }

    const validateFields = () => {
        if(isValidated && isValidate){
            if(isValidFiels(aboutInvaliFields, contactInvaliFields, accountInvaliFields)){
               return true
            }else{
                const listInvalidFieldsLabel:Array<string> = [
                    ...aboutInvaliFields.map(({label}) => label),
                    ...contactInvaliFields.map(({label}) => label),
                    ...accountInvaliFields.map(({label}) => label)
                ]
                Toastify({
                    text: listInvalidFieldsLabel.join("<br/>") + "required fields",
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();

                return false
            }
        }
    }

    const changePassword = async () => {
        const { newPassword }:any = accountValues
        onUpdatePassword({oldPassword:currentPassword, newPassword})
    }

    const getValuesForUpdated = () => {
        const imageChunk = newImage
                                ? ({foto:newImage})
                                : newImage === ""
                                    ? ({foto:undefined})
                                    : ({})

        const values = {...aboutValues, ...contactValues, ...accountValues, ...imageChunk}

        return normalizeValues(values, selectData)
    }

    const updateProfile = () =>{
        onUpdate({
            ...getValuesForUpdated(),
            senha_aluno: currentPassword,
            nova_senha_aluno:currentPassword
        })
    }

    const updateEmail = () => {
        const {email_aluno} = getValuesForUpdated()

        if(email_aluno && email_aluno !== "" && email_aluno !== response.email_aluno ){
            onUpdateEmail(email_aluno)
        }else if(!email_aluno){
            Toastify({
                text: "There is no email to update",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }else if( email_aluno === ""){
            Toastify({
                text: "E-mail field is empty!",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }else if(email_aluno === response.email_aluno){
            Toastify({
                text: "No change in email field!",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }

    useEffect(() => {
        if(isValidated && validateFields()){
            handleValidate(typeSubmit)
        }
    }, [isValidated])

    useEffect(() => {
        GetAllSelectData()
        .then(([responseIdioma, responsePaisYconnect, responseEstado]) => {
            setSelectData({
                id_idioma : responseIdioma.data.map(({value, key}:any) => ({id:key, value})),
                id_pais   : responsePaisYconnect.data.map(({id_pais, descr}:any) => ({id:id_pais, value:descr})),
                id_estado : responseEstado.data.map(({id_estado, descr_estado}:any) => ({id:id_estado, value:descr_estado})),
            })
        })
    }, [])

    const validatePassword = (newPassword: string, confirmPassword: string) => {
        setMessageError(undefined)
        if (!newPassword) {
            setMessageError("The new passwords is required!")
            return false
        } else if (!confirmPassword) {
            setMessageError("The confirm passwords is required!")
            return false
        } else if (newPassword !== confirmPassword) {
            setMessageError("The passwords must match!")
            return false
        } else if (newPassword.indexOf(" ") > -1) {
            setMessageError("The password can't have space character!")
            return false
        } else if (newPassword.length < 6 || newPassword.length > 12) {
            setMessageError("The password must have between 6 and 12 characters!")
            return false
        } else return true
    }

    const handleSaveChanges = () => {
        if(typeSubmit === "CHANGE_PASSWORD"){
            changePassword()
        }else if(typeSubmit === "UPDATE_PROFILE"){
            updateProfile()
        }
    }

    const handleResetPassword = async () => {

        const {email_aluno} = response

        try{
            setLoading(true)
            await API.Auth.ResetPassword({id: '', emailAluno: email_aluno})
            Toastify({
                text: "Success! <br> Password reset request sent!",
                duration: 3000,
                className: "toastify-success",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();


        }catch(err){
            Toastify({
                text: "Error! <br> Could not reset password!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }finally{
            setLoading(false)
        }
    }

    return <Fragment>
                <section className="row">
                    {isLoading && <Loader/>}
                    <div className="col-12 ">
                        <div className="card p-0 shadow d-flex flex-row flex-wrap justify-content-around profile">
                            <PictureContainer
                                disabledPictureEdit = {disabledPictureEdit}
                                response            = {response}
                                onAddNewPicture     = {setNewImage} />

                            <div className="pb-2 profile-tabs">
                                <Tabs defaultTabKey={defaultTabKey} tabs={onMapFormTabs({
                                    isValidate,
                                    messageErrorPassword,
                                    selectData,
                                    response,
                                    maskForEmail: maskForEmail,
                                    maskForFields: maskForFields,
                                    onChangeAbout    : ({valuesUpdated, invalidFields}:any) => {
                                        setAbout(valuesUpdated)
                                        setAboutInvaliFields(invalidFields)
                                    },
                                    onChangeContact  : ({valuesUpdated, invalidFields}:any) => {
                                        setContact(valuesUpdated)
                                        setContactInvaliFields(invalidFields)
                                    },
                                    onChangeAccount  : ({valuesUpdated, invalidFields}:any) => {
                                        setAccount(valuesUpdated)
                                        setaccountInvaliFields(invalidFields)
                                    },
                                    onChangePassword : () => handleValidate("CHANGE_PASSWORD"),
                                    onValidated      : () => setValidated(true),
                                    onResetPassword  : handleResetPassword
                                })} />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="btn-fixed d-flex text-right btn-profile">
                    <ButtonStyle
                        green
                        type    = "submit"
                        label   = "Save changes"
                        size    = "medium"
                        icon    = "save"
                        onClick = {() => handleValidate("UPDATE_PROFILE")} />
                </div>

                <InformPasswordModal
                    onClose          = {handleClose}
                    onChangePassword = {setCurrentPassword}
                    isShow           = {hasShowInformPassword}
                    currentPassword  = {currentPassword}
                    onSaveChange     = {handleSaveChanges}/>
            </Fragment>
}
export default FormTabsContainer

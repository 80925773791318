import * as React from "react"
import {
    useState,
    useEffect,
    useContext
} from "react"
import styled from "styled-components"
import find from "lodash/find"

import { ButtonStyle } from "YConnect/Components/Button"
import Table from "YConnect/Components/Table"
import SelectFieldStyle from "YConnect/Styles/SelectField.style"
import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import getUnitName   from "YConnect/Utils/CourseUnitName.util"
import UserContext from "YConnect/User.context"

import CONFIG_TABLE_REPORT_TASK_UNIT, { CONFIG_TABLE_REPORT_TASK } from "YConnect/Configs/Tables/ReportTask.table.config"
import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg"

const ReportUnitStyled = styled.div`
    border-bottom: 3px solid ${props => props.theme.main.borderLightGray};
    margin-bottom: 24px;

    .report-body {
        border-bottom: 1px solid ${props => props.theme.main.lightGray};
        margin-bottom: 24px;
        .title {
            font-weight: bold;
            width: 45%;
        }
        .info {
            width: 65%;
        }

        .report-score {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 65%;
        }
    }
`

const idCursoStarter = 67

const ScoreTask = ({
    idCurso,
    idAluno,
    report,
    period,
    onDownload,
    onPrint,
    resourceBook = false
}: any) => {

    const { profiles }: any = useContext(UserContext)

    const [unitParam, setUnitParam] = useState<any>("ALL")
    const [units, setUnits] = useState<any>()
    const [dataTable, setDataTable] = useState([])
    const [selectData, setSelectData] = useState([])

    const isCursoStarter: boolean = parseInt(idCurso, 10) === idCursoStarter

    useEffect(() => {
        if (!units) {
            fetchCourseUnits()
        }
    }, [idCurso, idAluno])

    useEffect(() => {
        if (units && report) {
            refreshSelectData()
            refreshDataTable()
        }
    }, [report, units])

    useEffect(() => refreshDataTable(), [unitParam])

    const fetchCourseUnits = async () => {
        try {
            const { data } = await API.Course.getById({
                id_curso: idCurso,
                id_usuario: idAluno,
                isDegustacao: false
            }, getConfig())

            setUnits(data)
        } catch (error) { }
    }

    const refreshSelectData = () => {
        const newSelectData = units.filter((item: any) => item.tipo_unidade === "BOOK_TASK").map((unit: any) => ({
            id: unit.ordem,
            value: getUnitName(parseInt(idCurso), unit.ordem, unit.nome)
        }));
        setSelectData(newSelectData)
    }

    const refreshDataTable = () => {
        if (unitParam !== "ALL")
            setDataTable(report.filter(({ unidade }: { unidade: number }) => unidade == unitParam).map((item: any) => {
                const unit = find(units, { ordem: item.unidade }) || {}

                return { ...item, ["idCurso"]: idCurso, ["idUnidade"]: unit.id_unidade, ["idAluno"]: idAluno }
            }))
        else
            setDataTable(report.map((item: any) => {
                const unit = find(units, { ordem: item.unidade }) || {}
                return { ...item, ["idCurso"]: idCurso, ["idUnidade"]: unit.id_unidade, ["idAluno"]: idAluno }
            }))
    }

    const handleChangeFilter = (keystone: string, value: any) => {
        const sets: any = {
            unit: setUnitParam
        }
        if (sets[keystone]) sets[keystone](value)
    }


    const details = {
        label: "Details",
        formatter: ({ idCurso, idUnidade, idAluno, nome_exercicio, ordem_exercicio }: any) => {

            if (!idUnidade) {
                return <span className="text-error">An Error Occurred, Please Try Again Later</span>
            }

            if (idUnidade == 161) {
                idUnidade = 142
            }

            if (idUnidade == 159) {
                idUnidade = 150
            }

            // if(true){
            //     return <span className="text-error">Cannot Consult Task From Past Period</span>
            // }
            if (period != 0) {
                return (
                    <span className="text-danger">
                      We cannot access the task, this registration is expired
                    </span>
                );
            }

            else {
                return (
                    <a href={`#/courses/${idCurso}/unit/${idUnidade}/view-book/${nome_exercicio}/${ordem_exercicio}/exercise/1/student/${idAluno}`}>
                        Consult this task
                        <img src={arrowRight} alt="" className="pl-2" />
                    </a>
                )
            }
        }
    }

    return <div className="score">
        <header className="score-header flex-row">
            <div className="btn-fixed d-flex text-right btn-profile">
                {(onDownload || onPrint) && <div className="header-cta header-student d-flex flex-row">
                    {onDownload && <ButtonStyle label="Download" icon="download" onClick={onDownload} />}
                    {onPrint && <ButtonStyle label="Print" icon="print" onClick={onPrint} />}
                </div>}
            </div>

            {!isCursoStarter
                && <div className="score-filter">
                    <span className="filter-label  pr-5">Filter by</span>
                    <SelectFieldStyle
                        keystone="unit"
                        label="Unit"
                        value={unitParam}
                        onChange={handleChangeFilter}
                        values={[
                            { id: "ALL", value: "All units" }, ...selectData
                        ]}
                        hasOptionEmpty={true} />
                </div>
            }
        </header>
        {
            window.innerWidth > 1024
                ? <Table data={dataTable} config={
                    [
                        ...(!isCursoStarter ? CONFIG_TABLE_REPORT_TASK_UNIT : CONFIG_TABLE_REPORT_TASK),
                        details,
                    ]}
                />
                : dataTable.map((report: any, key: any) => {
                    return <ReportUnitStyled key={key}>
                        <div className="report-body d-flex">
                            <span className="title">Unit</span>
                            <span className="info">{getUnitName(parseInt(idCurso), report.unidade, report.nomeUnidade)}</span>
                        </div>
                        <div className="report-body d-flex">
                            <span className="title">Type</span>
                            <span className="info">{report.descricao_tipo}</span>
                        </div>
                        <div className="report-body d-flex">
                            <span className="title">Task</span>
                            <div className="report-score">
                                <div className="d-flex flex-column">
                                    <span>{report.nome_exercicio}</span>
                                    <small>Task {report.ordem_exercicio}</small>
                                </div>
                            </div>
                        </div>
                        <div className="report-body d-flex">
                            <span className="title">Weight</span>
                            <span className="info">{report.peso}</span>
                        </div>
                        <div className="report-body d-flex">
                            <span className="title">Average score</span>
                            <div className="report-score">
                                <div className="d-flex flex-column">
                                    <span>{report.nota_first_attempt || report.nota_first_attempt === 0 ? report.nota_first_attempt : "-"}</span>
                                    <span>{report.nota_first_attempt || report.nota_first_attempt === 0 ? "1st attempt" : null}</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span>{report.nota_second_attempt || report.nota_second_attempt === 0 ? report.nota_second_attempt : "-"}</span>
                                    <span>{report.nota_second_attempt || report.scoreSecondAttempt === 0 ? "2nd attempt" : null}</span>
                                </div>
                            </div>
                        </div>
                        <div className="report-body d-flex">
                            <span className="title">Details</span>
                            {
                                <span className="info">
                                    <a href={`#/courses/${idCurso}/unit/${report.idUnidade}/view-book/${report.nome_exercicio}/${report.ordem_exercicio}/exercise/1/student/${report.idAluno}`}>
                                        Consult this task
                                        <img src={arrowRight} alt="" className="pl-2" />
                                    </a>
                                </span>
                            }
                        </div>
                    </ReportUnitStyled>
                })
        }
    </div>
}

export default ScoreTask

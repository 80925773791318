import styled from "styled-components"
import { Button } from "reactstrap"

const ButtonCollapseStyle = styled(Button)`
    margin-top: 5px;
    float: right;
    color: ${props => props.theme.main.textWhite};
    border: 1px solid ${props => props.theme.main.backgroundRadioActiveText};
    background-color: ${props => props.theme.main.backgroundRadioActiveText};
    font-weight: bold;
    &:hover {
        color: ${props => props.theme.main.backgroundRadioActiveText};
        border: 1px solid ${props => props.theme.main.backgroundRadioActiveText};
        background-color: ${props => props.theme.main.backgroundRadioActive};
        text-decoration: none;
        transition: 0.5s;
    }
    &:focus {
        text-decoration: none;
    }
`

export default ButtonCollapseStyle
import * as React from "react"
import { useEffect, useState } from "react"
import { Col } from "reactstrap"

import DragAndDropTextAndImageTable from "YConnect/PagesComponents/CMS/Editors/DragAndDropTextAndImage.editor/DragAndDropTextAndImage.editableTable"

const GetTextAndImagesByOrderByQuestion = (question:QuestionType ) => {
    if(question){
        const {Answers, ExerciseMedias} = question
        if(Answers && ExerciseMedias){
            const newTextAndImagesByOrder = Answers
            .reduce((acc:any, answer)=>{
                acc[answer.Order] = {
                    answer,
                    exerciseMedia:ExerciseMedias.find(({Order}) => answer.Order == Order)
                }
                return acc
            },{})

            return newTextAndImagesByOrder
        }else{
            return undefined
        }
    }else{
        return undefined
    }
}

type DragAndDropTextAndImagesEditorProps = {
    question: QuestionType
    onChange:any
}

const DragAndDropTextAndImagesEditor = ({
    question,
    onChange
}:DragAndDropTextAndImagesEditorProps) => {

    const [textAndImagesByOrder, setTextAndImagesByOrder] = useState(GetTextAndImagesByOrderByQuestion(question))

    useEffect(() => {
        if(question) {
            updateQuestion()
        }
    }, [question])

    const updateQuestion = () => {
            const {Answers, ExerciseMedias} = question
    }

    const handleChangeTextAndImages = (newTextAndImagesByOrder:any) => {
        const isAnyItemMissingImageOrText = newTextAndImagesByOrder.some((item:any) => !item.exerciseMedia || !item.answer)

        if (isAnyItemMissingImageOrText) {
            onChange({
                ...question,
                Answers: [],
                ExerciseMedias: []
            })
        } else {
            const {
                newExerciseMedias,
                newAnswers
            } = Object.keys(newTextAndImagesByOrder).reduce((acc, i) => {
                const newOrder = i*1+1
    
                const {exerciseMedia, answer}:any = newTextAndImagesByOrder[i]
                answer && acc.newAnswers.push({...answer, Order:newOrder, IsCorret:true})
                exerciseMedia && acc.newExerciseMedias.push({...exerciseMedia, Order:newOrder})
                return acc
            }, {newExerciseMedias:[], newAnswers:[]})
            
            const newValues = {
                ...question,
                Answers:newAnswers,
                ExerciseMedias:newExerciseMedias
            }
    
            onChange(newValues)
        }
    }

    return <Col md={12} className="mt-3">
                <h3 className="font-weight-bold">
                    Text and Image Editor {
                        textAndImagesByOrder
                        && Object.keys(textAndImagesByOrder).length > 0 
                        && ` (${Object.keys(textAndImagesByOrder).length})`
                    }
                </h3>
                <DragAndDropTextAndImageTable
                    textAndImagesByOrder={textAndImagesByOrder}
                    onChange={(newTextAndImagesByOrder:any) => 
                        handleChangeTextAndImages(newTextAndImagesByOrder)}/>
            </Col>
}

export default DragAndDropTextAndImagesEditor

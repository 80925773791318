import * as React from "react"
import { useState, useEffect, useContext, useRef } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Tooltip } from "reactstrap"

import * as pencil from "YConnect/Assets/svg/pencil.svg"
import * as checked from "YConnect/Assets/svg/checked.svg"
import * as remove from "YConnect/Assets/svg/close-btn.svg"
import * as brazilFlag from "YConnect/Assets/png/brazil-flag.png";
import * as usFlag from "YConnect/Assets/png/us-flag.png";

import PostTestModal from "YConnect/Modals/PostTest.modal"
import { BreadcrumbExercise } from "YConnect/Components/BreadCrumbList"
import PageTitle from "YConnect/Components/PageTitle"
import Loader from "YConnect/Components/Loader"
import ServerErrorPage from "YConnect/Pages/ServerError.page"
import { BackLinkStyle } from "YConnect/Styles/BackLink.style"
import { ButtonStyle, YLinkButtonStyle } from "YConnect/Components/Button"
import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"
import ExerciseTemplate, { getTemplateType } from "YConnect/Enums/ExerciseTemplate"

import AttemptsIconStyle from "YConnect/Styles/AttemptsIcon.style"
import HeaderExerciseStyle from "YConnect/Styles/HeaderExercise.style"
import ExerciseTimelineStyle from "YConnect/Styles/ExerciseTimeline.style"
import HeaderScoreStyle from "YConnect/Styles/HeaderScore.style"
import FormStyle from "YConnect/Styles/Form.style"
import FooterStyle from "YConnect/Styles/Footer.style"
import HomeworkLabelStyle from "YConnect/Styles/HomeworkLabel.style"

import UserContext from "YConnect/User.context"
import PrePostContext from "YConnect/PrePost.context"

import UnitType from "YConnect/Enums/UnitType"

import NextUnit from "YConnect/Modals/NextUnit.modal"

import capitalize from "lodash/capitalize"

import styled from "styled-components"
import { Button } from "react-bootstrap"
import DescryptoValue from "YConnect/Utils/DescryptoValue";
import formatText from "YConnect/Utils/FormatText.util";
import Toastify from "toastify-js"

const H3Style = styled.h3`
    i{
        font-size: 22px;
    }

    u{
        font-size: 22px;
    }

    b{
        font-size: 19px;
    }
`

const FlagPosition = styled.div`
    display: flex;
    position: relative;
    top: 6px;
    padding: 10px;

    @media screen and (max-width: 524px) {
        top: 56px;
        max-height: 65px;
    }

    .active {
        background-color: #4e2d78;
    }
`

const FlagBackground = styled.div`
    border-radius: 40px;
`

const FlagImages = styled.img`
    height: 18px;
    width: 38px;
    margin-right: 7px;
    margin-top: 11px;
`

const ButtonNextUnit = styled(ButtonStyle)`
    border: none;
`

const AlignFlags = styled.div`
    display: flex;
    justify-content: space-between;
`

const ExercisePage = ({
    onUpdatePrePost,
    match,
    location,
    onCheckTag
}: any) => {

    const userContext: any = useContext(UserContext)
    const prePostUserContext: any = useContext(PrePostContext)

    const [tooltipOpen, setTooltip] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [contentExercise, setContentExercise] = useState({})
    const [formValues, setFormValues] = useState()
    const [canSubmit, setSubmit] = useState(true)
    const [showResult, setShowResult] = useState(false)
    const [courseInfo, setCourseInfo] = useState()
    const [bookInfo, setBookInfo] = useState()
    const [prevExercises, setPrevExercises] = useState([])
    const [width, setWidth] = useState(window.innerWidth)
    const [showModalPost, setShowModalPost] = useState(false)
    const [modalNextUnit, setModalNextUnit] = useState(false)

    const [languageSelectedBrazil, setLanguageSelectedBrazil] = useState(false)
    const [languageSelectedEnglish, setLanguageSelectedEnglish] = useState(true)

    const {
        params: { idCourse, idUnidade, sectionName, orderId, idExercise }
    } = match

    useEffect(() => {
        const handleResize = () => {
            setTooltip(false)
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })

    useEffect(() => {
        if (courseInfo && !bookInfo) {
            fetchBookContent()
        } else if (bookInfo && !contentExercise) {
            fetchExerciseContent()
        }
    }, [courseInfo, bookInfo, contentExercise])

    useEffect(() => {
        setLoading(true)
        fetchCourseContent()
        setBookInfo(null)
        setContentExercise(null)
    }, [idCourse])

    useEffect(() => {
        setLoading(true)
        setBookInfo(null)
        setContentExercise(null)
    }, [idUnidade, sectionName, orderId])

    useEffect(() => {
        setLoading(true)
        setContentExercise(null)
        setShowResult(false)
    }, [idExercise])


    useEffect(() => {
        setSubmit(true)
        if (formValues && formValues.valuesUpdated.exercise) {
            const answers = formValues.valuesUpdated.exercise

            if (contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.TYPE_IN
                || contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.CATYPE_IN
            ) {
                let quant = 0
                answers.resposta.map(item => {
                    if (item) {
                        quant += Object.keys(item).length
                    }
                })

                if (quant === answers.qntd_respostas) {
                    setSubmit(false)
                }
            } else if (contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.CHOOSE_MULTIPLE
                || contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.CACHOOSE_MULTIPLE
                || contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.VIDEO_CHOOSE_MULTIPLE
            ) {
                if (answers.resposta) {
                    setSubmit(false)
                }
            } else if (contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.OPEN_QUESTION) {
                if (answers.resposta) {
                    setSubmit(false)
                }
            } else if (answers.qntd_respostas === (answers.resposta.filter(Boolean)).length) {
                setSubmit(false)
            } else {
                setSubmit(true)
            }
        } else {
            setSubmit(true)
        }
    }, [formValues])

    useEffect(() => {
        const { telas_total } = contentExercise || {}
        if (telas_total > 1) {
            Array.from(Array(telas_total).keys()).reduce(async (acc: any, index: any) => {
                const accumulator = await acc
                const { data } = await fetchExerciseHistoryContent(index + 1)

                return Promise.resolve([...accumulator, data.tentativas > 0])
            }, Promise.resolve([]))
                .then(setPrevExercises)
        }
    }, [contentExercise])

    useEffect(() => {
        if (isStudent()) {
            const listIdVigente = userContext.cursosVigentes.map(({ id_curso }: any) => id_curso)
            if (listIdVigente.indexOf(match.params.idCourse * 1) === -1) {
                prePostUserContext
                    && prePostUserContext.listPptTestAlunoYConnect
                    && prePostUserContext.listPptTestAlunoYConnect[0]
                    && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso
                    && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso.fl_feito === false
                    ? window.location.href = "#/"
                    : window.location.href = "#/courses"
            }
        }
    }, [])

    useEffect(() => {
        const script = document.createElement('script');

        script.src = window.lpTag=window.lpTag||{},'undefined'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:'18943792'||'',section:lpTag.section||'',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:'1.10.0',_tagCount:1,protocol:'https:',events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0===e?(this._defB=this._defB||[],this._defB.push(t)):1===e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+'//'+(this.ovr&&this.ovr.domain?this.ovr.domain:'lptag.liveperson.net')+'/tag/tag.js?site='+this.site);var o=document.createElement('script');o.setAttribute('charset',e?e:'UTF-8'),i&&o.setAttribute('id',i),o.setAttribute('src',n),document.getElementsByTagName('head').item(0).appendChild(o)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent('onload',function(){t._domReady('domReady')}):(window.addEventListener('DOMContentLoaded',function(){t._domReady('contReady')},!1),window.addEventListener('load',function(){t._domReady('domReady')},!1)),'undefined'===typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger('LPT','DOM_READY',{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;

        document.body.appendChild(script);
      }, []);

    useEffect(() => {
        const chat = document.querySelector('.LPMcontainer') as any;
        if (chat)
           chat.style.inset = "auto 0px 60px auto";
    }, []);

    const fetchCourseContent = async () => {

        try {
            const { data } = await API.Course.GetAll({
                id: idCourse,
                isDegustacao: false
            }, getConfig())

            const course = data.find(({ id_curso }: any) => id_curso == idCourse)

            localStorage.setItem("courseInfo", JSON.stringify(course))
            setCourseInfo(course)
        } catch (e) {
            setError(true)
        }
    }

    const fetchBookContent = async () => {
        try {
            const { titulo_curso } = courseInfo
            const { idUsuario } = userContext

            const { data: { exercicios } } = await API.Exercicio.GetCourseUnits({
                idUnit: idUnidade,
                curso: titulo_curso,
                id_usuario: idUsuario,
                isDegustacao: false
            }, getConfig())

            const book = exercicios.find(({ nome_secao, ordem_exercicio }: any) =>
                nome_secao === sectionName && ordem_exercicio == orderId)

            //localStorage.setItem("bookInfo", JSON.stringify(book))
            setBookInfo(book)
        } catch (e) {
            setError(true)
        }
    }

    const DecryptExercise = (content :any) =>{

        content.exercicio.enunciado = DescryptoValue.descryptoString( content.exercicio.enunciado );
        content.exercicio.enunciado_traduzido = DescryptoValue.descryptoString( content.exercicio.enunciado_traduzido );
        content.exercicio.conteudo_exercicio = DescryptoValue.descryptoString( content.exercicio.conteudo_exercicio );
        content.exercicio.texto = content.exercicio.texto ? DescryptoValue.descryptoString( content.exercicio.texto ) :  content.exercicio.texto ;
        content.exercicio.respostas_Exercicio_Yconnect =  content.exercicio.respostas_Exercicio_Yconnect.map((elem : object) =>
        DescryptoValue.descryptoObject(elem)
        );

        if (content.anterior) {
            content.anterior.curso = content.anterior.curso ? DescryptoValue.descryptoString( content.anterior.curso ) : content.anterior.curso;
            content.anterior.secao = content.anterior.secao ? DescryptoValue.descryptoString( content.anterior.secao ) : content.anterior.secao;
            content.anterior.unidade = content.anterior.unidade ? DescryptoValue.descryptoString( content.anterior.unidade ) : content.anterior.unidade;
        }

        if ( content.proximo) {
            content.proximo.curso = content.proximo.curso ? DescryptoValue.descryptoString( content.proximo.curso ) : content.proximo.curso;
            content.proximo.unidade = content.proximo.unidade ? DescryptoValue.descryptoString( content.proximo.unidade ) : content.proximo.unidade;
        }

        return content;
    }

    const fetchExerciseContent = async () => {

        try {

            const { idUsuario } = userContext
            const { id_curso, titulo_curso } = courseInfo
            const {
                nome_secao, ordem_exercicio, nome_unidade
            } = bookInfo

            const response = await API.Exercicio.getExercicio({
                userId: idUsuario,
                idCurso: id_curso,
                curso: titulo_curso,
                unidade: nome_unidade,
                idUnidade,
                secao: nome_secao,
                ordem: ordem_exercicio,
                tela: idExercise,
                isDegustacao: false
            }, getConfig())

            if (response && response.data) {
                const { exercicio: { ordem } } = response.data
                setPrevExercises(Array.from(Array(ordem - 1).fill()))
                response.data = DecryptExercise(response.data);
                setContentExercise(response.data)
                setLoading(false)
            } else {
                window.location.href = "#/login"
            }

        } catch (e) {
            setLoading(false)
            setError(true)
        }
    }

    const fetchSaveExercise = async (
        idUsuario: number,
        id_exercicio: any,
        ordem: any,
        qntd_acertos: any,
        qntd_respostas: any,
        respostas: any,
        onFetch: Function) => {

        try {
            await API.Exercicio.saveExercicio({
                id_usuario: idUsuario,
                id_exercicio: id_exercicio,
                ordem_tela: ordem,
                qntd_acertos: qntd_acertos,
                qntd_respostas: qntd_respostas,
                resposta: respostas
            }, getConfig())


            onFetch && onFetch()

            fetchUnlockPostExercise(idUsuario, id_exercicio)
        } catch (e) { }
    }

    const fetchUnlockPostExercise = async (idUsuario: number, id_exercicio: any) => {
        try {
            const { data } = await API.PrePostTest.UnlockPostExercise({
                idAluno: idUsuario,
                id: id_exercicio
            }, getConfig())

            if (data && data.id_teste_aluno != undefined) {
                fetchPrePostTest()
            }
        } catch (e) { }
    }

    const fetchPrePostTest = async () => {
        try {
            const response = await API.PrePostTest.Get({
                id_aluno: userContext.idUsuario,
                id_curso: userContext.idCursoAtual
            }, getConfig())

            localStorage.setItem("prepost", JSON.stringify(response.data))
            onUpdatePrePost(response.data)
            setShowModalPost(true)
        } catch (e) { }
    }

    const fetchExerciseHistoryContent = (tela: number) => {
        const { idUsuario } = userContext
        const { id_curso, titulo_curso } = courseInfo
        const {
            nome_unidade, nome_secao, ordem_exercicio
        } = bookInfo

        return API.Exercicio.getExercicio({
            userId: idUsuario,
            idCurso: id_curso,
            curso: titulo_curso,
            unidade: nome_unidade,
            idUnidade: idUnidade,
            secao: nome_secao,
            ordem: ordem_exercicio,
            tela: tela,
            isDegustacao: false
        }, getConfig())
    }


    const renderBookType = () => {
        const { tipo_exercicio } = contentExercise
        return <HomeworkLabelStyle className="task-card-homework">{tipo_exercicio}</HomeworkLabelStyle>
    }

    contentExercise && contentExercise.exercicio && console.log(getTemplateType(contentExercise.exercicio.template_Yconnect))

    const renderBookLifeTime = () => <div className="w-100 p-5 mt-3 d-none d-lg-block">
        <ExerciseTimelineStyle className="exercise-line d-flex flex-row justify-content-around mb-4">
            {[...Array(contentExercise.telas_total)].map((x, i) => {
                const tela = Number(idExercise)

                if (tela === i + 1) {
                    return <a key={i} className={`exercise-task ${showResult ? 'checked' : 'active'}`}>
                        <img src={showResult ? checked : pencil} alt="" />
                    </a>
                }

                return <Link
                    key={i}
                    className={`exercise-task ${prevExercises[i] ? 'checked-item' : ''}`}
                    to={`/courses/${courseInfo.id_curso}/unit/${idUnidade}/view-book/${bookInfo.nome_secao}/${bookInfo.ordem_exercicio}/exercise/${i + 1}`}>

                    {prevExercises[i] ?
                        <span className="circle"></span>
                        :
                        <img src={pencil} alt="" />
                    }
                </Link>
            })}

        </ExerciseTimelineStyle>
    </div>

    const getTemplate = () => {
        const { idUsuario } = userContext
        const { tentativas, exercicio } = contentExercise
        const { template_Yconnect } = exercicio

        const type = getTemplateType(template_Yconnect)

        let language = 'en'

        if(languageSelectedBrazil) {
            language = 'br'
        }

        let template = [
            {
                "id": "exercise",
                "studentId": idUsuario,
                "type": type,
                "attempts": tentativas,
                "isStudent": isStudent(),
                "exercise": exercicio,
                "translated": language,
                "innerRef": refLanguage
            }
        ]

        return template
    }

    useEffect(() => {
        if(refLanguage.current.selectLanguage) {
            if(languageSelectedBrazil) {
                refLanguage.current.selectLanguage('br')
            }

            if(languageSelectedEnglish) {
                refLanguage.current.selectLanguage('en')
            }
        }
    }, [languageSelectedBrazil, languageSelectedEnglish, contentExercise])

    const refLanguage = useRef<any>({})

    const getAttempts = () => {
        const { tentativas } = contentExercise

        //this rule only applys to open question
        if (tentativas === 1 && ExerciseTemplate.OPEN_QUESTION === getTemplateType(contentExercise.exercicio.template_Yconnect) || tentativas >= 2 && ExerciseTemplate.OPEN_QUESTION === getTemplateType(contentExercise.exercicio.template_Yconnect)) {
            return tentativas
        }

        if (tentativas < 2) {
            return tentativas + 1
        }

        return 3
    }

    const getPageTitle = () => {
        const { nome_secao, ordem_exercicio } = bookInfo

        if (nome_secao.includes('Class')) {
            return `${nome_secao} : Task ${ordem_exercicio}`
        }

        return `${capitalize(nome_secao)} : Task ${ordem_exercicio}`
    }

    const isStudent = () => {
        const { idProfessor, observador, colaborador, usuarioTrial, usuarioDegustacao } = userContext
        return (idProfessor == 0 && !observador && !colaborador && !usuarioTrial && !usuarioDegustacao)
    }

    const sendMessageToTeacher = (exerciseData: any, template: string) => {
        if (template === ExerciseTemplate.OPEN_QUESTION) {
            API.OpenQuestion.sendMessageToTeacher(exerciseData, getConfig())
        }
    }

    const saveExercise = () => {
        const {
            valuesUpdated: {
                exercise: {
                    resposta,
                    qntd_acertos,
                    qntd_respostas
                }
            }
        } = formValues

        const { id_curso } = courseInfo
        const { idUsuario } = userContext
        const { nome_secao } = bookInfo

        const {
            exercicio: {
                id_exercicio,
                ordem,
                template_Yconnect: { nome }
            },
            tentativas
        } = contentExercise

        if (isStudent() && tentativas < 2) {
            let respostas = resposta.toString()
            if (nome === ExerciseTemplate.UNSCRAMBLE || nome === ExerciseTemplate.TYPE_IN) {
                respostas = JSON.stringify(resposta)
            }

            fetchSaveExercise(
                idUsuario,
                id_exercicio,
                ordem,
                qntd_acertos,
                qntd_respostas,
                respostas,
                () => {
                    onCheckTag({
                        idAluno: idUsuario,
                        id: id_exercicio,
                        idSituacao: 1,
                        idCurso: id_curso
                    })
                    sendMessageToTeacher({
                        studentId: idUsuario,
                        exerciseId: id_exercicio,
                        questionOrder: ordem,
                        courseId: id_curso,
                        unitId: idUnidade,
                        sectionName: nome_secao
                    }, nome)
                }
            )
        }

        if (contentExercise.proximo != null && contentExercise.proximo.id_unidade != idUnidade) {
            handleModalNextUnit()
        }

        setShowResult(true)
        window.innerWidth > 1024 ? window.scrollTo(0, 0) : window.scrollTo(0, 170);
    }

    const reloadExercise = () => {
        setLoading(true)
        setContentExercise(null)
        setShowResult(false)
    }

    // TODO: Break this into a separate component
    const renderResult = () => {
        const answers = formValues.valuesUpdated.exercise
        const qntd_erros = answers.qntd_respostas - answers.qntd_acertos;

        return <div className="w-100 pt-5 pt-lg-0">
            {
                contentExercise.exercicio.template_Yconnect.nome === ExerciseTemplate.OPEN_QUESTION ?
                    <>
                        <Col md="12" className="text-center">
                            <h1>
                                Now, wait for your teacher's feedback.
                            </h1>
                        </Col>
                    </>
                    :
                    <>
                        <Col md="12" className="text-center">
                            <h1>
                                {
                                    languageSelectedBrazil &&
                                    <h1>{qntd_erros > 0 ? 'Continue tentando!' : 'Bom trabalho!'} Sua pontuação é:</h1>
                                }
                                {
                                    languageSelectedEnglish &&
                                    <h1>{qntd_erros > 0 ? 'Keep Trying!' : 'Nice work!'} Your score is:</h1>
                                }

                            </h1>
                        </Col>
                        <HeaderScoreStyle md="12" className="text-center mt-2">
                        {
                            languageSelectedBrazil &&
                            <h2>
                                <span className="right">{answers.qntd_acertos} corretas</span>
                                <span className="icon-x">
                                    <img src={remove} alt="" />
                                </span>
                                <span className="wrong">{qntd_erros} erradas</span>
                            </h2>
                        }
                        {
                            languageSelectedEnglish &&
                            <h2>
                                <span className="right">{answers.qntd_acertos} right</span>
                                <span className="icon-x">
                                    <img src={remove} alt="" />
                                </span>
                                <span className="wrong">{qntd_erros} wrong</span>
                            </h2>
                        }
                        </HeaderScoreStyle>
                        <Col md="12" className="text-center mt-2">
                            {
                                languageSelectedBrazil &&
                                <h3>Continue estudando e progredindo</h3>
                            }
                            {
                                languageSelectedEnglish &&
                                <h3>Keep studying and getting better</h3>
                            }
                        </Col>
                    </>
            }
            <Col md="12" className="d-none d-md-flex flex-row justify-content-center mt-5 mb-5">
                <ButtonStyle
                    size="medium"
                    label={languageSelectedBrazil ? "Repita a questão" : "Repeat this question"}
                    icon="reset"
                    onClick={() => { reloadExercise() }}
                />
                <span className="p-2">{languageSelectedBrazil ? "Ou" : "Or"}</span>
                {
                    contentExercise.proximo ?
                            <>
                            {contentExercise.proximo.id_unidade == idUnidade
                            ?
                                <YLinkButtonStyle
                                    green
                                    size="medium"
                                    label={languageSelectedBrazil ? "Vá para a próxima questão" : "Go to the next question"}
                                    icon="arrowRight"
                                    onClick={() => { setShowResult(false) }}
                                    link={`/courses/${courseInfo.id_curso}/unit/${contentExercise.proximo.id_unidade}/view-book/${contentExercise.proximo.secao}/${contentExercise.proximo.ordem}/exercise/${contentExercise.proximo.tela}`}
                                />
                            :   <ButtonNextUnit
                                    green
                                    size="medium"
                                    label={languageSelectedBrazil ? "Vá para a próxima questão" : "Go to the next question"}
                                    icon="arrowRight"
                                    onClick={() => {
                                        handleModalNextUnit()
                                        setShowResult(false)
                                    }}
                                />
                            }
                            </>
                        :
                        <YLinkButtonStyle
                            green
                            size="medium"
                            label="Return to courses"
                            icon="arrowRight"
                            onClick={() => { setShowResult(false) }}
                            link="/courses"
                        />
                }
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none mt-5">
                <ButtonStyle
                    size="medium"
                    label="Repeat this question"
                    icon="reset"
                    onClick={() => { reloadExercise() }}
                />
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none text-center mt-2">
                <span className="p-2">Or</span>
            </Col>
            <Col xs={{ size: 8, offset: 2 }} sm={{ size: 8, offset: 2 }} className="d-md-none mt-2 mb-5">
                {
                    contentExercise.proximo ?
                        <YLinkButtonStyle
                            green
                            size="medium"
                            label="Go to the next question"
                            icon="arrowRight"
                            onClick={() => { setShowResult(false) }}
                            link={`/courses/${courseInfo.id_curso}/unit/${contentExercise.proximo.id_unidade}/view-book/${contentExercise.proximo.secao}/${contentExercise.proximo.ordem}/exercise/${contentExercise.proximo.tela}`}
                        />
                        :
                        <YLinkButtonStyle
                            green
                            size="medium"
                            label="Return to courses"
                            icon="arrowRight"
                            onClick={() => { setShowResult(false) }}
                            link="/courses"
                        />
                }
            </Col>
        </div>

    }

    if (error) return <ServerErrorPage location={location} />

    const _popupCenter = (url: string, title: string, w: number, h: number) => {
        // Fixes dual-screen position                         Most browsers      Firefox
        const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title, 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

        if (window.focus) newWindow.focus();
    }

    const handleModalNextUnit = () => {
        setModalNextUnit(!modalNextUnit)
    }

    const handleSelectedLanguageBrazil = () => {
        if(!contentExercise.exercicio.enunciado_traduzido){
            Toastify({
                text: "Não há tradução cadastrada para o enunciado dessa questão.",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
        setLanguageSelectedBrazil(true)
        setLanguageSelectedEnglish(false)
        document.querySelector('#areaBrazil').classList.add("active")
        document.querySelector('#areaEnglish').classList.remove("active")
    }

    const handleSelectedLanguageEnglish = () => {
        setLanguageSelectedBrazil(false)
        setLanguageSelectedEnglish(true)
        document.querySelector('#areaEnglish').classList.add("active")
        document.querySelector('#areaBrazil').classList.remove("active")
    }

    const renderTranslateAttempts = () => {
        if(idCourse == 67) {
            return  <PageTitle label={getPageTitle()}>

                <span className="col-12 mb-4">
                    Attempts: {getAttempts()} / 2
                        <AttemptsIconStyle id="TooltipAttempt">
                        ?
                        </AttemptsIconStyle>
                    <Tooltip placement="right" onClick={() => setTooltip(!tooltipOpen)} isOpen={tooltipOpen} target="TooltipAttempt" toggle={() => setTooltip(!tooltipOpen)}>
                        {
                            languageSelectedBrazil &&
                            <span>Você tem duas tentativas por atividade. Após isso, você pode tentar novamente, mas não irá alterar suas notas. Se precisar de ajuda, pergunte a um Online Teacher!</span>
                        }

                        {
                            languageSelectedEnglish &&
                            <span>You have two attempts on a task. After that, you can try again, but it won’t change your grades. If you need help, ask an Online Teacher!</span>
                        }
                    </Tooltip>
                </span>
            </PageTitle>
        }
    }

    const renderTranslateFlags = () => {
        if(idCourse == 67) {
            return  <AlignFlags>
                        <BreadcrumbExercise
                        contentExercise={contentExercise}
                        ordem_exercicio={bookInfo.ordem_exercicio}
                        isStudent={isStudent()}
                        />
                        <FlagPosition>
                            <FlagBackground id="areaBrazil" className={languageSelectedBrazil && "active"}>
                                <FlagImages onClick={handleSelectedLanguageBrazil} src={brazilFlag} alt="" className="pl-2" />
                            </FlagBackground>
                            <FlagBackground id="areaEnglish" className={languageSelectedEnglish && "active"}>
                                <FlagImages onClick={handleSelectedLanguageEnglish} src={usFlag} alt="" className="pl-2" />
                            </FlagBackground>
                        </FlagPosition>
                    </AlignFlags>
        }
    }

    return <Container>
        {
            isLoading
                ? <Loader />
                : <>
                {
                    idCourse != 67 ?
                        <BreadcrumbExercise
                        contentExercise={contentExercise}
                        ordem_exercicio={bookInfo.ordem_exercicio}
                        isStudent={isStudent()}
                        />
                    : <>
                        {renderTranslateFlags()}
                    </>
                }
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                            {idCourse != 67 &&
                                <PageTitle label={getPageTitle()}>
                                    <span className="col-12 mb-4">
                                        Attempts: {getAttempts()} / 2
                                            <AttemptsIconStyle id="TooltipAttempt">
                                            ?
                                            </AttemptsIconStyle>
                                        <Tooltip placement="right" onClick={() => setTooltip(!tooltipOpen)} isOpen={tooltipOpen} target="TooltipAttempt" toggle={() => setTooltip(!tooltipOpen)}>
                                            You have two attempts on a task. After that, you can try again, but it won’t change your grades. If you need help, ask an Online Teacher!
                                            </Tooltip>
                                    </span>
                                </PageTitle>
                            }
                            {renderTranslateAttempts()}
                        </Col>

                        <Col xs="12" sm="12" md="12" lg="6" xl="6" className="d-flex flex-row justify-content-end mt-lg-1 mb-2">
                            <Col xs="4" sm="4" md="4" lg="4" className="text-right">
                                <h4 style={{ marginTop: '12px', whiteSpace: 'nowrap' }}>Need Help?</h4>
                            </Col>

                            <ButtonStyle
                                id        = "buttonFlag"
                                // disabled  = {true}
                                size="medium"
                                label="Online Teacher"
                                icon="messageGreen"
                                onClick={() => {
                                    const chat = document.querySelector('.LPMcontainer') as any
                                    const button = document.querySelector('#buttonFlag') as HTMLButtonElement

                                    if (chat) {
                                        chat.click()
                                        button.disabled = false
                                    }
                                    else {
                                        Toastify({
                                            text: "Please, wait a few seconds and try again.",
                                            duration: 3000,
                                            className: "toastify-warning",
                                            newWindow: true,
                                            close: true,
                                            gravity: "top",
                                            position: "right",
                                            stopOnFocus: true,
                                            escapeMarkup: false,
                                            onClick: function(){} 
                                          }).showToast();
                                    }
                                }} />
                        </Col>
                    </Row>

                    <Row className="m-0 mb-5">
                        <div className="exercise card shadow d-flex flex-row flex-wrap justify-content-between col-12 p-0">
                            {renderBookType()}
                            {renderBookLifeTime()}
                            {
                                showResult
                                    ? renderResult()
                                    : <>
                                        {
                                            width <= 768 &&
                                            <span className="col-12 mb-5 mt-5">

                                            </span>
                                        }
                                        <HeaderExerciseStyle className="exercise-header d-flex flex-column justify-content-center w-100 pb-3 pl-3 pr-3">
                                            {
                                                languageSelectedBrazil && <h1 className="text-center h3 mb-3 font-weight-bolder">
                                                    Questão {contentExercise.exercicio.ordem} de {contentExercise.telas_total}
                                                </h1>
                                            }
                                            {
                                                languageSelectedEnglish && <h1 className="text-center h3 mb-3 font-weight-bolder">
                                                    Question {contentExercise.exercicio.ordem} of {contentExercise.telas_total}
                                                </h1>
                                            }
                                            <H3Style
                                                className="text-center mt-2"
                                                dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(
                                                    languageSelectedBrazil && contentExercise.exercicio.enunciado_traduzido ?
                                                    contentExercise.exercicio.enunciado_traduzido :
                                                    contentExercise.exercicio.enunciado
                                                )) }}
                                            ></H3Style>
                                        </HeaderExerciseStyle>
                                        <FormStyle
                                            className="exercise-body w-100"
                                            onChange={setFormValues}
                                            row={false}
                                            template={getTemplate()}
                                            />
                                    </>
                            }

                            {
                                showResult
                                    ? <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                        <BackLinkStyle
                                            size="medium"
                                            link={`/courses/${courseInfo.id_curso}/view-book`}
                                            icon="arrowPrevious"
                                            label={bookInfo.tipo_unidade === UnitType.BOOK_TASK ? "Back (Book Tasks)" : "Back (Resource Book)"} />

                                    </FooterStyle>
                                    : <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                        {
                                            contentExercise.anterior
                                                && contentExercise.telas_total > 1
                                                && contentExercise.exercicio.ordem > 1
                                                ? <BackLinkStyle
                                                    icon="arrowPrevious"
                                                    size="medium"
                                                    label={`Back (Question ${contentExercise.anterior.tela} / ${contentExercise.telas_total})`}
                                                    link={`/courses/${courseInfo.id_curso}/unit/${idUnidade}/view-book/${bookInfo.nome_secao}/${bookInfo.ordem_exercicio}/exercise/${contentExercise.anterior.tela}`}
                                                />
                                                : <BackLinkStyle
                                                    link={`/courses/${courseInfo.id_curso}/view-book`}
                                                    icon="arrowPrevious"
                                                    label={bookInfo.tipo_unidade === UnitType.BOOK_TASK ? "Back (Book Tasks)" : "Back (Resource Book)"} />
                                        }
                                        <span className="d-flex flex-row">
                                            {!canSubmit && <ButtonStyle
                                                green
                                                size="medium"
                                                label="Answer Question"
                                                onClick={saveExercise}
                                                icon="checked"
                                            />}
                                            <NextUnit isShow={modalNextUnit} onClose={handleModalNextUnit} idCourse={courseInfo.id_curso} nameUnit={bookInfo.nome_unidade} content={contentExercise} username={userContext.nome}/>
                                            {
                                                !!canSubmit
                                                && contentExercise.proximo &&
                                                <>
                                                   { contentExercise.proximo.id_unidade == idUnidade
                                                        ? <BackLinkStyle
                                                        size="small"
                                                        className="ml-2"
                                                        label={`Next (Question ${contentExercise.proximo.tela} / ${contentExercise.telas_total})`}
                                                        link={`/courses/${courseInfo.id_curso}/unit/${contentExercise.proximo.id_unidade}/view-book/${contentExercise.proximo.secao}/${contentExercise.proximo.ordem}/exercise/${contentExercise.proximo.tela}`}
                                                        iconLeft="arrowRightGreen" />
                                                    : <ButtonNextUnit
                                                        size="small"
                                                        className="ml-2 p-2"
                                                        label={`Next (Question ${contentExercise.proximo.tela} / ${contentExercise.telas_total})`}
                                                        iconLeft="arrowRightGreen"
                                                        onClick={handleModalNextUnit}/>
                                                   }
                                                </>
                                            }
                                        </span>
                                    </FooterStyle>
                            }
                        </div>
                    </Row>


                    {
                        prePostUserContext
                        && prePostUserContext.listPptTestAlunoYConnect
                        && prePostUserContext.listPptTestAlunoYConnect[0]
                        && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso
                        && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre === false
                        && <PostTestModal
                            isShow={showModalPost}
                            onClose={() => { setShowModalPost(false) }}
                            username={userContext.nome}
                            prePost={prePostUserContext} />
                    }
                </>
        }

    </Container>
}

export default ExercisePage

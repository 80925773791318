import styled  from "styled-components"
import { Col } from "reactstrap"

const ColNavbarLogoStyle = styled(Col)`
    flex-direction: row;
    display: flex;

    @media screen and (max-width: 1024px) {
        padding: 0;
        margin: 0px;
    }
`

export default ColNavbarLogoStyle
import * as React             from "react"
import {useState, useEffect, useContext}  from "react"
import { connect }            from "react-redux"
import { bindActionCreators } from "redux"
import {
    Container,
    Card,
    Row,
    Col
} from "reactstrap"

import styled from "styled-components"

import Loader from "YConnect/Components/Loader"

import { ButtonStyle } from "YConnect/Components/Button"

import UserContext from "YConnect/User.context"

import * as CourseResource            from "YConnect/ResourcesBackEnd/Course.resources"
import * as UnitResource              from "YConnect/ResourcesBackEnd/Unit.resources"
import * as SectionResource           from "YConnect/ResourcesBackEnd/Section.resources"
import * as ExerciseResource          from "YConnect/ResourcesBackEnd/Exercise.resources"
import * as QuestionResource          from "YConnect/ResourcesBackEnd/Question.resources"
import * as TemplateResource          from "YConnect/ResourcesBackEnd/Template.resources"
import * as ExerciseTypeResource      from "YConnect/ResourcesBackEnd/ExerciseType.resources"
import * as LearningObjectiveResource from "YConnect/ResourcesBackEnd/LearningObjective.resources"


import ExerciseEditorCard     from "YConnect/PagesComponents/CMS/Cards/Exercise.card"
import QuestionChooseTypeCard from "YConnect/PagesComponents/CMS/Cards/QuestionChooseType.card"
import QuestionNewCard        from "YConnect/PagesComponents/CMS/Cards/QuestionNew.card"
import QuestionEditCard       from "YConnect/PagesComponents/CMS/Cards/QuestionEdit.card"
import QuestionCard           from "YConnect/PagesComponents/CMS/Cards/Question.card"

import QuestionRemoveModal from "YConnect/PagesComponents/CMS/Modals/QuestionRemove.modal"
import ExerciseRemoveModal from "YConnect/PagesComponents/CMS/Modals/ExerciseRemove.modal"
import ExerciseDragModal from "YConnect/PagesComponents/CMS/Modals/ExerciseDrag.modal"
import QuestionDragModal from "YConnect/PagesComponents/CMS/Modals/QuestionDrag.modal"

import Filters from "YConnect/Components/Filters2"
import QueryParamsActionsCreator from "YConnect/Actions/QueryParams.actionsCreator"
import ExerciseCardHeader from "YConnect/PagesComponents/CMS/CardHeaders/Exercise.cardHeader"

import useChangeQueryParamByFilter from "YConnect/PagesComponents/CMS/Hooks/useChangeQueryParamByFilter"
import useUpdateListByFilter from "YConnect/PagesComponents/CMS/Hooks/useUpdateListByFilter"
import useFilterState from "YConnect/Hooks/useFilterState"

import CardActiveStyled from "YConnect/PagesComponents/CMS/Styleds/CardNew.styled"

import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

import ConvertLegacyExerciseInQuestion from "YConnect/PagesComponents/CMS/Functions/ConvertLegacyExerciseInQuestion"
import { BreadcrumbCMSExercise } from "YConnect/Components/BreadCrumbList"
import PageTitle from "YConnect/Components/PageTitle"

import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

//TODO Tirar isso daqui não usar
let removables:any=[];

const setResponse = (f:any) => (response:any) => {
    const {data} = response
    f(data)
}

const DragableDiv = styled.div`
    .enable-link {
        pointer-events: visible;
        cursor: pointer;

        span {
            position: absolute;
            margin-left: -4px;
            padding: 10px 0;
            border-radius: 3px;

            &::before {
                position: relative;
                top: 1px;
                left: 10px;
                display: block;
                width: 20px;
                height: 15px;
                margin-right: 20px;
                cursor: row-resize;
                background: -webkit-linear-gradient(top, ${props => props.theme.main.textColor}, ${props => props.theme.main.textColor} 20%, ${props => props.theme.main.backgroundPage} 0, ${props => props.theme.main.backgroundPage} 40%, ${props => props.theme.main.textColor} 0, ${props => props.theme.main.textColor} 60%, ${props => props.theme.main.backgroundPage} 0, ${props => props.theme.main.backgroundPage} 80%, ${props => props.theme.main.textColor} 0, ${props => props.theme.main.textColor});
                background: linear-gradient(180deg,${props => props.theme.main.textColor},${props => props.theme.main.textColor} 20%,${props => props.theme.main.backgroundPage} 0,${props => props.theme.main.backgroundPage} 40%,${props => props.theme.main.textColor} 0,${props => props.theme.main.textColor} 60%,${props => props.theme.main.backgroundPage} 0,${props => props.theme.main.backgroundPage} 80%,${props => props.theme.main.textColor} 0,${props => props.theme.main.textColor});
                content: "";
            }
        }
    }
`;

const CMSContainer = ({
    QueryParams,
    AddQueryParam,
    RemoveQueryParam
}:any) => {

    const {idUsuario}:any = useContext(UserContext)

    const [isLoading, setLoading] = useState(false)
    const [isLoadingFilters, setLoadingFilters] = useState(false)

    const [listCourses, setListCourses]                 = useState<Array<CourseType>>()
    const [listUnitTypes, setListUnitTypes]             = useState<Array<UnitTypeType>>()
    const [listUnits, setListUnits]                     = useState<Array<UnitType>>()
    const [listSections, setListSections]               = useState<Array<SectionType>>()
    const [listExercises, setListExercises]             = useState<Array<ExerciseType>>()
    const [listQuestions, setListQuestions]             = useState<Array<QuestionType>>()
    const [listLearningObjective, setLearningObjective] = useState<Array<LearningObjectiveType>>()

    const [question, setQuestion]                   = useState<QuestionType>()
    const [magicQuestion, setMagicQuestion]         = useState<QuestionType>()
    const [questionForRemove, setQuestionForRemove] = useState<QuestionType>()
    const [exerciseForRemove, setExerciseForRemove] = useState<ExerciseType>()

    const [magicUrl, setMagicUrl] = useState<string>()

    const [listTemplates, setListTemplates]       = useState<Array<TemplateType>>([])
    const [listExerciseType, setListExerciseType] = useState<Array<ExerciseTypeType>>()

    const [nextExerciseOrder, setNextExerciseOrder] = useState<number>()
    const [nextQuestionOrder, setNextQuestionOrder] = useState<number>()

    const [newExerciseTitle, setNewExerciseTitle] = useState<string>("")
    const [newLearningObjective, setNewLearningObjective] = useState<number>()

    const [isShowQuestionRemoveModal, setShowQuestionRemoveModal] = useState<boolean>(false)
    const [isShowExerciseRemoveModal, setShowExerciseRemoveModal] = useState<boolean>(false)

    const [removableExercises, setRemovableExercises] = useState<any>([])

    const [modalDragExercise, setModalDragExercise] = useState(false)
    const [modalDragQuestion, setModalDragQuestion] = useState(false)
    const [editOpen, setEditOpen] = useState(-1)

    const [isCreating, setIsCreating] = useState(false)

    const [dragExercises, setDragExercises] = useState([])
    const [dragQuestions, setDragQuestions] = useState([])

    const [
        filters,
        setFilter
    ] = useFilterState()
    //TODO Filter

    const {
        courseIdSelected,
        unitTypeSelected,
        unitIdSelected,
        sectionIdSelected,
        exerciseIdSelected,
        templateIdSelected,
        newExerciseOrder,
        newExerciseType,
        questionIdSelected,
        advancedEditor
    } = filters

    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "course",          courseIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "unitType",        unitTypeSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "unit",            unitIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "section",         sectionIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "exercise",        exerciseIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "template",        templateIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "newExercise",     newExerciseOrder)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "newExerciseType", newExerciseType)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "question",        questionIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "advancedEditor",  advancedEditor)

    useEffect(() => {
        setLoading(true)
        CourseResource
            .GetAll()
            .then(setResponse(setListCourses))
            .finally(() => setLoading(false))

        UnitResource
            .GetUnitTypes()
            .then(setResponse(setListUnitTypes))

        TemplateResource
            .GetAll()
            .then(setResponse(setListTemplates))

        ExerciseTypeResource
            .GetAll()
            .then(setResponse(setListExerciseType))


        LearningObjectiveResource
            .GetAll()
            .then(setResponse(setLearningObjective))

     }, [])

    useUpdateListByFilter(setListUnits,     UnitResource.GetAllByCourseId,       unitTypeSelected, [courseIdSelected, unitTypeSelected],   ()=> setLoadingFilters(true), ()=>setLoadingFilters(false))
    useUpdateListByFilter(setListSections,  SectionResource.GetAllByUnitId,      unitIdSelected, [unitIdSelected],     ()=> setLoadingFilters(true), ()=>setLoadingFilters(false))
    useUpdateListByFilter(setListExercises, ExerciseResource.GetAllBySectionId,  sectionIdSelected, [sectionIdSelected],  ()=> setLoadingFilters(true), ()=>setLoadingFilters(false))
    useUpdateListByFilter(setListQuestions, QuestionResource.GetAllByExerciseId, exerciseIdSelected, [exerciseIdSelected], ()=> setLoadingFilters(true), ()=>setLoadingFilters(false))

    useEffect(() => {
        if (QueryParams && Object.keys(QueryParams).length > 0 && Object.keys(filters).length === 0) {
            setFilter({
                ...QueryParams.course          && QueryParams.course          !== "" ? { courseIdSelected   : QueryParams.course }          : {},
                ...QueryParams.unitType        && QueryParams.unitType        !== "" ? { unitTypeSelected   : QueryParams.unitType }        : {},
                ...QueryParams.unit            && QueryParams.unit            !== "" ? { unitIdSelected     : QueryParams.unit }            : {},
                ...QueryParams.section         && QueryParams.section         !== "" ? { sectionIdSelected  : QueryParams.section }         : {},
                ...QueryParams.exercise        && QueryParams.exercise        !== "" ? { exerciseIdSelected : QueryParams.exercise }        : {},
                ...QueryParams.template        && QueryParams.template        !== "" ? { templateIdSelected : QueryParams.template }        : {},
                ...QueryParams.newExercise     && QueryParams.newExercise     !== "" ? { newExerciseOrder   : QueryParams.newExercise }     : {},
                ...QueryParams.newExerciseType && QueryParams.newExerciseType !== "" ? { newExerciseType    : QueryParams.newExerciseType } : {},
                ...QueryParams.question        && QueryParams.question        !== "" ? { questionIdSelected : QueryParams.question }        : {},
                ...QueryParams.advancedEditor  && QueryParams.advancedEditor  !== "" ? { advancedEditor     : QueryParams.advancedEditor }  : {}
            })
        }
     }, [QueryParams])

    useEffect(() => {
        if (listUnits) {
            setFilter({unitTypeSelected: ""})
        }
    }, [courseIdSelected])

    useEffect(() => {
        if (listUnits) {
            setListUnits([])
            setFilter({unitIdSelected: ""})
        }
    }, [unitTypeSelected])

    useEffect(() => {
        if (listSections) {
            setListSections([])
            setFilter({sectionIdSelected: ""})
        }
    }, [unitIdSelected])

    useEffect(() => {
        if (listExercises) {
            setListExercises([])
            setFilter({exerciseIdSelected: "", newExerciseType: "", newExerciseOrder: "", questionIdSelected: ""})
        }

    }, [sectionIdSelected])

    useEffect(() => {
        if (listQuestions) {
            setListQuestions([])
            setFilter({templateIdSelected: "", newExerciseType: "", newExerciseOrder: "", questionIdSelected: ""})
        }

    }, [exerciseIdSelected])


    useEffect(() => {
        if (questionIdSelected) {
            setFilter({templateIdSelected: ""})
            fetchQuestion()
        }else{
            setQuestion(undefined)
        }
    }, [questionIdSelected])

    useEffect(() => {
        if (templateIdSelected) {
            setFilter({questionIdSelected: ""})
        }
    }, [templateIdSelected])

    useEffect(() => {
        if (listExercises) {
            setNextExerciseOrder(listExercises.length+1)
        }
        verifyRemovableExercises()

    }, [listExercises])

    useEffect(() => {
        if (listQuestions) {
            setNextQuestionOrder(listQuestions.length+1)
        }
        verifyRemovableExercises()

    }, [listQuestions])

    useEffect(() => {
        if (magicUrl) {
            fetchMagicQuestion()
        }
    }, [magicUrl])

    const fetchMagicQuestion = async () => {

        try{
            const [_, hashRoute] = magicUrl.split("#")

            const [
                __,
                ___,
                idCourse,
                ____,
                idUnidade,
                _____,
                _sectionName,
                orderId,
                ______,
                idExercise
            ] = hashRoute.split("/")


            const sectionName = decodeURI(_sectionName)

            const {id_curso, titulo_curso} = (await API.Course.GetAll({
                id: idCourse,
                isDegustacao: false
            }, getConfig()))
            .data
            .find(({id_curso}:any) => id_curso == idCourse)

            const {nome_unidade, nome_secao, ordem_exercicio} = (await API.Exercicio.GetCourseUnits({
                idUnit       : idUnidade,
                curso        : titulo_curso,
                id_usuario   : idUsuario,
                isDegustacao : false
            }, getConfig()))
            .data
            .exercicios
            .find(({nome_secao, ordem_exercicio}:any) =>
             nome_secao === sectionName && ordem_exercicio == orderId)


            const exerciseLegacy = (await API.Exercicio.getExercicio({
                userId       : idUsuario,
                idCurso      : id_curso,
                curso        : titulo_curso,
                unidade      : nome_unidade,
                idUnidade,
                secao        : nome_secao,
                ordem        : ordem_exercicio,
                tela         : idExercise,
                isDegustacao : false
            }, getConfig()))
            .data

            const dialogos = (await API.Exercicio.getDialogo({
                idExercicio: exerciseLegacy.exercicio.id_exercicio,
                tela: exerciseLegacy.exercicio.ordem
            }, getConfig()))
            .data

            const newQuestion = ConvertLegacyExerciseInQuestion(exerciseLegacy, listTemplates, dialogos)

            setMagicQuestion(newQuestion)
            setFilter({templateIdSelected:newQuestion.TemplateId})
            setMagicUrl(undefined)
        }catch(e) {
            console.error(e)
        }
    }

    const fetchQuestion = () => {
        setLoading(true)
        QuestionResource
        .GetById(questionIdSelected)
        .then((response:any) => setQuestion(response.data))
        .finally(() => setLoading(false))
    }

    const updateQuestions = () => {
        setListQuestions([])
        setLoading(true)
        QuestionResource
        .GetAllByExerciseId(exerciseIdSelected)
        .then((response:any) => setListQuestions(response.data))
        .finally(() => setLoading(false))
    }


    //TODO Revisar esse metodo
    const verifyRemovableExercises =  () =>{
        if (listExercises != undefined
            && listExercises.length > 0
            && (exerciseIdSelected == null || exerciseIdSelected == "")) {
               // setLoading(true)
            removables=[]
             listExercises.forEach( (value, idx) =>{
                QuestionResource
                .GetAllByExerciseId(value.Id)
                .then((response:any) => {
                    removables.push({Id: value.Id, isRemovable :response.data.length==0 })
                }).finally(() => {
                    if (removables.length==listExercises.length) {
                        setRemovableExercises(removables)
                        //setLoading(false)
                    }
                })
            })
        }/*else if (listExercises != undefined) {
            setLoading(false)
        }*/
    }

    const createQuestion = (newQuestion:QuestionType) => {
        setLoading(true)
        QuestionResource
        .Create({
            ...newQuestion,
            ExerciseId    : exerciseIdSelected,
            exerciseId    : exerciseIdSelected,
            QuestionOrder : nextQuestionOrder,
            TemplateId    : templateIdSelected
        })
        .then(() => {
            setFilter({templateIdSelected: ""})
            updateQuestions()
        })
        .finally(() => setLoading(false))
    }

    const createExercise = () => {
        setLoading(true)
        setListExercises([])
        ExerciseResource
        .Create({
            Order               : newExerciseOrder,
            sectionId           : sectionIdSelected,
            SectionId           : sectionIdSelected,
            ExerciseTypeId      : newExerciseType,
            ExerciseTitle       : newExerciseTitle,
            LearningObjectiveId : newLearningObjective
        })
        .then(()=>{
            setFilter({newExerciseType:"", newExerciseOrder:""})
            setNewExerciseTitle("")
            setNewLearningObjective(undefined)
            setMagicQuestion(undefined)
        })
        .finally(()=> {
            const newSectionIdSelected = sectionIdSelected
            setFilter({sectionIdSelected:""})
            setFilter({sectionIdSelected:newSectionIdSelected})
            setLoading(false)
        })
    }

    const updateExercise = (exercise:ExerciseType) =>{
        setLoading(true)
        setListExercises([])
        ExerciseResource
        .UpdateById({listExercise: [{
            Id             : exercise.Id,
            Order          : exercise.Order,
            sectionId      : exercise.SectionId,
            SectionId      : exercise.SectionId,
            ExerciseTypeId : newExerciseType,
            ExerciseTitle  : newExerciseTitle.length > 0 ? newExerciseTitle : null,
            LearningObjectiveId : newLearningObjective
        }]})
        .then(()=>{
            setFilter({newExerciseType:"", newExerciseOrder:""})
            setNewExerciseTitle("")
            setNewLearningObjective(undefined)
            setMagicQuestion(undefined)
        })
        .finally(()=> {
            const newSectionIdSelected = sectionIdSelected
            setFilter({sectionIdSelected: ""})
            setFilter({sectionIdSelected:newSectionIdSelected})
            setLoading(false)
        })
    }

    const removeExercise = (exerciseId:number) => {

        ExerciseResource
        .DeleteById(exerciseId)
        .then(() => {
            setFilter({templateIdSelected:""})
            refreshExercise()
            setExerciseForRemove(undefined)
            setShowExerciseRemoveModal(false)
            let clearList:any = [];
            var order = 1;
            listExercises.forEach(exercise => {
              if(exercise.Id != exerciseId){
                exercise.Order = order;
                clearList.push(exercise);
                order += 1;
              }
            });
            ExerciseResource
            .UpdateById({listExercise: clearList})
            .then(() => {
              refreshExercise();
              setModalDragExercise(false)
            })
        })
    }

    const refreshExercise = () => {
        setListExercises([])
        setLoading(true)
        ExerciseResource
        .GetAllBySectionId(sectionIdSelected)
        .then((response:any) => setListExercises(response.data))
        .finally(() => setLoading(false))
    }

    const updateQuestion = (question:QuestionType) => {
        setLoading(true)
        QuestionResource
        .UpdateById({listQuestion: [question]})
        .then(() => {
            setFilter({questionIdSelected: ""})
            updateQuestions()
        })
        .finally(() => setLoading(false))
    }

    const removeQuestion = (questionId:number) => {
        setLoading(true)
        QuestionResource
        .DeleteById({questionId})
        .then(() => {
            setFilter({templateIdSelected:""})
            setQuestionForRemove(undefined)
            setShowQuestionRemoveModal(false)
            let clearList:any = [];
            var order = 1;
            listQuestions.forEach(question => {
              if(question.Id != questionId){
                question.QuestionOrder = order;
                clearList.push(question);
                order += 1;
              }
            });
            QuestionResource
            .UpdateById({listQuestion: clearList})
            .then(() => {
                updateQuestions();
              setModalDragQuestion(false)
            }).finally(() => setLoading(false))
        })
    }

    const handleCreateExercise = ()                         => createExercise()
    const handleUpdateExercise = (exercise:ExerciseType)    => updateExercise(exercise)
    const handleCreateQuestion = (newQuestion:QuestionType) => createQuestion(newQuestion)
    const handleRemoveQuestion = ()                         => removeQuestion(questionForRemove.Id)
    const handleRemoveExercise = ()                         => removeExercise(exerciseForRemove.Id)
    const handleOpenModalQuestionRemove = (question:QuestionType) => {
        setQuestionForRemove(question)
        setShowQuestionRemoveModal(true)
    }
    const handleCloseModalQuestionRemove = () => {
        setShowQuestionRemoveModal(false)
        setQuestionForRemove(undefined)
    }

    const handleOpenModalExerciseRemove = (exercise:ExerciseType) => {
        listExercises
        setExerciseForRemove(exercise)
        setShowExerciseRemoveModal(true)
    }
    const handleCloseModalExerciseRemove = () => {
        setShowExerciseRemoveModal(false)
        setExerciseForRemove(undefined)
    }

    const handleUpdateQuestion = (question:QuestionType)    => updateQuestion(question)

    const handleOpenQuestionEditor    = (exerciseId:number)            => setFilter({exerciseIdSelected:exerciseId})
    const handleChangeFilter          = (filterName:string, value:any) => setFilter({[filterName]:value})
    const handleSelectTemplate        = (Id:number)                    => setFilter({templateIdSelected:Id})
    // const handleNewExercise           = ()                             => setFilter({newExerciseOrder:nextExerciseOrder})
    const handleNewExercise = () => {
        setFilter({newExerciseOrder:nextExerciseOrder})
        setIsCreating(true)
    }
    const handleCancelNewExercise     = ()                             => setFilter({newExerciseType: "", newExerciseOrder: ""})
    const handleCancelQuestionEditor  = ()                             => setFilter({exerciseIdSelected: ""})
    const handleChangeNewExerciseType = (exerciseTypeId:number)        => { newExerciseType != exerciseTypeId ? setFilter({newExerciseType:exerciseTypeId}): null}
    const handleEditQuestion          = (questionId:number)            => setFilter({questionIdSelected:questionId})
    const handleCancelNewQuestion     = ()                             => {
        setFilter({templateIdSelected: "", advancedEditor: ""})
        setMagicQuestion(undefined)
    }
    const handleCancelEditQuestion = () => setFilter({questionIdSelected:"", advancedEditor:""})

    const handleChangeNewExerciseTitle = (exerciseTitle:string) => setNewExerciseTitle(exerciseTitle)

    const handleChangeEditOpen = (indexOpen: number) => setEditOpen(indexOpen)

    const handleChangeLearningObjective = (learningObjectiveId:number) => setNewLearningObjective(learningObjectiveId)

    const handleToggleAdvantedEditor = () => {
        if (!advancedEditor) {
            setFilter({advancedEditor:true})
        }else{
            setFilter({advancedEditor: ""})
        }
    }

    const handleChangeMagicUrl = (url:string) => setMagicUrl(url)

    var clearExercise:any = [];
    var clearQuestion:any = [];

    const SortableExercise = SortableElement(({ value, sortIndex, removable }) => {
        if (sortIndex + 1 !== value.Order && !isLearningTrack) {
            value.Order = sortIndex + 1;
            clearExercise.push(value);
            setModalDragExercise(true)
            handleCancelQuestionEditor()
        }

        setDragExercises(listExercises)

        return (
                <DragableDiv>
                    <ExerciseCardHeader
                        isLearningTrack           = {isLearningTrack}
                        Order                     = {value.Order}
                        ExerciseType              = {listExerciseType && listExerciseType.find(({Id}) => Id == value.ExerciseTypeId)}
                        ExerciseTitle             = {value.ExerciseTitle}
                        onEditQuestion            = {() => handleOpenQuestionEditor(value.Id)}
                        onRemove                  = {() => handleOpenModalExerciseRemove(value)}
                        Removable                 = {removable && removable.isRemovable}
                        onChangeNewExerciseType   = {handleChangeNewExerciseType}
                        onChangeNewExerciseTitle  = {handleChangeNewExerciseTitle}
                        onChangeLearningObjective = {handleChangeLearningObjective}
                        newExerciseType           = {newExerciseType}
                        newExerciseTitle          = {newExerciseTitle}
                        listExerciseType          = {listExerciseType}
                        LearningObjectiveId       = {value.LearningObjectiveId}
                        newLearningObjective      = {newLearningObjective}
                        listLearningObjective     = {listLearningObjective}
                        onUpdate                  = {()=>handleUpdateExercise(value)}
                        index                     = {sortIndex}
                        onChangeEditOpen          = {handleChangeEditOpen}
                        editOpen                  = {editOpen}
                        isCreating                = {isCreating}
                    />
                </DragableDiv>
        )
    })

    const SortableListExercise = SortableContainer(({ children }) => {
        return (
                <div>
                    {children}
                </div>
        )
    })

    const onSortEndExercise = ({ oldIndex, newIndex }) => {
        setListExercises(arrayMove(listExercises, oldIndex, newIndex));
    };

    const onSortEndQuestion = ({ oldIndex, newIndex }) => {
        setListQuestions(arrayMove(listQuestions, oldIndex, newIndex));
    };

    const SortableQuestion = SortableElement(({ value, sortIndex }) => {
        if (sortIndex + 1 !== value.QuestionOrder) {
            value.QuestionOrder = sortIndex + 1;
            clearQuestion.push(value);
            setModalDragQuestion(true)
            handleCancelNewExercise()
        }

        setDragQuestions(listQuestions)

        if (question && value.Id == question.Id) {
            return <DragableDiv>
                    <QuestionEditCard
                                key                    = {value.key}
                                onToggleAdvantedEditor = {handleToggleAdvantedEditor}
                                listTemplates          = {listTemplates}
                                question               = {question}
                                onUpdate               = {handleUpdateQuestion}
                                onCancel               = {handleCancelEditQuestion}/>
                    </DragableDiv>
        }else {
            return <DragableDiv>
                <QuestionCard
                            key                    = {value.key}
                            question               = {value}
                            listTemplates          = {listTemplates}
                            onEdit                 = {handleEditQuestion}
                            onRemove               = {handleOpenModalQuestionRemove}/>
                    </DragableDiv>
        }
    })

    const SortableListQuestion = SortableContainer(({ children }) => {
        return (
                <div>
                    {children}
                </div>
        )
    })

    const isLearningTrack = (unitTypeSelected === "EXTRA_SUPPORT" || unitTypeSelected === "FINAL_TEST")

    return <Container className="my-4">
                {isLoading && <Loader/>}
                <Row>
                    <Col md={12}>
                        {/* <h1 className="font-weight-bold">YConnect CMS</h1> */}
                        <BreadcrumbCMSExercise/>
                        <PageTitle label="CMS Exercises" />
                    </Col>
                </Row>
                <Card className="px-4 pt-4 pb-0 mb-3">
                    <Filters
                        isLoading={isLoadingFilters}
                        filterProps={[
                            {
                                keystone       : "courseIdSelected",
                                label          : "Course",
                                value          : courseIdSelected,
                                onChange       : handleChangeFilter,
                                disabled       : !listCourses || listCourses.length == 0,
                                hasOptionEmpty : !!courseIdSelected && courseIdSelected !== "",
                                values         : listCourses ? listCourses.map(({Id, Title}) => ({id: Id, value: Title})): []
                            },
                            {
                                keystone       : "unitTypeSelected",
                                label          : "Unit Type",
                                value          : unitTypeSelected,
                                onChange       : handleChangeFilter,
                                disabled       : !listUnitTypes || listUnitTypes.length == 0,
                                hasOptionEmpty : !!unitTypeSelected && unitTypeSelected !== "",
                                values         : listUnitTypes ? listUnitTypes.map(({Key, Value}) => ({id: Value, value: Key})): []
                            },
                            {
                                keystone       : "unitIdSelected",
                                label          : "Unit",
                                value          : unitIdSelected,
                                onChange       : handleChangeFilter,
                                disabled       : !listUnits || listUnits.length == 0,
                                hasOptionEmpty : !!unitIdSelected && unitIdSelected !== "",
                                values         : listUnits ? listUnits.map(({Id, Name, Order}) => ({id: Id, value: `${Name}`})): []
                            },
                            {
                                keystone       : "sectionIdSelected",
                                label          : "Section",
                                value          : sectionIdSelected,
                                onChange       : handleChangeFilter,
                                disabled       : !listSections || listSections.length == 0,
                                hasOptionEmpty : !!sectionIdSelected && sectionIdSelected !== "",
                                values         : listSections ? listSections.map(({Id, Name}) => ({id: Id, value: Name})): []
                            }
                        ]}/>
                </Card>
                {
                    sectionIdSelected
                    && !exerciseIdSelected
                    && (!newExerciseOrder || newExerciseOrder === "")
                    && <Row className="mb-3 justify-content-end">
                        <Col md="auto">
                            <ButtonStyle
                                    green
                                    label     = "New Exercise"
                                    size      = "small"
                                    className = "mr-2"
                                    onClick   = {handleNewExercise}
                                    icon      = "plusWhite"/>
                        </Col>
                    </Row>
                }
                {
                     sectionIdSelected
                     && !exerciseIdSelected
                     && listExercises
                     && listExercises.length > 0 &&
                    <Card className="px-4 pt-4 pb-0 mb-3">
                        <SortableListExercise onSortEnd={onSortEndExercise} distance={2}>
                            {listExercises.map((exercise, key: number) => {
                                const removable=removableExercises.find((each:any)=>each.Id==exercise.Id)

                                return (
                                    <div className="p-3 mb-3 card">
                                        <SortableExercise key={`item-${key}`} index={key} sortIndex={key} value={exercise} removable={removable} />
                                    </div>
                                )
                            })}
                        </SortableListExercise>
                    </Card>
                }

                {
                     sectionIdSelected
                     && !exerciseIdSelected
                     && newExerciseOrder
                     && newExerciseOrder !== ""
                     && <CardActiveStyled className="p-3 mb-3">
                            <ExerciseCardHeader
                                Order                    = {nextExerciseOrder}
                                isLearningTrack          = {isLearningTrack}
                                isNew
                                ExerciseType = {
                                                    (
                                                        isLearningTrack
                                                        && listExerciseType
                                                        && listExerciseType.length > 0
                                                    )
                                                    ? listExerciseType[0]
                                                    : undefined
                                                }
                                newExerciseType = {
                                                        !isLearningTrack
                                                        ? newExerciseType
                                                        : undefined
                                                    }
                                newExerciseTitle          = {newExerciseTitle}
                                newLearningObjective      = {newLearningObjective}
                                listLearningObjective     = {listLearningObjective}
                                listExerciseType          = {listExerciseType}
                                onCancel                  = {handleCancelNewExercise}
                                onChangeNewExerciseType   = {handleChangeNewExerciseType}
                                onChangeNewExerciseTitle  = {handleChangeNewExerciseTitle}
                                onChangeLearningObjective = {handleChangeLearningObjective}/>
                            <Row className="justify-content-end">
                                <Col md="auto">
                                    <ButtonStyle
                                        green
                                        disabled  = {!newExerciseType}
                                        size      = "130"
                                        onClick   = {handleCreateExercise}
                                        icon      = "plusWhite"
                                        label     = "Create"/>
                                </Col>
                            </Row>
                        </CardActiveStyled>
                }
                {
                    exerciseIdSelected
                    && listExercises
                    && <ExerciseEditorCard
                            onCancel           = {handleCancelQuestionEditor}
                            exerciseIdSelected = {exerciseIdSelected}
                            listExercises      = {listExercises}
                            listExerciseType   = {listExerciseType}/>
                }
                <SortableListQuestion onSortEnd={onSortEndQuestion} distance={2}>
                {
                    listQuestions
                    && listTemplates
                    && listQuestions.length > 0
                    && listQuestions.map((_question:QuestionType, key:number) => {

                        return <SortableQuestion key={`item-${key}`} index={key} sortIndex={key} value={_question} />
                    })
                }
                </SortableListQuestion>


                {
                    !templateIdSelected
                    && !questionIdSelected
                    && exerciseIdSelected
                    && listExercises
                    && (
                        !listQuestions || (isLearningTrack && listQuestions.length < 1) || !isLearningTrack
                    )
                    && <QuestionChooseTypeCard
                            listTemplates      = {listTemplates}
                            templateIdSelected = {templateIdSelected}
                            onSelectTemplate   = {handleSelectTemplate}/>
                }
                {
                    templateIdSelected
                    && exerciseIdSelected
                    && listExercises
                    && <QuestionNewCard
                            nextQuestionOrder      = {nextQuestionOrder}
                            magicUrl               = {magicUrl}
                            magicQuestion          = {magicQuestion}
                            listTemplates          = {listTemplates}
                            templateIdSelected     = {templateIdSelected}
                            onToggleAdvantedEditor = {handleToggleAdvantedEditor}
                            onSave                 = {(newQuestion:QuestionType) => handleCreateQuestion(newQuestion)}
                            onCancel               = {handleCancelNewQuestion}
                            onChangeMagicUrl       = {handleChangeMagicUrl}/>
                }

                <QuestionRemoveModal
                    show     = {isShowQuestionRemoveModal}
                    question = {questionForRemove}
                    onCancel = {handleCloseModalQuestionRemove}
                    onRemove = {handleRemoveQuestion}/>
                <ExerciseRemoveModal
                    show     = {isShowExerciseRemoveModal}
                    exercise = {exerciseForRemove}
                    onCancel = {handleCloseModalExerciseRemove}
                    onRemove = {handleRemoveExercise}/>
                <ExerciseDragModal
                    show         = {modalDragExercise}
                    onHide       = {() => setModalDragExercise(false)}
                    List         = {dragExercises}
                    refresh      = {refreshExercise}
                    onCancel     = {handleCancelNewExercise}
                />
                <QuestionDragModal
                    show         = {modalDragQuestion}
                    onHide       = {() => setModalDragQuestion(false)}
                    List         = {dragQuestions}
                    refresh      = {updateQuestions}
                />
            </Container>
}


const mapDispatchToProps = (dispatch:any) => bindActionCreators({
    AddQueryParam    : QueryParamsActionsCreator.AddQueryParam,
    RemoveQueryParam : QueryParamsActionsCreator.RemoveQueryParam,
    SetQueryParams   : QueryParamsActionsCreator.SetQueryParams
}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(CMSContainer)

import * as React                          from "react"
import { useState, useEffect, useContext } from "react"
import { Container, Row, Col }             from "reactstrap"
import styled                              from "styled-components"

import { BreadcrumbPrePost }             from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle                         from "YConnect/Components/PageTitle"
import Loader                            from "YConnect/Components/Loader"
import ServerErrorPage                   from "YConnect/Pages/ServerError.page"
import { ButtonStyle, YLinkButtonStyle } from "YConnect/Components/Button"

import API                                from "YConnect/API"
import GetNewHtmlReplaceImg               from "YConnect/Utils/GetNewHtmlReplaceImg.util"
import getConfig                          from "YConnect/Utils/GetRequestConfig.util"
import DescryptoValue                     from "YConnect/Utils/DescryptoValue";

import * as pencil from "YConnect/Assets/svg/pencil.svg"
import * as test   from "YConnect/Assets/svg/test.svg"
import * as clock  from "YConnect/Assets/svg/clock.svg"

import PrePostTestExit from "YConnect/Modals/PrePostTestExit.modal"

import { BackLinkStyle }      from "YConnect/Styles/BackLink.style"
import HeaderExerciseStyle    from "YConnect/Styles/HeaderExercise.style"
import FooterStyle            from "YConnect/Styles/Footer.style"
import FormStyle              from "YConnect/Styles/Form.style"
import ExerciseTimeline2Style from "YConnect/Styles/ExerciseTimeline2.style"
import ResultBoxStyle         from "YConnect/Styles/ResultBox.style"

import UserContext    from "YConnect/User.context"
import PrePostContext from "YConnect/PrePost.context"

import find   from "lodash/find"
import moment from "moment"


const TimerDivStyle = styled.div`
    background-color: ${props => props.theme.main.backgroundWhite};
    border-radius: 8px;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.11);
    width: 198px;
    height: 65px;
    text-align: center;
    line-height: 4em;
    margin-bottom: 20px;

    span {
        color: ${props => props.theme.main.successColor};
        font-weight: bold;
    }
`

const ResultTitleStyle = styled.span`
    color: ${props => props.theme.main.successColor};
    font-size: inherit;
`


const BackButtonStyle = styled(ButtonStyle)`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    border: none;
    padding: 0;
    width: auto;

    img {
        margin-right: 10px;
        padding-left: 0 !important;
        filter: ${props => props.theme.main.filterSvg};
    }
`

const PrePostTestPage = (props?: any) => {

    const userContext:any        = useContext(UserContext)
    const prePostUserContext:any = useContext(PrePostContext)

    const [isLoading, setLoading]               = useState(true)
    const [error, setError]                     = useState(false)
    const [showResult, setShowResult]           = useState(false)
    const [result, setResult]                   = useState()
    const [submitDisable, setSubmitDisable]     = useState(true)
    const [formValues, setFormValues]           = useState()
    const [contentExercise, setContentExercise] = useState()
    const [time, setTime]                       = useState(moment.duration(0))
    const [courseInfo, setCourseInfo]           = useState({})
    const [showExitModal, setExitModal]         = useState(false)

    const {
        params : { idTeste, idCurso }
    } = props.match

    useEffect(() => {
        setLoading(true)
        fetchCourseContent()
        setSubmitDisable(true)
        setContentExercise(null)
    }, [idCurso])

    useEffect(() => {
        if (formValues && formValues.valuesUpdated.exercise) {
            const answers = formValues.valuesUpdated.exercise

            if (answers.resposta !== -1) {
                setSubmitDisable(false)
            }
        }
    }, [formValues])

    useEffect(() => {
        let timer
        if (!showResult)
            timer = setInterval(() => setTime(time + 1000), 1000)
        return () => clearInterval(timer);
    }, [time, showResult])


    useEffect(() => {
        setLoading(true)
        setSubmitDisable(true)
        setContentExercise(null)
        fetchTestQuestion()
    }, [idTeste])

    const fetchCourseContent = async () => {
        try {
            const {data} = await API.Course.GetAll({
                id: idCurso,
                isDegustacao: false
            }, getConfig())

            const course = data.find(({id_curso}:any) => id_curso == idCurso)

            localStorage.setItem("courseInfo", JSON.stringify(course))
            setCourseInfo(course)
        } catch (error) {
            setError(true)
        }
    }

    const DecryptPrePostTest = (content:any) => {

        content.questao.enunciado = DescryptoValue.descryptoString( content.questao.enunciado );
        content.questao.ppT_Alternativa_Yconnect = content.questao.ppT_Alternativa_Yconnect &&  content.questao.ppT_Alternativa_Yconnect.map((elem : object) => 
           DescryptoValue.descryptoObject(elem)
        );
        content.questao.subenunciado = DescryptoValue.descryptoString( content.questao.subenunciado );

       return content;
   }

    const fetchTestQuestion = async () => {
        try {
            const { data } = await API.PrePostTest.Get({
                idQuestao: idTeste,
                idCurso: idCurso,
                idAluno: userContext.idUsuario,
                isAluno: isStudent()
            }, getConfig())

            if (data.acessoIndevido) {
                setError(true)
            }
            data.questao = DecryptPrePostTest(data.questao);
            setContentExercise(data.questao)
            if (isStudent()) {
                fetchTestQuestionTime(data.questao)
                if (data.questao.respostaUsuario !== -1) {
                    setSubmitDisable(false)
                }
            } else {
                setLoading(false)
            }
        } catch (error) {
            setError(true)
        }
    }

    const fetchTestQuestionTime = async (exercise: any) => {
        try {
            const { data } = await API.PrePostTest.GetTime({
                idTesteAluno: exercise.idTesteAluno,
            }, getConfig())

            setTime(parseInt(data.tempoDecorridoTeste))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }

    const fetchSaveQuestion = async (type: string, idAlternativa: any, idQuestao: any) => {
        try {
            const response = await API.PrePostTest.SaveQuestion({
                idAlternativa: idAlternativa,
                idQuestao: idQuestao,
                idTesteAluno: contentExercise.idTesteAluno,
                tempoSessao: time,
                id: 1,
                tipo: type
            }, getConfig())

            if (type === 'next') {
                redirectPage(idCurso, contentExercise.idProximaQuestao)
            }

            if (type === 'check') {
                fetchGetResult(contentExercise.idTesteAluno)
            }
        } catch (error) {
            setError(true)
        }
    }


    const fetchGetResult = async (idTesteAluno: any) => {
        try {
            const response = await API.PrePostReport.GetStudentReport({
                idCurso: idCurso,
                idAluno: userContext.idUsuario,
                isAluno: true
            }, getConfig())

            const report = find(response.data.relatorio, { id_teste_aluno: Number(idTesteAluno) })

            userContext.cursoAtualBloqueadoPreTest = false
            localStorage.setItem('data', JSON.stringify(userContext))

            if(prePostUserContext
                && prePostUserContext.listPptTestAlunoYConnect
                && prePostUserContext.listPptTestAlunoYConnect[0]
                && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso){
                    prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso.fl_feito = true
            }

            props.onUpdatePrePost({...prePostUserContext})
            
            setResult(report)
            setShowResult(true)
            localStorage.removeItem("prepost")
        } catch (error) {}
    }

    const redirectPage = (cursoId: any, testId: any) => {
        props.history.push({
            pathname: `/pre_post/test/${cursoId}/${testId}`
        })
    }

    const getAlunoCurso = () =>{
        const { aluno_curso } = (prePostUserContext 
            && prePostUserContext.listPptTestAlunoYConnect
            && prePostUserContext.listPptTestAlunoYConnect[0]) || {}
        return aluno_curso;
    }

    const getPageTitle = () => {

        const { aluno_curso } = (prePostUserContext 
        && prePostUserContext.listPptTestAlunoYConnect 
        && prePostUserContext.listPptTestAlunoYConnect[0]) || {}

        if (isStudent()) {
            return `${courseInfo.titulo_curso}: ${aluno_curso && aluno_curso.fl_is_pre ? 'PRE TEST EVALUATION' : 'POST TEST EVALUATION'}`
        }

        return `${courseInfo.titulo_curso}: PRE/POST TEST EVALUATION`
    }

    const isStudent = () => {
        const { idProfessor, observador, colaborador, usuarioTrial, usuarioDegustacao } = userContext
        return (idProfessor == 0 && !observador && !colaborador && !usuarioTrial && !usuarioDegustacao)
    }

    const getTemplate = () => {
        let template = [
            {
                "id"            : "exercise",
                "type"        	: 'PrePostTest',
                "exercise"      : contentExercise
            }
        ]

        return template
    }

    const saveExercise = () => {
        setSubmitDisable(true)

        if (isStudent()) {
            const answer = formValues.valuesUpdated.exercise
            fetchSaveQuestion('previous', 0, contentExercise.idProximaQuestao)
            fetchSaveQuestion('next', answer.resposta, contentExercise.questao.id_questao)
        } else {
            redirectPage(idCurso, contentExercise.idProximaQuestao)
        }
    }

    const submit = () => {
        setSubmitDisable(true)

        if (isStudent()) {
            const answer = formValues.valuesUpdated.exercise
            fetchSaveQuestion('check', answer.resposta, contentExercise.questao.id_questao)
        } else {
            props.history.push({
                pathname: "/"
            })
        }
    }

    const renderTime = () => {
        const duration = moment.duration(time)
        return (
            <TimerDivStyle>
                Your time: <span>{moment.utc(duration.asMilliseconds()).format("HH:mm:ss")}</span>
            </TimerDivStyle>
        )
    }

    const renderBookLifeTime = () => (
        <div className="w-100 p-5 mt-3 d-none d-lg-block">
            <ExerciseTimeline2Style className="exercise-line d-flex flex-row justify-content-around mb-4">
                {[...Array(contentExercise.totalQuestoes)].map((x, i) => {
                    return (
                        <div
                            key={i}
                            className={`exercise-task ${i + 1 === contentExercise.ordemQuestao ? 'active' : ''} ${i + 1 < contentExercise.ordemQuestao || showResult ? 'checked' : ''}`}
                        >
                            <img src={i + 1 < contentExercise.ordemQuestao ? '' : showResult ? '' : pencil} alt=""/>
                        </div>
                    )
                })}

            </ExerciseTimeline2Style>
        </div>
    )

    const renderResult = () => {
        const media_aluno = (result.media_aluno * 100).toFixed(1)
        const tempo_aluno = (result.tempo_aluno / 60 ).toFixed(0)

        return (
            <>
                <div className="w-100 pt-5 pt-lg-0 text-center">
                    <h1>
                        
                    {media_aluno < 70 ? 'Keep Trying!' : 'Nice work!'} Your score is: <br className="d-md-none" /> <ResultTitleStyle>{result.qts_acertos} correct answers</ResultTitleStyle>
                    </h1>
                </div>
                <ResultBoxStyle className="mt-4 mb-4 pr-2 pl-2 pr-md-0 pl-md-0" xs={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} xl={{ size: 8, offset: 2 }}>
                    <Row className="result mt-1">
                        <Col className="score d-flex flex-row mt-2 mt-md-0" xs="12" sm="6" md="3" lg="3" xl="3">
                            <div className="img">
                                <img src={clock} />
                            </div>
                            <div className="text">
                                <p>Duration:</p>
                                {tempo_aluno} minutes
                            </div>
                        </Col>
                        <Col className="score d-flex flex-row mt-3 mt-md-0" xs="12" sm="6" md="3" lg="3" xl="3">
                            <div className="img">
                                <img src={test} />
                            </div>
                            <div className="text">
                                <p>Correct answers:</p>
                                {result.qts_acertos} of {result.qtde_total_questoes}
                            </div>
                        </Col>
                        <Col className="overall text-center mt-1 mt-md-0" xs="12" sm="12" md="6" lg="6" xl="6">
                            <h3>
                                Overall percentage: <span style={{color: media_aluno > 70 ? '#00A281' : '#FF1A4A'}}>{media_aluno} %</span>
                            </h3>
                        </Col>
                    </Row>
                    <Row className="graphic">
                        <Col className="mt-4 mt-md-0 align-self-center" xs="12" sm="12" md="6" lg="6" xl="6">
                            <div className={`line-graphic ${result.media_aluno >= 70 ? 'success' : 'fail'}`}>
                                <span
                                    className="progress-percentage"
                                    style={{ marginLeft: `calc(${result.media_aluno}* 90%)` }}
                                >
                                    {media_aluno} %
                                </span>
                                <div className="line" />
                                <div
                                    className="line-progress"
                                    style={{ width: `calc(${result.media_aluno}* 90%)`}}
                                />
                                <div
                                    className="pointer-progress"
                                    style={{ marginLeft: `calc(${result.media_aluno}* 90%)`}}
                                />
                                <div className="d-flex justify-content-between">
                                    <span className="line-start">0</span>
                                    <span className="line-start" style={{ marginLeft: `calc(0.70 * 90%)`}}>70%</span>
                                    <span className="line-start">100%</span>
                                </div>
                            </div>
                        </Col>
                        <Col className="mt-5 mb-2 mb-md-0 mt-md-0 texto" xs="12" sm="12" md="6" lg="6" xl="6">
                            {result.texto.replace('Y?zigi', 'Yázigi')}
                        </Col>
                    </Row>
                </ResultBoxStyle>
            </>
        )
    }

    if (error) return <ServerErrorPage location={location} />

    return (
        <Container>
            {
                isLoading
                ? <Loader />
                : <>
                    <BreadcrumbPrePost courseInfo={courseInfo} isStudent={isStudent()} aluno_curso={getAlunoCurso()}/>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="8" xl="8">
                            <PageTitle label={getPageTitle()}></PageTitle>
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="4" xl="4" className="d-flex flex-row justify-content-end mt-lg-1 mb-2">
                            {renderTime()}
                        </Col>
                    </Row>

                    <Row className="m-0 mb-5">
                        <div className="exercise card shadow d-flex flex-row flex-wrap justify-content-between col-12 p-0">
                            {renderBookLifeTime()}
                            {
                                showResult ?
                                renderResult()
                                :
                                <>
                                    <HeaderExerciseStyle className="exercise-header d-flex flex-column justify-content-center w-100 pb-3 pl-3 pr-3">
                                        <h1 className="text-center h3 mb-3 font-weight-bolder mt-5 mt-lg-0">
                                            Question {contentExercise.ordemQuestao} / {contentExercise.totalQuestoes}
                                        </h1>
                                        <h3
                                            className="text-center mt-2"
                                            dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(contentExercise.questao.enunciado)}}
                                        ></h3>
                                    </HeaderExerciseStyle>
                                    <FormStyle
                                        className  = "exercise-body w-100"
                                        onChange   = {setFormValues}
                                        row        = {false}
                                        template   = {getTemplate()} />
                                </>
                            }
                            { showResult ?
                                <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                    <BackLinkStyle
                                        size="medium"
                                        link="/"
                                        icon="arrowPrevious"
                                        label={ `${window.innerWidth > 1024 ? 'Go back (Homepage)' : 'Back'}`} />
                                    <YLinkButtonStyle
                                        green
                                        size="medium"
                                        label={`Go to course ${courseInfo.titulo_curso}`}
                                        icon="arrowRight"
                                        link={`/courses/${idCurso}/view-book`}
                                    />
                                </FooterStyle>
                                :
                                <FooterStyle className="exercise-footer d-flex flex-row justify-content-between p-2 p-lg-4 w-100">
                                    {
                                        contentExercise.idQuestaoAnterior != -1 ?
                                        <BackLinkStyle
                                            size="medium"
                                            label={`${window.innerWidth > 1024 ? `Back Question ${contentExercise.ordemQuestao - 1}/${contentExercise.totalQuestoes}` : 'Back'}`}
                                            link={`/pre_post/test/${idCurso}/${contentExercise.idQuestaoAnterior}`}
                                            icon="arrowPrevious"
                                        />
                                        :
                                        <BackButtonStyle
                                            size="medium"
                                            label={ `${window.innerWidth > 1024 ? 'Go back (Homepage)' : 'Back'}`}
                                            onClick={() => setExitModal(true)}
                                            icon="arrowPrevious"
                                        />
                                    }

                                    <span className="d-flex flex-row">
                                        {contentExercise.ordemQuestao < contentExercise.totalQuestoes ?
                                            <ButtonStyle
                                                green
                                                size="large"
                                                disabled={submitDisable}
                                                label={`Answer Question  ${contentExercise.ordemQuestao} / ${contentExercise.totalQuestoes}`}
                                                onClick={saveExercise}
                                                icon="checked"
                                            />
                                            :
                                            <ButtonStyle
                                                green
                                                size     = "small"
                                                disabled = {submitDisable}
                                                label    =  "Finish Test"
                                                onClick  = {submit}
                                                icon     = "checked"/>
                                        }
                                    </span>
                                </FooterStyle>
                            }
                        </div>
                    </Row>
                </>
            }
            <PrePostTestExit isShow={showExitModal} onClose={() => { setExitModal(false) }} />
        </Container>
    )
}
export default PrePostTestPage

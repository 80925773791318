import * as React from "react"
import { useEffect, useState, useContext, useRef} from "react"
import { Card, Row, Col } from "reactstrap"

import styled from "styled-components"

import API from "YConnect/API"
import getConfig from "YConnect/Utils/GetRequestConfig.util"
import { ButtonStyle } from "YConnect/Components/Button"
import UserContext from "YConnect/User.context"
import ButtonsBookStyle from "YConnect/PagesComponents/ElosIntegration/ButtonsBook.style"
import ContainerStyle from "YConnect/PagesComponents/BooksBlink/Container.styled"
import { BreadcrumbOnlineTearch } from "YConnect/Components/BreadCrumbList"
import PageTitle   from "YConnect/Components/PageTitle"
import SelectFieldStyle from "YConnect/Styles/SelectField.style"

const MY_STYLE_1 = {
    overflow: "hidden",
    height:"1000px"
}

const SelectFieldNewStyle = styled(SelectFieldStyle)`
    @media (min-width: 768px) {
        width: 30%;
    }
`

const ListBooksByCourse = (idcurso:any) =>
    API.BlinkIntegration.GetListBooks({idcurso}, getConfig())

const GetBooks = ({idcurso, IsProfessor, idaluno, estab, name_book}:any) =>
    API.BlinkIntegration.GetBooks({idcurso, IsProfessor, idaluno, estab, name_book }, getConfig())


const ButtonsBook = ({list, onSelect, isTeacher, disabled}:{list:Array<any>, onSelect:any, isTeacher:boolean, disabled: boolean}) =>
    <>
        {
            list
            && list.map(({name_book}, key) =>
                <ButtonStyle
                    disabled={disabled}
                    key={key}
                    onClick={() => onSelect({isTeacher, name_book})}>{name_book}</ButtonStyle>
            )
        }
    </>

const ElosIntegrationPage = ({match}: any) => {

    const {  params: { id }} = match

    const {
        idUsuario,
        estabelecimentos,
        idProfessor,
        observador,
        colaborador,
        usuarioTrial,
        usuarioDegustacao
    } :any = useContext(UserContext)

    const [bookSelected, setBookSelected] = useState<any>()
    const [listBooks, setListBooks] = useState<Array<any>>()
    const [listBodyParam, setListBodyParam] = useState<any>()
    const formRef = useRef<any>()

    const [grupos, setGrupos] = useState()
    const [establishments, setEstablishments] = useState()
    const [courses, setCourses] = useState()
    const [classes, setClasses] = useState()

    const [establishmentParam, setEstablishmentParam] = useState()
    const [courseParam, setCourseParam] = useState()
    const [classParam, setClassParam] = useState()
    const [currentParam, setCurrentParam] = useState("true")

    const [lastSearches, setLastSearches] = useState(JSON.parse(localStorage.getItem("lastSearches")))

    const listStudentsBooks = listBooks && listBooks.filter(({is_teacher_book}) =>  is_teacher_book === false)

    const [URLTest, setURLTest] = useState<string>()

    const isStudent = () =>
        idProfessor == 0
        && !observador
        && !colaborador
        && !usuarioTrial
        && !usuarioDegustacao

        useEffect(() => {
            if (!lastSearches) {
                const newLastSearches: any = {
                    [idUsuario]: []
                }
                setLastSearches(newLastSearches)
                localStorage.setItem("lastSearches", JSON.stringify(newLastSearches))
            }
            API.Course.GetAll({ isDegustacao: false }, getConfig())
                .then((response: any) => {
                    setCourses(response.data.map(({ id_curso, titulo_curso }: any) => ({ id: id_curso, value: titulo_curso })))
                })
        }, [])

        useEffect(() => {
            if (lastSearches && lastSearches[idUsuario]) {
                const onlyResourceBookList = lastSearches[idUsuario].filter((search: any) => !search.isResourceBook);
                lastSearches[idUsuario] = onlyResourceBookList;
                localStorage.setItem("lastSearches", JSON.stringify(lastSearches))
            }
        }, [lastSearches])

        useEffect(() => {
            if (estabelecimentos) {
                setEstablishments(estabelecimentos.map(({ nsu_estab, nm_estab }: any) => ({ id: nsu_estab, value: nm_estab })))
            }
        }, [estabelecimentos])

        useEffect(() => {
            if (establishmentParam) {
                setCourseParam("")
            }
        }, [establishmentParam])

        useEffect(() => {
            if (courseParam && courseParam !== "") {
                setClasses([])
                setClassParam("")
                const paramForFetch = {
                    idEstab: establishmentParam,
                    idCurso: courseParam,
                    fl_vigentes: true
                }

                API.Grupo.Get(paramForFetch, getConfig())
                    .then((response: any) => setGrupos(response.data))
            }
        }, [courseParam, currentParam])

        useEffect(() => {
            if (grupos && grupos.turmas.length > 0) {
                setClasses(grupos.turmas.map(({ id_grupo, nome_grupo, idCurso }: any) => ({ id: [id_grupo, idCurso], value: nome_grupo })))
            } else {
                setClasses([])
            }
        }, [grupos])

    useEffect(() => {
        updateListBooks()
        formUpdate()
    }, [])

    useEffect(() => {
        if(bookSelected)
            formUpdate()
    }, [bookSelected])

    useEffect(() => {
        if(listBodyParam)
            submit()
    }, [listBodyParam])

    const updateListBooks = async () =>
        setListBooks((await ListBooksByCourse(10)).data)

    const submit = () => {
        formRef.current.submit()
    }

    const formUpdate = async () => {
        const {parametersBody, url, urlParamters} = bookSelected
        setListBodyParam(parametersBody)
        setURLTest(url + urlParamters)
    }

    const selectBook = async ({isTeacher, name_book}:{isTeacher:boolean, name_book:string}) => {
        const classParamSplit = classParam.split(',')

        const {data:{response}} = await GetBooks({
            idcurso:10,
            IsProfessor:isTeacher,
            idaluno:idUsuario,
            estab:classParamSplit[0],
            name_book
        })
        setBookSelected(response)
    }

    const handleChangeFilter = (keystone: string, value: any) => {
        const sets: any = {
            establishment: setEstablishmentParam,
            course: setCourseParam,
            class: setClassParam,
            current: setCurrentParam
        }
        if (sets[keystone]) sets[keystone](value)
    }

    return <ContainerStyle>
                <BreadcrumbOnlineTearch/>
                <PageTitle label="Virtual Classroom Elos" />
                <Row className="mb-5">
                    <Col md={12}>
                        <Card className="users-table shadow">
                            <header className="users-table-header mb-2">
                                <SelectFieldNewStyle
                                    keystone="establishment"
                                    label="Establishment *"
                                    value={establishmentParam}
                                    onChange={handleChangeFilter}
                                    values={establishments} />
                                <SelectFieldNewStyle
                                    keystone="course"
                                    label="Course *"
                                    value={courseParam}
                                    onChange={handleChangeFilter}
                                    values={courses} />
                                <SelectFieldNewStyle
                                    keystone="class"
                                    label="Class *"
                                    disabled={!classes || classes.length === 0}
                                    value={classParam}
                                    onChange={handleChangeFilter}
                                    values={classes} />

                                    <Card className="shadow">
                                        <ButtonsBookStyle>
                                            <ButtonsBook
                                                disabled={classParam === '' || classParam === undefined}
                                                list={isStudent() ? listStudentsBooks : listBooks}
                                                isTeacher={!isStudent()}
                                                onSelect={(values:any)=> selectBook(values)}
                                            />

                                        </ButtonsBookStyle>

                                            {
                                                URLTest
                                                && <>
                                                        <form
                                                            ref={formRef}
                                                            className="form"
                                                            action={URLTest}
                                                            target="blinkFrame"
                                                            onSubmit={e => {
                                                                e.preventDefault()
                                                            }}
                                                            method="post">
                                                                {
                                                                    listBodyParam
                                                                    .map(({key, value}:any, i:number) => {
                                                                    return (<input key={i} type="hidden" name={key} defaultValue={value}/>)})
                                                                }
                                                            </form>
                                                        <iframe
                                                            name="blinkFrame"
                                                            id="blinkFrame"
                                                            style={MY_STYLE_1}/>
                                                    </>
                                            }
                                    </Card>
                            </header>
                        </Card>
                    </Col>
                </Row>

            </ContainerStyle>
}

export default ElosIntegrationPage

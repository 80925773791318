import * as React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from "react"
import DropdownButton from 'react-bootstrap/DropdownButton';
import AttemptsIconStyle   from "YConnect/Styles/AttemptsIcon.style"
import ButtonToolbarStyle  from "YConnect/Styles/ButtonToolbar.style"
import ToggleHelpStyle   from "YConnect/Styles/ToggleHelpStyle.style"
import DropdownUserProfileStyle from "YConnect/Styles/DropdownUserProfile.style"
import styled  from "styled-components"

const DropdownHelpStyle = styled(Dropdown)`
    align-items: center;
    display: flex;
    height: 100%;

    
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle, .btn-primary.focus, .btn-primary:focus, .show > .btn-primary.dropdown-toggle {
        background-color: #e9e9e92b !important;
        border-color: #e9e9e92b !important;
        border: none !important;
    }

    &:hover {
        background-color: #e9e9e92b !important;
        border-color: #e9e9e92b !important;
        border: none !important;
    }
    &:focus {
        background-color: #e9e9e92b !important;
        border-color: #e9e9e92b !important;
        border: none !important;
    }

`

const HelpInfoDropdown = ({width}: any) => {
    const [showDropdown, setShowDropdown] = useState(false);
   
    return (
        width > 991 ? <>
            <ButtonToolbarStyle>
                <DropdownHelpStyle onMouseOver={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                    <ToggleHelpStyle>
                        <AttemptsIconStyle id="TooltipHelpInfo"  className="align-items-center pointer">  ? </AttemptsIconStyle>
                    </ToggleHelpStyle>
                    <Dropdown.Menu show={showDropdown}>
                        <Dropdown.Item target="_blank" href="https://yazigi.pearson.com.br/base-de-conhecimento-yaz?hs_preview=WrnvSaOU-71405689948">Help</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#/technical-suppport">Technical Support</Dropdown.Item>
                    </Dropdown.Menu>
                </DropdownHelpStyle>
            </ButtonToolbarStyle>
        </> : <>
            <ButtonToolbarStyle>
                    <a href="https://yazigi.pearson.com.br/base-de-conhecimento-yaz?hs_preview=WrnvSaOU-71405689948" target="_blank">
                        <ToggleHelpStyle>
                            <AttemptsIconStyle id="TooltipHelpInfo"  className="align-items-center pointer" >  ? </AttemptsIconStyle>
                        </ToggleHelpStyle>
                    </a>
            </ButtonToolbarStyle>
        </>
      
    )
}

export default HelpInfoDropdown

import styled  from "styled-components"
import { Col } from "reactstrap"

const ColAccessibilityStyle = styled(Col)`
    border-left: 1px solid ${props => props.theme.main.lightGray};
    z-index: 10;
    @media screen and (min-width: 768px) {
        border-left: none;
    }

    img {
        width: 20px;
        @media screen and (min-width: 768px) {
            width: 25px;
        }
    }
`

export default ColAccessibilityStyle
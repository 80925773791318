import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import { ButtonStyle } from "YConnect/Components/Button"
import ImageFilesModal from "YConnect/PagesComponents/CMS/Modals/ImageFiles.modal"
import FormContainer from "YConnect/Containers/Form.container"

import ItemsEditableTable from "YConnect/PagesComponents/CMS/Editors/Unscramble.editor/Items.editableTable"
import IMAGE_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Image.template.json"
import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"

type UnscrambleTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const UnscrambleTemplateEditor = ({
    question,
    onChangeValues 
}: UnscrambleTemplateEditorProps
)=> {
    
    const [values, setValues] = useState<QuestionType>(question)
    const [isShowImageFilesModal, setShowImageFilesModal] = useState(false)
    const [source, setSource] = useState()

    const addValues = (newValues:QuestionType) => {
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }

    const handleChangeValues   = (values:QuestionType) => addValues(values)

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])

    const addMainExerciseMedia = ({Source, MediaType}:any) => {
        const newValues = {
            ...values,
            Content: `<Col md="auto" class="p-5">
                        <img class="img-fluid" src=\"${getStaticUrlAssests(Source)}\" />
                    </Col>`,
        }
        setSource(Source)
        setValues(newValues)
        onChangeValues(newValues)
    }

    const handleOpenModalImageFiles = () => setShowImageFilesModal(true)
    const handlAddImage = ({Source, MediaType}:any) => {
        addMainExerciseMedia({Source, MediaType})
        setShowImageFilesModal(false)
    }
    const handleCloseModalImageFiles = () => setShowImageFilesModal(false)

    const handleRemoveExerciseMedia = () => {
        const newValues = {
            ...values,
            Content: null,
        }
        setValues(newValues)
    }

    const RowGreyStyled = styled(Row)`
        background-color: #f0f5f6;
    `
 
    return  <>
                { values && values.Content &&
                <RowGreyStyled className="justify-content-center">
                    <Col md="auto" className="p-5">
                        <h2 dangerouslySetInnerHTML={{ __html: values.Content}}></h2>
                    </Col>
                </RowGreyStyled> }
                {   values
                    && values.Content ?
                    <Row className="py-3 justify-content-center">
                            <Col md="auto">
                                <ButtonStyle
                                    className = "mb-2"
                                    size      = "130"
                                    onClick   = {handleOpenModalImageFiles}
                                    icon      = "reset"
                                    label     = "Change"/>
                            </Col>
                            <Col md="auto">
                                <ButtonStyle
                                    size      = "130"
                                    onClick   = {handleRemoveExerciseMedia}
                                    icon      = "remove"
                                    label     = "Remove"/>
                            </Col>
                        </Row>
                        :
                        <Row className="py-3">
                                <Col md="auto">
                                    <ButtonStyle
                                        green
                                        disabled  = {values && values.ExerciseMedias && values.ExerciseMedias[0] && values.ExerciseMedias[0].MediaType === "audio"}
                                        size      = "190"
                                        onClick   = {handleOpenModalImageFiles}
                                        icon      = "imageWhite"
                                        label     = "Add Image"/>
                                </Col>
                            </Row>
                    }
                <Row>
                    <Col md={12}>
                        <FormContainer
                            values   = {values}
                            onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                            template = {[...IMAGE_TEMPLATE.map((field) => ({...field}))]}/>
                    </Col>
                    <Col md={12} className="mt-3">
                        <h3 className="font-weight-bold">
                            Item {values && values.Dialogues[0] && values.Dialogues[0].DialogueMessages.length > 0 && `(${values.Dialogues[0].DialogueMessages.length})`}
                        </h3>
                        <ItemsEditableTable values={values} onChange={(values:QuestionType) => handleChangeValues(values)}/>
                    </Col>
                </Row>
                {
                    isShowImageFilesModal
                    && <ImageFilesModal onSelect={handlAddImage} 
                    onClose={handleCloseModalImageFiles}/>
                }
            </>
}

export default UnscrambleTemplateEditor
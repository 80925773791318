import * as React from "react"

interface TableProps {
    data: Array<any>
    config: Array<ColumnConfigType>
    className?: string
    selectData?:any
}

const getValue = ({ keystone, formatter, selectDataName}: ColumnConfigType, row: any, selectData:any, key:number) => {
    if(selectData && keystone && selectData[selectDataName] && selectData[selectDataName].find(({id}:any) => id === row[keystone])){
        return selectData[selectDataName].find(({id}:any) => id === row[keystone]).value
    }else if (formatter && keystone) {
        return formatter(row[keystone], key)
    } else if (keystone) {
        return row[keystone]
    } else if (formatter) {
        return formatter(row, key)
    }
}

const Table = ({ data, config, className, selectData}: TableProps) => {
    const isSingleData = data && data.length === 1;
    return (
        <div className={`table-responsive ${className}`}>
            <table className="table" style={{minHeight: '250px'}}>
                <thead>
                    <tr>
                        {
                            config.map(({ keystone, label }: ColumnConfigType, key: any) => <th key={key}>{label || keystone}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {

                        data && data.map((row: any, key: any) =>
                        <tr key={key} className="table-line">
                            {
                                config.map((configColumn, key2: any) =>
                                    <td className={configColumn.className} key={key2} style={{verticalAlign: isSingleData ? 'top' : 'middle'}}>
                                        {getValue(configColumn, row, selectData, key)}
                                    </td>)
                            }
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Table

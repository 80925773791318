import styled  from "styled-components"
import { Col } from "reactstrap"

const DivNavbarStyle = styled(Col)`
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
`

export default DivNavbarStyle
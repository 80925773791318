import * as React   from "react"
import FieldWrapper from "YConnect/Wrappers/Field.wrapper"

interface TemplatePropsType {
    label?     : string
    style?     : object
    className? : string
    template   : Array<any>,
    row?       : boolean
}

const Template = ({ template, className, style, label, row }:TemplatePropsType) =>
    <form autoComplete="off" style = {style} className = {`mt-4 ${row ? 'row': ''} ${className || ""}`}>
        { label ? <div className="col-12"><h4>{label}</h4></div> : undefined}
        {template.filter(({type}:any) => type && type.toUpperCase() !== "HIDDEN").map((props, key:any) => <FieldWrapper key={key} {...props}/>)}
    </form>

Template.defaultProps = {
    row: true
}

export default Template


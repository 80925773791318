import styled from "styled-components"

const MobileMenuStyle = styled.div`
    &.lateral-menu-mobile-enter {
        left: 0;
        transform: translate3d(-100vw, 0, 0);
        width: 80%;
        @media screen and (min-width: 768px) {
            width: 50%;
        }
    }

    &.lateral-menu-mobile-enter-active,
    &.lateral-menu-mobile-enter-done {
        left: 0;
        transform: translate3d(0vw, 0, 0);
        transition: transform .8s cubic-bezier(0, .52, 0, 1);
        width: 85%;

        @media screen and (min-width: 768px) {
            width: 50%;
        }
    }

    &.lateral-menu-mobile-exit {
        left: 0;
        transform: translate3d(-100vw, 0, 0);
        @media screen and (min-width: 768px) {
            width: 50%;
        }
    }

    &.lateral-menu-mobile-exit-active,
    &.lateral-menu-mobile-exit-done {
        transform: translate3d(-100vw, 0, 0);
        transition: transform .8s cubic-bezier(0, .52, 0, 1);
    }

    .lateral-header-menu {
        border-bottom: 1px solid #e2e2e2;
        font-size: 15px;
        padding: 16px;
    }

    .profile-link {
        color: ${props => props.theme.main.clickable};
        font-weight: bold;
        font-size: 15px;
    }

    .menu-separator {
        border-bottom: 1px solid #e2e2e2;
        padding: 16px;
    }

    .lateral-body-menu {
        font-size: 15px;
        height: auto;
        overflow-y: scroll;
        .body-link {
            border-bottom: 1px solid #c9c9c9;
            padding: 16px;

            .body-link--span {
                color: ${props => props.theme.main.clickable};
                display: flex;
                font-weight: bold;
                flex-flow: column wrap;
                justify-content: center;
                padding: 16px 0;
                width: 100%;
            }
        }

        .lateral-social-media {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            padding: 30px;
            width: 100%;

            > a {
                padding: 0 12px;
            }
        }
    }

    .lateral-footer-menu {
        background-color: ${props => props.theme.main.backgroundWhite};
        justify-content: flex-end;
        bottom: 0;
        border-top: 1px solid #f7f8f8;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        padding: 16px 16px 32px;
    }
`

export default MobileMenuStyle
import * as React             from "react"
import {useEffect}            from "react"
import {Card}                 from "reactstrap"
import { connect }            from "react-redux"
import { bindActionCreators } from "redux"

import PanelCrud              from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"
import Filters                   from "YConnect/Components/Filters2"
import useSelectData             from "YConnect/Hooks/useSelectData"
import useFilterState            from "YConnect/Hooks/useFilterState"
import useChangeQueryParamByFilter from "YConnect/PagesComponents/CMS/Hooks/useChangeQueryParamByFilter"
import useQueryParamLoader from "YConnect/PagesComponents/CMS/Hooks/useQueryParamLoader"
import useUpdateFilterHierarchy from "YConnect/PagesComponents/CMS/Hooks/useUpdateFilterHierarchy"
import QueryParamsActionsCreator from "YConnect/Actions/QueryParams.actionsCreator"

import * as CourseResource from "YConnect/ResourcesBackEnd/Course.resources"
import * as UnitResource   from "YConnect/ResourcesBackEnd/Unit.resources"

import {
    GetAllByUnitId, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/Section.resources"

import CONFIG_TABLE_SECTION from "YConnect/PagesComponents/ControlPanel/Tables/Section.table.config"
import SECTION_TEMPLATE from "YConnect/PagesComponents/ControlPanel/Templates/Section.template"

const SectionsPanel = ({
    QueryParams,
    AddQueryParam,
    RemoveQueryParam
}:any) => {

    const  {
        selectData,
        AddSelectData,
    } = useSelectData()

    const [
        {
            courseIdSelected,
            unitTypeSelected,
            unitIdSelected
        },
        setIdSelected
    ] = useFilterState()
    
    useEffect(() => {
        updateCourseSelectData()
        updateUnitTypeSelectData()
    }, [])

    // QueryParams hooks
    useQueryParamLoader(courseIdSelected, "courseIdSelected", "course", [], QueryParams, setIdSelected)
    useQueryParamLoader(unitTypeSelected, "unitTypeSelected", "unitType", ["course"], QueryParams, setIdSelected)
    useQueryParamLoader(unitIdSelected, "unitIdSelected", "unit", ["course", "unitType"], QueryParams, setIdSelected)

    // Data fetchers
    const updateCourseSelectData = () =>
        CourseResource
        .GetAll()
        .then((response:any) => {
            const {data} = response
            AddSelectData("CourseId", "Id", "Title", data)
        })

    const updateUnitTypeSelectData = () =>
        UnitResource
        .GetUnitTypes()
        .then((response:any) => {
            const {data} = response
            AddSelectData("UnitType", "Value", "Key", data)
        })

    const updateUnitSelectData = () =>
        UnitResource
        .GetAllByCourseId(courseIdSelected, unitTypeSelected)
        .then((response:any) => {
            const {data} = response
            AddSelectData("UnitId", "Id", "Name", data)
        })

    useEffect(() => {
        if (unitTypeSelected) updateUnitSelectData()
    }, [unitTypeSelected])

    // Filter hooks
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "course", courseIdSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "unitType", unitTypeSelected)
    useChangeQueryParamByFilter(AddQueryParam, RemoveQueryParam, "unit", unitIdSelected)

    useUpdateFilterHierarchy(courseIdSelected, ["unitType", "unit"], RemoveQueryParam, ["unitTypeSelected", "unitIdSelected"], setIdSelected);
    useUpdateFilterHierarchy(unitTypeSelected, ["unit"], RemoveQueryParam, ["unitIdSelected"], setIdSelected);

    const handleChangeFilter = (keystone:string, value:any) => setIdSelected({[keystone]:value})

    return <>
            <Card className="px-4 pt-4 pb-0 mb-3">
                <Filters
                    filterProps={[
                        {
                            keystone : "courseIdSelected",
                            label    : "Course",
                            value    : courseIdSelected,
                            onChange : handleChangeFilter,
                            values   : selectData ? selectData["CourseId"] : []
                        },
                        {
                            keystone : "unitTypeSelected",
                            label    : "Unit Type",
                            value    : unitTypeSelected,
                            onChange : handleChangeFilter,
                            disabled : !courseIdSelected,
                            values   : selectData ? selectData["UnitType"] : []
                        },
                        {
                            keystone : "unitIdSelected",
                            label    : "Unit",
                            value    : unitIdSelected,
                            onChange : handleChangeFilter,
                            disabled : !courseIdSelected || !unitTypeSelected,
                            values   : selectData ? selectData["UnitId"] : []
                        }
                    ]}/>
            </Card>
            {
            courseIdSelected 
            && unitTypeSelected
            && unitIdSelected
            && <PanelCrud
                        idName            = "sectionId"
                        labelButtonCreate = "Create Section"
                        labelForm         = "Section"
                        selectData        = {selectData} 
                        template          = {SECTION_TEMPLATE}
                        configTable       = {CONFIG_TABLE_SECTION}
                        GetAll            = {GetAllByUnitId}
                        paramGetAll       = {unitIdSelected}
                        paramType         = {unitTypeSelected}
                        GetById           = {GetById}
                        Create            = {Create}
                        paramCreate       = {{unitId:unitIdSelected, UnitId:unitIdSelected}}
                        UpdateById        = {UpdateById}
                        DeleteById        = {DeleteById} />
            }
            </>
}

const mapDispatchToProps = (dispatch:any) => bindActionCreators({
    AddQueryParam : QueryParamsActionsCreator.AddQueryParam,
    RemoveQueryParam : QueryParamsActionsCreator.RemoveQueryParam
}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(SectionsPanel)
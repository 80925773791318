import styled from "styled-components"

const ExerciseText = styled.div`
    margin: 1em auto;
    @media screen and (min-width: 1024px) {
    }

    .open-question__title {
        text-align: center;
    }

    #text-quantity {
        width: 80%;
        margin: 1em auto;
    }
`

export default ExerciseText

import * as React                          from "react"
import { useContext, useState, useEffect } from "react"
import { withRouter }                      from "react-router"

import ReplyMessage  from "YConnect/PagesComponents/Message/ReplyMessage"
import UserContext   from "YConnect/User.context"
import { BreadcrumbReadMessage } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle     from "YConnect/Components/PageTitle"
import Loader        from "YConnect/Components/Loader"
import {ButtonStyle} from "YConnect/Components/Button"
import API           from "YConnect/API"
import formatDate    from "YConnect/Utils/FormatDate.util"
import convertPhoto  from "YConnect/Utils/ConvertPhoto.util"
import getConfig     from "YConnect/Utils/GetRequestConfig.util"

import * as user from "YConnect/Assets/svg/user.svg"

const ReadMessage = (props: any) => {

    const messageInterface = {
        anotacao: "",
        assunto: "",
        caminho: "",
        data: "",
        foto: "",
        id_destino: 1,
        id_notes: 1,
        id_remetente: 1,
        isChecked: false,
        is_yconnect: true,
        nome_destinatario: "",
        nome_grupo: "",
        nome_remetente: "",
        qntd_itens: 0,
        removida: "",
        replied: false,
        status_lido: "",
        strActivity: '',
        titulo_curso: "",
    };

    const {idUsuario}:any  = useContext(UserContext)

    const [message, setMessage]       = useState(messageInterface)
    const [recipient, setRecipient]   = useState()//TODO
    const [replyUsers, setReplyUsers] = useState([])
    const [showReply, setShowReply]   = useState(false)
    const [isLoading, setLoading]     = useState(false)
    const [senderList, setSenderList] = useState()//TODO

    useEffect(() => {
        // _loadUsers();
        
        if (props.location.state && props.location.state.message) {
            const message = props.location.state.message; 
            setReplyUsers([{ 'value': message.id_remetente, 'label': message.nome_remetente }])
            
            // TODO Verificar como fica quando é uma lista de usuários.
            // let replyUserList = [{}];
            // if(typeof message.id_remetente === 'number') {
            // } else {
            //     message.to.forEach((user: string) => {
            //         replyUserList.push({ 'value': user, 'label': user });
            //         setReplyUsers(replyUserList);
            //     });
            // }

            setMessage(message)
            setRecipient(message.nome_destinatario)
            setMessageAsRead(message.id_notes);
        } else {
            getMessageById();
        }
    }, []);

    const getMessageById = () => {
        const messageId = props.match.params.messageId;
        API.Note.NoteById({
            id_notes: messageId,
            id_aluno: idUsuario,
        }, getConfig())
            .then((res: any) => {
                const message = res.data; 
                setReplyUsers([{ 'value': message.id_remetente, 'label': message.nome_remetente }])
                setMessage(message)
                setRecipient(message.nome_destinatario)
                setMessageAsRead(messageId);
            })
            .catch((err: any) => { })
            .finally(() => setLoading(false))
    }

    const setMessageAsRead = (messageId: number) => {
        API.Note.markAsRead({
            id: messageId,
        }, getConfig())
            .then((res: any) => {})
            .catch((err: any) => { })
            .finally(() => setLoading(false))
    }

    const deleteSelectedMessages = () => {
        setLoading(true);
    }


    return (
        <main className="container">

        {isLoading && <Loader />}
        <BreadcrumbReadMessage message={message}/>
        <PageTitle label={`Message: ${message.assunto ? message.assunto : ''}`} className={"col-xs-12 col-md-6 message-page-title"}>
            <div className="message-page-actions col-md-6">
                <ButtonStyle label="Reply" icon="reply" onClick={() => setShowReply(true)} size={'small'} maxWidth={true}/>
            </div>
        </PageTitle>

        <section className="row mb-5 messages">

            <div className="col-12">

                <div className="card nopadding shadow YC-messageById">

                    <header className="message-header">
                        <figure>
                            <img src={convertPhoto(message.foto ? atob(message.foto) : user)} className="message-header-photo" />
                        </figure>
                        <div className="message-header-info">
                            <span className="userinfo">
                                {`${message.nome_remetente}`} 
                            </span> 
                            {
                                window.innerWidth > 1024 
                                ? <span className="users">to: {`${message.nome_destinatario}`}</span>
                                : null
                            }
                        </div>
                        <span className="header-datetime">{formatDate(message.data)}</span>
                        
                        {
                            window.innerWidth < 1024
                                ? <span className="users">to: {`${message.nome_destinatario}`}</span>
                                : null
                        }
                    </header>
                    
                    <div className="message-body" dangerouslySetInnerHTML={{__html: message.anotacao}}>
                    </div>

                    {
                        showReply ?
                        <ReplyMessage 
                            senderList  = {senderList} 
                            usersReply  = {replyUsers} 
                            setLoading  = {setLoading} 
                            message     = {message} />
                        :
                        null
                    }


                </div>

            </div>

        </section>


    </main>


    )
}

export default withRouter(ReadMessage);
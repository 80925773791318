import styled from "styled-components"

const SoundBarStyle = styled.div`
    width: 100%;
    height: 3px;
    left: 0;
    background: #D5B3FF;
    border-radius: 3px;
    cursor: pointer;
`

export default SoundBarStyle
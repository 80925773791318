import styled       from "styled-components"
import { Dropdown } from "react-bootstrap"

const ToggleUserProfileStyle = styled(Dropdown.Toggle)`
    margin-top: -65px !important;
    align-items: center;
    background: transparent;
    border: none;
    border-radius: 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 10px 5px 21px 7px;
    margin-left: 85px;

    @media screen and (max-width: 1024px) {
        padding: 5px 0px 21px 0px;
        margin-left: 70px;
    }

    @media screen and (max-width: 991px) {
        padding: 10px 0px 21px 0px;
        margin-left: 45px;
    }

    @media screen and (max-width: 767px) {
        padding: 5px 0px 21px 0px;
        margin-top: 0px !important;
        padding: 0px
    }

    &::after {
        display: none;
    }

    &:hover {
        background: #e9e9e92b;
    }
`

export default ToggleUserProfileStyle
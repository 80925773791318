
import styled from "styled-components"

const ReportSummaryStyle = styled.div`
    border-left: 1px solid #E1E6E7;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;

    @media screen and (min-width: 1024px) {
        min-height: 293px;
        padding: 25px 20px;
        width: 45%;
    }

    .report {
        height: 40px;
        display: flex; 
        flex-flow: row wrap;
        margin-bottom: 20px;
        margin-left: 20px;
        width: 100%;
        @media screen and (min-width: 768px) {
            width: 45%;
        }
        @media screen and (min-width: 1024px) {
            height: auto;
            margin-left: 0;
            width: 100%;
        }
    }
    img {
        margin-right: 15px;
        width: 30px;
    }

    .report-info {
        display: flex;
        flex-flow: column wrap;
        .title {
            color: #989898;
            font-size: 12px;
            font-weight: 400;
        }

        .value {
            color: #565656;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
`

export default ReportSummaryStyle
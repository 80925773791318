import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

import FormContainer from "YConnect/Containers/Form.container"
import DEFAULT_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Default.template.json"

import AnswersEditor from "YConnect/PagesComponents/CMS/Editors/DragAndDrop.editor/Answers.editor"
import MessagesEditor from "YConnect/PagesComponents/CMS/Editors/Components/Messages.editor"

type DragAndDropTemplateEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const DragAndDropTemplateEditor = ({
    question,
    onChangeValues
}:DragAndDropTemplateEditorProps) => {

    const handleChangeValues = (newValues:any) => {
        onChangeValues({...question, ...newValues})
    }

    return  <Row>
                <Col md={12}>
                    <FormContainer
                        values={question}
                        onChange={({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                        template={[...DEFAULT_TEMPLATE.map((field) => ({...field}))]}/>
                </Col>
                <AnswersEditor 
                    question={question}
                    onChange={handleChangeValues}/>
                <MessagesEditor
                    question={question}
                    onChange={handleChangeValues}/>
            </Row>
}

export default DragAndDropTemplateEditor

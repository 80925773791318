
import * as React                          from "react"
import { useContext, useState, useEffect } from "react"
import { withRouter }                      from "react-router"
import { FormGroup }                       from "react-bootstrap"
import Select                              from "react-select"
import styled                              from "styled-components"

import UserContext     from "YConnect/User.context"
import { ButtonStyle } from "YConnect/Components/Button"
import API             from "YConnect/API"
import getConfig       from "YConnect/Utils/GetRequestConfig.util"
import Toastify from "toastify-js"

const ReplyMessage = ({senderList, usersReply, setLoading,  message, history}: any) => {

    const CharacterLimit = styled.div`
        display: flex;
        justify-content: flex-end;
        border: none;
        margin-top: -10px;
    `

    const {idUsuario}:any = useContext(UserContext)

    const [replyUsers, setReplyUsers]     = useState([])
    const [messageReply, setMessageReply] = useState("")
    const [numberChar, setNumberChar]     = useState(0);

    useEffect(() => {
        setReplyUsers(usersReply)
    }, [usersReply])


    const _sendMessage = () => {
        setLoading(true);
        if(messageReply.length > 3000){
            Toastify({
                text: "Wait, the message over 3000 characteres!",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        } else {
            const formBody = {
                anotacao: messageReply,
                assunto: `RE: ${message.assunto}`,
                caminho: "PA",
                data: new Date().toISOString(),
                id_destino: message.id_remetente,
                id_grupo: '',
                id_remetente: message.id_destino || idUsuario
            }
            API.Note.sendMessage({...formBody}, getConfig())
            .then((res: any) => {
                Toastify({
                    text: "Success <br> Message sent with success!",
                    duration: 3000,
                    className: "toastify-success",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();

                history.push({pathname: `/my-message`});
            })
            .catch((err: any) => { })
            .finally(() => setLoading(false))
        }
    }

    const _verifyCharacteres = (e : any) => {
        setMessageReply(e.target.value)
        var qtdcaracteres = e.target.value.length;
        var restantes = 3000 - qtdcaracteres;

        if (restantes < 1) {
            e.value = e.target.value.slice(0, 3000);
            document.getElementById('carResTxtVoce').innerHTML = "0";
        }
        document.getElementById('carResTxtVoce').innerHTML = qtdcaracteres.toString();
        const typedChars = document.getElementById('carResTxtVoce').innerHTML;
        setNumberChar(Number(typedChars));
    }

    return (
        <form>
            <div className="message-reply">

                <FormGroup className="reply-to d-flex flex-row flex-nowrap option-message" >
                    <label className="form-label label-select" htmlFor="replyUsername">Reply to</label>
                    <Select
                        id              =  "replyUsername"
                        name            =  "userName"
                        options         =  {senderList}
                        value           =  {replyUsers}
                        onChange        =  {(e: any) => setReplyUsers(e)}
                        isMulti         =  {true}
                        isSearchable    =  {true}
                        placeholder     =  "destination"
                        classNamePrefix =  "message"
                        className       =  "recipient-message"
                    />

                </FormGroup>

                <FormGroup>
                    <label className="form-label">Message</label>
                    <textarea maxlength="3000"
                        className   =   "form-control"
                        value       =   {messageReply}
                        style       =   {{ height: "200px" }}
                        onChange={(e: any) => _verifyCharacteres(e)}
                        placeholder =   "Type your message"
                        rows        =   {5}
                    ></textarea>
                </FormGroup>
                <CharacterLimit><span id="carResTxtVoce">{numberChar}</span><span>/3000</span></CharacterLimit>
            </div>

            <div className="btn-fixed d-flex message-reply--btn justify-content-end">
                <ButtonStyle green
                    className   = {`${!messageReply ? "disabled" : ""}`}
                    label       = "Send message"
                    icon        = "messageWhite"
                    disabled    = {!messageReply}
                    onClick     = {_sendMessage}
                    size        = {'medium'}
                />
            </div>
        </form>
    )
}

export default withRouter(ReplyMessage);

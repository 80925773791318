import * as React from "react"
import { useEffect, useRef, useState } from "react"

import GoodLookingModal from "YConnect/Modals/GoodLooking.modal"
import Loader from "YConnect/Components/Loader"
import OpenQuestionModalCards from "YConnect/Modals/OpenQuestion.modal/OpenQuestionCards.modal"
import OpenQuestionModalActions from "YConnect/Modals/OpenQuestion.modal/OpenQuestionModalActions.modal"
import OpenQuestionModalReview from "YConnect/Modals/OpenQuestion.modal/OpenQuestionModalReview.modal"

import useOutsideClick from "YConnect/Hooks/useOutsideClick"

const OpenQuestionModal = ({attempts, visible, isStudent, latestReviewTypeAndAttempt, reviews, onApprovalSubmit, onRejectionSubmit, onClose}:any) => {
  const [newReviewDisplay, setNewReviewDisplay] = useState(false)
  const [reviewFn, setReviewFn] = useState(() => () => {})
  const [newReview, setNewReview] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, onClose)
  
  const toggleNewReviewDisplay = (reviewMode:Function) => {
    setReviewFn(() => reviewMode)
    setNewReviewDisplay(!newReviewDisplay)
  }

  const onApprove = () => toggleNewReviewDisplay(onApprovalSubmit)
  const onReject = () => toggleNewReviewDisplay(onRejectionSubmit)

  const reviewSubmit = () => {
    setIsLoading(true)
    reviewFn(newReview).then(() => {
      setNewReview("")
      setNewReviewDisplay(false)
    })
    .finally(() => setIsLoading(false))
  }

  if (isLoading) return <Loader />

  return (
    <GoodLookingModal className="open-question__modal" onClose={onClose} visible={visible} title="Review History">
      <OpenQuestionModalCards
        reviews={reviews}
      />
      <OpenQuestionModalActions
        attempts={attempts}
        isStudent={isStudent}
        newReviewDisplay={newReviewDisplay}
        latestReviewTypeAndAttempt={latestReviewTypeAndAttempt}
        onApprove={onApprove}
        onReject={onReject}
      />
      <OpenQuestionModalReview
        visible={newReviewDisplay}
        value={newReview}
        onChange={setNewReview}
        onSubmit={reviewSubmit}
        onCancel={toggleNewReviewDisplay}
      />
    </GoodLookingModal>
  )
}

export default OpenQuestionModal
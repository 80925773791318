import styled from "styled-components"

const ResultStyle = styled.div`
    color: ${props => props.theme.main.textColor};
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    margin: 20px 0;
    text-decoration: none;
    width: 100%;
    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }
    @media screen and (min-width: 1024px) {
        margin: 20px 15px;
        width: calc(50% - 30px);
    }
`
export default ResultStyle
import * as React from "react"
import { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"

import ExerciseRowStyle from "YConnect/Fields/Exercises/DragDropTxtImg.field/ExerciseRow.style"
import Div2Style from "YConnect/Fields/Exercises/ClickAudio.field/Div2.style"
import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"

type ClickAudioWithImageViewProps = {
    question: QuestionType
}

const ClickAudioWithImageView = ({ question }: ClickAudioWithImageViewProps) => {

    const [values, setValues] = useState<QuestionType>()
    const [imagesByOrder, setImagesByOrder] = useState()

    useEffect(() => {
        if (question) {
            setValues(question)
            const { Answers, ExerciseMedias } = question
            if (Answers && ExerciseMedias) {
                const newimagesByOrder = Answers
                    .reduce((acc: any, answer) => {
                        acc[answer.Order] = {
                            answer,
                        }
                        return acc
                    }, {})

                setImagesByOrder(newimagesByOrder)
            }
        }
    }, [question])

    const handleActiveImage = (order: number) => {

        if (question) {
            const { Answers, ExerciseMedias } = question
            if (Answers && ExerciseMedias) {
                const newimagesByOrder = Answers
                    .reduce((acc: any, answer) => {
                        acc[answer.Order] = {
                            answer,
                            active: answer.Order === order ? true : false
                        }
                        return acc
                    }, {})

                setImagesByOrder(newimagesByOrder)
            }
        }
    }

    return <Col md={12} className="p-5">
        <ExerciseRowStyle className="pt-3">
            {imagesByOrder
                && Object
                    .keys(imagesByOrder)
                    .map((order: any, index) =>
                        <div className="col-md" key={index}>
                            {imagesByOrder &&
                                <Div2Style
                                    className={imagesByOrder[order].active ? 'item-active' : ''}
                                    style={{ width: '100%' }}
                                    onClick={() => handleActiveImage(parseInt(order, 10))}
                                >
                                    <img src={getStaticUrlAssests(imagesByOrder[order].answer.Awnser)} />
                                </Div2Style>
                            }
                        </div>
                    )
            }
        </ExerciseRowStyle>
    </Col>
}

export default ClickAudioWithImageView
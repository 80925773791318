import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

import FormContainer from "YConnect/Containers/Form.container"
import AnswersChooseMultipleTable from "YConnect/PagesComponents/CMS/Editors/ChooseMultiple.editor/AnswersChooseMultipleTable"
import DEFAULT_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Default.template.json"

type ChooseMultipleEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const ChooseMultipleTemplateEditor = ({
    question,
    onChangeValues
}:ChooseMultipleEditorProps)=>{

    const [values, setValues]   = useState(question)    
    const [Answers, setAnswers] = useState<Array<AnswerType>>(question.Answers)

    const handleChangeValues = (values:any) => addValues(values)
    const handleChangeAnswer = (values:Array<AnswerType>) => setAnswers(values)

    const addValues = (newValues:any) => {
        
        setValues({...values, ...newValues})
        onChangeValues({...values, ...newValues})
    }

    useEffect(() => {
        setValues({...values, Answers})
        onChangeValues({...values, Answers})
    }, [Answers])

    useEffect(() => {
        if(question){
           setValues(question)
        }
    }, [question])

    return  <Row>
                <Col md={12}>
                    <FormContainer 
                        values   = {values}
                        onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                        template = {[...DEFAULT_TEMPLATE.map((field) => ({...field}))]}/>
                </Col>
                <Col md={12} className="mt-3">
                    <h3 className="font-weight-bold">Answers{Answers.length > 0 && ` (${Answers.length})`}</h3>
                    <AnswersChooseMultipleTable
                        nextQuestionOrder = {question.nextQuestionOrder}
                        values            = {Answers}
                        onChange          = {(values:Array<AnswerType>) => handleChangeAnswer(values)}/>
                </Col>
            </Row>
}
          
export default ChooseMultipleTemplateEditor
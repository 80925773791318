import * as React                   from "react"
import { useState, useEffect, useContext } from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"
import {Container, Row, Col, Card}  from "reactstrap"
import styled                       from "styled-components"

import * as arrowNext     from "YConnect/Assets/svg/arrow-next.svg"
import * as arrowPrevious from "YConnect/Assets/svg/arrow-previous.svg"
import * as userIcon      from "YConnect/Assets/svg/user-gray.svg"
import * as edit          from "YConnect/Assets/svg/edit.svg"
import * as eyes          from "YConnect/Assets/svg/eyes.svg"
import * as reloadGrey    from "YConnect/Assets/svg/reloadGrey.svg"

import API                from "YConnect/API"
import { ButtonStyle }    from "YConnect/Components/Button"
import { BreadcrumbManagerUser } from "YConnect/Components/BreadCrumbList.tsx"
import Table              from "YConnect/Components/Table"
import PageTitle          from "YConnect/Components/PageTitle"
import Loader             from "YConnect/Components/Loader"

import CONFIG_TABLE_USER  from "YConnect/Configs/Tables/TableUser.config"

import InputField  from "YConnect/Fields/Forms/Input.field"
import SelectField from "YConnect/Fields/Forms/Select.field"

import UserContext from "YConnect/User.context"
import { maskForEmail } from "YConnect/Utils/MaskFormat.util"
import ConfirmStudentMode from "YConnect/Modals/ConfirmacaoStudentMode"
import DescryptoValue from "YConnect/Utils/DescryptoValue"

import Toastify from 'toastify-js' 

const HeaderPagination = styled.div`
    display: flex;
    flex-flow: column wrap;

    @media screen and (min-width: 1024px) {
        flex-flow: row wrap;
        justify-content: flex-end;
    }
    label {
        @media screen and (min-width: 1024px) {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
        }
    }
`

/////////////////////////////////////////////////////////////

const FILTER_BY = [
    { id: 9, value: "All" },
    { id: 1, value: "Student" },
    { id: 2, value: "Collaborator" }
]

const TOTAL_USERS_PER_PAGE = 20
const FILTER_BY_DEFAULT = "9"

/////////////////////////////////////////////////////////////


const getAmountPages = (amountUsers:number)=>{
    const AMOUNT_USERS_LAST_PAGE = amountUsers%TOTAL_USERS_PER_PAGE
    return (amountUsers-AMOUNT_USERS_LAST_PAGE)/TOTAL_USERS_PER_PAGE
    + (AMOUNT_USERS_LAST_PAGE > 0?1:0)
}


const ManageUsersPage = () => {

    const {idUsuario}:any = useContext(UserContext)
    const { profiles }:any = useContext(UserContext)

    const [users, setUsers]               = useState()
    const [usersCurrent, setUsersCurrent] = useState([])
    const [isLoading, setIsLoading]       = useState(false)

    const [nomeParam, setNomeParam]       = useState("")
    const [emailParam, setEmailParam]     = useState("")
    const [usuarioParam, setUsuarioParam] = useState("")
    const [perfilParam, setPerfilParam]   = useState(FILTER_BY_DEFAULT)

    const [amountPages, setAmountPages] = useState(0)
    const [currentPageNumber, setCurrentPageNumber] = useState(0)

    const [showModalConfirmStudentMode, setShowModalConfirmStudentMode] = useState(false);
    const [userStudentTemp, setUserStudentTemp]=  useState();


    const hasPrevious = currentPageNumber > 0
    const hasNext     = currentPageNumber+1 < amountPages
    const startUsers  = TOTAL_USERS_PER_PAGE*currentPageNumber
    const endUsers    = startUsers+TOTAL_USERS_PER_PAGE

    useEffect(() => {
        if(users){
            setUsersCurrent(users.slice(startUsers, endUsers))
            setAmountPages(getAmountPages(users.length))
            setIsLoading(false)
        }
    }, [users, currentPageNumber])

    const fetch = () => {
        setUsers(undefined)
        API.Usuario.Buscar({
        nome    : nomeParam,
        email   : emailParam,
        usuario : usuarioParam,
        perfil  : perfilParam,
        idUsuario : idUsuario
    }).then((response: any) => {

        const dataUsers = response.data.map((user:any)=> ({
            ...user,
            setStudentMode: () => {
                setUserStudentTemp(user.usuario);
                setShowModalConfirmStudentMode(true);
            }
        }))
        setUsers(dataUsers)
    })
    }


    const handleChangeFilter = (keystone:string, value:any) => {
        const sets: any = {
            profile  : setPerfilParam,
            name     : setNomeParam,
            email    : setEmailParam,
            username : setUsuarioParam
        }
        if(sets[keystone]) sets[keystone](value)
    }

    const handleChangePage = (value: any) => {
        setCurrentPageNumber(value-1)
    }

    const handleNextPage     = () => hasNext && setCurrentPageNumber(currentPageNumber+1)
    const handlePreviousPage = () => hasPrevious && setCurrentPageNumber(currentPageNumber-1)

    const handleSearch = () => {
        setIsLoading(true)
        setCurrentPageNumber(0)
        fetch()
    }

    const handleConfirmUserMode = async () => {
        
        setShowModalConfirmStudentMode(false)
        setIsLoading(true)
        const token = localStorage.getItem("Authorization");
        try {
            let responseAuth = await API.Auth.Get({id: userStudentTemp, mobile:false, tokenAdmin:token})
            responseAuth.data.apelido = DescryptoValue.descryptoString(responseAuth.data.apelido);
            responseAuth.data.nomeCompleto = DescryptoValue.descryptoString(responseAuth.data.nomeCompleto);
            responseAuth.data.email = DescryptoValue.descryptoString(responseAuth.data.email);
            const {data} = responseAuth;

            if(data && data.emailConfirmado && !data.isBlocked){
                const authToken       = responseAuth.headers["auth-token"]
                let responseUsuario = await API.Usuario.Get({id:data.idUsuario}, {headers:{"Authorization": authToken}})
                responseUsuario.data = await DescryptoValue.descryptoObject(responseUsuario.data, true);
                
                const newUserData     = {
                    ...data, 
                    nome:responseUsuario.data.nome_aluno,
                }
                localStorage.setItem("dataTemp", JSON.stringify(newUserData))
    
                if(newUserData.primeiroAcessoGameficacao && newUserData.primeiroAcessoGameficacao.dt_primeiro_acesso){
                    localStorage.setItem("afterLogin", "true")
                }
                
                 localStorage.setItem("isStudentMode", "true");
                 window.location.href = "#/"
                 window.location.reload()

            }else if(data && data.isBlocked){

                Toastify({
                    text: "Blocked user! <br> Unable to change to student mode",
                    duration: 3000,
                    className: "toastify-warning",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
                return false;
            }else if(data && data.emailConfirmado === false){
                Toastify({
                    text: "Email not confirmed! <br> Unable to change to student mode",
                    duration: 3000,
                    className: "toastify-warning",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
                return false
            }else{
                Toastify({
                    text: "Authentication error! <br> Unable to change to student mode",
                    duration: 3000,
                    className: "toastify-warning",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
                return false
            }
        } catch (error) {
            // console.log("error - unable to change to student mode",)
        } finally {
            setIsLoading(false)
        }
    }
    
    const handleCancelStudentMode = () => {
        setShowModalConfirmStudentMode(false);
    }

    return <Container>
        {isLoading && <Loader />}
        <BreadcrumbManagerUser/>
        <PageTitle label="Manage users" />
        <Row className="mb-5">
            <Col md={12}>
                <Card className="users-table shadow">
                    <header className="users-table-header mb-2">
                        <SelectField keystone="profile"  className="header-options" label="Profile"  value={perfilParam}  onChange={handleChangeFilter} values={FILTER_BY} hasOptionEmpty={true}/>
                        <InputField  keystone="name"     className="header-options" label="Name"     value={nomeParam}    onChange={handleChangeFilter}/>
                        <InputField  keystone="email"    className="header-options" label="Email"    value={emailParam}   onChange={handleChangeFilter}/>
                        <InputField  keystone="username" className="header-options" label="Username" value={usuarioParam} onChange={handleChangeFilter}/>
                        <ButtonStyle label="Search"  icon="search" onClick={handleSearch} className={'btn-table-header'} size={'small'} />
                    </header>
                    {
                        window.innerWidth > 768
                        ? <HeaderPagination className="mb-2">
                            {users &&
                                <div className="header-filter user-pagination">
                                    <img
                                        src={arrowPrevious}
                                        className="pr-4"
                                        style={{
                                            cursor: hasPrevious ? 'pointer' : 'not-allowed',
                                            filter: hasPrevious ? 'grayscale(0)' : 'grayscale(100%)'
                                        }}
                                        onClick={() => handlePreviousPage()}
                                    />
                                    <span>Showing <b>{startUsers+1}-{hasNext?endUsers:users.length}</b> of {users.length}</span>
                                    <img
                                        src={arrowNext}
                                        className="pl-4"
                                        style={{
                                            cursor: hasNext ? 'pointer' : 'not-allowed',
                                            filter: hasNext ? 'grayscale(0)' : 'grayscale(100%)'
                                        }}
                                        onClick={() => handleNextPage()}
                                    />
                                </div>}
                        </HeaderPagination>
                        : null
                    }
                    { !users && <p>Please, select a filter to show users</p> }
                    {
                        window.innerWidth < 768
                            ? usersCurrent.map((user: any) =>
                                <div className="manage-users mb-2">
                                    <div className="manage-user d-flex flex-row justify-content-between">
                                        <img src={userIcon} className="users-table-icon align-self-start w-20" />
                                        <div className="user-info ml-3 w-80">
                                            <span className="d-block font-weight-bold">{user.nome} {user.sobrenome}</span>
                                            <span className="d-block">{user.usuario}</span>
                                            <span className="d-block">{maskForEmail(user.email) || "Not registered"}</span>
                                            {/*TODO Repetido #6df54g6dfg */}
                                            <DropdownButton
                                                alignRight
                                                drop  = "down"
                                                key   = "down"
                                                title = "Actions"
                                                id    = {`${user.id}`}
                                                className="users-dropdown">
                                                <Dropdown.Item eventKey="4" href={`#/manage-users/${user.id}`}>
                                                    <img src={edit} alt="" className="pr-3" />
                                                    <span className="text-clickable">Edit User</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="4" href={`#/unlock-score/${user.id}`}>
                                                    <img src={eyes} alt="" className="pr-3" />
                                                    <span className="text-clickable">Unlock Score</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="4" href={`#/reset-student/${user.id}`}>
                                                    <img src={reloadGrey} alt="" className="pr-3" />
                                                    <span className="text-clickable">Reset Activities</span>
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </div>
                                    <hr />
                                </div>)
                            : <Table data={usersCurrent} config={CONFIG_TABLE_USER}/>
                    }
                    <HeaderPagination>
                        <label className=" mr-3">Go to page</label>
                        <SelectField
                            keystone       = "selectpage"
                            label          = "Select page"
                            value          = {currentPageNumber+1}
                            onChange       = {(keystone:string, value:any)=> handleChangePage(value)}
                            values         = {Array.from(Array(amountPages).keys()).map((key) => ({id:key+1, value:key+1}))}
                            hasOptionEmpty = {true}/>
                    </HeaderPagination>
                </Card>
            </Col>
        </Row>
        <ConfirmStudentMode
                    hasShow   = {showModalConfirmStudentMode}
                    onConfirm = {handleConfirmUserMode}
                    onClose   = {handleCancelStudentMode}/>
    </Container>
}

export default ManageUsersPage

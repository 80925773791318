import * as React              from "react"
import styled                  from "styled-components"
import { useState, useEffect } from "react"

import * as CourseResource  from "YConnect/ResourcesBackEnd/Course.resources"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"

// DONE TODO EDUARDO trocar ImagesBook para API.Book.GetCover({idCurso})

const CourseCardStyle = styled.div`
    border-radius: 12px;
    border-bottom: solid 3px ${props => props.theme.main.clickable};
    color: ${props => props.theme.main.textColor};
    flex: 1 1 auto;
    margin: 5px;
    margin-bottom: 24px;
    padding: 0;
    width: 70%;

    @media screen and (min-width: 768px) {
        width: 40%;
    }
    @media screen and (min-width: 1024px) {
        display: flex;
        flex: initial;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: 33%;
    }

    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }

    .recommended {
        border: 0;
        position: absolute;
        text-align: center;
        top: -2px;
        width: 100%;

        .recommended-label {
            border: 0;
            background-color: rgba(78, 45, 120, 0.9);
            border-radius: 0 0 8px 8px;
            color: ${props => props.theme.main.textWhite};
            padding: 0 16px 8px;
        }
    }

    .card-photo {
        background-size: cover;
        background-position: top;
        border-radius: 12px 12px 0 0;
        height: 290px;
        width: 100%;
    }

    .courses-stamps {
        border: 1px solid ${props => props.theme.main.textWhite};
        border-radius: 50px;
        bottom: 120px;
        color: ${props => props.theme.main.textWhite};
        display: block;
        font-size: 0.75rem;
        margin: 0 auto;
        padding: 2px 10px;
        position: relative;
        text-align: center;
        top: calc(100% - 48px);
        width: calc(100% - 60px);
    }

    .card-description {
        padding: 24px;
        width: 100%;

        .card-description-title {
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 16px 0;

            small {
                margin-left: 8px;
            }
        }

        .card-description-tasks {

            &.completed {
                color: ${props => props.theme.main.successColor};
                small {
                    font-weight: bold;
                }
            }

            .card-description-percent {
                color: ${props => props.theme.main.mainPurple};

                &.completed {
                    color: ${props => props.theme.main.successColor};
                }
            }

        }

    }
`

const YCCourseLink = styled.div`
    margin: 0 2px 0 0!important;
    width: 90%;
`;

interface TeacherBook {
    aluno_Bandeira_Yconnect: any[]
    aluno_curso: any[]
    descr_curso: string
    duracao_dias: number
    id_curso: number
    id_livro: number
    id_nivel: number
    img_carimbo: string
    notas_Etapa_Yconnect: any[]
    titulo_curso: string
    unidade_Yconnect: any[]
    valor_curso: number
}

interface teacherBooksProps {
    book: TeacherBook
    link?: string,
    history: any
}

const TeacherBookCard = (teacherBooksProps?: teacherBooksProps) => {

    const [pageLocation, setLocation] = useState();
    const { id_curso, titulo_curso } = teacherBooksProps.book
    const [bookCoverUrl, setBookCoverUrl] = useState("")
    useEffect(() => {
        setLocation(window.location.hash);
        fetchCapaDeLivro()
    }, [])

    const responseHandler = (response: any) => {
        const hasBookCover = response.data.capas[0] ? true : false
        if (hasBookCover) return response.data.capas[0].url_capa
        return ""
    }

    const fetchCapaDeLivro = () : void => {
        const idCourseExists = id_curso ? true : false
        if(!idCourseExists) return null
        CourseResource
        .GetCover(id_curso)
        .then((response:any) => {
            const capa_do_livro = responseHandler(response)
            const urlCoverBookCMS = getStaticUrl(capa_do_livro)
            setBookCoverUrl(urlCoverBookCMS)
        })
    }

    const redirectPage = (course: any) => {
        teacherBooksProps.history.push({
            pathname: `courses/${course.id_curso}/view-book`,
            state: {
                course
            }
        });
    }

    const getCardSize = () => {
        if (window.innerWidth > 1024) {
            return pageLocation === '#/' ? '33%' : '24%';
        }
        return '90%'
    }

    return (
        <YCCourseLink
            className="YC-CourseLink pointer pr-3"
            onClick={() => redirectPage(teacherBooksProps.book)}
            style={{width: getCardSize()}}>
            <CourseCardStyle className="YC-CourseCard card shadow p-0">
                <div
                    className={`card-photo yconnect-course`}
                    style={{ backgroundImage: `url(${bookCoverUrl}` }}></div>
                <div className="card-description">
                    <span className="card-description-title">
                        {titulo_curso}
                    </span>
                </div>
            </CourseCardStyle>
        </YCCourseLink>

    )
}

export default TeacherBookCard

import API              from "YConnect/API"
import getRequestConfig from "YConnect/Utils/GetRequestConfig.util"
import moment           from "moment";

export const GetSemestersCombo = ({idProfile, idProfessor}:any) =>
    new Promise(async (resolve, reject)=>{
        try{
            const response = await API.PrePostReport.GetTeacherReport({
                idProfile,
                idProfessor
            }, getRequestConfig())

            resolve(response.data.map(({id_ano_semestre, semestre, ano}:any) => ({id:id_ano_semestre, value: `${ano} - ${semestre} semester`})))
        }
        catch(e){
            reject(e)
        }
    })

export const GetCoursesCombo = () =>
    new Promise(async (resolve, reject)=>{
        try{
            const response = await API.Course.GetAll({isDegustacao: false}, getRequestConfig())
            resolve(response
                .data
                .filter(
                    ({titulo_curso}:any) =>
                        titulo_curso !== "YTEEN 1 (2nd edition)"
                        && titulo_curso !== "YTEEN 2 (2nd edition)"
                        && titulo_curso !== "YTEEN 3 (2nd edition)"
                        && titulo_curso !== "YTEEN 4 (2nd edition)"
                        && titulo_curso !== "STARTER"
                )
                .map(({id_curso, titulo_curso}:any) => ({id:id_curso, value:titulo_curso})))
        }
        catch(e){
            reject(e)
        }
    })

//TODO SchoolReportContainer
export const GetTeachersCombo = ({idAnoSemestre, idEstabelecimento, idCurso}:any) =>
    new Promise(async (resolve, reject)=>{
        try{
            const response = await  API.PrePostTest.GetAllTeachers({
                idAnoSemestre,
                idEstabelecimento,
                idCurso
            }, getRequestConfig())
            resolve(response.data.professores.map(({ idProfessor, nomeProfessor }: any) => ({ id: idProfessor, value: nomeProfessor })))
        }
        catch(e){
            reject(e)
        }
    })

//TODO SchoolReportContainer
export const GetClassesCombo = ({
    idEstabelecimento,
	idAnoSemestre,
	idCurso,
	idProfessor
}:any) =>
    new Promise(async (resolve, reject)=>{
        try{
            const response = await API.PrePostTest.GetClassSchoolView({
                idEstabelecimento,
                idAnoSemestre,
                idCurso,
                idProfessor
            }, getRequestConfig())

            resolve((response.data || []).map(({idGrupo, nomeGupo}:any)=>({id:idGrupo, value:nomeGupo})))
        }
        catch(e){
            reject(e)
        }
    })

export const GetClassesCombo2 = ({
    idAnoSemestre,
    idEstabelecimento,
    idCurso,
    idProfessor
}:any) =>
    new Promise(async (resolve, reject)=>{
        try{
            const response = await API.PrePostReport.getClassesReportByCourse({
                idAnoSemestreAt:idAnoSemestre,
                idEstabelecimentoAt:idEstabelecimento,
                idCursoAt:idCurso,
                idProfessorAt:idProfessor
            }, getRequestConfig())

           resolve(((response.data.grupos || response.data.turmas) || []).map(({id_grupo, nome_grupo}:any)=>({id:id_grupo, value:nome_grupo})))
        }
        catch(e){
            reject(e)
        }
    })

export const GetStudentsCombo = ({
    idGrupo
}:any) =>
    new Promise(async (resolve, reject)=>{
        try{
            const response = await API
            .PrePostReport
            .getStudentsReportByClass({idGrupo}, getRequestConfig())

            resolve(response.data.map(({id_aluno, nome_completo}:any)=>({id:id_aluno, value:nome_completo})))
        }
        catch(e){
            reject(e)
        }
    })

export const GetPeriodsCombo = ({
    id_curso_matricula,
    id_aluno_matricula
}:any) =>
    new Promise(async (resolve, reject)=>{
        try{
            const response = await API
            .Aluno
            .GetMatriculaAluno({id_curso_matricula, id_aluno_matricula}, getRequestConfig());

            resolve(response.data.map(({id_Matricula, data_inicial}:any)=>({
                id: id_Matricula, 
                value: `${id_Matricula == 0 ? "Current" : id_Matricula} - ${moment(data_inicial).format("DD/MM/YYYY")}`
            })));
        }
        catch(e){
            reject(e)
        }
    })


export const GetRegionalsCombo = () =>
new Promise(async (resolve, reject)=>{
    try{
        const response = await API.PrePostTest.GetRegionais({}, getRequestConfig())
        resolve(response.data.listaRegionals.map((regional:any) => ({id:regional, value: regional})))
    }
    catch(e){
        reject(e)
    }
})

export const GetEstablishmentsCombo = ({regional}:any) =>
new Promise(async (resolve, reject)=>{
    try{
        const response = await API.PrePostTest.GetAllEstabelecimentos({ regional }, getRequestConfig())
        resolve(response.data.map(({nsu_estab, nm_estab}:any)=>({id:nsu_estab,value:nm_estab})))
    }
    catch(e){
        reject(e)
    }
})

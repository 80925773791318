import * as React   from "react"
import styled       from "styled-components"
import ImagesBook   from "YConnect/Utils/ImagesBook.util"
import { useState, useEffect } from "react"

import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"
import * as CourseResource  from "YConnect/ResourcesBackEnd/Course.resources"

// DONE TODO EDUARDO

const CourseCardStyle = styled.div`

    border-radius: 12px;
    border-bottom: solid 3px ${props => props.theme.main.clickable};
    color: ${props => props.theme.main.textColor};
    flex: 1 1 auto;
    margin: 5px;
    margin-bottom: 24px;
    padding: 0;
    width: 70%;

    &.card-blocked {
        cursor: not-allowed;
        border-bottom: solid 3px ${props => props.theme.main.disabled};
    }

    @media screen and (min-width: 768px) {
        width: 40%;
    }
    @media screen and (min-width: 1024px) {
        display: flex;
        flex: initial;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: 33%;
    }

    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }

    .recommended {
        border: 0;
        position: absolute;
        text-align: center;
        top: -2px;
        width: 100%;

        .recommended-label {
            border: 0;
            background-color: rgba(78, 45, 120, 0.9);
            border-radius: 0 0 8px 8px;
            color: ${props => props.theme.main.textWhite};
            padding: 0 16px 8px;
        }
    }

    .card-photo {
        background-size: cover;
        border-radius: 12px 12px 0 0;
        height: 290px;
        width: 100%;

    }
    .card-photo-blocked {
        filter: brightness(40%);
    }

    .courses-stamps {
        border: 1px solid ${props => props.theme.main.textWhite};
        border-radius: 50px;
        bottom: 120px;
        color: ${props => props.theme.main.textWhite};
        display: block;
        font-size: 0.75rem;
        margin: 0 auto;
        padding: 2px 10px;
        position: relative;
        text-align: center;
        top: calc(100% - 48px);
        width: calc(100% - 60px);
    }

    .card-description {
        padding: 24px;
        width: 100%;

        .card-description-title {
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 16px 0;

            small {
                margin-left: 8px;
            }
            &.card-description-title-blocked {
                margin: 0 0 10px 0;
            }
        }

        .card-description-tasks {

            &.completed {
                color: ${props => props.theme.main.successColor};
                small {
                    font-weight: bold;
                }
            }

            .card-description-percent {
                color: ${props => props.theme.main.mainPurple};

                &.completed {
                    color: ${props => props.theme.main.successColor};
                }
            }

        }

    }
    .card-description-blocked {
        background-color:  ${(props) => props.theme.main.blocked}; !important;
        span {
            font-size: 14px;
        }
    }
`

const ClickableStyled = styled.span`
    color: ${props => props.theme.main.errorColor} !important;
    font-weight: bold;
`

interface Book {
    aluno_Bandeira_Yconnect: any[]
    aluno_curso: any[]
    descr_curso: string
    duracao_dias: number
    id_curso: number
    id_livro: number
    id_nivel: number
    img_carimbo: string
    notas_Etapa_Yconnect: any[]
    titulo_curso: string
    unidade_Yconnect: any[]
    valor_curso: number
}

interface BookCardProps {
    book: Book,
    history: any
    isBlocked: boolean
    currentCourse?: String
}

const BookCard = ({
    book,
    history,
    isBlocked,
    currentCourse
}: BookCardProps) => {
    const [bookCoverUrl, setBookCoverUrl] = useState("")
    const redirectPage = (course: any) => {
        history.push({
            pathname: `courses/${course.id_curso}/view-book`,
            state: {
                course
            }
        });
    }

    useEffect(() => {
        fetchCapaDeLivro()
    }, [])

    const responseHandler = (response: any) => {
        const hasBookCover = response.data.capas[0] ? true : false
        if (hasBookCover) return response.data.capas[0].url_capa
        return ""
    }

    const fetchCapaDeLivro = () : void => {
        const idCourseExists = book.id_curso ? true : false
        if(!idCourseExists) return null
        CourseResource
        .GetCover(book.id_curso)
        .then((response:any) => {
            const capa_do_livro = responseHandler(response)
            const urlCoverBookCMS = getStaticUrl(capa_do_livro)
            setBookCoverUrl(urlCoverBookCMS)
        })
    }

    return <CourseCardStyle className={`YC-CourseCard card shadow p-0 mx-2 pointer`} onClick={() => redirectPage(book)}>
                <div className="card-photo" style={{ backgroundImage: `url(${bookCoverUrl}` }}>
                </div>

                <div className={`card-description ${ isBlocked ? "card-description-blocked":""}`}>
                    <span className={`card-description-title ${ isBlocked ? "card-description-title-blocked":""}`}>
                       {
                        isBlocked
                            ? <>
                                    <span>{" "}To unlock this course, you will need to complete the{" "}</span>
                                    <ClickableStyled
                                        className="mt-1 active-teach pointer"
                                    >
                                    {currentCourse}
                                    </ClickableStyled>
                                </>
                            : book.titulo_curso
                        }
                    </span>

                </div>
            </CourseCardStyle>
}

export default BookCard

import * as React from "react"
import {Fragment} from "react"
import { Row, Col } from "reactstrap"

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"
import ExerciseTemplate from "YConnect/Enums/ExerciseTemplate"

import VideoMainView from "YConnect/PagesComponents/CMS/Views/VideoMain.view"
import AudioMainView from "YConnect/PagesComponents/CMS/Views/AudioMain.view"
import ImageMainView from "YConnect/PagesComponents/CMS/Views/ImageMain.view"
import ContentView   from "YConnect/PagesComponents/CMS/Views/Content.view"
import HelpView      from "YConnect/PagesComponents/CMS/Views/Help.view"
import LongTextView  from "YConnect/PagesComponents/CMS/Views/LongText.view"

import TypeInView                  from "YConnect/PagesComponents/CMS/Views/TypeIn.view"
import UnscrambleView              from "YConnect/PagesComponents/CMS/Views/Unscramble.view"
import DragAndDropView             from "YConnect/PagesComponents/CMS/Views/DragAndDrop.view"
import ChooseMultipleView          from "YConnect/PagesComponents/CMS/Views/ChooseMultiple.view"
import DialogueView                from "YConnect/PagesComponents/CMS/Views/Dialogue.view"
import MatchTextView               from "YConnect/PagesComponents/CMS/Views/MatchText.view"
import DragAndDropTextAndImageView from "YConnect/PagesComponents/CMS/Views/DragAndDropTextAndImage.view"
import ClickAudioWithImageView     from "YConnect/PagesComponents/CMS/Views/ClickAudioWithImage.view"
import OpenQuestionPreview         from "YConnect/PagesComponents/CMS/Views/OpenQuestionPreview.view"

import ExerciseDescription from "YConnect/Enums/ExerciseDescription"

type QuestionViewProps = {
    listTemplates:Array<TemplateType>
    question:QuestionType
}

const QuestionView = ({
    listTemplates,
    question
}:QuestionViewProps)=>{

    const {
        Id,
        Enunciated,
        Content,
        Text,
        Help,
        QuestionOrder,
        Answers,
        TemplateId,
        Dialogues,
        ExerciseMedias
    } = question

    ////TYPE IN
    const {DialogueMessages} = Dialogues[0] || {}

    const getTemplate = () => listTemplates.find(({Id})=> Id == TemplateId) || {}

    return <Fragment>
                <Row className="my-3">
                    <Col md={12}>
                        <h3 className="text-center" dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(Enunciated) }}/>
                    </Col>
                </Row>
                <VideoMainView medias={ExerciseMedias}/>
                <AudioMainView medias={ExerciseMedias}/>
                <ImageMainView medias={ExerciseMedias}/>
                <HelpView Help={Help}/>
                <LongTextView Text={Text}/>
                <ContentView 
                    Content={Content} 
                    Help={Help} 
                    Text={Text} 
                    rowGrey={
                        getTemplate().Description == ExerciseDescription.TYPE_IN_IMG
                        || (Text == null 
                        && Help == null
                        && ExerciseMedias.length==0)
                        }/>

                {
                    getTemplate().Name === ExerciseTemplate.TYPE_IN
                    && DialogueMessages 
                    && <TypeInView 
                        Medias={ExerciseMedias.filter(({MediaType}) => MediaType.toLowerCase() ==="image")}
                        DialogueMessages={DialogueMessages} /> 
                }
                {
                    Answers
                    && (getTemplate().Name === ExerciseTemplate.CHOOSE_MULTIPLE
                    || getTemplate().Name === ExerciseTemplate.VIDEO_CHOOSE_MULTIPLE)
                    && <ChooseMultipleView Answers={Answers} />
                }
                {
                    getTemplate().Name === ExerciseTemplate.UNSCRAMBLE
                    && DialogueMessages && DialogueMessages.map(({Text}:any, key:number) =>
                        <UnscrambleView key={key} Text={Text} />)
                }
                {
                    (
                        getTemplate().Name === ExerciseTemplate.DDDIALOGO_LONGO 
                        || getTemplate().Name === ExerciseTemplate.CADDDIALOGO_LONGO
                        ||  getTemplate().Name === ExerciseTemplate.DRAG_DROP_VIDEO
                    ) && <DragAndDropView question={question} />
                }

                {
                    getTemplate().Name === ExerciseTemplate.DDDIALOGO_FRASE
                    && <DialogueView question={question} />
                }

                {
                    getTemplate().Name === ExerciseTemplate.MATCH_TEXT
                    && <MatchTextView question={question} />
                }

                {
                    getTemplate().Name === ExerciseTemplate.DRAG_DROP_TEXT_IMG
                    && <DragAndDropTextAndImageView question={question} />
                }
                {
                    getTemplate().Name === ExerciseTemplate.CACLICK_AUDIO
                    && <ClickAudioWithImageView question={question} />
                }
                {
                    getTemplate().Name === ExerciseTemplate.OPEN_QUESTION
                    && <OpenQuestionPreview />
                }
            </Fragment>
}

export default QuestionView
import * as React                   from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"

import {Container, Row, Col, FormGroup}             from "reactstrap"


import * as edit       from "YConnect/Assets/svg/edit.svg"
import * as eyes       from "YConnect/Assets/svg/eyes.svg"
import * as reloadGrey from "YConnect/Assets/svg/reloadGrey.svg"

function converterFormatoData(dataString: string) {
    // Divide a string em três partes: ano, mês e dia
    const [ano, mes, dia] = dataString.split("-");

    // Cria uma nova string no formato "DD/MM/AAAA"
    const novaDataString = `${dia}/${mes}/${ano}`;

    return novaDataString;
}

function converterStringParaData(dataString) {
    const partesData = dataString.split("/");
    const dia = parseInt(partesData[0], 10);
    const mes = parseInt(partesData[1], 10) - 1; // Os meses em JavaScript são indexados em 0 (janeiro é 0, fevereiro é 1, etc.)
    const ano = parseInt(partesData[2], 10);
    return new Date(ano, mes, dia);
  }

const CONFIG_COURSES_CONTROL:Array<ColumnConfigType> = [
    {
        label     : "Course Name",
        keystone: "titulo_curso"
    },
    {
        keystone : "data_inicial",
        label    : "Start Date",
        formatter : ((data_inicial: any) => {
            var getOnlyDate = data_inicial.split('T')
            return converterFormatoData(getOnlyDate[0])
        }),
    },
    {
        keystone : "data_final",
        label    : "Expire Date",
        formatter : ((data_final: any) => {
            var getOnlyDate = data_final.split('T')
            return converterFormatoData(getOnlyDate[0])
        }),
    },
    {
        keystone : "messageStatus",
        label    : "Status",
    },
    {
        keystone : "nm_estab",
        label    : "Establishment",
    },

    {
        label    : "Archived",
        formatter : (data:any) => {
            return <div><span style={data.curso_corrente == 0 ? { color:'red' } : { color:'black' }}>{data.curso_corrente == 1 ? "Not Archived" : "Archived"}</span></div>
        }
    },
    {
        label     : "Edit",
        formatter : (listCoursesWithModal:any) => {
        return <DropdownButton
                    alignRight
                    drop  = "up"
                    key   = "up"
                    title     = "Actions"
                    className = "users-dropdown">
                        <Dropdown.Item eventKey="4" onClick={() => listCoursesWithModal.openArchiveModal(listCoursesWithModal)}>
                            <img src={edit} alt="" className="pr-3" />
                            {listCoursesWithModal.curso_corrente == 1 ? "Archive Course" : "Unarchive Course"}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="4" onClick={() => listCoursesWithModal.openExpirationDateModal(listCoursesWithModal)}>
                            <img src={eyes} alt="" className="pr-3" />
                            Edit Expiration Date
                        </Dropdown.Item>
                </DropdownButton>}
    }
]


export default CONFIG_COURSES_CONTROL

import * as React from "react"
import { Col, Tooltip } from "reactstrap"
import {
    SortableContainer,
    SortableElement,
    arrayMove
}                   from "react-sortable-hoc"

import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style"

import API          from "YConnect/API"
import Loader       from "YConnect/Components/Loader"
import AudioPlayer  from "YConnect/Components/AudioPlayer"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"
import getConfig    from "YConnect/Utils/GetRequestConfig.util"
import formatText   from "YConnect/Utils/FormatText.util";

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

import find      from "lodash/find"
import clone     from "lodash/clone"
import isBoolean from "lodash/isBoolean"

import AudioPlayerColStyle       from "YConnect/Fields/Exercises/Unscramble.field/AudioPlayerCol.style"
import ExerciseOptionStyle       from "YConnect/Fields/Exercises/Unscramble.field/ExerciseOption.style"
import ExerciseRowStyle          from "YConnect/Fields/Exercises/Unscramble.field/ExerciseRow.style"
import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/Unscramble.field/ExerciseSubStatement.style"

class UnscrambleField extends React.Component<ExerciseFieldPropsType, {
    loadingExercise  : boolean,
    loadingAnwers    : boolean,
    rightAnswers     : any,
    contentDialogue  : any,
    respostasUsuario : any,
    tooltipOpen      : boolean
    isDisabledViewAnswers : boolean
}> {
    constructor(props: any) {
        super(props)

        if (this.props.innerRef) {
            this.props.innerRef.current.selectLanguage = this.selectLanguage
        }

        this.state = {
            loadingExercise: true,
            loadingAnwers: false,
            tooltipOpen: false,
            rightAnswers: [],
            contentDialogue: [],
            respostasUsuario: [],
            language: this.props.translated,
        }

        this.handleChange = this.handleChange.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this)
    }

    componentDidMount() {
        this.fetchDialogueExercise()
    }

    selectLanguage = (language: string) => {
        this.setState({...this.state, language})
    }

    async fetchDialogueExercise () {
        try {
            const contentDialogue = await API.Exercicio.getDialogo({
                idExercicio: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem
            }, getConfig())

            this.embaralhaOpRespostas(contentDialogue.data.dialogo_Mensagem_Yconnect)

            if (this.props.attempts > 0)
                this.fetchUserAnswer()
        } catch (error) {}
    }

    async fetchUserAnswer() {
        this.setState({ loadingAnwers: true })
        try {
            const response = await API.Exercicio.getRespostaUsuario({
                userId: this.props.studentId,
                exer: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem,
                tentativa: 'U',
                idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
            }, getConfig())

            this.findAnswers(response.data.resposta)

        } catch (error) {}
    }

	handleChange() {
        const { id, onChange } = this.props
        const { respostasUsuario, contentDialogue } = this.state

        const score = this.calculaScore(contentDialogue)

        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })
    }

    findAnswers(respostasUsuario: string) {
        const { contentDialogue, rightAnswers } = this.state
        const respostas = JSON.parse(respostasUsuario) || ''

        const result = respostas.map((idMensagem: number, index) => {
            const resp = find(contentDialogue, { ordem_mensagem: Number(idMensagem) })

            rightAnswers.push(Number(idMensagem) === index + 1)
            return resp
        }, [])

        this.setState({
            loadingAnwers: false,
            contentDialogue: result,
            respostasUsuario: respostas,
            rightAnswers
        })
    }

    calculaScore(answers: any) {
        var total = answers.length;
        let qntdAcertos = 0;

        answers.map((resposta: any, index) => {
            if (resposta.ordem_dialogo === index + 1) {
                qntdAcertos++;
            }
        })

        return { qntd_acertos: qntdAcertos, qntd_respostas: total };
    }

    embaralhaOpRespostas (dialogo_Mensagem_Yconnect: any) {
        let retorno = clone(dialogo_Mensagem_Yconnect)

        dialogo_Mensagem_Yconnect.map(() => {
            const r1 = Math.floor(Math.random() * dialogo_Mensagem_Yconnect.length)
            const r2 = Math.floor(Math.random() * dialogo_Mensagem_Yconnect.length)

            const aux = retorno[r2]
            retorno[r2] = retorno[r1]
            retorno[r1] = aux
        })

        const respostasUsuario = retorno.reduce((acc, item) => {
            acc.push(item.ordem_dialogo)

            return acc
        }, [])

        this.setState({
            contentDialogue: retorno,
            respostasUsuario,
            loadingExercise: false
        })

        return retorno
    }

    onSortEnd ({ oldIndex, newIndex }) {
        const { respostasUsuario, contentDialogue, rightAnswers } = this.state


        const right = arrayMove(rightAnswers, oldIndex, newIndex)
        right[newIndex] = null
        right[oldIndex] = null

        this.setState({
            contentDialogue: arrayMove(contentDialogue, oldIndex, newIndex),
            respostasUsuario: arrayMove(respostasUsuario, oldIndex, newIndex),
            rightAnswers: right
        }, () => this.handleChange())
    }

    showCorrectAnswers() {
        const { respostasUsuario, rightAnswers, contentDialogue } = this.state

        const result = contentDialogue.map((item, index) => {
            respostasUsuario[item.ordem_dialogo - 1] = item.ordem_dialogo
            rightAnswers[item.ordem_dialogo - 1] = true

            return find(contentDialogue, { ordem_dialogo: index + 1})
        }, [])

        /** calculate score */
        const score = this.calculaScore(result)

        /** call parent function and send the values */
        this.props.onChange(this.props.id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })

        /** update component parameters */
        this.setState({ rightAnswers, respostasUsuario, contentDialogue: result })
    }

    render() {
        let {
			props: {
                exercise,
                attempts,
                isStudent,
                isDisabledViewAnswers
            },
            state: {
                loadingAnwers,
                loadingExercise,
                contentDialogue,
                rightAnswers
            }
        } = this

        if (loadingAnwers || loadingExercise) {
            return <Loader />
        }

        const SortableItem = SortableElement(({ value, sortIndex }) => {
            return (
                <Col className="exercise-dialogue-content pb-2" md="12" lg="12">
                    <ExerciseOptionStyle
                        className={`d-flex ${isBoolean(rightAnswers[sortIndex]) ? (rightAnswers[sortIndex] ? 'right' : 'wrong') : ''}`}
                        data-asnwerOrder={value.ordem_dialogo}
                    >
                        <div className="enable-link">
                            <span />
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(value.texto)) }} />
                    </ExerciseOptionStyle>
                </Col>
            )
        })

        const SortableList = SortableContainer(({ children }) => {
            return (
                <ExerciseRowStyle className="exercise-dialogue pr-3 pl-3 pr-md-5 pl-md-5 pt-5">
                    {children}
                </ExerciseRowStyle>
            )
        })

        const midiaYConnect = exercise.midia_Yconnect.length > 0 ? exercise.midia_Yconnect[0]
            : (exercise.exercicio_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect.length > 0) ? exercise.exercicio_Yconnect.midia_Yconnect[0]
                : {}

        const hasAudio = (midiaYConnect || {}).tipo_midia === 'audio'
        return (
            <>

               {!isDisabledViewAnswers && (attempts >= 2 || !isStudent) ?
                    <HeaderButtonStyle
                        className="float-right"
                        label="View Answers"
                        onClick={this.showCorrectAnswers}
                        icon="checkedGreen2"
                    />
                    :
                    <>
                        <HeaderButtonStyle
                            id="completeResultToolTip"
                            className="float-right disabled view-answers"
                            label="View Answers"
                            icon="checkedGreen2"
                        />
                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="completeResultToolTip" toggle={() => this.setState(state => ({ tooltipOpen: !state.tooltipOpen }))}>
                                    {
                                        this.state.language == "br" &&
                                        <span>Oops, você só poderá saber a resposta correta depois de verificar suas próprias respostas na primeira e segunda tentativa.</span>
                                    }

                                    {
                                        this.state.language == "en" &&
                                        <span>Oops, you'll only be able to know the
                                    correct answers once you've checked your own
                                    answers on the 1st and 2nd attempts.</span>
                                    }
                        </Tooltip>
                    </>
                }
                {exercise.ajuda || hasAudio || exercise.conteudo_exercicio ?
                    <ExerciseSubStatementStyle className="w-100 pr-5 pl-5">
                        {/** Ajuda */}
                        {exercise.ajuda ?
                            <div className="w-100 text-center">
                                <h2 dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.ajuda))}}></h2>
                            </div>
                            :
                            ''
                        }


                        {/** texto */}
                        {exercise.conteudo_exercicio && !exercise.conteudo_exercicio.includes(midiaYConnect.url) ?
                            <div className="text-center">
                                <p dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.conteudo_exercicio))}}></p>
                            </div>
                            :
                            ''
                        }

                        {/** Midia file */}
                        {
                            exercise.midia_Yconnect.length > 0 && !hasAudio &&
                            <ExerciseSubStatementStyle className="w-100 pr-5 pl-5">
                                <Col className="exercise-dialogue-question align-image-cms" xs="12" md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }}>
                                    <img style={{width: '100%'}} src={getStaticUrl(midiaYConnect.url)} />
                                </Col>
                            </ExerciseSubStatementStyle>
                        }

                        {/** audio */}
                        {hasAudio ?
                            <AudioPlayerColStyle className=" mb-3" xs="12" md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }}>
                                <AudioPlayer
                                    src={getStaticUrl(midiaYConnect.url)}
                                />
                            </AudioPlayerColStyle>
                            :
                            ''
                        }
                    </ExerciseSubStatementStyle>
                    :
                    ''
                }

                <SortableList onSortEnd={this.onSortEnd}>
                    {contentDialogue.map((item: Array<any>, key: number) => (
                        <SortableItem key={`item-${key}`} index={key} sortIndex={key} value={item} />
                    ))}
                </SortableList>
            </>
        )
    }
}

export default UnscrambleField

import styled from "styled-components"

const BestClassScoreStyle = styled.div `
    border: 1px solid #E1E6E7;
    border-radius: 8px;
    min-height: 230px;
    margin-top: 10px;
    
    .best-class {
        border-bottom: 1px solid #E1E6E7;
        display: flex;
        flex-flow: row wrap;
        padding: 15px;
        .best-class-icon {
            background-color: #FFFFFF;
            border: 2px solid #E1E6E7;
            border-radius: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            height: 60px;
            width: 60px;
        }
        .best-class-name {
            display: flex;
            flex-flow: column wrap;
            padding-left: 24px;

            .title {
                color: #565656;
                font-size: 20px;
                font-weight: 600;
            }

            .address {
                color: #989898;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    .best-percent {
        align-items: center;
        border-bottom: 1px solid #E1E6E7;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 15px;
        .title {
            color: #565656;
            font-size: 17px;
            font-weight: 600;
            width: 100%;
        }
        .percent {
            font-size: 42px;
            font-weight: 300;
            width: 50%;
        }
        .description {
            color: #00A281;
            font-size: 14px;
            font-weight: 400;
            width: 50%;
        }
    }

    .teacher-info {
        border-bottom: 1px solid #E1E6E7;
        padding: 15px;
    }
`

export default BestClassScoreStyle
import * as React                        from "react"
import { Fragment, useEffect, useState } from "react"
import { Route, HashRouter, Switch }     from "react-router-dom"
import qs                                from "querystring"
import { ThemeProvider }                 from "styled-components"
import Toastify from 'toastify-js' 

import GlobalStyle         from "YConnect/Styles/Global.style"
import MainTheme           from "YConnect/Themes/Main.theme"
import DyslexiaTheme       from "YConnect/Themes/Dyslexia.theme"
import NightTheme          from "YConnect/Themes/Night.theme"
import HighTheme           from "YConnect/Themes/High.theme"
import UserContext         from "YConnect/User.context"
import PrePostContext      from "YConnect/PrePost.context"
import Header              from "YConnect/Components/Header"
import Footer              from "YConnect/Components/Footer"
import ScrollToTop         from "YConnect/Components/ScrollToTop"
import PlayerModal         from "YConnect/Modals/Player.modal"
import API                 from "YConnect/API"
import PageViewUtil        from "YConnect/Utils/PageView.util"
import getConfig           from "YConnect/Utils/GetRequestConfig.util"
import DescryptoValue      from "YConnect/Utils/DescryptoValue"
import { userDataLayer}    from "YConnect/Utils/DataLayer"
import UnlockBackpackModal from "YConnect/Modals/UnlockBackpack.modal"
import Loader              from "YConnect/Components/Loader"
import RetractPlayer       from "YConnect/Components/RetractPlayer"
import StudentMode from "./Components/StudentMode"

const filterWithPageComponent = ({ page }: any)   => !!page
const getKeystone             = ({keystone}:any)  => keystone
const by                      = (property:string) => (config:any) => config[property]

const Redirect = (redirect:any, profiles:any) => {

    if(redirect){
        if(profiles.indexOf(6) > -1 || profiles.indexOf(12) > -1){
            if(redirect.indexOf("/reports") > -1){
                window.location.href = `/#`
            }else{
                window.location.href = `#${redirect}`
            }
        }else if(
            redirect.indexOf("/settings") > -1
            || redirect.indexOf("/manage-users") > -1
            || redirect.indexOf("/explore") > -1
            || redirect.indexOf("/upload-card") > -1
            || redirect.indexOf("/unlock-score") > -1
            || redirect.indexOf("/reset-student") > -1
            || redirect.indexOf("/reports-teacher") > -1
            || redirect.indexOf("/reports-class-grades") > -1
            || redirect.indexOf("/pre-post-report") > -1
        ){
            window.location.href = "/#"
        }else{
            window.location.href = `#${redirect}`
        }


    } else {
        window.location.href = "/#"
    }
}

const YConnectContainer = ({configs}:{configs:Array<PageConfig>}) => {

    //TODO Iniciando variaveis de estado
    const [userData, setUserData]                      = useState(JSON.parse(localStorage.getItem("data")))
    const [isStudentMode, setIsStudentMode]            = useState(localStorage.getItem("isStudentMode"))
    const [contrast, setContrast]                      = useState(localStorage.getItem("contrast"))
    const [reading, setReading]                        = useState(localStorage.getItem("reading"))
    const [hasShowModalPlayer, setModalPlayer]         = useState(false)
    const [audioFiles, setAudioFiles]                  = useState({nomeCurso: "", faixas: []})
    const [retractPlayer, setRetractPlayer]            = useState(false)
    const [audioPlaying, setAudioPlaying]              = useState({})
    const [modalTitle, setModalTitle]                  = useState()
    const [hasShowBackpackModal, setShowBackpackModal] = useState()
    const [backpacks, setBackpack]                     = useState([])
    const [hasRender, setHasRender]                    = useState(true)
    const [prePostUser, setPrePostUser]                = useState(JSON.parse(localStorage.getItem("prepost")))

    useEffect(() => {
        if (reading) {
            localStorage.setItem("reading", reading)
        }
    }, [reading])

    useEffect(() => {
        if (contrast) {
            localStorage.setItem("contrast", contrast)
        }
    }, [contrast])


    useEffect(() => {
        if (!!isStudentMode) {          
            const studentTemp = JSON.parse(localStorage.getItem("dataTemp"));
            if(studentTemp) setUserData(studentTemp);
        }
    }, [isStudentMode])

    useEffect(() => {
        if (
            window.location.hash.indexOf("#/password-reset") > -1
            || window.location.hash.indexOf("#/press") > -1
            || window.location.hash.indexOf("#/redefine-senha") > -1
            || window.location.hash.indexOf("#/confirma-email") > -1
        ) {} else if (!userData) {
            if(window.location.hash.indexOf("#/login") === -1){
                window.location.href = "#/login"
            }
        }
    }, [userData])

    useEffect(()=>{
        if(hasShowBackpackModal === false && backpacks.length === 0){
            setHasRender(false)
            setTimeout(()=>{
                setHasRender(true)
            })
        }
    }, [hasShowBackpackModal])

    useEffect(()=>{

        if(backpacks.length > 0){
            setShowBackpackModal(true)
        }else if(backpacks.length === 0 && userData && userData.passwordForLogin){
            localStorage.setItem("afterLogin", "true")
            updateDataUser()
        }
    }, [backpacks])

    useEffect(() => {
        const {PROFILE} = server.env ;
        if( PROFILE !== 'UAT' && PROFILE !== 'UAT2') {
            const script = document.createElement('script');
            script.src= "//js.hs-scripts.com/7960436.js";
            script.text= "HubspotTracking loaded";
            script.type = "text/javascript";
            script.id = "hs-script-loader";
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            return () => {
             document.body.removeChild(script);
            }
        }
      }, []);

    useEffect(() => {
        if (userData && userData.idUsuario)
            userDataLayer.ResendUserId(userData.idUsuario);
    }, [userData]);

    const hasShowHeader = (keystone: string) => !(configs.filter(by("disableHeader")).map(getKeystone).indexOf(keystone) > -1)
    const hasShowFooter = (keystone: string) => !(configs.filter(by("disableFooter")).map(getKeystone).indexOf(keystone) > -1)

    const handleUpdateUserData = (userDataUpdated:any) =>{

        const newUserData = {...userData, ...userDataUpdated }
        localStorage.setItem("data", JSON.stringify(newUserData))
        setUserData(newUserData)
        if(
            userData.primeiroAcessoGameficacao === null
            || (
                userData.primeiroAcessoGameficacao
                && userData.primeiroAcessoGameficacao.dt_primeiro_acesso === null
            )
        ){
            window.location.href = "#/"
        }else{

        }
    }

    const handleLogin = async (userData: any, password?: string) => {

        if (userData) {
            try {
                setUserData(userData)
                const result = await API.Acessibilidade.Get({id_aluno: userData.idUsuario}, getConfig)

                if(result.data){
                    if(result.data.contraste){
                        setContrast(result.data.contraste)
                    }

                    if(result.data.leitura){
                        setReading(result.data.leitura)
                    }
                }
            } catch (error) {}


            if(
                userData.primeiroAcessoGameficacao !== null
                && (
                    userData.primeiroAcessoGameficacao
                    && userData.primeiroAcessoGameficacao.dt_primeiro_acesso !== null
                )
            ){
                const {location:{hash}} = window
                const {redirect} = qs.parse(hash.slice(hash.indexOf("?")+1, hash.length))

                API.PrePostTest.Get({
                    id_aluno: userData.idUsuario,
                    id_curso: userData.idCursoAtual
                }, getConfig())
                .then(({data}:any) => {
                    setPrePostUser(data)

                    userData.profiles.length <= 1
                    && data.listPptTestAlunoYConnect
                    && data.listPptTestAlunoYConnect[0]
                    && data.listPptTestAlunoYConnect[0].aluno_curso
                    && data.listPptTestAlunoYConnect[0].aluno_curso.fl_feito === false
                    ? window.location.href = "/#"
                    : Redirect(redirect, userData.profiles)

                })
                .catch((e:any) => {
                    Redirect(redirect, userData.profiles)
                })

            }else{
                const {data} = await API.Profile.Get({ id: userData.idUsuario}, getConfig())

                if (data && password) {
                    const valuesForRequest = {
                        ...data,
                        id: userData.idUsuario,
                    };
                    try {
                        await API.Profile.Put(
                            {
                                ...valuesForRequest,
                                senha_aluno: password,
                                nova_senha_aluno: password,
                            },
                            getConfig()
                        );

                         setUserData({
                            ...userData,
                            ...{
                                nomeCompleto: `${data.nome_aluno} ${data.sobrenome_aluno}`,
                                nome: data.nome_aluno,
                                email: data.email_aluno,
                                foto: data.foto
                                    ? typeof data.foto == "object"
                                        ? data.foto
                                              .map((item: any) =>
                                                  String.fromCharCode(item)
                                              )
                                              .join("")
                                        : atob(data.foto)
                                    : "",
                            },
                            ...(!userData.primeiroAcessoGameficacao ||
                            !userData.primeiroAcessoGameficacao
                                .dt_primeiro_acesso
                                ? { passwordForLogin: password }
                                : {}),
                        })
                         window.location.href = "#/"
                    } catch (e) {                        
                        window.location.href = "#/"
                    }
                }
            }
        } else {
            Toastify({
                text: "Error! <br> Invalid username or password!",
                duration: 3000,
                className: "toastify-error",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }
    const updateDataUser = async () => {
        setUserData({...userData, primeiroAcessoGameficacao:{}})
        const {apelido, idUsuario, passwordForLogin} = userData
        if(passwordForLogin){
            try{
                await API.Dashboard.Put({idUsuario})
                let responseAuth    = await API.Auth.Get({id:apelido, senha:passwordForLogin, mobile:false})
                responseAuth.data.apelido = DescryptoValue.descryptoString(responseAuth.data.apelido);
                responseAuth.data.nomeCompleto = DescryptoValue.descryptoString(responseAuth.data.nomeCompleto);
                responseAuth.data.email = DescryptoValue.descryptoString(responseAuth.data.email);
                const {data}          = responseAuth
                const authToken       = responseAuth.headers["auth-token"]
                let responseUsuario = await API.Usuario.Get({id:idUsuario}, {headers:{"Authorization": authToken}})
                responseUsuario.data = await DescryptoValue.descryptoObject(responseUsuario.data);
                const newUserData     = {...data, nome:responseUsuario.data.nome_aluno}


                handleLogin(newUserData, passwordForLogin)

                localStorage.setItem("Authorization", authToken)
                localStorage.setItem("data", JSON.stringify(newUserData))

            }catch(erro){
                Toastify({
                    text: "Error! <br> Unable to login",
                    duration: 3000,
                    className: "toastify-error",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
            }
        }else{
            Toastify({
                text: "Fill in all required fields in your profile",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
        }
    }

    const handleCloseModalFirstAccess = () => {
        const {profiles} = userData
        if( profiles.indexOf(15) === -1
            && profiles.indexOf(6) === -1
            && profiles.indexOf(12) === -1
        ){
            const {idUsuario} = userData
            API.Tag.Send({idAluno:idUsuario, idSituacao: 6}, getConfig())
            .then(({data}: any) => {
                const {stampTag} = data
                setBackpack(stampTag)
            })
            .catch((err: any) => {
                updateDataUser()
            })
        }
    }

    const handleCheckTag = (params:any) => {
        const {profiles} = userData

        if(
            profiles.indexOf(15) === -1
            && profiles.indexOf(6) === -1
            && profiles.indexOf(12) === -1
            ){
            const {idSituacao} = params

            API.Tag[idSituacao == 5?"Update":"Send"](params, getConfig())
            .then(({data}: any) => {
                const {stampTag} = data
                setBackpack(stampTag)
            })

        }

    }

    const handleLogout = () => {
        setRetractPlayer(false)
        setAudioPlaying(false)
        setContrast("regular")
        setReading("regular")

        localStorage.removeItem("Authorization")
        localStorage.removeItem("data")
        localStorage.removeItem("contrast")
        localStorage.removeItem("reading")
        localStorage.removeItem("prepost")
        localStorage.removeItem("courseInfo")
        localStorage.removeItem("reportAluno")
        setUserData(undefined)
        setPrePostUser(undefined)
        Toastify({
            text: "Success! <br> Logout with success!",
            duration: 3000,
            className: "toastify-success",
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
            escapeMarkup: false,
            onClick: function(){} 
          }).showToast();
    }

    const handleCloseAudio = () => {
        setModalPlayer(false)
        setRetractPlayer(false)
        setAudioPlaying({})
    }

    const handleOpenRetract = () => {
        setModalPlayer(false)
        setRetractPlayer(true)
    }

    const handleCloseRetract = () => {
        setRetractPlayer(false)
        setModalPlayer(true)
    }

    const handleCloseModalTag = () =>{
        setShowBackpackModal(false)
        setBackpack(backpacks.slice(1))
    }

    const handleUpdatePrePost = (prepost:any) => {
        setPrePostUser(prepost)
    }

    const handleStudentMode = () => {
        localStorage.removeItem("isStudentMode");
        localStorage.setItem("dataTemp", null);
        setIsStudentMode(null);
        window.location.href = "#/";
        window.location.reload();
    };
 

    const handleRenderContent = ({ keystone, path, page }: any) => {
        return (routeProps: any) => {
            PageViewUtil()

            if (
                userData
                || (
                    path
                    && (
                        path === "/login"
                        || path.indexOf("/password-reset") > -1
                        || path.indexOf("/press") > -1
                        || path.indexOf("/redefine-senha") > -1
                        || path.indexOf("/confirma-email") > -1
                    )
                )
            ) {
                return <Fragment>
                    <GlobalStyle whiteColor />
                    {
                        userData && hasShowHeader(keystone) &&
                        <Header
                            configs          = {configs}
                            contrast         = {contrast}
                            reading          = {reading}
                            onLogout         = {handleLogout}
                            onChangeContrast = {(contrast: string) => setContrast(contrast)}
                            onChangeReading  = {(reading: string) => setReading(reading)}
                            keystones        = {
                                [
                                    "COURSES",
                                    ...(userData.profiles.indexOf(6) > -1 || userData.profiles.indexOf(12) > -1 || userData.profiles.indexOf(15) > -1)
                                        ? ["REPORTS_TEACHER"]
                                        : ["REPORTS"],
                                    ...["TRAVEL_THE_WORLD"],
                                    ... ["ONLINE_TEACHER"],
                                    ...(userData.profiles.indexOf(6) > -1 || userData.profiles.indexOf(12) > -1)
                                        ? ["SETTINGS"]
                                        : ["VIRTUAL_CLASSROOM"],
                                    ...["TECHNICAL_SUPPORT"]
                                ]

                            }/>
                    }
                    {
                          userData && isStudentMode && hasShowHeader(keystone) &&
                          <StudentMode onClick={handleStudentMode} />
                    }

                    <main>
                        {
                            React.createElement(page, {
                                onLogin                 : handleLogin,
                                onLogout                : handleLogout,
                                onUpdateUserData        : handleUpdateUserData,
                                onUpdatePrePost         : handleUpdatePrePost,
                                onCloseModalFirstAccess : handleCloseModalFirstAccess,
                                backpacks               : {backpacks},
                                onOpenAudio             : ()=>setModalPlayer(true),
                                onCheckTag              : handleCheckTag,
                                setAudioFiles           : setAudioFiles,
                                setAudioTitle           : setModalTitle,
                                ...routeProps
                            }, null)
                        }
                    </main>

                    {
                        hasShowModalPlayer
                        && <PlayerModal
                                modalTitle    = {modalTitle}
                                hasShow       = {true}
                                audioFiles    = {audioFiles}
                                audioPlaying  = {audioPlaying}
                                onPlaying     = {setAudioPlaying}
                                OnCloseAudio  = {handleCloseAudio}
                                onOpenRetract = {handleOpenRetract}/>
                    }
                    {hasShowFooter(keystone) && <Footer
                        contrast         = {contrast}
                        reading          = {reading}
                        onChangeContrast = {(contrast: string) => setContrast(contrast)}
                        onChangeReading  = {(reading: string) => setReading(reading)}
                    />}
                </Fragment>
            } else if(path && path == "/password-reset") {
                window.location.href = "#/password-reset"
            } else if(path && path == "/press") {
                window.location.href = "#/press"
            } else if(path && path == "/redefine-senha") {
                window.location.href = "#/redefine-senha"
            } else if(path && path == "/confirma-email") {
                window.location.href = "#/confirma-email"
            } else {
                const {location:{pathname, search}} = routeProps
                window.location.href = `#/login?redirect=${pathname}${search}`
                return <Fragment />
            }
        }
    }

    const getTheme = () => {
        const theme = {
            main: MainTheme
        }

        if (reading === "dyslexia") {
            theme.main = {...theme.main, ...DyslexiaTheme}
        }

        if (contrast === "night") {
            theme.main = {...theme.main, ...NightTheme}
        }

        if (contrast === "high") {
            theme.main = {...theme.main, ...HighTheme}
        }

        return theme
    }

    return <UserContext.Provider value={userData}>
        <PrePostContext.Provider value={prePostUser}>
        <HashRouter>
            <ThemeProvider theme={getTheme()}>
                <ScrollToTop>
                    <Switch>
                        {
                            hasRender && configs
                            .filter(filterWithPageComponent)
                            .map((config, key) => <Route key={key} exact path={config.path} render={handleRenderContent(config)} />)
                        }
                    </Switch>
                    {!hasRender && <Loader/>}
                    <UnlockBackpackModal
                        isShow   = {hasShowBackpackModal && backpacks && backpacks.length > 0}
                        onClose  = {handleCloseModalTag}
                        backpack = {(backpacks && backpacks[0]) || []}/>
                    {
                        retractPlayer
                        && <RetractPlayer
                            audioFiles          = {audioFiles}
                            onPlaying           = {setAudioPlaying}
                            audioPlaying        = {audioPlaying}
                            onToggleRetract     = {handleCloseRetract}/>
                    }
                </ScrollToTop>
            </ThemeProvider>
        </HashRouter>
        </PrePostContext.Provider>
    </UserContext.Provider>
}
export default YConnectContainer



import * as React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"
import AudioPlayer  from "YConnect/Components/AudioPlayer"

const RowGreyStyled = styled(Row)`
    background-color: #f0f5f6;
`

const ColAudioStyled =  styled(Col)`
    background-color: #fff;
`

type AudioViewMainProps = {
    medias:Array<ExerciseMediaType>
}

const AudioViewMain = ({
    medias
}:AudioViewMainProps) => {

    const getMainExerciseMedia = () =>
        medias.find(({Order}) => Order === 0)

    return <>
    {
        getMainExerciseMedia()
        && getMainExerciseMedia().MediaType.toLowerCase() === "audio"
        && <RowGreyStyled className="justify-content-center">
                <ColAudioStyled md={6} className="my-5">
                    <AudioPlayer src={getStaticUrlAssests(getMainExerciseMedia().Source)}/>
                </ColAudioStyled>
            </RowGreyStyled>
    }
    </>
}

export default AudioViewMain
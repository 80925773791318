import * as React from "react"

import * as greenCheck from "YConnect/Assets/svg/checkedGreen.svg"
import * as warning from "YConnect/Assets/svg/warning.svg"
import * as closeRed from "YConnect/Assets/svg/closeRed.svg"

import OpenQuestionReviewType from "YConnect/Enums/OpenQuestionReviewType"

class OpenQuestionStatus extends React.Component<{
  attempts: number,
  latestReviewTypeAndAttempt: any,
  isStudent: boolean
}, {}> {
  isAwaitingForSecondReview() {
    const {attempts, latestReviewTypeAndAttempt} = this.props
    return (attempts === 2 && latestReviewTypeAndAttempt.type === OpenQuestionReviewType.REJECTION && latestReviewTypeAndAttempt.attempt === 1)
  }

  render() {
    const {latestReviewTypeAndAttempt, isStudent} = this.props
    let statusMessage = isStudent ? "Your answer " : "The student answer "
    let icon = ""

    if (!latestReviewTypeAndAttempt || this.isAwaitingForSecondReview()) {
      statusMessage += "is pending approval."
      icon = warning
    } else if (latestReviewTypeAndAttempt.type === OpenQuestionReviewType.APPROVAL) {
      statusMessage += "has been approved!"
      icon = greenCheck
    } else { 
      statusMessage += "has been disapproved."
      icon = closeRed
    }

    return (
      <div className="open-question__status-card__status">
        <strong>{statusMessage}</strong> <img src={icon} />
      </div>
    )
  }
}

export default OpenQuestionStatus
import * as React from "react"

import PanelCrud from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"

import {GetAll, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/Template.resources"

import CONFIG_TABLE_TEMPLATES from "YConnect/PagesComponents/ControlPanel/Tables/Template.table.config"
import TEMPLATE_TEMPLATE from "YConnect/PagesComponents/ControlPanel/Templates/Template.template"

const TemplatesPanel = () =>{

    return <PanelCrud
                idName            = "templateId"
                labelButtonCreate = "Create Template"
                labelForm         = "Template"
                template          = {TEMPLATE_TEMPLATE}
                configTable       = {CONFIG_TABLE_TEMPLATES}
                GetAll            = {GetAll}
                GetById           = {GetById}
                Create            = {Create}
                UpdateById        = {UpdateById}
                DeleteById        = {DeleteById} />
}

export default TemplatesPanel
import {useEffect, useReducer} from "react"

import API              from "YConnect/API"
import getRequestConfig from "YConnect/Utils/GetRequestConfig.util"

type ReportAction = {
    name     : string
    report  ?: any 
}

const useReport = ({
    classIdSelected,
    courseIdSelected,
    semesterIdSelected,
    establishmentIdSelected,
    teacherIdSelected
}: any) => {
    
    const [{
        teacherInfo,
        bestStudents,
        bestStudents2
    }, setReport] = useReducer((state:any, {name, report}:ReportAction) => {
        return  {...state, [name]:report}
    }, {})

    useEffect(()=>{

        setReport({name:"teacherInfo", report: undefined})
        API
        .PrePostTest
        .GetProfessor({ 
            idProfessor: teacherIdSelected
        }, getRequestConfig())
        .then((response: any) => setReport({name:"teacherInfo", report: response.data.professor}))


        setReport({name:"bestStudents", report: undefined})
        API
        .PrePostTest
        .GetBestRankedStudents({
            idProfessor          : !(classIdSelected && classIdSelected !== "") ? teacherIdSelected: "",
            idClassebrs          : classIdSelected,
            idCursobrs           : courseIdSelected,
            idAnoSemestrebrs     : semesterIdSelected,
            idEstabelecimentobrs : establishmentIdSelected,
            regionalbrs          : ""
        }, getRequestConfig())
        .then((response:any) => setReport({name:"bestStudents", report: response.data.bestRankedStudents}))

        if(
            semesterIdSelected
            && establishmentIdSelected
            && semesterIdSelected !== ""
            && establishmentIdSelected !== ""
        ){
            setReport({name:"bestStudents2", report: undefined})
            API
            .PrePostTest
            .GetScoreTurma({
                idGrupoSt           : classIdSelected || "",
                idCursoSt           : courseIdSelected || "",
                idAnoSemestreSt     : semesterIdSelected,
                idEstabelecimentoSt : establishmentIdSelected,
                regionalSt          : "",
                idProfessorSt       : teacherIdSelected

            }, getRequestConfig())
            .then((response:any) => setReport({name:"bestStudents2", report: response.data}))
        }

    }, [
        classIdSelected, 
        courseIdSelected, 
        semesterIdSelected, 
        establishmentIdSelected
    ])

    return {teacherInfo, bestStudents, bestStudents2}
}

export default useReport
import * as React from "react"
import {
    useEffect, useState, useContext
} from "react"
import { Container, Row, Col, Card } from "reactstrap"
import styled from "styled-components"

import { BreadcrumbReportByStudent } from "YConnect/Components/BreadCrumbList"
import PageTitle from "YConnect/Components/PageTitle"
import API from "YConnect/API"
import UserContext from "YConnect/User.context"
import { ButtonStyle } from "YConnect/Components/Button"
import SelectFieldStyle from "YConnect/Styles/SelectField.style"
import Table from "YConnect/Components/Table"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

import Loader             from "YConnect/Components/Loader"
import * as arrowNext     from "YConnect/Assets/svg/arrow-next.svg"
import * as arrowPrevious from "YConnect/Assets/svg/arrow-previous.svg"

import InputField  from "YConnect/Fields/Forms/Input.field"
import SelectField from "YConnect/Fields/Forms/Select.field"

import ModalReportByStudent from "YConnect/Modals/ReportByStudent.modal";

import CONFIG_TABLE_REPORT_BY_STUDENT from "YConnect/Configs/Tables/ReportByStudent.table.config"

const HeaderPagination = styled.div`
    display: flex;
    flex-flow: column wrap;

    @media screen and (min-width: 1024px) {
        flex-flow: row wrap;
        justify-content: flex-end;
    }
    label {
        @media screen and (min-width: 1024px) {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
        }
    }
`

const ContainerReportStudent = styled.div`
    max-width: 1400px !important;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
`

const TOTAL_USERS_PER_PAGE = 20
const FILTER_BY_DEFAULT = "9"

const getAmountPages = (amountUsers:number)=>{
    const AMOUNT_USERS_LAST_PAGE = amountUsers%TOTAL_USERS_PER_PAGE
    return (amountUsers-AMOUNT_USERS_LAST_PAGE)/TOTAL_USERS_PER_PAGE
    + (AMOUNT_USERS_LAST_PAGE > 0?1:0)
}

const ReportClassGrade = () => {

    const [grupos, setGrupos] = useState()
    const [establishments, setEstablishments] = useState()
    const [courses, setCourses] = useState()
    const [classes, setClasses] = useState()

    const [users, setUsers]               = useState()

    const [establishmentParam, setEstablishmentParam] = useState()
    const [courseParam, setCourseParam] = useState("")
    const [classParam, setClassParam] = useState()
    const [currentParam, setCurrentParam] = useState("true")

    const [isLoading, setIsLoading]       = useState(false)

    const [nomeParam, setNomeParam]       = useState("")
    const [emailParam, setEmailParam]     = useState("")
    const [usuarioParam, setUsuarioParam] = useState("")
    const [perfilParam, setPerfilParam]   = useState(FILTER_BY_DEFAULT)

    const [modalAproved, setModalAproved] = useState(false)

    const [lastSearches, setLastSearches] = useState(JSON.parse(localStorage.getItem("lastSearches")))

    const { estabelecimentos, idUsuario }: any = useContext(UserContext)

    const [amountPages, setAmountPages] = useState(0)
    const [currentPageNumber, setCurrentPageNumber] = useState(0)

    const [usersCurrent, setUsersCurrent] = useState([])


    const hasPrevious = currentPageNumber > 0
    const hasNext     = currentPageNumber+1 < amountPages
    const startUsers  = TOTAL_USERS_PER_PAGE*currentPageNumber
    const endUsers    = startUsers+TOTAL_USERS_PER_PAGE

    const handleChangeFilter = (keystone:string, value:any) => {
        const sets: any = {
            profile  : setPerfilParam,
            name     : setNomeParam,
            email    : setEmailParam,
            username : setUsuarioParam,
            course: setCourseParam,
        }
        if(sets[keystone]) sets[keystone](value)
    }

    useEffect(() => {
        if (!lastSearches) {
            const newLastSearches: any = {
                [idUsuario]: []
            }
            setLastSearches(newLastSearches)
            localStorage.setItem("lastSearches", JSON.stringify(newLastSearches))
        }
        API.Course.GetAll({ id_User_Report: idUsuario }, getConfig())
            .then((response: any) => {
                setCourses(response.data.map(({ id_curso, titulo_curso }: any) => ({ id: id_curso, value: titulo_curso })))
            })
    }, [])

    useEffect(() => {
        if (lastSearches && lastSearches[idUsuario]) {
            const onlyResourceBookList = lastSearches[idUsuario].filter((search: any) => !search.isResourceBook);
            lastSearches[idUsuario] = onlyResourceBookList;
            localStorage.setItem("lastSearches", JSON.stringify(lastSearches))
        }
    }, [lastSearches])

    useEffect(() => {
        if (estabelecimentos) {
            setEstablishments(estabelecimentos.map(({ nsu_estab, nm_estab }: any) => ({ id: nsu_estab, value: nm_estab })))
        }
    }, [estabelecimentos])

    useEffect(() => {
        if (establishmentParam) {
            setCourseParam("")
        }
    }, [establishmentParam])

    useEffect(() => {
        if (courseParam && courseParam !== "") {
            setClasses([])
            setClassParam("")
            const paramForFetch = {
                idEstab: establishmentParam,
                idCurso: courseParam,
                ...currentParam && currentParam !== "" ? { fl_vigentes: currentParam } : {}
            }

            API.Grupo.Get(paramForFetch, getConfig())
                .then((response: any) => setGrupos(response.data))
        }
    }, [courseParam, currentParam])

    useEffect(() => {
        if (grupos && grupos.turmas.length > 0) {
            setClasses(grupos.turmas.map(({ id_grupo, nome_grupo, idCurso }: any) => ({ id: [id_grupo, idCurso], value: nome_grupo })))
        } else {
            setClasses([])
        }
    }, [grupos])

    useEffect(() => {
        if(users){
            setUsersCurrent(users.slice(startUsers, endUsers))
            setAmountPages(getAmountPages(users.length))
            setIsLoading(false)
        }
    }, [users, currentPageNumber])


    const handleModalAproved = () => {
        setModalAproved(!modalAproved)
    }

    const fetch = () => {
        setUsers(undefined)
        API.Usuario.BuscarReportAluno({
        nome    : nomeParam,
        email   : emailParam,
        usuario : usuarioParam,
        cursos  : courseParam,
        idUsuario : idUsuario
    }).then((response: any) => {
        setUsers(response.data.filter((report:any)=> report.fl_report))
    })
    }

    const handleSearch = () => {
        if (nomeParam == "" && emailParam == "" && usuarioParam == "" && (courseParam == undefined || courseParam == "")  ) {
            // ModalReportByStudent(tr);
            handleModalAproved();

        } else {
            setIsLoading(true)
            fetch()
        }

    }

    const handleChangePage = (value: any) => {
        setCurrentPageNumber(value-1)
    }

    const handleNextPage     = () => hasNext && setCurrentPageNumber(currentPageNumber+1)
    const handlePreviousPage = () => hasPrevious && setCurrentPageNumber(currentPageNumber-1)

    return <ContainerReportStudent>

        {isLoading && <Loader />}
        <BreadcrumbReportByStudent/>
        <PageTitle label="Report search by student" />

        {/* <h4>Select parameters above to visualize and send reports - <small>* Required fields!</small></h4> */}
        <Row className="mb-5">
            <Col md={12}>
                <Card className="users-table shadow">
                <header className="report-by-student-header mb-2">
                        <InputField  keystone="name"     className="header-options" label="Name"     value={nomeParam}    onChange={handleChangeFilter}/>
                        <InputField  keystone="email"    className="header-options" label="Email"    value={emailParam}   onChange={handleChangeFilter}/>
                        <InputField  keystone="username" className="header-options" label="Username" value={usuarioParam} onChange={handleChangeFilter}/>
                        <SelectField
                            keystone="course"
                            label="Course *"
                            value={courseParam}
                            onChange={handleChangeFilter}
                            values={courses} />
                            <ButtonStyle green label="Search Report"  icon="searchWhite" onClick={handleSearch} className={'btn-table-header'} size={'medium'} />
                    </header>
                </Card>
            </Col>
        </Row>

        <HeaderPagination className="mb-2">
            {users &&
                <div className="header-filter user-pagination">
                    <img
                        src={arrowPrevious}
                        className="pr-4"
                        style={{
                            cursor: hasPrevious ? 'pointer' : 'not-allowed',
                            filter: hasPrevious ? 'grayscale(0)' : 'grayscale(100%)'
                        }}
                        onClick={() => handlePreviousPage()}
                    />
                    <span>Showing <b>{startUsers+1}-{hasNext?endUsers:users.length}</b> of {users.length}</span>
                    <img
                        src={arrowNext}
                        className="pl-4"
                        style={{
                            cursor: hasNext ? 'pointer' : 'not-allowed',
                            filter: hasNext ? 'grayscale(0)' : 'grayscale(100%)'
                        }}
                        onClick={() => handleNextPage()}
                    />
                </div>}
        </HeaderPagination>

        {/* <h4>Or consult again your last searches</h4> */}
        <Row className="mb-5">
            <Col md={12}>
                <Card className="users-table shadow">
                    <Table data={usersCurrent} config={CONFIG_TABLE_REPORT_BY_STUDENT} />
                </Card>
            </Col>
        </Row>

        <HeaderPagination>
            <label className=" mr-3">Go to page</label>
            <SelectField
                keystone       = "selectpage"
                label          = "Select page"
                value          = {currentPageNumber+1}
                onChange       = {(keystone:string, value:any)=> handleChangePage(value)}
                values         = {Array.from(Array(amountPages).keys()).map((key) => ({id:key+1, value:key+1}))}
                hasOptionEmpty = {true}/>
        </HeaderPagination>

        <ModalReportByStudent isShow={modalAproved} onClose={handleModalAproved} />
    </ContainerReportStudent>

}


export default ReportClassGrade

import * as React from "react"
import { useState } from "react"
import { Row, Col, Card } from "reactstrap"

import styled from "styled-components"
import { ButtonStyle } from "YConnect/Components/Button"
import MessageEditor from "YConnect/PagesComponents/CMS/Editors/Components/Message.editor"

const RowStyled = styled(Row)`
    max-height: 600px;
    border-top: 1px solid rgb(0 0 0 / 21%);
    border-right: 1px solid rgb(0 0 0 / 21%);
    border-left: 1px solid rgb(0 0 0 / 21%);
    background-color: #f0f5f6;
    overflow: auto;
`

const ColStyled = styled(Col)`
    border-bottom: 1px solid rgb(0 0 0 / 21%);
    border-right: 1px solid rgb(0 0 0 / 21%);
    border-left: 1px solid rgb(0 0 0 / 21%);
    background-color: #f0f5f6;
`

const GetDialogueMessageByOrder = (question:QuestionType) => {
    if(question && question.Dialogues[0] && question.Dialogues[0].DialogueMessages){

        const {DialogueMessages} = question.Dialogues[0]
        const {newDialogueMessageByOrder} = DialogueMessages
        .reduce((acc:any, value) => {
            if(!acc.newDialogueMessageByOrder[value.OrderMessage]){
                acc.newDialogueMessageByOrder[value.OrderMessage] = []
            }
            if(value.Text === null){
                acc.countAnswerOrder++;
                acc.newDialogueMessageByOrder[value.OrderMessage].push({...value,  AnswerOrder:acc.countAnswerOrder})
            }else{
                acc.newDialogueMessageByOrder[value.OrderMessage].push(value)
            }
            return acc
        }, {newDialogueMessageByOrder:{}, countAnswerOrder:0})

        const nMessages = Object.keys(newDialogueMessageByOrder).length
        return nMessages > 0 ? newDialogueMessageByOrder : {1:[]}
    }else{
       return {1:[]}
    }
}

type MessagesEditorProps = {
    question: QuestionType
    onChange:any
}

const MessagesEditor = ({
    question,
    onChange
}:MessagesEditorProps) => {

    const [dialogueMessageByOrder, setDialogueMessageByOrder] = useState<any>(GetDialogueMessageByOrder(question))

    const handleNewMessage = () => {
        setDialogueMessageByOrder({
            ...dialogueMessageByOrder,
            [Object.keys(dialogueMessageByOrder).length+1]:[]
        })
    }

    const handleChangeMessage = (OrderMessage:number, chunkDialogueMessages:Array<DialogueMessageType>) => {
        const dialogue0 = question.Dialogues[0] ||  {DialogueMessages:[], DialogueAvatars:[]}
        const {DialogueMessages} = dialogue0
        const newValues = {
            ...question,
            Dialogues:[
                {
                    ...dialogue0,
                    DialogueMessages:[
                        ...DialogueMessages
                            ? DialogueMessages.filter((dialogueMessage) => dialogueMessage.OrderMessage != OrderMessage)
                            : [],
                        ...chunkDialogueMessages.map((dialogueMessage, index) => ({...dialogueMessage, OrderMessage:OrderMessage, OrderSubMessage:index+1}))
                    ]
                }
            ]
        }
        onChange(newValues)
    }

    return <Col md={12} className="mt-3">
                <h3 className="font-weight-bold">
                    Messages {dialogueMessageByOrder && Object.keys(dialogueMessageByOrder).length > 0 && ` (${ Object.keys(dialogueMessageByOrder).length})`}
                </h3>
                <RowStyled className="m-0 pt-4 px-4">
                    {
                        dialogueMessageByOrder
                        && Object.keys(dialogueMessageByOrder)
                        .map((orderMessage, index) =>
                        <Col md={12} className="mb-4" key={index}>
                            <Card className="p-3 shadow mb-0">
                              <MessageEditor 
                                order = {parseInt(orderMessage)}
                                messages={dialogueMessageByOrder[orderMessage]} 
                                onChange={(newAnswers:any) => 
                                    handleChangeMessage(parseInt(orderMessage), newAnswers)}/>
                            </Card>
                        </Col>)
                    }
                </RowStyled>
                <ColStyled md={12} className="p-3">
                    <Row className="justify-content-end">
                        <Col md="auto">
                            <ButtonStyle
                                green
                                label     = "New Message"
                                size      = "190"
                                className = "mr-2"
                                onClick   = {handleNewMessage}
                                icon      = "plusWhite"/>
                        </Col>
                    </Row>
                </ColStyled>
            </Col>
}

export default MessagesEditor
import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import FormContainer from "YConnect/Containers/Form.container"
import AnswersEditableTable from "YConnect/PagesComponents/CMS/Editors/Advanced.editor/Answer.editableTable"
import MediasEditableTable from "YConnect/PagesComponents/CMS/Editors/Advanced.editor/Medias.editableTable"
import DialogueEditor from "YConnect/PagesComponents/CMS/Editors/Advanced.editor/DialogueEditor"
import DEFAULT_TEMPLATE from "YConnect/PagesComponents/CMS/Templates/Default.template.json"

const RowStyled = styled(Row)`
    background: aliceblue;
`

type AdvancedEditorProps = {
    question:QuestionType
    onChangeValues:any
}

const AdvancedEditor = ({
    question,
    onChangeValues
}:AdvancedEditorProps)=>{

    const [values, setValues]                 = useState(question)    

    const handleChangeValues    = (values:any)                      => addValues(values)
    const handleChangeAnswer    = (values:Array<AnswerType>)        => addValues({Answers:values})
    const handleChangeMedias    = (values:Array<ExerciseMediaType>) => addValues({ExerciseMedias:values})
    const handleChangeDialogues = (values:Array<DialogueType>)      => addValues({Dialogues:values})

    const addValues = (newValues:any) => {
        setValues({...values, ...newValues})
        onChangeValues(values)
    }

    useEffect(() => {
        if(question){
            setValues(question)
        }
    }, [question])


    return  <RowStyled>
                <Col md={12}>
                    <FormContainer 
                        values   = {values}
                        onChange = {({valuesUpdated}:any) => handleChangeValues(valuesUpdated)}
                        template = {[...DEFAULT_TEMPLATE.map((field) => ({...field}))]}/>
                </Col>
                <Col md={12} className="mt-3">
                    <h3 className="font-weight-bold">Medias{values.ExerciseMedias.length > 0 && ` (${values.ExerciseMedias.length})`}</h3>
                    <MediasEditableTable 
                        values={values.ExerciseMedias} 
                        onChange={(values:Array<ExerciseMediaType>) => handleChangeMedias(values)}/>
                </Col>
                <Col md={12} className="mt-3">
                    <h3 className="font-weight-bold">Answers{values.Answers.length > 0 && ` (${values.Answers.length})`}</h3>
                    <AnswersEditableTable
                        values={values.Answers}
                        onChange={(values:Array<AnswerType>) => handleChangeAnswer(values)}/>
                </Col>
                <DialogueEditor
                    values={values.Dialogues}
                    onChange={(values:Array<DialogueType>) => handleChangeDialogues(values)}/>
            </RowStyled>
}
          
export default AdvancedEditor
import * as React from "react"
import * as arrowRight from "YConnect/Assets/svg/arrow-right-green.svg"
import getUnitName from "YConnect/Utils/CourseUnitName.util"

export const CONFIG_TABLE_REPORT_STUDENT: Array<any> = [
    {
        label: "Student",
        formatter: ({ nome_aluno, nome_curso }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span>{nome_aluno}</span>
                <span className="small text-secondary">{nome_curso}</span>
            </div>
        </div>
    },
    {
        label: "Concluded sections",
        formatter: ({ concludedFirstTry, concludedLastTry }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span>{concludedFirstTry}%</span>
                <span className="small text-secondary">1st attempt</span>
            </div>
            <div className="d-flex flex-column mr-4">
                <span>{concludedLastTry}%</span>
                <span className="small text-secondary">2nd attempt</span>
            </div>
        </div>
    },
    {
        label: "Average score",
        formatter: ({ scoreFirstAttempt, scoreSecondAttempt }: any) => <div className="d-flex">
            <div className="d-flex flex-column mr-4">
                <span>{scoreFirstAttempt || scoreFirstAttempt === 0 ? scoreFirstAttempt : "-"}</span>
                <span className="small text-secondary">1st attempt</span>
            </div>
            <div className="d-flex flex-column mr-4">
                <span>{scoreSecondAttempt || scoreSecondAttempt === 0 ? scoreSecondAttempt : "-"}</span>
                <span className="small text-secondary">2nd attempt</span>
            </div>
        </div>
    },
    {
        keystone: "finalScore",
        label: "Final Score"
    },
    {
        label: "Student full report",
        formatter: ({ id_aluno, id_curso, idCurso }: any) =>
            <a href={`#/reports/view-full-report/${id_curso ? id_curso : idCurso}/${id_aluno}`}>Consult
        <img src={arrowRight} alt="" className="pl-2" /> </a>
    }

]

const CONFIG_TABLE_REPORT_UNIT_STUDENT: Array<ColumnConfigType> = [
    {
        label: "Unit",
        formatter: ({ unidade, nomeUnidade, idCurso }: any) => getUnitName(parseInt(idCurso), unidade, nomeUnidade)
    }
].concat(CONFIG_TABLE_REPORT_STUDENT)

export default CONFIG_TABLE_REPORT_UNIT_STUDENT

import * as React from "react"
import { Col, Tooltip } from "reactstrap"

import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style"

import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"
import API from "YConnect/API"
import Loader from "YConnect/Components/Loader"
import AudioPlayer from "YConnect/Components/AudioPlayer"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"

import isEmpty from "lodash/isEmpty"
import forEach from "lodash/forEach"

import AudioPlayerColStyle from "YConnect/Fields/Exercises/TypeIn.field/AudioPlayerCol.style"
import ButtonCollapseStyle from "YConnect/Fields/Exercises/TypeIn.field/ButtonCollapse.style"
import DivCollapseStyle from "YConnect/Fields/Exercises/TypeIn.field/DivCollapse.style"
import InputEditableStyle from "YConnect/Fields/Exercises/TypeIn.field/InputEditable.style"
import ExerciseRowStyle from "YConnect/Fields/Exercises/TypeIn.field/ExerciseRow.style"
import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/TypeIn.field/ExerciseSubStatement.style"
import HrCollapseStyle from "YConnect/Fields/Exercises/TypeIn.field/HrCollapse.style"
import ImgStyle from "YConnect/Fields/Exercises/TypeIn.field/Img.style"
import formatText from "YConnect/Utils/FormatText.util";


type DialogueMessageType = {
    id_dialogo: Number
    id_mensagem: Number
    ordem_dialogo: Number
    ordem_mensagem: Number
    texto: String
}

type TypeInFieldStateType = {
    loading: boolean
    allDialogueMessage: Array<DialogueMessageType>
    respostasUsuario: any,
    rightAnswers: Array<Boolean>
    answerMaxSize: number
    collapse: boolean
    gabarito: any
    answersByOrder: any
    tooltipOpen: boolean
    isDisabledViewAnswers : boolean
    language: string
}


const SegmentDialogue = (dialogue: string) => {
    const dialogueSplitted = dialogue.split(" ")

    const {segmentedDialog} = dialogueSplitted
    .reduce(({segmentedDialog, inputIndex}, chunkDialogue)=>{

        if(chunkDialogue == "___"){
            return {
                    segmentedDialog:[
                        ...segmentedDialog,
                        { isInput: true, identificador: inputIndex++ }
                    ],
                    inputIndex:inputIndex++
                }
        }else if(chunkDialogue == "<br>"){
            return {
                    segmentedDialog:[
                        ...segmentedDialog,
                        { isInput: false, quebraLinha: true }
                    ],
                    inputIndex:inputIndex++
                }
        }

        return {
                segmentedDialog:[
                    ...segmentedDialog,
                    { isInput: false, texto: chunkDialogue}
                ], inputIndex:inputIndex++
            }
    }, {inputIndex:0, segmentedDialog:[]})

    return segmentedDialog
}

class TypeInOrderedField extends React.Component<ExerciseFieldPropsType, TypeInFieldStateType> {

    constructor(props: any) {
        super(props)

        if (this.props.innerRef) {
            this.props.innerRef.current.selectLanguage = this.selectLanguage
        }

        this.state = {
            loading                : true,
            tooltipOpen            : false,
            allDialogueMessage     :  [],
            respostasUsuario       : [],
            rightAnswers           : [],
            gabarito               : this.gabarito(),
            answersByOrder         : this.getAnswersByOrder(),
            answerMaxSize          : this.answerMaxSize(),
            collapse               : false,
            language: this.props.translated,
        }

        this.handleChange2 = this.handleChange2.bind(this)
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this)

    }

    componentDidMount() {
        this.fetchDialogueExercise()

        if (this.props.attempts > 0)
            this.fetchUserAnswer()
    }

    selectLanguage = (language: string) => {
        this.setState({...this.state, language})
    }

    async fetchDialogueExercise() {
        try {

            const {
                props: {
                    exercise: {
                        id_exercicio,
                        ordem
                    }
                }
            } = this

            const {
                data: { dialogo_Mensagem_Yconnect }
            } = await API.Exercicio.getDialogo({
                idExercicio: id_exercicio,
                tela: ordem
            }, getConfig())

            this.setState({
                allDialogueMessage: [...dialogo_Mensagem_Yconnect],
                loading: false
            })
        } catch (error) { }
    }

    async fetchUserAnswer() {
        this.setState({ loading: true })

        try {
            const response = await API.Exercicio.getRespostaUsuario({
                userId: this.props.studentId,
                exer: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem,
                tentativa: 'U',
                idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
            }, getConfig())

            let respostasUsuario = []
            try {
                respostasUsuario = JSON.parse(response.data.resposta) || []

                const normalize = (arr: Array<any>) =>
                    arr.reduce((acc, obj) => {

                        Object.keys(obj)
                            .forEach((property) => {
                                acc = [...acc, { 0: obj[property] }]
                            })
                        return acc
                    }, [])

                respostasUsuario = normalize(respostasUsuario)
            } catch { }

            this.setState({
                loading: false,
                respostasUsuario
            }, () => this.bringRightAnswers2())
        } catch (error) { }
    }


    handleChange2(event: any) {

        const {
            props: {
                id,
                onChange
            },
            state: {
                respostasUsuario,
                rightAnswers
            }
        } = this



        const {
            target: { value, attributes }
        } = event

        const indexAnswer = parseInt(attributes["data-index-answer"].value)

        const { respostas_Exercicio_Yconnect } = this.props.exercise

        const nAnswer = Object.keys(respostas_Exercicio_Yconnect.reduce((acc: any, value: any) => {
            acc[value.ordem] = undefined
            return acc
        }, {})).length

        const newRightAnswers =
            (rightAnswers.length === nAnswer
                ? rightAnswers
                : Array.from(Array(nAnswer).keys()).map(() => null)
            )
                .reduce((acc, value, index) => {

                    if (indexAnswer === index) {
                        return [...acc, null]
                    }

                    return [...acc, value]
                }, [])

        const newUserAnswers = (respostasUsuario.length === nAnswer
            ? respostasUsuario
            : Array.from(Array(nAnswer).keys()).map(() => ({}))
        )
            .reduce((acc: Array<any>, answer: string, index: number) => {

                if (index === indexAnswer) {
                    return [...acc, { 0: value }]
                }
                return [...acc, answer]
            }, [])

        this.setState({
            respostasUsuario: newUserAnswers,
            rightAnswers: newRightAnswers
        })

        const score = this.calculaScore(newUserAnswers)
        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: newUserAnswers
        })

    }

    gabarito() {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const gabarito = []
        respostas_Exercicio_Yconnect.map(resposta => {
            const ordem = resposta.ordem - 1
            if (!gabarito[ordem])
                gabarito[ordem] = []

            gabarito[ordem].push(resposta)
        })
        return gabarito
    }

    getAnswersByOrder = () => {

        const {
            props: {
                exercise: { respostas_Exercicio_Yconnect }
            }
        } = this

        const answersByOrder = respostas_Exercicio_Yconnect
            .reduce((acc: any, value: any) => {
                if (!acc[value.ordem]) {
                    acc[value.ordem] = []
                }
                acc[value.ordem].push(value)
                return acc
            }, {})
        return answersByOrder
    }

    embaralhaOpRespostas(respostas: any) {
        let retorno = respostas;
        if (respostas) {
            // Embaralha as respostas, e armazena nas opções de respostas
            for (var i = 0; i < respostas.length; i++) { // gera números de 0 ao tamanho do vetor de respostas
                var r1 = Math.floor(Math.random() * respostas.length);
                var r2 = Math.floor(Math.random() * respostas.length);
                var aux = retorno[r2]
                retorno[r2] = retorno[r1];
                retorno[r1] = aux;
            }
        }
        return retorno;
    }



    getTotalInputUntilActualOrder(order: number){
        const { allDialogueMessage } = this.state

        return allDialogueMessage
        .reduce((totalInput:number, dialogueMessage:any) => {

            if(dialogueMessage.ordem_dialogo <= order){
                const nInputDialogueMessage =
                dialogueMessage
                .texto
                .split(' ')
                .filter((text: string) => text === '___').length

                return totalInput + nInputDialogueMessage
            }

            return totalInput
        }, 0)
    }

    answerMaxSize() {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        let maxAnswerSize = 0;
        respostas_Exercicio_Yconnect.map((item: any, i: number) => {
            if (item.resposta.length > maxAnswerSize) {
                maxAnswerSize = item.resposta.length;
            }
        })
        return maxAnswerSize += 10;
    }


    isAnswerCorrect2(order: number, answer: string): Boolean {
        const {
            state: { answersByOrder }
        } = this

        const formatter = (str: string) => str.toLowerCase().trim().replace(/\s+/g, ' ')
        const correct = !!(
            answer
            && answersByOrder[order]
                .find(({ resposta }: any) => formatter(resposta) === formatter(answer.replace(/([\u2018\u2019])/g, '\'')))
        )
        return correct
    }

    //TODO Legado
    isAnswerCorrect(gabarito, resposta): Boolean {
        const formataLacuna = (lacuna: string) => {
            return lacuna.toLowerCase().trim().replace(/\s+/g, ' ')
        }
        let correct = false
        if (resposta) {
            var respostaUsuario = formataLacuna(resposta.replace(/([\u2018\u2019])/g, '\''))

            forEach(gabarito, item => {
                if (respostaUsuario === formataLacuna(item.resposta)) correct = true
            })
        }
        return correct
    }

    bringRightAnswers2() {
        const {
            state: { respostasUsuario }
        } = this
        const getArrayUserAnswers = (userAnswers: any) => {
            const nUserAnswers = Object.keys(userAnswers).length
            return Array
                .from(Array(nUserAnswers).keys())
                .map((index2: number) => userAnswers[index2])
        }

        if (respostasUsuario.length !== 0) {

            const newRightAnswers = respostasUsuario.reduce((newRightAnswers: Array<any>, userAnswers: any, index: number) => {
                getArrayUserAnswers(userAnswers)
                    .forEach((userAnswer: any) => {
                        newRightAnswers[index] = this.isAnswerCorrect2(index + 1, userAnswer)
                    })
                return newRightAnswers
            }, [])

            const newUserAnswers = respostasUsuario.map((userAnswers: any) => {
                return getArrayUserAnswers(userAnswers)
                    .reduce((acc: any, userAnswer: any, index: number) => {
                        return { ...acc, [index]: userAnswer.trim().replace(/\s+/g, ' ') }
                    }, {})

            })
            this.setState({
                rightAnswers: newRightAnswers,
                respostasUsuario: newUserAnswers
            })
        }
    }

    calculaScore(respostasUsuario: any) {
        const { gabarito } = this.state
        let score = 0
        let ordem = 0

        respostasUsuario.map((item) => {
            const qntsLacunas = Object.keys(item).length

            for (var lacuna = 0; lacuna < qntsLacunas; lacuna++) {
                if (this.isAnswerCorrect(gabarito[ordem], item[lacuna])) {
                    score++
                }
                ordem++;
            }
        })

        return { qntd_acertos: score, qntd_respostas: gabarito.length };
    }


    showCorrectAnswers() {
        const {
            props: {
                exercise: { respostas_Exercicio_Yconnect }
            },
            state: { allDialogueMessage }
        } = this

        const getDialogueByOrder = (order: number) =>
            allDialogueMessage.find(({ ordem_dialogo }) => ordem_dialogo === order)

        const getNInputByDialogueMessage = (dialogueMessage: DialogueMessageType) =>
            (dialogueMessage.texto.split(' ').filter((text: string) => text === '___')).length

        const getAllAnswersByOrder = (order: number) =>
            respostas_Exercicio_Yconnect.filter((answer: any) => answer.ordem === order)

        const nDialogue = allDialogueMessage
        //.filter(({texto}:any) => texto.indexOf("___") > -1)
        .length
        const { newUserAnswers, newRightAnswers } =
            Array.from(Array(nDialogue).keys())
                .reduce(({ offset, newUserAnswers, newRightAnswers }: any, index: number) => {
                    const order = index + 1
                    const dialogueMessage = getDialogueByOrder(order)
                    if (dialogueMessage) {
                        const nInput = getNInputByDialogueMessage(dialogueMessage)
                        Array.from(Array(nInput).keys())
                            .forEach(() => {
                                const answers = getAllAnswersByOrder(offset + 1)
                                newUserAnswers[offset] = answers.reduce((userAnswers: any, { resposta }: any, index: number) => {
                                    return { ...userAnswers, [index]: resposta }
                                }, {})
                                newRightAnswers[offset] = true
                                offset++
                            })
                    }
                    return { offset, newUserAnswers, newRightAnswers }
                }, { offset: 0, newUserAnswers: [], newRightAnswers: [] })


        const score = this.calculaScore(newUserAnswers)
        this.props.onChange(this.props.id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: newUserAnswers
        })

        this.setState({
            respostasUsuario: newUserAnswers,
            rightAnswers: newRightAnswers
        })
    }

    getValue = (
            totalInputUntilActualOrder: number,
            nInputDialogueMessage:number,
            indexInput: number) => {
        const {
            state: {
                respostasUsuario
            }
        } = this

        const fisrtIndexUserAnswer = totalInputUntilActualOrder-nInputDialogueMessage

        return Object
            .values((respostasUsuario[fisrtIndexUserAnswer + indexInput] || {}))
            .reduce((value, answer) => value != ''
                ? value + " \n " + answer
                : answer,
        '')
    }


    render() {
        let {
            props: {
                exercise,
                attempts,
                isStudent,
                isDisabledViewAnswers
            },
            state: {
                respostasUsuario,
                loading,
                rightAnswers,
                allDialogueMessage,
                collapse,
                answerMaxSize
            },
            handleChange2
        } = this

        if (loading) {
            return <Loader />
        }

        const midiaYConnect = exercise.midia_Yconnect.length > 0 ? exercise.midia_Yconnect[0]
            : (exercise.exercicio_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect && exercise.exercicio_Yconnect.midia_Yconnect.length > 0) ? exercise.exercicio_Yconnect.midia_Yconnect[0]
                : {}

        const hasAudio = (midiaYConnect || {}).tipo_midia === 'audio'

        return (
            <>
                {
                    !isDisabledViewAnswers
                     && <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                   {(attempts >= 2 || !isStudent) ?
                        <HeaderButtonStyle
                            className="float-right"
                            label="View Answers"
                            onClick={this.showCorrectAnswers}
                            icon="checkedGreen2" />
                        :
                        <>
                            <HeaderButtonStyle
                                id="completeResultToolTip"
                                className="float-right disabled view-answers"
                                label="View Answers"
                                icon="checkedGreen2" />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="completeResultToolTip" toggle={() => this.setState(state => ({ tooltipOpen: !state.tooltipOpen }))}>
                                {
                                    this.state.language == "br" &&
                                    <span>Oops, você só poderá saber a resposta correta depois de verificar suas próprias respostas na primeira e segunda tentativa.</span>
                                }

                                {
                                    this.state.language == "en" &&
                                    <span>Oops, you'll only be able to know the
                                correct answers once you've checked your own
                                answers on the 1st and 2nd attempts.</span>
                                }
                            </Tooltip>
                        </>
                    }
                </div>}
                {
                    exercise.ajuda || exercise.texto || exercise.conteudo_exercicio || hasAudio ?
                        <ExerciseSubStatementStyle className="w-100 pr-5 pl-5">
                            {/** Ajuda */}
                            <div className="text-center">
                                <h2 dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.ajuda)) }}></h2>
                            </div>

                            {/** texto */}
                            {exercise.texto ?
                                <ExerciseRowStyle className="exercise-dialogue">
                                    <DivCollapseStyle toggler="#toggler" dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.texto)) }} />
                                    <HrCollapseStyle />
                                    <ButtonCollapseStyle color="link" id="toggler" size="md" onClick={() => this.setState(state => ({ collapse: !state.collapse }))}>
                                        {collapse ? '- Collapse text' : '+ Expand text'}
                                    </ButtonCollapseStyle>
                                </ExerciseRowStyle>
                                :
                                <div className="text-center">
                                    <p dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.conteudo_exercicio)) }}></p>
                                </div>
                            }

                            {/** audio */}
                            {hasAudio ?
                                <AudioPlayerColStyle className=" mb-3" xs="12" md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }}>
                                    <AudioPlayer
                                        src={getStaticUrl(midiaYConnect.url)}
                                    />
                                </AudioPlayerColStyle>
                                :
                                ''
                            }
                        </ExerciseSubStatementStyle>
                        :
                        ''
                }

                {/** exercicios */}
                <div className="pr-3 pl-3 pr-lg-5 pl-lg-5 pt-5">
                    {
                        allDialogueMessage
                            .map((dialogueMessage, key) => {

                                const imgUrl = (exercise.midia_Yconnect[dialogueMessage.ordem_dialogo - 1] || {}).url;
                                const hasImg = (exercise.midia_Yconnect[dialogueMessage.ordem_dialogo - 1] || {}).tipo_midia === 'imagem' && !isEmpty(imgUrl);

                                const segmentedDialog = SegmentDialogue(dialogueMessage.texto)

                                const totalInputUntilActualOrder = this
                                .getTotalInputUntilActualOrder(dialogueMessage.ordem_dialogo)

                                const nInput = segmentedDialog.filter(({ isInput }: any) => isInput).length

                                const nInputDialogueMessage =
                                dialogueMessage
                                .texto
                                .split(' ')
                                .filter((text: string) => text === '___')
                                .length

                                const firstAnswerIndexPerDialogue = totalInputUntilActualOrder - nInput

                                const isRightOrWrong = () => {

                                    if (nInputDialogueMessage > 0) {
                                        return Array
                                            .from(Array(nInputDialogueMessage).keys())
                                            .reduce((acc, inputIndex) => {
                                                const indexAnswer = firstAnswerIndexPerDialogue + inputIndex
                                                if (acc === "right") {

                                                    if (rightAnswers[indexAnswer] === true)
                                                        return "right"

                                                    if (rightAnswers[indexAnswer] === false)
                                                        return "wrong"

                                                    return ""
                                                }
                                                return acc
                                            }, "right")
                                    } else {
                                        return ""
                                    }
                                }

                                return <ExerciseRowStyle
                                    className={`exercise-dialogue borderBottom ${hasImg ? 'row' : ''} ${isRightOrWrong()}`}
                                    key={`dialoguePhrase-${key}`}>
                                    {
                                        hasImg
                                        && <Col xs="12" sm="5" md="4" lg="4" xl="4">
                                            <figure>
                                                <ImgStyle src={getStaticUrl(imgUrl)} alt="" />
                                            </figure>
                                        </Col>
                                    }

                                    <Col
                                        xs="12"
                                        sm={`${hasImg ? 7 : 12}`} md={`${hasImg ? 8 : 12}`}
                                        lg={`${hasImg ? 8 : 12}`} xl={`${hasImg ? 8 : 12}`}
                                        className="d-flex flex-row flex-wrap align-self-start"
                                        style={{ lineHeight: "2.5em" }}>
                                        {
                                            segmentedDialog.map((trecho: any, key: any) => {


                                                if (trecho.quebraLinha) {
                                                    return <span key={key} className="breakLine" />
                                                }

                                                if (!trecho.isInput) {
                                                    return <span key={key} dangerouslySetInnerHTML={{ __html: `${trecho.texto}${'&nbsp;'}` }}></span>
                                                }

                                                const value = this
                                                .getValue(totalInputUntilActualOrder,
                                                    nInputDialogueMessage,
                                                    trecho.identificador)
                                                const maxWidth: number = value ? value.length > 30 ? 30 : value.length : 5;

                                                const rows: number = (rightAnswers[totalInputUntilActualOrder] || (value && value.length > maxWidth))
                                                    && respostasUsuario[totalInputUntilActualOrder - 1] ?
                                                    Object.values(respostasUsuario[totalInputUntilActualOrder - 1]).length
                                                    : 1;

                                                return <InputEditableStyle
                                                            key                      = {key}
                                                            rows                     = {rows}
                                                            type                     = "text"
                                                            maxLength                = {answerMaxSize}
                                                            data-index-answer        = {firstAnswerIndexPerDialogue + trecho.identificador}
                                                            onChange                 = {handleChange2}
                                                            style                    = {{ width: (maxWidth + 4) + "ch", overflowY: 'hidden', maxHeight: ((rows != 0 ? rows : 1) * 2) + 'rem' }}
                                                            value                    = {value} />
                                            })
                                        }
                                    </Col>
                                </ExerciseRowStyle>
                            })}
                </div>
            </>
        )
    }
}

export default TypeInOrderedField

import * as React             from "react"
import {useEffect}            from "react"
import {Card}                 from "reactstrap"
import { connect }            from "react-redux"
import { bindActionCreators } from "redux"

import PanelCrud              from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"
import Filters                   from "YConnect/Components/Filters2"
import useSelectData             from "YConnect/Hooks/useSelectData"
import useFilterState            from "YConnect/Hooks/useFilterState"
import QueryParamsActionsCreator from "YConnect/Actions/QueryParams.actionsCreator"

import * as CourseResource from "YConnect/ResourcesBackEnd/Course.resources"
import * as UnitResource from "YConnect/ResourcesBackEnd/Unit.resources"

import {
    GetAllByCourseId, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/Unit.resources"

import CONFIG_TABLE_UNIT from "YConnect/PagesComponents/ControlPanel/Tables/Unit.table.config"
import UNIT_TEMPLATE from "YConnect/PagesComponents/ControlPanel/Templates/Unit.template"

const UnitsPanel = ({
    QueryParams,
    AddQueryParam
}:any) =>{

    const  {
        selectData,
        AddSelectData
    } = useSelectData()

    const [
        {
            courseIdSelected
        },
        setIdSelected
    ] = useFilterState()

    const [
      {
          typeSelected
      },
      setTypeSelected
    ] = useFilterState()

    useEffect(()=>{
        updateSelectData()
    }, [])

    useEffect(()=>{
        if(courseIdSelected){
            AddQueryParam("course", courseIdSelected)
        }  
    }, [courseIdSelected])

    useEffect(()=>{
      if(typeSelected){
          AddQueryParam("type", typeSelected)
      }  
  }, [typeSelected])

    useEffect(()=>{
        if(QueryParams.course){
            setIdSelected({courseIdSelected:QueryParams.course})
        }
    }, [QueryParams.course])

    useEffect(()=>{
      if(QueryParams.type){
          setTypeSelected({courseIdSelected:QueryParams.type})
      }
  }, [QueryParams.type])

    const updateSelectData = () => {
        CourseResource
        .GetAll()
        .then((response:any) => {
            const {data} = response
            AddSelectData("CourseId", "Id", "Title", data)
        })
        
        UnitResource
        .GetUnitTypes()
        .then((response:any) => {
            const {data} = response
            AddSelectData("UnitType", "Value", "Key", data)
        })
    }
    
    const handleChangeFilter = (keystone:string, value:any) => setIdSelected({[keystone]:value})

    const handleChangeTypeFilter = (keystone:string, value:any) => setTypeSelected({[keystone]:value})

    return <>
            <Card className="px-4 pt-4 pb-0 mb-3">
              { (courseIdSelected == undefined || courseIdSelected == "") &&
                <Filters
                    filterProps={[
                        {
                            keystone : "courseIdSelected",
                            label    : "Course",
                            value    : courseIdSelected,
                            onChange : handleChangeFilter,
                            values   : selectData ? selectData["CourseId"] : []
                        }
                    ]}/>
              }
              { courseIdSelected != undefined && courseIdSelected != "" &&
                <Filters
                  filterProps={[
                    {
                      keystone : "courseIdSelected",
                      label    : "Course",
                      value    : courseIdSelected,
                      onChange : handleChangeFilter,
                      values   : selectData ? selectData["CourseId"] : []
                    },
                    {
                      keystone : "typeSelected",
                      label    : "Type",
                      value    : typeSelected,
                      onChange : handleChangeTypeFilter,
                      values   : selectData ? selectData["UnitType"] : []
                    }
                ]}/>
              }
            </Card>
            {
            typeSelected 
            && <PanelCrud
                idName            = "unitId"
                labelButtonCreate = "Create Unit"
                labelForm         = "Unit"
                template          = {UNIT_TEMPLATE}
                configTable       = {CONFIG_TABLE_UNIT}
                selectData        = {selectData}
                GetAll            = {GetAllByCourseId}
                paramGetAll       = {courseIdSelected}
                paramType         = {typeSelected}
                GetById           = {GetById}
                Create            = {Create}
                paramCreate       = {{courseId:courseIdSelected, CourseId:courseIdSelected}}
                UpdateById        = {UpdateById}
                DeleteById        = {DeleteById} />
            }
            </>
}

const mapDispatchToProps = (dispatch:any) => bindActionCreators({
	AddQueryParam : QueryParamsActionsCreator.AddQueryParam
}, dispatch)

const mapStateToProps = ({QueryParams}:any) => ({
	QueryParams
})

export default connect(mapStateToProps, mapDispatchToProps)(UnitsPanel)
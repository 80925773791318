const CONFIG_TABLE_TEMPLATE:Array<ColumnConfigType> = [
    {
        keystone : "Name",
        label    : "Name"
    },
    {
        keystone : "Description",
        label    : "Description"
    }
]

export default CONFIG_TABLE_TEMPLATE
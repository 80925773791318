const CONFIG_TABLE_UNIT:Array<ColumnConfigType> = [
    {
        keystone : "Name",
        label    : "Name"
    },
    {
        keystone : "Order",
        label    : "Order"
    },
    {
        keystone : "UnitType",
        label    : "UnitType",
        selectDataName: "UnitType"
    },
    {
        keystone : "CourseId",
        label    : "Course",
        selectDataName : "CourseId"
    }
]

export default CONFIG_TABLE_UNIT
import styled from "styled-components"

const HeaderStyle = styled.div `
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .tabscore-title {
        color: #565656;
        font-size: 20px;
        font-weight: 600;
    }
`

export default HeaderStyle
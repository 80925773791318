
import styled from "styled-components"

const MessageLine = styled.div`
align-items: flex-start;
border-bottom: 1px solid ${props => props.theme.main.line};
display: flex;
justify-content: space-around;
padding: 8px;
transition: ease 0.3s background-color;

&.active {
    background-color:  ${props => props.theme.main.hoverMessage};
    border-bottom-color: ${props => props.theme.main.selectedLine};
}

.message-author {
    max-width: 100px;
    overflow: hidden !important;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media screen and (min-width: 1024px) {
        max-width: 240px;
    }
}

&:hover {
    background-color:  ${props => props.theme.main.hoverMessage};
}

&.newMessage {
    border-bottom-color: ${props => props.theme.main.mainPurple};

    .message-author,
    .message-body {
        font-weight: bold;
        color: ${props => props.theme.main.title} !important;
        text-decoration: none !important;
    }
}



.message-header {
    @media screen and (min-width: 1024px) {
        width: 240px;
    }

    .message-photo {
        border-radius: 50%;
        height: 45px;
        margin: 0 16px 0 0;
        width: 45px;
    }

    .message-notification {
        margin-left: -31px;
        margin-top: 25px;
        height: 20px;
        position: absolute;
        width: 20px;
    }
}

.message-body {
    font-size: small;
    padding: 0 16px 0 0;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
}

.message-subject {
    color: ${props => props.theme.main.mutedTextColor};
    font-size: 0.75rem;
    overflow: hidden !important;
    text-decoration: none;
    text-overflow: ellipsis;
    @media screen and (min-width: 1024px) {
        font-size: 1rem;
        white-space: nowrap;
        width: 20%;
    }
}

.message-time {
    color: ${props => props.theme.main.mutedTextColor};
    font-size: 0.75rem;
    text-align: right;
    @media screen and (min-width: 1024px) {
        width: 15%;
    }
}

.email-photo {
    min-width: 85px;
    border-radius: 50%;
    height: 45px;
    margin: 0 16px 0 0;
    width: 45px;
}

.mobile-message-body {
    font-size: small;
    padding: 0 16px 0 0;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 40%;

}


`

export { MessageLine }

import * as React              from "react"
import { Row, Col }            from "reactstrap"

import TabReportStyle     from "YConnect/PagesComponents/PrePostReport/Teacher.report/TabReport.style"

import ClassTeacher      from "YConnect/PagesComponents/PrePostReport/Teacher.components/ClassTeacher"
import BestStudentScore  from "YConnect/PagesComponents/PrePostReport/Teacher.components/BestStudentScore"
import StudentScoreTable from "YConnect/PagesComponents/PrePostReport/Teacher.components/StudentScoreTable"


const TabReport = ({
    isPost,
    teacherInfo, 
    bestStudents,
    bestStudents2,
    semesterIdSelected,
    establishmentIdSelected
}: any) => 
    <TabReportStyle>
        <header>
            <ClassTeacher teacherInfo={teacherInfo}/>
            <BestStudentScore 
                bestStudent={
                    bestStudents 
                    ? bestStudents.filter((student: any) => student.flIsPre === (isPost?false:true))[0] || {}
                    : undefined 
            }/>
        </header>
    
        <hr className="mt-5 mb-5" />

        {
            semesterIdSelected
            && establishmentIdSelected
            && semesterIdSelected !== ""
            && establishmentIdSelected !== ""
            && <Row>
                    <Col xs={12} lg={6}>
                        <StudentScoreTable 
                            title       = "Best ranked students" 
                            isBest      = {true} 
                            students    = {bestStudents2 && bestStudents2.highest && bestStudents2.highest.filter((student: any) => student.flIsPre === (isPost?false:true))}
                            isOrderable = {(isPost?true:false)}/>
                    </Col>
                    <Col xs={12} lg={6}>
                        <StudentScoreTable 
                            title       = "Lowest ranked students" 
                            isBest      = {false} 
                            students    = {bestStudents2 && bestStudents2.lowest && bestStudents2.lowest.filter((student: any) => student.flIsPre === (isPost?false:true))}
                            isOrderable = {(isPost?true:false)}/>
                    </Col>
                </Row>
        }

    </TabReportStyle>

export default TabReport
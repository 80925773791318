import * as React from "react"
import { useContext } from "react"
import UserContext from "YConnect/User.context"
import Breadcrumb from "YConnect/Components/Breadcrumb"
import bc from "YConnect/Enums/Breadcrumb.enums"

export const addBreadcrumbData = (objs: Array<any>) => {
    const page = JSON.parse(localStorage.getItem('breadcrumb')) || {}

    objs.forEach(obj => {
        const curentPage = obj['page']
        if (curentPage) {
            const data = obj['data']
            page[curentPage] = data
            localStorage.setItem('breadcrumb', JSON.stringify(page))
        }
    })
}

export const removeBreadcrumbData = (objs: Array<any>) => {
    const page = JSON.parse(localStorage.getItem('breadcrumb')) || {}

    objs.forEach(obj => {
        const curentPage = obj['page']
        if (curentPage) {
            page[curentPage] = null
            localStorage.setItem('breadcrumb', JSON.stringify(page))
        }
    })
}

const getBreadCrumbData = (page: string) => {
    return JSON.parse(localStorage.getItem('breadcrumb') || '{}')[page]
}

const blank = [{ path: '', label: '' },{ path: '', label: '' }]

//about
export const BreadCrumbAbout = (props: any) =>
    <Breadcrumb links={[
        { ...bc.Homepage, path: !props.isFirstLogin ? bc.Homepage.path : undefined },
        bc.About
    ]} />

//my-profile
export const BreadCrumbProfile = (props: any) => {
    const { primeiroAcessoGameficacao: p }: any = useContext(UserContext)
    if (props.isMyProfile) {
        return <Breadcrumb links={[
            { ...bc.Homepage, path: p && p.dt_primeiro_acesso ? bc.Homepage.path : undefined },
            bc.Profile,
        ]} />
    }

    return <Breadcrumb links={[
        bc.Homepage,
        bc.ManagerUser,
        bc.EditUser
    ]} />
}

//manage-users
export const BreadcrumbManagerUser = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.ManagerUser,
    ]} />

//settings
export const BreadcrumbSettings = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Settings
    ]} />

//upload-card
export const BreadcrumbCard = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Settings,
        bc.Card
    ]} />

//travel-world
export const BreadcrumbTravelWorld = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.TravelWorld,
    ]}
    />

//countries/:id
export const BreadcrumbCountry = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.TravelWorld,
        { path: '', label: props.country },
    ]}
    />

//courses
export const BreadcrumbCourses = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Courses
    ]}
    />

//explore
export const BreadcrumbExplore = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Settings,
        bc.Explore,
    ]}
    />

//my-message
export const BreadcrumbMessage = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Message,
    ]}
    />

//online-teacher
export const BreadcrumbOnlineTearch = (props: any) =>
    <Breadcrumb links={[
        { ...bc.Homepage, path: !props.isFirstLogin ? bc.Homepage.path : undefined },
        { path: "/online-teacher", label: "Online Teacher" },
    ]}
    />

//online-teacher
export const BreadcrumbTechnicalSupport = (props: any) =>
<Breadcrumb links={[
    { ...bc.Homepage },
    { path: "/technical-suppport", label: "Technical Support" },
]}
/>

//pre-post-report
export const BreadcrumbPrePost = (props: any) => {
    let links = [
        bc.Homepage, ,
        { path: `/courses/${props.courseInfo.id_curso}/view-book`, label: props.courseInfo.titulo_curso },
        { path: '', label: 'Pre/Post test evaluation' }
    ]
    if (props.isStudent) {
        if (props.aluno_curso && props.aluno_curso.fl_is_pre) {
            links.splice(3, 1, { path: '', label: 'Pre test evaluation' })
        } else {
            links.splice(3, 1, { path: '', label: 'Post test evaluation' })
        }
    }

    return <Breadcrumb links={links} />
}

//pre-post
export const BreadcrumbPrePostReport = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.ReportsTeacher,
        bc.PrePost,
    ]}
    />

//my-message
export const BreadcrumbReadMessage = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Message,
        { path: '', label: props.message.assunto ? props.message.assunto : '' }
    ]}
    />

//
export const BreadcrumbReintegrateStudent = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.ManagerUser,
        bc.Reintegrate,
    ]}
    />

export const BreadcrumbCoursesControl = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.ManagerUser,
        bc.CoursesControl,
    ]}
    />

//
export const BreadcrumbReportClassGrade = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.ReportsTeacher,
        bc.ReportClassGrades
    ]
    }
    />

export const BreadcrumbReportByStudent = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.ReportsTeacher,
        bc.ReportByStudent
    ]
    }
    />

//reports
export const BreadcrumbReportStudent = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Reports
    ]}
    />

//reports-teacher
export const BreadcrumbReportTeacher = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Reports
    ]}
    />

    //reports-teacher
export const BreadcrumbCMS = (props: any) =>
<Breadcrumb links={[
    bc.Homepage,
    bc.CMS
]}
/>

export const BreadcrumbCMSExercise = (props: any) =>
<Breadcrumb links={[
    bc.Homepage,
    bc.CMS,
    bc.CmsExercise
]}
/>

export const BreadcrumbCMSUnits = (props: any) =>
<Breadcrumb links={[
    bc.Homepage,
    bc.CMS,
    bc.CmsUnits
]}
/>

export const BreadcrumbCMSSections = (props: any) =>
<Breadcrumb links={[
    bc.Homepage,
    bc.CMS,
    bc.CmsSections
]}
/>

//support
export const BreadcrumbSupport = (props: any) =>
    <Breadcrumb links={[
        { ...bc.Homepage, path: !props.isFirstLogin ? bc.Homepage.path : undefined },
        bc.Support
    ]}
    />

///unlock-score/:idUser
export const BreadcrumbUnlockScore = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.ManagerUser,
        { path: '', label: bc.Unlock.label },
    ]}
    />

//courses/:idCurso/view-book
export const BreadcrumbViewBook = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.Courses,
        { path: '', label: props.currentCourse },
    ]}
    />

//reports/view-report-student/:idCurso
export const BreadcrumbViewReportStudent = (props: any) =>
    <Breadcrumb links={props.reportAluno ? [
        bc.Homepage,
        bc.Reports,
        {
            path: `/reports/view-report-student/${props.idCurso}`,
            label: `View Report: ${props.reportAluno.course}`
        },
        //bc.StudentReport,
    ] : blank}
    />

//reports/view-report-teacher/:idEst/:idCurso/:idGrupo
export const BreadcrumbViewReportTeacher = (props: any) =>
    <Breadcrumb links={props.currentSearch ? [
        bc.Homepage,
        bc.ReportsTeacher,
        bc.ReportClassGrades,
        {
            path: `/reports/view-report-teacher/${props.idEstab}/${props.idCurso}/${props.idGrupo}`,
            label: `View Reports: ${props.currentSearch.establishment} / ${props.currentSearch.course} / ${props.currentSearch.class}`
        }
    ] : blank
    }
    />

//reports/view-full-report/:idCurso/:idUser
export const BreadcrumbViewFullReport = (props: any) => {
    const current = getBreadCrumbData('ViewReportTeacherPage')
    const rb = getBreadCrumbData('ViewReportResourceBookPage')

    const links = [
        bc.Homepage,
        bc.ReportsTeacher,
        bc.StudentReport
    ]

    if (current && current.currentSearch) {
        links.splice(2, 0,
            bc.ReportClassGrades,
            {
                path: `/reports/view-report-teacher/${current.idEstab}/${current.idCurso}/${current.idGrupo}/?current=true`,
                label: `View Reports: ${current.currentSearch.establishment} / ${current.currentSearch.course} / ${current.currentSearch.class}`
            }
        );
    } else if (rb && rb.currentSearch) {
        links.splice(2, 0,
            bc.ReportResourceBook,
            {
                path: `/reports/view-report-resource-book/${rb.idEstab}/${rb.idCurso}/${rb.idGrupo}`,
                label: `View Reports: ${rb.currentSearch.establishment} / ${rb.currentSearch.course} / ${rb.currentSearch.class}`
            }
        );
    }

    return <Breadcrumb links={links} />
}

//reports/view-report-final-test/:idCurso/score/:idProvaFinal ---Student View
export const BreadcrumbViewReportFinalTestStudent = (props: any) =>
    <Breadcrumb links={props.reportFinal ? [
        bc.Homepage,
        bc.Reports,
        {
            path: `/reports/view-report-student/${props.reportFinal.provaFinal.id_curso}`,
            label: `View Report: ${props.reportFinal.curso}`
        },
        {
            path: `/reports/view-report-final-test/${props.reportFinal.provaFinal.id_curso}/student/`
                 +`${props.reportFinal.provaFinal.id_aluno}/score/${props.reportFinal.provaFinal.id_prova_final}`,
            label: `Final Test Report`
        },
    ] : blank}
    />

//reports/view-report-final-test/:idCurso/score/:idProvaFinal ---Teacher View
export const BreadcrumbViewReportFinalTestTeacher = (props: any) => {

    const current = getBreadCrumbData('ViewReportTeacherPage')

    const links = [
        bc.Homepage,
        bc.ReportsTeacher,
        bc.ReportClassGrades
    ]

    if (current && current.currentSearch) {
        links.push(
            {
                path: `/reports/view-report-teacher/${current.idEstab}/${current.idCurso}/${current.idGrupo}/?current=true`,
                label: `View Reports: ${current.currentSearch.establishment} / ${current.currentSearch.course} / ${current.currentSearch.class}`
            }
        );
        links.push(
            {
                path: `/reports/view-report-final-test/${props.reportFinal.provaFinal.id_curso}/student/`
                +`${props.reportFinal.provaFinal.id_aluno}/score/${props.reportFinal.provaFinal.id_prova_final}`,
                label: `Final Test Report`
            }
        );
    }

    return <Breadcrumb links={links} />

}

//reports/view-report-final-test/:idCurso/score/:idProvaFinal/exercise/:idExercicio ---Student View
export const BreadcrumbExerciseAnswerFinalTestStudent = (props: any) =>
    <Breadcrumb links={props.reportFinal ? [
        bc.Homepage,
        bc.Reports,
        {
            path: `/reports/view-report-student/${props.reportFinal.provaFinal.id_curso}`,
            label: `View Report: ${props.reportFinal.curso}`
        },
        {
            path: `/reports/view-report-final-test/${props.reportFinal.provaFinal.id_curso}/student/`
                 +`${props.reportFinal.provaFinal.id_aluno}/score/${props.reportFinal.provaFinal.id_prova_final}`,
            label: `Final Test Report`
        },
        {
            path: `/reports/view-report-final-test/${props.reportFinal.provaFinal.id_curso}/student/`
                 +`${props.reportFinal.provaFinal.id_aluno}/score/${props.reportFinal.provaFinal.id_prova_final}/`
                 +`exercise/${props.reportFinal.prova_Final_Resposta.id_exercicio}`,
            label: `Exercise View`
        },
    ] : blank}
    />

//reports/view-report-final-test/:idCurso/score/:idProvaFinal/exercise/:idExercicio ---Teacher View
export const BreadcrumbExerciseAnswerFinalTestTeacher = (props: any) => {

    const current = getBreadCrumbData('ViewReportTeacherPage')

    const links = [
        bc.Homepage,
        bc.ReportsTeacher,
        bc.ReportClassGrades
    ]

    if (current && current.currentSearch) {
        links.push(
            {
                path: `/reports/view-report-teacher/${current.idEstab}/${current.idCurso}/${current.idGrupo}/?current=true`,
                label: `View Reports: ${current.currentSearch.establishment} / ${current.currentSearch.course} / ${current.currentSearch.class}`
            }
        );
        links.push(
            {
                path: `/reports/view-report-final-test/${props.reportFinal.provaFinal.id_curso}/student/`
                +`${props.reportFinal.provaFinal.id_aluno}/score/${props.reportFinal.provaFinal.id_prova_final}`,
                label: `Final Test Report`
            }
        );

        links.push(
            {
                path: `/reports/view-report-final-test/${props.reportFinal.provaFinal.id_curso}/student/`
                    +`${props.reportFinal.provaFinal.id_aluno}/score/${props.reportFinal.provaFinal.id_prova_final}/`
                    +`exercise/${props.reportFinal.prova_Final_Resposta.id_exercicio}`,
                label: `Exercise View`
            }
        );
    }

    return <Breadcrumb links={links} />

}

//courses/:idCurso/unit/:idUnit/view-book/:section/:question/exercise/:task
export const BreadcrumbExercise = (props: any) => {
    const unlock = getBreadCrumbData('UnlockScorePage')
    const student = getBreadCrumbData('ViewFullReportPage')

    const {
        exercicio: {
            exercicio_Yconnect: {
                secao_Yconnect: {
                    nome,
                    unidade_Yconnect: {
                        ordem,
                        curso: {
                            id_curso,
                            titulo_curso
                        }
                    }
                }
            }
        }
    } = props.contentExercise

    const unidade = props.contentExercise.exercicio.exercicio_Yconnect.secao_Yconnect.unidade_Yconnect.nome;

    const links = [
        bc.Homepage,
        bc.Courses,
        { path: `/courses/${id_curso}/view-book`, label: titulo_curso },
        {
            path: '',
            label: id_curso == 69 || id_curso == 70
                ? `${unidade} / ${nome} / Task ${props.ordem_exercicio}`
                : `Unit ${ordem} / ${nome} / Task ${props.ordem_exercicio}`
        }
    ]

    if (!props.isStudent && unlock && unlock.idUsuario) {

        links[1] = bc.ManagerUser;
        links[2] = { path: `/unlock-score/${unlock.idAluno}`, label: bc.Unlock.label };

    } else if (student && student.idUsuario) {

        links[1] = bc.Reports;
        links[2] = { path: `/reports/view-report-student/${student.idCurso}`, label: `View Report: ${student.reportAluno.course}` };

    }

    return <Breadcrumb links={links} />
}

//exercise-reports-student
export const BreadcrumbExerciseReportStudent = (props: any) => {
    const unlock = getBreadCrumbData('UnlockScorePage')
    const student = getBreadCrumbData('ViewReportStudentPage')
    const user = getBreadCrumbData('ViewFullReportPage')
    const current = getBreadCrumbData('ViewReportTeacherPage')
    const currentRB = getBreadCrumbData('ViewReportResourceBookPage')

    const { profiles }: any = useContext(UserContext)

    const {
        exercicio: {
            exercicio_Yconnect: {
                secao_Yconnect: {
                    nome,
                    unidade_Yconnect: {
                        ordem,
                        curso: {
                            id_curso,
                            titulo_curso
                        }
                    }
                }
            }
        }
    } = props.contentExercise

    const unidade = props.contentExercise.exercicio.exercicio_Yconnect.secao_Yconnect.unidade_Yconnect.nome;

    const links = [
        bc.Homepage,
        bc.Reports,
        { path: `/reports/view-report-student/${id_curso}`, label: `View Report: ${titulo_curso}` },
        {
            path: '',
            label: id_curso == 69 || id_curso == 70
                ? `${unidade} / ${nome} / Task ${props.ordem_exercicio}`
                : `Unit ${ordem} / ${nome} / Task ${props.ordem_exercicio}`
        }
    ]

    if (!props.isStudent && unlock && unlock.idUsuario) {

        links[1] = bc.ManagerUser;
        links[2] = { path: `/unlock-score/${unlock.idAluno}`, label: bc.Unlock.label };

    } else if (student && student.idUsuario) {

        links[1] = bc.ReportsTeacher;
        links[2] = { path: `/reports/view-full-report/${user.idCurso}/${user.idAluno}`, label: `View Report: ${titulo_curso}` };

    } else if(profiles.indexOf(15) > -1 || profiles.indexOf(12) > -1) {

        links[1] = bc.ReportsTeacher;
        links[2] = bc.ReportClassGrades;

        if(current !== null) {
            links.splice(3, 0,
                {
                    path: `/reports/view-report-teacher/${current.idEstab}/${current.idCurso}/${current.idGrupo}/?current=${current.currentSearch.currentParam}`,
                    label: `View Reports: ${current.currentSearch.establishment} / ${titulo_curso} / ${current.currentSearch.class}`,
                },
                {
                    path: `/reports/view-full-report/${user.idCurso}/${user.idAluno}`,
                    label: `Student Report`
                }
            );
        }
        else if (currentRB) {
            links.splice(3, 0,
                {
                    path: `/reports/view-report-resource-book/${currentRB.idEstab}/${currentRB.idCurso}/${currentRB.idGrupo}/?current=true`,
                    label: `View Reports Resource Book: ${currentRB.currentSearch.establishment} / ${titulo_curso} / ${currentRB.currentSearch.class}`,
                }
                )
        }

    }

    return <Breadcrumb links={links} />
}

//reports-teacher
export const BreadcrumbExerciseReport = (props: any) => {
    const {
        exercicio: {
            exercicio_Yconnect: {
                secao_Yconnect: {
                    nome,
                    unidade_Yconnect: {
                        ordem,
                        curso: {
                            id_curso,
                            titulo_curso
                        }
                    }
                }
            }
        }
    } = props.contentExercise

    const unidade = props.contentExercise.exercicio.exercicio_Yconnect.secao_Yconnect.unidade_Yconnect.nome;

    const unlock = getBreadCrumbData('UnlockScorePage')
    const user = getBreadCrumbData('ViewFullReportPage')
    const current = getBreadCrumbData('ViewReportTeacherPage')
    const rb = getBreadCrumbData('ViewReportResourceBookPage')
    const rbfull = getBreadCrumbData('ViewFullReportResourceBookPage')

    const links = [
        bc.Homepage,
        {
            path: '',
            label: id_curso == 69 || id_curso == 70
                ? `${unidade} / ${nome} / Task ${props.ordem_exercicio}`
                : `Unit ${ordem} / ${nome} / Task ${props.ordem_exercicio}`
        }
    ]

    if (!props.isStudent && unlock && unlock.idAluno) {
        links.splice(1, 0, bc.ManagerUser, { path: `/unlock-score/${unlock.idAluno}`, label: bc.Unlock.label });

    } else if (current && current.currentSearch) {
        links.splice(1, 0,
            bc.ReportsTeacher,
            bc.ReportClassGrades,
            {
                path: `/reports/view-report-teacher/${current.idEstab}/${current.idCurso}/${current.idGrupo}/?current=true`,
                label: `View Reports: ${current.currentSearch.establishment} / ${current.currentSearch.course} / ${current.currentSearch.class}`
            },
            { path: `/reports/view-full-report/${user.idCurso}/${user.idAluno}`, label: bc.StudentReport.label },
        );
    } else if (rb && rb.currentSearch) {
        links.splice(1, 0,
            bc.ReportsTeacher,
            bc.ReportResourceBook,
            {
                path: `/reports/view-report-resource-book/${rb.idEstab}/${rb.idCurso}/${rb.idGrupo}`,
                label: `View Reports: ${rb.currentSearch.establishment} / ${rb.currentSearch.course} / ${rb.currentSearch.class}`
            },
            { path: `/reports/view-full-report-resource-book/${rbfull.idCurso}/${rbfull.idAluno}`, label: bc.StudentReport.label },
        );
    }

    return <Breadcrumb links={links} />
}

//report-resource-book
export const BreadcrumbReportResourceBook = (props: any) =>
    <Breadcrumb links={[
        bc.Homepage,
        bc.ReportsTeacher,
        bc.ReportResourceBook
    ]}
    />

//reports/view-report-resource-book/
export const BreadcrumbViewReportResourceBook = (props: any) =>
    <Breadcrumb links={props.currentSearch ? [
        bc.Homepage,
        bc.ReportsTeacher,
        bc.ReportResourceBook,
        {
            path: `/reports-teacher/view-report-resource-book/${props.idEstab}/${props.idCurso}/${props.idGrupo}`,
            label: `View Reports: ${props.currentSearch.establishment} / ${props.currentSearch.course} / ${props.currentSearch.class}`
        }
    ] : blank}
    />

//reports/view-full-report-resource-book/64/828805
export const BreadcrumbViewFullReportResourceBook = (props: any) => {
    const rb = getBreadCrumbData('ViewReportResourceBookPage')
    let links = blank

    if (rb && rb.idEstab) {
        links = [
            bc.Homepage,
            bc.ReportsTeacher,
            bc.ReportResourceBook,
            {
                path: `/reports/view-report-resource-book/${rb.idEstab}/${rb.idCurso}/${rb.idGrupo}`,
                label: `View Reports: ${rb.currentSearch.establishment} / ${rb.currentSearch.course} / ${rb.currentSearch.class}`
            },
            bc.StudentReport
        ]
    } else {
        links = [
            bc.Homepage,
            bc.ReportsTeacher,
            bc.ReportResourceBook,
        ]
    }


    return <Breadcrumb links={links} />
}

import styled from "styled-components"
import { Container } from "reactstrap"

import MainTheme from "YConnect/Themes/Main.theme.ts"

const ContainerStyle = styled(Container)`
    margin-top: 15px;

    @media (max-width: 768px) {
        margin-top: 80px !important;
        margin-bottom: 78px !important;
        p {
            font-size: 1.1rem;
        }
    }

    .card {
        width: 100%;

        .title {
            color: ${MainTheme.mainPurple};
            margin-bottom: 2rem;

            h1 {
                font-weight: bold;
            }
        }

        .modal-backdrop {
            position: relative !important;
        }
    }
`
export default ContainerStyle
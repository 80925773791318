import * as React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  textarea {
    width: 100%;
    padding: 1em;
  }

  .open-question__char-counter {
    width: 100%;
    text-align: right;
  }
`

const OpenQuestionTextArea = ({disabled, maxLength = 1996, onChange, rows = 10, value}:any) => {
  return (
    <Wrapper className="open-question__answer">
      <textarea 
        maxLength={maxLength} 
        rows={rows} 
        disabled={disabled} 
        onChange={onChange} 
        value={value}>
      </textarea>
      <div className="open-question__char-counter">
        {value.length}/{maxLength}
      </div>
    </Wrapper>
  )
}

export default OpenQuestionTextArea
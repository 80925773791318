import styled from "styled-components"

const AccessibilityStyle = styled.div`
    padding: 16px;    
    overflow: auto;
    z-index: 10000;

    @media screen and (max-width: 768px) {
        h4 button {
            width: auto;
        }
    }

    &.accessibility-menu-enter {
        right: 0;
        transform: translate3d(100vw, 0, 0);
        width: 80%;
        @media screen and (min-width: 1024px) {
            width: 45%;
        }
    }

    &.accessibility-menu-enter-active,
    &.accessibility-menu-enter-done {
        left: 0;
        transform: translate3d(0vw, 0, 0);
        transition: transform .8s cubic-bezier(0, .52, 0, 1);
        width: 100%;

        @media screen and (min-width: 1024px) {
            left: auto;
            right: 0;
            width: 45%;
        }
    }

    &.accessibility-menu-exit {
        right: 0;
        transform: translate3d(100vw, 0, 0);
        @media screen and (min-width: 1024px) {
            width: 45%;
        }
    }

    &.accessibility-menu-exit-active,
    &.accessibility-menu-exit-done {
        transform: translate3d(100vw, 0, 0);
        transition: transform .8s cubic-bezier(0, .52, 0, 1);
    }

    .btn-group-toggle {
        display: flex;
        flex-flow: column wrap;
        @media screen and (min-width: 1024px) {
            flex-flow: row nowrap;
        }

        @media screen and (max-width: 768px) {
            .btn-radio {
                border-radius: 25px !important;
            }
        }
    }

    .btn-radio {
        align-items: center;
        border-radius: 25px;
        display: flex;
        font-size: 12px;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 8px 24px;
        width: 70%;

        &:not(:last-child):not(.dropdown-toggle) {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            @media screen and (min-width: 768px) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        &:not(:first-child) {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            @media screen and (min-width: 768px) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    footer {
        border-top: 1px solid #eef0f0;
    }
`

export default AccessibilityStyle
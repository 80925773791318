import * as React from "react"
import {Card} from "reactstrap"

import ExerciseCardHeader from "YConnect/PagesComponents/CMS/CardHeaders/Exercise.cardHeader"

type ExerciseCardProps = {
    exerciseIdSelected : number
    listExercises      : Array<ExerciseType>
    listExerciseType   : Array<ExerciseTypeType>
    onCancel           : any
}

const ExerciseCard = ({
    exerciseIdSelected, 
    listExercises,
    listExerciseType,
    onCancel
}:ExerciseCardProps)=>{

    const {
        Order, 
        ExerciseTypeId
    } = listExercises.find(({Id}) => Id == exerciseIdSelected)

    return <Card className="p-3 mb-3">
                <ExerciseCardHeader 
                    Order        = {Order} 
                    ExerciseType = {(listExerciseType && listExerciseType.find(({Id}) => Id == ExerciseTypeId)) || {}} 
                    onCancel      = {onCancel}/>
            </Card>
}
          
export default ExerciseCard
import * as React   from "react"
import { Col, Tooltip } from "reactstrap"

import findIndex from "lodash/findIndex"
import last      from "lodash/last"
import clone     from "lodash/clone"
import indexOf   from "lodash/indexOf"
import countBy   from "lodash/countBy"

import API          from "YConnect/API"
import Loader       from "YConnect/Components/Loader"
import getConfig    from "YConnect/Utils/GetRequestConfig.util"

import GetNewHtmlReplaceImg from "YConnect/Utils/GetNewHtmlReplaceImg.util"
import HeaderButtonStyle from "YConnect/Styles/HeaderButton.style"

import ExerciseSubStatementStyle from "YConnect/Fields/Exercises/DialoguePhrase.field/ExerciseSubStatement.style"
import ExerciseRow from "YConnect/Fields/Exercises/DialoguePhrase.field/ExerciseRow"
import formatText from "YConnect/Utils/FormatText.util";

const formatData = (contentDialogue: any) => {

    const { dialogo_Avatar_Yconnect, dialogo_Mensagem_Yconnect } = contentDialogue

    let lastOrder = 0
    let answerIndex = 0
    let result = []
    for (let index = 0; index < dialogo_Mensagem_Yconnect.length; index++) {
        const item = dialogo_Mensagem_Yconnect[index]
        let node = {}

        if (item.ordem_dialogo !== lastOrder) {
            lastOrder = item.ordem_dialogo

            node = {
                avatar: item.ordem_dialogo % 2 === 0
                ? (dialogo_Avatar_Yconnect.find(({ordem}:any) => ordem === 2) || {}).avatar_Yconnect
                : (dialogo_Avatar_Yconnect.find(({ordem}:any) => ordem === 1) || {}).avatar_Yconnect,
                ordem_mensagem: item.ordem_mensagem,
                texto: [ item.texto ]
            }

            if (!item.texto) {
                node.options = true
                node.answerIndex = answerIndex
                answerIndex++
            }

        } else {
            node = last(result)
            result.pop()

            if (!item.texto) {
                node.options = true
                node.answerIndex = answerIndex
                answerIndex++
            }

            node.texto.push(item.texto)
        }

        result.push(node)
    }
    return result
}


class DialoguePhraseField extends React.Component<ExerciseFieldPropsType, {
    loadingExercise  : boolean,
    loadingAnwers    : boolean,
    answers          : any,
    contentDialogue  : any,
    respostasUsuario : any,
    exerciseAnswers  : any,
    rightAnswers     : any,
    formatedExercise : any,
    tooltipOpen      : any
    isDisabledViewAnswers : boolean,
    errorMessage : string[],
    qtyOptions   : Number
}> {
    constructor(props: any) {
        super(props)

        if (this.props.innerRef) {
            this.props.innerRef.current.selectLanguage = this.selectLanguage
        }

        this.state = {
            loadingExercise  : true,
            loadingAnwers    : false,
            tooltipOpen      : false,
            answers          : [],
            contentDialogue  : {},
            exerciseAnswers  : this.embaralhaOpRespostas(),
            respostasUsuario : [],
            rightAnswers     : [],
            formatedExercise : [],
            language: this.props.translated,
            errorMessage     : [],
            qtyOptions       : 0
        }

        this.handleChange = this.handleChange.bind(this)
        this.showCorrectAnswers = this.showCorrectAnswers.bind(this)
    }

    componentDidMount() {
        Promise.all([
            this.fetchDialogueExercise()
        ]).then(() => {
            if (this.props.attempts > 0)
                this.fetchUserAnswer()
        })
    }

    selectLanguage = (language: string) => {
        this.setState({...this.state, language})
    }

    async fetchDialogueExercise () {
        try {
            const {data} = await API.Exercicio.getDialogo({
                idExercicio: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem
            }, getConfig())

            const T = formatData(data)
            const qtyOptions = countBy( T ,obj => obj.options === true).true;

            this.setState({
                contentDialogue: data,
                formatedExercise: T,
                loadingExercise: false,
                qtyOptions:qtyOptions
            })
        } catch (error) {}
    }

    async fetchUserAnswer() {
        this.setState({ loadingAnwers: true })
        try {
            const response = await API.Exercicio.getRespostaUsuario({
                userId: this.props.studentId,
                exer: this.props.exercise.id_exercicio,
                tela: this.props.exercise.ordem,
                tentativa: 'U',
                idProvaFinal: this.props.exercise.idTesteFinal ? this.props.exercise.idTesteFinal : 0
            }, getConfig())

            this.findAnswers(response.data.resposta)

        } catch (error) {}
    }

	handleChange(event: any) {
        const { exercise, id, onChange } = this.props
        const { respostasUsuario, answers, exerciseAnswers, rightAnswers, errorMessage } = this.state
        const { target: { value, attributes } } = event
        const index = attributes["data-answerindex"].value;

        /** get answer index and object */
        const answerIndex = findIndex(
            exercise.respostas_Exercicio_Yconnect,
            {
                id_resposta: Number(value)
            }
        )

        const answer = exercise.respostas_Exercicio_Yconnect[answerIndex]

        errorMessage[index] = null
        /** clean last answer of same value before set current user answer and show error message */
        if (respostasUsuario.includes(answerIndex + 1)) {
            const oldIndex = indexOf(respostasUsuario, answerIndex + 1)

            respostasUsuario[oldIndex] = null
            answers[oldIndex] = null
            rightAnswers[oldIndex] = null
            errorMessage[oldIndex] = 'Each answer can only be used once!'

            document.getElementById(`exercise-${oldIndex}`).scrollIntoView({ behavior: 'smooth', block: 'center'});
        }

        /** set answers object */
        answers[index] = answer

        /** add answer to an array sent to backend
         *  the backend expect the index of the answer in the original object `respostas_Exercicio_Yconnect` 🙄
         */
        respostasUsuario[index] = answerIndex + 1

        /** clear last answer result */
        rightAnswers[index] = null

        /** calculate score */
        const score = this.calculaScore(answers)

        /** call parent function and send the values */
        onChange(id, {
            qntd_acertos: score.qntd_acertos,
            qntd_respostas: score.qntd_respostas,
            resposta: respostasUsuario
        })

        /** update component parameters */
        this.setState({ answers, respostasUsuario, exerciseAnswers, errorMessage })
    }



    findAnswers(respostasUsuario: string) {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const { exerciseAnswers, rightAnswers } = this.state
        const respostas = respostasUsuario || ""
        const respFinal = []

        const result = respostas.split(",").map((answer, index) => {
            if (answer) {
                const resp = respostas_Exercicio_Yconnect[answer - 1]
                rightAnswers.push(resp.ordem === index + 1)
                respFinal.push(Number(answer))

                return resp
            }
        }, [])

        this.setState({
            loadingAnwers: false,
            answers: result,
            respostasUsuario: respFinal,
            exerciseAnswers,
            rightAnswers
        })
    }

    calculaScore(answers: any) {
        const qntd_acertos = answers.reduce((qntdAcertos:number, answers: any, index:number) => {
            if (answers && answers.ordem === index + 1) {
                qntdAcertos++;
            }

            return qntdAcertos
        }, 0)



        return { qntd_acertos, qntd_respostas: answers.length }
    }

    embaralhaOpRespostas () {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        let retorno = clone(respostas_Exercicio_Yconnect)

        // Embaralha as respostas, e armazena nas opções de respostas
        respostas_Exercicio_Yconnect.map(() => {
            const r1 = Math.floor(Math.random() * respostas_Exercicio_Yconnect.length)
            const r2 = Math.floor(Math.random() * respostas_Exercicio_Yconnect.length)

            const aux = retorno[r2]
            retorno[r2] = retorno[r1]
            retorno[r1] = aux
        })

        return retorno
    }

    showCorrectAnswers() {
        const { respostas_Exercicio_Yconnect } = this.props.exercise
        const { exerciseAnswers, qtyOptions } = this.state

        const {
            answers,
            resposta,
            rightAnswers
        } =
        respostas_Exercicio_Yconnect
        .reduce((acc:any, item:any, index:number) => {
            if (qtyOptions == respostas_Exercicio_Yconnect.length ){
                if(item.ordem-1 == index){
                    acc.answers.push(item)
                    acc.resposta.push(index + 1)
                    acc.rightAnswers.push(true)
                }
            } else {
                if(item.correta){
                    acc.answers.push(item)
                    acc.resposta.push(item.ordem - 1)
                    acc.rightAnswers.push(true)
                }
            }

            return acc

        }, {answers:[], resposta:[], rightAnswers:[]})


        const {qntd_acertos, qntd_respostas} = this.calculaScore(answers)
        this.props.onChange(this.props.id, {qntd_acertos, qntd_respostas, resposta })
        this.setState({ answers, respostasUsuario:resposta, exerciseAnswers, rightAnswers })
    }

    render() {
        let {
			props: {
                id,
                exercise,
                attempts,
                isStudent,
                isDisabledViewAnswers
            },
            state: {
                answers,
                loadingAnwers,
                loadingExercise,
                exerciseAnswers,
                rightAnswers,
                formatedExercise
            },
            handleChange
        } = this

        return (loadingAnwers || loadingExercise)
                ? <Loader />
                : <div>
                    {
                    !isDisabledViewAnswers
                     && <div className="pl-2 pr-2 pl-lg-4 pr-lg-4 w-100">
                        {
                            attempts >= 2 || !isStudent
                            ? <HeaderButtonStyle
                                    className = "float-right"
                                    label     = "View Answers"
                                    onClick   = {this.showCorrectAnswers}
                                    icon      = "checkedGreen2"/>
                            : <>
                                <HeaderButtonStyle
                                    id        = "completeResultToolTip"
                                    className = "float-right disabled"
                                    label     = "View Answers"
                                    icon      = "checkedGreen2"/>
                                <Tooltip
                                    placement = "right"
                                    isOpen    = {this.state.tooltipOpen}
                                    target    = "completeResultToolTip" toggle={() => this.setState(state => ({ tooltipOpen: !state.tooltipOpen }))}
                                >
                                    {
                                        this.state.language == "br" &&
                                        <span>Oops, você só poderá saber a resposta correta depois de verificar suas próprias respostas na primeira e segunda tentativa.</span>
                                    }

                                    {
                                        this.state.language == "en" &&
                                        <span>Oops, you'll only be able to know the
                                    correct answers once you've checked your own
                                    answers on the 1st and 2nd attempts.</span>
                                    }
                                </Tooltip>
                            </>
                        }
                    </div>}
                    {/** sub enunciado */}
                    {
                        exercise.conteudo_exercicio
                        &&
                        <ExerciseSubStatementStyle className="w-100">
                            <Col className="exercise-dialogue-content text-center w-100 pb-5" md="12" lg="12">
                                <div className="exercise-dialogue-enunciado">
                                    <span dangerouslySetInnerHTML={{ __html: GetNewHtmlReplaceImg(formatText(exercise.conteudo_exercicio))}} />
                                </div>
                            </Col>
                        </ExerciseSubStatementStyle>
                    }

                    {/** dialogo e questoes */}
                    <div className="pl-3 pr-3 pl-md-5 pr-md-5 pt-5">

                        {
                            formatedExercise
                            .map((item: Array<any>, key: number) => {
                                // this.setState({errorMessage: null})

                                return <ExerciseRow
                                    id              = {`exercise-${key}`}
                                    key             = {key}
                                    index           = {key}
                                    item            = {item}
                                    rightAnswers    = {rightAnswers}
                                    answer          = {item.options ? (answers[item.answerIndex] || {}) : ({})}
                                    exerciseAnswers = {exerciseAnswers}
                                    onChange        = {handleChange}
                                    errorMessage    = {this.state.errorMessage}
                                    />
                                })
                        }
                    </div>
                </div>
    }
}

export default DialoguePhraseField

import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAllByCourseId = (courseId:any, unitType?:string) => 
    API2
    .Unit
    .GetAll({courseId, unitType}, getConfig())

export const Create = (unit:any) => 
    API2
    .Unit
    .Create(unit, getConfig())
    
export const UpdateById = (unit:any) => 
    API2
    .Unit
    .UpdateById(unit, getConfig())

export const GetById = (unitId:any) => 
    API2
    .Unit
    .GetById({unitId}, getConfig())

export const DeleteById = (unitId:any) => 
    API2
    .Unit
    .DeleteById({unitId}, getConfig())

export const GetUnitTypes = () => 
    API2
    .Unit
    .GetUnitTypes({}, getConfig())
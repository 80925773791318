import * as React               from "react"
import { useEffect, useReducer} from "react"

import TabStyle        from "YConnect/Styles/Tab.style"
import TabContentStyle from "YConnect/PagesComponents/PrePostReport/Components/TabContent.style"
import TabReport       from "YConnect/PagesComponents/PrePostReport/School.report/TabReport"
import useReport       from "YConnect/PagesComponents/PrePostReport/School.report/useReport"

const TabsResult = ({
    semesterIdSelected,
    courseIdSelected,
    establishmentIdSelected,
    teacherIdSelected,
    classIdSelected,
    idUsuario
}: any) => {

    const {
        averageScore,
        bestClassScore,
        rankedClasses,
        bestRankedStudents
    } = useReport({ 
        semesterIdSelected,
        courseIdSelected,
        establishmentIdSelected,
        teacherIdSelected,
        classIdSelected,
        idUsuario
    })

    return <TabStyle defaultActiveKey="pre">
                <TabContentStyle 
                    eventKey  = "pre" 
                    title     = "Pre test"  
                    className = "yc-tab">
                    <TabReport  
                        bestClassScore     = {bestClassScore}
                        rankedClasses      = {rankedClasses}
                        bestRankedStudents = {bestRankedStudents}
                        averageScore       = {averageScore}/>
                </TabContentStyle>
                <TabContentStyle 
                    eventKey  = "post" 
                    title     = "Post test" 
                    className = "yc-tab">
                    <TabReport  
                        isPost
                        bestClassScore     = {bestClassScore}
                        rankedClasses      = {rankedClasses}
                        bestRankedStudents = {bestRankedStudents}
                        averageScore       = {averageScore}/>
                </TabContentStyle>
            </TabStyle>
}

export default TabsResult
import * as React from "react"
import {useState, useEffect} from "react"
import styled from "styled-components"

import { Col } from "reactstrap"

const ColOverflowStyled = styled(Col)`
    overflow:auto;
    max-height:600px;
    background-color: #f0f5f6;
`

import DialogueTypeInEditor from "YConnect/PagesComponents/CMS/Editors/TypeIn.editor/DialogueTypeIn.editor"

    
const GetItemsByQuestion = (values:QuestionType) => {
    if(values.Dialogues[0]){
        const {DialogueMessages} = values.Dialogues[0]
        const {Answers, ExerciseMedias} = values

        const newDataState = DialogueMessages
        .map((dialog) =>
         ({
            text:dialog.Text,
            answers:Answers.filter(({Order}) => Order == dialog.OrderMessage),
            media: ExerciseMedias.find(({MediaType, Order}) => MediaType.toLowerCase() === "image" && Order==dialog.OrderMessage),
            dialogMessage: dialog,
        }))

        return newDataState
    }else{
        return []
    }
}

const GetQuestionByItems = (values:QuestionType, dataState:Array<ItemTypeInType>) => {
    
    const dialog= values.Dialogues[0]?
        {
            DialogueMessages:dataState
                .map(({dialogMessage}, i)=>({...dialogMessage})),
            DialogueAvatars:[],
            Id:values.Dialogues[0].Id,
            QuestionOrder:values.Dialogues[0].QuestionOrder,
            
        }
    :
        {
            DialogueMessages:dataState
                .map(({dialogMessage}, i)=>({...dialogMessage})),
            DialogueAvatars:[],
        }
    
    
    const newValues =  {
        ...values,
        ExerciseMedias:[
            ...values.ExerciseMedias.filter(({Order}) => Order === 0),
            ...dataState.map(({media}) => media).filter(media=> media !=null ),
          ],
        Answers:dataState
            .reduce((acc, {answers}, index) => {
                return [
                    ...acc, 
                    ...answers,
                ]
            }, []),
            Dialogues:[dialog],
    }

    return newValues
}



type TypeInItemsEditorProps = {
    hasImage?:Boolean
    values:QuestionType
    onChange:any
}

const TypeInItemsEditor = ({
    hasImage,
    values,
    onChange
}:TypeInItemsEditorProps) => {

    const [dataState, setDataState] = useState<Array<ItemTypeInType>>([])

    const [newItem, setNewItem] = useState<ItemTypeInType>()

    useEffect(() => {
        if(newItem){
            const newDataState = [...dataState, newItem]
            setDataState(newDataState)
            onChange(GetQuestionByItems(values, newDataState))    
        }
    }, [newItem])

    useEffect(() => {
        if(values){
            setDataState(GetItemsByQuestion(values))
        }

        if(newItem != undefined){
            setNewItem(undefined)
            var myDiv = document.getElementsByClassName("cms-typein-scroll")[0];
            if(myDiv !=null){
                myDiv.scrollTop = myDiv.scrollHeight;
            }
        }
    
    }, [values])

    
    const handleAddNewItem = (values:any) => setNewItem(values)
    const handleChangeItem = (index:number, newItem:ItemTypeInType) => {
        const newDataState = dataState.map((item, i) => i === index ? newItem : item)
        setDataState(newDataState)
        onChange(GetQuestionByItems(values, newDataState))
    }

    const ReorderDialogue = (newdataState:any) => {

        const result = newdataState.map((each:any, index:number) =>{
            const {answers, dialogMessage} = each
            const  answersOrdered= answers.map((answer:any)=> { return {...answer, Order:index + 1 }})

            return {...each, answers: answersOrdered, dialogMessage:{...dialogMessage, OrderMessage: index + 1, OrderSubMessage: index + 1}}
        })

        return result;
    }

    const handleRemoveItem = (key:number) => {
      if (dataState){
            const {Answers, ExerciseMedias} = values
            let newDataState = dataState
            .reduce((acc: any, answer) => {
                let currentIndex=answer.dialogMessage.OrderMessage

                if ( currentIndex!= key){
                    acc[currentIndex-1] = {
                        ...answer,
                    }
                }
                return acc
            }, []).filter((each:any)=>each != null)

            newDataState=ReorderDialogue(newDataState)
            setDataState(newDataState)
            onChange(GetQuestionByItems(values, newDataState))
        }
    }


    return <>
            <Col md={12}>
                <h3 className="font-weight-bold">Type In Editor</h3>
            </Col>
            <ColOverflowStyled md={12} className="px-4 cms-typein-scroll">
                    {
                        dataState
                        && dataState
                        .map((item, key:number) => 
                            <DialogueTypeInEditor
                                hasImage = {hasImage}
                                idx      = {key} 
                                item     = {item}
                                onChange = {(newItem:ItemTypeInType) => handleChangeItem(key, newItem)}
                                onRemove = {handleRemoveItem}/>)
                   }
            </ColOverflowStyled>
            <Col md={12} className="px-4">
                <DialogueTypeInEditor 
                    hasImage={hasImage}
                    isNew 
                    item  = {newItem}
                    idx   = { dataState ? dataState.length:0}
                    onAdd = {handleAddNewItem}/>
            </Col>
        </>
}

export default TypeInItemsEditor
import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAllBySectionId = (sectionId:any) => 
    API2
    .Exercise
    .GetAllBySectionId({sectionId}, getConfig())

export const Create = (exercise:any) => 
    API2
    .Exercise
    .Create(exercise, getConfig())
    
export const UpdateById = (exercise:any) => 
    API2
    .Exercise
    .UpdateById(exercise, getConfig())

export const GetById = (exerciseId:any) => 
    API2
    .Exercise
    .GetById({exerciseId}, getConfig())

export const DeleteById = (exerciseId:any) => 
    API2
    .Exercise
    .DeleteById({exerciseId}, getConfig())
import * as React from "react"

import * as hidePassword    from "YConnect/Assets/svg/hide-password.svg"
import * as show            from "YConnect/Assets/svg/show-password.svg"

interface PasswordState {
    show: boolean
}

class PasswordField extends React.Component<InputFieldPropsType, PasswordState>{

    constructor(props: any) {
        super(props)

        this.state = { show: false };
    }

    handleChange(event: any) {
        const { props: { keystone } } = this
        const { target: { value } } = event
        this.props.onChange(keystone, value);

    }

    render() {

        const iconStyle = {
            cursor:     'pointer',
            fill:       'green',
            height:     '25px',
            position:   'absolute' as 'absolute',
            right:      '25px',
            top:        '25px',
            width:      '25px'
        };

        let {
            props: {
                keystone,
                required,
                label,
                value,
                placeholder,
                className,
                disabled,
                messageBlocker,
                id,
                isInvalid
            },
            handleChange
        } = this

        return <div className={`${className || ""}`}>
            <div className={`form-group ${messageBlocker ? '' : 'mb-4'} ${disabled ? 'input-disabled' : ''}`}>
                {(label) ? <label className="form-label">{`${label}${required ? "*" : ""}`}</label> : ""}
                <input
                    type={this.state.show ? 'text' : 'password'}
                    className={`form-control ${isInvalid === true && "isInvalid"}`}
                    value={value}
                    onChange={handleChange.bind(this)}
                    placeholder={placeholder}
                    disabled={disabled}
                    style={{padding: '0 56px 0 18px'}}
                    id={`${id ? id: keystone}`}
                />
                {
                    this.state.show ?
                        <img src={show} style={iconStyle} onClick={(() => this.setState({ show: !this.state.show }))}/>
                        :
                        <img src={hidePassword} style={iconStyle} onClick={(() => this.setState({ show: !this.state.show }))}/>
                }
            </div>
            {(messageBlocker) ? <small className="form-text text-muted mb-3 text-clickable">{messageBlocker}</small> : ''}
        </div>
    }
}

export default PasswordField
import { useEffect} from "react"

const useFilter = ({
    semesterIdSelected,
    establishmentIdSelected,
    courseIdSelected,
    classIdSelected,
    studentIdSelected,
    setIdSelected
}:any) => {

    useEffect(() => setIdSelected({establishmentIdSelected:""}), [semesterIdSelected])
    useEffect(() => setIdSelected({courseIdSelected:""}),        [establishmentIdSelected])
    useEffect(() => setIdSelected({classIdSelected:""}),         [courseIdSelected])
    useEffect(() => setIdSelected({studentIdSelected:""}),       [classIdSelected])
    useEffect(() => setIdSelected({periodIdSelected:"0"}),        [studentIdSelected])

}

export default useFilter
import * as React              from "react"
import { useState, useEffect } from "react"
import { Fragment }            from "react"

import * as plusIcon from "YConnect/Assets/svg/plus.svg"

import TripProgress      from "YConnect/PagesComponents/Dashboard/TripProgress"
import getConfig         from "YConnect/Utils/GetRequestConfig.util"
import ProfilePicture    from "YConnect/Components/ProfilePicture"
import TripProgressModal from "YConnect/Modals/TripProgress.modal"
import ConvertPhoto      from "YConnect/Utils/ConvertPhoto.util"
import API               from "YConnect/API"

//Renomear
const YCard = ({user}: any) => {
    const [userStamps, setUserStamps] = useState()
    const [lastStamps, setLastStamps] = useState()
    const [userTags, setUserTags] = useState()
    const [lastTags, setLastTags] = useState()
    const [amountTag, setAmountTag] = useState()

    const [showTripModal, setShowTrip] = useState(false)

    useEffect(() => {
        _fetchStamps()
        _fetchTags()
    }, [])

    const _fetchTags = async () => {
        if (user.profiles.indexOf(15) > -1 || user.profiles.indexOf(12) > -1 ) {
            try {
                const result = await API.Tag.GetAll(getConfig())
                setAmountTag(result.data.count)
                setLastTags(result.data.listTags.slice(0,6))
                setUserTags(result.data.listTags)
            } catch (error) {}
        } else {
            try {
                const result = await API.Tag.getTagByStudent({
                    idAluno: user.idUsuario
                }, getConfig())
                setAmountTag(result.data.count)
                setLastTags(result.data.listTags.slice(0,6))
                setUserTags(result.data.listTags)
                
            } catch (error) {}

        }
    }

    const _fetchStamps = async () => {
        if (user.profiles.indexOf(15) > -1 || user.profiles.indexOf(12) > -1 ) {
            try {
                const result = await API.Stamp.getAll(getConfig())
                setUserStamps(result.data)
                setLastStamps(result.data.slice(0, 3))
            } catch (error) {}
        } else {
            try {
                const result = await API.Stamp.getStampByStudent({
                    idAluno: user.idUsuario
                }, getConfig())
                setUserStamps(result.data)
                setLastStamps(result.data.slice(0, 3))
            } catch (error) {}

        }
    }

    const showProgressBar = (user: any): any => {
        if (!user.profiles.indexOf(15) > -1) {
            return <Fragment>
                        <div className="profile-status d-flex flex-row justify-content-around">
                            <div className="profile-status-item d-flex flex-column align-items-center">
                                <span className="status-item-title">Stamps</span>
                                <span className="status-item-subtitle">{userStamps && userStamps.length}</span>
                            </div>

                            <div className="profile-status-item d-flex flex-column align-items-center">
                                <span className="status-item-title">Tags</span>
                                <span className="status-item-subtitle">{amountTag}</span>
                            </div>

                        </div>
                    </Fragment>
        }
        return null
    }

    return <>
                <div className="YC-ProfileCard d-flex flex-row flex-wrap justify-content-center p-4">

                    <div className="col-6 align-self-center mb-2">
                        <ProfilePicture
                            picture={ConvertPhoto(user.foto)}
                            user={user.nomeCompleto}
                            border={`#eee 10px solid`}
                            className={'img-fluid rounded-circle'}
                            minHeight={120}
                            minWidth={120}
                            width={120}
                            height={120}
                        />
                    </div>

                    <div className="col-12 text-center pb-3">
                        <span className="font-weight-bold">{user.nomeCompleto}</span>
                    </div>

                    {showProgressBar(user)}

                    <hr className="m-0" />
                    {<TripProgress stamps={lastStamps} tags={lastTags} />}
                    <hr className="ml-3 mr-3" />
                    <footer className="profile-footer h-100 w-100 d-flex flex-column justify-content-center">
                        <p 
                            className="text-right pointer font-weight-bold mt-1" 
                            style={{ color: '#79b100' }}
                            onClick={() => setShowTrip(true)}>
                            See your world
                            <img src={plusIcon} className="ml-2" />
                        </p>
                    </footer>

                    <TripProgressModal 
                        stamps          = {userStamps} 
                        tags            = {userTags} 
                        showTripModal   = {showTripModal}
                        setShowTrip     = {setShowTrip}
                    />

                </div>
            </>
}

export default YCard

import * as React from "react"
import InputMask  from 'react-input-mask'

class ZipcodeField extends React.Component<InputFieldPropsType, {}>{

  handleChange(event: any) {
    const { props: { keystone } } = this
    const { target: { value } } = event
    this.props.onChange(keystone, value)
  }

  render() {
    let {
      props: {
        required,
        label,
        value,
        placeholder,
        className,
        disabled,
        messageBlocker,
        id,
        keystone,
        isInvalid
      },
      handleChange
    } = this

    return <div className={`${className || ""}`}>
      <div className={`form-group ${messageBlocker ? 'mb-0' : 'mb-4'} ${disabled ? 'input-disabled' : ''}`}>
        {(label) ? <label className="form-label">{`${label}${required ? "*" : ""}`}</label> : ""}
        <InputMask
          mask        = "9****-**9"
          maskChar    = " "
          type        = "tel"
          formatChars={{
            '9': '[0-9]',
          }}
          className   = {`form-control ${isInvalid === true && "isInvalid"}`}
          value       = {value ? value : ''}
          onChange    = {handleChange.bind(this)}
          placeholder = {placeholder}
          disabled    = {disabled}
          id          = {`${id ? id: keystone}`}
        />
      </div>
      {(messageBlocker) ? <small className="form-text text-muted mb-3 text-clickable">{messageBlocker}</small> : ''}
    </div>
  }
}

export default ZipcodeField

import getBaseURLWebApi from "YConnect/Utils/GetBaseURLWebAPI.util"

const getStaticUrl = (url: string) => {
    if (url.includes('midia:')) {
        const urlCMSFormatted = url.replace('midia:', '')

        // https://cdn-cms.pearsonbr.info/ - link assets homolog
        return `https://cms-cdn.pearsonbr.com/${urlCMSFormatted}`
    } else if (url.includes('vimeo')) {
        return url
    } else {
        return `${getBaseURLWebApi()}/${url}`
    }
}

export default getStaticUrl

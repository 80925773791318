import * as React from "react"
import {useEffect} from "react"
import {
    Row,
    Col
} from "reactstrap"
import styled from "styled-components"
import Table from "YConnect/Components/Table"

import { ButtonStyle } from "YConnect/Components/Button"
import useEditableTableState from "YConnect/PagesComponents/CMS/Hooks/useEditableTableState"

import EditorField from "YConnect/Fields/Forms/Editor.field"

const TableStyled = styled(Table)`
    .table{
        min-height: auto;
    }
    td, th {
        padding:10px;
    }
`
const DivStyled = styled.div`
    .editor-textarea{
        max-width: 28rem;
        min-width: 28rem;
    }
`

const INITIAL_DATA_STATE = [
    {
        type:"NEW",
        row:{}
    }
]

const GetStateByMatchTextsByOrder = (matchTextsByOrder:any) => 
    matchTextsByOrder && Object.keys(matchTextsByOrder).length > 0
    ? [
        ...Object.keys(matchTextsByOrder).map(order => ({type:"ADDED", row:matchTextsByOrder[order]})),
        {type:"NEW", row:{}}
    ]
    : INITIAL_DATA_STATE

type MatchTextEditableTableProps = {
    matchTextsByOrder:any
    onChange:any
}
    
const MatchTextEditableTable = ({
    matchTextsByOrder,
    onChange
}:MatchTextEditableTableProps) => {

    const {
        dataState,
        getValues,
        updateLine,
        addDataNewLine,
        removeLine,
        setDataState
    } = useEditableTableState(GetStateByMatchTextsByOrder(matchTextsByOrder))

    const handleUpdateRow = (key:number, property:string, value:any) => {
        const values  = updateLine(key, property, value)  
        onChange(values)
    }
    const handleRemoveRow = (key:number) => {
        const values  = removeLine(key)
        onChange(values)
    }

    useEffect(() =>{
        if(matchTextsByOrder){
            setDataState(GetStateByMatchTextsByOrder(matchTextsByOrder))
        }
    }, [matchTextsByOrder])


    const config : Array<ColumnConfigType> = [
        {
            label     : "Text",
            formatter : (state:any, key:number) =>
            <DivStyled>
                <EditorField
                    value={state.row["dialogueMessage"] && state.row["dialogueMessage"]["Text"] || ""}
                    keystone="Text"
                    className="editor-textarea"
                    onChange={(_, value:string) =>
                        handleUpdateRow(key, "dialogueMessage", {...state.row["dialogueMessage"], Text:value})}
                    toolbar={{"options":  ["inline"], "inline": {"options": ["bold", "italic", "underline", "strikethrough"]}}}/>
            </DivStyled>
        },
        {
            label     : "Answer",
            formatter : (state:any, key:number) =>
            <DivStyled>
                <EditorField
                    value={state.row["answer"] && state.row["answer"]["Awnser"] || ""}
                    className="editor-textarea"
                    keystone="Answer"
                    onChange={(_, value:string) =>
                        handleUpdateRow(key, "answer", {...state.row["answer"], Awnser:value})}
                    toolbar={{"options":  ["inline"], "inline": {"options": ["bold", "italic", "underline", "strikethrough"]}}}/>
            </DivStyled>
        },
        {
            formatter : (state:any, key:any) => 
            <>
                {
                    state.type === "ADDED"
                    &&<Col> <Row className="px-0">
                        <ButtonStyle 
                            size      = "forTable"
                            className = "mr-2"
                            icon      = "remove"
                            onClick   = {() => handleRemoveRow(key)}/>
                    </Row></Col>
                }
            </>
        }
    ]

    return <TableStyled 
                data={dataState}
                config={config} />
}

export default MatchTextEditableTable
import * as React from "react"
import {useEffect, useState} from "react"
import { Row, Col, Card } from "reactstrap"

import styled from "styled-components"

import DialogueMessagesEditableTable from "YConnect/PagesComponents/CMS/Editors/Advanced.editor/DialogueMessages.editableTable"
import DialogueAvatarsEditableTable from "YConnect/PagesComponents/CMS/Editors/Advanced.editor/DialogueAvatars.editableTable"

import { ButtonStyle } from "YConnect/Components/Button"

const RowStyled = styled(Row)`
    max-height: 600px;
    border-top: 1px solid rgb(0 0 0 / 21%);
    border-right: 1px solid rgb(0 0 0 / 21%);
    border-left: 1px solid rgb(0 0 0 / 21%);
    background-color: #f0f5f6;
    overflow: auto;
`

const ColStyled = styled(Col)`
    border-bottom: 1px solid rgb(0 0 0 / 21%);
    border-right: 1px solid rgb(0 0 0 / 21%);
    border-left: 1px solid rgb(0 0 0 / 21%);
    background-color: #f0f5f6;
`

const INITIAL_DATA_STATE = [
    {
        type:"NEW",
        row:{
            DialogueMessages:[],
            DialogueAvatars:[]
        }
    }
]

const GetStateByValues = (values:Array<DialogueType>) => 
    values && values.length > 0
    ? values.map((dialogue) => ({type:"ADDED", row:dialogue}))
    : INITIAL_DATA_STATE


const SetPropertyByIndex = (index:number, property:any, values:any) => (acc:any, value:any, i:number) => {
    if(index === i){
        value.row[property] = values
    }
    return [...acc, value]
}

type DialoguesEditorProps = {
    values:Array<DialogueType>
    onChange:any
}

const DialoguesEditor = ({
    values,
    onChange
}:DialoguesEditorProps) => {

    const [dataState, setDataState] = useState<Array<any>>(GetStateByValues(values))

    const setDialogueMessagesByIndex = (index:number, dialogueMessages:any) => 
        setDataState(dataState.reduce(SetPropertyByIndex(index, "DialogueMessages", dialogueMessages), []))

    const setDialogueAvatarsByIndex = (index:number, dialogueAvatars:any) => 
        setDataState(dataState.reduce(SetPropertyByIndex(index, "DialogueAvatars", dialogueAvatars), []))

    const handleChangeDialogueMessages = (index:number, values:any) => setDialogueMessagesByIndex(index, values) 
    const handleChangeDialogueAvatars  = (index:number, values:any) => setDialogueAvatarsByIndex(index, values)

    const handleNewDialogue = () => setDataState([...dataState, {
        type:"NEW",
        row:{
            DialogueMessages:[],
            DialogueAvatars:[]
        }
    }])

    useEffect(()=>{
        if(dataState){
            const newDialogues = dataState.map(({row}:any) => row)
            .filter((dialogue:any) => dialogue.DialogueMessages.length > 0 || dialogue.DialogueAvatars.length > 0)
            onChange(newDialogues)
        }
    }, [dataState])

    return <Col md={12} className="mt-3">
                <h3 className="font-weight-bold">Dialogues {dataState.length > 0 && ` (${dataState.length})`}</h3>
                    <RowStyled className="m-0 pt-4 px-4">
                        {
                            dataState.map(({row:Dialogue}:any, key:number) => 
                            <Col md={12} className="mb-4" key={key}>
                                <Card className="p-3 shadow mb-0">
                                    <Col md={12}>
                                        <h4 className="font-weight-bold"> Dialogue {key+1}</h4>
                                        <hr/>
                                    </Col>
                                    <Col md={12}>
                                        <h4 className="font-weight-bold">Dialogues Messages{Dialogue.DialogueMessages.length > 0 && ` (${Dialogue.DialogueMessages.length})`}</h4>
                                        <DialogueMessagesEditableTable 
                                            values   = {Dialogue.DialogueMessages}
                                            onChange = {(values:any) => handleChangeDialogueMessages(key, values)}/>
                                    </Col>
                                    <Col md={12}>
                                        <h4 className="font-weight-bold">Dialogue Avatars{Dialogue.DialogueAvatars.length > 0 && ` (${Dialogue.DialogueAvatars.length})`}</h4>
                                        <DialogueAvatarsEditableTable 
                                            values   = {Dialogue.DialogueAvatars}
                                            onChange = {(values:any) => handleChangeDialogueAvatars(key, values)}/>
                                    </Col>
                                </Card>
                            </Col>)
                        }  
                    </RowStyled>
                    <ColStyled md={12} className="p-3">
                        <Row className="justify-content-end">
                            <Col md="auto">
                                    <ButtonStyle 
                                        green
                                        label     = "New Dialogue" 
                                        size      = "190"
                                        className = "mr-2"
                                        onClick   = {handleNewDialogue}
                                        icon      = "plusWhite"/>
                            </Col>
                        </Row>
                    </ColStyled>
            </Col>
}


export default DialoguesEditor
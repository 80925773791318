export default [
    {
        keystone: "Order",
        className: "col-md-3",
        type: "input",
        label: "Order"
    },
    {
        keystone: "SectionId",
        className: "col-md-3",
        type: "Select",
        label: "Course",
        disabled: true
    },
    {
        keystone: "ExerciseTypeId",
        className: "col-md-3",
        type: "Select",
        label: "Type"
    },
    {
        keystone: "LearningObjectiveId",
        className: "col-md-3",
        type: "Select",
        label: "Learning Objective"
    }
    
]
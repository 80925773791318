import * as React from "react"
import { Modal }  from "react-bootstrap"
import {useEffect, useState} from "react"
import styled from "styled-components"
import { Row, Col, Card, FormGroup } from "reactstrap"

import Loader from "YConnect/Components/Loader"
import { ButtonStyle } from "YConnect/Components/Button"

import getStaticUrlAssests from "YConnect/Utils/GetStaticUrlAssets.util"
import * as FileResource from "YConnect/ResourcesBackEnd/File.resources"
import Toastify from "toastify-js"

const RowStyled = styled(Row)`
    overflow: auto;
    max-height: 350px;
`

const RowStyledTop = styled(Row)`
    width: 100%;
`

const CardStyled = styled(Card)`
    cursor: pointer;
    ${props => props.actived ? "border: 2px solid #79b100;":""}
    &:hover{
        background-color: #f0f5f6;
    }
`

const ImageStyled = styled.img`
    width: 100%;
    height: auto;
`

type ImageFilesModalProps = {
    onSelect:any
    onClose:any
}

const MAX_FILE_SIZE = 5*1024*1024

let files = 0

const ImageFilesModal = ({
    onSelect,
    onClose
}:ImageFilesModalProps) =>{

    const [searchTitle, setSearchTitle]       = useState<string>()
    const [listImageFiles, setListImageFiles] = useState([])
    const [listFiltered, setListFiltered]     = useState([])
    const [pageIndex, setPageIndex]           = useState(0)
    const [imageSelected, setImageSelected]   = useState()
    const [hasError, setHasError] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [fileForUpload, setFileForUpload] = useState([])

    let nameInput:any;

const checkFileSize = (file:any) => {
        if (file.size > MAX_FILE_SIZE) {
            Toastify({
                text: "File size exceeds 5 MB.",
                duration: 3000,
                className: "toastify-warning",
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                escapeMarkup: false,
                onClick: function(){} 
              }).showToast();
            setHasError(true)
            setFileForUpload([])
        } else {
            setHasError(false)
            fileForUpload.push(file)
            setFileForUpload(fileForUpload)
        }
    }

    const MAX_PER_PAGE = 200

    const totalPages =
    ((
        listFiltered.length - (listFiltered.length % MAX_PER_PAGE)
    )/MAX_PER_PAGE)
    + (listFiltered.length % MAX_PER_PAGE === 0 ? 0:1)

    useEffect(()=> {
        setLoading(true)
        updateFileList()
        setLoading(false)
    }, [])

    useEffect(() =>{
        if(listImageFiles){
            setListFiltered(listImageFiles)
        }
    }, [listImageFiles])

    useEffect(() => {
        if(searchTitle && searchTitle !== ""){
            setListFiltered(listImageFiles
                .filter(({Title}:any) => Title.toLowerCase().indexOf(searchTitle.toLowerCase()) > -1))
        }else{
            setListFiltered(listImageFiles)
        }

    }, [searchTitle])

    const updateFileList = () => {
        FileResource
        .GetAll()
        .then(({data}) => {
            setListImageFiles(data
                .filter(({Type}:any) => Type.toLowerCase() === "image")
                .sort((a:any, b:any) => {
                if ((new Date(a.Date)).getTime() > (new Date(b.Date)).getTime()) {
                    return -1
                }
                if ((new Date(a.Date)).getTime() < (new Date(b.Date)).getTime()) {
                    return 1
                }
                return 0
            }))
            setFileForUpload([])
        })
    }

    const uploadFile = async () => {
        if(fileForUpload && fileForUpload.length>0){
            setLoading(true)
            fileForUpload.forEach(async file=>{
                const data = new FormData()
                data.append("file[]", file)
                try{
                    const response = await FileResource.Upload(data)
                }catch(e){
                  Toastify({
                    text: e.response.data.error,
                    duration: 3000,
                    className: "toastify-warning",
                    newWindow: true,
                    close: true,
                    gravity: "top",
                    position: "right",
                    stopOnFocus: true,
                    escapeMarkup: false,
                    onClick: function(){} 
                  }).showToast();
                }finally{
                    files+=1
                    if(fileForUpload.length == files){
                        files = 0
                        setLoading(false)
                        updateFileList()
                    }
                }
            })
        }
    }

    const handleUploadFile = () => uploadFile()

    const handleSelectImage = ({Title, Type}:any) => {
        setImageSelected({Title, Type})
    }

    const handleSelectMedia = () =>{
        const {Title, Type} = imageSelected
        onSelect({Source:Title, MediaType:Type})
    }

    const handleChangeSearch= ( search:string) => {
        setSearchTitle(search)
    }

    return <Modal show={true} onHide={onClose} size="lg">
                {isLoading && <Loader/>}
                <Modal.Header
                    closeButton
                    closeLabel={"Close"}
                    className="first-access"
                    style={{zIndex: 10}}>
                          Image Files
                </Modal.Header>
                <Modal.Body className="d-flex flex-column justify-content-center">
                    <Row>
                        <Col md="auto">
                            <FormGroup>
                                <label className="form-label">Upload New Image</label>
                                <input
                                    type     = "file"
                                    accept   = ".jpg,.jpeg,.png"
                                    multiple
                                    onChange = {(e) => {
                                        setFileForUpload([])
                                        for(let i=0; i< e.target.files.length;i++){
                                            checkFileSize(e.target.files[i])
                                        }
                                    }}/>
                                     {hasError && (
                                <small className="form-text text-danger mb-3 ml-3">
                                    File size exceeds 5 MB. Please, try again!
                                </small>
                            )}
                            </FormGroup>
                        </Col>
                        <Col md="auto">
                            <ButtonStyle
                                green
                                disabled  = {!fileForUpload}
                                size      = "190"
                                onClick   = {handleUploadFile}
                                label     = "Upload"/>
                        </Col>
                    </Row>

                    {/* <Row>
                            <Col md={8}>
                            <ButtonStyle size = "350" onClick={()=>{ nameInput.focus(); }}  icon="search" className={'btn-table-header search-file'}>
                            <input
                                ref={(input) => { nameInput = input; }}
                                placeholder="Search"
                                type="text"
                                style={{border: 'transparent',width: '100%', outline: 'none'}}
                                value={searchTitle}
                                onChange = {({ target: { value } }:any) => handleChangeSearch(value)}/>
                            </ButtonStyle>
                            </Col>
                        </Row> */}
                    <Row>
                        <Col md={6}>
                            <input
                                value={searchTitle}
                                type="text"
                                placeholder="Search image"
                                className="form-control"
                                onChange = {({ target: { value } }:any) => handleChangeSearch(value)}/>
                        </Col>
                        {
                            listFiltered.length > 0 && <Col md="auto">
                                <strong>{listFiltered.length} image files found</strong>
                            </Col>
                        }
                    </Row>

                    <RowStyled>
                        {
                            listFiltered.slice(pageIndex, MAX_PER_PAGE)
                            .map(({Title, Type}:any, key:number) =>
                             <Col
                                key={key}
                                md={4}
                                className="py-2">
                                    <CardStyled
                                        actived ={imageSelected && imageSelected.Title === Title}
                                        onClick={()=> handleSelectImage({Title, Type})}
                                        className="mb-0 p-2">
                                        <ImageStyled src={getStaticUrlAssests(Title)}/>
                                        <div>{Title}</div>
                                    </CardStyled>
                            </Col>)
                        }
                    </RowStyled>
                </Modal.Body>
                <Modal.Footer>
                        <div className="d-flex flex-row justify-content-end">
                                <ButtonStyle
                                    disabled = {!imageSelected}
                                    green
                                    label    = "Select"
                                    size     = "small"
                                    onClick  = {handleSelectMedia}/>
                        </div>
                </Modal.Footer>
            </Modal>
}


export default ImageFilesModal

import * as React     from "react"
import { useContext } from "react"
import { Link }       from "react-router-dom"
import { Container }  from "reactstrap"
import styled         from "styled-components"
import { useState, useEffect } from "react"

import { BreadcrumbReportStudent } from "YConnect/Components/BreadCrumbList.tsx"
import PageTitle   from "YConnect/Components/PageTitle"
import Loader      from "YConnect/Components/Loader"
import UserContext from "YConnect/User.context"
import ImagesBook  from "YConnect/Utils/ImagesBook.util"
import * as CourseResource  from "YConnect/ResourcesBackEnd/Course.resources"
import getStaticUrl from "YConnect/Utils/GetStaticUrl.util"

//done TODO EDUARDO trocar ImagesBook para API.Book.GetCover({idCurso})

/** STYLE */
const CourseCardStyle = styled(Link)`
    border-radius: 12px;
    border-bottom: solid 3px ${props => props.theme.main.clickable};
    color: ${props => props.theme.main.textColor};
    flex: 1 1 auto;
    margin: 5px;
    margin-bottom: 24px;
    padding: 0;
    &.normal {
        width: 70%;
        @media screen and (min-width: 768px) {
            width: 40%;
        }
        @media screen and (min-width: 1024px) {
            width: calc(25% - 30px);
        }
    }

    &.fullsize {
        width: 100%;
        @media screen and (min-width: 768px) {
            width: 40%;
        }
        @media screen and (min-width: 1024px) {
            width: calc(25% - 30px);
        }
    }

    @media screen and (min-width: 768px) {
        width: 40%;
    }

    @media screen and (min-width: 1024px) {
        display: flex;
        flex: initial;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: 33%;
    }

    &:hover {
        color: ${props => props.theme.main.textColor};
        text-decoration: none;
    }

    .recommended {
        border: 0;
        position: absolute;
        text-align: center;
        top: -2px;
        width: 100%;

        .recommended-label {
            border: 0;
            background-color: rgba(78, 45, 120, 0.9);
            border-radius: 0 0 8px 8px;
            color: ${props => props.theme.main.textWhite};
            padding: 0 16px 8px;
        }
    }

    .card-photo {
        background-size: cover;
        border-radius: 12px 12px 0 0;
        height: 290px;
        width: 100%;
    }

    .overlayBlock{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        border-radius: 8px;
        background-color:  rgba(0, 0, 0, .5);
        cursor: not-allowed;
    }

    .overlayBlock > span {
        color: ${props => props.theme.main.textWhite};
    }

    .courses-stamps {
        border: 1px solid ${props => props.theme.main.textWhite};
        border-radius: 50px;
        bottom: 120px;
        color: ${props => props.theme.main.textWhite};
        display: block;
        font-size: 0.75rem;
        margin: 0 auto;
        padding: 2px 10px;
        position: relative;
        text-align: center;
        top: calc(100% - 48px);
        width: calc(100% - 60px);
    }

    .card-description {
        padding: 24px;
        width: 100%;

        .card-description-title {
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 16px 0;

            small {
                margin-left: 8px;
            }

            span {
                font-size: 14px;
            }
        }

        .card-description-tasks {

            &.completed {
                color: ${props => props.theme.main.successColor};
                small {
                    font-weight: bold;
                }
            }

            .card-description-percent {
                color: ${props => props.theme.main.mainPurple};

                &.completed {
                    color: ${props => props.theme.main.successColor};
                }
            }

        }

    }
`

const ClickableStyled = styled.span`
    color: ${props => props.theme.main.errorColor} !important;
    font-weight: bold;
`;

const ReportStudentPage = () => {

    const [bookCoverUrl, setBookCoverUrl] = useState("")
    const [cursosCapa, setCursosCapa] = useState()
    const { cursosVigentes, cursoAtual}: any = useContext(UserContext)

    useEffect(() => {
        let value = cursosVigentes.filter( (curso:any) => curso.fl_report);
        let valueFunc: any

        async function fetchCapaDeLivro(id) {
            await CourseResource
            .GetCover(id)
            .then((response:any) => {
                const capa_do_livro = responseHandler(response)
                const urlCoverBookCMS = getStaticUrl(capa_do_livro)
                valueFunc = urlCoverBookCMS
                return urlCoverBookCMS
            })

            return valueFunc
        }

        async function fetchCursosComCapa() {
            value = await Promise.all(value.map(async (key: any) => {
                return {
                    ...key,
                    urlBook: await fetchCapaDeLivro(key.id_curso)
                }
            }))

            setCursosCapa(value)
        }

        fetchCursosComCapa()

    }, [cursosVigentes])

    const responseHandler = (response: any) => {
        const hasBookCover = response.data.capas[0] ? true : false
        if (hasBookCover) return response.data.capas[0].url_capa
        return ""
    }

    const verifyBlockReport = (idCurso: any) => {
        const statusCourse = cursosVigentes.filter(
            ( course : any )=> course.id_curso === parseInt(idCurso, 10)).map((course:any) => course.ordem_curso);
            if(cursoAtual[0].id_curso === idCurso) return false;
            return statusCourse[0][0] === "20"
            // return statusCourse[0][0] === "3"
    }

    return (
        <Container>
            <BreadcrumbReportStudent/>
            <PageTitle label="Reports" />

            <section className="YC-flex reportsPage lateral-scroll-books d-flex mb-5">
                {
                    cursosCapa && cursosCapa.map(({ id_curso, titulo_curso, urlBook }:any, key:any) =>
                        <CourseCardStyle
                            key={key}
                            to={!verifyBlockReport(id_curso) && `reports/view-report-student/${id_curso}`}
                            className={`YC-CourseCard card shadow p-0 ${cursosVigentes.length >= 1 ? 'normal' : 'fullsize'}`}>

                            {verifyBlockReport(id_curso) &&
                            <div className="overlayBlock">

                            </div>
                            }

                            <div className="card-photo"
                                style={{ backgroundImage: `url(${urlBook}`}}>
                            </div>
                            <div className="card-description">
                                <span className="card-description-title">
                                    {verifyBlockReport(id_curso) ?
                                        <>
                                            <span>{" "}To unlock this course, you will need to complete the{" "}</span>
                                            <ClickableStyled
                                                className="mt-1 active-teach pointer"
                                            >
                                            {cursoAtual[0].titulo_curso}
                                            </ClickableStyled>
                                        </>
                                        : <span>{titulo_curso}</span>
                                    }

                                </span>
                            </div>
                        </CourseCardStyle>
                    )
                }
                {!cursosVigentes &&
                    <section className="row">
                        <div className="col-12 ">
                            <div className="card p-0 shadow d-flex flex-row flex-wrap justify-content-around profile">
                                <Loader/>
                            </div>
                        </div>
                    </section>
                }
            </section>
        </Container>
    )

}


export default ReportStudentPage



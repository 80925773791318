const styles = {
    reportTitle: {
        fontSize  : 20,
        color     : "#3cc187",
        alignment : "center",
        bold      : true,
        margin    : [0,40,0,5]
    },
    firstHeader: {
        fontSize  : 14,
        color     : "#335f6a",
        alignment : "center",
        bold      : true
    },
    secondHeader: {
        fontSize  : 10,
        color     : "#afbfc3",
        alignment : "center"
    },
    cells: {
        fontSize  : 12,
        color     : "#335f6a",
        alignment : "center"
    }
}

const generateRows = (rows:Array<any>) =>
rows.map(
    row => Object.keys(row)
    .reduce((newRow, property)=>[...newRow, {text: row[property], style: "cells"}], [])
)

const getHeader = ({
    nomeCompleto,
    course,
    date,
    email_aluno,
    teacher,
    last_visit
}:any) => {

    const [year, month, day]    = date.split("T")[0].split("-")
    const [year2, month2, day2] = last_visit.split("T")[0].split("-")
    return {
        table: {
            headerRows: 0,
            alignment: "center",
            widths: ["auto", "*", "*"],
            body: [
                [
                        { text: nomeCompleto, style: "firstHeader" },
                        { text: course, style: "firstHeader" },
                        { text: `${day}/${month}/${year}`, style: "firstHeader" }
                ],
                [
                        { text: email_aluno, style: "secondHeader" },
                        { text: teacher, style: "secondHeader" },
                        { text: `Last Visit: ${day2}/${month2}/${year2}`, style: "secondHeader" }
                ]
            ]
        },
        layout: "noBorders"
    }
}

const getTableUnidade = (rows:Array<Array<any>>) => (
    {
        table: {
            headerRows: 1,
            widths: ["auto", "*", "*", "*", "*", "*"],
            body: [
              [
                    { text: "Units", style: "firstHeader" },
                    { text: "Concluded sessions", colSpan: 2, style: "firstHeader" },
                    {},
                    { text: "Average Score", colSpan: 2, style: "firstHeader" },
                    {},
                    { text: "Final Score", style: "firstHeader" }
              ],
              [
                  "",
                  { text: "1st attempt", style: "secondHeader" },
                  { text: "2nd attempt", style: "secondHeader" },
                  { text: "1st attempt", style: "secondHeader" },
                  { text: "2nd attempt", style: "secondHeader" },
                  ""
              ],
              ...rows
            ]
        },
        layout: "lightHorizontalLines"
    })



const getTableEtapa = (rows:Array<Array<any>>) => ({
        table: {
            widths: ["*", "*"],
            body: rows
        },
        margin: [80,0],
        layout: {
            hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 0 : 1,
            vLineWidth: (i, node) => 0,
            hLineColor: "gray"
        }
    })


const getTableExercicio = (rows:Array<Array<any>>) => (
    {
        table: {
            headerRows: 1,
            widths: ["auto", "auto", "auto", "auto", "*", "*"],
            body: [
              [
                    { text: "Units", style: "firstHeader" },
                    { text: "Activity", style: "firstHeader" },
                    { text: "Average Score", colSpan: 2, style: "firstHeader" },
                    {},
                    { text: "Type", style: "firstHeader" },
                    { text: "Weight", style: "firstHeader" }
              ],
              [
                  "",
                  "",
                  { text: "1st attempt", style: "secondHeader" },
                  { text: "2nd attempt", style: "secondHeader" },
                  "",
                  ""
              ],
              ...rows
            ]
        },
        layout: "lightHorizontalLines" //Layout da Tabela
    }
)


const prepareEtapa = (reportEtapa:Array<any>) =>
reportEtapa.map((row:any) => Object.keys(row).reduce((newRow, property)=>property === "descricao" || property === "nota" ? ({...newRow, [property]:row[property]}):newRow, {}))

const prepareUnidade = (reportUnidade:Array<any>) =>
reportUnidade.map((row:any) => Object.keys(row).reduce((newRow, property)=>{
    if(property==="unidade"){
        return ({...newRow, [property]: row.idCurso == 69 || row.idCurso == 70 || row.idCurso == 82 || row.idCurso == 83 || row.idCurso == 85 || row.idCurso == 86 || row.idCurso == 87 ? row.nomeUnidade.substring(0, row.nomeUnidade.indexOf("-")) : `Unit ${row[property]}`});
    }else if(property==="concludedFirstTry" || property==="concludedLastTry"){
        return ({...newRow, [property]:`${row[property]}%`})
    }else if(property==="scoreFirstAttempt" || property==="scoreSecondAttempt"){
        return ({...newRow, [property]:row[property]?row[property]:"--"})
    }else if(property==="idCurso" || property==="nomeUnidade"){
        return ({...newRow});
    } else {
        return ({...newRow, [property]:row[property]})
    }

}, {}))


const prepareExercicio = (reportExercicio:Array<any>) =>
reportExercicio.map((row:any) => Object.keys(row).reduce((newRow, property)=>{
    if(property==="unidade"){
        return ({...newRow, [property]: row.idCurso == 69 || row.idCurso == 70 || row.idCurso == 82 || row.idCurso == 83 || row.idCurso == 85 || row.idCurso == 86 || row.idCurso == 87 ? row.nomeUnidade.substring(0, row.nomeUnidade.indexOf("-")) : `Unit ${row[property]}`});
    }else if(property==="nome_exercicio"){
        const newNome = row[property].toLowerCase()
        return ({...newRow, [property]:`${newNome.charAt(0).toUpperCase() + newNome.slice(1)} - Task ${row["ordem_exercicio"]}`})
    }else if(property==="nota_first_attempt" || property==="nota_second_attempt"){
        return ({...newRow, [property]:row[property] || row[property]===0?row[property]:"--"})
    }else if(property==="idCurso" || property==="nomeUnidade"){
        return ({...newRow});
    }else if(property!=="ordem_exercicio"){
        return ({...newRow, [property]:row[property]})
    }else{
        return newRow
    }

}, {}))

const getTitle = (text:string) => ({ text, style: "reportTitle" })

const generateObjectPDF = ({reportAluno, reportUnidade, reportEtapa, reportExercicio}:any) => ( {
    content: [
        ...reportAluno     ? [getHeader(reportAluno)] : [],
        ...reportUnidade   ? [getTitle("Score per Unit"),        getTableUnidade(generateRows(prepareUnidade(reportUnidade)))]:[],
        ...reportEtapa     ? [getTitle("Report's Final Score"),  getTableEtapa(generateRows(prepareEtapa(reportEtapa)))]: [],
        ...reportExercicio ? [getTitle("Score per Activity"),    getTableExercicio(generateRows(prepareExercicio(reportExercicio)))] :[]
    ],
    styles
})

export default generateObjectPDF

import API2 from "YConnect/API2"
import getConfig from "YConnect/Utils/GetRequestConfig.util"

export const GetAllByUnitId = (unitId:any) => 
    API2
    .Section
    .GetAllByUnitId({unitId}, getConfig())

export const Create = (section:any) => 
    API2
    .Section
    .Create(section, getConfig())
    
export const UpdateById = (section:any) => 
    API2
    .Section
    .UpdateById(section, getConfig())

export const GetById = (sectionId:any) => 
    API2
    .Section
    .GetById({sectionId}, getConfig())

export const DeleteById = (sectionId:any) => 
    API2
    .Section
    .DeleteById({sectionId}, getConfig())
import * as React  from "react"
import {useState}  from "react"
import { Row, Col} from "reactstrap"

import AverageResultScore from "YConnect/PagesComponents/PrePostReport/Components/AverageResultScore"
import StudentVision      from "YConnect/PagesComponents/PrePostReport/Components/StudentVision"
import Filters            from "YConnect/Components/Filters"
import useReport          from "YConnect/PagesComponents/PrePostReport/Components/useReport"
import useQueryParams     from "YConnect/PagesComponents/PrePostReport/Components/useQueryParams"

import useFilter  from "YConnect/PagesComponents/PrePostReport/Pearson.report/useFilter"
import useCombo   from "YConnect/PagesComponents/PrePostReport/Pearson.report/useCombo"
import TabsResult from "YConnect/PagesComponents/PrePostReport/Pearson.report/TabsResult"

const ReportContainer = ({
    queryParams,
    onChangeQueryParams
}:any) => {

    const [hasFilterLoading, setHasFilterLoading] = useState(false)

    const {
        fiterState : [
            {
                regionalIdSelected,
                establishmentIdSelected,
                semesterIdSelected,
                courseIdSelected,
                classIdSelected,
                studentIdSelected,
                periodIdSelected
            },
            setIdSelected
        ],
        comboState:[
            {
                regionalsCombo,
                establishmentsCombo,
                semestersCombo,
                coursesCombo,
                classesCombo,
                studentsCombo,
                periodCombo
            }, 
            setCombo
        ]
    } = useReport()

    useQueryParams({
        idSelecteds:{
            regionalIdSelected,
            establishmentIdSelected,
            semesterIdSelected,
            courseIdSelected,
            classIdSelected,
            studentIdSelected,
            periodIdSelected
        },
        setIdSelected,
        queryParams,
        onChangeQueryParams
    })

    useFilter({
        establishmentIdSelected,
        courseIdSelected,
        classIdSelected,
        studentIdSelected,
        setIdSelected
    })

    useCombo({
        regionalIdSelected,
        establishmentIdSelected,
        semesterIdSelected,
        courseIdSelected,
        classIdSelected,
        studentIdSelected,
        setHasFilterLoading,
        setCombo
    })
    
    const handleChangeFilter = (keystone:string, value:any) => setIdSelected({[keystone]:value})

    return <>
        <Row className="mb-5">
            <Col md={12}>
                <Filters
                    isLoading = {hasFilterLoading}
                    filterProps={[
                        {
                            keystone : "regionalIdSelected",
                            label    : "Regional",
                            value    : regionalIdSelected,
                            onChange : handleChangeFilter,
                            values   : regionalsCombo
                        },
                        {
                            keystone : "establishmentIdSelected",
                            label    : "Establishment",
                            value    : establishmentIdSelected,
                            onChange : handleChangeFilter,
                            values   : establishmentsCombo
                        },
                        {
                            keystone : "semesterIdSelected",
                            label    : "Semester",
                            value    : semesterIdSelected,
                            onChange : handleChangeFilter,
                            values   : semestersCombo
                        },
                        {
                            keystone : "courseIdSelected",
                            label    : "Course",
                            value    : courseIdSelected,
                            onChange : handleChangeFilter,
                            values   : coursesCombo
                        },
                        {
                            keystone : "classIdSelected",
                            label    : "Class",
                            value    : classIdSelected,
                            disabled : !classesCombo || classesCombo.length === 0,
                            onChange : handleChangeFilter,
                            values   : classesCombo
                        },
                        {
                            keystone : "studentIdSelected",
                            label    : "Student",
                            value    : studentIdSelected,
                            disabled : !studentsCombo || studentsCombo.length === 0,
                            onChange : handleChangeFilter,
                            values   : studentsCombo
                        },
                        {
                            keystone : "periodIdSelected",
                            label    : "Period",
                            value    : periodIdSelected,
                            disabled : !periodCombo || periodCombo.length === 0,
                            onChange : handleChangeFilter,
                            values   : periodCombo
                        }
                    ]}/>
            </Col>
        </Row>
            {
                !semesterIdSelected
                    ? null
                    : studentIdSelected
                        ? <StudentVision
                            studentIdSelected       = {studentIdSelected}
                            establishmentIdSelected = {establishmentIdSelected}
                            courseIdSelected        = {courseIdSelected}
                            classIdSelected         = {classIdSelected}
                            semesterIdSelected      = {semesterIdSelected}
                            periodIdSelected        = {periodIdSelected} />
                        : !studentIdSelected
                            ? <>
                                <AverageResultScore
                                    establishmentIdSelected = {establishmentIdSelected}
                                    semesterIdSelected      = {semesterIdSelected}
                                    courseIdSelected        = {courseIdSelected}
                                    classIdSelected         = {classIdSelected}
                                    regionalIdSelected      = {regionalIdSelected || ""} />
                                <TabsResult
                                    semesterIdSelected      = {semesterIdSelected}
                                    regionalIdSelected      = {regionalIdSelected || ""}
                                    establishmentIdSelected = {establishmentIdSelected || ""}
                                    courseIdSelected        = {courseIdSelected || ""}
                                    classIdSelected         = {classIdSelected || ""} />
                            </>
                            : null
            }
    </>
}

export default ReportContainer
import * as React   from "react"
import { Fragment, useState, useEffect, useContext } from "react"
import { Container, Col, Row } from "reactstrap"

import UserContext    from "YConnect/User.context"
import PrePostContext from "YConnect/PrePost.context"

import RecommendedCard       from "YConnect/PagesComponents/Dashboard/RecommendedCard"
import PrePostCard           from "YConnect/PagesComponents/Dashboard/PrePostCard"
import VideoFirstAccessModal from "YConnect/Modals/VideoFirstAccess.modal"
import PostTestModal         from "YConnect/Modals/PostTest.modal"
import PreTestModal          from "YConnect/Modals/PreTest.modal"
import YCard                 from "YConnect/Components/YCard"
import ReportCard            from "YConnect/Components/Report.card"
import MessagesCard          from "YConnect/Components/MessagesCard"
import VirtualClassesCard    from "YConnect/Components/VirtualClassesCard"
import TeacherBookCard       from "YConnect/Components/TeacherBook.card"
import getConfig             from "YConnect/Utils/GetRequestConfig.util"

import API      from "YConnect/API"
import Loader   from "YConnect/Components/Loader"


const BodyStudent = ({ user, history, prePost, exercise, isLoadingExercise }: any) =>
window.innerWidth > 1024
? <Fragment>
    <Col xs={12} md={8} className="d-flex flex-column flex-nowrap justify-content-start">
        {   prePost
            && prePost.listPptTestAlunoYConnect
            && prePost.listPptTestAlunoYConnect[0]
            && prePost.listPptTestAlunoYConnect[0].aluno_curso
            && prePost.listPptTestAlunoYConnect[0].aluno_curso.fl_feito === false
            ? <PrePostCard user={user} history={history} prePost={prePost} />
            : <RecommendedCard user={user} history={history} exercise={exercise} isLoadingExercise={isLoadingExercise} />
        }
        {
            user.cursoAtualBloqueadoPreTest ? '' : <ReportCard />
        }

        {/* Voltar esse card quando BBB estiver ativo novamente
        <VirtualClassesCard /> */}
        <MessagesCard />

    </Col>
    <Col xs={12} md={4}>
        <div className=" card shadow p-0">
            <YCard user={user} />
        </div>
    </Col>
</Fragment>
: <Fragment>
    <Col xs={12} className="d-flex flex-column flex-nowrap justify-content-start">
        {   prePost
            && prePost.listPptTestAlunoYConnect
            && prePost.listPptTestAlunoYConnect[0]
            && prePost.listPptTestAlunoYConnect[0].aluno_curso
            && prePost.listPptTestAlunoYConnect[0].aluno_curso.fl_feito === false
            ? <PrePostCard user={user} history={history} prePost={prePost} />
            : <RecommendedCard user={user} history={history} exercise={exercise} isLoadingExercise={isLoadingExercise} />
        }
    </Col>
    <Col xs={12}>
        <div className=" card shadow p-0">
            <YCard user={user} />
        </div>
    </Col>
    <Col xs={12}>
        {
            !user.cursoAtualBloqueadoPreTest
            && <ReportCard />
        }
    </Col>
    <Col>
        {/* Voltar esse card quando BBB estiver ativo novamente
        <VirtualClassesCard /> */}
        <MessagesCard />
    </Col>

</Fragment>


const BodyOther = ({ user, teacherBooks, history }: any) =>
        window.innerWidth > 1024
        ? <Fragment>
            <Col xs={12} md={8}>
                <section className="book-courses lateral-scroll-books d-flex justify-content-between">
                    {teacherBooks && teacherBooks.map((book: any, key: any) => (
                        <TeacherBookCard key={key} book={book} link={"courses/1/view-book"} history={history} />
                    ))}
                </section>
                <MessagesCard />
            </Col>
            <Col xs={12} md={4}>
                <div className=" card shadow p-0">
                    <YCard user={user} />
                </div>
            </Col>
        </Fragment>
        : <Fragment>
            <Col xs={12}>
                <section className="book-courses lateral-scroll-books d-flex justify-content-between">
                    {teacherBooks && teacherBooks.map((book: any, key: any) => (
                        <TeacherBookCard key={key} book={book} link={"courses/1/view-book"} history={history} />
                    ))}
                </section>
            </Col>
            <Col xs={12}>
                <div className=" card shadow p-0">
                    <YCard user={user} />
                </div>
            </Col>
            <Col xs={12}>
                <MessagesCard />
            </Col>
        </Fragment>

const renderOptionsByUserRole = (user: any, history: any, teacherBooks?: any, prePostUserContext?: any, exercise: any, isLoadingExercise: boolean) =>
    user.profiles.indexOf(15) > -1 || user.profiles.indexOf(12) > -1
    ? <BodyOther user={user} history={history} teacherBooks={teacherBooks} />
    : <BodyStudent user={user} history={history} prePost={prePostUserContext} exercise={exercise} isLoadingExercise={isLoadingExercise} />

type DashboardProps = {
    history                 : any
    onCloseModalFirstAccess : Function
    backpacks               : any
    onUpdatePrePost         : Function
}


const Dashboard = ({history, onCloseModalFirstAccess, backpacks, onUpdatePrePost}: DashboardProps) => {

    const userContext:any        = useContext(UserContext)
    const prePostUserContext:any = useContext(PrePostContext)

    const [hasShowVideoModal, setShowVideoModal] = useState(false)
    const [width, setWidth]                      = useState(window.innerWidth)
    const [teacherBooks, setTeacherBooks]        = useState()
    const [isLoading, setLoading]                = useState(true)
    const [isLoadingExercise, setLoadingExercise]= useState(true)
    const [showPostModal, setShowPostModal]      = useState(false)
    const [showPreModal, setShowPreModal]        = useState(false)
    const [exercise, setExercise]                = useState()

    useEffect(() => {
        if (!prePostUserContext) {
            fetchPrePostTest()
        } else {
            const afterLogin = JSON.parse(localStorage.getItem("afterLogin"))
             if ( prePostUserContext.listPptTestAlunoYConnect
                    && prePostUserContext.listPptTestAlunoYConnect[0]
                    && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso
                    && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso.fl_is_pre === false) {
                if (afterLogin) {
                    setShowPostModal(true)
                    localStorage.removeItem("afterLogin")
                }
            } else {
                localStorage.removeItem("afterLogin")
            }
        }
    }, [prePostUserContext])


    useEffect(() => {

        setLoading(true)

        if (
            (userContext.primeiroAcessoGameficacao === null
            || (
                userContext.primeiroAcessoGameficacao
                && userContext.primeiroAcessoGameficacao.dt_primeiro_acesso === null
            )
            ) && userContext.profiles.indexOf(15) === -1 && userContext.profiles.indexOf(12) === -1
        ) {
            setShowVideoModal(true)
        }

        const fetchData = async () => {
            try {
                const result = await API.Course.GetAll({
                    isDegustacao : false
                }, getConfig())
                setTeacherBooks(result.data.slice(0,3))
                setLoading(false);
            } catch (error) {}
        }

        if (userContext && (userContext.profiles.indexOf(15) > -1 || userContext.profiles.indexOf(12) > -1)) {
            fetchData()
        } else {
            setLoading(false)
        }

        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [userContext])

    useEffect(() => {
        if (!exercise) {
            fetchExercise()
        }
    }, [exercise])

    const fetchPrePostTest = async () => {
        try {
            const response = await API.PrePostTest.Get({
                id_aluno: userContext.idUsuario,
                id_curso: userContext.idCursoAtual
            }, getConfig())

            localStorage.setItem("prepost", JSON.stringify(response.data))
            onUpdatePrePost(response.data)
        } catch (error) {
            onUpdatePrePost({})
        }
    }

    const fetchExercise = async () => {
        setLoadingExercise(true)
        const {idUsuario, idCursoAtual} = userContext
        if(idCursoAtual !== 0){
            try {
                const response = await API.Exercicio.getLastExercicio({
                    idUsuario: idUsuario,
                    idCurso: idCursoAtual
                }, getConfig())

                setExercise(response.data)
                setLoadingExercise(false)
            } catch (error) {
                setExercise({})
                setLoadingExercise(false)
            }
        }
    }

    const handleClose = () => {
        setShowVideoModal(false)
        onCloseModalFirstAccess()
    }

    const handleClosePostModal = () => {
        setShowPostModal(false)
        setShowPreModal(false)
        localStorage.removeItem("afterLogin")
    }

    const renderWelcomeMessage = () => {
        if (userContext.cursoAtualBloqueadoPreTest) {
            return (
                <>
                    <span className="h2 font-weight-bold">{userContext.nome}</span>, <br className="d-lg-none"/>
                    welcome to YConnect! It's English time. :)
                </>
            )
        }

        return (
            <>
                Welcome back, <span className="h2 font-weight-bold">{userContext.nome}</span>. <br className="d-lg-none"/>
                It's English time!
            </>
        )
    }



    return !isLoading && prePostUserContext
    ? <Container className="home-page main-container" fluid={width <= 1024}>
            <Row className="col-12">
                <h2 className="home-title">
                    {renderWelcomeMessage()}
                </h2>
            </Row>
            <Row className="mb-5">
                {
                    renderOptionsByUserRole(
                        userContext,
                        history,
                        teacherBooks,
                        prePostUserContext,
                        exercise,
                        isLoadingExercise)
                }
            </Row>
            <VideoFirstAccessModal isShow={hasShowVideoModal} onClose={handleClose} />

            {
                userContext.profiles.length <= 1
                && prePostUserContext.listPptTestAlunoYConnect
                && prePostUserContext.listPptTestAlunoYConnect[0]
                && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso
                && prePostUserContext.listPptTestAlunoYConnect[0].aluno_curso.fl_feito === false
                ? <>
                    <PostTestModal
                        isShow   = {showPostModal}
                        onClose  = {handleClosePostModal}
                        username = {userContext.nome}
                        prePost  = {prePostUserContext} />
                    <PreTestModal
                        isShow   = {showPreModal}
                        onClose  = {handleClosePostModal}
                        username = {userContext.nome}
                        prePost  = {prePostUserContext} />
                </> : ''
            }
        </Container>
        : <Loader />
}

export default Dashboard

import styled        from "styled-components"
import { LinkStyle, YLinkButtonStyle } from "YConnect/Components/Button"

export const BackButtonStyle = styled(LinkStyle)`
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;

    img {
        margin-right: 10px;
        padding-left: 0 !important;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const BackLinkStyle = styled(YLinkButtonStyle)`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    border: none;
    padding: 0;
    width: auto!important;

    img {
        margin-right: 10px;
        padding-left: 0 !important;
        filter: ${props => props.theme.main.filterSvg};
        width: ${window.innerWidth > 1024 ? 'auto': '15px'};
    }
`

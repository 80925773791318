import * as React              from "react"
import { useState }            from "react"
import { UncontrolledTooltip } from "reactstrap"
import orderBy                 from "lodash/orderBy"
import styled                  from "styled-components"

import * as crown        from "YConnect/Assets/svg/crown.svg"
import StudentScoreStyle from "YConnect/PagesComponents/PrePostReport/Teacher.components/StudentScore.style"

import ProfilePictureById from "YConnect/PagesComponents/PrePostReport/Components/ProfilePictureById"

const CardStyle = styled.div`
    align-items: center;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    min-height: 223px;
`

const CardLoadingStyle = styled(CardStyle)`
    .loading {
        left: calc(50% - 20px);
        position: relative;
        width: 40px;
        height: 40px;
        border: 4px transparent solid;
        border-top: 4px ${props => props.theme.main.lightPurple} solid;
        border-radius: 30px;
        animation: preload .7s infinite;
        animation-timing-function: linear;
    }

        
    @-moz-keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes preload {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`

const StudentScoreTable = ({ 
    title, 
    isBest, 
    isOrderable, 
    students 
}: any) => {

    const [rankedStudentEvo, setRankedStudentEvo] = useState("score")

    const setOrder = (e: any) => {
        setRankedStudentEvo(e.target.value)
    }
    
    return <StudentScoreStyle>
        <header>
            <h2>{title}</h2>
            {
                isOrderable
                && <div className="order">
                    <label className="form-label mr-2">Order by higher</label>
                    <div className="btn-group btn-group-toggle" data-toggle="buttons" onChange={(e) => setOrder(e)}>
                        <label className={`btn btn-radio ${rankedStudentEvo === "score" ? "active": ""}`}>
                            <input type="radio" name="order" value="score"/>
                            Score
                        </label>
                        <label className={`btn btn-radio ${rankedStudentEvo === "evolution" ? "active": ""}`}>
                            <input type="radio" name="order" value="evolution"/>
                            Evolution
                        </label>
                    </div>
                </div>
            }

        </header>
        <div className="student-score-border">
            {
                students 
                ? students.length > 0 
                    ? <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Duration</th>
                                        <th scope="col">Correct answers</th>
                                        <th scope="col">{rankedStudentEvo === "evolution" ? "Evolution" : "Score"}</th>
                                    </tr>
                                </thead>
                                <tbody className={isBest ? "bestStudents" : null}>
                                    {students && (rankedStudentEvo === "evolution" 
                                        ? orderBy(students, "evolucao", "desc")
                                        : students
                                    ).map((student: any, key: any) => {
                                        return (
                                            <tr key={key}>
                                                <td className="user-avatar">
                                                    <ProfilePictureById
                                                        height    = {42}
                                                        idUsuario = {student.idaluno}
                                                        width     = {42}/>
                                                    {
                                                        isBest 
                                                        && key === 0
                                                        && <div className="best-crown ">
                                                            <img src={crown} className="white-icon" />
                                                        </div>
                                                    }
                                                    <span className="student-name" id={`student-${student.idaluno}-${key}`}>{student.nome}</span>
                                                    <UncontrolledTooltip  placement="top" target={`student-${student.idaluno}-${key}`}>
                                                        {student.nome}
                                                    </UncontrolledTooltip >
                                                </td>
                                                <td>{Math.floor(student.duracao / 60)}:00</td>
                                                <td>{student.respCorretas}</td>
                                                <td>
                                            {
                                                rankedStudentEvo === "evolution"
                                                ? `${student.evolucao}%`
                                                : `${(student.media * 100).toFixed(1)}%`
                                            }
                                        </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    : <p className="w-100 align-self-center text-center justify-content-center">No students!</p>
                : <CardLoadingStyle>
                        <div className="loading"></div>
                    </CardLoadingStyle>
            }
        </div>
    </StudentScoreStyle>
}

/*

*/
export default StudentScoreTable
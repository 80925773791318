import * as React from "react"
import {useEffect} from "react"

import PanelCrud        from "YConnect/PagesComponents/ControlPanel/Components/PanelCrud"
import useSelectData       from "YConnect/Hooks/useSelectData"

import * as LevelResource from "YConnect/ResourcesBackEnd/Level.resources"
import * as CourseResource  from "YConnect/ResourcesBackEnd/Course.resources"

import {
    GetAll, 
    GetById, 
    Create, 
    UpdateById,
    DeleteById
} from "YConnect/ResourcesBackEnd/Course.resources"

import CONFIG_TABLE_COURSE from "YConnect/PagesComponents/ControlPanel/Tables/Course.table.config"
import COURSE_TEMPLATE     from "YConnect/PagesComponents/ControlPanel/Templates/Course.template"

const CoursesPanel = () =>{

    const  {
        selectData,
        AddSelectData
    } = useSelectData()

    useEffect(()=>{
        updateAllSelectData()
    }, [])

    const updateLevelSelectData = () =>
        LevelResource
        .GetAll()
        .then((response:any) => {
            const {data} = response
            AddSelectData("LevelId", "Id", "Description", data)

        })
    
    const updateBookSelectData = () =>
        BookResource
        .GetAll()
        .then((response:any) => {
            const {data} = response
            AddSelectData("BookId", "Id", "Title", data)
        })

    const updateAllSelectData = () => {
        updateLevelSelectData()
        updateBookSelectData()
    }
    
    return <PanelCrud
                idName            = "courseId"
                labelButtonCreate = "Create Course"
                labelForm         = "Course"
                template          = {COURSE_TEMPLATE}
                configTable       = {CONFIG_TABLE_COURSE}
                selectData        = {selectData}
                GetAll            = {GetAll}
                GetById           = {GetById}
                Create            = {Create}
                UpdateById        = {UpdateById}
                DeleteById        = {DeleteById} />
}

export default CoursesPanel